import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Spin, Table } from 'antd';
import AddAdminFeedback from './AddFeedbackForm';
import { adminData, errorMessage, successMessage } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import FeedbackDetailModal from '../../../Components/FeebackDetail/FeebackDetail';
import EditAdminFeedback from './EditFeedbackForm';
import axios from 'axios';
import { ADMIN } from '../../../utils/apis';
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { FeedbackResponsesModal } from '../../../Components';
import CopyAdminFeedbackForm from './CopyFeedback';

const AdminFeedback = (props) => {
    const { selectedLanguage, adminActions, lanKorCondition } = props
    const [showform, setShowFrom] = useState(false)
    const [showDetail, setShowDetail] = useState(null)
    const [responsesDetail, setResponsesDetail] = useState(null)
    const [data, setData] = useState({})
    const [copyData, setCopyData] = useState({})
    const [spin, setSpin] = useState({})
    const allAdminFeedbackForms = useSelector(state => state?.adminReducer?.allAdminFeedbackForms) || []
    console.log('allAdminFeedbackForms', allAdminFeedbackForms)
    const onCancel = () => {
        setData({})
        setCopyData({})
        setShowFrom(false)
    }

    const deleteFeedback = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_ADMIN_FEEDBACK_FORM, { id })
            .then(async (res) => {
                const { data } = res
                if (data?.success) {
                    setSpin({})
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    await adminData.getAllAdminFeedBackForms(adminActions)
                }
                else {
                    setSpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }

            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const columns = [
        {
            title: <p style={{ width: 160 }}>{selectedLanguage?.title_Title}</p>,
            dataIndex: 'title',
        },
        {
            title: <p style={{ width: 360 }}>{selectedLanguage?.title_Action}</p>,
            render: (e) => <>
                <Button
                    type='primary'
                    style={{ marginRight: '5px', marginTop: '4px' }}
                    onClick={() => setCopyData(e)}
                    icon={<CopyOutlined />}
                >{selectedLanguage?.txt_copyFeedbackForm}</Button>
                <Button
                    disabled={e?.feedbackResponses?.length}
                    type='primary'
                    style={{ marginRight: '5px', marginTop: '4px' }}
                    onClick={() => setData(e)}
                    icon={<EditOutlined />}
                >{selectedLanguage?.Edit}</Button>
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    disabled={e?.feedbackResponses?.length}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteFeedback(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button
                            style={{ marginTop: '4px' }}
                            disabled={e?.feedbackResponses?.length}
                            icon={<DeleteOutlined />} type='primary'
                        >{selectedLanguage?.Delete}</Button>
                    }
                </Popconfirm >
            </>
        },
        {
            title: <p style={{ width: '200px' }}></p>,
            render: (e) =>
                <span style={{ width: '220px' }}>

                    <Button disabled={!e?.feedbackResponses?.length} onClick={() => setResponsesDetail(e)} >{selectedLanguage?.txt_Responses}</Button>
                    &nbsp;
                    <Button onClick={() => setShowDetail(e)} >{selectedLanguage?.txt_detail}</Button>
                </span>
        },
    ]

    useEffect(() => {
        adminData?.getAllAdminFeedBackForms(adminActions)
    }, [])

    return (
        <div>
            {(data?._id || copyData?._id || showform) ?
                <>
                    <Button
                        style={{ float: 'right' }}
                        type='primary'
                        onClick={onCancel}>{selectedLanguage?.txt_Cancel}</Button>
                    <br />
                </>
                :
                <>
                    <h1> {selectedLanguage?.txt_Feedbacks} </h1>
                    <Button
                        style={{ float: 'right', margin: '10px', marginRight: '0px' }}
                        type='primary' onClick={() => setShowFrom(true)}>{selectedLanguage?.txt_Add_feedback_form}</Button>
                </>
            }

            <div>
                {showform ?
                    <AddAdminFeedback onCancel={onCancel} {...props} />
                    : data?._id ?
                        <EditAdminFeedback data={data} onCancel={onCancel} {...props} />
                        : copyData?._id ?
                            <CopyAdminFeedbackForm data={copyData} onCancel={onCancel} {...props} />
                            :
                            <div className='new-layout-table-upper-div margin0'>
                                <Table
                                    style={{ marginTop: '30px' }}
                                    className='new-layout-table'
                                    scroll={{ x: '800px' }}
                                    columns={columns}
                                    dataSource={allAdminFeedbackForms}
                                    pagination={true}
                                />
                            </div>}
            </div>

            {
                showDetail?._id &&
                <FeedbackDetailModal data={showDetail} open={showDetail?._id} onCancel={() => setShowDetail(null)} {...props} />
            }
            {
                responsesDetail?._id &&
                <FeedbackResponsesModal data={responsesDetail} open={responsesDetail?._id} onCancel={() => setResponsesDetail(null)} {...props} />
            }
        </div>
    )
}

export default AdminFeedback