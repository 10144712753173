import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Upload } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN, POST } from '../../../utils/apis';
import { checkSelectOption, errorMessage, successMessage, teacherData } from '../../../utils/helpers';

const { Option } = Select
const AddTrainVideo = (props) => {
    const { selectedLanguage, subjects, onCancel, showPopup, user, userActions, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const chapters = useSelector(state => state?.generalReducer?.chapters || [])
    const [form] = Form.useForm()

    let optionsChapter = chapters?.map((v) => v?.chapterName)
    optionsChapter = _.uniq(optionsChapter)
    let optionsParagraph = chapters?.map((v) => v?.paragraph).flat().map((x) => x?.paragraphName)
    optionsParagraph = _.uniq(optionsParagraph)
    let optionsTopics = chapters?.map((v) => v?.paragraph)?.flat()?.map((x) => x?.topics)?.flat()?.map((z) => z?.topicName)
    optionsTopics = _.uniq(optionsTopics)

    const normFile3 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }

        return setFileList(e?.fileList);
    }
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }
    const onFinish = async (values) => {
        setLoading(true)
        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        setLoading(false)
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    setLoading(false)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageMl)
                })
        }

        console.log('values', values)
        values.train = true
        values.userId = user?._id
        axios.post(POST?.ADD_TEACHER_VIDEO, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    teacherData.getTeacherVideos(userActions, user?._id)
                    successMessage(lanKorCondition ? data?.message : data?.messageMl)
                    setLoading(false)
                    onCancel()
                } else {
                    setLoading(false)
                    return errorMessage(lanKorCondition ? data?.message : data?.messageMl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageMl)
            })
    }

    return (
        <Modal
            className='pop-info-modal'
            style={{ top: 10 }}
            open={showPopup}
            footer={false}
            onCancel={onCancel}
        >
            <div >
                <p className='new-p-question'>{selectedLanguage?.txt_Uploadvideo}</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                    requiredMark={true}
                    style={{ maxHeight: '74vh', overflowY: 'scroll' }} className='new-layout-scroll-class'
                >
                    <Form.Item
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseEnterVideoName,
                            }
                        ]}
                        label={selectedLanguage?.txt_Entervideoname}
                        required
                    >
                        <Input placeholder={selectedLanguage?.txt_Entervideoname} />
                    </Form.Item>
                    <Form.Item
                        name='discription'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseEnterVideDescription,
                            }
                        ]}
                        label={selectedLanguage?.txt_Description}
                        required
                    >
                        <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.txt_EnterVideDescription} />
                    </Form.Item>
                    <Form.Item
                        name='subject'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectSubject
                            }
                        ]}
                    >
                        <Select
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            placeholder={selectedLanguage?.label_SelectSubject}>
                            {subjects.map((v, i) => {
                                return (
                                    <Option key={i} value={v?._id}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='chapter'
                        label={selectedLanguage?.Chapter}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectChapter,
                            },
                        ]}
                    // initialValue={copyQuestion?.subject?._id}
                    >
                        <Select
                            // onChange={onChangeSubject}
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.txt_PleaseSelectChapter}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {optionsChapter.map((v, i) => {
                                return (
                                    <Option value={v} key={i}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='paragraph'
                        label={selectedLanguage?.txt_Paragraph}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseParagraph,
                            },
                        ]}
                    // initialValue={copyQuestion?.subject?._id}
                    >
                        <Select
                            // onChange={onChangeSubject}
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.errorMessage_PleaseParagraphhere}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {optionsParagraph.map((v, i) => {
                                return (
                                    <Option value={v} key={i}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='topics'
                        label={selectedLanguage?.txtTopics}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselecttxtTopics,
                            }
                        ]}
                    >
                        <Select
                            mode='multiple'
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.errorMessage_selecttxtTopics}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {optionsTopics.map((v, i) => {
                                return (
                                    <Option value={v} key={i}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={selectedLanguage?.txt_Selectvideo}
                        name='file'
                        style={{ width: '100%' }}
                        valuePropName='file'
                        getValueFromEvent={normFile3}
                    >
                        <Upload
                            className='questionFileUpload'
                            multiple={false}
                            maxCount={1}
                            beforeUpload={() => false}
                            fileList={fileList}
                            onChange={handlePreview2}
                            accept='video/*'
                            action='/upload.do'
                            style={{ width: '100%' }}
                        >
                            <Button type='primary' style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                            <br />
                        </Upload>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    )
}
export default AddTrainVideo