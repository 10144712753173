import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { allPaths } from '../../../utils/constants'
import { calculateReviewAverage, checkLuxurySub, errorMessage, sortFormsByAttempted, studentData } from '../../../utils/helpers'
import NoDataComponent from '../../NoData/NoDataComponent'
import CardsReviewScreen from '../Review/StudentCardsReview'

const NewStudentExam = (props) => {
    const { user, userActions, selectedLanguage, lanKorCondition, history } = props

    const forms = useSelector(state => state?.userReducer?.forms || [])
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const [examDetailForReview, setExamDetailForReview] = useState(null)

    useEffect(() => {
        studentData.getStudentExams(userActions, user?._id)
    }, [])

    const getSubject = (id) => {
        let subject = subjects.find((v) => v?._id === id)
        return subject?.subjectName
    }

    const gotoReview = (e) => {
        setExamDetailForReview(e)
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                {!examDetailForReview?._id ?
                    <div>
                        <h3 className='new-exam-title marleft-16' >{selectedLanguage?.txt_Exams}</h3>
                        <div className='new-exam-screen-cards'>
                            {sortFormsByAttempted(forms)?.length ?
                                sortFormsByAttempted(forms)?.map((v, i) => {
                                    return (
                                        <div className='new-exam-card' key={i} >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end',
                                                    marginTop: '-11px',
                                                }}
                                                onClick={() => { gotoReview(v) }}
                                            >
                                                <button
                                                    className='guest-card-review-box'
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {/* {v?.review?.length ? */}
                                                    <AiFillStar size={15} color='white' />
                                                    {/* : null} */}
                                                    {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                </button>
                                                <p className='card_desc1' style={{ textAlign: 'end', marginBottom: '5px' }}>
                                                    {!v?.isReviewHide ?
                                                        v?.review?.length ?
                                                            calculateReviewAverage(v?.review) < 4.5 ?
                                                                selectedLanguage?.Bad + ',' :
                                                                calculateReviewAverage(v?.review) < 5.9 ?
                                                                    selectedLanguage?.Poor + ',' :
                                                                    calculateReviewAverage(v?.review) < 6.9 ?
                                                                        selectedLanguage?.Adeqate + ',' :
                                                                        calculateReviewAverage(v?.review) < 8.9 ?
                                                                            selectedLanguage?.Good + ',' :
                                                                            selectedLanguage?.Excellent + ',' : null : null}
                                                    {!v?.isReviewHide ?
                                                        v?.review?.length ?
                                                            v?.review?.length !== 1 ?
                                                                v?.review?.length > 999 ?
                                                                    ' ' +
                                                                    `${v?.review?.length?.slice}`(0, 1) +
                                                                    '.' +
                                                                    `${v?.review?.length}`?.slice(1, 2) +
                                                                    'k' + ' ' + selectedLanguage?.Reviews :
                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                            selectedLanguage?.NoReviewesYet :
                                                        selectedLanguage?.NoReviewesYet}
                                                </p>
                                            </div>
                                            <div className='grey-card' />
                                            <div className='new-exam-card-row-div'>
                                                <p className='new-row-title'>{selectedLanguage?.txt_Teacher}</p>
                                                <p className='new-row-value'>{v?.userId?.businessName || selectedLanguage?.title_Admin}</p>
                                            </div>
                                            <div className='grey-card' />
                                            <div className='new-exam-card-row-div'>
                                                <p className='new-row-title'>{selectedLanguage?.txt_Title}</p>
                                                <p className='new-row-value'>{v?.title ? v?.title : '----'}</p>
                                            </div>
                                            <div className='grey-card' />
                                            <div className='new-exam-card-row-div'>
                                                <p className='new-row-title'>{selectedLanguage?.txt_Formname}</p>
                                                <p className='new-row-value'>{v?.formName ? v?.formName : '----'}</p>
                                            </div>
                                            <div className='grey-card' />
                                            <div className='new-exam-card-row-div'>
                                                <p className='new-row-title'>{selectedLanguage?.title_Subject}</p>
                                                <p className='new-row-value'>{getSubject(v?.subjects) || '----'}</p>
                                            </div>
                                            <div className='grey-card' />
                                            <div className='new-exam-card-row-div'>
                                                <p className='new-row-title'>{selectedLanguage?.txt_Status} </p>
                                                <p className='new-row-value'>
                                                    {
                                                        v?.public && v?.isApproved ? selectedLanguage?.Approved
                                                            : v?.public && v?.isDeclined ? selectedLanguage?.Declined
                                                                : v?.public && !v?.isApproved && !v?.isDeclined ? selectedLanguage?.Pending
                                                                    : selectedLanguage?.Privates}
                                                </p>
                                            </div>
                                            <div className='grey-card' />
                                            <p className='new-row-title'>{selectedLanguage?.txt_Description} </p>
                                            <p className='new-row-value' style={{ width: '100%' }}>{v?.description ? v?.description : '---------'}</p>

                                            <Button
                                                type='primary'
                                                disabled={v?.attempted}
                                                block
                                                size='large'
                                                target={'_blank'}
                                                onClick={() => {
                                                    if (!user?.exams && !checkLuxurySub(user?.subscription)) {
                                                        return errorMessage('Please Buy Subscription')
                                                    }

                                                    history.push({
                                                        pathname: allPaths?.FORM,
                                                        state: { formData: v }
                                                    })
                                                }} >{v?.attempted ? selectedLanguage?.btn_Attempted : selectedLanguage?.btn_Open}</Button>
                                        </div>
                                    )
                                }) :
                                <>
                                    <NoDataComponent />
                                </>
                            }
                        </div>
                    </div>
                    :
                    <CardsReviewScreen
                        type='exam'
                        onCancel={() => setExamDetailForReview(null)}
                        data={examDetailForReview}{...props} />
                }
            </div>
        </div >
    )
}
export default NewStudentExam