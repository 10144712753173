import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Switch, Upload } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { BiMoneyWithdraw, } from 'react-icons/bi';
import { GiRank1 } from 'react-icons/gi';
import { useSelector } from "react-redux";
import { ADMIN } from "../../../utils/apis";
import { adminData, errorMessage, successMessage, warningMessage } from "../../../utils/helpers";
import { contentType, subscriptionType } from '../../../utils/constants';

const { Option } = Select
const AddCoupons = (props) => {
    const { selectedLanguage, generalActions, user, adminActions } = props
    const language = useSelector(state => state?.languageReducer)
    const isEnglish = language?.language === 'english'
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [type, setType] = useState('wallet')
    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []

    function onChangeRecharge_TeacherSub(e) {
        setType(e/*?.target.checked*/);
    }
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.PleaseUploadImageonly)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const onFinish = async (values) => {
        values.adminId = user?._id
        values.file = fileList
        if (!values?.file?.length) {
            return warningMessage(selectedLanguage?.text_PleaseUploadImage)
        }
        setLoading(true)

        let formData = new FormData()

        formData.append('file', values?.file[0])
        formData.append('description', values?.description)
        formData.append('type', values?.type)
        formData.append('quantity', Number(values?.quantity))
        formData.append('money', Number(values?.money || 0) || 0)
        formData.append('adminId', values?.adminId)
        if (values?.teacher?.length) {
            for (let v of values?.teacher) {
                formData.append('teacher[]', v)
            }
        }

        axios?.post(ADMIN?.ADD_ADMIN_COUPONS, formData)
            .then((res) => {
                const { data } = res
                console.log('res', 'res', res, isEnglish)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    setLoading(false)
                    form.resetFields()
                    adminData.getAdminCoupons(adminActions, user?._id)
                }
                else {
                    setLoading(false)
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }

            }).catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    return (
        <Form
            name='form'
            onFinish={onFinish}
            layout={'vertical'}
            requiredMark={false}
        >
            <div className='form-two-input-flex'>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='description'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_Description}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_PleaseInputDescription
                            }
                        ]}
                    >
                        <Input
                            maxLength={40}
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.placeHolder_InputYourDescriptionHere}
                        />
                    </Form.Item>
                </div>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='quantity'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_Quantity}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_PleaseInputYourQuantity
                            }
                        ]}
                    >
                        <div>
                            <Input
                                type='number'
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.placeholderInputYourQuantityHere}
                                min={0}
                                step={1}
                                precision={0}
                            />
                        </div>
                    </Form.Item>
                </div>
            </div>

            <div className='form-two-input-flex'>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        label={<p className='p-admin-title-text'>{selectedLanguage?.txt_SelectType}</p>}
                        name='type'
                        onChange={onChangeRecharge_TeacherSub}
                        initialValue={type}
                    >
                        <Select
                            className='form-input-new-style-select-minhi'
                            showSearch={true}
                            optionFilterProp="children"
                            placeholder={selectedLanguage?.placeHolder_SelectTeacher}
                        >
                            <Option value={'wallet'} key={'wallet'}>
                                {selectedLanguage?.txt_Walletrecharge}
                            </Option>

                            {subscriptionType.map((v, i) => (
                                <Option value={v?.value} key={i}>
                                    {v?.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='money'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_money}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_moneyisrequired
                            }
                        ]}
                    >
                        <Input
                            className='form-input-new-style form-input-new-style-sufix'
                            placeholder={selectedLanguage?.placeholder_enterMoneyHere}
                            type='number'
                            step={0.01}
                            min={0}
                            precision={2}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className='form-two-input-flex'>
                {type === 'teacher' &&
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='teacher'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.title_SelectTeacher}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_TeacherisRequired
                                }
                            ]}
                        >
                            <Select
                                mode='multiple'
                                className='form-input-new-style-select-minhi'
                                showSearch={true}
                                optionFilterProp="children"
                                placeholder={selectedLanguage?.placeHolder_SelectTeacher}
                            >
                                {buUsers?.length ?
                                    buUsers?.map((v, i) => {
                                        return (
                                            <Option value={v?._id}>
                                                {`${v?.fullName}`}
                                            </Option>
                                        )
                                    }) : null}
                            </Select>
                        </Form.Item>
                    </div>}
                <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                    <Form.Item
                        name='file'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_IMG}</p>}
                    >
                        <Upload
                            name='file'
                            multiple={false}
                            beforeUpload={() => false}
                            accept='image/png, image/jpeg'
                            onChange={normFile}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>{selectedLanguage?.text_ClickToUpload}</Button>
                        </Upload>
                    </Form.Item>
                </div>
            </div>



            <Form.Item>
                <Button
                    type="primary"
                    style={{ height: '40px', width: '100%', marginTop: '10px' }}
                    htmlType='submit'
                    loading={loading}
                >
                    {selectedLanguage?.btn_CreateCoupons}
                </Button>
            </Form.Item>
        </Form>
    )
}
export default AddCoupons