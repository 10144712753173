import { FileOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Switch, Upload } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DragDropQuiz, PreviewMaths, PreviewStudentQuestion } from '../../../Components'
import { ADMIN } from '../../../utils/apis'
import { adminData, checkSelectOption, errorMessage, generalData, successMessage, teacherData } from '../../../utils/helpers'
const { Option } = Select

const AddQuestions = (props) => {
    const { onCancel, user, copyQuestion, setCopyQuestion, openQuestions, selectedLanguage, lanKorCondition, generalActions, userActions, adminActions } = props
    const tutions = useSelector(state => state?.generalReducer?.tutions || [])
    const chapters = useSelector(state => state?.generalReducer?.chapters || [])
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const languages = useSelector(state => state?.generalReducer?.adminLanguages || [])
    const [form] = Form.useForm()
    const [ansType, setAnsType] = useState(copyQuestion?.answerType || 'inputNumber')
    const [ansList, setansList] = useState(copyQuestion?.answerList || '')
    const [loading, setLoading] = useState(false)
    const [allChapters, setAllChapters] = useState(chapters || [])
    const [subjectName, setSubjectsName] = useState(copyQuestion?.subject?.subjectName || '')
    const [chapterValue, setchapterValue] = useState(/* copyQuestion.chapter || */ {})
    const [paragraphValue, setParagraphValue] = useState(/* copyQuestion.paragraph || */ {})
    const [fileList, setFileList] = useState(copyQuestion?.file?.url ? [copyQuestion?.file] : [])
    const [titleFileList, setTitleFileList] = useState(copyQuestion?.titleFile?.url ? [copyQuestion?.titleFile] : [])
    const [questionType, setQuestionType] = useState(copyQuestion?.questionType || 'test')
    const [another, setAnother] = useState(false)
    const [videos, setVideos] = useState([])
    const [formValues, setFormValues] = useState(copyQuestion || {})
    const [dragFileList, setDragFileList] = useState(copyQuestion?.dragFile?.url ? [copyQuestion?.dragFile] : [])
    const [previewImage, setPreviewImage] = useState(copyQuestion?.dragFile?.url || '');
    const [spots, setSpots] = useState(((copyQuestion?.answerType === 'dragDrop' || copyQuestion?.answerType === 'dragDropSequence') && copyQuestion?.answerList) || [])
    const [previewQuestion, setPreviewQuestion] = useState(false)
    const [cardsData, setCardsData] = useState([])

    useEffect(() => {
        generalData.getAllChapters(generalActions)
        generalData?.getAllSubjects(generalActions, user?.verify)
        generalData.getAllLanguages(generalActions)

        getVideoBySub(copyQuestion?.subject?._id)

        let obj = {
            userId: user?._id,
            paragraph: copyQuestion?.paragraph?.paragraphName,
            chapter: copyQuestion?.chapter?.chapterName
        }

        getCardWithChapterAndPara(obj)

        if (copyQuestion?.title) {
            setchapterValue({ chapterName: copyQuestion?.chapter?.chapterName, chapterId: copyQuestion?.chapter?._id })
            setParagraphValue({ paragraphName: copyQuestion?.paragraph?.paragraphName, paragraphId: copyQuestion?.paragraph?._id || null })

            if (ansType === 'radio') {
                form.setFieldsValue({
                    answerList2: ansList
                })
            } else if (ansType === 'multiple') {
                form.setFieldsValue({
                    answerList3: ansList
                })
            } else if (ansType === 'single') {
                form.setFieldsValue({
                    answerList4: ansList
                })
            } else if (ansType === 'hotspot') {
                form.setFieldsValue({
                    answerList5: ansList
                })
            } else if (ansType === 'rating') {
                form.setFieldsValue({
                    answerList6: ansList
                })
            } else if (ansType === 'thesis') {
                form.setFieldsValue({
                    answerList7: ansList
                })
            } else if (ansType === 'inputNumber') {
                form.setFieldsValue({
                    numAnswerList: ansList?.slice(0, 1)
                })
            }
        }
    }, [])

    var error1
    var error2
    const normFile = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return e?.fileList[0];
    }
    const normFile2 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setFileList(e?.fileList)
    }
    const normFile3 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }

        return setTitleFileList(e?.fileList);
    }
    const normDragFile = (e) => {

        new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(e?.file);

            img.onload = () => {
                const { width, height } = img;
                if (width > height) {
                    return resolve(e?.file);
                } else {
                    errorMessage(selectedLanguage?.txt_Pleaseuploadalandscapeimage)
                    form.setFieldsValue({
                        spots: []
                    })
                    setDragFileList([])
                    setSpots([])
                    setPreviewImage('')
                    return /* setDragFileList([]) */
                }
            }
        })


        if (e?.fileList?.length && !e?.file?.type?.includes('image')) {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setDragFileList(e?.fileList);
    }
    const onChange = e => {
        setAnsType(e.target.value)
        if (copyQuestion?.answerType === 'dragDrop' || copyQuestion?.answerType === 'dragDropSequence') {
            form.setFieldsValue({
                spots: copyQuestion?.answerList?.length ? copyQuestion?.answerList?.map((v) => {
                    return {
                        correct: v?.correct,
                        x: Number(v?.x.slice(0, -1)),
                        y: Number(v?.y.slice(0, -1)),
                    }
                }) : []
            })
            let newArray = copyQuestion?.answerList
            newArray = newArray.map((v, i) => {
                return {
                    id: i,
                    x: `${v?.x}%`,
                    y: `${v?.y}%`,
                    correct: v?.correct
                }
            })
            setSpots(newArray)
        } else if (ansType === 'dragDrop' || ansType === 'dragDropSequence') {
            form.setFieldsValue({
                spots: []
            })
            setSpots([])
        }
    }
    const getTopicId = (value) => {
        return allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)[0]?.topics?.filter((x) => x?.topicName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterId = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getParagraphId = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterName = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?.chapterName
    }
    const getParagraphName = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?.paragraphName
    }

    const onchangeTopic = () => {
        let obj = {
            userId: user?._id,
            paragraph: paragraphValue?.paragraphName,
            chapter: chapterValue?.chapterName,
        }
        getCardWithChapterAndPara(obj)
    }

    const getCardWithChapterAndPara = (e) => {
        if (!e?.chapter || !e?.paragraph) {
            return true
        }
        axios?.post(ADMIN?.GET_CARDS_WITH_VIDEO_AND_PARA, e)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setCardsData(data?.data)
                }
            })
    }
    const onFinish = async (values) => {

        let tempArr = values?.topics?.map(element => element?.toLowerCase())
        let cond = _.uniq(tempArr)?.length !== tempArr?.length
        values.questionType = questionType

        if (cond && !user?.userType) {
            return errorMessage(selectedLanguage?.errorMessage_Topicsmustbeunique)
        }
        setLoading(true)
        values.answerType = ansType
        if (ansType === 'input') {
            values.answerList = values?.answerList1
        }
        if (ansType === 'inputNumber') {
            values.answerList = values?.numAnswerList
        }
        else if (ansType === 'radio') {
            values.answerList = values?.answerList2
        }
        else if (ansType === 'multiple') {
            values.answerList = values?.answerList3
        }
        else if (ansType === 'dragDrop' || ansType === 'dragDropSequence') {
            values.answerList = spots
        }
        else if (ansType === 'hotspot') {
            values.answerList = values?.answerList5
        }
        else if (ansType === 'rating') {
            values.answerList = values?.answerList6
        }
        else if (ansType === 'thesis') {
            values.answerList = values?.answerList7
        }
        else {
            values.answerList = values?.answerList4
        }

        let checkCorrectAnsCond = _.find(values?.answerList, { 'isTrue': true })
        if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.questionType === 'test' && values?.answerType !== 'input' && values?.answerType !== 'inputNumber' && values?.answerType !== 'upload' && !checkCorrectAnsCond) {
            setLoading(false)
            return errorMessage(selectedLanguage?.errorMessage_PleaseSelectaCorrectAnswer)
        }

        if ((ansType === 'dragDrop' || ansType === 'dragDropSequence') && (!spots.length || !dragFileList?.length)) {
            setLoading(false)
            return errorMessage(selectedLanguage?.errorMessage_PleaseSelectaCorrectAnswer)
        }
        values.userId = user?._id
        values.point = Number(values?.point || 0)
        values.chapter = chapterValue
        values.paragraph = paragraphValue

        if (values?.topics?.length > 0) {
            values.topic1 = { topicName: values?.topics[0], topicId: getTopicId(values?.topics[0]) || null }
        }
        if (values?.topics?.length > 1) {
            values.topic2 = { topicName: values?.topics[1], topicId: getTopicId(values?.topics[1]) || null }
        }
        if (values?.topics?.length > 2) {
            values.topic3 = { topicName: values?.topics[2], topicId: getTopicId(values?.topics[2]) || null }
        }

        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

                })
        } else if (fileList[0]?.url) {
            let fileObj = {
                mediaType: fileList[0]?.mediaType,
                name: fileList[0]?.name,
                url: fileList[0]?.perUrl,
            }
            values.file = fileObj
        }
        let dragDropType = ansType === 'dragDropSequence' || ansType === 'dragDrop'

        if (dragFileList[0]?.originFileObj && dragDropType) {
            let formData = new FormData()
            formData.append('file', dragFileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.dragFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

                })
        } else if (dragFileList[0]?.url && dragDropType) {
            let fileObj = {
                mediaType: dragFileList[0]?.mediaType,
                name: dragFileList[0]?.name,
                url: dragFileList[0]?.perUrl,
            }
            values.dragFile = fileObj
        }

        if (titleFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', titleFileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.titleFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
                })
        } else if (titleFileList[0]?.url) {
            let fileObj = {
                mediaType: titleFileList[0]?.mediaType,
                name: titleFileList[0]?.name,
                url: titleFileList[0]?.perUrl,
            }
            values.titleFile = fileObj
        }

        for (var i = 0; i < values?.answerList?.length; i++) {
            if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.answerList[i]?.file?.originFileObj) {
                let fileData = new FormData()
                fileData.append('file', values?.answerList[i]?.file?.originFileObj)
                await axios.post(ADMIN.GET_FILE_URL, fileData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            values.answerList[i].file = data?.data
                        } else {
                            return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

                    })
            } else if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.answerList[i]?.file?.url) {
                let fileObj = {
                    mediaType: values?.answerList[i]?.file?.mediaType,
                    name: values?.answerList[i]?.file?.name,
                    url: values?.answerList[i]?.file?.perUrl,
                }
                values.answerList[i].file = fileObj 
            }
        }

        if (!user?.ownerType) {
            values.isAdmin = true
        }
        
        await axios.post(ADMIN.CREATE_QUESTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    setCopyQuestion({})
                    generalData.getAllChapters(generalActions)
                    generalData?.getAllSubjects(generalActions, user?.verify)
                    generalData.getAllLanguages(generalActions)
                    if (user?.ownerType) {
                        teacherData.getMyQuestions(userActions, user?._id)
                    } else {
                        adminData.getAdminQuestions(adminActions, user?._id)
                    }

                    if (another) {
                        setFileList([])
                        form.setFieldsValue({
                            point: 0,
                            question: null,
                            file: undefined,
                            answerList2: null,
                            answerList3: null,
                            answerList4: null,
                            answerList5: null
                        })

                        generalData.getAllChapters(generalActions)
                    } else {
                        form.resetFields()
                        openQuestions()
                        onCancel()
                    }
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const optionsTopics = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)?.map((x) => x?.topics)?.flat()?.map((z) => {
        return {
            value: z?.topicName,
            id: z?._id
        }
    })

    const optionsParagraph = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph).flat().map((x) => {
        return {
            value: x?.paragraphName,
            id: x?._id,
        }
    })

    const optionsChapter = allChapters?.map((v, i) => {
        return {
            value: v?.chapterName
        }
    })

    const onSearchChapter = (searchText) => {
        if (getChapterId(searchText)) {
            setchapterValue({ chapterName: getChapterName(searchText), chapterId: getChapterId(searchText) })
        } else {
            setchapterValue({ chapterName: searchText, chapterId: getChapterId(searchText) || null })
        }

        form.setFieldsValue({
            paragraph: null,
            topics: [null]
        })
        setParagraphValue({})
    }

    const onSelectChapter = (data) => {
        setchapterValue({
            chapterName: allChapters?.filter((v, i) => v?.chapterName === data)[0]?.chapterName,
            chapterId: allChapters?.filter((v, i) => v?.chapterName === data)[0]?._id,
        })
        form.setFieldsValue({
            paragraph: null,
            topics: [null]
        })
        setParagraphValue({})

    }

    const onSearchParagraph = (searchText) => {
        setParagraphValue({ paragraphName: searchText, paragraphId: null })
        if (getParagraphId(searchText)) {
            setParagraphValue({ paragraphName: getParagraphName(searchText), paragraphId: getParagraphId(searchText) || null })
        } else {
            setParagraphValue({ paragraphName: searchText, paragraphId: null })
        }
        form.setFieldsValue({
            topics: [null]
        })
    }

    const onSelectParagraph = (data) => {
        setParagraphValue({
            paragraphName: data,
            paragraphId: optionsParagraph?.filter((v) => v?.value === data)[0]?.id,
        })
        form.setFieldsValue({
            topics: [null]
        })

    }

    const onChangeQuestionType = (e) => {
        setQuestionType(e?.target?.value)
    }

    const onChangeSubject = (id) => {
        getVideoBySub(id)
        let subjectName = subjects?.find((v) => v?._id === id)
        setSubjectsName(subjectName?.subjectName)
        let filteredChapters = chapters?.filter((v) => v?.subject === id?.toString())
        setAllChapters(filteredChapters)
        form.setFieldsValue({
            videos: []
        })
    }

    const getVideoBySub = (id) => {
        axios.get(`${ADMIN?.GET_VIDEO_BY_SUB_ID}/${id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setVideos(data?.data || [])
                }
                else {
                    setVideos(data?.data || [])
                }
            })
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handlePreview = async (file) => {

        new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file?.file);

            img.onload = () => {
                const { width, height } = img;
                if (width > height) {
                } else {
                    return setPreviewImage('')
                }
            }
        })

        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        setPreviewImage(file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }

    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }

    const handleSpots = () => {
        let newArray = form.getFieldValue('spots')
        newArray = newArray.map((v, i) => {
            return {
                id: i,
                x: `${v?.x}%`,
                y: `${v?.y}%`,
                correct: v?.correct
            }
        })
        setSpots(newArray)
    }

    console.log('copyQuestion', copyQuestion)

    const quesData = () => {
        let allData = form.getFieldsValue()

        allData.spots = allData?.spots?.map((v, i) => {
            return {
                id: i,
                x: v?.x + '%',
                y: v?.y + '%',
                correct: v?.correct
            }
        })

        allData.titleFile = titleFileList?.length ? (titleFileList?.[0]?.preview || titleFileList?.[0]?.url) : null
        allData.file = fileList?.length ? (fileList?.[0]?.preview || fileList?.[0]?.url) : null
        allData.answerType = ansType

        allData.answerList = (allData?.answerList1?.length && allData?.answerList1) || (allData?.answerList2?.length && allData?.answerList2) || (allData?.answerList3?.length && allData?.answerList3) || (allData?.answerList4?.length && allData?.answerList4) || (allData?.answerList5?.length && allData?.answerList5) || (allData?.answerList6?.length && allData?.answerList6) || (allData?.answerList7?.length && allData?.answerList7) || (allData?.numAnswerList?.length && allData?.numAnswerList)

        allData.dragFile = previewImage

        return allData
    }
    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'flex-end', }} >
                <Button
                    type='primary'
                    style={{
                        padding: '0px 20px 0px 20px',
                        background: '#1eb2a6',
                        color: 'white',
                        border: 'none',
                        marginTop: '10px',
                        borderRadius: 6
                    }}
                    onClick={() => { onCancel(); setCopyQuestion({}) }}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </span>

            <div className='crt-question-div'>

                <p className='crt-qutin-heading'>{selectedLanguage?.Button_CreateQuestion} </p>
                {
                    previewQuestion && <PreviewStudentQuestion subjectName={subjectName} onCancelPreview={() => setPreviewQuestion(false)} previewQuestion={previewQuestion} quesData={quesData} {...props} />
                }
                <Form
                    form={form}
                    onFinish={onFinish}
                    name='QuestionsForm'
                    layout='vertical'
                    requiredMark={true}
                    onValuesChange={(e) => {
                        setFormValues(form.getFieldsValue())
                    }}
                >
                    <Form.Item
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.requiredMessage_pleaseEnteraTitle,
                            }
                        ]}
                        label={selectedLanguage?.title_Title}
                        required
                        initialValue={copyQuestion?.title}
                    >
                        <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.title_Title} />
                    </Form.Item>
                    <Form.Item
                        label={selectedLanguage?.title_FileforTitle}
                        name='titleFile'
                        style={{ width: '100%' }}
                        valuePropName='file'
                        getValueFromEvent={normFile3}
                    >
                        <Upload
                            className='questionFileUpload'
                            multiple={false}
                            maxCount={1}
                            beforeUpload={() => false}
                            fileList={titleFileList}
                            onChange={handlePreview2}
                            accept='image/*, video/*, .pdf'
                            action='/upload.do'
                            style={{ width: '100%' }}
                        >
                            <Button type='primary'
                                style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                            <br />
                        </Upload>
                    </Form.Item>



                    <Form.Item
                        name='time'
                        label={selectedLanguage?.label_Time}
                        initialValue={copyQuestion?.time || 8}
                    >
                        <InputNumber
                            controls={false}
                            addonAfter='s'
                            style={{ width: '100%', maxWidth: 200 }}
                            placeholder={selectedLanguage?.txt_Time_inSeconds}
                            type='number'
                            max={300}
                            min={8}
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        name='fixTime'
                        valuePropName='checked'
                        initialValue={copyQuestion?.fixTime || false}
                    >
                        <Checkbox>
                            {'Fix time'}
                        </Checkbox>
                    </Form.Item>

                    {/* <Form.Item
                            name='questionType'
                            label={selectedLanguage?.label_QuestionType}
                            required
                            initialValue={questionType}
                        >
                            <Radio.Group disabled={copyQuestion?.questionType} value={questionType} onChange={onChangeQuestionType} defaultValue={questionType}>
                                <Radio.Button value='questionnaire'>{selectedLanguage?.Button_Questionnaire}</Radio.Button>
                                <Radio.Button value='test'>{selectedLanguage?.Button_Test}</Radio.Button>
                            </Radio.Group>
                        </Form.Item> */}

                    {questionType === 'test' &&
                        <Form.Item
                            name='point'
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.errorMessage_Pointsarerequired,
                                }
                            ]}
                            label={selectedLanguage?.label_Points}
                            required
                            initialValue={copyQuestion?.point || 0}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                placeholder='2'
                                type='number'
                                max={1000}
                                min={0}
                                step={1}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name='subject'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectSubject,
                            },
                        ]}
                        initialValue={copyQuestion?.subject?._id}
                    >
                        <Select
                            onChange={onChangeSubject}
                            showArrow
                            allowClear
                            showSearch
                            placeholder={selectedLanguage?.label_SelectSubject}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {subjects.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={`subject${i}`}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='videos'
                        label={selectedLanguage?.txt_Selectvideos}
                        initialValue={copyQuestion?.videos?.map((v) => v?._id?.toString()) || []}
                    >
                        <Select
                            mode='multiple'
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.txt_Select_videos_here}
                            optionFilterProp='children'
                        // filterOption={(input, option) =>
                        //     option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                        // }
                        >
                            {videos.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={`videos${i}`}>{v?.name}{/* ||{v?.file?.name} */}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='question'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_questionerequired,
                            }
                        ]}
                        label={selectedLanguage?.title_Question}
                        required
                        initialValue={copyQuestion?.question}
                    >
                        <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.ttxt_WhatiscapitalofNeatherland} />
                    </Form.Item>
                    {
                        subjectName?.toLowerCase()?.includes('math') && formValues?.question && <PreviewMaths sentence={formValues?.question || ' '} />
                    }
                    <Form.Item
                        label={selectedLanguage?.File}
                        name='file'
                        style={{ width: '100%' }}
                        valuePropName='file'
                        getValueFromEvent={normFile2}
                    >
                        <Upload
                            className='questionFileUpload'
                            multiple={false}
                            maxCount={1}
                            beforeUpload={() => false}
                            fileList={fileList}
                            onChange={handlePreview2}
                            accept='image/*, video/*, .pdf'

                            action='/upload.do'
                            style={{ width: '100%' }}
                        >
                            <Button type='primary' style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                            <br />
                        </Upload>
                    </Form.Item>

                    {questionType === 'test'
                        &&
                        <Form.Item
                            name='index'
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_enteryourindexnumber,
                                }
                            ]}
                            label={selectedLanguage?.txtx_index}
                            initialValue={copyQuestion?.index || null}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder='2.1.1'
                            // type='number'
                            // max={1000}
                            // min={0}
                            // step={1}
                            />
                        </Form.Item>}

                    <Form.Item label={selectedLanguage?.label_SelectTypeofAnswer}>
                        <Radio.Group onChange={onChange} value={ansType}>
                            {questionType !== 'test' && <Radio value={'input'}>{selectedLanguage?.radioLabel_Input}</Radio>}
                            <Radio value={'inputNumber'}>{selectedLanguage?.ttx_NumericInput}</Radio>
                            <Radio value={'radio'}>{selectedLanguage?.radioLabel_RadioOptions}</Radio>
                            <Radio value={'multiple'}>{selectedLanguage?.radioLabel_SelectMultipleoptions}</Radio>
                            <Radio value={'single'}>{selectedLanguage?.radioLabel_Selectsingleoptions}</Radio>
                            {questionType !== 'test' && <Radio value={'upload'}>{selectedLanguage?.radioLabel_Uploadoption}</Radio>}
                            <Radio value={'dragDrop'}>{selectedLanguage?.txt_Drag_and_Drop}</Radio>
                            <Radio value={'dragDropSequence'}>{selectedLanguage?.txtx_Drag_and_Drop_Sequence}</Radio>
                            <Radio value={'hotspot'}>{selectedLanguage?.txt_Hotspot}</Radio>
                            {questionType !== 'test' && <Radio value={'rating'}>{selectedLanguage?.txtx_rating}</Radio>}
                            <Radio value={'thesis'}>{selectedLanguage?.txt_Thesis}</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {questionType === 'test' ?
                        (
                            ansType === 'input' || ansType === 'upload' ? null :
                                ansType === 'inputNumber' ?
                                    <Form.List
                                        name='numAnswerList'
                                        initialValue={ansList?.length ? ansList.slice(0, 1) : [{ isTrue: true, option: 0, explaination: '' }]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, name, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={`inputNumber${field.key}`}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'isTrue']}
                                                                valuePropName='checked'
                                                                noStyle
                                                                initialValue={true}
                                                            >
                                                                <Switch disabled style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'option']}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                noStyle
                                                            >
                                                                <InputNumber type='number' placeholder='Option' style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </div>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'explaination']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                }
                                                            ]}
                                                            noStyle
                                                        >
                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                        </Form.Item>
                                                        {
                                                            subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('numAnswerList') ? 'numAnswerList' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('numAnswerList') ? 'numAnswerList' : 'answerList']?.[name]?.explaination || ' '} />
                                                        }
                                                    </Form.Item>
                                                ))}
                                            </>
                                        )}
                                    </Form.List> :
                                    ansType === 'radio' ?
                                        <Form.List
                                            name='answerList2'
                                            rules={[
                                                {
                                                    validator: async (_, names) => {
                                                        if (!names || names.length < 2) {
                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                        }
                                                    }
                                                }
                                            ]}
                                            initialValue={ansList}
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <Form.Item
                                                            required={false}
                                                            key={`answerList2${field.key}`}
                                                        >
                                                            <div style={{ display: 'flex' }}>
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'isTrue']}
                                                                    valuePropName='checked'
                                                                    noStyle
                                                                    initialValue={false}
                                                                >
                                                                    <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'option']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                </Form.Item>
                                                            </div>
                                                            {
                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option || ' '} />
                                                            }
                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'explaination']}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        whitespace: true,
                                                                        message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                    },
                                                                ]}
                                                                noStyle
                                                            >
                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                            </Form.Item>
                                                            {
                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.explaination || ' '} />
                                                            }
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'file']}
                                                                        style={{ width: '100%' }}
                                                                        valuePropName='file'
                                                                        getValueFromEvent={normFile}

                                                                    >
                                                                        <Upload
                                                                            className='questionFileUpload'
                                                                            multiple={false}
                                                                            maxCount={1}
                                                                            beforeUpload={() => false}
                                                                            accept='image/*, video/*, .pdf'
                                                                            onChange={handlePreview2}
                                                                            action='/upload.do'
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                            <br />
                                                                        </Upload>
                                                                    </Form.Item>
                                                                    {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                </div>
                                                                &nbsp;
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        style={{ marginTop: 8 }}
                                                                        className='dynamic-delete-button'
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type='dashed'
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {selectedLanguage?.button_AddField}
                                                        </Button>
                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List> :
                                        ansType === 'multiple' ?
                                            <Form.List
                                                name='answerList3'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                            }
                                                        }
                                                    }
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={`answerList3${field.key}`}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'isTrue']}
                                                                        valuePropName='checked'
                                                                        noStyle
                                                                        initialValue={false}
                                                                    >
                                                                        <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                </div>
                                                                {
                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option || ' '} />
                                                                }
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'explaination']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                </Form.Item>
                                                                {
                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.explaination || ' '} />
                                                                }
                                                                <div style={{ display: 'flex' }}>
                                                                    <div style={{ width: '100%' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'file']}
                                                                            style={{ width: '100%' }}
                                                                            valuePropName='file'
                                                                            getValueFromEvent={normFile}

                                                                        >
                                                                            <Upload
                                                                                className='questionFileUpload'
                                                                                multiple={false}
                                                                                maxCount={1}
                                                                                beforeUpload={() => false}
                                                                                accept='image/*, video/*, .pdf'
                                                                                onChange={handlePreview2}

                                                                                action='/upload.do'
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                <br />
                                                                            </Upload>
                                                                        </Form.Item>
                                                                        {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                    </div>
                                                                    &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            style={{ marginTop: 8 }}
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {selectedLanguage?.button_AddField}
                                                            </Button>
                                                            <Form.ErrorList errors={errors} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> :
                                            (ansType === 'dragDrop' || ansType === 'dragDropSequence') ?
                                                <Form.Item
                                                    // label={selectedLanguage?.File}
                                                    name='file'
                                                    style={{ width: '100%' }}
                                                    valuePropName='file'
                                                    getValueFromEvent={normDragFile}
                                                >
                                                    <Upload
                                                        className='questionFileUpload'
                                                        multiple={false}
                                                        maxCount={1}
                                                        beforeUpload={() => false}
                                                        fileList={dragFileList}
                                                        onChange={handlePreview}
                                                        accept='image/*'
                                                        action='/upload.do'
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                                                        <br />
                                                    </Upload>
                                                </Form.Item> :
                                                ansType === 'hotspot' ?
                                                    <Form.List
                                                        name='answerList5'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {

                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <Form.Item
                                                                        // required={false}
                                                                        key={`answerList5${field.key}`}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>
                                                                            <div style={{ width: '100%' }}>
                                                                                <Form.Item
                                                                                    required
                                                                                    {...field}
                                                                                    name={[name, 'file']}
                                                                                    style={{ width: '100%' }}
                                                                                    valuePropName='file'
                                                                                    getValueFromEvent={normFile}
                                                                                    rules={[
                                                                                        {
                                                                                            validator: async (_, names) => {

                                                                                                if (!names) {
                                                                                                    return Promise.reject(new Error('Please Upload file'))
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Upload
                                                                                        className='questionFileUpload'
                                                                                        multiple={false}
                                                                                        maxCount={1}
                                                                                        beforeUpload={() => false}
                                                                                        accept='image/*'
                                                                                        onChange={handlePreview2}
                                                                                        action='/upload.do'
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                        <br />
                                                                                    </Upload>
                                                                                </Form.Item>
                                                                                {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}

                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'explaination']}
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            whitespace: true,
                                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                        },
                                                                                    ]}
                                                                                    noStyle
                                                                                >
                                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                                </Form.Item>
                                                                                {
                                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination || ' '} />
                                                                                }
                                                                            </div>
                                                                            &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    style={{ marginTop: 8 }}
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}

                                                                        </div>
                                                                    </Form.Item>
                                                                ))}
                                                                {fields.length < 4 &&
                                                                    <Form.Item>
                                                                        <Button
                                                                            type='dashed'
                                                                            onClick={() => add()}
                                                                            style={{ width: '100%' }}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {selectedLanguage?.button_AddField}
                                                                        </Button>
                                                                        <Form.ErrorList errors={errors} />
                                                                    </Form.Item>}
                                                            </>
                                                        )}
                                                    </Form.List> :
                                                    ansType === 'thesis' ?
                                                        <Form.List
                                                            name='answerList7'
                                                            rules={[
                                                                {
                                                                    validator: async (_, names) => {
                                                                        if (!names || names.length < 2) {
                                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                        }
                                                                    }
                                                                }
                                                            ]}
                                                            initialValue={ansList}
                                                        >
                                                            {(fields, { add, remove }, { errors }) => (
                                                                <>
                                                                    <Form.Item
                                                                        required={false}
                                                                        key={`answerList7${0}`}
                                                                        label={`${selectedLanguage?.txt_Thesis} 1`}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                name={[0, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch
                                                                                    style={{ marginRight: 5, marginTop: 5 }}
                                                                                    checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                name={[0, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: `${selectedLanguage?.erorr_messae_Please_enter_txt_Thesis} ${1}`,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`${selectedLanguage?.txt_Thesis} ${1}`} style={{ width: '100%' }} />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        required={false}
                                                                        key={`answerList7${1}`}
                                                                        label={`${selectedLanguage?.txt_Thesis} 2`}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                name={[1, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch
                                                                                    style={{ marginRight: 5, marginTop: 5 }}
                                                                                    checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                name={[1, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: `${selectedLanguage?.erorr_messae_Please_enter_txt_Thesis} ${2}`,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`${selectedLanguage?.txt_Thesis} ${2}`} style={{ width: '100%' }} />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </Form.Item>
                                                                </>
                                                            )}
                                                        </Form.List> :
                                                        <Form.List
                                                            name='answerList4'
                                                            rules={[
                                                                {
                                                                    validator: async (_, names) => {
                                                                        if (!names || names.length < 2) {
                                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                        }
                                                                    }
                                                                }
                                                            ]}
                                                            initialValue={ansList}
                                                        >
                                                            {(fields, { add, remove }, { errors }) => (
                                                                <>
                                                                    {fields.map((field, name, index) => (
                                                                        <Form.Item
                                                                            required={false}
                                                                            key={`answerList4${field.key}`}
                                                                        >
                                                                            <div style={{ display: 'flex' }}>
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'isTrue']}
                                                                                    valuePropName='checked'
                                                                                    noStyle
                                                                                    initialValue={false}
                                                                                >
                                                                                    <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'option']}
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            whitespace: true,
                                                                                            message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                                        },
                                                                                    ]}
                                                                                    noStyle
                                                                                >
                                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                                </Form.Item>
                                                                            </div>
                                                                            {
                                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option || ' '} />
                                                                            }
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'explaination']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                            </Form.Item>
                                                                            {
                                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.explaination || ' '} />
                                                                            }
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ width: '100%' }}>
                                                                                    <Form.Item
                                                                                        {...field}
                                                                                        name={[name, 'file']}
                                                                                        style={{ width: '100%' }}
                                                                                        valuePropName='file'
                                                                                        getValueFromEvent={normFile}

                                                                                    >
                                                                                        <Upload
                                                                                            className='questionFileUpload'
                                                                                            multiple={false}
                                                                                            maxCount={1}
                                                                                            beforeUpload={() => false}
                                                                                            accept='image/*, video/*, .pdf'
                                                                                            onChange={handlePreview2}

                                                                                            action='/upload.do'
                                                                                            style={{ width: '100%' }}
                                                                                        >
                                                                                            <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                            <br />
                                                                                        </Upload>
                                                                                    </Form.Item>
                                                                                    {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                                </div>
                                                                                &nbsp;
                                                                                {fields.length > 1 ? (
                                                                                    <MinusCircleOutlined
                                                                                        style={{ marginTop: 8 }}
                                                                                        className='dynamic-delete-button'
                                                                                        onClick={() => remove(field.name)}
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Item>
                                                                    ))}
                                                                    <Form.Item>
                                                                        <Button
                                                                            type='dashed'
                                                                            onClick={() => add()}
                                                                            style={{ width: '100%' }}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {selectedLanguage?.button_AddField}
                                                                        </Button>
                                                                        <Form.ErrorList errors={errors} />
                                                                    </Form.Item>
                                                                </>
                                                            )}
                                                        </Form.List>
                        ) :
                        (
                            ansType === 'input' || ansType === 'inputNumber' ? null :
                                ansType === 'upload' ? ''
                                    : ansType === 'radio' ?
                                        <Form.List
                                            name='answerList2'
                                            rules={[
                                                {
                                                    validator: async (_, names) => {
                                                        if (!names || names.length < 2) {
                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                        }
                                                    },
                                                },
                                            ]}
                                            initialValue={ansList}
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (

                                                        <span key={`answerList2${field.key}`}>
                                                            <Form.Item
                                                                // label={index === 0 ? 'options' : null}
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'option']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '90%' }} />
                                                                </Form.Item> &nbsp;
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        className='dynamic-delete-button'
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </Form.Item>
                                                            {
                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option || ' '} />
                                                            }
                                                        </span>
                                                    ))}

                                                    <Form.Item>
                                                        <Button
                                                            type='dashed'
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {selectedLanguage?.button_AddField}
                                                        </Button>

                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List> : ansType === 'multiple' ?
                                            <Form.List
                                                name='answerList3'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                            }
                                                        },
                                                    },
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors = error1 }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <span key={`answerList3${field.key}`}>
                                                                <Form.Item
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '90%' }} />
                                                                    </Form.Item> &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </Form.Item>
                                                                {
                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option || ' '} />
                                                                }
                                                            </span>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {selectedLanguage?.button_AddField}
                                                            </Button>

                                                            <Form.ErrorList errors={error1} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> :
                                            (ansType === 'dragDrop' || ansType === 'dragDropSequence') ?
                                                <Form.Item
                                                    // label={selectedLanguage?.File}
                                                    name='file'
                                                    style={{ width: '100%' }}
                                                    valuePropName='file'
                                                    getValueFromEvent={normDragFile}
                                                >
                                                    <Upload
                                                        className='questionFileUpload'
                                                        multiple={false}
                                                        maxCount={1}
                                                        beforeUpload={() => false}
                                                        fileList={dragFileList}
                                                        onChange={handlePreview}
                                                        accept='image/*'
                                                        action='/upload.do'
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                                                        <br />
                                                    </Upload>
                                                </Form.Item> :
                                                ansType === 'hotspot' ?
                                                    <Form.List
                                                        name='answerList5'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {

                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <Form.Item
                                                                        // required={false}
                                                                        key={`answerList5${field.key}`}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>
                                                                            <div style={{ width: '100%' }}>
                                                                                <Form.Item
                                                                                    required
                                                                                    {...field}
                                                                                    name={[name, 'file']}
                                                                                    style={{ width: '100%' }}
                                                                                    valuePropName='file'
                                                                                    getValueFromEvent={normFile}
                                                                                    rules={[
                                                                                        {
                                                                                            validator: async (_, names) => {

                                                                                                if (!names) {
                                                                                                    return Promise.reject(new Error('Please Upload file'))
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Upload
                                                                                        className='questionFileUpload'
                                                                                        multiple={false}
                                                                                        maxCount={1}
                                                                                        beforeUpload={() => false}
                                                                                        accept='image/*'
                                                                                        onChange={handlePreview2}
                                                                                        action='/upload.do'
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                        <br />
                                                                                    </Upload>
                                                                                </Form.Item>
                                                                                {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}

                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'explaination']}
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            whitespace: true,
                                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                        },
                                                                                    ]}
                                                                                    noStyle
                                                                                >
                                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                                </Form.Item>
                                                                                {
                                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination || ' '} />
                                                                                }
                                                                            </div>
                                                                            &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    style={{ marginTop: 8 }}
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}

                                                                        </div>
                                                                    </Form.Item>
                                                                ))}
                                                                {fields.length < 4 &&
                                                                    <Form.Item>
                                                                        <Button
                                                                            type='dashed'
                                                                            onClick={() => add()}
                                                                            style={{ width: '100%' }}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {selectedLanguage?.button_AddField}
                                                                        </Button>
                                                                        <Form.ErrorList errors={errors} />
                                                                    </Form.Item>}
                                                            </>
                                                        )}
                                                    </Form.List> :
                                                    ansType === 'rating' ?
                                                        <Form.List
                                                            name='answerList6'
                                                            rules={[
                                                                {
                                                                    validator: async (_, names) => {
                                                                        if (!names || names.length < 2) {
                                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                            initialValue={ansList}
                                                        >
                                                            {(fields, { add, remove }, { errors }) => (
                                                                <span key={`answerList6${1}`}>
                                                                    <Form.Item
                                                                        label={`${selectedLanguage?.txt_Value} ${1}`}
                                                                        required={false}
                                                                        key={1}
                                                                    >
                                                                        <Form.Item
                                                                            // {...field}
                                                                            name={[0, 'option']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: `${selectedLanguage?.erorr_messae_Please_enter_vale} ${1}`,
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`Value  ${1}`} style={{ width: '90%' }} />
                                                                        </Form.Item> &nbsp;
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        label={`${selectedLanguage?.txt_Value} ${2}`}
                                                                        required={false}
                                                                        key={2}
                                                                    >
                                                                        <Form.Item
                                                                            // {...field}
                                                                            name={[1, 'option']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: `${selectedLanguage?.erorr_messae_Please_enter_vale} ${1}`,
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`Value  ${1}`} style={{ width: '90%' }} />
                                                                        </Form.Item> &nbsp;
                                                                    </Form.Item>
                                                                </span>
                                                            )}
                                                        </Form.List> : ansType === 'thesis' ?
                                                            <Form.List
                                                                name='answerList7'
                                                                rules={[
                                                                    {
                                                                        validator: async (_, names) => {
                                                                            if (!names || names.length < 2) {
                                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                                initialValue={ansList}
                                                            >
                                                                {(fields, { add, remove }, { errors = error1 }) => (
                                                                    <>
                                                                        <Form.Item
                                                                            label={`${selectedLanguage?.txt_Thesis} ${1}`}
                                                                            required={false}
                                                                            key={1}
                                                                        >
                                                                            <Form.Item
                                                                                // {...field}
                                                                                name={[0, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: `${selectedLanguage?.erorr_messae_Please_enter_txt_Thesis} ${1}`,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`${selectedLanguage?.txt_Thesis} ${1}`} style={{ width: '90%' }} />
                                                                            </Form.Item> &nbsp;
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            label={`${selectedLanguage?.txt_Thesis} ${2}`}
                                                                            required={false}
                                                                            key={2}
                                                                        >
                                                                            <Form.Item
                                                                                // {...field}
                                                                                name={[1, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: `${selectedLanguage?.erorr_messae_Please_enter_txt_Thesis} ${2}`,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`${selectedLanguage?.txt_Thesis} ${2}`} style={{ width: '90%' }} />
                                                                            </Form.Item> &nbsp;
                                                                        </Form.Item>
                                                                    </>
                                                                )}
                                                            </Form.List>
                                                            :
                                                            <Form.List
                                                                name='answerList4'
                                                                rules={[
                                                                    {
                                                                        validator: async (_, names) => {
                                                                            if (!names || names.length < 2) {
                                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                                initialValue={ansList}
                                                            >
                                                                {(fields, { add, remove }, { errors = error2 }) => (
                                                                    <>
                                                                        {fields.map((field, name, index) => (
                                                                            <span>
                                                                                <Form.Item
                                                                                    // label={index === 0 ? 'options' : ''}
                                                                                    required={false}
                                                                                    key={field.key}
                                                                                >
                                                                                    <Form.Item
                                                                                        {...field}
                                                                                        name={[name, 'option']}
                                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                whitespace: true,
                                                                                                message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                                            },
                                                                                        ]}
                                                                                        noStyle
                                                                                    >
                                                                                        <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '90%' }} />
                                                                                    </Form.Item> &nbsp;
                                                                                    {fields.length > 1 ? (
                                                                                        <MinusCircleOutlined
                                                                                            className='dynamic-delete-button'
                                                                                            onClick={() => remove(field.name)}
                                                                                        />
                                                                                    ) : null}
                                                                                </Form.Item>
                                                                                {
                                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option || ' '} />
                                                                                }
                                                                            </span>
                                                                        ))}
                                                                        <Form.Item>
                                                                            <Button
                                                                                type='dashed'
                                                                                onClick={() => add()}
                                                                                style={{ width: '100%' }}
                                                                                icon={<PlusOutlined />}
                                                                            >
                                                                                {selectedLanguage?.button_AddField}
                                                                            </Button>

                                                                            <Form.ErrorList errors={error2} />
                                                                        </Form.Item>
                                                                    </>
                                                                )}
                                                            </Form.List>
                        )
                    }

                    {previewImage && dragFileList.length && (ansType === 'dragDrop' || ansType === 'dragDropSequence') &&
                        <>
                            <Form.List
                                name='spots'
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            handleSpots()
                                        }
                                    }
                                ]}
                                initialValue={(ansList?.length && (copyQuestion?.answerType === 'dragDrop' || copyQuestion?.answerType === 'dragDropSequence')) ? ansList?.map((v, i) => {
                                    return {
                                        id: i,
                                        correct: v?.correct,
                                        x: Number(v?.x?.slice(0, -1)),
                                        y: Number(v?.y?.slice(0, -1)),
                                    }
                                }) : []}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    ansType === 'dragDrop' ?
                                        <>
                                            {fields.map((field, name, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={`dragDrop${field.key}`}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'correct']}
                                                            valuePropName='checked'
                                                            noStyle
                                                            initialValue={false}
                                                        >
                                                            <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' onChange={handleSpots} />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'x']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore={'X'}
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                step={5}
                                                                precision={0}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'y']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore='Y'
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                precision={0}
                                                                step={5}
                                                            />
                                                        </Form.Item>
                                                        &nbsp;
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                style={{ marginTop: 8 }}
                                                                className='dynamic-delete-button'
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Form.Item>
                                            ))}

                                            {!(fields.length > 4) && <Form.Item>
                                                <Button
                                                    type='dashed'
                                                    onClick={() => (add(), handleSpots())}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {'Add Spots'}
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>}
                                        </>
                                        :
                                        <>
                                            {fields.map((field, name, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={`dragDropSeq${field.key}`}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'x']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore={'X'}
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                step={5}
                                                                precision={0}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'y']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore='Y'
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                precision={0}
                                                                step={5}
                                                            />
                                                        </Form.Item>
                                                        &nbsp;
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                style={{ marginTop: 8 }}
                                                                className='dynamic-delete-button'
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Form.Item>
                                            ))}

                                            {!(fields.length > 4) && <Form.Item>
                                                <Button
                                                    type='dashed'
                                                    onClick={() => (add(), handleSpots())}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {'Add Spots'}
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>}
                                        </>
                                )}
                            </Form.List>
                            <div style={{ maxWidth: 400 }}>
                                <DragDropQuiz spots={spots} sequence={ansType === 'dragDropSequence'} imgUrl={previewImage} {...props} />
                            </div>
                            <br />
                            <br />
                        </>}

                    <Form.Item
                        name='language'
                        label={selectedLanguage?.label_SelectLanguage}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectLanguage,
                            },
                        ]}
                        initialValue={copyQuestion?.language?._id}
                    >
                        <Select
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.label_SelectLanguage}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {languages.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.languageName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='tutions'
                        label={selectedLanguage?.label_SelectSelectTutions}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.requiredMessage_pleaseSelectTutions,
                            },
                        ]}
                        initialValue={copyQuestion?.tutions?.map((v) => v?._id?.toString()) || []}
                    >
                        <Select
                            showArrow
                            allowClear
                            mode='multiple'
                            placeholder={selectedLanguage?.label_SelectSelectTutions}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}


                        >
                            {tutions?.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.tutionName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='book'
                        label={selectedLanguage?.label_LearningBook}
                        initialValue={copyQuestion?.book}
                    >
                        <Input placeholder={'e.g. Harry potter'} />
                    </Form.Item>

                    <Form.Item
                        name='chapter'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_Pleasechapter,
                            }
                        ]}
                        required
                        label={selectedLanguage?.label_Chapters}
                        initialValue={copyQuestion?.chapter?.chapterName}
                    >
                        <AutoComplete
                            options={_.uniqBy(optionsChapter, 'value')}
                            placeholder={selectedLanguage?.placeholder_Chapters}
                            onSelect={onSelectChapter}
                            onSearch={onSearchChapter}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name='paragraph'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseParagraph,
                            }
                        ]}
                        label={selectedLanguage?.label_Paragraph}
                        required
                        initialValue={copyQuestion?.paragraph?.paragraphName}
                    >
                        <AutoComplete
                            disabled={!chapterValue?.chapterName?.length}
                            options={_.uniqBy(optionsParagraph, 'value')}
                            // options={optionsParagraph}
                            placeholder={selectedLanguage?.placeholder_Paragraph}
                            onSearch={onSearchParagraph}
                            onSelect={onSelectParagraph}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.List
                        name='topics'
                        initialValue={copyQuestion?.topics?.map((v) => v?.topicName) || ['']}

                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? selectedLanguage?.label_Topics : ''}
                                        required
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: selectedLanguage?.errorMessage_PleaseTopic,
                                                },
                                            ]}
                                            noStyle
                                        // initialValue={copyQuestion?.topics[0]?.topicName}
                                        >
                                            <AutoComplete
                                                onChange={onchangeTopic}
                                                options={_.uniqBy(optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value)), 'value')}
                                                // options={optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value))}
                                                placeholder={selectedLanguage?.placeholder_Topic}
                                                disabled={!paragraphValue?.paragraphName?.length}
                                                style={{
                                                    width: fields.length > 1 ? '93%' : '100%',
                                                    marginRight: fields.length > 1 ? '1%' : 0
                                                }}
                                                filterOption={(inputValue, option) =>
                                                    option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className='dynamic-delete-button'
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type='dashed'
                                        onClick={() => add()}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={!paragraphValue?.paragraphName?.length && fields?.length > 2}

                                    >
                                        {selectedLanguage?.button_AddTopic} ({fields?.length}/3)
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <br />

                    <Form.Item
                        name='cards'
                        label={selectedLanguage?.txt_Select_Cards}
                        initialValue={copyQuestion?.cards?.map((v) => v?._id?.toString()) || []}
                    >
                        <Select
                            mode='multiple'
                            disabled={!paragraphValue?.paragraphName?.length}
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.txt_Select_Cards_Here}
                            optionFilterProp='children'
                        >
                            {cardsData.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.title}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>


                    <Form.Item name={'level'} initialValue={copyQuestion?.level || 1} label={selectedLanguage?.label_Level}>
                        <Radio.Group >
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {subjectName === 'MATHS' &&
                        <Form.Item name={'grade'} initialValue={copyQuestion?.grade || 10} label={selectedLanguage?.label_Grade}>
                            <Radio.Group >
                                <Radio value={10}>10</Radio>
                                <Radio value={11}>11</Radio>
                                <Radio value={12}>12</Radio>
                            </Radio.Group>
                        </Form.Item>}
                    {!copyQuestion?.other && <Form.Item
                        name='public'
                        valuePropName='checked'
                        initialValue={copyQuestion?.public || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.label_Public}
                        </Checkbox>
                    </Form.Item>}
                    <Form.Item
                        name='practice'
                        valuePropName='checked'
                        initialValue={copyQuestion?.practice || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.txt_Practice}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            type='primary'
                            size='large'
                            style={{ float: 'right' }}
                            onClick={() => setPreviewQuestion(true)}>
                            {selectedLanguage?.Button_PreviewQuestions}
                        </Button>
                        <br />
                        <br />
                        <Button
                            type='primary'
                            htmlType='submit'
                            block
                            size='large'
                            loading={!another && loading}
                            onClick={() => setAnother(false)}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>
                        <Button
                            style={{ marginTop: 5 }}
                            type='primary'
                            htmlType='submit'
                            block
                            size='large'
                            loading={another && loading}
                            onClick={() => setAnother(true)}
                        >
                            {selectedLanguage?.button_SubmitAnother}
                        </Button>
                    </Form.Item>
                </Form>

            </div >
        </>
    )
}

export default AddQuestions