import { Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { adminData, checkSelectOption, convertRoundNumber, createCbrStudentInvoicePdf, createStudentInvoicePdf } from "../../../utils/helpers";
import ViewCard from "../../Cards/ViewCard";
const { Option } = Select

const AdminStudentInvoice = (props) => {
    const { user, selectedLanguage, isEnglish, adminActions } = props
    const dispatch = useDispatch()

    let gUsers = useSelector(state => state?.adminReducer?.gUsers) || []

    const [guests, setGuests] = useState([])
    const [selectedUser, setSelectedUser] = useState(guests?.[0]?._id || null)
    const [selectedApplication, setSelectedApplication] = useState(true)
    const [selectedUserDetail, setSelectedUserDetail] = useState(guests?.[0] || null)
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    useEffect(() => {
        adminData.getGuestUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        setSelectedUserDetail(gUsers?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])

    useEffect(() => {
        setGuests(gUsers.filter((v) => v?.isCbr == selectedApplication))
        setSelectedUser(null)
    }, [selectedApplication, gUsers])

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: selectedLanguage?.txt_SubscriptionName,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            sorter: (a, b) => a.subscriptionName.length - b.subscriptionName.length,
        },
        {
            title: selectedLanguage?.txt_TotalAmount,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.txt_SubscriptionDate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('DD-MM-YYYY')}</p>
        },
        {
            title: selectedLanguage?.txt_ViewInvoice,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                selectedApplication ? createCbrStudentInvoicePdf(e, selectedUserDetail, 'view', viewInvoice) : createStudentInvoicePdf(e, selectedUserDetail, 'view', viewInvoice)
            }}
            > {selectedLanguage?.txt_View}</a >
        },
        {
            title: selectedLanguage?.txt_DownloadInvoice,
            key: 'download',
            render: (e) => <a
                onClick={() => { selectedApplication ? createCbrStudentInvoicePdf(e, selectedUserDetail, 'download', viewInvoice) : createStudentInvoicePdf(e, selectedUserDetail, 'download', viewInvoice) }}
            >{selectedLanguage?.txt_Download}</a>
        }
    ]

    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.txt_StudentInvoices}</p>
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedApplication}
                    onChange={setSelectedApplication}
                    className='form-input invice-select'
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    <Option value={true}>CBR</Option>
                    <Option value={false}>TESTALLY</Option>
                </Select>
                <br />
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedUser}
                    onChange={(e) => setSelectedUser(e)}
                    className='form-input invice-select'
                    placeholder={selectedLanguage?.txt_SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {guests?.length && guests?.map((v, i) => <Option value={v?._id} key={i}>{`${(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        dataSource={selectedUserDetail?.invoiceHistory || []}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    />
                </div>

                {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice}{...props} /> : null}

            </div>
        </div>
    )
}

export default AdminStudentInvoice