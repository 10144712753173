import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

const OverviewDragDrop = (props) => {
    const { imgUrl, spots, sequence, currentQuestionObj, VideoData, CardsData } = props
    const [draggedSpots, setDraggedSpots] = useState({})
    const [draggedSeqSpots, setDraggedSeqSpots] = useState([])
    const [ansButton, setAnsButton] = useState(false)
    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        setTimeout(() => {
            handleResize()
        }, 1000)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [imgUrl, spots, draggedSpots, draggedSeqSpots, VideoData, CardsData])

    useEffect(() => {
        setAnsButton(false)
        if (currentQuestionObj?._id && currentQuestionObj?.answerType === 'dragDropSequence') {
            setDraggedSeqSpots(currentQuestionObj?.answerGiven || [])
        } else if (currentQuestionObj?._id && currentQuestionObj?.answerType === 'dragDrop') {
            setDraggedSpots(currentQuestionObj?.answerGiven || {})
        }

    }, [currentQuestionObj])

    const handleResize = () => {
        const image = document.getElementById('image1')
        const imageWidth = image.clientWidth
        const circleHeight = (imageWidth) * 0.1

        const dragCircle = document.getElementById(`dragCircle`)
        const checkIcon = document.getElementById(`checkIcon`)
        if (dragCircle) {
            dragCircle.style.fontSize = circleHeight + 'px'
        }
        if (checkIcon) {
            checkIcon.style.fontSize = circleHeight + 'px'
        }

        spots.forEach((v, i) => {
            const circle = document.getElementById(`spot${v?.id}`)
            if (circle) {
                circle.style.height = circleHeight + 'px'
            }

            const seqdragCircle = document.getElementById(`dragCircle${i + 1}`)

            if (seqdragCircle) {
                seqdragCircle.style.height = circleHeight + 'px'
                seqdragCircle.style.width = circleHeight + 'px'
            }
        })
    }

    const SequenceDraggableCircle = ({ id, noMargin }) => {

        const style = {
            position: 'absolute',
            opacity: 1,
            width: '10%',
            marginLeft: noMargin ? 0 : `${6 * (id - 1)}%`,
            backgroundColor: 'darkOrange',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            zIndex: 'auto',
            borderWidth: 'thick',
            borderColor: 'white',
            borderStyle: 'solid',
            transform: 'translate(0%, 0%)'
        }

        return (
            <div class='circle' id={`dragCircle${id}`} style={style}>
                <h1 style={{ fontSize: '1em' }} className='spotNum'>{id}</h1>
                {/* <h1 style={{ fontSize: `${window.innerWidth * 0.09} px` }} className='spotNum'>{id}</h1> */}
            </div>
        )
    }

    const DraggableCircle2 = ({ id }) => {

        return (
            <CheckCircleOutlined id='checkIcon' style={{
                fontSize: '100%',
                backgroundColor: 'white',
                position: 'absolute',
                borderRadius: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'orange'
            }} />
        )
    }
    const DropZone = (data) => {
        const { spot } = data

        const backgroundColor = 'orange'
        const style = {
            position: 'absolute',
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            borderRadius: '50%',
            zIndex: 2
        }
        return (
            <div id={`spot${spot?.id}`} style={style}>
                {draggedSpots?.id === spot.id && (
                    <DraggableCircle2 id={2} />
                )}
            </div>
        )
    }

    const SequenceDropZone = (data) => {
        const { spot } = data

        let collectedSpot = draggedSeqSpots.find((v) => v?.spot === spot?.id + 1)

        const backgroundColor = 'orange'

        const style = {
            position: 'absolute',
            opacity: collectedSpot?.circle ? 1 : 0.6,
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            borderRadius: '50%',
            zIndex: 2,
        }
        return (
            <div id={`spot${spot?.id}`} style={style}>
                {collectedSpot?.circle && (
                    <SequenceDraggableCircle id={collectedSpot?.circle} noMargin={true} />
                )}
            </div>
        )
    }
    const handleSequenceRightAnswer = () => {
        let newArray = currentQuestionObj?.answerList.map((v, i) => {
            return {
                spot: i + 1,
                circle: i + 1
            }
        })
        return newArray
    }

    return (
        <>
            {!currentQuestionObj?.correct &&
                (!sequence ? <div style={{ marginBottom: 10, width: window.innerWidth < 700 ? '100%' : '30%', textAlign: 'initial' }}>
                    <Button disabled={ansButton} style={{ marginRight: 10 }} size='small' type='primary' onClick={() => (setDraggedSpots(currentQuestionObj?.answerList?.find((v) => v?.correct)), setAnsButton(true))} >Right Answer</Button>
                    <Button disabled={!ansButton} size='small' type='primary' onClick={() => (setDraggedSpots(currentQuestionObj?.answerGiven || {}), setAnsButton(false))} danger>Answer Given</Button>
                </div>
                    :
                    <div style={{ marginBottom: 10, width: window.innerWidth < 700 ? '100%' : '30%', textAlign: 'initial' }}>
                        <Button disabled={ansButton} style={{ marginRight: 10 }} size='small' type='primary' onClick={() => (setDraggedSeqSpots(handleSequenceRightAnswer), setAnsButton(true))} >Right Answer</Button>
                        <Button disabled={!ansButton} size='small' type='primary' onClick={() => (setDraggedSeqSpots(currentQuestionObj?.answerGiven || []), setAnsButton(false))} danger>Answer Given</Button>
                    </div>)
            }

            <div style={{ width: window.innerWidth < 700 ? '100%' : '30%', position: 'relative' }}>
                <img src={imgUrl} id='image1' width={'100%'} alt='' />
                {sequence ?
                    spots.map((spot) => (
                        <SequenceDropZone
                            key={spot.id}
                            spot={spot}
                        />
                    )) : spots.map((spot) => (
                        <DropZone
                            key={spot.id}
                            spot={spot}
                        />
                    ))
                }
            </div>
            <br />
        </>
    )
}


export default OverviewDragDrop
