import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Switch, Upload } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../../utils/apis';
import { adminData, checkSelectOption, errorMessage, successMessage } from '../../../utils/helpers';

const { Option } = Select
const AddAdminVideo = (props) => {
    const { selectedLanguage, subjects, adminActions, lanKorCondition } = props
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [train, setTrain] = useState(true)
    const [fileList, setFileList] = useState([])
    const chapters = useSelector(state => state?.generalReducer?.chapters || [])
    const allTeachers = useSelector(state => state.generalReducer.allTeachers) || []
    const [form] = Form.useForm()

    let optionsChapter = chapters?.map((v) => v?.chapterName)
    optionsChapter = _.uniq(optionsChapter)
    let optionsParagraph = chapters?.map((v) => v?.paragraph).flat().map((x) => x?.paragraphName)
    optionsParagraph = _.uniq(optionsParagraph)
    let optionsTopics = chapters?.map((v) => v?.paragraph)?.flat()?.map((x) => x?.topics)?.flat()?.map((z) => z?.topicName)
    optionsTopics = _.uniq(optionsTopics)

    const normFile3 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }

        return setFileList(e?.fileList);
    }
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }
    const onFinish = async (values) => {
        setLoading(true)
        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        setLoading(false)
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    setLoading(false)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
                })
        }
        values.admin = true
        axios.post(ADMIN?.ADD_NEW_VIDEO, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    setShowModal(false)
                    adminData.getAdminVideos(adminActions)
                    return successMessage(lanKorCondition ? data?.message : data?.messageNl)

                } else {
                    setLoading(false)
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })

    }

    return (
        <>

            <Button
                type='primary'
                size='large'
                style={{
                    float: 'right', 
                    marginBottom: '10px',
                }}
                onClick={() => { setShowModal(true) }}
            >
                {selectedLanguage?.txt_Uploadvideo}
            </Button>

            <Modal
                className='pop-info-modal'
                style={{ top: '10px' }}
                open={showModal}
                footer={false}
                onCancel={() => setShowModal(false)}>
                <div>
                    <p className='new-p-question'>{selectedLanguage?.txt_Uploadvideo}</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout='vertical'
                        requiredMark={true}
                    >
                        <Form.Item
                            name='name'
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Pleaseentervideoname,
                                }
                            ]}
                            label={selectedLanguage?.txt_Videoname}
                            required
                        >
                            <Input autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.txt_Entervideoname} />
                        </Form.Item>
                        <Form.Item
                            name='discription'
                            label={selectedLanguage?.txt_Discripion}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Pleaseentervideodiscription,
                                }
                            ]}
                            required
                        >
                            <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.txt_Entervideodiscription} />
                        </Form.Item>
                        <Form.Item
                            name='subject'
                            label={selectedLanguage?.label_SelectSubject}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Pleaseselectsubject
                                }
                            ]}
                        >
                            <Select
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                                placeholder={selectedLanguage?.label_SelectSubject}>
                                {subjects.map((v, i) => {
                                    return (
                                        <Option key={i} value={v?._id}>{v?.subjectName}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={'train'}
                            valuePropName='checked'
                            initialValue={true}
                        >
                            <Switch onChange={(e) => setTrain(e)} style={{ marginRight: 5, marginTop: 5 }} checkedChildren='Train' unCheckedChildren='Question' />
                        </Form.Item>
                        {train && <>
                            <Form.Item
                                name='chapter'
                                label={selectedLanguage?.txt_Chapter}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectChapter,
                                    },
                                ]}
                            // initialValue={copyQuestion?.subject?._id}
                            >
                                <Select
                                    // onChange={onChangeSubject}
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.label_SelectChapters}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {optionsChapter.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{v}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='paragraph'
                                label={selectedLanguage?.txt_Paragraph}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectParagraph,
                                    },
                                ]}
                            // initialValue={copyQuestion?.subject?._id}
                            >
                                <Select
                                    // onChange={onChangeSubject}
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.placeholder_Paragraph}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {optionsParagraph.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{v}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='topics'
                                label={selectedLanguage?.txt_Topics}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectTopic,
                                    }
                                ]}
                            // initialValue={copyQuestion?.subject?._id}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.txt_SelectTopicHere}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {optionsTopics.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{v}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='teachers'
                                label={selectedLanguage?.txt_Teachers}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_pleaseSelectTeacher,

                                    }
                                ]}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.txt_PleaseSelectTeachers}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {allTeachers.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </>}
                        <Form.Item
                            label={selectedLanguage?.txt_Selectvideo}
                            name='file'
                            style={{ width: '100%' }}
                            valuePropName='file'
                            getValueFromEvent={normFile3}
                        >
                            <Upload
                                className='questionFileUpload'
                                multiple={false}
                                maxCount={1}
                                beforeUpload={() => false}
                                fileList={fileList}
                                onChange={handlePreview2}
                                accept='video/*'
                                action='/upload.do'
                                style={{ width: '100%' }}
                            >
                                <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                <br />
                            </Upload>
                        </Form.Item>
                        <Form.Item >
                            <Button
                                htmlType='submit'
                                style={{ borderRadius: 4, width: '100%' }}
                                type='primary'
                                loading={loading}
                            >
                                {selectedLanguage?.txt_Submit}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

            </Modal>
        </>
    )
}
export default AddAdminVideo