import React, { useEffect, useState } from 'react'
import { Faq } from '../../../Screens'
import { checkSelectOption, studentData } from '../../../utils/helpers'
import { Button, Modal, Rate, Select } from 'antd'
import NoDataComponent from '../../NoData/NoDataComponent'
import { allPaths } from '../../../utils/constants'
import { useSelector } from 'react-redux'
import FeedbackResponseModal from './FeedbackResponse'

const TeacherResponses = (props) => {
    const { selectedLanguage, history, user, userActions, selectOption } = props
    const teacherResponses = useSelector(state => state?.userReducer?.teacherResponses || [])
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState(null)
    const [response, setResponse] = useState(false)
    console.log('teacherResponses', teacherResponses)
    useEffect(() => {
        studentData.getTeacherResponses(userActions, user?._id)
    }, [])

    useEffect(() => {
        if (selectOption === 'all') {
            setData(teacherResponses)
        } else {
            const filterArray = teacherResponses?.filter((y) => y?.userId?._id === selectOption)
            setData(filterArray)
        }
    }, [selectOption])

    const onCancel = () => {
        setModalData(null)
    }

    const gotoFeedbackModal = (v) => {
        setModalData(v)
    }


    return (
        <>
            <div className='new-exam-screen-cards'>
                {(data || [])?.length ?
                    (data || [])?.map((v, i) => {
                        return (
                            <div className='new-exam-card' style={{ minHeight: 'auto' }} key={i} >
                                <br />
                                <br />
                                <div className='new-exam-card-row-div' >
                                    <p className='new-row-title'>{selectedLanguage?.txt_title}</p>
                                    <p className='new-row-value'>{v?.feedbackId?.title || '----'}</p>
                                </div>
                                <div className='grey-card' />

                                <div className='new-exam-card-row-div'>
                                    <p className='new-row-title'>{selectedLanguage?.txt_Creater}</p>
                                    <p className='new-row-value'>{selectedLanguage?.title_Admin}</p>
                                </div>
                                <Button
                                    type='primary'
                                    block
                                    size='large'
                                    onClick={() => gotoFeedbackModal(v)}
                                >{selectedLanguage?.btn_Open}</Button>
                                <br />
                                <br />
                            </div>
                        )
                    }) :
                    <NoDataComponent />
                }
            </div>

            <Modal
                open={modalData?._id}
                onCancel={onCancel}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.ttx_FeedbackResponsesDetail}</p>

                <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_title}:</span>{modalData?.feedbackId?.title}</p>
                <p className='margin0'><span className='colored-span'>{selectedLanguage?.title_FormType}:</span>{modalData?.feedbackId?.formType}</p>
                {modalData?.feedbackId?.groupmembers ? <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Groupmembers}:</span>{modalData?.feedbackId?.groupmembers}</p> : null} <br />

                <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Responses}</span></p>

                <div className='admin-modal-responses' >
                    <p className='margin0'>
                        <span className='colored-span'>Teacher name :</span>{modalData?.userId?.fullName}
                    </p>
                    {modalData?.response?.map((y, i) => {
                        return (
                            <div className='form-feedback-student' key={i}>
                                <div className='feedback-student-inner-1'>
                                    <p className='margin0' style={{ fontSize: 16 }}>
                                        <span className='colored-span'>&nbsp;✦</span>{y?.description}</p>
                                </div>
                                <div className='feedback-student-inner-2'>
                                    <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{y?.value1}</p>
                                    <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{y?.value2}</p>
                                </div>
                                <div className='feedback-student-inner-3'>
                                    <Rate disabled={true} value={y?.answer} className='new-my-rate' />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Modal>

        </>
    )
}
export default TeacherResponses