import { ADMIN_COUPONS, ADMIN_FAQ, ADMIN_FORMS, ADMIN_FORM_APPROVALS, ADMIN_IMAGES, ADMIN_QUESTIONS, ADMIN_QUESTIONS_APPROVALS, ADMIN_SPECIAL_QUESTION, ADMIN_VIDEOS, ALL_ADMIN_FEEDBACK_FORMS, ALL_BUSINESS_USERS, ALL_GROUPS, ALL_GUEST_USERS, ALL_SUB_ADMINS, ALL_USERS, CARDS, RATES } from '../types'

const addAllUsers = (users) => {
    return {
        type: ALL_USERS,
        users
    }
}

const addAllBusinessUsers = (buUsers) => {
    return {
        type: ALL_BUSINESS_USERS,
        buUsers
    }
}

const addAllGuestUsers = (gUsers) => {
    return {
        type: ALL_GUEST_USERS,
        gUsers
    }
}
const addAllSubAdmins = (subAdmins) => {
    return {
        type: ALL_SUB_ADMINS,
        subAdmins
    }
}
const setRates = (rates) => {
    return {
        type: RATES,
        rates
    }
}

const addAllGroups = (groups) => {
    return {
        type: ALL_GROUPS,
        groups
    }
}

const addAllAdminQuestions = (adminQuestions) => {
    return {
        type: ADMIN_QUESTIONS,
        adminQuestions
    }
}

const addAllAdminQuestionsApprovals = (adminQuestionsApprovals) => {
    return {
        type: ADMIN_QUESTIONS_APPROVALS,
        adminQuestionsApprovals
    }
}
const addAdminFaq = (adminFaq) => {
    return {
        type: ADMIN_FAQ,
        adminFaq
    }
}

const addAllAdminSpecialQuestions = (adminSpecialQuestions) => {
    return {
        type: ADMIN_SPECIAL_QUESTION,
        adminSpecialQuestions
    }
}
const addAllAdminCards = (adminCards) => {
    return {
        type: CARDS,
        adminCards
    }
}
const addAdminForms = (adminForms) => {
    return {
        type: ADMIN_FORMS,
        adminForms
    }
}
const addAdminVideos = (adminVideos) => {
    return {
        type: ADMIN_VIDEOS,
        adminVideos
    }
}
const addAdminCoupons = (adminCoupons) => {
    return {
        type: ADMIN_COUPONS,
        adminCoupons
    }
}
const addAdminImages = (adminImages) => {
    return {
        type: ADMIN_IMAGES,
        adminImages
    }
}
const addAdminFormApprovals = (formApprovals) => {
    return {
        type: ADMIN_FORM_APPROVALS,
        formApprovals
    }
}
const addAllAdminFeedbackForms = (allAdminFeedbackForms) => {
    return {
        type: ALL_ADMIN_FEEDBACK_FORMS,
        allAdminFeedbackForms
    }
}

export {
    addAdminCoupons, addAdminFaq, addAdminFormApprovals, addAdminForms, addAdminImages, addAdminVideos, addAllAdminCards, addAllAdminFeedbackForms, addAllAdminQuestions,
    addAllAdminQuestionsApprovals,
    addAllAdminSpecialQuestions,
    addAllBusinessUsers,
    addAllGroups,
    addAllGuestUsers,
    addAllSubAdmins,
    addAllUsers,
    setRates
}

