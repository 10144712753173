import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { QuestionDetail } from '../../../Components'
import { ADMIN } from '../../../utils/apis'
import { adminData, successNotification } from '../../../utils/helpers'
import { FaRegCopy } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import EditAdminQuestion from './EditAdminQuestion'

const ViewAllQuestions = (props) => {
    const { adminActions, setAllQuestion, createQuestion, user, setCopyQuestion, selectedLanguage } = props
    const questions = useSelector(state => state.adminReducer.adminQuestions)
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewSelectedData, setViewSelectedData] = useState({})
    const [editQuestion, setEditQuestion] = useState(null)
    useEffect(() => {
        adminData.getAdminQuestions(adminActions, user?._id)
    }, [])
    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setViewSelectedData({})
    }

    const deleteQuestion = (_id) => {
        setSpin({ [_id]: true })

        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${ADMIN.DELETE_QUESTION}`, valObj)
            .then((res) => {
                adminData.getAdminQuestions(adminActions, user?._id)
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const copyQues = (e) => {
        delete e?._id
        createQuestion()
        setCopyQuestion(e)

    }
    const onCancel = () => {
        setEditQuestion(null)
    }
    const columns = [
        {
            title: selectedLanguage?.title_Questions,
            dataIndex: 'question',
            key: 'question'
        },
        {
            title: selectedLanguage?.title_Title,
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: selectedLanguage?.title_Subject,
            dataIndex: ['subject', 'subjectName'],
            sorter: (a, b) => a?.subject?.subjectName?.length - b?.subject?.subjectName?.length
        },
        {
            title: selectedLanguage?.label_Chapter,
            dataIndex: ['chapter', 'chapterName'],
            sorter: (a, b) => a?.chapter?.chapterName?.length - b?.chapter?.chapterName?.length
        },
        {
            title: selectedLanguage?.label_Paragraph,
            dataIndex: ['paragraph', 'paragraphName'],
            sorter: (a, b) => a?.paragraph?.paragraphName?.length - b?.paragraph?.paragraphName?.length
        },
        {
            title: selectedLanguage?.label_Points,
            dataIndex: 'point',
            key: 'point'
        },
        {
            title: selectedLanguage?.label_QuestionType,
            dataIndex: 'questionType',
            render: text => text?.toUpperCase()
        },
        {
            title: selectedLanguage?.label_Public,
            dataIndex: 'public',
            sorter: (a, b) => a?.public - b?.public,
            render: e => e ? 'True' : 'False'
        },
        {
            title: <p style={{ width: '120px' }}></p>,
            key: 'action',
            render: (e) => <span>
                <a onClick={() => showModal(e)}><FaRegEye size={18} color='black' /></a> &nbsp;&nbsp;&nbsp;
                <a onClick={() => copyQues(e)}><FaRegCopy size={18} color='black' /></a> &nbsp;&nbsp;&nbsp;
                <a onClick={() => setEditQuestion(e)} >{<TbEdit size={18} color='black' />}</a> &nbsp;&nbsp;&nbsp;
                {spin?.[e?._id] ?
                    <Spin /> : <Popconfirm
                        title={selectedLanguage?.title_DeletethisQuestion}

                        okText={selectedLanguage?.Delete}
                        cancelText={selectedLanguage?.Cancel}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => deleteQuestion(e?._id)}
                    >
                        <a href="#"><MdOutlineDeleteOutline size={18} color='red' /></a>
                    </Popconfirm>}
            </span>
        }
    ]
    return (
        <div>
            {editQuestion?._id ?
                <EditAdminQuestion editQuestion={editQuestion} onCancel={onCancel} {...props} />
                :
                <div>
                    <div className='button-end button-end-scroll' style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px' }}>
                        <Button
                            onClick={setAllQuestion}
                            type='primary'
                        >
                            {selectedLanguage?.ttx_Back}
                        </Button>
                    </div>
                    <div >
                        {viewSelectedData?._id &&
                            <QuestionDetail viewSelectedData={viewSelectedData} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} {...props} />
                        }

                        <div className='new-layout-table-upper-div margin0'>
                            <Table
                                style={{ marginTop: '10px' }}
                                className='new-layout-table'
                                loading={loading}
                                columns={columns}
                                dataSource={questions}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 'auto' }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default ViewAllQuestions;
