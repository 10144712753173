const authApi = `/api/user`
const getApi = `/api/get`
const postApi = `/api/post`
const adminApi = `/api/admin`

const AUTH = {
    LOGIN: `${authApi}/login`,
    SIGNUP: `${authApi}/signup`,
    FORGOT_PASSWORD: `${authApi}/forgot-password`,
    UPDATE_PASSWORD: `${authApi}/update-password`,
    UPDATE_SETTINGS: `${authApi}/update-settings`,
    CHANGE_PASSWORD: `${authApi}/change-password`,
    ACTIVATE_USER: `${authApi}/activate-user`
}

const GET = {
    VERIFY_EMAIL: `${getApi}/verifyemail`,
    ALL_QUESTIONS_FORM: `${getApi}/get-all-questions-form`,
    FORM_REPORTS: `${getApi}/get-form-reports`,
    USER_BY_ID: `${getApi}/get-user`,
    VERIFY_TOKEN: `${getApi}/verifytoken`,
    ALL_GUEST_USERS: `${getApi}/get-all-guest-users`,
    ALL_BUSINESS_USERS: `${getApi}/get-all-bu-users`,
    FORM: `${getApi}/get-form`,
    GET_OTHER_QUESTIONS: `${getApi}/get-other-questions`,
    GET_COMPANY_OWNERS: `${getApi}/get-company-owners`,
    GET_PAYMENTS: `${getApi}/get-payments`,
    GET_COUNTRY_TAX: `${getApi}/get-country-tax`,
    GET_QUESTION_FEE_REPORT: `${getApi}/get-question-fee-report`,
    PAYMENT_STATUS: `${getApi}/get-payment-status`,
    GET_WALLET_TRANSACTION: `${getApi}/get-walet-transaction`,
    GET_ALL_TEACHERS: `${getApi}/get-all-teachers`,
    GET_USER_CASH_BACK: `${getApi}/get-cash-back`,
    GET_USER_CASH_BACK_AMOUNT: `${getApi}/get-user-cash-back-amount`,
    GET_STUDENT_RESULT: `${getApi}/get-student-results`,
    GET_MY_STUDENT: `${getApi}/get-my-students`,
    GET_MY_STUDENT_RESULT: `${getApi}/get-my-students-result`,
    GET_ALL_CARDS: `${getApi}/get-all-cards`,
    GET_TEACHER_VIDEOS: `${getApi}/get-teacher-videos`,
    GET_STUDENT_VIDEOS: `${getApi}/get-student-videos`,
    GET_TEACHER_CARDS: `${getApi}/get-teacher-cards`,
    GET_TEACHER_FEEDBACKS_FORMS: `${getApi}/get-teacher-feedback-froms`,
    GET_ALL_FEEDBACKS_FORMS: `${getApi}/get-all-feedback-froms`,
    GET_ADMIN_FEEDBACKS_FORMS_FOR_TEACHER: `${getApi}/get-admin-feedback-froms-for-teacher`,
    GET_STUDENT_TRAIN_RESULT: `${getApi}/get-train-results`,
    GET_STUDENT_DASHBOARD_DATA: `${getApi}/get-dashboard-data`,
    GET_TEACHER_CARDS_FOR_ADMIN: `${getApi}/get-teacher-cards-for-admin`,
    GET_CARDS_BY_ID: `${getApi}/get-cards-by-id`,
    GET_TEACHER_VIDEOS_FOR_ADMIN: `${getApi}/get-teacher-video-for-admin`,
    GET_VIDEO_BY_ID: `${getApi}/get-video-by-id`,
    GET_TEACHER_EXAMS_FOR_ADMIN: `${getApi}/get-teacher-exams-for-admin`,
    GET_EXAM_BY_ID: `${getApi}/get-exam-by-id`,
    GET_TEACHER_RESPONSES: `${getApi}/get-teacher-responses`,
    GET_TEACHER_TO_STUDENT_RESPONSES: `${getApi}/get-teacher-to-student-response`,
}

const POST = {
    FEEDBACK: `${postApi}/user-feedback`,
    DELETE_FORM: `${postApi}/delete-form`,
    CREATE_QUESTIONS_FORM: `${postApi}/questions-form`,
    CREATE_FORM_REPORT: `${postApi}/create-form-report`,
    DELETE_FORM_REPORT: `${postApi}/delete-form-report`,
    EDIT_COUNTRY_TAX: `${postApi}/edit-country-tax`, // /create-review-answer
    EDIT_QUESTION: `${postApi}/edit-question`, // /edit-question    
    WALLET_RECHARGE: `${postApi}/wallet-recharge`,
    BUY_TEACHER_SUBSCRIPTION: `${postApi}/buy-teacher-subscription`,
    CASH_BACK_REQUEST: `${postApi}/cash-back-request`,
    SAVE_EXAM_RESULT: `${postApi}/save-exam-result`,
    GET_RETRY_QUESTIONS: `${postApi}/get-retry-questions`,
    DELETE_EXAM_RESULT: `${postApi}/delete-exam-result`,
    BUY_TEACHER_SUBSCRIPTION_FROM_MOLLIE: `${postApi}/buy-teacher-sub-from-mollie`,
    ADD_DESIGNER_IMAGES: `${postApi}/add-designer-images`,
    GET_TRAINING_TOPICS: `${postApi}/get-training-topics`,
    GET_TRAINING_QUESTIONS: `${postApi}/get-training-questions`,
    GET_TEST10_QUESTIONS: `${postApi}/get-test10-questions`,
    DISPLAY_FORM: `${postApi}/display-form`,
    ADD_CARDS: `${postApi}/add-cards`,
    ADD_TEACHER_VIDEO: `${postApi}/add-teacher-video`,
    GET_VIDEO_TRAINING_QUESTIONS: `${postApi}/get-video-training-questions`,
    GET_CARDS_TRAINING_QUESTIONS: `${postApi}/get-cards-training-questions`,

    DISPLAY_UNDISPLAY_VIDEOS: `${postApi}/display-undisplay-video`,
    DISPLAY_UNDISPLAY_CARD: `${postApi}/display-undisplay-card`,
    DELETE_TEACHER_FEEDBACK_FORM: `${postApi}/delete-teacher-feedback-form`,
    EDIT_TEACHER_FEEDBACK_FORM: `${postApi}/edit-teacher-feedback-form`,
    SAVE_TRAIN_RESULT: `${postApi}/save-train-result`,
    ADD_RESPONSE_TO_FEEDBACK: `${postApi}/add-response-to-feedback`,
    ADD_REVIEW: `${postApi}/create-review`,
    CREATE_REVIEW_ANSWER: `${postApi}/create-review-answer`,
    ADD_COUPON_FROM_TEACHER: `${postApi}/add-coupon-from-teacher`,
    BUY_CONTENT_SUBSCRIPTION: `${postApi}/buy-content-subscription`,
    BUY_CONTENT_SUBSCRIPTION_MOLLIE: `${postApi}/buy-content-subscription-mollie`,
    EDIT_TEACHER_FEEDBACK_RESPONSE: `${postApi}/edit-teacher-feedback-response`,
    DELETE_TEACHER_TO_STUDENT_RESPONSE: `${postApi}/delete-teacher-to-student-response`,
    GET_EXAM_REPORT: `${postApi}/get-exam-report`
}


const ADMIN = {

    LOGIN: `${adminApi}/login`,
    ADMIN_BY_ID: `${adminApi}/get-admin`,
    GET_ALL_CHAPTERS: `${adminApi}/get-all-chapters`, /* GET API */
    GET_FILE_URL: `${adminApi}/get-file-url`, /* Post File */
    CREATE_QUESTION: `${adminApi}/create-question`,
    GET_QUESTION_WITH_TYPE: `${adminApi}/get-questions-type`,
    GET_ADMIN_QUESTION_WITH_TYPE: `${adminApi}/get-admin-questions-type`,
    GET_FEEDBACKS: `${adminApi}/get-feedbacks`, /* params id */
    GET_QUESTION: `${adminApi}/get-questions`,
    DELETE_QUESTION: `${adminApi}/delete-question`,
    GET_ADMIN_FORM_REPORTS: `${adminApi}/get-admin-form-reports`, /* POST API */
    CREATE_ADMIN_FORM_REPORTS: `${adminApi}/create-admin-form-reports`, /* POST API */
    DELETE_ADMIN_FORM_REPORTS: `${adminApi}/delete-admin-form-reports`, /* POST API */

    GET_QUESTION_APPROVALS: `${adminApi}/get-question-approvals`, /* POST API */
    DECLINE_QUESTION_APPROVAL: `${adminApi}/decline-question-approval`, /* POST API */
    APPROVE_QUESTION_APPROVAL: `${adminApi}/approve-question-approval`, /* POST API */

    GET_FORM_APPROVALS: `${adminApi}/get-form-approvals`, /* POST API */
    DECLINE_FORM_APPROVAL: `${adminApi}/decline-form-approval`, /* POST API */
    APPROVE_FORM_APPROVAL: `${adminApi}/approve-form-approval`, /* POST API */

    GET_BU_QUESTIONS: `${adminApi}/get-bu-questions`, /* POST API */
    GET_BU_FORMS: `${adminApi}/get-bu-forms`, /* POST API */

    // tution

    GET_TUTIONS: `${adminApi}/get-tutions`, // get , params id
    CREATE_TUTIONS: `${adminApi}/create-tutions`, // post
    EDIT_TUTIONS: `${adminApi}/edit-tutions`, // post
    DELETE_TUTIONS: `${adminApi}/delete-tutions`, // post
    // subjects
    GET_SUBJECTS: `${adminApi}/get-subjects`, // get , params id
    CREATE_SUBJECTS: `${adminApi}/create-subjects`, // post
    EDIT_SUBJECTS: `${adminApi}/edit-subjects`, // post
    DELETE_SUBJECTS: `${adminApi}/delete-subjects`, // post
    // languages
    GET_LANGUAGES: `${adminApi}/get-languages`, // get , params id
    CREATE_LANGUAGES: `${adminApi}/create-languages`, // post
    EDIT_LANGUAGES: `${adminApi}/edit-languages`, // post
    DELETE_LANGUAGES: `${adminApi}/delete-languages`, // post

    UPDATE_QUESTION_FEE: `${adminApi}/update-question-fee`, // post
    
    GET_ADMIN_PAYMENTS: `${adminApi}/get-admin-payments`, // post
    CHANGE_PAYMENT_STATUS: `${adminApi}/change-payment-status`, // post
    
    ADD_NEW_FAQ: `${adminApi}/add-new-faq`, // post
    GET_ADMIN_FAQ: `${adminApi}/get-admin-faq`, // get 
    DELETE_ADMIN_FAQ: `${adminApi}/delete-admin-faq`, // post
    EDIT_FAQ_QUESTION: `${adminApi}/edit-admin-faq`,
    ADD_JSON_QUESTION: `${adminApi}/add-json-questions`,
    GET_SPECIAL_QUESTION: `${adminApi}/get-special-questions`,
    DELETE_SPECIAL_QUESTION: `${adminApi}/delete-special-question`,

    //cashback
    ADD_CASHBACK_SERVICE_FEE: `${adminApi}/add-cashback-service-fee`,
    EDIT_CASHBACK_SERVICE_FEE: `${adminApi}/edit-cashback-service-fee`,
    GET_CASHBACK_SERVICE_FEE: `${adminApi}/get-cashback-service-fee`,
    GET_ALL_CASH_BACKS: `${adminApi}/get-all-cash-backs`,
    CONFIRM_CASH_BACK: `${adminApi}/confirm-cash-back`,
    ADD_NEW_VIDEO: `${adminApi}/add-new-video`,
    GET_ALL_VIDEO: `${adminApi}/get-all-videos`,
    DELETE_VIDEO: `${adminApi}/delete-video`,
    EDIT_VIDEO: `${adminApi}/edit-video`,
    GET_VIDEO_BY_SUB_ID: `${adminApi}/get-video-by-sub-id`,
    ADD_ADMIN_COUPONS: `${adminApi}/add-admin-coupon`,
    GET_ADMIN_COUPOUN: `${adminApi}/get-admin-coupons`,
    ADD_ADMIN_IMAGES: `${adminApi}/add-admin-images`,
    GET_ALL_IMAGES: `${adminApi}/get-all-images`,
    DELETE_IMAGES: `${adminApi}/delete-images`,
    APPROVE_IMAGES: `${adminApi}/approve-images`,
    ADD_ADMIN_IMAGES_REMARKS: `${adminApi}/add-images-remarks`,
    
    // Cards
    GET_ALL_CARDS: `${adminApi}/get-all-cards`,
    DELETE_CARDS: `${adminApi}/delete-cards`,
    
    // Feedback
    ADD_FEEDBACK_FORM: `${adminApi}/add-feedback-form`,
    GET_ALL_ADMIN_FEEDBACK_FORM: `${adminApi}/get-all-admin-feedback-form`,
    EDIT_ADMIN_FEEDBACK_FORM: `${adminApi}/edit-admin-feedback-form`,
    DELETE_ADMIN_FEEDBACK_FORM: `${adminApi}/delete-admin-feedback-form`,
    GET_CARDS_WITH_VIDEO_AND_PARA: `${adminApi}/get-card-with-chapter-and-para`,
    // REVIEW
    HIDE_SINGLE_REVIEW: `${adminApi}/hide-single-review`, // post
    SHOW_SINGLE_REVIEW: `${adminApi}/show-single-review`, // post
    
    //SUBSCRIPTION
    CREATE_SUBSCRIPTION: `${adminApi}/create-subscription`, // post
    GET_SUBSCRIPTION: `${adminApi}/get-subscriptions`, // post
    UPDATE_TEACHER_SUBSCRIPTION: `${adminApi}/update-teacher-subscription-fee`, // post
    DELETE_SUBSCRIPTION: `${adminApi}/delete-subscription`, // post
    UPDATE_SUBSCRIPTION: `${adminApi}/update-subscription`,
    //COUPON
    GET_ALL_COUPON_FOR_TEACHER: `${adminApi}/get-teachers-coupons`,
    UPDATE_PRINT_STATUS: `${adminApi}/update-print-status`, // post
    GET_COUPON_REPORT: `${adminApi}/get-coupon-report`, // get
    GET_SUBSCRIPTION_REPORT: `${adminApi}/get-subscription-report`, // get
    GET_QUESTION_FEE_REPORT: `${adminApi}/get-question-fee-report`, // get
}

export {
    AUTH,
    GET,
    POST,
    ADMIN
}