import { Button, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { adminData, createGiftCard } from '../../../utils/helpers';
import AddCoupons from './AddCoupons';
import TeachersCoupon from './TeachersCoupon';

const Coupons = (props) => {
    const { selectedLanguage, isEnglish, user, adminActions } = props
    const [showForm, setShowform] = useState(false)
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const [teachersCoupon, setTeacherCoupon] = useState(false)
    const [allcoupons, setAllcoupons] = useState([])
    const adminCoupons = useSelector(state => state?.adminReducer?.adminCoupons || {})

    useEffect(() => {
        adminData.getAdminCoupons(adminActions, user?._id)
    }, [])

    useEffect(() => {
        let tableData = Object.entries(adminCoupons || {})
        tableData = tableData?.map((v) => {
            return {
                id: v[0],
                value: v[1]
            }
        })
        setAllcoupons([...tableData])
    }, [adminCoupons])

    let columns = [
        {
            title: selectedLanguage?.text_Description,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            render: (e) => {
                return (
                    <p>{e ? e : '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (<>
                    {spin?.[e?.id] ? <Spin /> :
                        <p onClick={() => (
                            setSpin({ [e?.id]: true, ...spin }),
                            createGiftCard(e?.value, spinFunc)
                        )}
                            style={{
                                cursor: 'pointer',
                                color: '#23afe5',
                                width: '110px'
                            }}
                        >{selectedLanguage?.text_ShowTotalPdfs}</p>}
                </>)
            }
        }
    ]


    return (
        <div style={{ margin: '20px' }}>
            {teachersCoupon ?
                <TeachersCoupon onCancel={() => setTeacherCoupon(false)} {...props} />
                :
                <div>
                    {showForm &&
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <p className='admin-home-title'>
                                {showForm ? selectedLanguage?.coupon_AddNewCoupons : selectedLanguage?.coupons_Coupons}
                            </p>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={() => {
                                        setShowform(false)
                                        adminData.getAdminCoupons(adminActions, user?._id)
                                    }}
                                    type='primary'
                                >{selectedLanguage?.Cancel}</Button>
                            </div>
                        </div>}

                    {!showForm &&
                        <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                            <Button
                                type='primary'
                                onClick={() => setTeacherCoupon(true)}>{selectedLanguage?.txt_TeachersCoupons}
                            </Button>
                            &nbsp;
                            <Button
                                type='primary'
                                onClick={() => setShowform(true)}>{selectedLanguage?.coupon_AddNewCoupons}
                            </Button>
                        </span>}

                    <div style={{ marginTop: '20px' }}>
                        {!showForm ?
                            <div className='new-layout-table-upper-div margin0'>
                                <Table
                                    style={{ marginTop: '30px' }}
                                    className='new-layout-table'
                                    dataSource={allcoupons}
                                    columns={columns}
                                    pagination={true}
                                    scroll={{ x: 'auto' }}
                                />
                            </div>
                            :
                            <div className='crt-question-div'>
                                <AddCoupons {...props} />
                            </div>}
                    </div>
                </div>}
        </div >
    )
}
export default Coupons