import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { teacherData } from '../../../utils/helpers'
import NoDataComponent from '../../NoData/NoDataComponent'
import AddFeedbackResponseModal from './AddResponseModal'

const AdminFeedbackFormForTeacher = (props) => {
    const { userActions, user, selectedLanguage } = props
    const myFeedbackFormsFromAdmin = useSelector(state => state?.userReducer?.allAdminFeedbackForTeacher || [])
    const [modalData, setModalData] = useState(null)
    const onCancel = () => {
        setModalData(null)
    }

    const gotoFeedbackModal = (v) => {
        setModalData(v)
    }

    useEffect(() => {
        teacherData?.getAllAdminFeedbackFromForTeacher(userActions, user?._id)
        teacherData.getMyStudents(userActions, user?._id)
    }, [])
    return (
        <>
            <div className='new-exam-screen-cards' style={{ padding: '30px 0px 0px 0px' }}>
                {myFeedbackFormsFromAdmin?.length ?
                    myFeedbackFormsFromAdmin?.map((v, i) => {
                        return (
                            <div className='new-exam-card' style={{ minHeight: 'auto' }} key={i} >
                                <br />
                                <br />
                                <div className='new-exam-card-row-div' >
                                    <p className='new-row-title'>{selectedLanguage?.txt_title}</p>
                                    <p className='new-row-value'>{v?.title || '----'}</p>
                                </div>
                                <div className='grey-card' />

                                <div className='new-exam-card-row-div'>
                                    <p className='new-row-title'>{selectedLanguage?.txt_Creater}</p>
                                    <p className='new-row-value'>{v?.admin ? selectedLanguage?.title_Admin : v?.user?.fullName}</p>
                                </div>
                                <Button
                                    type='primary'
                                    block
                                    size='large'
                                    onClick={() => gotoFeedbackModal(v)}
                                >{selectedLanguage?.btn_Open}</Button>
                                <br />
                                <br />
                            </div>
                        )
                    }) : <NoDataComponent />
                }
            </div>
            {modalData?._id &&
                <AddFeedbackResponseModal open={modalData?._id} data={modalData} onCancel={onCancel} {...props} />
            }
        </>
    )
}

export default AdminFeedbackFormForTeacher