import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShowAllFiles } from '../../Components';
import { adminData } from '../../utils/helpers';
import AddWork from './AddWork';

const AdminFiles = (props) => {
    const { isEnglish, designerActions } = props
    const [loading, setLoading] = useState(false)
    const [showUpload, setShowUpload] = useState({})
    const [data, setData] = useState({})
    const designerFiles = useSelector(state => state?.adminReducer?.adminImages || [])
    useEffect(() => {
        adminData.getAdminImages(designerActions)
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Files',
            render: (e) => <Button onClick={() => setData(e)}>Files</Button>,
        },
        {
            title: 'Completed',
            dataIndex: 'isCompleted',
            align: 'center',
            sorter: (a, b) => a.isCompleted - b.isCompleted,
            render: (e) => <>{e ? <CheckCircleFilled style={{ color: 'green' }} /> : <CloseCircleFilled style={{ color: 'red' }} />}</>,
        },
        {
            title: 'Approved',
            align: 'center',
            dataIndex: 'isApproved',
            sorter: (a, b) => a.isApproved - b.isApproved,
            render: (e) => <>{e ? <CheckCircleFilled style={{ color: 'green' }} /> : <CloseCircleFilled style={{ color: 'red' }} />}</>,
        },
        {
            title: 'Updated At',
            dataIndex: 'adminUpdatedAt',
            align: 'center',
            sorter: (a, b) => new moment(b.adminUpdatedAt, 'DD-MM-YYYY HH:mm') - new moment(a.adminUpdatedAt, 'DD-MM-YYYY HH:mm'),

        },
        {
            title: 'Action',
            render: (e) =>
                <Button type='primary' onClick={() => setShowUpload(e)}>Upload</Button>
        },
    ]

    return (
        <>
            <div style={{ marginTop: 20, marginRight: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <h1>
                        Images
                    </h1>
                    {showUpload?._id && <AddWork showUpload={showUpload} onCancel={() => setShowUpload({})} {...props} />}
                </div>
                <div>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={designerFiles || []}
                        pagination={{ hideOnSinglePage: true }}
                    />
                </div>
            </div>
            {data?._id && <Modal
                open={data?._id}
                footer={false}
                onCancel={() => setData({})}
            >
                <ShowAllFiles data={data} {...props} />
            </Modal>}
        </>
    )
}

export default AdminFiles
