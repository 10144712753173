import { Button, Card, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import allPaths from '../../Config/paths'
import { GET } from '../../utils/apis'
import { checkDurationType, convertRoundNumber, convertTitle, errorMessage, generalData, successNotification } from '../../utils/helpers'
import AdModal from './AdModal'
import EasyLoadModal from './EasyLoadModal'
import TeacherSubModal from './TeacherSubModal'
import ContentSubModal from './ContentSubModal'

const { Option } = Select

const SubscriptionScreen = (props) => {
    const history = useHistory()
    const { user, selectedLanguage, getUser, location, lanKorCondition, generalActions } = props
    let allTeachers = useSelector(state => state?.generalReducer?.allTeachers || [])
    allTeachers = allTeachers.filter((v) => !v?.isCbr)
    let subscriptions = useSelector(state => state?.generalReducer?.subscriptions || [])
    subscriptions = subscriptions.filter((v) => v?.userType === user?.userType && v?.activate && !v?.cbr)
    const [loading, setLoading] = useState(false)
    const [ShowEasyLoadModal, setShowEasyLoadModal] = useState(false)
    const [ShowTeacherSubModal, setShowTeacherSubModal] = useState(false)
    const [showContentModal, setShowContentModal] = useState(false)
    const [money, setMoney] = useState(5)
    const [teacher, setTeacher] = useState(null)
    const [duration, setDuration] = useState(null)
    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedContentDetail, setSelectedContentDetail] = useState({})
    const [selectedSubscription, setSelectedSubscription] = useState({})
    const [adModal, setAdModal] = useState(false)
    const [imgUrl, setImgUrl] = useState('')
    const teacherSubscriptions = subscriptions?.filter((v) => v?.subscriptionType === 'teacher')
    let contentSubscriptions = subscriptions?.filter((v) => v?.subscriptionType === 'content' && !(v?.subject?.length === 1 && v?.subject[0]?.subjectName.toLowerCase().includes('cbr')))
    contentSubscriptions = _.groupBy(contentSubscriptions, 'name')

    const userId = new URLSearchParams(location.search).get('userId') || null
    let subscriptionMoney = [5, 10, 15, 20, 25, 50, 100]


    const handleChangeContent = (value) => {
        setSelectedContent(value)
        setSelectedContentDetail({})
    }
    const handleChangeMoney = (value) => {
        setMoney(value)
    }
    const handleChangeTeacher = (value) => {
        setTeacher(value)
        setDuration(null)
    }
    const handleChangeDuration = (value) => {
        let selectedTeacher = allTeachers.find((x) => x?._id === teacher)
        let teacherSub = (selectedTeacher?.teacherSubscription.find((x) => x?.subId === value))
        let subData = (subscriptions.find((x) => x?._id === value))

        setDuration(value)

        setSelectedSubscription({
            amount: teacherSub?.amount || subData?.amount,
            duration: subData?.duration,
            durationType: subData?.durationType
        })
    }
    const openAddModal = (url) => {
        setAdModal(true)
        setImgUrl(url)
    }
    const goBack = () => {
        setImgUrl('')
        setAdModal(false)
    }

    useEffect(() => {
        generalData.getAllSubscriptions(generalActions)

        generalData.getAllTeachers(generalActions)

        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths?.SUBSCRIPTION_PAYMENT)
                    if (data.success) {
                        if (data?.data) {
                            openAddModal(data?.data)
                        }
                        return successNotification(data.message)
                    }
                    errorMessage(lanKorCondition ? data.message : data.messageNl)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(lanKorCondition ? e?.message : e?.messageNl)
                })
        }
    }, [])
    const onCancel = () => {
        setShowTeacherSubModal(false)
        setShowEasyLoadModal(false)
        setShowContentModal(false)
    }
    return (
        <div className='subscription-main-div'>
            <div className='new-sub-card-scroll-div new-layout-scroll-class'>
                <div
                    // style={{
                    //     marginLeft: 4
                    // }}
                    className='subsc-cards'
                >
                    <Card style={{ borderRadius: '6px', }}
                        bordered={false}>
                        <div className='subscription-title-new'>
                            {selectedLanguage?.txt_Easyloadcard}
                        </div>

                        <br />
                        <div className='subscription-title-new'
                            style={{ height: '60px', marginTop: '10px', fontSize: '13px' }}
                        >
                            {selectedLanguage?.txt_Loadyourwalletwith} € {convertRoundNumber(money)}
                        </div>
                        <Select
                            onChange={handleChangeMoney}
                            style={{ width: '100%' }}
                            defaultValue={money}
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.txt_Selectamount}>
                            {subscriptionMoney?.length && subscriptionMoney?.map((v, i) => {
                                return (
                                    <Option value={v} key={i} >{`€ ${convertRoundNumber(v)}`}</Option>
                                )
                            })}
                        </Select>
                        <Button
                            style={{ marginTop: 5 }}
                            type='primary'
                            size='large'
                            block
                            htmlType='submit'
                            onClick={() => { setShowEasyLoadModal(true) }}
                            loading={loading}
                        >
                            {selectedLanguage?.btn_Purchasenow}
                        </Button>
                    </Card>
                </div>

                {user?.userType === 'guest' &&
                    <>
                        <div className='subsc-cards'>
                            <Card style={{ borderRadius: '6px', }}
                                bordered={false}>
                                <div className='subscription-title-new'>
                                    {selectedLanguage?.txt_Teachersubscription}
                                </div>
                                <div className='subscription-title-new'
                                    style={{ marginTop: '5px', fontSize: '13px' }}
                                >
                                    {!duration ?
                                        selectedLanguage?.txt_Selectamount :
                                        `${selectedLanguage?.ttx_Thissubscriptionisfor} ${selectedSubscription?.duration} ${convertTitle(selectedSubscription?.durationType)} € ${selectedSubscription?.amount}`}

                                </div>
                                <Select
                                    onChange={handleChangeTeacher}
                                    style={{ width: '100%', marginTop: '6px' }}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_Selectteacher}>
                                    {allTeachers?.length && allTeachers?.map((v, i) => {
                                        let filterObj = user?.subscribeTeacher?.find(obj => obj?.teacher?._id === v?._id)
                                        const expiryDate = moment(filterObj?.expiry)
                                        const currentDate = moment()
                                        const hasExpired = expiryDate?.isBefore(currentDate)
                                        let textShow = !filterObj?._id ? '' : hasExpired ? `- (${selectedLanguage?.txt_renew})` : `- (${selectedLanguage?.txt_extend})`

                                        return (
                                            <Option key={i} value={v?._id}>{`${convertTitle(v?.fullName)}${textShow}`}</Option>
                                        )
                                    })}
                                </Select>

                                <Select
                                    onChange={handleChangeDuration}
                                    disabled={teacher ? false : true}
                                    style={{ width: '100%', marginTop: '6px' }}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_SelectDuration}
                                    value={duration}
                                >
                                    {
                                        teacherSubscriptions?.map((v, i) => {
                                            let selectedTeacher = allTeachers.find((x) => x?._id === teacher)
                                            let sub = (selectedTeacher?.teacherSubscription.find((x) => x?.subId === v?._id))
                                            return (
                                                <Option key={i} value={v?._id}>{`${v?.duration} ${convertTitle(v?.durationType)} € ${sub?.amount || v?.amount} `}</Option>
                                            )
                                        }
                                        )}
                                </Select>
                                <br />
                                <Button
                                    style={{ marginTop: 5 }}
                                    disabled={!duration}
                                    block
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    onClick={() => { setShowTeacherSubModal(true) }}
                                    loading={loading}
                                >
                                    {selectedLanguage?.btn_Purchasenow}
                                </Button>
                            </Card>
                        </div>

                        <div className='subsc-cards'>
                            <Card style={{ borderRadius: '6px', }}
                                bordered={false}>
                                <div className='subscription-title-new'>
                                    {selectedLanguage?.txt_ContentSubscription}
                                </div>
                                <div className='subscription-title-new'
                                    style={{ marginTop: '5px', fontSize: '13px' }}
                                >
                                    {!selectedContentDetail?._id ?
                                        selectedLanguage?.txt_Selectamount :
                                        `${selectedLanguage?.ttx_Thissubscriptionisfor} ${selectedContentDetail?.duration} ${convertTitle(selectedContentDetail?.durationType)} € ${selectedContentDetail?.amount}`}

                                </div>
                                <Select
                                    onChange={handleChangeContent}
                                    style={{ width: '100%', marginTop: '6px' }}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_SubscriptionType}>
                                    {Object.keys(contentSubscriptions).map((v, i) => {
                                        let subscription = user?.subscription?.subscriptionName === v ? user?.subscription : {}
                                        const expiryDate = moment(subscription?.expiry)
                                        const currentDate = moment()
                                        const hasExpired = expiryDate?.isBefore(currentDate)
                                        let textShow = !subscription?._id ? '' : hasExpired ? ` - (${selectedLanguage?.txt_renew})` : ` - (${selectedLanguage?.txt_extend})`
                                        return (<Option key={i} value={v}>{v + textShow}</Option>)
                                    })}
                                </Select>

                                <Select
                                    onChange={(e) => setSelectedContentDetail(subscriptions.find((v) => v?._id === e))}
                                    disabled={!selectedContent}
                                    style={{ width: '100%', marginTop: '6px' }}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_SelectDuration}
                                    value={selectedContentDetail?._id || null}
                                >
                                    {
                                        (contentSubscriptions[selectedContent] || []).map((v, i) =>
                                            <Option key={i} value={v?._id}>{`${v?.duration} ${checkDurationType(v) /* convertTitle(v?.durationType) */} € ${v?.amount} `}</Option>
                                        )
                                    }
                                </Select>
                                <br />
                                <Button
                                    style={{ marginTop: 5 }}
                                    disabled={!selectedContentDetail?._id}
                                    block
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    onClick={() => setShowContentModal(true)}
                                    loading={loading}
                                >
                                    {selectedLanguage?.btn_Purchasenow}
                                </Button>
                            </Card>
                        </div>
                    </>
                }
            </div>
            <br/>
            {ShowEasyLoadModal &&
                <EasyLoadModal
                    money={money}
                    onCancel={onCancel}
                    {...props} />
            }

            {ShowTeacherSubModal &&
                <TeacherSubModal
                    teacher={teacher}
                    allTeachers={allTeachers}
                    selectedSubscription={selectedSubscription}
                    onCancel={onCancel}
                    {...props} />
            }
            {
                adModal && <AdModal imgUrl={imgUrl} open={adModal} onCancel={goBack} {...props} />
            }
            <ContentSubModal show={showContentModal} onCancel={onCancel} subscriptions={subscriptions} selectedContentDetail={selectedContentDetail} {...props} />
        </div >

    )
}

export default SubscriptionScreen