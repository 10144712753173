import { Button, InputNumber, Modal, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../utils/apis'
import { errorMessage, studentData, successMessage } from '../../../utils/helpers'

const NewAddCashback = (props) => {
    const { user, userActions, selectedLanguage, lanKorCondition, getUser, pointSettingAction } = props
    const cashBackServiceFee = 0.50
    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const wallet = user?.walletBalance
    const isEnglish = lanKorCondition
    let isUserIsValidForCashback = user?.walletBalance > 1 ? true : false


    useEffect(() => {
        getUser()
    }, [])

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        setConfirmLoading(true)
        let obj = {
            playerId: user?._id,
            amount: Number(amount),
            serviceCharge: cashBackServiceFee
        }

        setLoading(true)
        if (Number(amount) > wallet) {
            setLoading(false)
            errorMessage(selectedLanguage?.txt_Insufficientbalanceinyourwallet)
        } else if ((Number(amount) + cashBackServiceFee) > wallet) {
            setLoading(false)
            errorMessage(`${selectedLanguage?.txt_Themaximumyucanwithdrawis} € ${wallet - (cashBackServiceFee || 0)}, ${selectedLanguage?.txt_as} ${cashBackServiceFee || 0} ${selectedLanguage?.txt_isservicecharges}`)
        } else {
            setLoading(true)
            axios.post(POST.CASH_BACK_REQUEST, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        studentData.getMyCashbacks(userActions, user?._id)
                        getUser()
                        successMessage(isEnglish ? data?.message : data?.messageNl)
                        setVisible(false)
                    }
                    else {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    console.log('e ', e)
                    setVisible(false)
                    setLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }

        showModal1()
    }

    const handleCancel = () => {
        setVisible(false)
        showModal1()
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    return (
        <div>
            <Button
                type='primary'
                size='large'
                onClick={showModal} >
                {selectedLanguage?.txt_CASHBACKREQUESTS}
            </Button>
            <Modal
                open={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                className='pop-info-modal'
                style={{ top: '10px' }}
                closable={false}
                footer={[
                    <Button
                        key='back'
                        onClick={handleCancel}  >
                        {selectedLanguage?.btn_Cancel}
                    </Button>,
                    <Button
                        type='primary'
                        key='ok'
                        onClick={handleOk}
                        loading={loading}
                        disabled={!isUserIsValidForCashback}
                    >
                        {selectedLanguage?.Ok}
                    </Button>
                ]}
            >
                <>
                    <p className='new-p-question'>{selectedLanguage?.txt_Cashbackfrom}</p>
                    {isUserIsValidForCashback ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4>{selectedLanguage?.txt_yourcashbackamount}</h4>
                            <InputNumber
                                className='form-input-new-style input12345'
                                style={{ width: '100%', height: '45px' }}
                                onChange={(e) => setAmount(e)}
                                placeholder='e.g: 145'
                                maxLength={11}
                                min={1}
                                max={99999999999}
                                value={amount}
                                prefix="€"
                                autoFocus
                                required
                                step={1}
                            />
                            <p>{selectedLanguage?.txt_Themaximumyucanwithdrawis} €
                                {(wallet - (cashBackServiceFee || 0)).toFixed(2)}, {selectedLanguage?.txt_as} € {cashBackServiceFee || 0} {selectedLanguage?.txt_isservicecharges}</p>
                        </div> :
                        <div style={{ padding: '15px' }}>
                            <p className='clc-insufficient-balance'>{selectedLanguage?.txt_Youhaveinsufficientbalanceinyourwallet}</p>
                            <p className='clc-balance-info'>{selectedLanguage?.txt_Walletamountmustmorethen1}</p>
                        </div>
                    }
                </>
            </Modal>
        </div>
    )
}

export default NewAddCashback