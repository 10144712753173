import React from 'react'
import { Menu, Dropdown, Tooltip } from 'antd'
import { BellTwoTone, LogoutOutlined, CaretDownOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import allPaths from '../Config/paths'

const Header = (props) => {
    const { history, location, user, authActions } = props

    const logout = () => {
        authActions.removeUser()
        history.push(allPaths.LOGIN)
    }

    const menu = () => (
        <Menu className='mouse-effect'>
            <Menu.Item key='0'>
                <Link to={allPaths.HOME}>
                    <PlusOutlined style={{ color: '#1890ff' }} />Add Match
                </Link>
            </Menu.Item>
            {user?.isAdmin ? <Menu.Item key='1'>
                <Link to={allPaths.ADMIN}>
                    <BellTwoTone />Admin
                </Link>
            </Menu.Item> : null}
            <Menu.Divider />
            <Menu.Item onClick={logout} key='2'>
                <LogoutOutlined style={{ color: '#1890ff' }} />Logout
            </Menu.Item>
        </Menu>
    )

    return (
        <div style={{ position: 'sticky', top: 0 }}>
            <div className='drawer-header'>
                <div className='header'>
                    <div>
                        {location?.pathname !== allPaths.HOME && location?.pathname !== allPaths.MATCH_TOOL ?
                            <ArrowLeftOutlined style={{ fontSize: 24 }} onClick={() => history?.push(allPaths.HOME)} /> : null}
                    </div>
                    <div className='flex-row mouse-effect'>
                        <Tooltip placement='bottom' title='Notifications'>
                            <BellTwoTone
                                style={{ marginRight: 15, fontSize: 20 }}
                            // onClick={() => history.push(allPaths.NOTIFICATIONS)}
                            />
                        </Tooltip>
                        <Dropdown overlay={menu} trigger={['click', 'hover']} placement='bottomLeft'>
                            <div className='flex-row mouse-effect'>
                                <div style={{ paddingBottom: 0 }}>{user?.fullName}</div>
                                <div style={{ margin: 5, marginTop: 1.2 }}>
                                    <CaretDownOutlined />
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header