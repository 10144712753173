import { Badge } from 'antd'
import React from 'react'
import { QuestionCircleFilled } from '@ant-design/icons'

const OverviewBoxes = (props) => {
    const { questions, setQuestion } = props
    return (
        <div className='overview_boxes'>
            {questions?.map((x, ind) => {
                let bgColor = x?.correct ? 'gray' : '#DC4C35'
                return (
                    <div key={ind}>
                        {x?.marked ?
                            <Badge offset={[-5, 2]} count={<QuestionCircleFilled style={{ color: '#1677FF' }} />}>
                                <div onClick={() => setQuestion(x)} className='qBox' style={{ backgroundColor: bgColor, cursor: 'pointer' }} >{ind + 1}</div>
                            </Badge>
                            :
                            <div onClick={() => setQuestion(x)} className='qBox' style={{ backgroundColor: bgColor, cursor: 'pointer' }} >{ind + 1}</div>
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default OverviewBoxes
