import { DeleteFilled } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Rate, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { POST } from '../../../utils/apis';
import { errorMessage, successMessage, teacherData } from '../../../utils/helpers';

const TeacherToStudent = (props) => {
    const { userActions, user, selectedLanguage, lanKorCondition } = props
    const allResponses = useSelector(state => state?.userReducer?.teacherToStudentResponse || [])
    const [modalData, setModalData] = useState(null)
    const [spin, setSpin] = useState({})
    const onCancel = () => {
        setModalData(null)
    }

    useEffect(() => {
        teacherData?.getTeacherToStudentResponse(userActions, user?._id)
    }, [])

    const deleteResponse = (id) => {
        setSpin({ [id]: true })
        axios.post(POST?.DELETE_TEACHER_TO_STUDENT_RESPONSE, { id, userId: user?._id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    userActions.addTeacherToStudentResponse(data?.data)
                    setSpin({})
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    setSpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(lanKorCondition ? e?.messege : e?.messegeNl)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_title}</p>,
            dataIndex: ['feedbackId', 'title'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Student}</p>,
            dataIndex: ['studentsDisplay', 'fullName'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Action}</p>,
            render: (e) => <>
                <Button type='primary' onClick={() => setModalData(e)}>{selectedLanguage?.txt_View}</Button>
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteResponse(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button type="link" icon={<DeleteFilled />} danger />

                    }
                </Popconfirm >
            </>
        },
    ]

    return (
        <div className='new-layout-table-upper-div' style={{margin:'0px'}}>
            <Table
                style={{ marginTop: '30px' }}
                className='new-layout-table'
                scroll={{ x: 'auto' }}
                columns={columns}
                dataSource={allResponses || []}
                pagination={false}
            />
            <Modal
                open={modalData?._id}
                onCancel={onCancel}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.ttx_FeedbackResponsesDetail}</p>
                <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_title}:</span>{modalData?.feedbackId?.title}</p>
                <p className='margin0'><span className='colored-span'>{selectedLanguage?.title_FormType}:</span>{modalData?.feedbackId?.formType}</p>
                <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Responses}</span></p>
                <div className='admin-modal-responses'>
                    <p className='margin0'>
                        <span className='colored-span'>{selectedLanguage?.txt_Student} :</span>{modalData?.studentsDisplay?.fullName}
                    </p>
                    {modalData?.response?.map((y, ii) => {
                        return (
                            <div className='form-feedback-student' key={ii}>
                                <div className='feedback-student-inner-1'>
                                    <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'>&nbsp;✦</span>{y?.description}</p>
                                </div>
                                <div className='feedback-student-inner-2'>
                                    <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{y?.value1}</p>
                                    <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{y?.value2}</p>
                                </div>
                                <div className='feedback-student-inner-3'>
                                    <Rate disabled={true} value={y?.answer} className='new-my-rate' />
                                </div>
                            </div>
                        )
                    })}
                </div>

            </Modal>
        </div>
    )
}

export default TeacherToStudent