import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../../utils/apis'
import { convertTitle, errorMessage, generalData, successNotification } from '../../../../utils/helpers'
import AddTution from './AddTution'

const Tutions = (props) => {
    const { user, language, selectedLanguage, adminActions, generalActions } = props
    const [loading, setLoading] = useState(false)
    const [showAddType, setShowAddType] = useState(false)
    const [edit, setEdit] = useState({})
    const [spin, setSpin] = useState({})
    const isEnglish = language?.language === 'english'
    const tutions = useSelector(state => state?.generalReducer?.tutions || [])

    useEffect(() => {
        generalData.getAllTutions(generalActions)
    }, [])

    const deleteType = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id,
            userId: user?._id
        }
        axios.post(`${ADMIN?.DELETE_TUTIONS}`, deleteProduct)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    generalData.getAllTutions(generalActions)
                    setSpin({})
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                }
                else {
                    setSpin({})
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let columns = [
        {
            title: selectedLanguage?.txt_Tutionname,
            dataIndex: 'tutionName',
            key: 'tutionName',
            sorter: (a, b) => a?.tutionName?.length - b?.tutionName?.length
        },
        {
            title: selectedLanguage?.Actions,
            key: 'actions',
            align: 'center',
            render: (e) => <span>
                <EditTwoTone onClick={() => setEdit(e)} />&nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title={`${selectedLanguage?.Delete} ${convertTitle(e?.tutionName)}?`}
                    okText={selectedLanguage?.Delete}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteType(e?._id)}
                >
                    <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
    ]

    return (
        <div className='new-flex-cloum'>
            {showAddType || edit?._id ?
                <AddTution
                    edit={edit}
                    onCancel={() => (setShowAddType(false), setEdit({}))}
                    {...props}
                />
                :
                <div>
                    <Button
                        onClick={() => setShowAddType(true)}
                        style={{ float: 'right' }}
                        type='primary'
                        size='large'
                    >
                        {selectedLanguage?.txt_AddTution}
                    </Button>
                    <br/>
                    <div className='new-layout-table-upper-div margin0'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            scroll={{ x: 'auto' }}
                            columns={columns}
                            dataSource={tutions}
                            pagination={{ hideOnSinglePage: true }}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default Tutions

