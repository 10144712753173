import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Radio, Select, Space, Switch, Upload } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../../utils/apis';
import { adminData, checkSelectOption, errorMessage, successMessage } from '../../../utils/helpers';

const EditAdminFeedback = (props) => {
    const { selectedLanguage, adminActions, user, lanKorCondition, data, onCancel } = props
    const [form] = Form.useForm()
    const { Option } = Select
    const [loading, setLoading] = useState(false)
    const [groupmembers, setGroupMembers] = useState(data?.groupmembers)
    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []
    const gUser = useSelector(state => state?.adminReducer?.gUsers) || []
    const allCompanyOwners = buUsers.filter((v) => v?.ownerType === 'companyowner')
    const allTeachers = buUsers.filter((v) => v?.ownerType !== 'companyowner')
    const allStudents = gUser.filter((v) => v?.ownerType === null)

    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
        adminData.getGuestUser(adminActions, user?._id)
    }, [])

    const onChangeMember = (e) => {
        setGroupMembers(e?.target?.value)
    }

    const onFinish = (value) => {
        setLoading(true)
        let obj = {
            admin: true,
            _id: data?._id,
            title: value?.title,
            formType: value?.formType,
            groupmembers: groupmembers,
            content: value?.content,
            students: (groupmembers === 'student' ? value?.students : []),
            companyOwners: (groupmembers === 'company' ? value?.companyOwners : []),
            teachers: (groupmembers === 'teacher' ? value?.teachers : []),
        }

        axios.post(ADMIN?.EDIT_ADMIN_FEEDBACK_FORM, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    form.resetFields()
                    adminData?.getAllAdminFeedBackForms(adminActions)
                } else {
                    setLoading(false)
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    return (
        <div className='crt-question-div' style={{ marginTop: '20px' }}>
            <h1 className='crt-qutin-heading'>{selectedLanguage?.txt_Edit_Add_feedback_form}</h1>
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
                requiredMark={true}
            >
                <Form.Item
                    name='title'
                    rules={[
                        {
                            required: true,
                            message: selectedLanguage?.txt_Pleaseentertitle,
                        }
                    ]}
                    required
                    label={selectedLanguage?.title_Title}
                    initialValue={data?.title}
                >
                    <Input placeholder={selectedLanguage?.txt_Entertitlehere} />
                </Form.Item>

                <div className='new-radao-btn-div'>
                    <Form.Item
                        required
                        name='formType'
                        label={selectedLanguage?.title_FormType}
                        initialValue={data?.formType}
                    >
                        <Radio.Group defaultValue={data?.formType}>
                            <Radio.Button value='personal'>{selectedLanguage?.Button_Personal}</Radio.Button>
                            <Radio.Button value='anonymous'>{selectedLanguage?.Button_Anonymous}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>


                <div className='new-radao-btn-div'>
                    <Form.Item
                        required
                        name='groupmembers'
                        label={selectedLanguage?.txt_Groupmembers}
                        initialValue={groupmembers}
                    >
                        <Radio.Group onChange={onChangeMember} defaultValue={groupmembers}>
                            <Radio.Button value='student'>{selectedLanguage?.Button_Students}</Radio.Button>
                            <Radio.Button value='teacher'>{selectedLanguage?.txt_Teachers}</Radio.Button>
                            <Radio.Button value='company'>{selectedLanguage?.txt_CompanyOwner}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>

                {groupmembers === 'student' ?
                    <Form.Item
                        name='students'
                        label={selectedLanguage?.Button_Students}
                        initialValue={data?.students?.map((v) => v?._id?.toString()) || []}
                        required
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectstudentshere,
                            }
                        ]}    >
                        <Select
                            mode='multiple'
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.txt_Selectstudentshere}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {allStudents.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    : groupmembers === 'teacher' ?
                        <>
                            <Form.Item
                                name='teachers'
                                label={selectedLanguage?.txt_Teachers}
                                initialValue={data?.teachers?.map((v) => v?._id?.toString()) || []}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectTeacher,
                                    }
                                ]}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.txt_SelectTeachershere}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {allTeachers.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='showStudent'
                                valuePropName='checked'
                                initialValue={data?.showStudent || false}
                            >
                                <Checkbox>
                                    {selectedLanguage?.txt_showStudent}
                                </Checkbox>
                            </Form.Item>
                        </>
                        : groupmembers === 'company' ?
                            <Form.Item
                                name='companyOwners'
                                label={selectedLanguage?.txt_CompanyOwner}
                                initialValue={data?.companyOwners?.map((v) => v?._id?.toString()) || []}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_pleaseSelectCompanyOwner,
                                    }
                                ]}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.txt_SelectCompanyhere}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {allCompanyOwners.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item> : null
                }

                <Form.List name='content' initialValue={data?.content}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'description']}
                                        label={!name && selectedLanguage?.txt_Description}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.PleaseInputYourDescription,
                                            }
                                        ]}
                                        required
                                    >
                                        <Input.TextArea
                                            autoSize={{ minRows: 2 }}
                                            placeholder={selectedLanguage?.txt_inputDescriptionhere}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value1']}
                                        label={!name && selectedLanguage?.label_Value1}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseValue1,
                                            }
                                        ]}
                                        required
                                    >
                                        <Input
                                            style={{ /* width: 290, maxWidth:'95%' */ }}
                                            placeholder={selectedLanguage?.txt_EnterValue1}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value2']}
                                        label={!name && selectedLanguage?.label_Value2}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseValue2,
                                            }
                                        ]}
                                        required
                                    >
                                        <Input
                                            style={{ /* width: 290, maxWidth:'95%' */ }}
                                            placeholder={selectedLanguage?.txt_EnterValue2}
                                        />
                                    </Form.Item>

                                    {fields.length > 1 &&
                                        <span style={fields.length > 1 && name && { marginTop: -25, position: 'absolute', color: '#1eb2a6', } || { color: '#1eb2a6', }}><MinusCircleOutlined onClick={() => remove(name)} /></span> || null
                                    }
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type='dashed'
                                    style={{
                                        background: 'white',
                                        color: '#1eb2a6',
                                        border: '1px dashed #1eb2a6'
                                    }}
                                    onClick={() => add()} block icon={<PlusOutlined />}>
                                    {selectedLanguage?.txt_AddFiled}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item >
                    <Button
                        htmlType='submit'
                        style={{ borderRadius: 4, width: '100%' }}
                        type='primary'
                        loading={loading}
                    >
                        {selectedLanguage?.txt_save}
                    </Button>
                    {/* <br />
                    <Button
                        style={{ marginTop: 5 }}
                        onClick={onCancel}
                        size='large'
                        block
                    >
                        {selectedLanguage?.Cancel}
                    </Button> */}
                </Form.Item>
            </Form>
        </div>
    )
}

export default EditAdminFeedback