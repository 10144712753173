import { Button, Popconfirm, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../../utils/apis';
import { adminData, errorMessage, generalData, successMessage } from '../../../utils/helpers';
import AddAdminVideo from './AdminAddVideo';
import AdminEditVideo from './AdminEditVideo';

const AdminVideos = (props) => {
    const { isEnglish, generalActions, adminActions, selectedLanguage, user, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const [data, setData] = useState({})
    const subject = useSelector(state => state?.generalReducer?.subjects || [])
    const adminVideos = useSelector(state => state?.adminReducer?.adminVideos || [])

    const onCancel = () => {
        setData({})
    }

    useEffect(() => {
        adminData.getAdminVideos(adminActions)
        generalData.getAllTeachers(generalActions)
        generalData?.getAllSubjects(generalActions, user?.verify)
    }, [])

    const deleteVideo = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_VIDEO, { id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    adminData.getAdminVideos(adminActions)
                    setSpin({})
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    setSpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }

            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
                errorMessage(lanKorCondition ? data?.message : data?.messageNl)

            })
    }

    const columns = [
        {
            title: selectedLanguage?.txt_Videos,
            dataIndex: 'file',
            align: 'center',
            render: (e) =>
                <><video width={150} height={100} controls>
                    <source src={e?.url} />
                </video></>
        },
        {
            title: selectedLanguage?.txt_Name,
            dataIndex: 'name',
            sorter: (a, b) => a['name'].length - b['name'].length,
            align: 'center',
            render: e => <p>{e}</p>,
        },
        {
            title: selectedLanguage?.txtSubject,
            dataIndex: 'subject',
            align: 'center',
            render: e => <p>{e?.subjectName}</p>,

        },
        {
            title: selectedLanguage?.txt_Description,
            dataIndex: 'discription',
            align: 'center',
            render: e => <p>{e}</p>,
        },
        {
            title: selectedLanguage?.txt_Train,
            dataIndex: 'train',
            sorter: (a, b) => a.train - b.train,
            align: 'center',
            render: e => <>{e ? selectedLanguage?.True : selectedLanguage?.False}</>
        },
        {
            title: selectedLanguage?.title_Action,
            align: 'center',
            render: (e) => {
                return (
                    <div style={{ width: '200px' }}>
                        <Button
                            type='primary'
                            style={{ marginRight: '5px' }}
                            onClick={() => setData(e)}
                        >{selectedLanguage?.Edit}
                        </Button>
                        <Popconfirm
                            title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                            okText={selectedLanguage?.Yes}
                            cancelText={selectedLanguage?.No}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => deleteVideo(e?._id)}
                        >
                            {spin[e?._id] ?
                                <span className='demo-loading-container'>
                                    &nbsp;<Spin />
                                </span> :
                                <Button type='primary'>{selectedLanguage?.Delete}</Button>
                            }
                        </Popconfirm >
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: '10px' }}>
                    <h1 className='crt-qutin-heading'>
                        {selectedLanguage?.txt_Videos}
                    </h1>
                </div>
                <AddAdminVideo subjects={subject} {...props} />
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '10px' }}
                        className='new-layout-table'
                        scroll={{ x: '800px' }}
                        loading={loading}
                        columns={columns}
                        dataSource={adminVideos || []}
                        pagination={false}
                    />
                </div>
            </div>
            {
                data?._id && <AdminEditVideo data={data} onCancel={onCancel} subjects={subject} {...props} />
            }
        </>
    )
}

export default AdminVideos
