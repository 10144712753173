import React from "react";
import NewStudentResult from "../Student/Result/Result"; 

const NewResult = (props) => {
    const { user } = props
    return (
        <>
            <NewStudentResult {...props} />
        </>
    )
}
export default NewResult