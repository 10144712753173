import { ADMIN_COUPONS, ADMIN_FAQ, ADMIN_FORMS, ADMIN_FORM_APPROVALS, ADMIN_IMAGES, ADMIN_QUESTIONS, ADMIN_QUESTIONS_APPROVALS, ADMIN_SPECIAL_QUESTION, ADMIN_VIDEOS, ALL_ADMIN_FEEDBACK_FORMS, ALL_BUSINESS_USERS, ALL_GROUPS, ALL_GUEST_USERS, ALL_SUB_ADMINS, ALL_USERS, CARDS, PAYMENTS, RATES, REMOVE_USER, VOUCHERS } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ALL_USERS: {
            return { ...state, users: action.users }
        }
        case ALL_BUSINESS_USERS: {
            return { ...state, buUsers: action.buUsers }
        }
        case PAYMENTS: {
            return { ...state, payments: action.payments }
        }
        case VOUCHERS: {
            return { ...state, vouchers: action.vouchers }
        }
        case ALL_GUEST_USERS: {
            return { ...state, gUsers: action.gUsers }
        }
        case ALL_SUB_ADMINS: {
            return { ...state, subAdmins: action.subAdmins }
        }
        case RATES: {
            return { ...state, rates: action.rates }
        }
        case ALL_GROUPS: {
            return { ...state, groups: action.groups }
        }
        case ADMIN_QUESTIONS: {
            return { ...state, adminQuestions: action.adminQuestions }
        }
        case ADMIN_QUESTIONS_APPROVALS: {
            return { ...state, adminQuestionsApprovals: action.adminQuestionsApprovals }
        }
        case ADMIN_FAQ: {
            return { ...state, adminFaq: action.adminFaq }
        }
        case ADMIN_SPECIAL_QUESTION: {
            return { ...state, adminSpecialQuestions: action.adminSpecialQuestions }
        }
        case CARDS: {
            return { ...state, adminCards: action.adminCards }
        }
        case ADMIN_FORMS: {
            return { ...state, adminForms: action.adminForms }
        }
        case ADMIN_VIDEOS: {
            return { ...state, adminVideos: action.adminVideos }
        }
        case ADMIN_COUPONS: {
            return { ...state, adminCoupons: action.adminCoupons }
        }
        case ADMIN_IMAGES: {
            return { ...state, adminImages: action.adminImages }
        }
        case ADMIN_FORM_APPROVALS: {
            return { ...state, formApprovals: action.formApprovals }
        }
        case ALL_ADMIN_FEEDBACK_FORMS: {
            return { ...state, allAdminFeedbackForms: action.allAdminFeedbackForms }
        }
        case REMOVE_USER: {
            return {}
        }
        default: {
            return state
        }
    }
}

export default reducer