import { StarOutlined, StarTwoTone } from '@ant-design/icons'
import { Button, Form, Input, Modal, Radio, Result, Select, Tooltip } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DragDropStudent, DragSequence, NewTimerComponent, OverviewBoxes, PreviewMaths, TimerComponent } from '..'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import LottieLoading from '../../assets/LottieLoading.gif'
import logo from '../../assets/testallyLogo-removebg.png'
import { GET, POST } from '../../utils/apis'
import { allPaths, thesisOptions } from '../../utils/constants'
import { checkArrayForThesis, errorMessage, removeBaseUrls, studentData, successMessage } from '../../utils/helpers'
import { addDashboardData } from '../../Redux/actions/userActions'
import { loginUser } from '../../Redux/actions/authActions'

const { Option } = Select

const ParaTest = (props) => {
    const { location } = props
    const { allQuestions, formData, paraQuestions } = location.state
    const history = useHistory()
    const language = useSelector(state => state?.languageReducer)
    const user = useSelector(state => state.authReducer.user)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'

    const [selectedAns, setSelectedAns] = useState({})
    const [totalQuestions, setTotalQuestions] = useState([])
    const [allParaQuestions, setAllParaQuestions] = useState(paraQuestions)
    const [form] = Form.useForm()
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentQuestionObj, setCurrentQuestionObj] = useState({})
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [currentQuestionIsWithImg, setCurrentQuestionIsWithImg] = useState('')
    const [loading, setLoading] = useState(false)
    const [nextLoading, setNextLoading] = useState(true)
    const [resultModal, setResultModal] = useState(false)
    const [quesChng, setQuesChng] = useState(false)
    const [points, setPoints] = useState(0)
    const [chPoints, setChPoints] = useState({})
    const [chResults, setChResults] = useState({})
    const [paragraphResults, setparagraphResults] = useState({})
    const [passed, setPassed] = useState(false)
    const [examInProgress, setExamInProgress] = useState(true)
    const [marked, setMarked] = useState(false)
    const [timeEnd, setTimeEnd] = useState(false)
    const dispatch = useDispatch()

    console.log('paraQuestions', paraQuestions)
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        setCurrentQuestionObj(allParaQuestions?.[currentQuestion] || {})
        setCurrentQuestionIsWithImg(allParaQuestions?.[currentQuestion]?.file?.baseUrl || allParaQuestions?.[currentQuestion]?.file?.url || '')

        setMarked(allParaQuestions?.[currentQuestion]?.marked || false)

    }, [currentQuestion, allParaQuestions])

    const userActions = {
        addDashboardData: (e) => dispatch(addDashboardData(e)),
    }

    const handleBeforeUnload = (event) => {
        if (examInProgress) {
            // Display a confirmation message
            event.returnValue = 'You have an ongoing exam. Are you sure you want to leave?'
            event.preventDefault()
        }
    }

    const getUser = () => {
        axios.get(`${GET?.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
            })
            .catch((e) => {
                console.log('error', e)
            })
    }

    const gotoNextQuestion = (timeFinished = false) => {
        setQuesChng(!quesChng)

        if (currentQuestion + 1 === allParaQuestions?.length) {

            if (currentQuestionObj?.answerType !== 'dragDrop' && currentQuestionObj?.answerType !== 'dragDropSequence') {
                declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
            }

            onfinish()
            setCurrentQuestion(currentQuestion + 1)
        } else if (currentQuestion < allParaQuestions?.length) {
            setNextLoading(true)

            if (currentQuestionObj?.answerType !== 'dragDrop' && currentQuestionObj?.answerType !== 'dragDropSequence') {
                declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
            }

            for (let i = currentQuestion + 1; i < allParaQuestions?.length; i++) {
                let nextQ = allParaQuestions?.[i]
                if (i + 1 > allParaQuestions?.length) {
                    onfinish()
                    setCurrentQuestion(currentQuestion + 1)
                    return
                } else if (!(nextQ?.answerGiven && nextQ?.fixTime)) {
                    setCurrentQuestion(i)
                    break
                }
            }

            if (timeFinished) {
                onfinish()
            }
            // form.resetFields()
        }
    }
    const previousQuestion = () => {
        setQuesChng(!quesChng)

        for (let i = currentQuestion - 1; i >= 0; i--) {
            let previous = allParaQuestions[i]
            if (!previous?.fixTime) {
                setCurrentQuestion(i)
                break
            } else if (i === 0) {
                errorMessage(selectedLanguage?.txt_PreviousOnehadaFixedTime)
            }
        }
    }
    const declareAnswer = (currQues, givenResponse) => {
        let newAllQuestion = [...allParaQuestions].map((v, i) => {
            if (v?._id === currQues?._id) {
                if (currQues?.answerType === 'radio' || currQues?.answerType === 'single') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || null

                    newObj.answerGiven = givenResponse || null
                    newObj.correct = (currQues?.answerList?.find((x) => x?.option === answerGiven))?.isTrue || false

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.marked = marked

                    return newObj

                } else if (currQues?.answerType === 'hotspot') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || null

                    newObj.answerGiven = givenResponse || null
                    newObj.correct = (currQues?.answerList?.find((x) => x?.file?.perUrl === answerGiven))?.isTrue || false

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.marked = marked

                    return newObj

                } else if (currQues?.answerType === 'inputNumber') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || null

                    newObj.answerGiven = answerGiven
                    newObj.correct = Number(answerGiven) === currQues?.answerList?.[0]?.option

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.marked = marked

                    return newObj

                } else if (currQues?.answerType === 'multiple') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || []
                    let trueAns = currQues?.answerList?.filter((x) => x?.isTrue) || []
                    let answerLength = trueAns?.length

                    newObj.answerGiven = answerGiven
                    newObj.correct = trueAns.filter((x) => answerGiven.includes(x?.option)).length === answerLength && answerLength === answerGiven?.length

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.marked = marked

                    return newObj

                } else if (currQues?.answerType === 'dragDrop') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || {}

                    newObj.answerGiven = answerGiven
                    newObj.correct = answerGiven?.correct || false

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.marked = marked

                    return newObj

                } else if (currQues?.answerType === 'dragDropSequence') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || []

                    newObj.answerGiven = answerGiven
                    newObj.correct = answerGiven.every(obj => obj.spot === obj.circle) && (answerGiven?.length === currQues?.answerList?.length)

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.marked = marked

                    return newObj

                }
            }
            else if (currQues?.answerType === 'thesis') {
                let newObj = { ...v }

                let answerGiven = givenResponse || null

                newObj.answerGiven = givenResponse || null

                if (answerGiven === thesisOptions[0]?.value) {
                    newObj.correct = (newObj?.answerList?.[0]?.isTrue ? true : false)
                }
                else if (answerGiven === thesisOptions[1]?.value) {
                    newObj.correct = (newObj?.answerList?.[1]?.isTrue ? true : false)
                }
                else if (answerGiven === thesisOptions[2]?.value) {
                    newObj.correct = newObj?.answerList?.every(item => item?.isTrue) ?? false;
                }
                else if (answerGiven === thesisOptions[3]?.value) {
                    newObj.correct = newObj?.answerList?.every(item => item?.isTrue) ?? false;
                }

                if (newObj.correct) {
                    newObj.point = currQues?.totalPoint
                } else {
                    newObj.point = 0
                }

                newObj.marked = marked

                return newObj

            } else {
                return v
            }
        })
        setAllParaQuestions(newAllQuestion)

        const updatedQuestionData = allQuestions.map((v) => {
            let indexofNewQues = newAllQuestion?.findIndex(x => x?._id === v?._id)
            if (indexofNewQues !== -1) {
                return newAllQuestion[indexofNewQues]
            }
            return v
        })

        setTotalQuestions(updatedQuestionData)
        let paraResults = _.groupBy(updatedQuestionData, 'paragraph')
        let chapResults = _.groupBy(updatedQuestionData, 'chapter')
        let chapPoints = {}
        let formLimit = formData?.limit
        let allKeys = Object.keys(chapResults)
        let pointsGet = updatedQuestionData.reduce((a, { point }) => a + point, 0)
        let checkAllTrue = []

        for (let v of allKeys) {
            chapPoints[v] = chapResults[v]?.reduce((a, { point }) => a + point, 0)
            checkAllTrue.push(chapPoints[v] >= formLimit[v])
        }
        setChResults(chapResults)
        setChPoints(chapPoints)
        setPoints(pointsGet)
        setPassed(checkAllTrue.every((v) => v))
        setparagraphResults(paraResults)

    }
    const handleChangeName = (e) => {
        let value = e.target.value
        setName(value)
    }
    const handleChangeEmail = (e) => {
        let value = e.target.value
        setEmail(value)
    }

    const onfinish = () => {
        setExamInProgress(false)
        setResultModal(true)
    }

    const saveResult = () => {
        setLoading(true)
        let obj = {
            formId: formData?._id,
            allQuestions: totalQuestions,
            teacher: formData?.userId?._id || formData?.userId,
            student: user?._id,
            admin: !!formData?.isAdmin,
            passed: passed,
            paragraph: paraQuestions?.[0]?.paragraph
        }

        if (user?.userType !== 'guest') {
            setLoading(false)
            return history.push({
                pathname: allPaths?.OVERVIEW,
                state: {
                    chResults: chResults,
                    chPoints: chPoints,
                    formData: formData,
                    paragraphResults: paragraphResults,
                    passed: passed,
                    allQuestions: totalQuestions
                }
            })
        } else {
            obj.allQuestions = removeBaseUrls(totalQuestions)
            axios.post(POST.SAVE_EXAM_RESULT, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        getUser()
                        successMessage(isEnglish ? data?.message : data?.messageNl)
                        studentData.getDashboardData(userActions, user?._id)
                        return history.push({
                            pathname: allPaths?.OVERVIEW,
                            state: {
                                chResults: chResults,
                                chPoints: chPoints,
                                formData: formData,
                                paragraphResults: paragraphResults,
                                passed: passed,
                                allQuestions: totalQuestions
                            }
                        })
                    } else if (data.alreadySaved) {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                        return history.push({
                            pathname: allPaths?.RESULTS,
                        })

                    } else {
                        return errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    console.log('error', e)
                    setLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }

    }
    const Loginuser = () => {
        // history.push(allPaths.LOGIN)
    }

    const changeQuestion = (data) => {
        let index = allParaQuestions.findIndex((v) => v?._id === data?._id)
        if (timeEnd) {
            return errorMessage(selectedLanguage?.txt_Thetimehasended)
        } else if (data?.fixTime) {
            return errorMessage(selectedLanguage?.txt_YouretryQuestionwithFixTime)
        }
        setCurrentQuestion(index)
        setResultModal(false)
        setExamInProgress(true)
    }
    const saveHotspotResponse = (response, id) => {
        let newObj = { ...selectedAns }
        newObj[id] = response
        setSelectedAns(newObj)
    }

    return (
        <div>
            <div>
                <div className='header-with-text'>
                    <div>
                        <img src={logo} style={{ height: '30px' }} alt='Logo' />
                    </div>
                    <NewTimerComponent
                        selectedLanguage={selectedLanguage}
                        // triggerFunc={() => (examInProgress && gotoNextQuestion(true))}
                        triggerFunc={() => (examInProgress && gotoNextQuestion(true), setTimeEnd(true))}
                        questions={paraQuestions}
                        formData={formData}
                        {...props}
                    />
                    {marked ? <Button onClick={() => setMarked(state => !state)} type="primary" icon={<StarOutlined />} />
                        : <Tooltip placement='bottomLeft' title={currentQuestionObj?.fixTime && selectedLanguage?.markTooltip} >
                            <Button onClick={() => setMarked(state => !state)} type='default' icon={<StarTwoTone />} />
                        </Tooltip>}
                </div>
                <div style={{ width: '100%' }}>
                    <TimerComponent
                        currentQuestionObj={currentQuestionObj}
                        currentQuestion={currentQuestion}
                        triggerFunc={() => (examInProgress && gotoNextQuestion(false))}
                        formData={formData}
                        {...props}
                    />
                </div>
                <br />
                <div className='student-main-div student-part-scroll_class'>
                    <p className={currentQuestionIsWithImg ? 'heading padd-left-21' : 'heading head-center'}>{formData?.formName}</p>
                    {!nextLoading ?
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px auto' }}>
                            <img src={LottieLoading} className='loading-next-img' />
                        </div>
                        :
                        <center>
                            <div>
                                <h2 className={'head-center'}>
                                    {selectedLanguage?.title_Question} {currentQuestion + 1} : {currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={currentQuestionObj?.question || ' '} /> : currentQuestionObj?.question}
                                </h2>
                                <Form
                                    name='form'
                                    form={form}
                                    layout='vertical'
                                >
                                    {
                                        currentQuestionObj?.answerType === 'radio' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Radio.Group>
                                                            {(currentQuestionObj?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                                <Radio
                                                                    className='student_radio'
                                                                    value={radioAnswerlist?.option}
                                                                    key={radioI}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={radioAnswerlist?.option || ' '} /> : radioAnswerlist?.option}</p>
                                                                        {(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview) && <img style={{ marginLeft: 10 }} width={100} src={(radioAnswerlist?.file?.baseUrl || radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview)} alt='' />}
                                                                    </div>
                                                                </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : currentQuestionObj?.answerType === 'hotspot' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Radio.Group onChange={e => saveHotspotResponse(e?.target?.value, currentQuestionObj?._id)}>
                                                            {(currentQuestionObj?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                                 <Radio
                                                                 className='hotspot_radio'
                                                                 value={`${radioAnswerlist?.file?.perUrl}`}
                                                                 key={radioI}
                                                             >
                                                                 <div style={selectedAns?.[currentQuestionObj?._id] && ((selectedAns?.[currentQuestionObj?._id] === radioAnswerlist?.file?.perUrl) || (selectedAns?.[currentQuestionObj?._id] === radioAnswerlist?.file?.preview)) ? { backgroundColor: 'lightsalmon', padding: 5 } : { padding: 5 }}>
                                                                     {(radioAnswerlist?.file?.url) && <img className='hotspot_image' src={radioAnswerlist?.file?.baseUrl || radioAnswerlist?.file?.url} alt='' />}
                                                                 </div>
                                                             </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : currentQuestionObj?.answerType === 'multiple' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Select
                                                            style={{ minWidth: 200 }}
                                                            className='form-input-new-style'
                                                            showArrow
                                                            allowClear
                                                            mode='multiple'
                                                            placeholder={selectedLanguage?.placeholder_SelectMultipleOption}
                                                        >
                                                            {(currentQuestionObj?.answerList || []).map((multipleAnswerlist, MultipleI) => (
                                                                <Option value={multipleAnswerlist?.option} key={MultipleI}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={multipleAnswerlist?.option || ' '} /> : multipleAnswerlist?.option}</p>
                                                                        {(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.baseUrl || multipleAnswerlist?.file?.url)} alt='' />}
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : currentQuestionObj?.answerType === 'single' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Select
                                                            style={{ minWidth: 200 }}
                                                            className='form-input-new-style'
                                                            showArrow
                                                            allowClear
                                                            placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                                        >
                                                            {(currentQuestionObj?.answerList || []).map((singleAnswerlist, SingleI) => (
                                                                <Option value={singleAnswerlist?.option} key={SingleI}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={singleAnswerlist?.option || ' '} /> : singleAnswerlist?.option}</p>
                                                                        {(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.baseUrl || singleAnswerlist?.file?.url)} alt='' />}
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : (currentQuestionObj?.answerType === 'dragDrop') ? (
                                            <DragDropStudent marked={marked} quesChng={quesChng} declareAnswer={declareAnswer} currentQuestionObj={currentQuestionObj} sequence={false} spots={currentQuestionObj?.answerList} imgUrl={currentQuestionObj?.dragFile?.baseUrl || currentQuestionObj?.dragFile?.url} {...props} />
                                        ) : (currentQuestionObj?.answerType === 'dragDropSequence') ? (
                                            <DragSequence marked={marked} quesChng={quesChng} declareAnswer={declareAnswer} currentQuestionObj={currentQuestionObj} spots={currentQuestionObj?.answerList} imgUrl={currentQuestionObj?.dragFile?.baseUrl || currentQuestionObj?.dragFile?.url} {...props} />

                                        ) : (currentQuestionObj?.answerType === 'thesis') ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    {currentQuestionObj?.answerList?.map((v, i) => <p style={{ width: '250px', textAlign: 'start', margin: '0px' }}>Thesis {i + 1}: {v?.option}</p>)}
                                                    {thesisOptions?.map((v, i) => {
                                                        let answerValidation = currentQuestionObj?.answerGiven === 'first' ? 1 : currentQuestionObj?.answerGiven === 'second' ? 2 : currentQuestionObj?.answerGiven === 'both' ? 3 : currentQuestionObj?.answerGiven === 'none' ? 4 : null
                                                        let CorrectIndex = checkArrayForThesis(currentQuestionObj?.answerList)
                                                        return (
                                                            <div style={{ width: '250px' }}>
                                                                {currentQuestionObj?.correct ?
                                                                    <Input
                                                                        key={i}
                                                                        style={{ marginTop: 10 }}
                                                                        className={'form-input-new-style ' + (answerValidation === (i + 1) ? 'correct_answer' : 'neutral_answer')}
                                                                        disabled
                                                                        value={v?.OptionEnglish || null}
                                                                    /> :
                                                                    <Input
                                                                        key={i}
                                                                        style={{ marginTop: 10 }}
                                                                        className={'form-input-new-style ' + (answerValidation === (i + 1) ? 'wrong_answer' : CorrectIndex === (i + 1) ? 'correct_answer' : 'neutral_answer')}
                                                                        disabled
                                                                        value={v?.OptionEnglish || null}
                                                                    />}
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>) : (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        {currentQuestionObj?.answerType === 'inputNumber' ?
                                                            <Input
                                                                className='form-input-new-style'
                                                                placeholder='Numeric Answer'
                                                                type='number'
                                                            />
                                                            :
                                                            <Input
                                                                className='form-input-new-style'
                                                            />
                                                        }
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Form>
                            </div>
                        </center>
                    }

                    <div className='test-footer'>
                        <Button
                            // disabled={(remainingTime <= 0 && ) || nextLoading}
                            disabled={currentQuestionObj?.fixTime}
                            onClick={previousQuestion}
                            type='primary'
                            size='large'
                            style={{
                                opacity: currentQuestionObj?.fixTime && 0.6,
                                background: '#1eb2a6',
                                color: 'white',
                            }}
                        >
                            {!currentQuestion ? '' : '< Previous'}
                        </Button>
                        <p style={{ fontSize: '18px', fontWeight: 'bold', margin: '0px' }}>
                            {currentQuestion + 1} of {allParaQuestions?.length}
                        </p>
                        <Button
                            type='primary'
                            // disabled={(remainingTime <= 0 && ) || nextLoading}
                            disabled={currentQuestionObj?.fixTime}
                            onClick={() => gotoNextQuestion(false)}
                            size='large'
                            style={{
                                opacity: currentQuestionObj?.fixTime && 0.6,
                                background: '#1eb2a6',
                                color: 'white'
                            }}
                        >
                            {'Next >'}
                        </Button>
                    </div>
                </div>

            </div >
            <Modal footer={null} closable={false} title='' open={resultModal}/*  open={true} */ >
                <Result
                    status={passed ? 'success' : 'error'}
                    title={passed ? 'Successfully Passed' : 'Unfortunately Failed'}
                    subTitle={`Total Points: ${points} / ${formData.questionPoints.reduce((a, { point }) => a + point, 0)}`}
                    // extra={Object.keys(chPoints).map((v) => (
                    //     <p>{v}: {chPoints?.[v]} / {formData?.limit?.[v]}</p>
                    //     <p>{v}: {chPoints?.[v]} / {chResults?.[v]?.reduce((a, { totalPoint }) => a + totalPoint, 0)}</p>
                    // ))}
                    extra={[
                        <Button type='primary' key='result' loading={loading} onClick={saveResult}>
                            View My Results
                        </Button>
                    ]}
                >
                </Result>
                {!timeEnd && <OverviewBoxes questions={allParaQuestions || []} setQuestion={changeQuestion} />}

            </Modal>
        </div >
    )
}

export default ParaTest
