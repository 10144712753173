import { Button, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { QuestionDetail } from '../../Components'
import { teacherData } from '../../utils/helpers'
import { CopyTwoTone, EyeFilled, EyeTwoTone } from '@ant-design/icons'

const OtherUserQuestions = (props) => {
    const { user, onBack, selectedLanguage, userActions, setCopyQuestion, createQuestion } = props
    const questions = useSelector(state => state.userReducer.otherQuestions)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [viewSelectedData, setViewSelectedData] = useState({})

    useEffect(() => {
        teacherData.getOtherUserQuestions(userActions, user?._id)
    }, [])

    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }
    const handleOk = () => {
        setIsModalVisible(false)
    }
    const handleCancel = () => {
        setIsModalVisible(false)
        setViewSelectedData({})

    }
    const copyQues = (e) => {
        delete e?._id
        e.public = false
        e.other = true
        createQuestion()
        setCopyQuestion(e)
    }
    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Questions}</p>,
            dataIndex: 'question',
            key: 'question',
            sorter: (a, b) => a?.question?.localeCompare(b?.question),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Title}</p>,
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a?.title?.localeCompare(b?.title),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Subject}</p>,
            dataIndex: ['subject', 'subjectName'],
            sorter: (a, b) => a?.subject?.subjectName?.localeCompare(b?.subject?.subjectName),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_Chapter}</p>,
            dataIndex: ['chapter', 'chapterName'],
            sorter: (a, b) => a?.chapter?.chapterName?.localeCompare(b?.chapter?.chapterName),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_Paragraph}</p>,
            dataIndex: ['paragraph', 'paragraphName'],
            sorter: (a, b) => a?.paragraph?.paragraphName?.localeCompare(b?.paragraph?.paragraphName),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Creater}</p>,
            dataIndex: ['userId', 'email'],
            sorter: (a, b) => a?.userId?.email?.localeCompare(b?.userId?.email),
            render: text => <p style={{ marginBottom: 0 }}>{text || selectedLanguage?.title_Admin}</p>,
        },
        {
            key: 'action',
            render: (e) =>
                <span style={{ width: '115px', display: 'flex' }}>
                    <Button type='link' size='large' onClick={() => showModal(e)} icon={<EyeTwoTone twoToneColor={'#1EB2A6'} />} />&nbsp;
                    <Button type='link' size='large' onClick={() => copyQues(e)} icon={<CopyTwoTone twoToneColor={'#1EB2A6'} />} />&nbsp;
                </span>
        },
    ]
    return (
        <div>
            <div>
                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button
                        style={{
                            padding: '0px 20px 0px 20px',
                            background: 'red'
                        }}
                        type='primary'
                        size='large'
                        onClick={onBack}
                    >
                        {selectedLanguage?.Back}
                    </Button>
                </span>

                <div >
                    {viewSelectedData?._id &&
                        <QuestionDetail viewSelectedData={viewSelectedData} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} {...props} />
                    }
                    <div className='new-layout-table-upper-div'>
                        <Table
                            className='new-layout-table'
                            columns={columns}
                            dataSource={questions}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 'auto' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtherUserQuestions;
