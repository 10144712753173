import React, { useEffect, useState } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import NoDataComponent from '../../NoData/NoDataComponent'
import NewViewCard from '../../Cards/ViewCard'
import { Button, Popconfirm, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { convertRoundNumber, createTeacherInvoicePdf } from '../../../utils/helpers'

const NewTeacherInvoice = (props) => {
    const { selectedLanguage, isEnglish, user, getUser } = props
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})


    useEffect(() => {
        getUser()
    }, [])


    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })
        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.newtxt_Name}</p>,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            sorter: (a, b) => a.subscriptionName.length - b.subscriptionName.length,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {convertRoundNumber(e)}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_SubscriptionDate}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('DD-MM-YYYY')}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a
                className='color-c-Green'
                onClick={() => { createTeacherInvoicePdf(e, user, 'view', viewInvoice) }}
            >{selectedLanguage?.ViewInvoice}</a>
        },
        {
            title: <p className='table-title-p '>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a
                className='color-c-Green'
                onClick={() => { createTeacherInvoicePdf(e, user, 'download', viewInvoice) }}
            >{selectedLanguage?.DownloadInvoice}</a>
        }
    ]


    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Invoices}</h3>
                <br />
                <div className='new-layout-table-upper-div'>
                    <Table
                        className='new-layout-table'
                        dataSource={user?.invoiceHistory || []}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>

                {showInvoice ? <NewViewCard card={invoice} onCancel={onCancel} showData={showInvoice} /> : null}

            </div>
        </div>
    )
}
export default NewTeacherInvoice