import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestionDetail } from '../../../Components';
import { ADMIN } from '../../../utils/apis';
import { adminData, errorMessage, successNotification } from '../../../utils/helpers';
// import ReactPlayer from 'react-player'

const QuestionsApproval = (props) => {
    const { onBack, user, selectedLanguage, adminActions, lanKorCondition } = props
    const questions = useSelector(state => state.adminReducer.adminQuestionsApprovals)

    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewSelectedData, setViewSelectedData] = useState('')
    console.log('questions', questions)
    useEffect(() => {
        adminData.getQuestionsApprovals(adminActions, user?._id)
    }, [])

    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const declineQuestion = (_id) => {
        setSpin({ [_id]: true })
        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${ADMIN.DECLINE_QUESTION_APPROVAL}`, valObj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    adminData.getQuestionsApprovals(adminActions, user?._id)
                    successNotification(data?.message)
                }
            })
            .catch((e) => {
                console.log('e', e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

            })
    }

    const aproveQuestion = (_id, fee, userId) => {
        setSpin({ [_id]: true })
        let valObj = { _id, userId, fee, adminId: user?._id }
        axios.post(`${ADMIN.APPROVE_QUESTION_APPROVAL}`, valObj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    adminData.getQuestionsApprovals(adminActions, user?._id)
                    successNotification(lanKorCondition ? data?.message : data?.messageNl)
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.title_Questions,
            dataIndex: 'question',
            key: 'question',
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_Title,
            dataIndex: 'title',
            key: 'title',
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.label_QuestionType,
            dataIndex: 'questionType',
            render: text => text?.toUpperCase(),
        },
        {
            title: selectedLanguage?.title_Creater,
            dataIndex: ['userId', 'email'],
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_Approve_Decline,
            key: 'action',
            align: 'center',
            render: (e) => <span>
                {spin?.[e?._id] ?
                    <Spin /> :
                    <>
                        <CheckOutlined onClick={() => aproveQuestion(e?._id, e?.userId?.questionFee, e?.userId?._id)} style={{ color: 'green', cursor: 'pointer' }} /> &nbsp;&nbsp;&nbsp;
                        <Popconfirm
                            title={selectedLanguage?.popup_message_Decline}
                            okText={selectedLanguage?.button_Decline}
                            cancelText={selectedLanguage?.Cancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => declineQuestion(e?._id)}
                        >
                            <CloseOutlined style={{ color: 'red' }} />
                        </Popconfirm>
                    </>}
            </span>
        },
        {
            key: 'action',
            render: (e) => {
                return (
                    <span>
                        <a onClick={() => showModal(e)}>{selectedLanguage?.View}</a>
                    </span>
                )
            }
        },
    ]
    return (
        <div>
            <div className='button-end button-end-scroll' style={{ marginTop: '10px' }}>
                <Button
                    onClick={onBack}
                    type='primary'
                >
                    {selectedLanguage?.Back}
                </Button>
            </div>
            <div  >
                {viewSelectedData?._id &&
                    <QuestionDetail viewSelectedData={viewSelectedData} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} {...props} />
                }
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '10px' }}
                        className='new-layout-table'
                        loading={loading}
                        columns={columns}
                        dataSource={questions}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: '800px' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuestionsApproval
