import { EuroCircleOutlined } from '@ant-design/icons';
import { Group, Summarize } from '@mui/icons-material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsIcon from '@mui/icons-material/Settings';
import { AiFillHome, AiFillVideoCamera } from 'react-icons/ai';
import { FaQuestionCircle } from 'react-icons/fa';
import { MdQrCodeScanner } from 'react-icons/md';
import { VscFeedback } from 'react-icons/vsc';
import apiUrl from '../Config/api';
import allPaths from '../Config/paths';
import { MdReviews } from 'react-icons/md';


const bgColor = '#0adc00'
const googleClientId = '202056254581-atll6adadjh6hpum280usr41a6o7uvhc.apps.googleusercontent.com'
// const pdfURL = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1657027850/name_zfttup.pdf'
const pdfURL = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'

const drawerRoutes = [
    {
        title: 'Home',
        titleNl: 'Home',
        route: allPaths.TEACHER_HOME,
        icon: <QuizIcon />
    },
    {
        title: 'Theory',
        titleNl: 'Theorie',
        route: allPaths?.BU_THEORY,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BU_THEORY,
        children: [
            {
                title: 'Cards',
                titleNl: 'Kaarten',
                route: allPaths.TRAIN_CARDS,
                // route: allPaths.ST_CARDS,
                icon: <AutoStoriesIcon />
            },
            {
                title: "Video's",
                titleNl: "Video's",
                route: allPaths.TRAIN_VIDEOS,
                icon: <AutoStoriesIcon />
            },
        ]
    },
    {
        title: 'Testings',
        titleNl: 'Examens',
        route: allPaths?.BU_TESTING,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BU_TESTING,
        children: [
            {
                title: 'Exams',
                titleNl: 'Examens',
                route: allPaths.QUESTIONS,
                icon: <QuizIcon />
            },

            {
                title: 'Results',
                titleNl: 'Resultaten',
                route: allPaths.BU_RESULTS,
                icon: <QuizIcon />
            },
        ]
    },
    {
        title: 'Account',
        titleNl: 'Account',
        route: allPaths?.BU_ACCOUNT,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BU_ACCOUNT,
        children: [
            {
                title: 'Profile',
                titleNl: 'Profiel',
                route: allPaths.BU_PROFILE_SETTINGS,
                icon: <AutoStoriesIcon />
            },
            {
                title: 'Feedback',
                titleNl: 'Feedback',
                route: allPaths.BU_FEEDBACK,
                icon: <AutoStoriesIcon />
            },
            {
                title: 'Review',
                titleNl: 'Review',
                route: allPaths.BU_REVIEW,
                icon: <AutoStoriesIcon />
            },
        ]
    },
    {
        title: 'Financial',
        titleNl: 'Financieel',
        route: allPaths?.BUFINANCIAL,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BUFINANCIAL,
        children: [
            {
                title: 'Upload',
                titleNl: 'Upload',
                route: allPaths?.SUBSCRIPTION_PAYMENT,
            },
            {
                title: 'Invoices',
                titleNl: 'Facturen',
                route: allPaths?.BUFINANCIAL_SUBSCRIPTION_INVOICE,
            },
            {
                title: 'Transaction',
                titleNl: 'Transactie',
                route: allPaths?.BU_FINANCIAL_WALLLET_TRANSACTION,
            },
            {
                title: 'Cashback',
                titleNl: 'Terugbetaalverzoek',
                route: allPaths?.BU_FINANCIAL_CASHBACK_REQUEST,
            },
            {
                title: 'Payments',
                titleNl: 'Betalingen',
                route: allPaths?.BUPAYMENTS,
            },
            {
                title: 'Coupons',
                titleNl: 'Coupons',
                route: allPaths?.BUCOUPON,
            },
            {
                title: 'Reports',
                titleNl: 'Rapporten',
                route: allPaths?.BU_REPORTS,
            },
        ]
    },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        route: allPaths?.FAQ,
        icon: <FaQuestionCircle />
    },
]

const drawerGraphicDesignerRoutes = [
    {
        title: 'Work',
        titleNl: 'Werk',
        route: allPaths.GRAPHIC,
        icon: <AiFillHome />
    },
]

const drawerGuestRoutes = [
    {
        title: 'Home',
        titleNl: 'home',
        route: allPaths.GUEST_HOME,
        icon: <AiFillHome />
    },
    {
        title: 'Theory',
        titleNl: 'Theorie',
        route: allPaths?.THEORY,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.THEORY,
        children: [
            {
                title: 'Cards',
                titleNl: 'Kaarten',
                route: allPaths.ST_CARDS,
                icon: <AutoStoriesIcon />
            },
            {
                title: 'Videos',
                titleNl: 'Videos',
                route: allPaths.ST_VIDEO,
                icon: <AutoStoriesIcon />
            },
        ]
    },
    {
        title: 'Training',
        titleNl: 'Training',
        route: allPaths?.MAIN_TRAINING,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.MAIN_TRAINING,
        children: [
            {
                title: 'Train',
                titleNl: 'Train',
                route: allPaths.TRAINING,
                icon: <AutoStoriesIcon />
            },
            {
                title: 'Test 10',
                titleNl: 'Test 10',
                route: allPaths.TEST_10,
                icon: <AutoStoriesIcon />
            },
        ]
    },
    {
        title: 'Testings',
        titleNl: 'Examens',
        route: allPaths?.TESTING,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.TESTING,
        children: [
            {
                title: 'Exams',
                titleNl: 'Examens',
                route: allPaths.EXAMINATIONS,
                icon: <AutoStoriesIcon />
            },
            {
                title: 'Result',
                titleNl: 'Resultaten',
                route: allPaths.RESULTS,
                icon: <AutoStoriesIcon />
            },
        ]
    },
    {
        title: 'Accounts',
        titleNl: 'Accounts',
        route: allPaths?.ACCOUNT,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.ACCOUNT,
        children: [
            {
                title: 'Profile',
                titleNl: 'Profiel',
                route: allPaths.PROFILE_SETTINGS,
                icon: <AutoStoriesIcon />
            },
            {
                title: 'Feedback',
                titleNl: 'Feedback',
                route: allPaths.ST_FEEDBACK,
                icon: <AutoStoriesIcon />
            },
        ]
    },
    {
        title: 'Financial',
        titleNl: 'Financieel',
        route: allPaths?.BUFINANCIAL,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BUFINANCIAL,
        children: [
            {
                title: 'Upload',
                titleNl: 'Upload',
                route: allPaths?.SUBSCRIPTION_PAYMENT,
            },
            {
                title: 'Invoices',
                titleNl: 'Facturen',
                route: allPaths?.FINANCIAL_SUBSCRIPTION_INVOICE,
            },
            {
                title: 'Transaction',
                titleNl: 'Transactie',
                route: allPaths?.FINANCIAL_WALLLET_TRANSACTION,
            },
            {
                title: 'Cashback',
                titleNl: 'Terugbetaalverzoek',
                route: allPaths?.FINANCIAL_CASHBACK_REQUEST,
            },

        ]
    },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        route: allPaths?.FAQ,
        icon: <FaQuestionCircle />
    },
]

const drawerAdminRoutes = [
    {
        title: 'Users',
        titleNl: 'Leveranciers',
        route: allPaths.ADMIN_USERS,
        icon: <Group />
    },
    {
        title: 'Questions',
        titleNl: 'Vragen',
        route: allPaths.ADMIN_QUESTION,
        icon: <QuizIcon />
    },
    {
        title: 'Videos',
        titleNl: 'Videos',
        route: allPaths.ADMIN_VIDEOS,
        icon: <AiFillVideoCamera />
    },
    {
        title: 'Images',
        titleNl: 'Afbeeldingen',
        route: allPaths.ADMIN_IMAGES,
        icon: <AiFillVideoCamera />
    },
    {
        title: 'Cards',
        titleNl: 'Kaarten',
        route: allPaths.ADMIN_CARDS,
        icon: <AiFillVideoCamera />
    },
    {
        title: 'Feedback',
        titleNl: 'Feedback',
        route: allPaths.ADMIN_FEEDBACK,
        icon: <VscFeedback />
    },
    {
        title: 'Review',
        titleNl: 'Review',
        route: allPaths.ADMIN_REVIEW,
        icon: <MdReviews />
    },
    {
        title: 'Coupons',
        titleNl: 'Coupons',
        route: allPaths.ADMIN_COUPONS,
        icon: <MdQrCodeScanner />
    },
    {
        title: 'Financial',
        titleNl: 'Financieel',
        route: allPaths?.ADMIN_FINANCIAL,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.ADMIN_FINANCIAL,
        children: [
            {
                title: 'Payments',
                titleNl: 'Betalingen',
                route: allPaths?.ADMINPAYMENTS,
            },
            {
                title: 'Cashback',
                titleNl: 'Terugbetaalverzoeken',
                route: allPaths.ADMIN_CASHBACK,
                // icon: <TbBrandCashapp />
            },
            {
                title: 'Student invoice',
                titleNl: 'Studenten factuur',
                route: allPaths.ADMIN_STUDENT_INVOICE,
                // icon: <TbBrandCashapp />
            },
            {
                title: 'Teacher invoice',
                titleNl: 'Leraren factuur',
                route: allPaths.ADMIN_TEACHER_INVOICE,
                // icon: <TbBrandCashapp />
            },
        ]
    },
    {
        title: 'Reports',
        titleNl: 'Rapporten',
        route: allPaths.REPORT,
        isSubMenu: true,
        icon: <Summarize />,
        children: [
            {
                title: 'Exam',
                titleNl: 'Examen',
                route: allPaths.EXAM_REPORT
            },
            {
                title: 'Coupon',
                titleNl: 'Coupon',
                route: allPaths?.COUPON_REPORT
            },
            {
                title: 'Subscription',
                titleNl: 'Subscription',
                route: allPaths?.SUBSCRIPTION_REPORT
            },
            {
                title: 'Question fee',
                titleNl: 'Vergoeding per vraag',
                route: allPaths?.QUESTION_FEE_REPORT
            }
        ]
    },
    {
        title: 'Settings',
        titleNl: 'Instellingen',
        route: allPaths?.ADMIN_SETTINGS,
        isSubMenu: true,
        icon: <SettingsIcon />,
        key: allPaths?.ADMIN_SETTINGS,
        children: [
            {
                title: 'Tution',
                titleNl: 'Leerplan',
                route: allPaths?.ADMIN_SETTINGS_TUTION,
            },
            {
                title: 'Subject',
                titleNl: 'Onderwerp',
                route: allPaths?.ADMIN_SETTINGS_SUBJECTS,
            },
            {
                title: 'Language',
                titleNl: 'Taal',
                route: allPaths?.ADMIN_SETTINGS_LANGUAGES,
            },
            {
                title: 'Tax settings',
                titleNl: 'Belastingen',
                route: allPaths?.ADMIN_TAX_SETTINGS,
                // icon: <TypeSpecimenIcon />
            },
            {
                title: 'Subscriptions',
                titleNl: 'abbonnementen',
                route: allPaths?.ADMIN_SUBSCRIPTIONS,
                // icon: <TypeSpecimenIcon />
            },
            {
                title: 'Price list',
                titleNl: 'Prijslijst',
                route: allPaths?.ADMIN_PRICE_SETTINGS,
                // icon: <TypeSpecimenIcon />
            },
            {
                title: 'Add faq',
                titleNl: 'Toevoegen faq',
                route: allPaths?.ADMIN_ADD_FAQ,
                // icon: <TypeSpecimenIcon />
            },
            {
                title: 'Faq',
                titleNl: 'Faq',
                route: allPaths?.ADMIN_FAQ,
                // icon: <TypeSpecimenIcon />
            },
        ]
    },
]

const zeroString = '00'

var timeArray = [zeroString, ...Array.from(Array(96).keys()).map(v => String((v + 1) * 15))]

const constraints = {
    // facingMode: { exact: 'environment' }
    facingMode: { exact: 'user' }
}

const productTimeRange = ['morning', 'afternoon', 'evening']
const productTimeRangeLanguage = [{ name: 'morning', nameNl: 'Ochtend', }, { name: 'afternoon', nameNl: 'Middag' }, { name: 'evening', nameNl: 'Avond' }]
const provincie = [
    'Noord-Holland',
    'Zuid-Holland',
    'Zeeland',
    'Noord-Brabant',
    'Utrecht',
    'Flevoland',
    'Friesland',
    'Groningen',
    'Drenthe',
    'Overijssel',
    'Gelderland',
    'Limburg'
]
const ageOptions = [
    '0-3',
    '4-12',
    '13-65',
    '66 and older'
]
const provincieLanguage = [
    { name: 'Noord-Holland', nameNl: 'Noord-Holland' },
    { name: 'Zuid-Holland', nameNl: 'Zuid-Holland' },
    { name: 'Zeeland', nameNl: 'Zeeland' },
    { name: 'Noord-Brabant', nameNl: 'Noord-Brabant' },
    { name: 'Utrecht', nameNl: 'Utrecht' },
    { name: 'Flevoland', nameNl: 'Flevoland' },
    { name: 'Friesland', nameNl: 'Friesland' },
    { name: 'Groningen', nameNl: 'Groningen' },
    { name: 'Drenthe', nameNl: 'Drenthe' },
    { name: 'Overijssel', nameNl: 'Overijssel' },
    { name: 'Gelderland', nameNl: 'Gelderland' },
    { name: 'Limburg', nameNl: 'Limburg' }
]

const intervalOptions = [
    {
        value: 'none',
        label: 'none',
    },
    // {
    //     value: '10',
    //     label: '10',
    // },
    {
        value: '15',
        label: '15',
    },
    // {
    //     value: '20',
    //     label: '20',
    // },
    {
        value: '30',
        label: '30',
    },
    // {
    //     value: '45',
    //     label: '45',
    // },
    {
        value: '60',
        label: '60',
    }
]

const supplierType = [
    { name: 'Restaurant', nameNl: 'Restaurant', val: 'restaurant' },
    { name: 'Hotel', nameNl: 'Hotel', val: 'hotel' },
    { name: 'Attractions', nameNl: 'Attracties', val: 'attractions' },
    { name: 'Shops', nameNl: 'Winkels', val: 'shops' }
]
const ownerType = [
    { name: 'Company owner', nameNl: 'Company Owner', val: 'companyowner' },
    { name: 'Teacher', nameNl: 'Teacher', val: 'teacher' },
]
const thesisOptions = [
    { value: 'first', OptionEnglish: 'Only thesis 1 is true.' },
    { value: 'second', OptionEnglish: 'Only thesis 2 is true.' },
    { value: 'both', OptionEnglish: 'Both thesises are true.' },
    { value: 'none', OptionEnglish: 'None of both thesises are true.' },
]
const subscriptionType = [{ label: 'TEACHER', value: 'teacher' }, { label: 'CONTENT', value: 'content' }, { label: 'COUPON', value: 'coupon' }]
const contentType = [{ label: 'BASIC', value: 'basic' }, { label: 'LUXURY', value: 'luxury' }]
const userTypes = ['guest', 'business']
export {
    ageOptions,
    allPaths,
    apiUrl,
    bgColor,
    constraints,
    drawerAdminRoutes,
    drawerGraphicDesignerRoutes,
    drawerGuestRoutes,
    drawerRoutes,
    googleClientId,
    intervalOptions,
    ownerType,
    pdfURL,
    productTimeRange,
    productTimeRangeLanguage,
    provincie,
    provincieLanguage,
    supplierType,
    timeArray,
    thesisOptions,
    subscriptionType,
    contentType,
    userTypes
};
