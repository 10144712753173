import { AutoComplete, Button, Checkbox, Form, InputNumber, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../../utils/apis'
import { contentType, subscriptionType, userTypes } from '../../../../utils/constants'
import { checkSelectOption, errorMessage, generalData, successNotification } from '../../../../utils/helpers'

const { Option } = Select

const AddCbrSubscriptions = (props) => {
    const { onCancel, copy, user, language, selectedLanguage, generalActions } = props
    let cbrSubTypes = subscriptionType.filter((v) => v?.value !== 'teacher')
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [selectedContentType, setSelectedContentType] = useState(copy?.contentType || null)
    const [selectedUser, setSelectedUser] = useState(copy?.userType || null)
    const [subType, setSubType] = useState(copy?.subscriptionType || cbrSubTypes[0]?.value)
    const [durationType, setDurationType] = useState(copy.durationType || 'days')
    const isEnglish = language?.language === 'english'
    const subjects = useSelector(state => state?.generalReducer?.subjects) || []
    const subscriptions = useSelector(state => state?.generalReducer?.subscriptions) || []
    const optionSubscriptions = _.uniqBy(subscriptions.map((v) => { return { value: v?.name } }), 'value')
    useEffect(() => {
        generalData?.getAllSubjects(generalActions, user?.verify)

    }, [])
    const onFinish = (values) => {
        setLoading(true)
        values.adminId = user?._id
        values.durationType = durationType
        values.cbr = true

        axios.post(ADMIN.CREATE_SUBSCRIPTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    generalData.getAllSubscriptions(generalActions)
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                    onCancel()
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const selectAfter = (
        <Select
            showArrow
            defaultValue={copy.durationType || durationType}
            onChange={setDurationType}
            value={durationType}
        >
            <Option value={'months'}>
                {selectedLanguage?.txt_Months}
            </Option>
            <Option value={'days'}>
                {selectedLanguage?.txt_Days}
            </Option>
        </Select>
    )
    console.log('selectedUser', selectedUser, 'selectedContentType', selectedContentType)
    console.log('copy', copy)
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type='primary'
                    style={{ float: 'right', width: '120px' }}
                    size='large'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div className='crt-question-div'>
                <p className='admin-home-title'>{selectedLanguage?.txt_Add_CBR_Subscription}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    form={form}
                >
                    <Form.Item
                        name='name'
                        label={selectedLanguage?.txt_Subscriptionname}
                        initialValue={copy?.name || null}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseInputSubscriptionName
                            }
                        ]}
                    >
                        <AutoComplete
                            className='form-input-new-style'
                            options={optionSubscriptions}
                            placeholder={'e.g Subscription'}
                            filterOption={checkSelectOption}
                        />
                    </Form.Item>
                    <Form.Item
                        name='userType'
                        label={selectedLanguage?.txt_User_Type}
                        initialValue={copy?.userType || null}
                    >
                        <Select
                            className='form-input-new-style'
                            showArrow
                            placeholder={selectedLanguage?.txt_User_Type}
                            onChange={(e) => (setSelectedUser(e), setDurationType('months'))}
                        >
                            <Option value={'guest'}>
                                {selectedLanguage?.txt_Student}
                            </Option>
                            <Option value={'business'}>
                                {selectedLanguage?.txt_Teacher}
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='subscriptionType'
                        label={selectedLanguage?.txt_SubscriptionType}
                        initialValue={copy?.subscriptionType || null}
                    >
                        <Select
                            className='form-input-new-style'
                            showArrow
                            value={subType}
                            onChange={(e) => setSubType(e)}
                            placeholder={selectedLanguage?.txt_SubscriptionType}
                        >
                            {cbrSubTypes.map((v, i) => (
                                <Option value={v?.value} key={i}>
                                    {v?.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {subType === 'content' && selectedUser === 'guest' &&
                        <>
                            <Form.Item
                                name='contentType'
                                label={selectedLanguage?.txt_Content_Type}
                                initialValue={copy?.contentType || null}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showArrow
                                    placeholder={selectedLanguage?.txt_Content_Type}
                                    onChange={setSelectedContentType}
                                >
                                    {contentType.map((v, i) => (
                                        <Option value={v?.value} key={i}>
                                            {v?.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                    }
                    <Form.Item
                        name='duration'
                        label={selectedUser === 'guest' && selectedContentType === 'basic' ? selectedLanguage?.txt_NoOEXAMS : selectedLanguage?.txt_Duration}
                        initialValue={copy?.duration || null}
                    >
                        <InputNumber
                            style={{ maxWidth: 200 }}
                            placeholder='e.g 1'
                            min={1}
                            precision={0}
                            type='number'
                            addonAfter={selectedUser === 'guest' && selectedContentType === 'basic' ? selectedLanguage?.txt_Exams : selectAfter}
                        />
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        label={selectedLanguage?.Amount}
                        initialValue={copy?.amount || null}
                    >
                        <InputNumber
                            placeholder='e.g 1'
                            min={0.1}
                            step={0.1}
                            type='number'
                            addonAfter='€'
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ float: 'right', width: '120px' }}
                            loading={loading}
                            htmlType='submit'
                            type='primary'
                            size='large'
                        >
                            {copy?._id ? selectedLanguage?.Button_Copy : selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </>
    )
}

export default AddCbrSubscriptions