import { Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { adminData, checkSelectOption, convertRoundNumber, createCbrTeacherInvoicePdf, createInvoicePdf, createTeacherInvoicePdf } from "../../../utils/helpers";
import ViewCard from "../../Cards/ViewCard";
const { Option } = Select

const AdminTeacherInvoice = (props) => {
    const { user, selectedLanguage, isEnglish, adminActions } = props

    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []
    const [selectedApplication, setSelectedApplication] = useState(true)
    const [BU, setBU] = useState([])

    const [selectedUser, setSelectedUser] = useState(BU?.[0]?._id || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(BU?.[0] || null)
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})

    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        setSelectedUserDetail(BU?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])

    useEffect(() => {
        setBU(buUsers.filter((v) => v?.isCbr == selectedApplication))
        setSelectedUser(null)
    }, [selectedApplication, buUsers])

    console.log('guser', buUsers, 'asasa')
    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }



    let columns = [
        {
            title: selectedLanguage?.txt_SubscriptionName,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            sorter: (a, b) => a.subscriptionName.length - b.subscriptionName.length,
        },
        {
            title: selectedLanguage?.txt_TotalAmount,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.txt_SubscriptionDate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('DD-MM-YYYY')}</p>
        },
         {
            title: selectedLanguage?.txt_ViewInvoice,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                selectedApplication ? createCbrTeacherInvoicePdf(e, selectedUserDetail, 'view', viewInvoice) : createTeacherInvoicePdf(e, selectedUserDetail, 'view', viewInvoice)
            }}
            > {selectedLanguage?.txt_View}</a >
        },
        {
            title: selectedLanguage?.txt_DownloadInvoice,
            key: 'download',
            render: (e) => <a
                onClick={() => { selectedApplication ? createCbrTeacherInvoicePdf(e, selectedUserDetail, 'download', viewInvoice) : createTeacherInvoicePdf(e, selectedUserDetail, 'download', viewInvoice) }}
            >{selectedLanguage?.txt_Download}</a>
        },

    ]




    return (
        <div style={{ margin: '20px' }} >
            <div style={{ marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.txt_TeacherInvoices}</p>
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedApplication}
                    onChange={setSelectedApplication}
                    className='form-input invice-select'
                    // placeholder={selectedLanguage?.txt_SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    <Option value={true}>CBR</Option>
                    <Option value={false}>TESTALLY</Option>
                </Select>
                <br />
                <Select
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedUser}
                    onChange={(e) => setSelectedUser(e)}
                    className='form-input invice-select'
                    style={{ maxWidth: '100%', width: '350px' }}
                    placeholder={selectedLanguage?.txtx_selectTeacher}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {BU?.length && BU?.map((v, i) => <Option value={v?._id} key={i}>{`${(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        dataSource={selectedUserDetail?.invoiceHistory || []}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    />
                </div>
                {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice}{...props} /> : null}

            </div>
        </div>
    )
}

export default AdminTeacherInvoice