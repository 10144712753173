import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { POST } from '../../../utils/apis';
import { convertNumber, errorMessage, warningMessage } from '../../../utils/helpers';

const AddNewCoupon = (props) => {
    const { selectedLanguage, user, lanKorCondition, priceForOneCoupon } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [totalAmount, setTotalAmount] = useState(priceForOneCoupon)
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.PleaseUploadImageonly)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const onFinish = async (values) => {
        values.teacherId = user?._id
        values.teacher = [user?._id]
        values.file = fileList
        if (!values?.file?.length) {
            return warningMessage(selectedLanguage?.text_PleaseUploadImage)
        }
        setLoading(true)

        let formData = new FormData()

        formData.append('file', values?.file[0])
        formData.append('priceForOneCoupon', priceForOneCoupon)
        formData.append('teacherId', values?.teacherId)
        formData.append('userId', user?._id)
        formData.append('description', values?.description)
        formData.append('type', values?.type)
        formData.append('quantity', Number(values?.quantity))
        formData.append('money', Number(values?.money || 0) || 0)
        formData.append('adminId', values?.adminId)

        if (values?.teacher?.length) {
            for (let v of values?.teacher) {
                formData.append('teacher[]', v)
            }
        }

        axios?.post(POST?.ADD_COUPON_FROM_TEACHER, formData)
            .then((res) => {
                const { data } = res
                console.log('data==>', data)
                if (data?.success) {
                    window.location.href = data?.redirectUrl
                    // successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    // teacherData?.getAllTeacherCoupon(userActions, user?._id)

                    // setLoading(false)
                    // form.resetFields()
                }
                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }

            }).catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const changeTotalAmount = () => {
        let rate = priceForOneCoupon * form.getFieldValue('quantity')
        setTotalAmount(rate || 0)
    }
    return (
        <div style={{ padding: '15px' }}>
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                form={form}
                requiredMark={false}
                onValuesChange={changeTotalAmount}
            >
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='description'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.text_Description}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_PleaseInputDescription
                                }
                            ]}
                        >
                            <Input
                                maxLength={40}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.placeHolder_InputYourDescriptionHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='quantity'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.title_Quantity}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_PleaseInputYourQuantity
                                }
                            ]}
                            initialValue={1}
                        >
                            <div>
                                <Input
                                    defaultValue={1}
                                    type='number'
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeholderInputYourQuantityHere}
                                    min={1}
                                    step={1}
                                    precision={0}
                                />
                            </div>
                        </Form.Item>
                    </div>
                </div>

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='file'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.text_IMG}</p>}
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>{selectedLanguage?.text_ClickToUpload}</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='money'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.Amount}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputAmount
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style form-input-new-style-sufix'
                                placeholder={selectedLanguage?.InputAmountHere}
                                type='number'
                                step={0.01}
                                min={0}
                                precision={2}
                            />
                        </Form.Item>
                    </div>
                </div>
                <h3>Total Amount: {totalAmount}</h3>
                <h3>BTW (21 %): {convertNumber(totalAmount * 0.21)}</h3>
                <Form.Item>
                    <Button
                        type='primary'
                        style={{ height: '40px', width: '160px', marginTop: '10px' }}
                        htmlType='submit'
                        loading={loading}
                    >
                        {selectedLanguage?.btn_Createbtn_Create}
                    </Button>
                </Form.Item>
            </Form>
            <br />
        </div>
    )
}
export default AddNewCoupon