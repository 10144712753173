import PDFDocument from '@react-pdf/pdfkit'
import { message, notification } from 'antd'
import axios from 'axios'
import moment from 'moment'
import allPaths from '../Config/paths'
import { ADMIN, GET } from './apis'
// import draftToHtml from 'draftjs-to-html'
// import ReactHtmlParser from 'react-html-parser'
import blobStream from 'blob-stream'
import QRCode from 'qrcode'
import { contentType } from './constants'
import countryCodes from './countryCodes'
import _ from 'lodash'

const generateQR = async (text) => {
    let result = await QRCode.toDataURL(text)

    return result
}

const requiredMessage = (value) => `Please input your ${value}!`

const inputPlace = (value) => `Input your ${value} Here...!`

const setActiveMenu = (path) => path === allPaths.QUESTIONS ? allPaths.QUESTIONS : path === allPaths.FAQ ? allPaths.FAQ : path === allPaths.SIDE_SETTING ? allPaths.SIDE_SETTING : path === allPaths.FAQ ? allPaths.FAQ : path === allPaths.BUPAYMENTS ? allPaths.BUPAYMENTS : 1

const successMessage = (desc = 'Successfully Complete!') => {
    return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
    return message.info(desc)
}

const errorMessage = (desc = 'Oeps, er is iets misgegaan!') => {
    return message.error(desc)
}

const warningMessage = (desc = 'Warning!') => {
    return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
    return notification.success({ message })
}

const errorNotification = (message = 'Oeps, er is iets misgegaan!') => {
    return notification.error({ message })
}

const dateFormater = (d) => moment(d).format('ddd MMM DD YYYY')

const convertTitle = (val) => val.charAt(0).toUpperCase() + val.slice(1)
const convertNumberCardCoupon = (num) => { return `${parseFloat(num).toFixed(2)}` }

const stringLimiter = (val, limit = 50) => val?.length > limit ? `${val.slice(0, limit)}...` : val
const convertNumber = (num) => `${parseFloat(num)?.toFixed(2)}`

const convertRoundNumber = (num) => `${(Math.round(num * 100) / 100).toFixed(2)}`

const setKeyMenu = (path) =>
    (path === allPaths?.ADMIN_SETTINGS_TUTION || path === allPaths?.ADMIN_SETTINGS_SUBJECTS || path === allPaths?.ADMIN_SETTINGS_LANGUAGES || path === allPaths?.ADMIN_TAX_SETTINGS || path === allPaths?.ADMIN_PRICE_SETTINGS) ? allPaths.ADMIN_SETTINGS : (path === allPaths?.VOUCHERS || path === allPaths?.INVOICES || path === allPaths?.PAYMENTS) ? allPaths?.FINANCIAL : (path === allPaths?.BUVOUCHERS || path === allPaths?.BUINVOICES || path === allPaths?.BUPAYMENTS) ? allPaths?.BUFINANCIAL : (path === allPaths?.ADMINVOUCHERS || path === allPaths?.ADMINPAYMENTS || path === allPaths?.ADMIN_TEACHER_INVOICE || path === allPaths?.ADMINGUESTINVOICES) ? allPaths.ADMIN_FINANCIAL : (path === allPaths?.COUPON_REPORT || path === allPaths?.QUESTION_FEE_REPORT || path === allPaths?.EXAM_REPORT || path === allPaths?.SUBSCRIPTION_REPORT) ? allPaths.REPORT : path === allPaths.QUESTIONS ? allPaths.QUESTIONS : 0

const setActiveAdminMenu = (path) => path === allPaths?.ADMIN_PRICE_SETTINGS ? allPaths?.ADMIN_PRICE_SETTINGS : path === allPaths?.QUESTION_FEE_REPORT ? allPaths?.QUESTION_FEE_REPORT : path === allPaths?.ADMINPAYMENTS ? allPaths?.ADMINPAYMENTS : path === allPaths.ADMIN_USERS ? allPaths.ADMIN_USERS : path === allPaths.ADMIN_QUESTION ? allPaths.ADMIN_QUESTION : path === allPaths.ADMIN_SETTINGS_TUTION ? allPaths.ADMIN_SETTINGS_TUTION : path === allPaths.ADMIN_SETTINGS_SUBJECTS ? allPaths.ADMIN_SETTINGS_SUBJECTS : path === allPaths.ADMIN_TAX_SETTINGS ? allPaths.ADMIN_TAX_SETTINGS : path === allPaths.ADMIN_SETTINGS_LANGUAGES ? allPaths.ADMIN_SETTINGS_LANGUAGES : 0

const getAllStudentData = async (userId, userActions) => {

    axios.get(`${GET.GET_STUDENT_VIDEOS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addStudentVideos(data?.data || [])
        })
}
const checkSelectOption  = (input, option) => {
    if (option && option.children) {
        return option.children.toLowerCase().includes(input.toLowerCase().trim());
    }
    return false;
};

const fetchData = async (ROUTE, action) => {
    axios.get(ROUTE)
        .then((res) => {
            const { data } = res
            action(data?.data || [])
        })
}
const fetchDataNewCondition = async (ROUTE, action, verify) => {
    axios.get(ROUTE)
        .then((res) => {
            const { data } = res
            let subData = data?.data
            let newArray = verify ? subData?.filter(item => item?.subjectName?.toLowerCase() !== 'cbr') : subData;

            action(newArray || [])
        })
}

const fetchDataParams = async (ROUTE, action, userId, obj = false) => {
    axios.get(`${ROUTE}/${userId}`)
        .then((res) => {
            const { data } = res
            if (data?.success) {
                if (obj) {
                    action(data?.data || [])
                } else {
                    action(data?.data || {})
                }
            }
        })
}

// const fetchDataParamsWithCond = async (ROUTE, action, userId) => {
//     axios.get(`${ROUTE}/${userId}`)
//         .then((res) => {
//             const { data } = res
//             if (data?.success) {
//                 let array = data?.data || []
//                 console.log('array',array)
//                 // let newArray = array?.filter(item => item?.subjects?.subjectName?.toLowerCase() !== 'cbr');
//                 action(newArray || {})
//             }
//         })
// }

const getAdminFaq = (adminActions) => {
    axios.get(ADMIN.GET_ADMIN_FAQ)
        .then((res) => {
            const { data } = res
            data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
            if (Object.keys(data?.FaqArrayGrourp).length > 0) {
                const arr = Object.entries(data?.FaqArrayGrourp);
                arr.forEach(([key, value]) => {
                    value.sort((a, b) => a.priority - b.priority);
                });
                const sortedObj = Object.fromEntries(arr);
                adminActions?.addAdminFaq([data?.howIsWorkArray || [], sortedObj || {}])
            } else {
                adminActions?.addAdminFaq([data?.howIsWorkArray || [], data?.FaqArrayGrourp || {}])
            }
        }).catch((e) => {
            adminActions?.addAdminFaq([])
        })
}

const getFaq = (userActions) => {
    axios.get(ADMIN.GET_ADMIN_FAQ)
        .then((res) => {
            const { data } = res
            data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
            if (Object.keys(data?.FaqArrayGrourp).length > 0) {
                const arr = Object.entries(data?.FaqArrayGrourp);
                arr.forEach(([key, value]) => {
                    value.sort((a, b) => a.priority - b.priority);
                });
                const sortedObj = Object.fromEntries(arr);
                userActions?.addUserFaq([data?.howIsWorkArray || [], sortedObj || {}])
            } else {
                userActions?.addUserFaq([data?.howIsWorkArray || [], data?.FaqArrayGrourp || {}])
            }
        }).catch((e) => {
            userActions?.addUserFaq([])
        })
}

const generalData = {
    getAllTeachers: (generalActions) => fetchData(GET?.GET_ALL_TEACHERS, generalActions?.setAllTeachers),
    getAllSubjects: (generalActions, verify) => fetchDataNewCondition(ADMIN?.GET_SUBJECTS, generalActions?.setAllSubjects, verify),
    getAllChapters: (generalActions,) => fetchData(ADMIN?.GET_ALL_CHAPTERS, generalActions?.setAllChapters),
    getAllTaxes: (generalActions) => fetchData(GET?.GET_COUNTRY_TAX, generalActions?.setTaxes),
    getAllTutions: (generalActions) => fetchData(ADMIN.GET_TUTIONS, generalActions?.addAllTutions),
    getAllLanguages: (generalActions) => fetchData(ADMIN?.GET_LANGUAGES, generalActions?.setAllLanguages),
    getAllSubscriptions: (generalActions) => fetchData(ADMIN.GET_SUBSCRIPTION, generalActions?.addAllSubscription),
    all: function (generalActions, verify) {
        this.getAllTeachers(generalActions)
        this.getAllSubjects(generalActions, verify)
        this.getAllChapters(generalActions)
        this.getAllTaxes(generalActions)
        this.getAllTutions(generalActions)
        this.getAllLanguages(generalActions)
        this.getAllSubscriptions(generalActions)
    }
}

const adminData = {
    getBusinessUser: (adminActions, userId) => fetchDataParams(GET.ALL_BUSINESS_USERS, adminActions?.addAllBusinessUsers, userId),

    getGuestUser: (adminActions, userId) => fetchDataParams(GET.ALL_GUEST_USERS, adminActions?.addAllGuestUsers, userId),

    getAdminForms: (adminActions, userId) => fetchDataParams(GET.ALL_QUESTIONS_FORM, adminActions.addAdminForms, userId),

    getQuestionsApprovals: (adminActions, userId) => fetchDataParams(ADMIN.GET_QUESTION_APPROVALS, adminActions.addAllAdminQuestionsApprovals, userId),

    getAdminCoupons: (adminActions, userId) => fetchDataParams(ADMIN?.GET_ADMIN_COUPOUN, adminActions.addAdminCoupons, userId),

    getAdminCards: (adminActions) => fetchData(ADMIN.GET_ALL_CARDS, adminActions.addAllAdminCards),

    getSpecialQuestions: (adminActions) => fetchData(ADMIN.GET_SPECIAL_QUESTION, adminActions.addAllAdminSpecialQuestions),

    getAdminVideos: (adminActions) => fetchData(ADMIN?.GET_ALL_VIDEO, adminActions.addAdminVideos),

    getAdminImages: (adminActions) => fetchData(ADMIN?.GET_ALL_IMAGES, adminActions.addAdminImages),

    getAdminFormApprovals: (adminActions, userId) => fetchDataParams(ADMIN.GET_FORM_APPROVALS, adminActions.addAdminFormApprovals, userId),

    getAdminQuestions: (adminActions, userId) => fetchDataParams(ADMIN.GET_QUESTION, adminActions.addAllAdminQuestions, userId),

    getAllAdminFeedBackForms: (adminActions) => fetchData(ADMIN.GET_ALL_ADMIN_FEEDBACK_FORM, adminActions.addAllAdminFeedbackForms),

    all: function (adminActions, userId) {
        this.getBusinessUser(adminActions, userId)
        this.getGuestUser(adminActions, userId)
        this.getAdminForms(adminActions, userId)
        this.getQuestionsApprovals(adminActions, userId)
        this.getAdminCoupons(adminActions, userId)
        this.getAdminFormApprovals(adminActions, userId)
        this.getAdminQuestions(adminActions, userId)

        this.getAdminCards(adminActions)
        this.getSpecialQuestions(adminActions)
        this.getAdminVideos(adminActions)
        this.getAdminImages(adminActions)
        this.getAllAdminFeedBackForms(adminActions)
        getAdminFaq(adminActions)
    }
}

const teacherData = {
    getTeacherCards: (userActions, userId) => fetchDataParams(GET.GET_TEACHER_CARDS, userActions.addTeacherCards, userId),
    getTeacherVideos: (userActions, userId) => fetchDataParams(GET.GET_TEACHER_VIDEOS, userActions.addTeacherVideos, userId),
    getTeacherForms: (userActions, userId) => fetchDataParams(GET.ALL_QUESTIONS_FORM, userActions.addAllForms, userId),
    getMyStudents: (userActions, userId) => fetchDataParams(GET?.GET_MY_STUDENT, userActions.addMyStudents, userId),
    getOtherUserQuestions: (userActions, userId) => fetchDataParams(GET.GET_OTHER_QUESTIONS, userActions.addAllOtherUserQuestions, userId),
    getMyQuestions: (userActions, userId) => fetchDataParams(ADMIN.GET_QUESTION, userActions.addAllQuestions, userId),
    getWalletTransactions: (userActions, userId) => fetchDataParams(GET?.GET_WALLET_TRANSACTION, userActions.addWalletTransaction, userId),
    getMyCashbacks: (userActions, userId) => fetchDataParams(GET.GET_USER_CASH_BACK, userActions.addMyCashbacks, userId),
    getMyFeedbackForms: (userActions, userId) => fetchDataParams(GET.GET_TEACHER_FEEDBACKS_FORMS, userActions.addTeacherFeedbackForm, userId),
    getAllAdminFeedbackFromForTeacher: (userActions, userId) => fetchDataParams(GET.GET_ADMIN_FEEDBACKS_FORMS_FOR_TEACHER, userActions.addAdminFeedbackFormForTeacer, userId),
    getAllTeacherCoupon: (userActions, userId) => fetchDataParams(ADMIN?.GET_ALL_COUPON_FOR_TEACHER, userActions.addTeacherCoupon, userId),
    getTeacherToStudentResponse: (userActions, userId) => fetchDataParams(GET.GET_TEACHER_TO_STUDENT_RESPONSES, userActions.addTeacherToStudentResponse, userId),

    all: function (userActions, userId) {
        this.getTeacherCards(userActions, userId)
        this.getTeacherVideos(userActions, userId)
        this.getTeacherForms(userActions, userId)
        this.getMyStudents(userActions, userId)
        this.getOtherUserQuestions(userActions, userId)
        this.getMyQuestions(userActions, userId)
        this.getWalletTransactions(userActions, userId)
        this.getMyCashbacks(userActions, userId)
        this.getMyFeedbackForms(userActions, userId)
        this.getAllAdminFeedbackFromForTeacher(userActions, userId)
        this.getAllTeacherCoupon(userActions, userId)
        this.getTeacherToStudentResponse(userActions, userId)
        getFaq(userActions)
    }
}
const studentData = {
    getStudentCards: (userActions, userId) => fetchDataParams(GET.GET_ALL_CARDS, userActions.addCards, userId),

    getStudentVideos: (userActions, userId) => fetchDataParams(GET.GET_STUDENT_VIDEOS, userActions.addStudentVideos, userId),

    getStudentExams: (userActions, userId) => fetchDataParams(GET.ALL_QUESTIONS_FORM, userActions.addAllForms, userId),
    // getStudentExams: (userActions, userId) => fetchDataParamsWithCond(GET.ALL_QUESTIONS_FORM, userActions.addAllForms, userId),

    getStudentResults: (userActions, userId) => fetchDataParams(GET?.GET_STUDENT_RESULT, userActions.addStudentResults, userId),

    getWalletTransactions: (userActions, userId) => fetchDataParams(GET?.GET_WALLET_TRANSACTION, userActions.addWalletTransaction, userId),

    getMyCashbacks: (userActions, userId) => fetchDataParams(GET.GET_USER_CASH_BACK, userActions.addMyCashbacks, userId),

    getAllFeedbackForm: (userActions, userId) => fetchDataParams(GET.GET_ALL_FEEDBACKS_FORMS, userActions.addAllFeedbackForm, userId),

    getStudentTrainResults: (userActions, userId) => fetchDataParams(GET?.GET_STUDENT_TRAIN_RESULT, userActions.addStudentTrainResults, userId),

    getDashboardData: (userActions, userId) => fetchDataParams(GET?.GET_STUDENT_DASHBOARD_DATA, userActions.addDashboardData, userId, true),

    getTeacherResponses: (userActions, userId) => fetchDataParams(GET?.GET_TEACHER_RESPONSES, userActions.addTeacherResponses, userId, false),

    all: function (userActions, userId) {
        this.getStudentCards(userActions, userId)
        this.getStudentVideos(userActions, userId)
        this.getStudentExams(userActions, userId)
        this.getStudentResults(userActions, userId)
        this.getWalletTransactions(userActions, userId)
        this.getStudentTrainResults(userActions, userId)
        this.getDashboardData(userActions, userId)
        this.getMyCashbacks(userActions, userId)
        this.getTeacherResponses(userActions, userId)
        this.getAllFeedbackForm(userActions, userId)

        getFaq(userActions)
    }
}

const getAllUserData = async (userId, userActions) => {

    axios.get(`${GET.FORM_REPORTS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addAllFormReports(data?.data || [])
        })
}

const getAllData = async (userId, adminActions) => {
    axios.get(`${ADMIN.GET_QUESTION}/${userId}`)
        .then((res) => {
            const { data } = res
            adminActions.addAllAdminQuestions(data?.data || [])
        })
    axios.get(`${ADMIN.GET_QUESTION_APPROVALS}/${userId}`)
        .then((res) => {
            const { data } = res
            adminActions.addAllAdminQuestionsApprovals(data?.data || [])
        })

    axios.get(`${GET.ALL_GUEST_USERS}/${userId}`)
        .then((res) => {
            const { data } = res
            adminActions?.addAllGuestUsers(data?.data || [])
        })
    axios.get(`${GET.ALL_BUSINESS_USERS}/${userId}`)
        .then((res) => {
            const { data } = res
            // adminActions?.addAllBusinessUsers(data?.data || [])
        })

    // axios.get(ADMIN.GET_TUTIONS)
    //     .then((res) => {
    //         const { data } = res
    //         adminActions?.addAllTutions(data?.data || [])
    //     })
    axios.get(ADMIN.GET_ADMIN_FAQ)
        .then((res) => {
            const { data } = res
            data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
            if (Object.keys(data?.FaqArrayGrourp).length > 0) {
                const arr = Object.entries(data?.FaqArrayGrourp);
                arr.forEach(([key, value]) => {
                    value.sort((a, b) => a.priority - b.priority);
                });
                const sortedObj = Object.fromEntries(arr);
                adminActions?.addAdminFaq([data?.howIsWorkArray || [], sortedObj || {}])
            } else {
                adminActions?.addAdminFaq([data?.howIsWorkArray || [], data?.FaqArrayGrourp || {}])
            }
        }).catch((e) => {
            adminActions?.addAdminFaq([])
        })

    axios.get(`${ADMIN.GET_SPECIAL_QUESTION}`)
        .then((res) => {
            const { data } = res
            adminActions.addAllAdminSpecialQuestions(data?.data || [])
        })
    axios.get(`${ADMIN.GET_ALL_CARDS}`)
        .then((res) => {
            const { data } = res
            adminActions.addAllAdminCards(data?.data || [])
        })
}

const getGeneralData = async (generalActions) => {
    axios.get(GET?.GET_COUNTRY_TAX)
        .then((res) => {
            const { data } = res
            generalActions?.setTaxes(data?.data || [])
        })
    axios.get(GET?.GET_ALL_TEACHERS)
        .then((res) => {
            const { data } = res
            generalActions?.setAllTeachers(/* data?.data || */[])
        })
    axios.get(ADMIN?.GET_ALL_CHAPTERS)
        .then((res) => {
            const { data } = res
            generalActions?.setAllChapters(data?.data || [])
        })
    axios.get(ADMIN?.GET_SUBJECTS)
        .then((res) => {
            const { data } = res
            generalActions?.setAllSubjects(data?.data || [])
        })
}

const toBase64 = async (url) => {
    try {
        const data = await fetch(url)
        const blob = await data.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
            }
        })
    } catch (e) {
        console.log('e', e)
    }
}

const createFeeReport = async (invoice) => {
    console.log('invoice', invoice)
    let user = invoice?.userId

    let totalQuestions = invoice?.payments?.length
    let totalFee = invoice?.payments?.reduce((sum, { fee }) => sum + fee, 0)
    totalFee = convertRoundNumber(totalFee)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })

    let x = 100

    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, x)
    }
    x += 20
    doc.text(user?.fullName, 60, x)
    x += 20
    doc.text(user?.streetNumber, 60, x)
    x += 20
    doc.text(user?.postalCodeCity, 60, x)
    x += 20
    doc.text(user?.country, 60, x)
    x += 20
    x += 20

    doc.fontSize(14).text(`Kosten rapport:     ${invoice?.reportNumber}`, 60, x)
    x += 20
    doc.fontSize(14).text(`Rapport datum range:     ${dateFormater(invoice?.date1)} - ${dateFormater(invoice?.date2)}`, 60, x)
    x += 20
    doc.fontSize(14).text(`Totaal van vragen:     ${totalQuestions}`, 60, x)
    x += 10

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, x)

    x += 20

    // let x = 280
    let questionVal = 60
    let feeVal = 400
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Vraag`, questionVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Kosten per vraag`, feeVal, x)
    x += 20

    for (let payment of invoice?.payments) {
        console.log(payment)
        let question = payment?.question?.question

        if (question?.length > 52) {
            doc.font('Helvetica').fontSize(11).text(`${question.slice(0, 52)}...`, questionVal, x)
        } else {
            doc.font('Helvetica').fontSize(11).text(`${question}`, questionVal, x)
        }

        doc.font('Helvetica').fontSize(11).text(`€ ${convertRoundNumber(payment?.fee)}`, feeVal, x)

        if (x > 730) {
            doc.addPage()
            x = 20
        } else {
            x += 20
        }
    }

    if (x > 730) {
        doc.addPage()
        x = 20
    } else {
        x += 30
    }

    doc.font('Helvetica-Bold').fontSize(11).text(`Totaal`, questionVal, x)
    doc.font('Helvetica').fontSize(11).text(`€ ${totalFee}`, feeVal, x)

    x = x + 10

    doc.text('______________________________________________________________________________', 60, x)

    if (x > 730) {
        doc.addPage()
        x = 20
    } else {
        x += 20
    }

    doc.font('Helvetica-Bold').text('www.testally.com Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    if (x > 730) {
        doc.addPage()
        x = 20
    } else {
        x += 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        let url = stream.toBlobURL('application/pdf')
        window.open(url, '_blank')
    })
}



function findCountryByCode(countryCodesArray, providedCountryCode) {
    const foundCountry = countryCodesArray.find(country => country.code === providedCountryCode);
    return foundCountry ? { name: foundCountry.name, code: foundCountry.code } : null;
}

const createTeacherInvoicePdf = async (invoice, user, action, viewInvoice) => {
    const doc = new PDFDocument()
    const stream = doc.pipe(blobStream())
    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    console.log('invoice', invoice)
    let getCountryName = findCountryByCode(countryCodes, user?.country)

    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }
    doc.text(user?.fullName, 60, 120)
    doc.text(user?.streetNumber, 60, 140)
    doc.text(`${user?.postalCodeCity} ${' '} ${user?.city}`, 60, 160)
    doc.text(getCountryName?.name, 60, 180)

    doc.fontSize(14).text(`Factuurnummer: ${invoice?.invoiceNumber}`, 60, 220)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)
    let x = 280
    let leftVal = 360
    let rightVal = 530
    let subType = invoice?.subscriptionType === 'basic' ? 'Exams' : invoice?.durationType

    if (invoice?.couponRate) {

        doc.text(`Subscription A: ${invoice?.subscriptionName}     ${invoice?.quantity} x € ${invoice?.couponRate} /Piece`, 60, x)

        let price = invoice?.couponRate * invoice?.quantity
        price = convertNumber(price)

        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
        x = x + 20

    } else if (invoice?.contentSubscription) {
        doc.text(`Subscription : ${invoice?.subscriptionName?.slice(0, 50)}  ${invoice?.duration} ${subType} ${invoice?.extend && ' (Extend)' || ''}`, 60, x)

        let price = convertNumber(invoice?.subscriptionAmount)

        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
    }
    else if (invoice?.subscriptionAmount) {
        doc.text(`Abbonnement A: ${invoice?.subscriptionName?.slice(0, 50)}`, 60, x)
        let price = convertNumber(invoice?.subscriptionAmount)

        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
        x = x + 20
    }
    x = x + 80
    doc.text('Subtotal', leftVal, x)
    let SubTotaal = convertNumber(invoice?.couponUsed ? ((invoice?.totalAmount - invoice?.couponDiscount) * (100 / 121)) : invoice?.totalAmount * (100 / 121))
    let total = convertNumber(invoice?.couponUsed ? ((invoice?.totalAmount) - (invoice?.couponDiscount)) : invoice?.totalAmount)
    let btw = convertNumber(invoice?.couponUsed ? (invoice?.totalAmount - invoice?.couponDiscount) * (21 / 121) : (invoice?.totalAmount) * (21 / 121))
    if (invoice?.subscriptionName !== 'Wallet Recharge subscription') {
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${SubTotaal}`, rightVal - (SubTotaal?.length * 4), x)
        x = x + 20
        doc.text('21 % VAT', leftVal, x)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(btw, rightVal - (btw?.length * 4), x)
        if (invoice?.couponUsed) {
            x = x + 20
            let discountCoupon = convertNumber(invoice?.couponDiscount)
            doc.text(`Kortingsbedrag`, leftVal, x)
            doc.text(`€ `, rightVal - (7 * 5), x)
            doc.text(discountCoupon, rightVal - (discountCoupon?.length * 4), x)
        }
        x = x + 20
        doc.text('Totaal incl BTW', leftVal, x)
    } else {
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(total, rightVal - (total?.length * 4), x)
        x = x + 20
        doc.text('0 % VAT', leftVal, x)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text('0.00', rightVal - (16), x)
        if (invoice?.couponUsed) {
            x = x + 20
            let discountCoupon = convertNumber(invoice?.couponDiscount)
            doc.text(`Kortingsbedrag`, leftVal, x)
            doc.text(`€ `, rightVal - (7 * 5), x)
            doc.text(discountCoupon, rightVal - (discountCoupon?.length * 4), x)
        }
        x = x + 20
        doc.text('Total', leftVal, x)
    }
    doc.text(`€ `, rightVal - (7 * 5), x)
    doc.text(total, rightVal - (total?.length * 4), x)
    x = x + 40
    x = x + 20
    doc.text('______________________________________________________________________________________', 60, x)
    x = x + 20
    doc.font('Helvetica-Bold').text('www.testally.com Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    x = x + 20
    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)
    doc.end()
    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}
const createStudentInvoicePdf = async (invoice, user, action, viewInvoice) => {
    const doc = new PDFDocument()
    const stream = doc.pipe(blobStream())
    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    console.log('invoice', invoice)
    let getCountryName = findCountryByCode(countryCodes, user?.country)

    if (!invoice?.teacherSubscription) {
        if (user?.businessName) {
            doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
        }
        doc.text(user?.fullName, 60, 120)
        doc.text(user?.streetNumber, 60, 140)
        doc.text(`${user?.postalCodeCity} ${' '} ${user?.city}`, 60, 160)
        doc.text(getCountryName?.name, 60, 180)
    }

    if (invoice?.teacherSubscription) {
        doc.text(`${user?.fullName}`, 60, 120)
        doc.text(user?.country, 60, 140)
        doc.text(`Leraar abbonnement (${invoice?.teacherId?.fullName})`, 60, 170)
        doc.text(`Leraar bedrijfsnaam : ${invoice?.teacherId?.businessName}`, 60, 190)
    }

    doc.fontSize(14).text(`Factuurnummer: ${invoice?.invoiceNumber}`, 60, 220)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)
    let x = 280
    let leftVal = 360
    let rightVal = 530
    let subType = invoice?.subscriptionType === 'basic' ? 'Exams' : invoice?.durationType

    if (invoice?.contentSubscription) {
        doc.text(`Subscription : ${invoice?.subscriptionName?.slice(0, 50)}  ${invoice?.duration} ${subType} ${invoice?.extend && ' (Extend)' || ''}`, 60, x)

        let price = convertNumber(invoice?.subscriptionAmount)

        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
    }
    else if (invoice?.subscriptionAmount) {
        doc.text(`Abbonnement A: ${invoice?.subscriptionName?.slice(0, 50)}`, 60, x)
        let price = convertNumber(invoice?.subscriptionAmount)

        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
        x = x + 20
    }

    x = x + 80
    doc.text('Subtotal', leftVal, x)
    let SubTotaal = convertNumber(invoice?.couponUsed ? ((invoice?.totalAmount - invoice?.couponDiscount) * (100 / 121)) : invoice?.totalAmount * (100 / 121))
    let total = convertNumber(invoice?.couponUsed ? ((invoice?.totalAmount) - (invoice?.couponDiscount)) : invoice?.totalAmount)
    let btw = convertNumber(invoice?.couponUsed ? (invoice?.totalAmount - invoice?.couponDiscount) * (21 / 121) : (invoice?.totalAmount) * (21 / 121))
    if (invoice?.subscriptionName !== 'Wallet Recharge subscription') {
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${SubTotaal}`, rightVal - (SubTotaal?.length * 4), x)
        x = x + 20
        doc.text('21 % VAT', leftVal, x)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(btw, rightVal - (btw?.length * 4), x)
        if (invoice?.couponUsed) {
            x = x + 20
            let discountCoupon = convertNumber(invoice?.couponDiscount)
            doc.text(`Kortingsbedrag`, leftVal, x)
            doc.text(`€ `, rightVal - (7 * 5), x)
            doc.text(discountCoupon, rightVal - (discountCoupon?.length * 4), x)
        }
        x = x + 20
        doc.text('Totaal incl BTW', leftVal, x)
    } else {
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(total, rightVal - (total?.length * 4), x)
        x = x + 20
        doc.text('0 % VAT', leftVal, x)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text('0.00', rightVal - (16), x)
        if (invoice?.couponUsed) {
            x = x + 20
            let discountCoupon = convertNumber(invoice?.couponDiscount)
            doc.text(`Kortingsbedrag`, leftVal, x)
            doc.text(`€ `, rightVal - (7 * 5), x)
            doc.text(discountCoupon, rightVal - (discountCoupon?.length * 4), x)
        }
        x = x + 20
        doc.text('Total', leftVal, x)
    }
    doc.text(`€ `, rightVal - (7 * 5), x)
    doc.text(total, rightVal - (total?.length * 4), x)
    x = x + 40
    x = x + 20
    doc.text('______________________________________________________________________________________', 60, x)
    x = x + 20
    doc.font('Helvetica-Bold').text('www.testally.com Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    x = x + 20
    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)
    doc.end()
    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}
const createInvoicePdf = async (invoice, user, action, viewInvoice) => {
    const doc = new PDFDocument()
    const stream = doc.pipe(blobStream())
    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    console.log('invoice', invoice)
    let getCountryName = findCountryByCode(countryCodes, user?.country)

    if (!invoice?.teacherSubscription) {
        if (user?.businessName) {
            doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
        }
        doc.text(user?.fullName, 60, 120)
        doc.text(user?.streetNumber, 60, 140)
        doc.text(`${user?.postalCodeCity} ${' '} ${user?.city}`, 60, 160)
        doc.text(getCountryName?.name, 60, 180)
    }

    if (invoice?.teacherSubscription) {
        doc.text(`${user?.fullName}`, 60, 120)
        doc.text(user?.country, 60, 140)
        doc.text(`Leraar abbonnement (${invoice?.teacherId?.fullName})`, 60, 170)
        doc.text(`Leraar bedrijfsnaam : ${invoice?.teacherId?.businessName}`, 60, 190)
    }

    doc.fontSize(14).text(`Factuurnummer: ${invoice?.invoiceNumber}`, 60, 220)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)
    let x = 280
    let leftVal = 360
    let rightVal = 515
    if (invoice?.subscriptionType === 'basic') {
        doc.text(`Abbonnement : ${invoice?.subscriptionName?.slice(0, 50)} ${invoice?.exams} Exams`, 60, x)
        let price = convertNumber(invoice?.subscriptionAmount)
        doc.text(`€ ${price}`, rightVal - (price?.length * 5), x)
        x = x + 20
        doc.text(invoice?.subscriptionName?.slice(51), 60, x)
        x = x + 20
    } else if (invoice?.contentSubscription) {
        doc.text(`Abbonnement : ${invoice?.subscriptionName?.slice(0, 50)} ${invoice?.extend && ' (Verleng)' || ''}`, 60, x)
        let price = convertNumber(invoice?.subscriptionAmount)
        doc.text(`€ ${price}`, rightVal - (price?.length * 5), x)
        x = x + 20
        doc.text(invoice?.subscriptionName?.slice(51), 60, x)
        x = x + 20
    }
    else if (invoice?.subscriptionAmount) {
        doc.text(`Abbonnement A: ${invoice?.subscriptionName?.slice(0, 50)}`, 60, x)
        let price = convertNumber(invoice?.subscriptionAmount)
        doc.text(`€ ${price}`, rightVal - (price?.length * 5), x)
        x = x + 20
        doc.text(invoice?.subscriptionName?.slice(51), 60, x)
        x = x + 20
    }

    x = x + 80
    doc.text('Subtotaal', leftVal, x)
    let SubTotaal = convertNumber(invoice?.couponUsed ? ((invoice?.totalAmount - invoice?.couponDiscount) * (100 / 121)) : invoice?.totalAmount * (100 / 121))
    let total = convertNumber(invoice?.couponUsed ? ((invoice?.totalAmount) - (invoice?.couponDiscount)) : invoice?.totalAmount)
    let btw = convertNumber(invoice?.couponUsed ? (invoice?.totalAmount - invoice?.couponDiscount) * (21 / 121) : (invoice?.totalAmount) * (21 / 121))
    if (invoice?.subscriptionName !== 'Wallet Recharge subscription') {
        doc.text(`$ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)
        x = x + 20
        doc.text('21 % VAT', leftVal, x)
        doc.text(`$ ${btw}`, rightVal - (btw?.length * 5), x)
        if (invoice?.couponUsed) {
            x = x + 20
            doc.text(`Kortingsbedrag`, leftVal, x)
            doc.text(`€ ${convertNumber(invoice?.couponDiscount)}`, rightVal - (btw?.length * 5), x)
        }
        x = x + 20
        doc.text('Totaal incl BTW', leftVal, x)
    } else {
        doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)
        x = x + 20
        doc.text('0 % VAT', leftVal, x)
        doc.text(`€ 0.00`, rightVal - (btw?.length * 5), x)
        if (invoice?.couponUsed) {
            x = x + 20
            doc.text(`Kortingsbedrag`, leftVal, x)
            doc.text(`€ ${convertNumber(invoice?.couponDiscount)}`, rightVal - (btw?.length * 5), x)
        }
        x = x + 20
        doc.text('Total', leftVal, x)
    }
    doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)
    x = x + 40
    x = x + 20
    doc.text('______________________________________________________________________________________', 60, x)
    x = x + 20
    doc.font('Helvetica-Bold').text('www.testally.com Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    x = x + 20
    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)
    doc.end()
    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}
const createCbrTeacherInvoicePdf = async (invoice, user, action, viewInvoice) => {
    const doc = new PDFDocument()
    const stream = doc.pipe(blobStream())
    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    console.log('invoice---', invoice)
    let getCountryName = findCountryByCode(countryCodes, user?.country)

    if (!invoice?.teacherSubscription) {
        if (user?.businessName) {
            doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
        }
        doc.text(user?.fullName, 60, 120)
        doc.text(user?.streetNumber, 60, 140)
        doc.text(`${user?.postalCodeCity} ${' '} ${user?.city}`, 60, 160)
        doc.text(getCountryName?.name, 60, 180)
    }

    doc.fontSize(14).text(`Invoice Number: ${invoice?.invoiceNumber}`, 60, 220)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)
    let x = 280
    let leftVal = 360
    let rightVal = 530
    let subType = invoice?.subscriptionType === 'basic' ? 'Exams' : invoice?.durationType

    let subTotal = invoice?.subscriptionAmount - (invoice?.subscriptionAmount * 21 / 121)
    subTotal = convertNumber(subTotal)

    let total = convertNumber(invoice?.subscriptionAmount)
    if (invoice?.contentSubscription) {
        let content = invoice?.subscriptionType === 'basic' ? 'Exams' : invoice?.durationType
        doc.text(`Subscription : ${invoice?.subscriptionName?.slice(0, 50)}  ${invoice?.duration} ${content} ${invoice?.extend && ' (Extend)' || ''}`, 60, x)

        let price = subTotal

        console.log(price, price.length)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
    } else if (invoice?.coupon) {

        doc.text(`Subscription A: ${invoice?.subscriptionName} (${convertTitle(invoice?.subscriptionType)} ${invoice?.subscriptionItems} ${checkDurationType(invoice)})     ${invoice?.quantity} x € ${invoice?.couponRate} /Piece`, 60, x)

        let price = subTotal

        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
    }

    x = x + 80

    let btw = invoice?.subscriptionAmount * 21 / 121
    btw = convertNumber(btw)

    doc.text('Subtotal', leftVal, x)
    doc.text(`€ `, rightVal - (7 * 5), x)
    doc.text(subTotal, rightVal - (subTotal?.length * 4), x)

    x = x + 20

    doc.text('21 % BTW', leftVal, x)
    doc.text('€ ', rightVal - (7 * 5), x)
    doc.text(btw, rightVal - (btw?.length * 4), x)

    x = x + 20
    doc.text('Total', leftVal, x)
    doc.text('€ ', rightVal - (7 * 5), x)
    doc.text(total, rightVal - (total?.length * 4), x)

    x = x + 40
    x = x + 20
    doc.text('______________________________________________________________________________________', 60, x)
    x = x + 20
    doc.font('Helvetica-Bold').text('www.testally.com Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    x = x + 20
    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)
    doc.end()
    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}
const createCbrStudentInvoicePdf = async (invoice, user, action, viewInvoice) => {
    const doc = new PDFDocument()
    const stream = doc.pipe(blobStream())
    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    console.log('invoice', invoice)
    let getCountryName = findCountryByCode(countryCodes, user?.country)

    if (!invoice?.teacherSubscription) {
        if (user?.businessName) {
            doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
        }
        doc.text(user?.fullName, 60, 120)
        doc.text(user?.streetNumber, 60, 140)
        doc.text(`${user?.postalCodeCity} ${' '} ${user?.city}`, 60, 160)
        doc.text(getCountryName?.name, 60, 180)
    }

    doc.fontSize(14).text(`Invoice Number: ${invoice?.invoiceNumber}`, 60, 220)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)
    let x = 280
    let leftVal = 360
    let rightVal = 530
    let subType = invoice?.subscriptionType === 'basic' ? 'Exams' : invoice?.durationType

    if (invoice?.contentSubscription && invoice?.coupon && invoice?.couponUsed) {

        doc.text(`Coupon ( ${convertTitle(invoice?.subscriptionType)} ${invoice?.subscriptionItems} ${subType} )`, 60, x)
    } else if (invoice?.contentSubscription) {
        let content = invoice?.subscriptionType === 'basic' ? 'Exams' : invoice?.durationType
        doc.text(`Subscription : ${invoice?.subscriptionName?.slice(0, 50)}  ${invoice?.duration} ${content} ${invoice?.extend && ' (Extend)' || ''}`, 60, x)

        let price = convertNumber(invoice?.subscriptionAmount)

        console.log(price, price.length)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(`${price}`, rightVal - (price?.length * 4), x)
    }

    x = x + 80

    let btw = invoice?.subscriptionAmount * 21 / 121
    btw = convertNumber(btw)

    let subTotal = invoice?.subscriptionAmount - (invoice?.subscriptionAmount * 21 / 121)
    subTotal = convertNumber(subTotal)

    let total = convertNumber(invoice?.subscriptionAmount)

    if (invoice?.contentSubscription && !invoice?.coupon && !invoice?.couponUsed) {
        doc.text('Subtotal', leftVal, x)
        doc.text(`€ `, rightVal - (7 * 5), x)
        doc.text(subTotal, rightVal - (subTotal?.length * 4), x)

        x = x + 20

        doc.text('21 % BTW', leftVal, x)
        doc.text('€ ', rightVal - (7 * 5), x)
        doc.text(btw, rightVal - (btw?.length * 4), x)

        x = x + 20
        doc.text('Total', leftVal, x)
        doc.text('€ ', rightVal - (7 * 5), x)
        doc.text(total, rightVal - (total?.length * 4), x)
    }
    x = x + 40
    x = x + 20
    doc.text('______________________________________________________________________________________', 60, x)
    x = x + 20
    doc.font('Helvetica-Bold').text('www.testally.com Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    x = x + 20
    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)
    doc.end()
    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}
const createCashBackReport = async (user, dateRange, pendingRequest) => {
    console.log(pendingRequest, "=pendingRequest")


    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    const formattedDate1 = moment(dateRange?.date1, 'YYYY-MM-DD').format('ddd MMM DD YYYY');
    const formattedDate2 = moment(dateRange?.date2, 'YYYY-MM-DD').format('ddd MMM DD YYYY');

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'

    let imageBase64 = await toBase64(iconUrl)

    // doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })


    doc.text(user?.name, 60, 120)
    doc.text(user?.country, 60, 140)

    doc.fontSize(14).text(`Rapport van terugbetalingsverzoeken`, 60, 220)
    doc.fontSize(14).text(`Datum:${moment(Date.now()).format('DD-MM-YYYY')}`, 60, 240)
    doc.fontSize(14).text(`Rapport datum range: ${formattedDate1}-${formattedDate2}`, 60, 260)
    doc.fontSize(14).text(`Totaal van terugbetalingsverzoeken: ${pendingRequest?.length || 0}`, 60, 280)

    doc.font('Helvetica').fontSize(10).text('____________________________________________________________________________________________', 60, 290)

    let x = 310
    let indVal = 60
    let fullname = 100
    let quantityVal = 215
    let costVal = 340
    let amountVal = 470


    doc.font('Helvetica-Bold').fontSize(11).text(`S.no`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Voor- en achternaam`, fullname, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Bankrekeningnummer`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`IBAN `, costVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Bedrag`, amountVal, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }
    var totalAmount


    {
        pendingRequest?.length &&
            pendingRequest?.map((v, index) => {
                totalAmount = totalAmount ? totalAmount + v?.amount : v?.amount

                doc.font('Helvetica').fontSize(11).text(`${(index + 1)}`, indVal, x)
                doc.font('Helvetica').fontSize(11).text(`${(v?.playerId?.fullName)}`, fullname, x)
                doc.font('Helvetica').fontSize(11).text(`${(v?.playerId?.bankAccount?.length > 18 ? ((v?.playerId?.bankAccount).substring(0, 18)) + '...' : v?.playerId?.bankAccount)}`, quantityVal, x)
                doc.font('Helvetica').fontSize(11).text(`${(v?.playerId?.IBAN?.length > 18 ? ((v?.playerId?.IBAN).substring(0, 16)) + '...' : v?.playerId?.IBAN)}`, costVal, x)
                doc.font('Helvetica').fontSize(11).text(`€ ${(convertRoundNumber(v?.amount))}`, amountVal, x)
                if (x >= 700) {
                    doc.addPage()
                    x = 30
                } else {
                    x = x + 20
                }
            })
    }

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }


    doc.font('Helvetica').fontSize(11).text(`Totaal`, costVal, x)
    doc.font('Helvetica').fontSize(11).text(`€ ${(convertRoundNumber(totalAmount))}`, amountVal, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.text('____________________________________________________________________________________', 60, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').text('www.testally.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'cashbackReport.pdf'
        a.click()
    })
}

// const toBase64 = async (url) => {
//     try {
//         console.log('url---->', url)
//         const data = await fetch(url, { mode: 'no-cors' })
//         const blob = await data.blob()
//         console.log('blob-->', blob)
//         return new Promise((resolve) => {
//             const reader = new FileReader()
//             reader.readAsDataURL(blob)
//             reader.onloadend = () => {
//                 const base64data = reader.result
//                 console.log('reader', reader)
//                 resolve(base64data)
//             }
//         })
//     } catch (e) {
//         console.log('e', e)
//     }
// }

const createGiftCard = async (arr, spinFunc) => {
    try {
        const doc = new PDFDocument({ size: [907.1, 1275.6] })
        const stream = doc.pipe(blobStream())

        let width = 209.76
        let height = 147.4

        var x = 13
        var y = 10
        console.log('a')
        let iconUrl = arr[0].imageUrl || 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'
        console.log('iconUrl-->', iconUrl)
        let imageBase64 = await toBase64(iconUrl)
        // let imageBase64 = await downloadAsDataURL(iconUrl)
        console.log("img--->", imageBase64)

        for (var i in arr) {
            console.log('e')

            doc.image(imageBase64, x, y, { width, height })

            var index = Number(i) + 1

            if (index % 32 === 0) {
                x = 13
                y = 10
                doc.addPage()
            }
            else if (index % 4 === 0) {
                x = 13
                y += height + 10
            }
            else {
                x += width + 14
            }
        }
        console.log('b')
        doc.addPage()

        var xAxis = 0
        var yAxis = 0
        let QRwidth = 180
        let QRheight = 100


        for (var i in arr) {
            console.log('c')

            let obj = arr[i]
            console.log('obj', obj)
            let code = obj?.couponCode || obj?.giftCardCode
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 20 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(20 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 153 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(215 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()

            let qrImage = await generateQR(code)

            let qrX = 60 + xAxis
            let qrY = 21 + yAxis

            doc.image(qrImage, qrX, qrY, { QRwidth, QRheight })

            y = 100 + yAxis
            x = 80 + xAxis
            let lineX = 78 + xAxis
            let lineY = 114 + yAxis
            var lineXEnd = 156 + xAxis

            lineY = lineY + 35
            y = y + 35

            // doc.text('abcd', x, y-5)
            // 
            doc.text(code, x, y)
            doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

            var index = Number(i) + 1

            if (index % 32 === 0) {
                xAxis = 0
                yAxis = 0
                doc.addPage()
            }
            else if (index % 4 === 0) {
                xAxis = 0
                yAxis += height + 10
            }
            else {
                xAxis += width + 14
            }
        }
        console.log('d')

        doc.end()

        stream.on('finish', () => {
            const blob = stream.toBlob('application/pdf')
            const blobUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')

            spinFunc()

            a.href = blobUrl
            a.download = 'coupon.pdf'
            a.click()
        })
    } catch (e) {
        console.log('error', e)
        spinFunc()
        return errorMessage('Oeps, er is iets misgegaan!')
    }

}

const formattedStringDate = (date) => {
    const givenDate = moment(date)

    const currentDate = moment()

    const duration = moment.duration(givenDate.diff(currentDate))

    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()

    let formattedDifference = ''
    if (years > 0) {
        formattedDifference += years + (years === 1 ? ' yr ' : ' yrs ')
    }

    if (months > 0) {
        formattedDifference += months + (months === 1 ? ' month ' : ' months ')
    }

    if (days > 0) {
        formattedDifference += days + (days === 1 ? ' day' : ' days ')
    }

    if (!years && !months && hours > 0) {
        formattedDifference += hours + (hours === 1 ? ' hour ' : ' hours ')
    }

    if (!years && !months && !hours && minutes > 0) {
        formattedDifference += minutes + (minutes === 1 ? ' minute' : ' minutes')
    }

    return formattedDifference
}

const secondsToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}

const checkExpiry = (expirydate) => {
    const expiry = moment(expirydate);
    const currentDate = moment();
    const hasExpired = expiry.isBefore(currentDate);
    return hasExpired
}
const checkSubscription = (sub) => {
    if (sub?.subscriptionType === contentType[0]?.value && !checkExpiry(sub?.expiry)) {
        return contentType[0]?.value
    } else if (sub?.subscriptionType === contentType[1]?.value && !checkExpiry(sub?.expiry)) {
        return contentType[1]?.value
    } else {
        return false
    }
}

function sortFormsByAttempted(formsArray) {
    // Sorting the array based on the 'attempted' property
    formsArray.sort((a, b) => {
        if (a.attempted === b.attempted) {
            return 0;
        } else if (a.attempted) {
            return 1;
        } else {
            return -1;
        }
    });

    return formsArray;
}

const paraText = (questions, language) => {
    const correctQuestions = questions.filter((v) => v?.correct)
    const correctPercentage = (correctQuestions.length / questions.length) * 100
    if (correctPercentage >= 90) {
        return 'Congratulations! You achieved an outstanding score of 90% or higher.'
    } else if (correctPercentage >= 70) {
        return 'Well done! You scored a commendable 70% or higher.'
    } else if (correctPercentage >= 60) {
        return 'Not bad! Your score falls between 60% and 70%.'
    } else {
        return 'Er is ruimte voor verbetering! U heeft minder dan 60% goed beantwoord.'
    }
}


function checkArrayForThesis(array) {
    const isTrueFirst = array[0].isTrue;
    const isTrueSecond = array[1].isTrue;

    if (isTrueFirst && !isTrueSecond) {
        return 1;
    }
    else if (!isTrueFirst && isTrueSecond) {
        return 2;
    }
    else if (isTrueFirst && isTrueSecond) {
        return 3;
    }
    else if (!isTrueFirst && !isTrueSecond) {
        return 4;
    }
}

// review Avrage
const calculateReviewAverage = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.average;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)

}

const calculateAverageForRelevance = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.relevance;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}

const calculateAverageForClarity = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.clarity;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}

const calculateAverageForDetailed = (array) => {
    var total = 0
    var count = 0

    array?.forEach((item, index) => {
        total += item?.detailed;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForImages = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.images;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}

const calculateAverageForOverall = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.overall;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForLanguageUse = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.languageUse;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}

const createBuOrderReport = async (invoice, user, action, viewInvoice) => {
    // console.log('create Invoice Pdf function is working')
    // console.log('invoice', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    console.log('invoice===>', user, invoice)

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'

    let imageBase64 = await toBase64(iconUrl)

    let orderDate = moment(invoice?.created).format('YYYY-MM-DD')

    doc.image(imageBase64, 410, 10, { width: 150, height: 70 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }
    doc.text(user?.fullName, 60, 120)
    doc.text(user?.streetNumber, 60, 140)
    doc.text(user?.postalCodeCity, 60, 160)
    doc.text(user?.country, 60, 180)

    doc.fontSize(14).text(`Ordernummer:        ${invoice?.orderNumber}`, 60, 220)
    doc.fontSize(14).text(`Orderdatum:              ${dateFormater(orderDate)}`, 60, 240)

    doc.text(`Voorschoten`, 60, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 270)

    let x = 300
    let rightVal = 186
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Hoeveelheid: `, 60, x)
    let quantity = `${invoice?.quantity}`
    doc.font('Helvetica').fontSize(12).text(`${invoice?.quantity}`, rightVal - (quantity?.length * digitVal), x)
    x = x + 20

    // let productLength = `${invoice?.products?.length}`
    // doc.font('Helvetica-Bold').fontSize(11).text(`Products Related: `, 60, x)
    // doc.font('Helvetica').fontSize(12).text(`${productLength}`, rightVal - (productLength?.length * digitVal), x)
    // x = x + 20

    // let productLength = `${invoice?.products?.length}`
    // doc.font('Helvetica-Bold').fontSize(11).text(`Products Related: `, 60, x)
    // doc.font('Helvetica').fontSize(12).text(`${productLength}`, rightVal - (productLength?.length * digitVal), x)

    // if (invoice?.products?.length) {
    //     for (let product of invoice?.products) {
    //         x = x + 20

    //         doc.font('Helvetica').fontSize(11).text(`(•) ${product?.name} `, 70, x)
    //     }
    // }

    // x = x + 40

    // doc.text(`Wij zien uw betaling graag binnen 5 dagen tegemoet tnv 2keepintouch.com`, 60, x)

    x = x + 20

    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)

        if (action === 'view') {
            return viewInvoice(blobUrl)
        }
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'orderReport.pdf'

        a.click()
    })
}
let userTypes = ['guest', 'busniess']

let checkDurationType = (obj) => {
    if (obj.contentType === 'basic' /* && obj.userType === 'guest' */) {
        return 'Exams'
    } else {
        return convertTitle(obj?.durationType || '')
    }
}

let checkLuxurySub = (sub) => {
    return sub?.subscriptionType === 'luxury' && !checkExpiry(sub?.expiry)
}

let removeBaseUrls = (allQuestions) => {
    let newAllQues = JSON.parse(JSON.stringify(allQuestions))

    for (let questions of newAllQues) {
        let answerList = questions?.answerList
        let videoList = questions?.videos

        if (videoList?.length) {
            for (let k of videoList) {
                if (k?.file?.baseUrl) {
                    delete k?.file?.baseUrl
                }
            }
        }

        if (questions?.file?.baseUrl) {
            delete questions?.file?.baseUrl
        }
        if (questions?.dragFile?.baseUrl) {
            delete questions?.dragFile?.baseUrl
        }
        if (answerList?.length) {
            for (let y of answerList) {
                if (y?.file?.perUrl) {
                    delete y?.file?.baseUrl
                }
            }
        }
    }

    return newAllQues
}

const createExamReport = async (invoice, user, dateRange) => {
    let totalAmount = invoice?.reduce((sum, { amount }) => sum + amount, 0)
    totalAmount = convertRoundNumber(totalAmount)
    let totalFee = invoice?.reduce((sum, { fee }) => sum + fee, 0)
    totalFee = convertRoundNumber(totalFee)
    let totalTransactions = invoice?.reduce((sum, { transactions }) => sum + transactions, 0)
    totalTransactions = `${totalTransactions}`
    let totalQuantity = invoice?.reduce((sum, { quantity }) => sum + quantity, 0)
    totalQuantity = `${totalQuantity}`

    console.log('invoice', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 120)
    }
    doc.text(user?.fullName, 60, 140)
    doc.text(user?.streetNumber, 60, 160)
    doc.text(user?.postalCodeCity, 60, 180)
    doc.text(user?.country, 60, 200)

    let startX = 120
    doc.text(user?.userName, 60, startX)

    startX += 40
    doc.fontSize(14).text(`Exam Report`, 60, 220)
    doc.fontSize(14).text(`Created At:     ${dateFormater(moment())}`, 60, 240)
    doc.fontSize(14).text(`Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 280)

    let x = 300
    let subVal = 60
    let transactionVal = 170
    let quantityVal = 260
    let feeCostVal = 340
    let amountVal = 430
    let feeVal = 480
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Exam Name`, subVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Used`, transactionVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Passed`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Failed`, feeCostVal, x)
    x = x + 20
    let index = 1
    for (let inv of invoice) {
        let passString = inv?.passed.toString()
        let usedString = inv?.used.toString()
        let failedString = inv?.failed.toString()

        doc.font('Helvetica').fontSize(11).text(`${inv?.examName}`, subVal, x)
        doc.font('Helvetica').fontSize(11).text(usedString, transactionVal + 20 - (usedString?.length * digitVal), x)
        doc.font('Helvetica').fontSize(11).text(passString, 282 - (passString?.length * 5), x)
        doc.font('Helvetica').fontSize(11).text(`%`, 287, x)
        doc.font('Helvetica').fontSize(11).text(failedString, 357 - (failedString.length * 5), x)
        doc.font('Helvetica').fontSize(11).text(`%`, 362, x)
        index += 1
        if (x > 720) {
            doc.addPage()
            x = 20
        } else {
            x = x + 20
        }
    }

    doc.text('______________________________________________________________________________', 60, x)

    if (x > 720) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').text('testally.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    if (x > 760) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 115, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'ExamReport.pdf'

        a.click()
    })
}

const createCouponReport = async (invoice, user, dateRange) => {
    // console.log('create Invoice Pdf function is working')
    console.log('invoice', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    let startX = 120
    doc.text(user?.userName, 60, startX)

    startX += 40
    doc.fontSize(14).text(`Coupon Report`, 60, 220)
    doc.fontSize(14).text(`Report Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 260)

    let x = 300
    let rightVal = 286
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Testally Coupons `, 60, x)
    x = x + 30
    doc.font('Helvetica-Bold').fontSize(11).text(`Total BU Orders Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${invoice?.totalBuOrders}`, rightVal - (invoice?.totalBuOrders?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total BU Coupons Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${invoice?.totalBuCoupons}`, rightVal - (invoice?.totalBuCoupons?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total Admin Coupons Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${invoice?.totalAdminCoupons}`, rightVal - (invoice?.totalAdminCoupons?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total Amount: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`€ ${invoice?.totalTestallyAmount}`, rightVal - 5 - (invoice?.totalTestallyAmount?.length * digitVal), x)
    x = x + 30

    doc.font('Helvetica-Bold').fontSize(11).text(`CBR Coupons `, 60, x)
    x = x + 30

    doc.font('Helvetica-Bold').fontSize(11).text(`BASIC Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${invoice?.basicQuantity}`, rightVal - (invoice?.basicQuantity?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`LUXURY  Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${invoice?.luxuryQuantity}`, rightVal - (invoice?.luxuryQuantity?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total Amount: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`€ ${invoice?.totalCbrAmount}`, rightVal - 5 - (invoice?.totalCbrAmount?.length * digitVal), x)
    x = x + 30

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('testally.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 115, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'couponReport.pdf'

        a.click()
    })
}
const createSubReport = async (invoice, user, dateRange) => {
    console.log('invoice', invoice)
    console.log('invoice', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    doc.text(user?.fullName, 60, 140)
    doc.text(user?.streetNumber, 60, 160)
    doc.text(user?.postalCodeCity, 60, 180)
    doc.text(user?.country, 60, 200)

    let startX = 120
    doc.text(user?.userName, 60, startX)

    startX += 40
    doc.fontSize(14).text(`Subscription Report`, 60, 220)
    doc.fontSize(14).text(`Created At:     ${dateFormater(moment())}`, 60, 240)
    doc.fontSize(14).text(`Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 280)

    let x = 300
    let subVal = 60
    let quantityVal = 300
    let amountVal = 450
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Subscription Name`, subVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Quantity`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Amount`, amountVal, x)
    x = x + 20

    let groupInvoice = _.groupBy(invoice, 'userType')

    for (let v of Object.keys(groupInvoice)) {

        doc.font('Helvetica-Bold').fontSize(11).text(v, subVal, x)
        x = x + 20

        let totalAmount = groupInvoice[v]?.reduce((a, { amount }) => a + Number(amount), 0)
        totalAmount = convertRoundNumber(totalAmount)

        for (let inv of groupInvoice[v]) {
            let subName = inv?.name.toString()
            let quantity = inv?.quantity.toString()
            let amount = convertRoundNumber(inv?.amount)

            doc.font('Helvetica').fontSize(11).text(subName, subVal, x)
            doc.font('Helvetica').fontSize(11).text(quantity, quantityVal + 40 - (quantity?.length * digitVal), x)
            doc.font('Helvetica').fontSize(12).text(`€ `, amountVal - 10, x)
            doc.font('Helvetica').fontSize(11).text(amount, amountVal + 35 - (amount?.length * 4), x)
            if (x > 740) {
                doc.addPage()
                x = 20
            } else {
                x = x + 20
            }
        }

        doc.font('Helvetica-Bold').fontSize(11).text(`Total`, subVal, x)
        doc.font('Helvetica').fontSize(12).text(`€ `, amountVal - 10, x)
        doc.font('Helvetica-Bold').fontSize(11).text(totalAmount, amountVal + 35 - (totalAmount?.length * 4), x)

        if (x > 760) {
            doc.addPage()
            x = 20
        } else {
            x = x + 20
        }

        doc.text('______________________________________________________________________________', 60, x)

        if (x > 720) {
            doc.addPage()
            x = 20
        } else {
            x = x + 30
        }
    }
    let overAllTotal = invoice?.reduce((a, { amount }) => a + Number(amount), 0)
    overAllTotal = convertRoundNumber(overAllTotal)

    doc.font('Helvetica-Bold').fontSize(11).text(`Overall Total`, subVal, x)
    doc.font('Helvetica').fontSize(12).text(`€ `, amountVal - 10, x)
    doc.font('Helvetica-Bold').fontSize(11).text(overAllTotal, amountVal + 35 - (overAllTotal?.length * 4), x)

    if (x > 760) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.text('______________________________________________________________________________', 60, x)

    if (x > 720) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').text('testally.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    if (x > 760) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 115, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'SubscriptionReport.pdf'

        a.click()
    })
}

const createQuestionFeeReport = async (invoice, user, dateRange) => {
    console.log('invoice', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/abbas-docs/image/upload/v1682682923/testallyLogo_zdzjvd.png'
    let imageBase64 = await toBase64(iconUrl)
    doc.image(imageBase64, 310, 40, { width: 250, height: 40 })
    doc.text(user?.fullName, 60, 140)
    doc.text(user?.streetNumber, 60, 160)
    doc.text(user?.postalCodeCity, 60, 180)
    doc.text(user?.country, 60, 200)

    let startX = 120
    doc.text(user?.userName, 60, startX)

    startX += 40
    doc.fontSize(14).text(`Question Fee Report`, 60, 220)
    doc.fontSize(14).text(`Created At:     ${dateFormater(moment())}`, 60, 240)
    doc.fontSize(14).text(`Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 280)

    let x = 300
    let nameVal = 60
    let feeVal = 400
    let taxVal = 450
    let amountVal = 490
    let digitVal = 4

    doc.font('Helvetica-Bold').fontSize(11).text(`Question`, nameVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Fee`, feeVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`VAT`, taxVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Amount`, amountVal, x)
    x = x + 15

    doc.font('Helvetica').fontSize(9).text(`(€)`, feeVal + 4, x)
    doc.font('Helvetica').fontSize(9).text(`(€)`, taxVal + 5, x)
    doc.font('Helvetica').fontSize(9).text(`(€)`, amountVal + 17, x)
    x = x + 30
    let index = 1
    for (let inv of invoice) {
        if (inv?.question?.length > 67) {
            doc.font('Helvetica').fontSize(11).text(`${inv?.question?.slice(0, 67)}...`, nameVal, x)
        } else {
            doc.font('Helvetica').fontSize(11).text(inv?.question, nameVal, x)
        }
        doc.font('Helvetica').fontSize(11).text(inv?.fee, feeVal + 15 - (inv?.fee?.length * 4), x)
        doc.font('Helvetica').fontSize(11).text(inv?.tax, taxVal, x)
        doc.font('Helvetica').fontSize(11).text(inv?.amount, amountVal + 35 - (inv?.amount?.length * 4), x)
        index += 1
        if (x > 740) {
            doc.addPage()
            x = 20
        } else {
            x = x + 20
        }
    }

    doc.text('______________________________________________________________________________', 60, x)

    if (x > 760) {
        doc.addPage()
        x = 20
    } else {
        x = x + 30
    }

    let totalFee = invoice.reduce((a, { fee }) => a + Number(fee), 0)
    totalFee = convertRoundNumber(totalFee)
    let totalTax = invoice.reduce((a, { tax }) => a + Number(tax), 0)
    totalTax = convertRoundNumber(totalTax)
    let totalAmount = invoice.reduce((a, { amount }) => a + Number(amount), 0)
    totalAmount = convertRoundNumber(totalAmount)

    doc.font('Helvetica-Bold').fontSize(11).text(`Total`, nameVal, x)
    doc.font('Helvetica').fontSize(11).text(totalFee, feeVal + 15 - (totalFee?.length * 4), x)
    doc.font('Helvetica').fontSize(11).text(totalTax, taxVal + 15 - (totalTax?.length * 4), x)
    doc.font('Helvetica').fontSize(11).text(totalAmount, amountVal + 35 - (totalAmount?.length * 4), x)

    if (x > 760) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.text('______________________________________________________________________________', 60, x)

    if (x > 720) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.font('Helvetica-Bold').text('testally.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    if (x > 760) {
        doc.addPage()
        x = 20
    } else {
        x = x + 20
    }

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 115, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'FeeReport.pdf'

        a.click()
    })
}



export {
    adminData,
    checkExpiry,
    checkSelectOption,
    convertNumber,
    convertRoundNumber,
    convertTitle,
    createCashBackReport,
    createFeeReport,
    createGiftCard,
    createInvoicePdf,
    errorMessage,
    errorNotification,
    formattedStringDate,
    generalData,
    getAdminFaq,
    getAllData,
    getAllStudentData,
    getAllUserData,
    getFaq,
    getGeneralData,
    infoMessage,
    inputPlace,
    requiredMessage,
    secondsToMinutesAndSeconds,
    setActiveAdminMenu,
    setActiveMenu,
    setKeyMenu,
    sortFormsByAttempted,
    stringLimiter,
    successMessage,
    successNotification,
    teacherData,
    studentData,
    warningMessage,
    paraText,
    checkArrayForThesis,
    calculateReviewAverage,
    calculateAverageForRelevance,
    calculateAverageForClarity,
    calculateAverageForDetailed,
    calculateAverageForLanguageUse,
    calculateAverageForOverall,
    calculateAverageForImages,
    checkSubscription,
    createBuOrderReport,
    toBase64,
    checkDurationType,
    checkLuxurySub,
    createStudentInvoicePdf,
    createTeacherInvoicePdf,
    createCbrStudentInvoicePdf,
    createCbrTeacherInvoicePdf,
    removeBaseUrls,
    createExamReport,
    createCouponReport,
    createSubReport,
    createQuestionFeeReport,
}
