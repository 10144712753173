import { UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Image, Input, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useRef, useState } from 'react'
import { AUTH } from '../../utils/apis'
import { ownerType, provincie } from '../../utils/constants'
import countryCodes from '../../utils/countryCodes'
import { checkSelectOption, convertNumber, errorMessage, formattedStringDate, successMessage, warningMessage } from '../../utils/helpers'
import moment from 'moment'

const { Option } = Select

const UserSettings = (props) => {
    const { user, authActions, tutions, subjects, language, selectedLanguage, history, companyOwners } = props
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState(selectedLanguage?.UpdateProfile)
    const [fileList, setFileList] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(user?.ownerType || null)
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.code === user?.country)?.[0]?.dial_code || '+31')
    const formRef = useRef(null)
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {
        values.file = fileList
        values._id = user._id
        values.isPublic = values?.isPublic ? values?.isPublic : false

        console.log(values, '===values')
        if (!values._id && !values?.file?.length && user?.userType != 'guest') {
            return warningMessage(selectedLanguage?.PleaseUploadLogo)
        }

        let formData = new FormData()

        if (values?.companyOwner === '1') {
            values.companyOwner = null
        }
        if (values?.file?.length) {
            formData.append('logo', values?.file[0])
        }
        formData.append('showDashboard', values?.showDashboard ? true : false)
        delete values.file
        delete values.showDashboard

        for (var [k, v] of Object.entries(values)) {
            console.log('k', k, 'v', v)
            if (k && v) {
                if (Array.isArray(v)) {
                    for (let x of v) {
                        formData.append(`${k}[]`, x)
                    }
                } else {
                    formData.append(k, v)
                }
            }
        }


        setLoading(true)
        setBtnText(selectedLanguage?.Updating)
        axios.post(AUTH.UPDATE_SETTINGS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setBtnText(selectedLanguage?.UpdateProfile)
                if (data.success) {
                    successMessage(selectedLanguage?.ProfileUpdatedSuccessfully)
                    authActions.loginUser(data.user)
                    return window.location.reload()
                }
                errorMessage(isEnglish ? data?.message : data?.messageNl)
            })
            .catch((e) => {
                setLoading(false)
                setBtnText(selectedLanguage?.UpdateProfile)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter(v => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => <Option value={v?.dial_code} key={i}>{`${v?.name} ${v?.dial_code}`}</Option>)}
                </Select>
            </Form.Item >
        )
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const subExpiry = moment(user?.subscription?.expiry)
    const currentDate = moment()
    const subduration = moment.duration(subExpiry.diff(currentDate))
    const subRemainDays = subduration.asDays()
    return (
        <div>
            <div className='main-div-of-pf' >
                <div className='inner-div-pf'>
                    <p className='p-pf'>
                        {selectedLanguage?.txt_Yourwalletamountis} €{convertNumber(user?.walletBalance || 0)}
                    </p>
                    {user?.subscribeTeacher?.length ?
                        <p className='p-border-pf'> {selectedLanguage?.txt_Teachersubscription}</p>
                        : null}
                    {user?.subscribeTeacher?.length ?
                        user?.subscribeTeacher?.map((v, i) => {
                            const targetDate = moment(v?.expiry)
                            const duration = moment.duration(targetDate.diff(currentDate))
                            const reamin_days = duration.asDays()
                            console.log('v', v)
                            return (
                                <p className='p-pf' key={i}>{reamin_days > 0 ?
                                    `${selectedLanguage?.txt_Yoursubscriptionto} ${v?.teacher?.fullName}, ${selectedLanguage?.txt_willexpirein} ${formattedStringDate(v?.expiry)} ${selectedLanguage?.txt_at} ${targetDate.format('DD-MMMM-YYYY')} ` :
                                    `${selectedLanguage?.txt_Yoursubscriptionto} ${v?.teacher?.fullName}'s ${selectedLanguage?.service_has_expired_If_you_wish_to_continue_please_renew_your_subscription}`}</p>
                            )
                        })
                        : null}
                    {user?.subscription?._id &&
                        <>
                            <p className='p-border-pf'> {selectedLanguage?.txt_ContentSubscription}</p>
                            {user?.subscription?.subscriptionType === 'luxury' && <p className='p-pf'>{subRemainDays > 0 ?
                                `${selectedLanguage?.txt_Yoursubscriptionto} ${user?.subscription?.subscriptionName}, ${selectedLanguage?.txt_willexpirein} ${formattedStringDate(user?.subscription?.expiry)} ${selectedLanguage?.txt_at} ${subExpiry.format('DD-MMMM-YYYY')} ` :
                                `${selectedLanguage?.txt_Yoursubscriptionto} ${user?.subscription?.subscriptionName}'s ${selectedLanguage?.service_has_expired_If_you_wish_to_continue_please_renew_your_subscription}`}</p>}

                        </>}
                    {user?.userType === 'guest' && <p className='p-pf'>{selectedLanguage?.txt_Exams_Remaining}: {user?.exams || 0}</p>}
                </div>
            </div>

            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                ref={formRef}
            >
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='email'
                            label={selectedLanguage?.Email}
                            initialValue={user?.email}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourEmailHere}
                                disabled
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='fullName'
                            label={selectedLanguage?.FullName}
                            initialValue={user?.fullName}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseinputYourFullName
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputyourFullNameHere}
                            />
                        </Form.Item>
                    </div>
                </div>
                {user?.userType == 'guest' ? null :
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='file'
                                label={selectedLanguage?.Logo}
                            >
                                <Upload
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button type='primary' icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                </Upload>
                                {user?.logo ?
                                    <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                                        <Image src={user?.logoUrl} height={80} />
                                    </div> : null}
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='subject'
                                label={selectedLanguage?.label_SelectSubject}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseselectSubject,
                                    },
                                ]}
                                initialValue={user?.subject}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    allowClear
                                    placeholder={selectedLanguage?.label_SelectSubject}
                                    className='form-input-new-style'

                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {subjects.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.subjectName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                }
                {
                    user?.userType == 'guest' ? null :
                        <div className='form-two-input-flex'>

                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='businessName'
                                    label={selectedLanguage?.BusinessName}
                                    initialValue={user?.businessName}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: user?.userType == 'guest' ? false : true,
                                            message: selectedLanguage?.PleaseInputYourBusinessName
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourBusinessNameHere}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='ownerType'
                                    label={selectedLanguage?.label_OwnerType}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.requiredMessage_pleaseSelectOwnerType
                                        }
                                    ]}
                                    initialValue={user?.ownerType}
                                >
                                    <Select
                                        className='form-input-new-style'
                                        showArrow
                                        placeholder={selectedLanguage?.placeholder_SelectOwnerType}
                                        onChange={(e) => setSelectedOwner(e)}
                                    >
                                        {ownerType?.map((v, i) => <Option value={v?.val} key={i}>{language?.language === 'english' ? v?.name : v?.nameNl}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                }     


                {
                    selectedOwner === 'teacher' &&
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='tutions'
                                label={selectedLanguage?.label_SelectTutions}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_pleaseSelectTutions,
                                    },
                                ]}
                                initialValue={user?.tutions}
                            >
                                <Select
                                    showArrow
                                    allowClear
                                    placeholder={selectedLanguage?.label_SelectTutions}
                                    className='form-input-new-style'

                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {tutions.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.tutionName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='companyOwner'
                                label={selectedLanguage?.label_CompanyOwner}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_pleaseSelectCompanyOwner,
                                    },
                                ]}
                                initialValue={user?.companyOwner || '1'}
                            >
                                <Select
                                    showArrow
                                    allowClear
                                    placeholder={selectedLanguage?.label_CompanyOwner}
                                    className='form-input-new-style'
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >

                                    <Option value={'1'} key={'a'}>{selectedLanguage?.label_Independant}</Option>
                                    {companyOwners.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                }
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='streetNumber'
                            label={selectedLanguage?.StreetNumber}
                            initialValue={user?.streetNumber}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseIputYourStreetNumber
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourStreetNumberHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='postalCodeCity'
                            label={selectedLanguage?.PostalCode}
                            initialValue={user?.postalCodeCity}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourPostalCode
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style  inputTellAAA'
                                placeholder={selectedLanguage?.InputYourPostalCodeHere}
                            />
                        </Form.Item>
                    </div>
                </div>



                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='city'
                            label={selectedLanguage?.City}
                            initialValue={user?.city ? user?.city : ''}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourCity
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourCityHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='provincie'
                            label={selectedLanguage?.Provincie}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourProvincie
                                }
                            ]}
                            initialValue={user?.provincie ? user?.provincie : null}
                        >
                            <Select
                                className='form-input-new-style'
                                showArrow
                                placeholder={selectedLanguage?.SelectProvincie}
                            >
                                {provincie?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                            </Select>
                        </Form.Item>
                    </div >
                </div >

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='country'
                            label={selectedLanguage?.Country}
                            initialValue={user?.country}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseSelectCountry
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                className='form-input-new-style serchSe'
                                placeholder={selectedLanguage?.SelectCountry}
                                optionFilterProp='children'
                                onChange={updateCountry}
                                filterOption={checkSelectOption}
                            >
                                {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{language?.language === 'english' ? v?.name : v?.nameNL}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='telNo'
                            label={selectedLanguage?.TelNo}
                            initialValue={user?.telNo}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourTelNo
                                }
                            ]}
                        >
                            <Input
                                min={0}
                                type='number'
                                pattern='[0-9]*'
                                addonBefore={prefixSelector()}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.TelNoExample + '-' + `612345678`}
                                onBeforeInput={(e) => {
                                    const inputValue = e.clipboardData?.getData('text') || e.data
                                    if (!/^\d*$/.test(inputValue) || e.target.value.length > 12) {
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </Form.Item>
                    </div >
                </div >
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='remark'
                            label={selectedLanguage?.Remark}
                            initialValue={user?.remark}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourRemark
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourRemarkHere}
                            />
                        </Form.Item>
                    </div>

                    {user?.userType === 'guest' &&
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                label={<p style={{ margin: 0 }}></p>}
                                name='showDashboard'
                                valuePropName='checked'
                                initialValue={user?.showDashboard || false}
                            >
                                <Checkbox
                                    className='form-input-new-style'
                                    style={{ width: '100%', padding: '17px 14px', background: 'white' }}>
                                    {selectedLanguage?.txt_ShowDashboard}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    }
                </div>

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='bankAccount'
                            label={selectedLanguage?.NameonBankAccount}
                            initialValue={user?.bankAccount ? user?.bankAccount : ''}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: selectedLanguage?.PleaseEnterNameonyourBankAccount
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'

                                placeholder={selectedLanguage?.WhatisnameonyourBankaccount}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='IBAN'
                            label={selectedLanguage?.IBANNumber}
                            initialValue={user?.IBAN}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: selectedLanguage?.PleaseEnterYourIBANNumber
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.EnterYourIBANNumber}
                            />
                        </Form.Item>
                    </div>
                </div>
                {selectedOwner === 'teacher' &&
                    <Form.Item
                        name='isPublic'
                        valuePropName='checked'
                        initialValue={user?.isPublic || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.label_Public}
                        </Checkbox>
                    </Form.Item>
                }

                <Form.Item>
                    <Button
                        type='primary'
                        style={{ marginTop: '10px', height: '45px', marginLeft: '0.5%' }}
                        className='new-button-for-profile'
                        htmlType='submit'
                        loading={loading}
                    >
                        {btnText}
                    </Button>
                </Form.Item>
            </Form >
        </div >
    )
}

export default UserSettings