import { ALL_SUBJECTS, ALL_SUBSCRIPTION, ALL_TEACHERS, ALL_TUTIONS, CHAPTER, CITIES, COLLAPSED, LANGUAGES, PARAGRAPHS, RATES, REMOVE_USER, TAXES, TOPICS } from '../types'

const initialState = {
    isTourOpen: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COLLAPSED: {
            return { ...state, inlineCollapsed: action.inlineCollapsed }
        }
        case CITIES: {
            return { ...state, cities: action.cities }
        }
        case TAXES: {
            return { ...state, taxes: action.taxes }
        }
        case ALL_TEACHERS: {
            return { ...state, allTeachers: action.allTeachers }
        }
        case CHAPTER: {
            return { ...state, chapters: action.chapters }
        }
        case ALL_SUBJECTS: {
            return { ...state, subjects: action.subjects }
        }
        case PARAGRAPHS: {
            return { ...state, paragraphs: action.paragraphs }
        }
        case TOPICS: {
            return { ...state, topics: action.topics }
        }
        case ALL_TUTIONS: {
            return { ...state, tutions: action.tutions }
        }
        case LANGUAGES: {
            return { ...state, adminLanguages: action.adminLanguages }
        }
        case ALL_SUBSCRIPTION: {
            return { ...state, subscriptions: action.subscriptions }
        }
        case REMOVE_USER: {
            return {}
        }
        default: {
            return state
        }
    }
}

export default reducer