import { Modal, Rate } from 'antd'
import React from 'react'

const FeedbackResponsesModal = (props) => {
    const { open, onCancel, data, selectedLanguage } = props
    let feedbackResponses = data?.feedbackResponses
    feedbackResponses = feedbackResponses.filter((v) => !v?.isDeleted)
    console.log('feedbackResponses', feedbackResponses)
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            className='pop-info-modal'
            style={{ top: '10px' }}
            footer={null}
        >
            <p className='new-p-question'>{selectedLanguage?.ttx_FeedbackResponsesDetail}</p>

            <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_title}:</span>{data?.title}</p>
            <p className='margin0'><span className='colored-span'>{selectedLanguage?.title_FormType}:</span>{data?.formType}</p>
            {data?.groupmembers ? <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Groupmembers}:</span>{data?.groupmembers}</p> : null} <br />

            <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Responses}</span></p>

            {feedbackResponses?.map((v, i) => {
                return (
                    <div className='admin-modal-responses' key={i}>
                        <p className='margin0'><span className='colored-span'>
                            {data?.students?.length ?
                                'Student name' :
                                data?.teachers?.length ?
                                    'Teacher name' :
                                    data?.companyOwners?.length ?
                                        'Company wner name' : null
                            }:</span>{v?.userId?.fullName}
                        </p>

                        {v?.feedbackId?.showStudent &&
                            <p className='margin0'><span className='colored-span'>
                                Student :</span>{v?.studentsDisplay?.fullName}
                            </p>
                        }

                        {v?.response?.map((y, ii) => {
                            return (
                                <div className='form-feedback-student' key={ii}>
                                    <div className='feedback-student-inner-1'>
                                        <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'>&nbsp;✦</span>{y?.description}</p>
                                    </div>
                                    <div className='feedback-student-inner-2'>
                                        <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{y?.value1}</p>
                                        <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{y?.value2}</p>
                                    </div>
                                    <div className='feedback-student-inner-3'>
                                        <Rate disabled={true} value={y?.answer} className='new-my-rate' />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </Modal>
    )
}
export default FeedbackResponsesModal