const HOME = '/'
const LOGIN = '/login'
const SIGNUP = '/register'
const BU_LOGIN = `/bu-login`
const BU_SIGNUP = `/bu-register`
const FORGOT = `/forgot`
const BU_FORGOT = `/bu-forgot`
const VERIFY_EMAIL = `/verifyemail`
const BU_TESTING = `/bu-testings`
const QUESTIONS = `${BU_TESTING}/exams`
const TESTING = `/testing`
const EXAMINATIONS = `${TESTING}/examinations`
const RESULTS = `${TESTING}/results`
const THEORY = `/theory`
const ST_CARDS = `${THEORY}/cards`
const ST_VIDEO = `${THEORY}/video`
const MAIN_TRAINING = `/training`
const TRAINING = `${MAIN_TRAINING}/train`
const TEST_10 = `${MAIN_TRAINING}/test-10`
const BU_RESULTS = `${BU_TESTING}/bu-results`
const FORM = `/form`
const UPDATE_PASSWORD = `/update-password`
const BU_UPDATE_PASSWORD = `/bu-update-password`
const SIDE_SETTING = `/side-setting`
const FAQ = `/faq`
const ACCOUNT = `/accounts`
const BU_ACCOUNT = `/bu-accounts`
const PROFILE_SETTINGS = `${ACCOUNT}/profile-settings`
const ST_FEEDBACK = `${ACCOUNT}/feedback`
const BU_PROFILE_SETTINGS = `${BU_ACCOUNT}/bu-profile-settings`
const BU_FEEDBACK = `${BU_ACCOUNT}/bu-feedback`
const BU_REVIEW = `${BU_ACCOUNT}/review`
const FINANCIAL = `/financial`
const BUFINANCIAL = `/bu-financial`
const SUBSCRIPTION_PAYMENT = `/subscription-payment`
const BU_SUBSCRIPTION_PAYMENT = `${BUFINANCIAL}/bu-subscription-payment`
const BUVOUCHERS = `${BUFINANCIAL}/vouchers`
const BUINVOICES = `${BUFINANCIAL}/invoices`
const BUPAYMENTS = `${BUFINANCIAL}/payment`
const BUCOUPON = `${BUFINANCIAL}/coupon`
const FINANCIAL_SUBSCRIPTION_INVOICE = `${FINANCIAL}/subscription-invoice`
const BUFINANCIAL_SUBSCRIPTION_INVOICE = `${BUFINANCIAL}/subscription-invoice`
const FINANCIAL_TEACHER_INVOICE = `${FINANCIAL}/teacher-invoice`
const FINANCIAL_WALLLET_TRANSACTION = `${FINANCIAL}/wallet-transaction`
const BU_FINANCIAL_WALLLET_TRANSACTION = `${BUFINANCIAL}/wallet-transaction`
const FINANCIAL_CASHBACK_REQUEST = `${FINANCIAL}/cashback-request`
const BU_FINANCIAL_CASHBACK_REQUEST = `${BUFINANCIAL}/cashback-request`
const BU_REPORTS = `${BUFINANCIAL}/report`
const OVERVIEW = `/overview`
const TRAIN_OVERVIEW = `/train-overview`
const PARA_TEST = `/para-test`
const STUDENT = '/student'
const TEACHER = '/teacher'
const GUEST_HOME = `${STUDENT}/home`
const STUDENT_DASHBOARD = `${STUDENT}/dashboard`
const TEACHER_HOME = `${TEACHER}/home`

const ADMIN = `/admin`
const ADMIN_LOGIN = `${ADMIN}/login`
const ADMIN_USERS = `${ADMIN}/users`
const ADMIN_QUESTION = `${ADMIN}/questions`
const ADMIN_VIDEOS = `${ADMIN}/videos`
const ADMIN_IMAGES = `${ADMIN}/images`
const ADMIN_CARDS = `${ADMIN}/cards`
const ADMIN_FEEDBACK = `${ADMIN}/feedback`
const ADMIN_REVIEW = `${ADMIN}/review`
const ADMIN_FORM = `/admin-form`
const REPORT = `${ADMIN}/report`
const EXAM_REPORT = `${REPORT}/exam`
const QUESTION_FORM_REPORT = `${REPORT}/form`
const QUESTION_FEE_REPORT = `${REPORT}/question-fee`
const COUPON_REPORT = `${REPORT}/coupon`
const SUBSCRIPTION_REPORT = `${REPORT}/subscription`
const ADMIN_SETTINGS = `${ADMIN}/settings`
const ADMIN_SETTINGS_TUTION = `${ADMIN_SETTINGS}/tution`
const ADMIN_SETTINGS_SUBJECTS = `${ADMIN_SETTINGS}/subject`
const ADMIN_SETTINGS_LANGUAGES = `${ADMIN_SETTINGS}/language`
const ADMIN_FINANCIAL = ADMIN + FINANCIAL
const ADMINPAYMENTS = `${ADMIN_FINANCIAL}/payments`
const ADMIN_TAX_SETTINGS = `${ADMIN}/tax-settings`
const ADMIN_PRICE_SETTINGS = `${ADMIN}/price-settings`
const ADMIN_SUBSCRIPTIONS = `${ADMIN}/subscriptions`
const ADMIN_ADD_FAQ = `${ADMIN}/add-faq`
const ADMIN_FAQ = `${ADMIN}/faq`
const ADMIN_CASHBACK = `${ADMIN}/admin-cashback`
const ADMIN_COUPONS = `${ADMIN}/coupons`
const ADMIN_STUDENT_INVOICE = `${ADMIN_FINANCIAL}/student-invoice`
const ADMIN_TEACHER_INVOICE = `${ADMIN_FINANCIAL}/teacher-invoice`
const GRAPHIC = `/graphic-designer`
const TOPIC_TRAINING = `/topic-training`

// New Paths
const NEW = '/new'
const NEW_HOME = `${NEW}/home`
const BU_THEORY = `/bu-theory`
const TRAIN_VIDEOS = `${BU_THEORY}/train-video`
const TRAIN_CARDS = `${BU_THEORY}/train-card`
const VISITER_HOME = `/visiter-home`
const VISITER_FAQ = `/visiter-faq`
export default {
    HOME,
    LOGIN,
    SIGNUP,
    BU_LOGIN,
    BU_SIGNUP,
    FORGOT,
    BU_FORGOT,
    VERIFY_EMAIL,
    QUESTIONS,
    FORM,
    UPDATE_PASSWORD,
    BU_UPDATE_PASSWORD,
    SIDE_SETTING,
    FAQ,
    PROFILE_SETTINGS,
    ADMIN,
    ADMIN_LOGIN,
    ADMIN_USERS,
    ADMIN_QUESTION,
    ADMIN_FORM,
    QUESTION_FORM_REPORT,
    ADMIN_SETTINGS,
    ADMIN_SETTINGS_TUTION,
    ADMIN_SETTINGS_SUBJECTS,
    ADMIN_SETTINGS_LANGUAGES,
    BUFINANCIAL,
    BUVOUCHERS,
    BUINVOICES,
    BUPAYMENTS,
    ADMINPAYMENTS,
    FINANCIAL,
    ADMIN_FINANCIAL,
    ADMIN_TAX_SETTINGS,
    QUESTION_FEE_REPORT,
    ADMIN_PRICE_SETTINGS,
    ADMIN_ADD_FAQ,
    ADMIN_FAQ,
    SUBSCRIPTION_PAYMENT,
    ADMIN_CASHBACK,
    FINANCIAL_SUBSCRIPTION_INVOICE,
    FINANCIAL_WALLLET_TRANSACTION,
    EXAMINATIONS,
    FINANCIAL_CASHBACK_REQUEST,
    OVERVIEW,
    ADMIN_STUDENT_INVOICE,
    ADMIN_TEACHER_INVOICE,
    ADMIN_VIDEOS,
    RESULTS,
    FINANCIAL_TEACHER_INVOICE,
    ADMIN_COUPONS,
    PARA_TEST,
    GUEST_HOME,
    ADMIN_IMAGES,
    GRAPHIC,
    TRAINING,
    TOPIC_TRAINING,
    ADMIN_CARDS,
    BU_RESULTS,
    BU_SUBSCRIPTION_PAYMENT,
    BUFINANCIAL_SUBSCRIPTION_INVOICE,
    BU_FINANCIAL_WALLLET_TRANSACTION,
    BU_FINANCIAL_CASHBACK_REQUEST,
    BU_PROFILE_SETTINGS,
    ST_CARDS,
    TRAIN_VIDEOS,
    ST_VIDEO,
    THEORY,
    MAIN_TRAINING,
    TEST_10,
    TESTING,
    ST_FEEDBACK,
    ACCOUNT,
    TEACHER_HOME,
    BU_THEORY,
    BU_TESTING,
    BU_ACCOUNT,
    VISITER_HOME,
    TRAIN_CARDS,
    VISITER_FAQ,
    ADMIN_FEEDBACK,
    BU_FEEDBACK,
    TRAIN_OVERVIEW,
    STUDENT_DASHBOARD,
    BU_REVIEW,
    ADMIN_REVIEW,
    ADMIN_SUBSCRIPTIONS,
    BUCOUPON,
    BU_REPORTS,
    EXAM_REPORT,
    REPORT,
    COUPON_REPORT,
    SUBSCRIPTION_REPORT
}