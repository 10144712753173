import { Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { checkSelectOption } from '../../../utils/helpers';
import loafingImg from '../../../assets/load.gif'
import nodataFound from '../../../assets/NewImages/grapgh-not.png'

const { Option } = Select
const TheoryCharts = (props) => {
    const { selectedLanguage, text, data, loading } = props
    const [teacher, setTeacher] = useState('all')
    const [opened, setOpened] = useState('opened')

    console.log('data', data)
    let teachers = data.filter((v) => !v.admin).map((v) => v?.user)
    teachers = _.uniqBy(teachers, '_id')

    useEffect(() => {

        setChartState(state => {
            let labels
            let colors
            if (opened === 'opened') {
                labels = ['Opened', 'Unopened'/* , 'Total' */]
                colors = ['#2ecc71', '#3498db'/* , '#3498db' */] // Customize colors here

            } else {
                labels = ['Wrong', 'Correct'/* , 'Total' */]
                colors = ['#3498db', '#2ecc71'/* , '#3498db' */] // Customize colors here

            }
            return {
                ...state,
                series: checkData(),
                options: { ...state.options, labels, colors }

            }
        })
    }, [teacher, opened, data])


    const checkData = () => {
        if (opened === 'opened') {
            if (teacher === 'all') {
                let totalCards = data.length || 0
                let cardsOpened = data.filter((v) => v.questions)

                return [cardsOpened.length || 0, totalCards - cardsOpened.length || 0]
            } else if (teacher === 'admin') {
                let adminData = data.filter((v) => v.admin)
                let totalCards = adminData.length || 0
                let cardsOpened = adminData.filter((v) => v.questions)

                return [cardsOpened.length || 0, totalCards - cardsOpened.length || 0]
            } else {
                let teacherData = data.filter((v) => v.user?._id === teacher)
                let totalCards = teacherData.length || 0
                let cardsOpened = teacherData.filter((v) => v.questions)

                return [cardsOpened.length || 0, totalCards - cardsOpened.length || 0]
            }
        } else {
            if (teacher === 'all') {
                let totalQuestions = data.reduce((a, { questions }) => a + questions, 0)
                let correctAns = data.reduce((a, { correct }) => a + correct, 0)
                let wrongAns = data.reduce((a, { wrong }) => a + wrong, 0)

                return [wrongAns || 0, correctAns || 0/* , totalQuestions */]
            } else if (teacher === 'admin') {
                let adminData = data.filter((v) => v.admin)
                let totalQuestions = adminData.reduce((a, { questions }) => a + questions, 0)
                let correctAns = adminData.reduce((a, { correct }) => a + correct, 0)
                let wrongAns = adminData.reduce((a, { wrong }) => a + wrong, 0)

                return [wrongAns || 0, correctAns || 0/* , totalQuestions */]
            } else {
                let teacherData = data.filter((v) => v.user?._id === teacher)
                let totalQuestions = teacherData.reduce((a, { questions }) => a + questions, 0)
                let correctAns = teacherData.reduce((a, { correct }) => a + correct, 0)
                let wrongAns = teacherData.reduce((a, { wrong }) => a + wrong, 0)

                return [wrongAns || 0, correctAns || 0/* , totalQuestions */]
            }
        }
    }
    const [chartState, setChartState] = useState({
        series: checkData(),
        options: {
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -10,
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    return `${w.globals.series[seriesIndex]}`;
                },
            },
            chart: {
                type: 'pie',
            },
            labels: ['Opened', 'Unopened'],
            colors: ['#2ecc71', '#3498db'],
            legend: {
                position: 'bottom'
            },
        }
    })

    return (
        <div className='dasboard-card'>
            <h2 className='inner-dasboard-card-title' style={{ color: 'black', textTransform: 'none' }}>{text}</h2>
            <div className='inner-dasboard-card-title-select-div'>
                <Select
                    showSearch
                    showArrow
                    allowClear={false}
                    className='dashboard-select'
                    placeholder={selectedLanguage?.txtx_selectTeacher}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                    defaultValue={'all'}
                    onChange={(e) => setTeacher(e)}
                >
                    <Option value={'all'} >{selectedLanguage?.txt_All}</Option>
                    <Option value={'admin'} >{selectedLanguage?.title_Admin}</Option>
                    {teachers?.length ?
                        teachers?.map((v, i) => <Option key={i} value={v?._id} >{v?.fullName}</Option>)
                        : null}
                </Select>
                &nbsp;
                <Select
                    showSearch
                    showArrow
                    allowClear={false}
                    className='dashboard-select'
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                    onChange={(e) => setOpened(e)}
                    defaultValue={'opened'}
                >
                    <Option value={'opened'} >{selectedLanguage?.txt_Opened}</Option>
                    <Option value={'score'} >{selectedLanguage?.txt_Score}</Option>
                </Select>
            </div>

            <div className='dasboard-main-chart-div'>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={loafingImg} />
                    </div> :
                    data?.length ?
                        <div id='chart'>
                            <ReactApexChart
                                options={chartState.options}
                                series={chartState.series}
                                type='pie'
                                width={'100%'}
                                height={205} />
                        </div> :
                        <div style={{ textAlign: 'center' }}>
                            <img src={nodataFound} style={{ height: '140px' }} />
                            <p>{selectedLanguage?.txt_graphnotFound}</p>
                        </div>}
            </div>
        </div>
    )
}

export default TheoryCharts