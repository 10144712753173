import { InputNumber, Modal, Spin } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../utils/apis';
import { convertTitle, errorMessage, successMessage } from '../../utils/helpers';

const UserDetail = (props) => {
    const { user, onCancel, userDetail, selectedLanguage, adminActions, lanKorCondition } = props
    const [fee, setFee] = useState(userDetail?.questionFee)
    const [loading, setLoading] = useState(false)
    let subscriptions = useSelector(state => state?.generalReducer?.subscriptions || [])
    subscriptions = subscriptions.filter((v) => v?.subscriptionType === 'teacher' && v?.activate)
    console.log('userDetail', userDetail)
    const initializeSubFee = () => {
        let array = subscriptions.map((v) => {
            let userSub = (userDetail?.teacherSubscription || []).find((x) => x?.subId === v?._id)
            return {
                amount: userSub?.subId ? userSub?.amount : v?.amount,
                subId: v?._id
            }
        })

        return array
    }

    const [subFee, setSubFee] = useState(initializeSubFee() || [])

    const updateFee = () => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            userId: userDetail?._id,
            questionFee: fee,
            teacherSubscription: subFee
        }
        axios.post(ADMIN.UPDATE_QUESTION_FEE, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    adminActions?.addAllBusinessUsers(data?.data || [])

                    setLoading(false)
                    onCancel()
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                } else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(selectedLanguage?.OopsSomethingWentWrong)


            })
    }

    const changeSubFee = (e, ind) => {
        setSubFee(state => state.map((x, i) => {
            if (i === ind) {
                return {
                    ...x,
                    amount: e
                }
            } else {
                return x
            }
        }))
    }

    return (
        <Modal
            open={userDetail?._id}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <p className='new-p-question'>{selectedLanguage?.UserDetail}</p>
            <h2 style={{ color: '#1EB2A6' }}>{selectedLanguage?.txt_QuestionFee}</h2>
            {/* <Row gutter={24}>
                <Col className='gutter-row'> */}
            <InputNumber
                // className='form-input-new-style-small-height'
                style={{ width: '100%' }}
                type='number'
                value={fee}
                min={0}
                step={1}
                onChange={setFee}
            />
            {/* </Col>
            </Row> */}
            <br />
            <h2 style={{ color: '#1EB2A6' }}>{selectedLanguage?.txt_TeacherSubscription}</h2>
            {
                subscriptions?.map((v, i) => {
                    return (
                        <>
                            <InputNumber
                                style={{ width: '100%' }}
                                type='number'
                                value={subFee[i]?.amount}
                                min={0.1}
                                step={0.1}
                                addonAfter={v?.duration.toString() + ' ' + convertTitle(v?.durationType)}
                                onChange={(e) => changeSubFee(e, i)}
                            />
                            <br />
                        </>
                    )
                }
                )
            }
            <div style={{ display: 'flex', marginTop: 10 }}>
                <button
                    style={{
                        height: '40px',
                        width: '50%',
                        background: 'white',
                        color: '#1eb2a6',
                        border: 'none',
                        borderBottomLeftRadius: 10,
                        border: '1px solid #1eb2a6',
                        cursor: 'pointer'
                    }}
                    onClick={onCancel}
                >{selectedLanguage?.btn_Cancel}</button>
                <button
                    style={{
                        height: '40px',
                        width: '50%',
                        background: '#1eb2a6',
                        color: 'white',
                        border: 'none',
                        borderBottomRightRadius: 10,
                        cursor: loading ? 'not-allowed' : 'pointer'
                    }}
                    onClick={loading ? () => console.log('') : updateFee}
                >{loading ? <Spin /> : null}{selectedLanguage?.Update}</button>
            </div>
        </Modal>
    )
}

export default UserDetail