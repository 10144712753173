import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { checkSelectOption } from '../../../utils/helpers';
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';
import loafingImg from '../../../assets/load.gif'
import nodataFound from '../../../assets/NewImages/grapgh-not.png'

const { Option } = Select
const StudentsResultChart = (props) => {
    const { selectedLanguage, user, data, loading } = props
    const [teacher, setTeacher] = useState('all')

    console.log('data=data', data)

    let teachers = data?.filter((v) => !v.admin).map((v) => v?.user)
    teachers = _.uniqBy(teachers, '_id')


    useEffect(() => {

        setSeries(state => {
            return [
                {
                    ...state?.[0],
                    data: [...(checkData())?.correct]
                },

                {
                    ...state?.[1],
                    data: [...(checkData())?.wrong]
                },

            ]
        })

        setOptions(state => {
            return {
                ...state,
                yaxis: {
                    title: {
                        text: '%',
                    },
                    labels: {
                        formatter: function (value) {
                            // return value + '%';
                            return value
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + '%';
                        },
                    },
                },
                xaxis: {
                    categories: [...(checkData())?.categories],
                    labels: {
                        rotate: 0, // Rotate labels for better visibility
                        formatter: function (value) {
                            if (value.length > 5) {
                                // return value.slice(0,4) + '...' 
                                return value
                            } else {
                                return value
                            }
                        },
                    },
                },
            }
        })
    }, [teacher, data])

    const checkData = () => {
        if (teacher === 'all') {
            let categories = data.map((v) => v?.exam/* .slice(0, 4) */) || []
            let correct = data.map((v) => ((v?.correct / v?.total) * 100).toFixed(2)) || []
            let wrong = data.map((v) => ((v?.wrong / v?.total) * 100).toFixed(2)) || []

            return { categories, correct, wrong }
        } else if (teacher === 'admin') {
            let adminData = data.filter((v) => v?.admin)
            let categories = adminData.map((v) => v?.exam/* .slice(0, 4) */) || []
            let correct = adminData.map((v) => ((v?.correct / v?.total) * 100).toFixed(2)) || []
            let wrong = adminData.map((v) => ((v?.wrong / v?.total) * 100).toFixed(2)) || []

            return { categories, correct, wrong }
        } else {
            let teacherData = data.filter((v) => v?.user?._id === teacher)
            let categories = teacherData.map((v) => v?.exam/* .slice(0, 4) */) || []
            let correct = teacherData.map((v) => ((v?.correct / v?.total) * 100).toFixed(2)) || []
            let wrong = teacherData.map((v) => ((v?.wrong / v?.total) * 100).toFixed(2)) || []

            return { categories, correct, wrong }
        }
    }

    const [series, setSeries] = useState([
        {
            name: 'Correct',
            data: [...(checkData())?.correct],
            color: '#2ecc71',
        },
        {
            name: 'Wrong',
            data: [...(checkData())?.wrong],
            color: '#e74c3c'
        },
    ])
    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            categories: (checkData())?.categories,
            labels: {
                formatter: function (value) {
                    if (value.length > 5) {
                        // return value.slice(0,4) + '...' 
                        return value
                    } else {
                        return value
                    }
                },
                rotate: 0
            },
        },
        legend: {
            position: 'top',
        },
        yaxis: {
            title: {
                text: '%',
            },
            labels: {
                formatter: function (value) {
                    // return value + '%';
                    return value
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + '%';
                },
            },
        }
    })

    return (
        <div className='dasboard-card'>
            <h2 className='inner-dasboard-card-title'>{selectedLanguage?.txt_Result}</h2>
            <div className='inner-dasboard-card-title-select-div'>
                <Select
                    showSearch
                    showArrow
                    style={{ width: '100%' }}
                    allowClear={false}
                    className='dashboard-select'
                    placeholder={selectedLanguage?.txtx_selectTeacher}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                    defaultValue={'all'}
                    onChange={(e) => setTeacher(e)}
                >
                    <Option value={'all'} >{selectedLanguage?.txt_All}</Option>
                    <Option value={'admin'} >{selectedLanguage?.title_Admin}</Option>
                    {teachers.map((v, i) => <Option key={i} value={v?._id} >{v?.fullName}</Option>)}
                </Select>
            </div>

            <div className='dasboard-main-chart-div result-chart-div new-layout-scroll-class' >
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={loafingImg} />
                    </div> :
                    data?.length ?
                        <div id='chart'>
                            <ReactApexChart
                                options={options}
                                series={series}
                                type='bar'
                                height={170}
                                width={600}
                            />
                        </div> :
                        <div style={{ textAlign: 'center' }}>
                            <p>{selectedLanguage?.txt_graphnotFound}</p>
                            <img src={nodataFound} style={{ height: '170px' }} />
                        </div>}
            </div>
        </div>
    )
}

export default StudentsResultChart