import { UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoginLanguageSelect from '../../Components/LoginLanguageSelect/LoginLanguageSelect'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { removeUser } from '../../Redux/actions/authActions'
import testallyLogo from "../../assets/testallyLogo-removebg.png"
import { ADMIN, AUTH, GET } from '../../utils/apis'
import { allPaths, ownerType, provincieLanguage } from '../../utils/constants'
import countryCodes from '../../utils/countryCodes'
import { checkSelectOption, errorMessage, successNotification, warningMessage } from '../../utils/helpers'

const { Option } = Select

const Signup = (props) => {
    const { history } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [country, setCountry] = useState('NL')
    const [selectedOwner, setSelectedOwner] = useState(null)
    const [tutions, setTutions] = useState([])
    const [subjects, setSubjects] = useState([])
    const [companyOwners, setCompanyOwners] = useState([])
    const [countryCode, setCountryCode] = useState('+31')
    const formRef = useRef(null)
    const dispatch = useDispatch()
    const path = history?.location?.pathname
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'
    useEffect(() => {
        dispatch(removeUser())
        getTutions()
        getSubjects()
        getCompanyOwners()
    }, [])
    const getSubjects = () => {
        axios.get(ADMIN?.GET_SUBJECTS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setSubjects(data?.data || [])
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const getTutions = () => {
        axios.get(ADMIN?.GET_TUTIONS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setTutions(data?.data || [])
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const getCompanyOwners = () => {
        axios.get(GET?.GET_COMPANY_OWNERS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setCompanyOwners(data?.data || [])
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const onFinish = (values) => {

        values.userType = path === allPaths?.BU_SIGNUP ? 'business' : 'guest'

        values.file = fileList

        if (values?.companyOwner === '1') {
            values.companyOwner = null
        }

        if (!values?.file?.length && path == allPaths?.BU_SIGNUP) {
            return warningMessage(selectedLanguage?.PleaseUploadLogo)
        }

        let formData = new FormData()

        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        delete values.file
        console.log('values', values)
        for (var [k, v] of Object.entries(values)) {
            if (k && v) {
                console.log(k, v)
                if (Array.isArray(v)) {
                    for (let x of v) {
                        formData.append(`${k}[]`, x)
                    }
                } else {
                    formData.append(k, v)
                }
                // formData.append(k, v)
            }
        }

        setLoading(true)
        axios.post(AUTH.SIGNUP, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(
                        selectedLanguage?.SuccessfullySignupwehavesentyouAverificationLinkToyouremailPleaseverifyYourEmailAndLogin)
                    return setTimeout(() => {
                        history.push(allPaths?.LOGIN)
                    }, 300)
                }
                errorMessage(isEnglish ? data.message : data.messageNl)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(isEnglish ? e.message : e.messageNl)
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (<div className='new-layout-scroll-class'
        style={{
            height: '100vh',
            background: '#A4C8CE',
            overflow: 'auto'
        }}>
        <LoginLanguageSelect color={true} />
        <div className='auth-screen-main-div'>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <img className='login-top-img' src={testallyLogo} />
            </div>
            <p className='heading head-center'>{path === allPaths?.BU_SIGNUP ?
                selectedLanguage?.txtTeacher_registration : selectedLanguage?.txtstudent_registration}</p>
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                ref={formRef}
            >
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='fullName'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.FullName}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseinputYourFullName
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputyourFullNameHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='email'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.Email}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourEmail,
                                },
                                {
                                    type: 'email',
                                    message: selectedLanguage?.EmailIsNotValid,
                                },
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourEmailHere}
                            />
                        </Form.Item>
                    </div>
                </div>
                {path == allPaths?.BU_SIGNUP &&
                    <>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='file'
                                    label={<p className='new-pp-colr-wht'>{selectedLanguage?.Logo}</p>}
                                >
                                    <Upload
                                        name='file'
                                        multiple={false}
                                        beforeUpload={() => false}
                                        accept='image/png, image/jpeg'
                                        onChange={normFile}
                                        fileList={fileList}
                                    >
                                        <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='businessName'
                                    label={<p className='new-pp-colr-wht'>{selectedLanguage?.BusinessName}</p>}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: path == allPaths?.BU_SIGNUP ? true : false,
                                            message: selectedLanguage?.PleaseInputYourBusinessName
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourBusinessNameHere}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </>
                }


                {path === allPaths?.BU_SIGNUP &&
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='ownerType'
                                label={<p className='new-pp-colr-wht'>{selectedLanguage?.label_OwnerType}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_pleaseSelectOwnerType
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showArrow
                                    placeholder={selectedLanguage?.placeholder_SelectOwnerType}
                                    onChange={(e) => setSelectedOwner(e)}
                                >
                                    {ownerType?.map((v, i) => <Option value={v?.val} key={i}>{language?.language === 'english' ? v?.name : v?.nameNl}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='remark'
                                label={<p className='new-pp-colr-wht'>{selectedLanguage?.Remark}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourRemark
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourRemarkHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                }

                {
                    selectedOwner === 'teacher' &&
                    <>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='tutions'
                                    label={<p className='new-pp-colr-wht'>{selectedLanguage?.label_SelectTutions}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.requiredMessage_pleaseSelectTutions,
                                        },
                                    ]}
                                >
                                    <Select
                                        showArrow
                                        allowClear
                                        placeholder={selectedLanguage?.label_SelectTutions}
                                        className='form-input-new-style'
                                        optionFilterProp='children'
                                        filterOption={checkSelectOption}
                                    >
                                        {tutions.map((v, i) => {
                                            return (
                                                <Option value={v?._id} key={i}>{v?.tutionName}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='companyOwner'
                                    label={<p className='new-pp-colr-wht'>{selectedLanguage?.label_CompanyOwner}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.requiredMessage_pleaseSelectCompanyOwner,
                                        },
                                    ]}
                                >
                                    <Select
                                        showArrow
                                        allowClear
                                        placeholder={selectedLanguage?.label_CompanyOwner}
                                        className='form-input-new-style'
                                        optionFilterProp='children'
                                        filterOption={checkSelectOption}
                                    >
                                        <Option value={'1'} key={'a'}>{selectedLanguage?.label_Independant}</Option>
                                        {companyOwners.map((v, i) => {
                                            return (
                                                <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='subject'
                                    label={selectedLanguage?.label_SelectSubject}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.errorMessage_PleaseselectSubject,
                                        },
                                    ]}
                                >
                                    <Select
                                        mode='multiple'
                                        showArrow
                                        allowClear
                                        placeholder={selectedLanguage?.label_SelectSubject}
                                        className='form-input-new-style'

                                        optionFilterProp='children'
                                        filterOption={checkSelectOption}
                                    >
                                        {subjects.map((v, i) => {
                                            return (
                                                <Option value={v?._id} key={i}>{v?.subjectName}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </>}

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='streetNumber'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.StreetNumber}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseIputYourStreetNumber
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourStreetNumberHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='postalCodeCity'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.PostalCode}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourPostalCode
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourPostalCodeHere}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='city'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.City}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourCity
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourCityHere}
                            />
                        </Form.Item>
                    </div >

                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='country'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.Country}</p>}
                            hasFeedback
                            initialValue={country}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourCity
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                showArrow
                                allowClear={false}
                                className='form-input-new-style serchSe'
                                placeholder={selectedLanguage?.InputYourCityHere}
                                optionFilterProp='children'
                                onChange={updateCountry}
                                filterOption={checkSelectOption}
                            >
                                {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{language?.language === 'english' ? v?.name : v?.nameNL}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>

                </div >

                <div className='form-two-input-flex'>
                    {path === allPaths?.BU_SIGNUP &&
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='provincie'
                                label={<p className='new-pp-colr-wht'>{selectedLanguage?.Provincie}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourProvincie
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showArrow
                                    placeholder={selectedLanguage?.SelectProvincie}
                                >
                                    {provincieLanguage?.map((v, i) => <Option value={v?.name} key={i}>{language?.language === 'english' ? v?.name : v?.nameNl}</Option>)}
                                </Select>
                            </Form.Item>
                        </div >}
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='telNo'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.TelNo}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourTelNo
                                }
                            ]}
                        >
                            <Input
                                addonBefore={prefixSelector()}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.TelNo}
                            />
                        </Form.Item>
                    </div >
                </div >

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='password'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.Password}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourPassword
                                },
                                {
                                    min: 6,
                                    message: selectedLanguage?.PasswordMustBeAtLeastCharacters,
                                }
                            ]}
                        >
                            <Input.Password
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourPasswordHere}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.ConfirmPassword}</p>}
                            name='confirm'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourConfirmPassword,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }

                                        return Promise.reject(
                                            selectedLanguage?.TheTwoPasswordsThatYouEntereddoNotMatch
                                        )
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                placeholder={selectedLanguage?.InputYourConfirmPasswordHere}
                                className='form-input-new-style'
                            />
                        </Form.Item>
                    </div>
                </div>
                {selectedOwner === 'teacher' &&
                    <Form.Item
                        name='isPublic'
                        valuePropName='checked'
                    // initialValue={copyForm?.public || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.label_Public}
                        </Checkbox>
                    </Form.Item>
                }
                <Form.Item>
                    <Button
                        type='primary'
                        className='newlogin-btn form-input-new-style'
                        style={{ height: '45px', width: '99%', marginTop: '10px', /* marginLeft: '1%' */ }}
                        htmlType='submit'
                        loading={loading}
                    >
                        {selectedLanguage?.Register}
                    </Button>
                </Form.Item>
            </Form >
            <div style={{ textAlign: 'center' }}>
                <p>
                    {selectedLanguage?.AlreadyHaveAccount}&nbsp;
                    {path === allPaths?.BU_SIGNUP ? <span>
                        <Link to={allPaths?.BU_LOGIN}>{<span className='new-pp-colr-wht'>{selectedLanguage?.txt_Teacherlogin}</span>}</Link>
                        <br />
                        <Link to={allPaths?.BU_FORGOT}>{<span className='new-pp-colr-wht'>{selectedLanguage?.ForgotPassword}</span>}</Link>
                    </span> : <span>
                        <Link to={allPaths.LOGIN}>{<span className='new-pp-colr-wht'>{selectedLanguage?.txtStudentlogin}</span>}</Link>
                        <br />
                        <Link to={allPaths?.FORGOT}>{<span className='new-pp-colr-wht'>{selectedLanguage?.ForgotPassword}</span>}</Link>
                    </span>}
                </p>
            </div>
        </div >
    </div >

    )
}

export default Signup