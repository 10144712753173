import { Button, Form, Input, Modal, Radio, Result, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropStudent, DragSequence, PreviewMaths } from '../../Components'
import { addDashboardData } from '../../Redux/actions/userActions'
import logo from '../../assets/testallyLogo-removebg.png'
import { POST } from '../../utils/apis'
import { allPaths, thesisOptions } from '../../utils/constants'
import { errorMessage, removeBaseUrls, studentData, successMessage } from '../../utils/helpers'
import TrainTestDetail from './TrainTestDetail'

const { Option } = Select

const TrainTest = (props) => {
    const { history, selectedLanguage, location } = props
    const { trainQuestions, topics, card, video, test10 } = location?.state || {}
    const user = useSelector(state => state.authReducer.user)
    const language = useSelector(state => state?.languageReducer)
    const isEnglish = language?.language === 'english'
    const [selectedAns, setSelectedAns] = useState({})
    const [allQuestions, setAllQuestions] = useState(trainQuestions || [])
    const [form] = Form.useForm()
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentQuestionObj, setCurrentQuestionObj] = useState({})
    const [currentQuestionIsWithImg, setCurrentQuestionIsWithImg] = useState('')
    const [showExplaination, setShowExplaination] = useState(false)
    const [resultModal, setResultModal] = useState(false)
    const [quesChng, setQuesChng] = useState(false)
    const [chResults, setChResults] = useState({})
    const [paragraphResults, setparagraphResults] = useState({})
    const [examInProgress, setExamInProgress] = useState(true)
    const dispatch = useDispatch()
    const isFirstRender = useRef(true);
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])
    useEffect(() => {
        if (!isFirstRender.current) {
            setShowExplaination(state => !state)
        } else {
            isFirstRender.current = false;
        }
    }, [quesChng])
    useEffect(() => {
        setCurrentQuestionObj(trainQuestions?.[currentQuestion] || {})
        setCurrentQuestionIsWithImg(trainQuestions?.[currentQuestion]?.file?.baseUrl || trainQuestions?.[currentQuestion]?.file?.url || '')
    }, [currentQuestion, trainQuestions])

    const handleBeforeUnload = (event) => {
        if (examInProgress) {
            // Display a confirmation message
            event.returnValue = 'You have an ongoing exam. Are you sure you want to leave?'
            event.preventDefault()
        }
    }

    const userActions = {
        addDashboardData: (e) => dispatch(addDashboardData(e)),
    }
    const gotoNextQuestion = (finish = false) => {
        setQuesChng(!quesChng)

        if (currentQuestionObj?.answerType !== 'dragDrop' && currentQuestionObj?.answerType !== 'dragDropSequence') {
            declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
        }
        if (!showExplaination) {
            return true
        }

        if (currentQuestion + 1 === trainQuestions?.length || finish) {
            onfinish()
        } else {
            setCurrentQuestion(currentQuestion + 1)
        }
    }

    const declareAnswer = (currQues, givenResponse) => {
        let newAllQuestion = [...allQuestions].map((v, i) => {
            if (v?._id === currQues?._id) {
                if (currQues?.answerType === 'radio' || currQues?.answerType === 'single') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || null

                    newObj.answerGiven = givenResponse || null
                    newObj.correct = (currQues?.answerList?.find((x) => x?.option === answerGiven))?.isTrue || false

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }

                    newObj.attempt = true

                    return newObj

                } else if (currQues?.answerType === 'hotspot') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || null

                    newObj.answerGiven = givenResponse || null
                    newObj.correct = (currQues?.answerList?.find((x) => x?.file?.perUrl === answerGiven))?.isTrue || false

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.attempt = true

                    return newObj

                } else if (currQues?.answerType === 'inputNumber') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || null

                    newObj.answerGiven = answerGiven
                    newObj.correct = Number(answerGiven) === currQues?.answerList?.[0]?.option

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.attempt = true

                    return newObj

                } else if (currQues?.answerType === 'multiple') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || []
                    let trueAns = currQues?.answerList?.filter((x) => x?.isTrue) || []
                    let answerLength = trueAns?.length

                    newObj.answerGiven = answerGiven
                    newObj.correct = trueAns.filter((x) => answerGiven.includes(x?.option)).length === answerLength && answerLength === answerGiven?.length

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.attempt = true

                    return newObj

                } else if (currQues?.answerType === 'dragDrop') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || {}

                    newObj.answerGiven = answerGiven
                    newObj.correct = answerGiven?.correct || false

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.attempt = true

                    return newObj

                } else if (currQues?.answerType === 'dragDropSequence') {
                    let newObj = { ...v }
                    let answerGiven = givenResponse || []

                    newObj.answerGiven = answerGiven
                    newObj.correct = answerGiven.every(obj => obj.spot === obj.circle) && (answerGiven?.length === currQues?.answerList?.length)

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }
                    newObj.attempt = true

                    return newObj

                } else if (currQues?.answerType === 'thesis') {
                    let newObj = { ...v }

                    let answerGiven = givenResponse || null

                    newObj.answerGiven = givenResponse || null

                    if (answerGiven === thesisOptions[0]?.value) {
                        newObj.correct = (newObj?.answerList?.[0]?.isTrue ? true : false)
                    }
                    else if (answerGiven === thesisOptions[1]?.value) {
                        newObj.correct = (newObj?.answerList?.[1]?.isTrue ? true : false)
                    }
                    else if (answerGiven === thesisOptions[2]?.value) {
                        newObj.correct = newObj?.answerList?.every(item => item?.isTrue) ?? false;
                    }
                    else if (answerGiven === thesisOptions[3]?.value) {
                        newObj.correct = newObj?.answerList?.every(item => item?.isTrue) ?? false;
                    }

                    if (newObj.correct) {
                        newObj.point = currQues?.totalPoint
                    } else {
                        newObj.point = 0
                    }

                    newObj.attempt = true

                    return newObj

                }
            } else {
                return v
            }
        })
        setAllQuestions(newAllQuestion)

        let chapResults = _.groupBy(allQuestions.filter((v) => v?.attempt), 'chapter')
        let paraResults = _.groupBy(allQuestions.filter((v) => v?.attempt), 'paragraph')

        setChResults(chapResults)
        setparagraphResults(paraResults)

    }

    const onfinish = () => {
        setExamInProgress(false)
        setResultModal(true)

        saveResult()
    }

    const saveResult = () => {
        let obj = {
            allQuestions: allQuestions.filter((v) => v?.attempt),
            student: user?._id,
            topics,
            train: true,
            test10: test10 || false,
            card: card || null,
            video: video || null
        }
        obj.allQuestions = removeBaseUrls(obj.allQuestions)

        axios.post(POST.SAVE_TRAIN_RESULT, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    studentData.getDashboardData(userActions, user?._id)
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                } else {
                    console.log('error', data)
                    return errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('error', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }
    const saveHotspotResponse = (response, id) => {
        let newObj = { ...selectedAns }
        newObj[id] = response
        setSelectedAns(newObj)
    }
    return (
        <div>
            <div>
                <div className='header-with-text'>
                    <div>
                        <img src={logo} style={{ height: '30px' }} alt='Logo' />
                    </div>
                </div>
                <div className='student-main-div student-part-scroll_class'>
                    <br />
                    <center>
                        {showExplaination ?
                            <TrainTestDetail currentQuestionIsWithImg={allQuestions?.[currentQuestion]?.file?.baseUrl || allQuestions?.[currentQuestion]?.file?.url} currentQuestion={currentQuestion} currentQuestionObj={allQuestions?.[currentQuestion] || {}} {...props} />
                            :
                            <div>
                                <h2 className={'head-center'}>
                                    {currentQuestion + 1} : {currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={currentQuestionObj?.question || ' '} /> : currentQuestionObj?.question}
                                </h2>
                                <Form
                                    name='form'
                                    form={form}
                                    layout='vertical'
                                >
                                    {
                                        currentQuestionObj?.answerType === 'radio' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Radio.Group>
                                                            {(currentQuestionObj?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                                <Radio
                                                                    className='student_radio'
                                                                    value={radioAnswerlist?.option}
                                                                    key={radioI}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={radioAnswerlist?.option || ' '} /> : radioAnswerlist?.option}</p>
                                                                        {(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview) && <img style={{ marginLeft: 10 }} width={100} src={(radioAnswerlist?.file?.baseUrl || radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview)} alt='' />}
                                                                    </div>
                                                                </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : currentQuestionObj?.answerType === 'hotspot' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Radio.Group onChange={e => saveHotspotResponse(e?.target?.value, currentQuestionObj?._id)}>
                                                            {(currentQuestionObj?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                                <Radio
                                                                    className='hotspot_radio'
                                                                    value={`${radioAnswerlist?.file?.perUrl}`}
                                                                    key={radioI}
                                                                >
                                                                    <div style={selectedAns?.[currentQuestionObj?._id] && ((selectedAns?.[currentQuestionObj?._id] === radioAnswerlist?.file?.perUrl) || (selectedAns?.[currentQuestionObj?._id] === radioAnswerlist?.file?.preview)) ? { backgroundColor: 'lightsalmon', padding: 5 } : { padding: 5 }}>
                                                                        {(radioAnswerlist?.file?.url) && <img className='hotspot_image' src={radioAnswerlist?.file?.baseUrl || radioAnswerlist?.file?.url} alt='' />}
                                                                    </div>
                                                                </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : currentQuestionObj?.answerType === 'multiple' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Select
                                                            style={{ minWidth: 200 }}
                                                            className='form-input-new-style'
                                                            showArrow
                                                            allowClear
                                                            mode='multiple'
                                                            placeholder={selectedLanguage?.placeholder_SelectMultipleOption}
                                                        >
                                                            {(currentQuestionObj?.answerList || []).map((multipleAnswerlist, MultipleI) => (
                                                                <Option value={multipleAnswerlist?.option} key={MultipleI}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={multipleAnswerlist?.option || ' '} /> : multipleAnswerlist?.option}</p>
                                                                        {(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.baseUrl || multipleAnswerlist?.file?.url)} alt='' />}
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : currentQuestionObj?.answerType === 'single' ? (
                                            <div className='question_div'>
                                                {currentQuestionIsWithImg &&
                                                    <div className='form-div-img1'>
                                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                                    </div>
                                                }
                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                    <Form.Item
                                                        name={`${currentQuestionObj?._id}`}
                                                        label={selectedLanguage?.title_Answer}
                                                    >
                                                        <Select
                                                            style={{ minWidth: 200 }}
                                                            className='form-input-new-style'
                                                            showArrow
                                                            allowClear
                                                            placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                                        >
                                                            {(currentQuestionObj?.answerList || []).map((singleAnswerlist, SingleI) => (
                                                                <Option value={singleAnswerlist?.option} key={SingleI}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={singleAnswerlist?.option || ' '} /> : singleAnswerlist?.option}</p>
                                                                        {(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.baseUrl || singleAnswerlist?.file?.url)} alt='' />}
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ) : (currentQuestionObj?.answerType === 'dragDrop') ? (
                                            <DragDropStudent quesChng={quesChng} declareAnswer={declareAnswer} currentQuestionObj={currentQuestionObj} sequence={false} spots={currentQuestionObj?.answerList} imgUrl={currentQuestionObj?.dragFile?.baseUrl || currentQuestionObj?.dragFile?.url} {...props} />
                                        )
                                            : (currentQuestionObj?.answerType === 'dragDropSequence') ? (
                                                <DragSequence quesChng={quesChng} declareAnswer={declareAnswer} currentQuestionObj={currentQuestionObj} spots={currentQuestionObj?.answerList} imgUrl={currentQuestionObj?.dragFile?.baseUrl || currentQuestionObj?.dragFile?.url} {...props} />
                                            ) : currentQuestionObj?.answerType === 'thesis' ? (
                                                <div className='question_div'>
                                                    {currentQuestionIsWithImg &&
                                                        <div className='form-div-img1'>
                                                            <img className='question-img1' src={currentQuestionIsWithImg} />
                                                        </div>
                                                    }
                                                    <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                        <div>
                                                            {currentQuestionObj?.answerList?.map((v, i) => {
                                                                return (
                                                                    <>
                                                                        <p style={{ margin: '0px', width: '250px', textAlign: 'start' }}>Thesis {i + 1} : {v?.option}</p>
                                                                    </>
                                                                )
                                                            })}
                                                            <br />
                                                            <div className='question_div'>
                                                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                                    <Form.Item
                                                                        name={`${currentQuestionObj?._id}`}
                                                                        label={selectedLanguage?.title_Answer}
                                                                    >
                                                                        <Radio.Group>
                                                                            {(thesisOptions || [])?.map((radioAnswerlist, radioI) => (
                                                                                <Radio
                                                                                    className='student_radio'
                                                                                    value={radioAnswerlist?.value}
                                                                                    key={radioI}
                                                                                >{radioAnswerlist?.OptionEnglish}
                                                                                </Radio>
                                                                            ))}
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                                : (
                                                    <div className='question_div'>
                                                        {currentQuestionIsWithImg &&
                                                            <div className='form-div-img1'>
                                                                <img className='question-img1' src={currentQuestionIsWithImg} />
                                                            </div>
                                                        }
                                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                            <Form.Item
                                                                name={`${currentQuestionObj?._id}`}
                                                                label={selectedLanguage?.title_Answer}
                                                            >
                                                                {currentQuestionObj?.answerType === 'inputNumber' ?
                                                                    <Input
                                                                        className='form-input-new-style'
                                                                        placeholder='Numeric Answer'
                                                                        type='number'
                                                                        pattern="[0-9]*"
                                                                    />
                                                                    :
                                                                    <Input
                                                                        className='form-input-new-style'
                                                                    />
                                                                }
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                )
                                    }
                                </Form>
                            </div>
                        }
                    </center>

                    <div className='test-footer'>
                        {/* <Button
                            onClick={previousQuestion}
                            size='large'
                            style={{
                                background: '#259bd2',
                                color: 'white',
                            }}
                        >
                            {'< Previous'}
                        </Button> */}
                        <div></div>
                        <p style={{ fontSize: '18px', fontWeight: 'bold', margin: '0px' }}>
                            Question: {currentQuestion + 1}
                        </p>
                        {examInProgress ? <div>
                            <Button
                                onClick={() => (gotoNextQuestion(false)/* , setShowExplaination(state => !state) */)}
                                size='large'
                                type='primary'
                                style={{ marginRight: 5 }}
                            >
                                {'Next >'}
                            </Button>
                            <Button
                                onClick={() => gotoNextQuestion(true)}
                                size='large'
                                style={{
                                    background: '#1eb2a6',
                                    color: 'white'
                                }}
                                type='primary'
                            >
                                {'Finish'}
                            </Button>
                        </div> : <div></div>}
                    </div>
                </div>
            </div >
            <Modal footer={null} closable={false} title='' open={resultModal} >
                <Result
                    status={allQuestions.filter((v) => !v?.attempt)?.length ? 'success' : 'error'}
                    title={allQuestions.filter((v) => !v?.attempt)?.length ? 'You can review your questions' : 'No more Questions Available'}
                    extra={[
                        <Button type='primary' key='result' onClick={() => {
                            history.push({
                                pathname: allPaths?.TRAIN_OVERVIEW,
                                state: {
                                    chResults: chResults,
                                    paragraphResults: paragraphResults,
                                    allQuestions: allQuestions.filter((v) => v?.attempt),
                                }
                            })
                        }}>
                            View My Results
                        </Button>
                    ]}
                >
                </Result>
            </Modal>
        </div >
    )
}

export default TrainTest