import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calculateAverageForClarity, calculateAverageForDetailed, calculateAverageForImages, calculateAverageForLanguageUse, calculateAverageForOverall, calculateAverageForRelevance, calculateReviewAverage, checkSelectOption, errorMessage, successNotification, teacherData } from "../../../utils/helpers";
import { Button, Progress, Select, Skeleton } from "antd";
import { DotChartOutlined, WechatOutlined } from "@ant-design/icons";
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import axios from "axios";
import { POST } from "../../../utils/apis";
import TextArea from "antd/lib/input/TextArea";
import { FaUserCircle } from 'react-icons/fa'
const NewTeacherCardsReview = (props) => {
    const { selectedLanguage, user, userActions, lanKorCondition } = props
    const teacherCards = useSelector(state => state?.userReducer?.teacherCards || [])
    let filterTimeData = [
        { val: 'Newest first' },
        { val: 'Oldest first' },
        { val: 'Highest score' },
        { val: 'Lowest score' }
    ]
    const { Option } = Select
    const [loading, setLoading] = useState(false)
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [index, setIndex] = useState(null)
    const [readingIndex, setReadingIndex] = useState(null)
    const [answer, setAnswer] = useState('')
    const [answerLoading, setAnswerLoading] = useState(false)
    const [commentReadingIndex, setCommentReadingIndex] = useState(null)
    const [filterTime, setFilterTime] = useState(filterTimeData[0]?.val)
    const [GuestReview, setGuestReview] = useState(null)
    const [reviewData, setReviewData] = useState(null)

    useEffect(() => {
        setProgressLoading(true)
        onChnageFilter(teacherCards?.[0]?._id)
        setTimeout(() => {
            setProgressLoading(false)
        }, 1000);
    }, [])


    useEffect(() => {
        let filterReviewModal = selectedProduct?.review
        switch (filterTime) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(filterReviewModal || [])
    }, [selectedProduct])

    const setFilterValue = (e) => {
        setFilterTime(e)
        let filterReviewModal = selectedProduct?.review
        switch (e) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(filterReviewModal || [])
    }

    useEffect(() => {
        teacherData.getTeacherCards(userActions, user?._id)
    }, [])

    const onChnageFilter = (e) => {
        let matchingObj = teacherCards.find(obj => obj._id === e);
        setProgressLoading(true)
        setTimeout(() => {
            setProgressLoading(false)
        }, 1000);
        setSelectedProduct(matchingObj)
        setReviewData(matchingObj?.review)
    }

    const ReviewAnswer = (v) => {
        
        if (answer === '' || v?._id === '' || v?.userId?._id === '') {
            errorMessage(selectedLanguage?.txt_Please_enter_reply_for_your_guest)
        }

        setAnswerLoading(true)
        let obj = {
            answered: answer,
            userId: v?.userId?._id,
            _id: v?._id
        }

        console.log(obj)

        axios.post(POST?.CREATE_REVIEW_ANSWER, obj)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    console.log(data)
                    successNotification(lanKorCondition ? data?.message : data?.messageNl)
                    await teacherData.getTeacherCards(userActions, user?._id)
                    setIndex(null)
                    setAnswerLoading(false)
                    setProgressLoading(true)
                    onChnageFilter(teacherCards?.[0]?._id)
                    setTimeout(() => {
                        setProgressLoading(false)
                    }, 1000);
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                    setAnswerLoading(false)
                }
            })
            .catch((e) => {
                setAnswerLoading(false)
                console.log(e)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    console.log('teacherCards', '=', teacherCards)
    return (
        <div>
            <h3 className='new-exam-title'>{selectedLanguage?.txt_Guest_cards_Reviews}</h3>
            <div className='flex-mobile' style={{ display: 'flex', width: '98%', margin: '10px auto' }}>
                <Select
                    style={{ maxWidth: '98%', width: 350 }}
                    showSearch
                    showArrow
                    allowClear={true}
                    onChange={(e) => onChnageFilter(e)}
                    defaultValue={!selectedProduct && teacherCards?.[0]?._id}
                    className='form-input'
                    placeholder={selectedLanguage?.txt_Select_card_here}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {teacherCards?.map((v, i) => {
                        return (
                            <Option value={v?._id} key={i} >{v?.title}</Option>
                        )
                    })}
                </Select>
            </div>
            <br />
            <div className="div-for-reviews-padd">
                <div style={{ textAlign: 'left', marginTop: 5 }}>
                    <span>
                        <div style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                style={{
                                    height: 34,
                                    width: 34,
                                    padding: 0,
                                    background: 'rgb(30, 178, 166)',
                                    border: 'none',
                                    borderRadius: 4,
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: 'white',
                                }}>
                                {selectedProduct?.review?.length ? calculateReviewAverage(selectedProduct?.review) : 0}
                            </button>
                        </div>
                        <span className='card_sold_price'
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                            <p className='card_title'
                            >{selectedProduct?.title}</p>
                            <span >
                                <p className='card_desc1'>
                                    {selectedProduct?.review?.length ?
                                        calculateReviewAverage(selectedProduct?.review) < 4.5 ?
                                            selectedLanguage?.Bad + ',' :
                                            calculateReviewAverage(selectedProduct?.review) < 5.9 ?
                                                selectedLanguage?.Poor + ',' :
                                                calculateReviewAverage(selectedProduct?.review) < 6.9 ?
                                                    selectedLanguage?.Adeqate + ',' :
                                                    calculateReviewAverage(selectedProduct?.review) < 8.9 ?
                                                        selectedLanguage?.Good + ',' :
                                                        selectedLanguage?.Excellent + ',' : null}

                                    {selectedProduct?.review?.length ?
                                        selectedProduct?.review?.length !== 1 ?
                                            selectedProduct?.review?.length > 999 ?
                                                ' ' +
                                                `${selectedProduct?.review?.length?.slice}`(0, 1) +
                                                '.' +
                                                `${selectedProduct?.review?.length}`?.slice(1, 2) +
                                                'k' + ' ' + selectedLanguage?.Reviews :
                                                ' ' + selectedProduct?.review?.length + ' ' + selectedLanguage?.Reviews :
                                            ' ' + selectedProduct?.review?.length + ' ' + selectedLanguage?.Review :
                                        selectedLanguage?.NoReviewesYet}
                                        
                                </p>
                            </span>
                        </span>
                    </span>
                </div>
                <hr />
                <div >
                    <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.Categories}</h4>
                    <div className='progress-main-div'>
                        <div className='progress-inner-main-div'>
                            <div className='progress-text-point-div'>
                                <p className='review-text'>{selectedLanguage?.ttx_Relevance}</p>
                                <p className='review-text'>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> :
                                        reviewData?.length
                                            ? calculateAverageForRelevance(reviewData) : 0}
                                    {ProgressLoading ? null : '/10'}</p>
                            </div>
                            <div>
                                {ProgressLoading ?
                                    <Skeleton.Input
                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                        active={true}
                                        block={true}
                                    /> : <Progress percent={reviewData?.length ?
                                        (calculateAverageForRelevance(reviewData) * 10) : 0}
                                        showInfo={false} />}
                            </div>
                        </div>
                        <div className='progress-inner-main-div'>
                            <div className='progress-text-point-div'>
                                <p className='review-text'>{selectedLanguage?.ttx_Clarity}</p>
                                <p className='review-text'>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> :
                                        reviewData?.length
                                            ? calculateAverageForClarity(reviewData) : 0}
                                    {ProgressLoading ? null : '/10'}</p>
                            </div>
                            <div>
                                {ProgressLoading ?
                                    <Skeleton.Input
                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                        active={true}
                                        block={true}
                                    /> : <Progress percent={reviewData?.length ?
                                        (calculateAverageForClarity(reviewData) * 10) : 0}
                                        showInfo={false} />}
                            </div>
                        </div>
                    </div>
                    <div className='progress-main-div'>
                        <div className='progress-inner-main-div'>
                            <div className='progress-text-point-div'>
                                <p className='review-text'>{selectedLanguage?.ttx_Detailed}</p>
                                <p className='review-text'>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> :
                                        reviewData?.length
                                            ? calculateAverageForDetailed(reviewData) : 0}
                                    {ProgressLoading ? null : '/10'}</p>
                            </div>
                            <div>
                                {ProgressLoading ?
                                    <Skeleton.Input
                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                        active={true}
                                        block={true}
                                    /> : <Progress percent={reviewData?.length ?
                                        (calculateAverageForDetailed(reviewData) * 10) : 0}
                                        showInfo={false} />}
                            </div>
                        </div>
                        <div className='progress-inner-main-div'>
                            <div className='progress-text-point-div'>
                                <p className='review-text'>{selectedLanguage?.ttx_Languageuse}</p>
                                <p className='review-text'>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> : reviewData?.length
                                            ? calculateAverageForLanguageUse(reviewData) : 0}
                                    {ProgressLoading ? null : '/10'}</p>
                            </div>
                            <div>
                                {ProgressLoading ?
                                    <Skeleton.Input
                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                        active={true}
                                        block={true}
                                    /> : <Progress percent={reviewData?.length ?
                                        (calculateAverageForLanguageUse(reviewData) * 10) : 0}
                                        showInfo={false} />}
                            </div>
                        </div>
                    </div>
                    <div className='progress-main-div'>
                        <div className='progress-inner-main-div'>
                            <div className='progress-text-point-div'>
                                <p className='review-text'>{selectedLanguage?.ttx_Images}</p>
                                <p className='review-text'>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> : reviewData?.length
                                            ? calculateAverageForImages(reviewData) : 0}
                                    {ProgressLoading ? null : '/10'}</p>
                            </div>
                            <div>
                                {ProgressLoading ?
                                    <Skeleton.Input
                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                        active={true}
                                        block={true}
                                    /> : <Progress percent={reviewData?.length ?
                                        (calculateAverageForImages(reviewData) * 10) : 0}
                                        showInfo={false} />}
                            </div>
                        </div>
                        <div className='progress-inner-main-div'>
                            <div className='progress-text-point-div'>
                                <p className='review-text'>{selectedLanguage?.ttx_Overall}</p>
                                <p className='review-text'>
                                    {ProgressLoading ?
                                        <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                        </Skeleton.Node> : reviewData?.length
                                            ? calculateAverageForOverall(reviewData) : 0}
                                    {ProgressLoading ? null : '/10'}</p>
                            </div>
                            <div>
                                {ProgressLoading ?
                                    <Skeleton.Input
                                        style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                        active={true}
                                        block={true}
                                    /> : <Progress percent={reviewData?.length ?
                                        (calculateAverageForOverall(reviewData) * 10) : 0}
                                        showInfo={false} />}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end'
                }}>
                    <Select
                        showSearch
                        showArrow
                        allowClear={false}
                        value={filterTime}
                        onChange={setFilterValue}
                        className='form-input'
                        optionFilterProp='children'
                        style={{ width: '200px', minWidth: '200px', marginTop: '10px' }}
                    >
                        {filterTimeData?.map((v, i) => {
                            return (
                                <Option value={v?.val} key={i}>{`${v?.val}`}</Option>
                            )
                        })
                        }
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.GuestReview}</h4>
                </div>
                {
                    GuestReview?.length ?
                        GuestReview?.map((v, i) => {
                            return (
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', alignItems: 'center' }}>
                                            <div>
                                                {ProgressLoading ?
                                                    <div
                                                        style={{ height: 40, width: 40, borderRadius: 100 }}
                                                    >
                                                        <Skeleton.Avatar
                                                            style={{ margin: 5 }}
                                                            active={true} shape={'circle'} />
                                                    </div> :
                                                    <div
                                                        style={{
                                                            height: 40,
                                                            width: 40,
                                                            border: '2px solid rgb(30, 178, 166)',
                                                            borderRadius: 100,
                                                            // padding: '10px'
                                                        }}
                                                    >
                                                        <FaUserCircle size={36} color="rgb(30, 178, 166)" />
                                                    </div>
                                                }
                                            </div>
                                            {ProgressLoading ? <div style={{ margin: 3, marginLeft: 7 }}>
                                                <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node>
                                                <br />
                                                <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node>
                                            </div> :
                                                <div style={{ margin: 5 }}>
                                                    <p className='review-text' style={{ fontWeight: 700 }}>{v?.userId?.fullName ? v?.userId?.fullName : '----'}</p>
                                                    <p className='review-text' style={{ fontWeight: 400 }}>{v?.userId?.city ? v?.userId?.city : '----'}</p>
                                                </div>}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'flex-end' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                {ProgressLoading ?
                                                    <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 10 }}>
                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                    </Skeleton.Node>
                                                    : <p className='review-text' style={{ marginBottom: 2 }}>
                                                        {v?.average ?
                                                            v?.average < 4.5 ?
                                                                selectedLanguage?.Bad :
                                                                v?.average < 5.9 ?
                                                                    selectedLanguage?.Poor :
                                                                    v?.average < 6.9 ?
                                                                        selectedLanguage?.Adeqate :
                                                                        v?.average < 8.9 ?
                                                                            selectedLanguage?.Good :
                                                                            v?.average > 8.9 ?
                                                                                selectedLanguage?.Excellent :
                                                                                null :
                                                            null}
                                                    </p>}
                                                {ProgressLoading ?
                                                    <Skeleton.Node active={true} style={{ height: 34, width: 34 }}>
                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                    </Skeleton.Node> :
                                                    <button
                                                        style={{
                                                            height: 34,
                                                            width: 34,
                                                            padding: 0,
                                                            background: 'rgb(30, 178, 166)',
                                                            border: 'none',
                                                            borderRadius: 4,
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: 'white',
                                                        }}>
                                                        {v?.average ? v?.average?.toFixed(1) : 0}
                                                    </button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ margin: 5 }}>
                                        <p className='review-text' style={{ fontWeight: 700 }}>
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 5 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node>
                                                :
                                                `${selectedLanguage?.Reviewed} :`}
                                            {ProgressLoading ?
                                                <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 5, marginLeft: 4 }}>
                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                </Skeleton.Node>
                                                :
                                                new Date(v?.created).toLocaleDateString()}
                                        </p>
                                        {ProgressLoading ?
                                            <Skeleton.Node active={true} style={{ height: 40, marginBottom: 5 }}>
                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                            </Skeleton.Node>
                                            :
                                            <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                <p className={'review-text'}   >{v?.comment ? <>
                                                    <WechatOutlined style={{ marginRight: 10 }} /> {
                                                        v?.comment?.length > 100 && commentReadingIndex !== i ?
                                                            v?.comment?.slice(0, 100) + '...'
                                                            :
                                                            v?.comment} </> : '-----'}
                                                    <p className='review-text'
                                                        style={{ color: '#007AFF', cursor: 'pointer' }}
                                                        onClick={() => { setCommentReadingIndex(commentReadingIndex !== i ? i : null) }}
                                                    >{v?.comment?.length > 100 ?
                                                        commentReadingIndex !== i ?
                                                            selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                </p>
                                            </div>
                                        }</div>
                                    <div>

                                        {v?.answered === null ?
                                            index !== i ?
                                                ProgressLoading ?
                                                    <Skeleton.Node active={true} style={{
                                                        height: 34,
                                                        width: 100,
                                                    }}>
                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                    </Skeleton.Node> :
                                                    <button
                                                        onClick={() => {
                                                            setIndex(i);
                                                            setAnswer(v?.userId?.fullName ? `Dear ${v?.userId?.fullName}! ${selectedLanguage?.message_Thanksforyourreview}` : selectedLanguage?.message_Thanksforyourreview)
                                                        }}
                                                        style={{
                                                            height: 34,
                                                            width: 100,
                                                            padding: 0,
                                                            background: 'rgb(30, 178, 166)',
                                                            border: 'none',
                                                            borderRadius: 4,
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: 'white',
                                                            cursor: 'pointer'
                                                        }}
                                                    >{selectedLanguage?.Reply}</button> :
                                                <>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{
                                                            width: '50px',
                                                            height: '50px',
                                                            padding: '14px',
                                                            rotate: '180deg',
                                                        }}>
                                                            <ReplyAllIcon fontSize='large' />
                                                        </div>
                                                        <div style={{ background: '#E9EBEE', width: '80%', padding: 5, borderRadius: 8 }}>
                                                            <TextArea
                                                                defaultValue={answer}
                                                                onChange={(e) => {
                                                                    setAnswer(e?.target?.value)
                                                                }}
                                                                rows={2} placeholder={selectedLanguage?.placeholder_EnterAreply} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '90%', marginTop: 20 }}>
                                                        <button
                                                            onClick={() => {
                                                                setIndex(null);
                                                                setAnswer('')
                                                            }}
                                                            style={{
                                                                height: 34,
                                                                width: 100,
                                                                padding: 0,
                                                                background: 'rgb(30, 178, 166)',
                                                                border: 'none',
                                                                borderRadius: 4,
                                                                fontSize: 12,
                                                                fontWeight: 500,
                                                                color: 'white',
                                                                marginRight: 2,
                                                                cursor: 'pointer'
                                                            }}>{selectedLanguage?.Cancel}</button>
                                                        <button
                                                            loading={answerLoading}
                                                            onClick={() => {
                                                                ReviewAnswer(v)
                                                            }}
                                                            style={{
                                                                height: 34,
                                                                width: 100,
                                                                padding: 0,
                                                                background: 'rgb(30, 178, 166)',
                                                                border: 'none',
                                                                borderRadius: 4,
                                                                fontSize: 12,
                                                                fontWeight: 500,
                                                                color: 'white',
                                                                cursor: 'pointer'
                                                            }}
                                                        >{selectedLanguage?.SendReply}</button>
                                                    </div>
                                                </>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    padding: '14px',
                                                    rotate: '180deg',
                                                }}>
                                                    {ProgressLoading ? <Skeleton.Node active={true} style={{ height: 20, width: 20 }}>
                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                    </Skeleton.Node> : <ReplyAllIcon fontSize='large' />}
                                                </div>
                                                {ProgressLoading ?
                                                    <Skeleton.Node active={true} style={{ height: 40, }}>
                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                    </Skeleton.Node> :
                                                    <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                        <p className={'review-text'}   >{v?.answered ? <>
                                                            <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                v?.answered?.length > 100 && readingIndex !== i ?
                                                                    v?.answered?.slice(0, 100) + '...'
                                                                    :
                                                                    v?.answered} </> : '-----'}
                                                            <p className='review-text'
                                                                style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                onClick={() => { setReadingIndex(readingIndex !== i ? i : null) }}
                                                            >{v?.answered?.length > 100 ?
                                                                readingIndex !== i ?
                                                                    selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                        </p>
                                                    </div>}
                                            </div>}
                                    </div>
                                    <hr />
                                </div>
                            )
                        })
                        :
                        <div>
                            <p className='review-text'>{selectedLanguage?.NoReviewesYet}</p>
                        </div>
                }
            </div>
        </div>

    )
}

export default NewTeacherCardsReview