
import { ALL_ADMIN_FEEDBACK_FORMS_FOR_TEACHER, ALL_FEEDBACK_FORMS, ALL_SUBSCRIBE_GIFTCARDS, ALL_SUBSCRIBE_LOTTERY, BU_INVOICES, DASHBOARD_DATA, FORMS, FORM_REPORTS, INVOICES, MY_CASHBACKS, MY_STUDENTS, OTHER_USER_QUESTIONS, PAYMENTS, PRODUCT_FILTER, RATES, REMOVE_USER, STUDENT_CARDS, STUDENT_RESULT, STUDENT_TRAIN_RESULT, STUDENT_VIDEOS, SUBSCRIPTIONS, TEACHER_CARDS, TEACHER_COUPON, TEACHER_FEEDBACK_FORMS, TEACHER_RESPONSE, TEACHER_TO_STUDENT_RESPONSE, TEACHER_VIDEOS, USER_FAQ, USER_JOINED_GROUPS, USER_PENDING_GROUPS, USER_QUESTIONS, VOUCHERS, WALLET_TRANSACTIONS } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case INVOICES: {
            return { ...state, invoices: action.invoices }
        }
        case PAYMENTS: {
            return { ...state, payments: action.payments }
        }
        case VOUCHERS: {
            return { ...state, vouchers: action.vouchers }
        }
        case PRODUCT_FILTER: {
            return { ...state, filter: action.filter }
        }
        case BU_INVOICES: {
            return { ...state, bu_invoices: action.bu_invoices }
        }
        case SUBSCRIPTIONS: {
            return { ...state, subscriptions: action.subscriptions }
        }
        case RATES: {
            return { ...state, rates: action.rates }
        }
        case USER_JOINED_GROUPS: {
            return { ...state, joined: action.joined }
        }
        case USER_PENDING_GROUPS: {
            return { ...state, pending: action.pending }
        }
        case ALL_SUBSCRIBE_LOTTERY: {
            return { ...state, lotteryDetail: action.lotteryDetail }
        }
        case ALL_SUBSCRIBE_GIFTCARDS: {
            return { ...state, giftCardDetail: action.giftCardDetail }
        }
        case FORMS: {
            return { ...state, forms: action.forms }
        }
        case FORM_REPORTS: {
            return { ...state, formReports: action.formReports }
        }
        case USER_QUESTIONS: {
            return { ...state, questions: action.questions }
        }
        case OTHER_USER_QUESTIONS: {
            return { ...state, otherQuestions: action.otherQuestions }
        }
        case USER_FAQ: {
            return { ...state, userFaq: action.userFaq }
        }
        case WALLET_TRANSACTIONS: {
            return { ...state, walletTransaction: action.walletTransaction }
        }
        case STUDENT_RESULT: {
            return { ...state, studentResult: action.studentResult }
        }
        case STUDENT_TRAIN_RESULT: {
            return { ...state, studentTrainResult: action.studentTrainResult }
        }
        case MY_STUDENTS: {
            return { ...state, myStudents: action.myStudents }
        }
        case STUDENT_CARDS: {
            return { ...state, studentCards: action.studentCards }
        }
        case TEACHER_VIDEOS: {
            return { ...state, teacherVideos: action.teacherVideos }
        }
        case TEACHER_CARDS: {
            return { ...state, teacherCards: action.teacherCards }
        }
        case STUDENT_VIDEOS: {
            return { ...state, studentVideos: action.studentVideos }
        }
        case MY_CASHBACKS: {
            return { ...state, myCashbacks: action.myCashbacks }
        }
        case TEACHER_FEEDBACK_FORMS: {
            return { ...state, teacherFeedbackForm: action.teacherFeedbackForm }
        }
        case ALL_FEEDBACK_FORMS: {
            return { ...state, allFeedbackForm: action.allFeedbackForm }
        }
        case ALL_ADMIN_FEEDBACK_FORMS_FOR_TEACHER: {
            return { ...state, allAdminFeedbackForTeacher: action.allAdminFeedbackForTeacher }
        }
        case DASHBOARD_DATA: {
            return { ...state, dashboardData: action.dashboardData }
        }
        case TEACHER_COUPON: {
            return { ...state, teacherCoupon: action.teacherCoupon }
        }
        case TEACHER_RESPONSE: {
            return { ...state, teacherResponses: action.teacherResponses }
        }
        case TEACHER_TO_STUDENT_RESPONSE: {
            return { ...state, teacherToStudentResponse: action.teacherToStudentResponse }
        }
        case REMOVE_USER: {
            return {}
        }
        default: {
            return state
        }
    }
}

export default reducer