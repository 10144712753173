import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"
import { ADMIN } from "../../utils/apis";

const VisiterFaqContent = (props) => {
    const { selectedLanguage, language } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [faqs, setFaqs] = useState([null])
    const [reading, setReading] = useState('')
    const [readingFaq, setReadingFaq] = useState('')
    let FaqGroup = faqs?.[1] || {}
    const keys = Object.keys(FaqGroup);
    let showfaqs = { userType: 'guest' }

    // console.log(FaqGroup, keys, '1========')

    const getFaq = () => {
        setLoading(true)
        axios.get(ADMIN.GET_ADMIN_FAQ)
            .then((res) => {
                const { data } = res
                data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
                if (Object.keys(data?.FaqArrayGrourp).length > 0) {
                    const arr = Object.entries(data?.FaqArrayGrourp);
                    arr.forEach(([key, value]) => {
                        value.sort((a, b) => a.priority - b.priority);
                    });
                    const sortedObj = Object.fromEntries(arr);
                    setFaqs([data?.howIsWorkArray || [], sortedObj || {}])
                    setLoading(false)
                } else {
                    setLoading(false)
                    setFaqs([data?.howIsWorkArray || [], data?.FaqArrayGrourp || {}])
                }
            }).catch((e) => {
                setLoading(false)
                setFaqs([null])
            })
    }

    useEffect(() => {
        getFaq()
    }, [])

    return (
        <div className="new-home-main-div">
            <div className="home-newbg-img">
                <div className='new-exam-main-div'>
                    <div className='exam-transaprent-div'>
                        <p className='screen-qoute'> {selectedLanguage?.Quote4}</p>
                    </div>
                </div>
            </div>
            <br />
            {/* --------------bellow content---------------- */}

            <div className="visiter-faq-div">
                <div className='new-exam-screen-upper'>
                    <h3 className='new-exam-title' style={{ paddingLeft: '2px' }}>{selectedLanguage?.txt_FAQ}</h3>
                    <br />
                    <br />
                </div>
                <div>
                    <div className='div-flex-back-name'>
                        <div className='height-35'>
                            <p className='new-screen-heading'>{selectedLanguage?.txt_Howitswork}</p>
                        </div>
                    </div>
                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                        {faqs?.[0]?.length ?
                            faqs?.[0]?.map((v, i) => {
                                return (
                                    (v?.catagrie?.includes(showfaqs?.userType) || v?.catagrie?.includes(showfaqs?.ownerType)) &&
                                    <>
                                        <div style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                            borderRadius: '8px',
                                            paddingTop: '30px'
                                        }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.questionEng : v?.questionNl}</p>
                                                {reading !== v?._id ?
                                                    <IoIosArrowDown color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                        setReading(v?._id)
                                                    }} /> :
                                                    <IoIosArrowUp color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                        setReading('')
                                                    }} />}
                                            </div>
                                            {reading === v?._id ?
                                                <div>
                                                    <br />
                                                    <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.answerEng : v?.answerNl}</p>
                                                </div> : null}
                                        </div>
                                    </>
                                )
                            })
                            : null
                        }
                    </div>

                    <>
                        <div className='div-flex-back-name'>
                            <div className='height-35'>
                                <p className='new-screen-heading'>{selectedLanguage?.txt_FAQs}</p>
                            </div>
                        </div>
                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {keys?.length ? keys?.map((v, i) => {
                                let faqArr = FaqGroup?.[v]
                                return (
                                    <>
                                        {faqArr?.filter((vv) => vv?.catagrie?.includes(showfaqs?.userType) || vv?.catagrie?.includes(showfaqs?.ownerType))?.length ?
                                            < div className='height-35' key={i + 2}>
                                                <p className='new-screen-heading' style={{ textAlign: 'center' }}>{isEnglish ? faqArr[0].titleEng : faqArr[0].titleNl}</p>
                                            </div >
                                            : null}

                                        {
                                            faqArr?.filter((vv) => vv?.catagrie?.includes(showfaqs?.userType) || vv?.catagrie?.includes(showfaqs?.ownerType))?.map((vv, ii) => {
                                                return (
                                                    <>
                                                        <div
                                                            key={ii + 1}
                                                            style={{
                                                                padding: '20px',
                                                                width: '100%',
                                                                marginTop: '20px',
                                                                marginBottom: '20px',
                                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                                borderRadius: '8px',
                                                                paddingTop: '30px'
                                                            }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.questionEng : vv?.questionNl}</p>
                                                                {readingFaq !== vv?._id ?
                                                                    <IoIosArrowDown color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                        setReadingFaq(vv?._id)
                                                                    }} /> :
                                                                    <IoIosArrowUp color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                        setReadingFaq('')
                                                                    }} />}
                                                            </div>
                                                            {readingFaq === vv?._id ?
                                                                <div>
                                                                    <br />
                                                                    <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.answerEng : vv?.answerEng}</p>
                                                                </div> : null}
                                                        </div>
                                                    </>
                                                )
                                            })}
                                    </>

                                )
                            }) :
                                <div className='nodata-center-text'>
                                    <p>No Faqs added yet!</p>
                                </div>}
                        </div>
                    </>
                </div>
            </div>
        </div >

    )
}

export default VisiterFaqContent