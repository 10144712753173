import { Image, Modal } from "antd";
import React from "react";


const AdModal = (props) => {
    const { imgUrl, open, onCancel, selectedLanguage } = props
    return (
        <Modal open={open}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}>
            <>
                <h2 className="new-p-question">{selectedLanguage?.txt_Advertisement}</h2>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                    <Image src={imgUrl} width={300} />
                </div>
                <button
                    style={{
                        height: '40px',
                        width: '100%',
                        background: 'white',
                        color: '#1eb2a6',
                        border: 'none',
                        borderBottomLeftRadius: 10,
                        border: '1px solid #1eb2a6',
                        cursor: 'pointer'
                    }}
                    onClick={onCancel}
                >{selectedLanguage?.btn_Cancel}</button>
            </>
        </Modal>
    )


}
export default AdModal