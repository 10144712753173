import { CopyFilled, CopyOutlined, CopyTwoTone, DeleteOutlined, EditTwoTone, EyeTwoTone } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../../utils/apis'
import { checkDurationType, convertTitle, errorMessage, generalData, successNotification } from '../../../../utils/helpers'
import AddSubscription from './AddSubscription'
import { MdPreview } from "react-icons/md";
import PreviewSubscriptioinModal from './PreviewSubscriptionModal'
import AddCbrSubscriptions from './AddCbrSubscriptions'

// import AddTution from './AddTution'

const AdminSubscriptions = (props) => {
    const { user, language, selectedLanguage, adminActions, generalActions } = props
    const [loading, setLoading] = useState(false)
    const [showAddType, setShowAddType] = useState(false)
    const [showAddCbr, setShowAddCbr] = useState(false)
    const [copy, setCopy] = useState({})
    const [previewData, setPreviewData] = useState({})
    const [spin, setSpin] = useState({})
    const isEnglish = language?.language === 'english'
    let subscriptions = useSelector(state => state?.generalReducer?.subscriptions || [])
    subscriptions = subscriptions.filter((v) => !v?.monthlyCoupon && !v?.coupon)
    useEffect(() => {
        generalData.getAllSubscriptions(generalActions)
    }, [])

    const deleteType = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id,
            userId: user?._id
        }
        axios.post(`${ADMIN?.DELETE_SUBSCRIPTION}`, deleteProduct)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    generalData.getAllSubscriptions(generalActions)
                    setSpin({})
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                }
                else {
                    setSpin({})
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let columns = [
        {
            title: selectedLanguage?.txt_Name,
            dataIndex: 'name',
            sorter: (a, b) => a?.name?.localeCompare(b.name)
        },
        {
            title: selectedLanguage?.Type,
            dataIndex: 'subscriptionType',
            align: 'center',
            sorter: (a, b) => a.subscriptionType.localeCompare(b.subscriptionType),
            render: (e) => <>{convertTitle(e)}</>
        },
        {
            title: selectedLanguage?.txt_Content_Type,
            dataIndex: 'contentType',
            align: 'center',
            sorter: (a, b) => (a.contentType || '-').length - (b.contentType || '-').length,
            render: (e) => <>{convertTitle(e || '-')}</>
        },
        {
            title: selectedLanguage?.txt_User_Type,
            dataIndex: 'userType',
            align: 'center',
            sorter: (a, b) => a?.userType?.localeCompare(b.userType),
            render: (e) => <>{e === 'guest' ? selectedLanguage?.txt_Student : selectedLanguage?.txt_Teacher}</>
        },
        {
            title: 'CBR',
            dataIndex: 'cbr',
            align: 'center',
            sorter: (a, b) => a.cbr - b.cbr,
            render: (e) => <>{e ? selectedLanguage?.True : selectedLanguage?.False}</>
        },
        {
            title: selectedLanguage?.txt_Duration,
            align: 'center',
            sorter: (a, b) => {
                let aVal = `${a.duration}${a.durationType}`
                let bVal = `${b.duration}${b.durationType}`
                return aVal.localeCompare(bVal);
            },
            render: (e) => <>{e?.duration.toString() + ' ' + (checkDurationType(e))}</>
        },
        {
            title: selectedLanguage?.Actions,
            key: 'actions',
            align: 'center',
            render: (e) => <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button type='link' onClick={() => setCopy(e)} icon={<CopyTwoTone twoToneColor={'#1EB2A6'} />} />
                &nbsp;
                <Button type='link' onClick={() => setPreviewData(e)} icon={<EyeTwoTone twoToneColor={'#1EB2A6'} />} />
                &nbsp;
                <Popconfirm
                    title={`${selectedLanguage?.Delete} ${convertTitle(e?.name)}?`}
                    okText={selectedLanguage?.Delete}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteType(e?._id)}
                >
                    <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
                &nbsp;
                &nbsp;
            </span>
        }
    ]

    const onCancel = () => {
        setShowAddType(false)
        setShowAddCbr(false)
        setCopy({})
    }
    return (
        <div className='new-flex-cloum'>
            {showAddType || (copy?._id && !copy?.cbr) ?
                <AddSubscription
                    copy={copy}
                    onCancel={onCancel}
                    {...props}
                />
                :
                showAddCbr || (copy?._id && copy?.cbr) ?
                    <AddCbrSubscriptions
                        copy={copy}
                        onCancel={onCancel}
                        {...props}
                    />
                    :
                    <>
                        <div className='button-end button-end-scroll'>
                            <Button
                                size='large'
                                type='primary'
                                onClick={() => setShowAddType(true)}
                            >
                                {selectedLanguage?.txt_Add_Testally_Subscription}
                            </Button>
                            &nbsp;
                            <Button
                                size='large'
                                type='primary'
                                onClick={() => setShowAddCbr(true)}
                            >
                                {selectedLanguage?.txt_Add_CBR_Subscription}
                            </Button>
                        </div>
                        <div className='new-layout-table-upper-div margin0'>
                            <Table
                                style={{ marginTop: '10px' }}
                                className='new-layout-table'
                                scroll={{ x: 'auto' }}
                                columns={columns}
                                dataSource={subscriptions || []}
                                pagination={{ hideOnSinglePage: true }}
                            />
                        </div>
                    </>}

            {previewData?._id &&
                <PreviewSubscriptioinModal open={true} data={previewData} onCancel={() => setPreviewData(null)} {...props} />
            }

        </div>
    )
}

export default AdminSubscriptions

