import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, POST } from '../utils/apis'
import { adminData, checkSelectOption, errorMessage, generalData, successMessage, teacherData } from '../utils/helpers'

const { Option } = Select

const CreateLeadForm = (props) => {
    const { onCancel, forms, user, copyForm, setCopyForm, openForm, selectedLanguage, lanKorCondition, userActions, generalActions, adminActions } = props
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const [form] = Form.useForm()
    const [previousQuestions, setPreviousQuestions] = useState(copyForm?.questions || [])
    const [selectedQuestions, setSelectedQuestions] = useState(copyForm?.questions || [])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [formType, setFormType] = useState(copyForm?.formType || 'personal')
    const [questionType, setQuestionType] = useState(copyForm?.questionsType || 'test')
    const [totalPoints, setTotalPoints] = useState(copyForm?.totalPoints || 0)
    const [selectedSubject, setSelectedSubject] = useState(copyForm?.subjects || null)
    const [filteredForms, setFilteredForms] = useState(forms)
    const [selectedForms, setSelectedForms] = useState([])
    const [allQuestionPoints, setAllQuestionPoints] = useState(copyForm?.questions?.questionPoints || [])

    useEffect(() => {
        copyForm?._id && onCopyForm()
        generalData?.getAllSubjects(generalActions, user?.verify)
    }, [questionType])
    useEffect(() => {
        setFilteredForms(forms.filter(checkSubjects).filter(checkType))
    }, [selectedSubject, questionType])

    const onQuestionTypeChange = (e) => {
        setQuestionType(e.target.value)
    }
    const onFinish = (values) => {
        values.userId = `${user?._id}`
        values.formType = formType
        values.questionsType = questionType
        values.totalPoints = totalPoints

        if (!user?.ownerType) {
            values.isAdmin = true
        }

        values.questionPoints = allQuestionPoints
        values.lead = true
        values.selectedForms = values?.forms

        setLoading(true)
        axios.post(`${POST.CREATE_QUESTIONS_FORM}`, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {

                    if (user?.ownerType) {
                        teacherData.getTeacherForms(userActions, user?._id)
                    } else {
                        adminData.getAdminForms(adminActions, user?._id)
                    }

                    form.resetFields()

                    openForm()
                    setCopyForm({})
                    openForm()
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                onCancel()
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const onChange = (e) => {
        setFormType(e.target.value)
    }
    const onSelectForm = (value) => {
        let formSelected = filteredForms.filter((v) => value.includes(v?._id))
        setSelectedForms(formSelected)
        let filteredQuestion = (formSelected.map((v) => v?.questions)).flat()

        setSelectedQuestions([...filteredQuestion])
        setAllQuestionPoints((formSelected.map(v => v?.questionPoints).flat()))
        setTotalPoints(_.sumBy(formSelected, 'totalPoints'))

        form.setFieldsValue({
            questions: filteredQuestion?.map((v) => v?._id)
        })
    }
    const onCopyForm = () => {
        let formSelected = filteredForms.filter((v) => copyForm?.selectedForms.includes(v?._id))
        setSelectedForms(formSelected)
        let filteredQuestion = (formSelected.map((v) => v?.questions)).flat()
        setAllQuestionPoints((formSelected.map(v => v?.questionPoints).flat()))

        setSelectedQuestions([...filteredQuestion])
        setTotalPoints(_.sumBy(formSelected, 'totalPoints'))

        form.setFieldsValue({
            questions: filteredQuestion?.map((v) => v?._id)
        })
    }

    const checkSubjects = (v) => {
        if (selectedSubject) {
            return v?.subjects === selectedSubject?.toString() && !v?.lead
        } else {
            return !v?.lead
        }
    }
    const checkType = (v) => {
        return v?.questionsType === questionType
    }
    const onChangeSubject = (id) => {
        setSelectedQuestions([])
        form.setFieldsValue({
            questions: [],
        })
    }
    const onChangePoint = (value, id) => {
        // let questionsData = JSON.parse(JSON.stringify(selectedQuestions))
        // questionsData[i].point = value
        // setSelectedQuestions(questionsData)

        let newPoints = allQuestionPoints?.map((v, i) => {
            if (v?._id == id) {
                return { _id: v?._id, point: value }
            } else {
                return v
            }
        })
        setTotalPoints(newPoints.reduce((a, { point }) => a + point, 0))

        setAllQuestionPoints(newPoints)
    }
    const onCancelModal = () => {
        // setSelectedQuestions(previousQuestions)
        setVisible(false)
    }
    const onOk = () => {
        // setPreviousQuestions(selectedQuestions)
        setTotalPoints(allQuestionPoints.reduce((a, { point }) => a + point, 0))

        setVisible(false)
        // form.setFieldsValue({
        //     limit: _.sumBy(allQuestionPoints, 'point')
        // })
    }

    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }} >
                <Button
                    type='primary'
                    onClick={() => { onCancel(); setCopyForm({}) }}
                >
                    {selectedLanguage?.Back}
                </Button>
            </span>

            <div className='crt-question-div'>
                <Modal title={selectedLanguage?.title_Questions} open={visible} onOk={onOk} onCancel={onCancelModal} okText={selectedLanguage?.Ok} cancelText={selectedLanguage?.Cancel}>
                    {selectedQuestions?.map((v, i) => (
                        <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                            <Col span={24}>
                                <span>
                                    {`${i + 1}.) ${v?.question}`}&nbsp;&nbsp;&nbsp;
                                </span>
                                <span>
                                    <InputNumber
                                        type='number'
                                        min={0}
                                        max={100}
                                        className='subs-input'
                                        value={(allQuestionPoints?.find(x => x?._id === v?._id))?.point}
                                        onChange={e => onChangePoint(e, v?._id)}
                                    />
                                </span>
                            </Col>
                        </Row>
                    ))}
                </Modal>
                <p className='crt-qutin-heading'>{selectedLanguage?.txt_CreateLeadForm}</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    name='Form'
                    layout='vertical'
                    requiredMark={true}>
                    <Form.Item
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Pleaseentertitle,
                            }
                        ]}
                        label={selectedLanguage?.title_Title}
                        required
                        initialValue={copyForm?.title}
                    >
                        <Input.TextArea placeholder={selectedLanguage?.txt_Entertitlehere} />
                    </Form.Item>

                    <Form.Item
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Pleaseenterdescription
                            }
                        ]}
                        label={selectedLanguage?.txt_Description}
                        required
                        initialValue={copyForm?.description}
                    >
                        <Input.TextArea placeholder={selectedLanguage?.txt_Entedescriptionhere} />
                    </Form.Item>

                    <Form.Item
                        required
                        name='formType'
                        label={selectedLanguage?.title_FormType}
                        initialValue={copyForm?.formType}
                    >
                        <Radio.Group onChange={onChange} defaultValue='personal'>
                            <Radio.Button value='personal'>{selectedLanguage?.Button_Personal}</Radio.Button>
                            <Radio.Button value='anonymous'>{selectedLanguage?.Button_Anonymous}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='formName'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseEnterFormName,
                            }
                        ]}
                        label={selectedLanguage?.label_FormName}
                        required
                        initialValue={copyForm?.formName}
                    >
                        <Input placeholder={selectedLanguage?.placeholder_EnterFormName} />
                    </Form.Item>
                    {/* <Form.Item
                        name='questionsType'
                        label={selectedLanguage?.label_QuestionsType}
                        required
                        initialValue={copyForm?.questionsType}
                    >
                        <Radio.Group onChange={onQuestionTypeChange} defaultValue='questionnaire'>
                            <Radio.Button value='questionnaire'>{selectedLanguage?.Button_Questionnaire}</Radio.Button>
                            <Radio.Button value='test'>{selectedLanguage?.Button_Test}</Radio.Button>
                        </Radio.Group>
                    </Form.Item> */}
                    <Form.Item
                        name='subjects'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectSubject
                            }
                        ]}
                        initialValue={copyForm?.subjects}
                    >
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            onChange={(e) => (setSelectedSubject(e), onChangeSubject(e))}
                            placeholder={selectedLanguage?.label_SelectSubject}>
                            <Option value={null}>{null}</Option>
                            {subjects.map((v, i) => {
                                return (
                                    <Option key={i} value={v?._id}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='forms'
                        label={selectedLanguage?.txt_SelectForms}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectForms,
                                type: 'array',
                            },
                        ]}
                        initialValue={copyForm?.selectedForms || []}
                    >
                        <Select
                            showSearch
                            disabled={!selectedSubject}
                            mode='multiple'
                            onChange={onSelectForm}
                            placeholder={selectedLanguage?.txt_SelectForms}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {filteredForms.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v.formName + ', ' + v?.chapters}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='questions'
                        label={selectedLanguage?.label_SelectQuestions}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectyourQuestions,
                                type: 'array',
                            },
                        ]}
                        initialValue={_.map(copyForm?.questions, '_id')}
                    >
                        <Select
                            disabled={true}
                            mode='multiple'
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            placeholder={selectedLanguage?.placeholder_SelectQuestion}>
                            {selectedQuestions.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v.question}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='totalPoints'
                        label={selectedLanguage?.label_Totalpoints}
                        initialValue={totalPoints || copyForm?.totalPoints}
                    >
                        <div>
                            <Input disabled value={totalPoints} />
                        </div>
                    </Form.Item>
                    <Form.Item
                        name='extraTime'
                        label={selectedLanguage?.txt_ExtraTime}
                        initialValue={copyForm?.extraTime || 0}
                    >
                        <InputNumber
                            type='number'
                            min={0}
                            precision={0}
                            addonAfter='s'
                            placeholder={selectedLanguage?.txt_enterExrtaTimeHere}

                        />
                    </Form.Item>

                    {
                        selectedForms.map((v, i) => {
                            let limit = v?.limit
                            return (
                                <Form.Item
                                    key={i}
                                    name={['limit', Object.keys(limit)?.[0]]}
                                    label={Object.keys(limit)?.[0]}
                                    initialValue={copyForm?._id ? copyForm?.limit?.[Object.keys(limit)?.[0]] : Object.values(limit)?.[0]}
                                >
                                    <InputNumber
                                        defaultValue={copyForm?._id ? copyForm?.limit?.[Object.keys(limit)?.[0]] : Object.values(limit)?.[0]}
                                        max={totalPoints}
                                        precision={0}
                                        value={totalPoints}
                                    />
                                </Form.Item>
                            )
                        })}

                    {user?.ownerType === 'teacher' &&
                        <Form.Item
                            name='public'
                            valuePropName='checked'
                            initialValue={copyForm?.public || false}
                        >
                            <Checkbox>
                                {selectedLanguage?.label_Public}
                            </Checkbox>
                        </Form.Item>
                    }
                    <Form.Item
                        name='display'
                        valuePropName='checked'
                        initialValue={copyForm?.display || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.txt_Display}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <Button type='primary'
                            style={{
                                float: 'right',
                                padding: '0px 20px 0px 20px',
                                marginBottom: '-27px',
                                height: 45
                            }}
                            onClick={() => setVisible(true)}
                        >
                            {selectedLanguage?.Button_PreviewQuestions}
                        </Button>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            block
                            style={{
                                padding: '0px 20px 0px 20px',
                                height: 45
                            }}
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </>
    )
}

export default CreateLeadForm