import { Image, Input, Modal, Spin, Switch } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
import { checkDurationType, convertTitle, errorMessage, successMessage } from '../../utils/helpers'

const ContentSubModal = (props) => {
    const { teacher, show, selectedSubscription, user, onCancel, selectedContentDetail, lanKorCondition, selectedLanguage, getUser, allTeachers } = props
    const [CouponCodeValue, setCouponCodeValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(false)
    const [adModal, setAdModal] = useState(false)
    const [imgUrl, setImgUrl] = useState('')
    const [showCouponInput, setShowCouponInput] = useState(false)
    const [loadingTeacher, setLoadingTeacher] = useState(false)
    const checkSubStatus = () => {
        let subscription = user?.subscription?.subscriptionType === selectedContentDetail?.contentType ? user?.subscription : {}
        if (subscription?._id) {
            const expiryDate = moment(subscription?.expiry)
            const currentDate = moment()
            const hasExpired = expiryDate.isBefore(currentDate)

            return hasExpired ? '(renew)' : '(extend)'
        } else {
            return ' '
        }
    }

    console.log('selectedContentDetail', selectedContentDetail)
    const onChangePaymentMethod = (e) => {
        setPaymentMethod(e)
    }

    const handleCouponCodeChange = (e) => {
        let newCode = e?.target?.value?.replace(/[^A-Za-z0-9]/g, '')
        newCode = newCode?.replace(/(.{3})/g, '$1-')
        newCode = newCode?.replace(/-$/, '')
        setCouponCodeValue(newCode)
    }

    const openAddModal = (url) => {
        setAdModal(true)
        setImgUrl(url)
    }
    const goBack = () => {
        setImgUrl('')
        onCancel()

    }
    const buyContentSubscription = () => {

        setLoadingTeacher(true)

        if (user?.walletBalance < selectedContentDetail?.amount) {
            setLoadingTeacher(false)
            return errorMessage(selectedLanguage?.txt_TobeabletopaybywalletfirstouploadorpaybyMollie)
        }

        if (showCouponInput && !CouponCodeValue && CouponCodeValue === '') {
            setLoadingTeacher(false)
            return errorMessage(selectedLanguage?.error_Entercouponcodeplease)
        }

        let obj = {
            userId: user?._id,
            price: selectedContentDetail?.amount,
            duration: selectedContentDetail?.duration,
            durationType: selectedContentDetail?.durationType,
            subscriptionName: selectedContentDetail?.name,
            subscriptionId: selectedContentDetail?._id,
            subscriptionType: selectedContentDetail?.contentType
        }

        showCouponInput && (obj.couponUsed = true)
        showCouponInput && (obj.couponCode = CouponCodeValue)
        axios.post(POST.BUY_CONTENT_SUBSCRIPTION, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    getUser()
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    setLoadingTeacher(false)
                    if (data?.data) {
                        openAddModal(data?.data)
                    }
                }
                else {
                    setLoadingTeacher(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoadingTeacher(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const buyContentSubscriptionRequest = () => {
        let obj = {
            userId: user?._id,
            price: selectedContentDetail?.amount,
            duration: selectedContentDetail?.duration,
            durationType: selectedContentDetail?.durationType,
            subscriptionName: selectedContentDetail?.name,
            subscriptionId: selectedContentDetail?._id,
            subscriptionType: selectedContentDetail?.contentType
        }

        showCouponInput && (obj.couponUsed = true)
        showCouponInput && (obj.couponCode = CouponCodeValue)
        setLoadingTeacher(true)
        axios.post(POST.BUY_CONTENT_SUBSCRIPTION_MOLLIE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    window.location.href = data?.redirectUrl
                }
                else {
                    setLoadingTeacher(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoadingTeacher(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <h2 className='new-p-question'>{adModal ? selectedLanguage?.txt_Advertisement : `${selectedLanguage?.txt_ContentSubscription} ${checkSubStatus()}`}</h2>
            {!adModal ?
                <>
                    <h4>{`${selectedLanguage?.txt_Areyousureyouwantto} ${checkSubStatus()} ${selectedLanguage?.txt_subscriptionof} ${selectedContentDetail?.name} ${selectedLanguage?.for}  ${selectedContentDetail?.duration} ${checkDurationType(selectedContentDetail)} € ${selectedContentDetail?.amount}.`} </h4>
                    <div>
                        <p style={{ fontWeight: 'bold', margin: '0px 0px 2px 0px' }}>{selectedLanguage?.ttx_Selectpaymentmethod}</p>
                        {/* <p style={{ fontWeight: 'bold', margin: '0px 0px 2px 0px' }}>{selectedLanguage?.txt_Deductmoneyformyourwallet} </p> */}
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                            <p className='my-label-text'>{selectedLanguage?.txt_YesWallet} </p>
                            <Switch
                                onChange={onChangePaymentMethod}
                                checked={paymentMethod}
                                checkedChildren={true}
                                unCheckedChildren={false}
                            />
                            <p className='my-label-text' style={{ marginLeft: '10px' }}>{selectedLanguage?.txt_NoMollie}</p>
                        </div>
                    </div>
                    <div >
                        <p
                            style={{ marginLeft: '4px', cursor: 'pointer' }}
                            className='color-c-Green'
                            onClick={() => {
                                setShowCouponInput(!showCouponInput)
                                setCouponCodeValue('')
                            }}
                        >{!showCouponInput ? selectedLanguage?.text_DoYouCanCouponCode : selectedLanguage?.text_idonthavecouponcode}</p>

                        {showCouponInput &&
                            <>
                                <p>{selectedLanguage?.txt_Enter_Coupon_Code}</p>
                                <Input
                                    value={CouponCodeValue}
                                    onChange={handleCouponCodeChange}
                                    className='form-input-new-style'
                                    placeholder={'###-###-###'}
                                    maxLength={11}
                                />
                            </>
                        }
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <button
                            style={{
                                height: '40px',
                                width: '50%',
                                background: 'white',
                                color: '#1eb2a6',
                                border: 'none',
                                borderBottomLeftRadius: 10,
                                border: '1px solid #1eb2a6',
                                cursor: 'pointer'
                            }}
                            onClick={goBack}
                        >{selectedLanguage?.btn_Cancel}</button>
                        <button
                            style={{
                                height: '40px',
                                width: '50%',
                                background: '#1eb2a6',
                                color: 'white',
                                border: 'none',
                                borderBottomRightRadius: 10,
                                cursor: 'pointer'
                            }}
                            onClick={paymentMethod ? buyContentSubscriptionRequest : buyContentSubscription}
                        >{loadingTeacher ? <Spin /> : null}{selectedLanguage?.btn_Subscribe}</button>
                    </div>
                </> :
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                        <Image src={imgUrl} width={300} />
                    </div>
                    <button
                        style={{
                            height: '40px',
                            width: '100%',
                            background: 'white',
                            color: '#1eb2a6',
                            border: 'none',
                            borderBottomLeftRadius: 10,
                            border: '1px solid #1eb2a6',
                            cursor: 'pointer'
                        }}
                        onClick={goBack}
                    >{selectedLanguage?.btn_Cancel}</button>
                </>
            }
        </Modal >
    )
}

export default ContentSubModal