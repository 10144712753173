import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ShowCardDetails } from '../../../Components'
import { ADMIN } from '../../../utils/apis'
import { adminData, errorMessage, successMessage } from '../../../utils/helpers'
import AdminAddCards from './AdminAddCard'

const AdminCards = (props) => {
    const { adminActions, user, lanKorCondition, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [showAddImage, setShowAddImage] = useState(false)
    const [spin, setSpin] = useState({})
    const [data, setData] = useState([])
    const adminCards = useSelector(state => state?.adminReducer?.adminCards || [])
    console.log(adminCards, '===adminCards')

    useEffect(() => {
        adminData.getAdminCards(adminActions)
    }, [])

    const deleteImages = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_CARDS, { id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    adminData.getAdminCards(adminActions)
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(lanKorCondition ? e?.messege : e?.messegeNl)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.txt_Title,
            key: 'title',
            dataIndex: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: selectedLanguage?.txtSubject,
            key: 'subject',
            dataIndex: 'subject',
            render: (e) => <>{e || '----'}</>
        },
        {
            title: selectedLanguage?.label_Chapter,
            key: 'chapter',
            dataIndex: 'chapter',
            sorter: (a, b) => a.chapter.length - b.chapter.length,
        },
        {
            title: selectedLanguage?.txt_Paragraph,
            key: 'paragraph',
            dataIndex: 'paragraph',
            sorter: (a, b) => a.paragraph.length - b.paragraph.length,
        },
        {
            title: selectedLanguage?.txt_RegularTested,
            key: 'regularTested',
            dataIndex: 'regularTested',
            sorter: (a, b) => a.regularTested - b.regularTested,
            render: (e) => <>{e ? selectedLanguage?.True : selectedLanguage?.False}</>
        },
        {
            title: selectedLanguage?.txt_Files,
            key: 'content',
            dataIndex: 'content',
            render: (e) => <Button onClick={() => setData(e)}>{selectedLanguage?.txt_Files}</Button>,
        },
        {
            title: selectedLanguage?.txt_Action,
            render: (e) => <>
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteImages(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button type='primary' danger>{selectedLanguage?.txt_Delete}</Button>
                    }
                </Popconfirm >
            </>
        },
    ]

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: '10px' }}>
                <h1 className='crt-qutin-heading'>
                    {selectedLanguage?.txt_Cards}
                </h1>
            </div>
            <Button
                type='primary'
                style={{ float: 'right', margin: '10px', marginRight: '0px' }}
                onClick={() => setShowAddImage(true)}
            >
                {selectedLanguage?.txt_AddCards}
            </Button>
            <AdminAddCards showAddImage={showAddImage} onCancel={() => setShowAddImage(false)} {...props} />
            <div>
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        scroll={{ x: '800px' }}
                        loading={loading}
                        columns={columns}
                        dataSource={adminCards}
                        pagination={true}
                    />
                </div>
            </div>
            <ShowCardDetails content={data || []} onCancel={() => setData([])} {...props} />
        </>
    )
}

export default AdminCards
