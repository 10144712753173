import React from 'react';
import ReactApexChart from 'react-apexcharts';
import loafingImg from '../../../assets/load.gif'
import nodataFound from '../../../assets/NewImages/grapgh-not.png'

const TraningChart = (props) => {
    const { text, data, selectedLanguage, loading } = props
    const chartState = {
        series: [data?.wrong || 0, data?.correct || 0/* , data?.questions || 0 */],
        options: {
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -10,
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    return `${w.globals.series[seriesIndex]}`;
                },
            },
            chart: {
                type: 'pie',
            },
            labels: ['Wrong', 'Correct'/*  'Total' */],
            colors: ['#3498db', '#2ecc71'/*  '#3498db' */],
            legend: {
                position: 'bottom'
            }
        }
    }
    console.log(chartState, '=========1234')

    return (
        <div className='dasboard-card'>
            <h2 className='inner-dasboard-card-title' style={{ color: 'black', textTransform: 'none' }}>{text}</h2>
            <div className='dasboard-main-chart-div' style={{ marginTop: 0 }}>
                <br />
                <br />
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={loafingImg} />
                    </div>
                    :
                    (data?.questions > 0 && (data?.correct > 0 || data?.wrong > 0)) ?
                        <div id='chart'>
                            <ReactApexChart
                                options={chartState.options}
                                series={chartState.series}
                                type='pie'
                                width={'100%'}
                                height={205} />
                        </div>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <img src={nodataFound} style={{ height: '140px' }} />
                            <p>{selectedLanguage?.txt_graphnotFound}</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default TraningChart