import React from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import NoDataComponent from '../NoData/NoDataComponent'
import SubscriptionScreen from '../../Screens/Subscription/Subscription'


const NewSubscriptionScreen = (props) => {
    const { user, selectedLanguage } = props
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{user?.userType !== 'guest' ? selectedLanguage?.txt_wallet : selectedLanguage?.txt_SUBSCRIPTION}</h3>
                <div className='main-div-for-sub-cards'>
                    <SubscriptionScreen {...props} />
                </div>
            </div>
        </div>
    )
}
export default NewSubscriptionScreen