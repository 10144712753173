import React, { useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import MyPdfView from './MyPdfView'

const ViewCard = (props) => {
    const { card, showData, onCancel,selectedLanguage } = props
    const [numPages, setNumPages] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log(numPages, "numberpage")
        setNumPages(numPages)
    }


    return (
        <div className='flex-column menu-main'>
            <Modal
                title={card?.designName}
                open={showData}
                onCancel={onCancel}
                className='modal-view'
                style={{top:'10px'}}
                footer={[
                    <Button
                        key='back'
                        onClick={onCancel}
                        className='form-input'
                    >
                        {selectedLanguage?.text_Return}
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <MyPdfView pdfUrl={card?.fileUrl} />
                </div>
            </Modal>
        </div>
    )
}

export default ViewCard