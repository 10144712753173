import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoginLanguageSelect from '../../Components/LoginLanguageSelect/LoginLanguageSelect'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { removeUser } from '../../Redux/actions/authActions'
import testallyLogo from "../../assets/testallyLogo-removebg.png"
import { AUTH } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { errorMessage, successMessage } from '../../utils/helpers'

const ForgotPassword = (props) => {
    const { history } = props
    const path = history?.location?.pathname
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('Search')
    const [heading, setHeading] = useState(path === allPaths?.BU_FORGOT ? 'Business User' : 'Guest')
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {
        setLoading(true)
        setBtnText('Searching...')

        values.userType = path === allPaths?.BU_FORGOT ? 'business' : 'guest'

        axios.post(AUTH.FORGOT_PASSWORD, values)
            .then((result) => {
                const { data } = result
                setLoading(false)
                setBtnText('Search')
                if (data.success) {
                    return successMessage(isEnglish ? data.message : data.messageNl)
                }
                errorMessage(isEnglish ? data.message : data.messageNl)
            })
            .catch((err) => {
                setLoading(false)
                setBtnText('Search')
                errorMessage(isEnglish ? err.message : err.messageNl)
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div className='new-layout-scroll-class'
            style={{
                height: '100vh',
                background: '#A4C8CE',
                overflow: 'auto'
            }}>
            <LoginLanguageSelect color={true} />

            <div className='auth-screen-main-div'>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <img className='login-top-img' src={testallyLogo} />
                </div>
                <p className='heading head-center'>({heading === 'Guest' ? selectedLanguage?.txt_Student : selectedLanguage?.txt_Teacher}) {selectedLanguage?.ResetPassword}</p>

                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-input-single-input'>
                        <Form.Item
                            name='email'
                            label={<p className='new-pp-colr-wht'>{selectedLanguage?.Email}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourEmail,
                                },
                                {
                                    type: 'email',
                                    message: selectedLanguage?.EmailIsNotValid,
                                },
                            ]}
                        >
                            <Input
                                style={{ width: '99%', marginTop: '10px', marginLeft: '0.5%' }}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourEmailHere}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Button
                            type='primary'
                            className='newlogin-btn form-input-new-style'
                            style={{ height: '45px', width: '99%', marginTop: '10px', marginLeft: '0.5%' }}
                            htmlType='submit'
                            loading={loading}
                        >
                            {btnText === 'Search' ? selectedLanguage?.Search : selectedLanguage?.Searching}
                        </Button>
                        <br />
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            {path === allPaths?.BU_FORGOT ? <p>
                                {selectedLanguage?.AlreadyHaveAccount} <Link to={allPaths?.BU_LOGIN}>
                                    <span className='new-pp-colr-wht'>{selectedLanguage?.txt_Teacherlogin}</span>
                                </Link>
                                <br />
                                {selectedLanguage?.DontHaveAnAccount} <Link to={allPaths?.BU_SIGNUP}>
                                    <span className='new-pp-colr-wht'>{selectedLanguage?.txt_Teacherregister}</span>
                                </Link>
                            </p> : <p>
                                {selectedLanguage?.AlreadyHaveAccount} <Link to={allPaths?.LOGIN}>
                                    <span className='new-pp-colr-wht'>{selectedLanguage?.txtStudentlogin}</span></Link>
                                <br />
                                {selectedLanguage?.DontHaveAnAccount} <Link to={allPaths?.SIGNUP}>
                                    <span className='new-pp-colr-wht'>{selectedLanguage?.txtstudent_registration}</span>
                                </Link>
                            </p>}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword