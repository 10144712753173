import { Button, Table } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import countryCodes from '../../../../utils/countryCodes'
import { generalData } from '../../../../utils/helpers'
import EditTax from './EditTax'

const TaxSettings = (props) => {
    const { language, selectedLanguage, generalActions } = props
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState({})
    const isEnglish = language?.language === 'english'
    const taxes = useSelector(state => state?.generalReducer?.taxes || [])

    useEffect(() => {
        generalData.getAllTaxes(generalActions)
    }, [])

    let columns = [
        {
            title: selectedLanguage?.txt_Countryname,
            dataIndex: 'countryName',
            render: (e) => <p>{(_.find(countryCodes, { code: e }))?.name}</p>
        },
        {
            title: selectedLanguage?.ttx_Default,
            dataIndex: 'default',
            align: 'center',
            sorter: (a, b) => a?.default - b?.default,
        },
        {
            key: 'actions',
            align: 'center',
            render: (e) => <Button type='link' onClick={() => setEdit(e)}>{selectedLanguage?.txt_Edit}</Button>
        }
    ]

    return (
        <div className='new-flex-cloum'>
            {edit?._id ?
                <EditTax
                    edit={edit}
                    onCancel={() => (setEdit({}))}
                    {...props}
                />
                :
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        scroll={{ x: 'auto' }}
                        columns={columns}
                        dataSource={taxes}
                        loading={loading}
                        pagination={{ hideOnSinglePage: true }}
                    />
                </div>
            }
        </div>
    )
}

export default TaxSettings