import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaRegEye } from 'react-icons/fa'
import { FiCopy } from 'react-icons/fi'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { TbEdit } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { QuestionDetail } from '../../Components'
import { ADMIN } from '../../utils/apis'
import { successNotification, teacherData } from '../../utils/helpers'
import EditQuestion from './EditQuestion'

const ViewAllQuestions = (props) => {
    const { onBack, createQuestion, user, setCopyQuestion, selectedLanguage, userActions } = props

    const questions = useSelector(state => state.userReducer.questions)

    const [loading, setLoading] = useState(false)
    // const [questions, setQuestions] = useState([])
    const [spin, setSpin] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [viewSelectedData, setViewSelectedData] = useState({})
    const [editQuestion, setEditQuestion] = useState({})
    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }
    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setViewSelectedData({})
    }

    useEffect(() => {
        teacherData.getMyQuestions(userActions, user?._id)
    }, [])

    const deleteQuestion = (_id) => {
        setSpin({ [_id]: true })

        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${ADMIN.DELETE_QUESTION}`, valObj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    teacherData.getMyQuestions(userActions, user?._id)

                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                setSpin({})
                console.log('e', e)
            })
    }

    const copyQues = (e) => {
        delete e?._id
        createQuestion()
        setCopyQuestion(e)
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Questions}</p>,
            dataIndex: 'question',
            key: 'question',
            sorter: (a, b) => a?.question?.localeCompare(b?.question),
            // render: text => <a>{text}</a>,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Title}</p>,
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a?.title?.localeCompare(b?.title),
            // render: text => <a>{text}</a>,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_Public}</p>,
            dataIndex: 'public',
            key: 'public',
            sorter: (a, b) => a.public - b.public,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Subject}</p>,
            dataIndex: ['subject', 'subjectName'],
            sorter: (a, b) => a?.subject?.subjectName?.localeCompare(b?.subject?.subjectName),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_Chapter}</p>,
            dataIndex: ['chapter', 'chapterName'],
            sorter: (a, b) => a?.chapter?.chapterName?.localeCompare(b?.chapter?.chapterName),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_Paragraph}</p>,
            dataIndex: ['paragraph', 'paragraphName'],
            sorter: (a, b) => a?.paragraph?.paragraphName?.localeCompare(b?.paragraph?.paragraphName),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            key: 'actions',
            align: 'center',
            sorter: (a, b) => a?.isApproved - b?.isApproved,
            render: (e) => <>{
                <>
                    {
                        e?.public && e?.isApproved ?
                            <p style={{ marginBottom: 0, color: 'green' }}>{selectedLanguage?.Approved}</p>
                            : e?.public && e?.isDeclined ?
                                <p style={{ marginBottom: 0, color: 'red' }}>{selectedLanguage?.Declined}</p>
                                : e?.public && !e?.isApproved && !e?.isDeclined ?
                                    <p style={{ marginBottom: 0, color: 'blue' }}>{selectedLanguage?.Pending}</p>
                                    :
                                    <p style={{ marginBottom: 0 }}>{selectedLanguage?.Private}</p>
                    }
                </>
            }</>
        },
        {
            key: 'action',
            render: (e) =>
                <span style={{ width: '115px', display: 'flex' }}>
                    <span onClick={() => showModal(e)}><FaRegEye size={19} style={{ color: 'black', cursor: 'pointer' }} /></span> &nbsp;&nbsp;&nbsp;
                    <span onClick={() => copyQues(e)}><FiCopy size={19} style={{ color: 'black', cursor: 'pointer' }} /></span> &nbsp;&nbsp;&nbsp;
                    <span onClick={() => setEditQuestion(e)}><TbEdit size={19} style={{ color: 'black', cursor: 'pointer' }} /></span> &nbsp;&nbsp;&nbsp;
                    {spin?.[e?._id] ?
                        <Spin /> :
                        <Popconfirm
                            title={selectedLanguage?.title_DeletethisQuestion}
                            okText={selectedLanguage?.Delete}
                            cancelText={selectedLanguage?.Cancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => deleteQuestion(e?._id)}
                        >
                            <span><MdOutlineDeleteOutline size={19} style={{ color: 'red', cursor: 'pointer' }} /></span>
                        </Popconfirm>
                    }
                </span>
        },
    ]
    console.log('viewSelectedData', viewSelectedData)
    return (
        <div>
            {editQuestion?._id ?
                <EditQuestion editQuestion={editQuestion} onCancel={() => setEditQuestion({})} {...props} />
                :
                <div>
                    <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                        <Button
                            type='primary'
                            size='large'
                            onClick={onBack}
                        >
                            {selectedLanguage?.Back}
                        </Button>
                    </span>

                    <div >
                        {viewSelectedData?._id &&
                            <QuestionDetail viewSelectedData={viewSelectedData} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} {...props} />}
                        <div className='new-layout-table-upper-div'>

                            <Table
                                className='new-layout-table'
                                loading={loading}
                                columns={columns}
                                pagination={{ hideOnSinglePage: true }}
                                dataSource={questions}
                                scroll={{ x: 'auto' }}
                            />
                        </div>
                    </div>
                </div>}
        </div >
    )
}

export default ViewAllQuestions;
