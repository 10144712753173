import { Button, Result } from 'antd'
import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { AdminFiles, AdminLayout, Overview, ParaTest, TrainOverview, TrainTest } from '../Components'
import MenuLayoutUpdate from '../Components/MenuLayoutUpdate/MenuLayoutUpdate'
import { NewLayout } from '../NewComponents'
import NewTeacherCashback from '../NewScreen/Teacher/Cashback/Cashback'
import { NewBuPayments, NewHome, NewQuestionScreen, NewResult, NewStudentCards, NewStudentCashback, NewStudentDashboard, NewStudentExam, NewStudentFaq, NewStudentFeedbackScreen, NewStudentInvoice, NewStudentSetting, NewStudentTraning, NewStudentTransaction, NewSubscriptionScreen, NewTeacherFeedback, NewTeacherHome, NewTeacherInvoice, NewTeacherReportScreen, NewTeacherTransaction, StudentVideo, TeacherCards, TeacherCardsReview, TeacherCoupon, TeacherResult, Test10, Videos, VisiterFaq, VisiterHome } from '../NewScreen/index'
import { AddAdminFaq, AdminFaq, AdminLogin, AdminPayments, AdminStudentInvoice, AdminTeacherInvoice, AdminVideos, Coupons, ForgotPassword, Login, NewQuestionForm, TestallyPriceSettings, QuestionFeeReport, Signup, Subjects, UpdatePassword, VerifyEmail } from '../Screens'
import { AdminCards, AdminCashback, AdminFeedback, AdminForm, AdminImages, AdminSubscriptions, AdminQuestions, AdminMainReviewScreen, Language, QuestionFormReport, TaxSettings, Tutions, Users, AdminPriceSettings, ExamReport, CouponReport, SubscriptionReport } from '../Screens/Admin'
import allPaths from './paths'

const Page404 = (props) => {
    const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => history.push('/')}
            >Back Home</Button>}
        />
    )
}

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path={allPaths?.LOGIN} exact component={Login} />
                <Route path={allPaths?.HOME} exact component={VisiterHome} />
                <Route path={allPaths?.VISITER_FAQ} exact component={VisiterFaq} />
                <Route path={allPaths?.SIGNUP} exact component={Signup} />
                <Route path={allPaths?.BU_LOGIN} exact component={Login} />
                <Route path={allPaths?.BU_SIGNUP} exact component={Signup} />
                <Route path={allPaths?.FORGOT} exact component={ForgotPassword} />
                <Route path={allPaths?.BU_FORGOT} exact component={ForgotPassword} />
                <Route path={`${allPaths?.VERIFY_EMAIL}/:token`} exact component={VerifyEmail} />
                <Route path={`${allPaths?.UPDATE_PASSWORD}/:token`} exact component={UpdatePassword} />
                <Route path={`${allPaths?.BU_UPDATE_PASSWORD}/:token`} exact component={UpdatePassword} />
                <Route path={allPaths?.FORM} exact component={NewQuestionForm} />
                <Route path={allPaths?.TOPIC_TRAINING} exact component={TrainTest} />
                <Route path={allPaths?.PARA_TEST} exact component={ParaTest} />
                <Route path={`${allPaths?.OVERVIEW}`} exact component={Overview} />
                <Route path={`${allPaths?.TRAIN_OVERVIEW}`} exact component={TrainOverview} />
                <Route path={`${allPaths?.ADMIN_FORM}/:token`} exact component={AdminForm} />
                <Route path={allPaths?.ADMIN_LOGIN} exact component={AdminLogin} />

                <MenuLayoutUpdate path={allPaths?.GRAPHIC} exact component={AdminFiles} />


                <AdminLayout path={allPaths?.ADMIN_REVIEW} exact component={AdminMainReviewScreen} />
                <AdminLayout path={allPaths?.ADMIN_CASHBACK} exact component={AdminCashback} />
                <AdminLayout path={allPaths?.ADMIN_USERS} exact component={Users} />
                <AdminLayout path={allPaths?.ADMIN_QUESTION} exact component={AdminQuestions} />
                <AdminLayout path={allPaths?.QUESTION_FORM_REPORT} exact component={QuestionFormReport} />
                <AdminLayout path={allPaths?.ADMIN_SETTINGS_TUTION} exact component={Tutions} />
                <AdminLayout path={allPaths?.ADMIN_SETTINGS_SUBJECTS} exact component={Subjects} />
                <AdminLayout path={allPaths?.ADMIN_SETTINGS_LANGUAGES} exact component={Language} />
                <AdminLayout path={allPaths?.ADMINPAYMENTS} exact component={AdminPayments} />
                <AdminLayout path={allPaths?.ADMIN_TAX_SETTINGS} exact component={TaxSettings} />
                <AdminLayout path={allPaths?.QUESTION_FEE_REPORT} exact component={QuestionFeeReport} />
                <AdminLayout path={allPaths?.EXAM_REPORT} exact component={ExamReport} />
                <AdminLayout path={allPaths?.COUPON_REPORT} exact component={CouponReport} />
                <AdminLayout path={allPaths?.SUBSCRIPTION_REPORT} exact component={SubscriptionReport} />
                {/* <AdminLayout path={allPaths?.ADMIN_PRICE_SETTINGS} exact component={TestallyPriceSettings} /> */}
                <AdminLayout path={allPaths?.ADMIN_PRICE_SETTINGS} exact component={AdminPriceSettings} />
                <AdminLayout path={allPaths?.ADMIN_SUBSCRIPTIONS} exact component={AdminSubscriptions} />
                <AdminLayout path={allPaths?.ADMIN_FAQ} exact component={AdminFaq} />
                <AdminLayout path={allPaths?.ADMIN_ADD_FAQ} exact component={AddAdminFaq} />
                <AdminLayout path={allPaths?.ADMIN_STUDENT_INVOICE} exact component={AdminStudentInvoice} />
                <AdminLayout path={allPaths?.ADMIN_TEACHER_INVOICE} exact component={AdminTeacherInvoice} />
                <AdminLayout path={allPaths?.ADMIN_VIDEOS} exact component={AdminVideos} />
                <AdminLayout path={allPaths?.ADMIN_IMAGES} exact component={AdminImages} />
                <AdminLayout path={allPaths?.ADMIN_COUPONS} exact component={Coupons} />
                <AdminLayout path={allPaths?.ADMIN_CARDS} exact component={AdminCards} />
                <AdminLayout path={allPaths?.ADMIN_FEEDBACK} exact component={AdminFeedback} />

                {/* New Layout */}
                <NewLayout path={allPaths?.GUEST_HOME} exact component={NewHome} />
                <NewLayout path={allPaths?.TEACHER_HOME} exact component={NewTeacherHome} />
                <NewLayout path={allPaths?.EXAMINATIONS} exact component={NewStudentExam} />
                <NewLayout path={allPaths?.RESULTS} exact component={NewResult} />
                <NewLayout path={allPaths?.BU_RESULTS} exact component={TeacherResult} />
                <NewLayout path={allPaths?.PROFILE_SETTINGS} exact component={NewStudentSetting} />
                <NewLayout path={allPaths?.BU_PROFILE_SETTINGS} exact component={NewStudentSetting} />
                <NewLayout path={allPaths?.SUBSCRIPTION_PAYMENT} exact component={NewSubscriptionScreen} />
                <NewLayout path={allPaths?.FINANCIAL_SUBSCRIPTION_INVOICE} exact component={NewStudentInvoice} />
                <NewLayout path={allPaths?.BUFINANCIAL_SUBSCRIPTION_INVOICE} exact component={NewTeacherInvoice} />
                <NewLayout path={allPaths?.FINANCIAL_WALLLET_TRANSACTION} exact component={NewStudentTransaction} />
                <NewLayout path={allPaths?.BU_FINANCIAL_WALLLET_TRANSACTION} exact component={NewTeacherTransaction} />
                <NewLayout path={allPaths?.FINANCIAL_CASHBACK_REQUEST} exact component={NewStudentCashback} />
                <NewLayout path={allPaths?.BU_FINANCIAL_CASHBACK_REQUEST} exact component={NewTeacherCashback} />
                <NewLayout path={allPaths?.FAQ} exact component={NewStudentFaq} />
                <NewLayout path={allPaths?.TRAINING} exact component={NewStudentTraning} />
                <NewLayout path={allPaths?.TEST_10} exact component={Test10} />
                <NewLayout path={allPaths?.BUPAYMENTS} exact component={NewBuPayments} />
                <NewLayout path={allPaths?.QUESTIONS} exact component={NewQuestionScreen} />
                <NewLayout path={allPaths?.ST_CARDS} exact component={NewStudentCards} />
                <NewLayout path={allPaths?.TRAIN_VIDEOS} exact component={Videos} />
                <NewLayout path={allPaths?.ST_VIDEO} exact component={StudentVideo} />
                <NewLayout path={allPaths?.TRAIN_CARDS} exact component={TeacherCards} />
                <NewLayout path={allPaths?.BU_FEEDBACK} exact component={NewTeacherFeedback} />
                <NewLayout path={allPaths?.STUDENT_DASHBOARD} exact component={NewStudentDashboard} />
                <NewLayout path={allPaths?.BU_REVIEW} exact component={TeacherCardsReview} />
                <NewLayout path={allPaths?.BUCOUPON} exact component={TeacherCoupon} />
                <NewLayout path={allPaths?.ST_FEEDBACK} exact component={NewStudentFeedbackScreen} />
                <NewLayout path={allPaths?.BU_REPORTS} exact component={NewTeacherReportScreen} />

                <Route path='/:page404' exact component={Page404} />
            </Switch >
        </Router >
    )
}

export {
    Page404, Routes
}
