import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Select, Space, Switch, Upload } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { POST } from '../../../utils/apis';
import { adminData, checkSelectOption, errorMessage, generalData, successMessage, teacherData } from '../../../utils/helpers';
const { Option } = Select

const AdminAddCards = (props) => {
    const { selectedLanguage, showAddImage, onCancel, adminActions, user, userActions, generalActions, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [more, setMore] = useState(false)
    const [form] = Form.useForm()
    const chapters = useSelector(state => state.generalReducer.chapters)
    const [sub, setSub] = useState('')
    const allTeachers = useSelector(state => state.generalReducer.allTeachers)
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])

    useEffect(() => {
        generalData?.getAllSubjects(generalActions, user?.verify)

    }, [])

    let optionsChapter = chapters?.map((v) => v?.chapterName)
    optionsChapter = _.uniq(optionsChapter)
    let optionsParagraph = chapters?.map((v) => v?.paragraph).flat().map((x) => x?.paragraphName)
    optionsParagraph = _.uniq(optionsParagraph)
    let optionsTopics = chapters?.map((v) => v?.paragraph)?.flat()?.map((x) => x?.topics)?.flat()?.map((z) => z?.topicName)
    optionsTopics = _.uniq(optionsTopics)

    const onFinish = async (values) => {
        let formData = new FormData()
        for (let [j, k] of Object.entries(values)) {
            if (j === 'content') {

                for (let v in k) {

                    formData.append(`description[]`, k[v]?.description || null)

                    if (k[v]?.upload?.length) {
                        for (let x of k[v]?.upload) {
                            formData.append(`${v}`, x?.originFileObj)
                        }
                    }
                }
            } else if (Array.isArray(k)) {
                for (let v of k) {
                    formData.append(`${j}[]`, v)
                }
            } else {
                formData.append(j, k)
            }
        }

        if (user?.ownerType) {
            formData.append('user', user?._id)
        } else {
            formData.append('admin', true)
        }

        setLoading(true)

        axios.post(POST?.ADD_CARDS, formData)
            .then((res) => {
                const { data } = res
                // console.log(data)
                setLoading(false)
                if (data?.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)

                    form.resetFields()
                    if (user?.ownerType) {
                        teacherData.getTeacherCards(userActions, user?._id)
                    } else {
                        adminData.getAdminCards(adminActions)
                    }

                    onCancel()
                } else {
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })

    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const onChangeSub = (e) => {
        setSub(e)
    }

    return (
        <Modal
            open={showAddImage}
            footer={false}
            className='pop-info-modal'
            style={{ top: '10px' }}
            onCancel={() => (onCancel(), form.resetFields())}>
            <div>
                <p className='new-p-question'>{selectedLanguage?.txt_UploadFiles}</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                    requiredMark={true}
                >
                    <Form.Item
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.requiredMessage_pleaseEnteraTitle,
                            }
                        ]}
                        label={selectedLanguage?.txt_Title}
                        required
                    // initialValue={copyQuestion?.title}
                    >
                        <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.title_Title} />
                    </Form.Item>
                    <Form.Item
                        name='chapter'
                        label={selectedLanguage?.Chapter}
                        rules={[
                            {
                                required: true,
                                // message: selectedLanguage?.errorMessage_PleaseselectSubject,
                            },
                        ]}
                    // initialValue={copyQuestion?.subject?._id}
                    >
                        <Select
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.txt_PleaseSelectChapter}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {optionsChapter.map((v, i) => {
                                return (
                                    <Option value={v} key={i}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='paragraph'
                        label={selectedLanguage?.label_SelectParagraph}
                        rules={[
                            {
                                required: true,
                                // message: selectedLanguage?.errorMessage_PleaseselectSubject,
                            },
                        ]}
                    >
                        <Select
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.txt_PleaseSelectParagraph}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {optionsParagraph.map((v, i) => {
                                return (
                                    <Option value={v} key={i}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='subject'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectSubject
                            }
                        ]}
                    >
                        <Select
                            optionFilterProp='children'
                            onChange={onChangeSub}
                            filterOption={checkSelectOption}
                            placeholder={selectedLanguage?.label_SelectSubject}>
                            {subjects.map((v, i) => {
                                return (
                                    user?.ownerType && v?.subjectName === 'CBR' ? null :
                                        <Option key={i} value={v?.subjectName}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='topics'
                        label={selectedLanguage?.Topic}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMsg_enterTopic
                            }
                        ]}
                    >
                        <Select
                            mode='multiple'
                            showArrow
                            showSearch
                            allowClear
                            placeholder={selectedLanguage?.txt_SelectTopic}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {optionsTopics.map((v, i) => {
                                return (
                                    <Option value={v} key={i}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='regularTested'
                        valuePropName='checked'
                        initialValue={false}
                    >
                        <Checkbox>
                            {selectedLanguage?.txt_Regular_Tested}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name='theory'
                        valuePropName='checked'
                        // noStyle
                        initialValue={false}
                    >
                        <Switch checkedChildren={selectedLanguage?.txt_Practical} unCheckedChildren={selectedLanguage?.txt_Theory} />
                    </Form.Item>
                    {!user?.ownerType && sub !== 'CBR' &&
                        <Form.Item
                            name='teachers'
                            label={selectedLanguage?.txt_Teacher}
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Select
                                mode='multiple'
                                showArrow
                                showSearch
                                allowClear
                                placeholder={selectedLanguage?.txt_PleaseSelectTeacher}
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {allTeachers.map((v, i) => {
                                    return (
                                        <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    }
                    <Form.List name='content' initialValue={[{ description: null, upload: null }]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (

                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'description']}
                                            label={!name && selectedLanguage?.txt_Description}
                                        >
                                            <Input.TextArea style={{ /* width: 290, maxWidth:'95%' */ }} autoSize={{ minRows: 2 }} placeholder={'Description 1'} />
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            label={!name && selectedLanguage?.txt_addimage}
                                            name={[name, 'upload']}
                                            valuePropName='fileList'
                                            getValueFromEvent={normFile}
                                        >
                                            <Upload
                                                multiple={true}
                                                accept='image/*'
                                                name='logo'
                                                action='/upload.do'
                                                listType='picture'
                                                beforeUpload={() => false}
                                            >
                                                <Button icon={<UploadOutlined />}>{selectedLanguage?.txt_Clicktoupload}</Button>
                                            </Upload>
                                        </Form.Item>
                                        {fields.length > 1 && <span style={fields.length > 1 && name && { marginTop: -25, position: 'absolute' } || {}}><MinusCircleOutlined onClick={() => remove(name)} /></span> || null}
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                        {selectedLanguage?.txt_AddFiled}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    {/* <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={loading}
                            onClick={() => setMore(true)}
                        >
                            {'Submit More'}
                        </Button>
                    </Form.Item> */}
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.btn_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </Modal >
    )
}
export default AdminAddCards