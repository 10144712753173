// import * as React from 'react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import { Box, Button, Divider, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { allPaths } from '../../utils/constants'
import Person from '@mui/icons-material/Person'
import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import Stack from '@mui/material/Stack'
import { successNotification } from '../../utils/helpers'
import axios from 'axios'
import { GET } from '../../utils/apis'
import { Link } from 'react-router-dom'
import Reviews from '@mui/icons-material/Reviews';
import ReactFlagsSelect from 'react-flags-select';
import { setLanguage } from '../../Redux/actions/languageAction'
import { BiMenuAltLeft } from "react-icons/bi"
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import MainLogo from "../../assets/testallyLogo-removebg.png"
const HeaderUpdateMobile = (props) => {
    // console.log('HEADER IS WORKING', updateWallet)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorEl2, setAnchorEl2] = React.useState(null)
    const open = Boolean(anchorEl)
    const open2 = Boolean(anchorEl2)
    const { authActions, history, user, userActions, language, selectedLanguage, MenuNone } = props
    const [auth, setAuth] = useState(true)
    const [walletAmount, setWalletAmount] = useState([])
    const [isAmount, setIsAmount] = useState(false)
    const [selected, setSelected] = useState('GB');
    const dispatch = useDispatch()
    useEffect(() => {
        language?.language === 'english' ?
            setSelected('GB') :
            setSelected('NL')
    })


    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setAnchorEl2(null)
    }

   
    const Review = () => {
        handleClose()
        history?.push(allPaths.BU_REVIEW)
    }

    const logout = () => {
        setAnchorEl(null)
        setAnchorEl2(null)
        authActions?.removeUser()
    }

    return (
        <>
            <AppBar
                className='mobileDiv'
                style={{
                    background: 'white', color: 'black', height: '100%',
                    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                }}
                position='static'>
                <Toolbar>
                    {MenuNone &&
                        <IconButton
                            sx={{
                                color: 'white',
                                bgcolor: '#ec671b',
                                borderRadius: 2,
                                '&.MuiButtonBase-root:hover': {
                                    color: 'white',
                                    bgcolor: '#ec671b',
                                    borderRadius: 2
                                }
                            }}
                            onClick={() => {
                                dispatch(SetMenuNone(false))
                            }}
                        >
                            <BiMenuAltLeft style={{ fontSize: 26 }} />
                        </IconButton>}
                    <Typography
                        variant='h6'
                        component='div'
                        className='mui-size'
                        sx={{ flexGrow: 1 }}
                    >
                        &nbsp;
                        <span style={{ display: 'flex' }}>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '1.2em',
                                    height: '100%',
                                    textAlign: 'center',
                                    paddingTop: '12px',
                                    width: '100%',
                                    marginLeft: !MenuNone ? '42px' : '0px',
                                    marginBottom: '15px'
                                }}>
                                <img src={MainLogo} style={{  width: '200px' }} />
                            </span>
                        </span>
                        &nbsp;
                    </Typography>

                    {auth && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Stack direction="row" spacing={4}>
                                <span style={{ display: 'flex' }}>
                                    <span className='countries-select-update-span' style={{
                                        cursor: 'pointer',
                                        fontSize: '1.2em',
                                        marginLeft: 15,
                                    }}>
                                        <ReactFlagsSelect
                                            className='countries-select-update'
                                            fullWidth={false}
                                            showOptionLabel={false}
                                            showSelectedLabel={false}
                                            countries={["GB", "NL"]}
                                            selected={selected}
                                            onSelect={(v) => onchangeLanguage(v)}
                                        />
                                    </span>
                                </span>
                            </Stack>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </>
    )
}

export default HeaderUpdateMobile