import React from 'react'
import Nodataimage from '../../../src/assets/NewImages/Nodata.gif'

const NoDataComponent = () => {
    return (
        <div className='nodata-lottie-div'>
            <img src={Nodataimage} className='nodata-lottie' />
        </div>
    )

}
export default NoDataComponent