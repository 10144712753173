import { Button, Skeleton, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Page404 } from '../../Config/routes'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import logo from '../../assets/testallyLogo-removebg.png'
import NewFormTest from './NewFormTest'
import { toBase64 } from '../../utils/helpers'

const NewQuestionForm = (props) => {
    const { location } = props
    const language = useSelector(state => state?.languageReducer)
    const user = useSelector(state => state.authReducer.user)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const [pageLoading, setPageLoading] = useState(false)
    const [showQuestion, setShowQuestion] = useState(false)
    const [formData, setFormData] = useState(location?.state?.formData)

    useEffect(() => {
        preloadImages()
    }, [])
    const preloadImages = async () => {
        setPageLoading(true)

        let questions = JSON.parse(JSON.stringify(location?.state?.formData?.questions))
        for (let v of questions) {
            let dragFile = v?.dragFile
            let answerList = v?.answerList
            if (dragFile?.url) {
                dragFile.baseUrl = await toBase64(dragFile?.url)
            }
            if (v?.file?.url) {
                v.file.baseUrl = await toBase64(v?.file?.url)
            }
            for (let x of answerList) {
                if (x?.file?.url) {
                    x.file.baseUrl = await toBase64(x?.file?.url)
                }
            }

            v.chapter = v?.chapter?.chapterName
            v.subject = v?.subject?.subjectName
            v.paragraph = v?.paragraph?.paragraphName
            v.point = 0
            v.totalPoint = location?.state?.formData?.questionPoints?.find((x) => x?._id === v?._id)?.point
            v.answerGiven = null
            v.correct = false
            v.marked = false
        }
        setFormData(state => {
            return {
                ...state,
                questions
            }
        })
        setPageLoading(false)
    }

    return (
        <div className='inner-main-div'>
            {showQuestion ?
                <NewFormTest
                    handleSuccess={() => setShowQuestion(false)}
                    selectedLanguage={selectedLanguage}
                    formData={formData}
                    language={language}
                    user={user}
                    {...props} />
                :
                <>
                    <div className='header-with-text'>
                        <div>
                            <img src={logo} style={{ height: '30px' }} />
                        </div>
                    </div>
                    {false ?
                        <div style={{ margin: '10px' }}>
                            <Skeleton paragraph={{ rows: 5 }} active loading />
                        </div> :
                        location?.state?.formData ?
                            <div>
                                <div className='main-scroll-div student-part-scroll_class'
                                    style={{ padding: '5px', paddingTop: '15px' }}>
                                    <p style={{ fontSize: 24, fontWeight: 'bolder', margin: '0px' }}>{location?.state?.formData?.title ? location?.state?.formData?.title : "Lorem ipsum dolor sit amet consectetur...."}</p>
                                    <div style={{ borderBottom: '1px solid grey', marginTop: '10px', marginBottom: '10px' }} />
                                    <p>{location?.state?.formData?.description ?
                                        location?.state?.formData?.description :
                                        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit, quidem temporibus modi dolorem excepturi nihil perspiciatis similique  possimus quos alias ab nemo nesciunt odit ducimus repellendus! Repellendus quibusdam aut nisi."}
                                    </p>

                                    <div style={{
                                        padding: '8px',
                                        justifyContent: 'flex-end',
                                        display: 'flex',
                                        background: '#A4C8CE',
                                        position: 'absolute',
                                        bottom: '0pc',
                                        width: '99%',
                                        zIndex: 9999999999
                                    }}>
                                        <Tooltip placement='top' title={pageLoading && 'Please Wait Fetching Exam'}>
                                            <Button loading={pageLoading} onClick={() => setShowQuestion(true)} type='primary'>Continue</Button>
                                        </Tooltip>

                                    </div>
                                </div>

                            </div> :
                            <div>
                                <Page404 />
                            </div>}
                </>
            }
        </div>
    )
}

export default NewQuestionForm