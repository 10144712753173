// import * as React from 'react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { drawerGraphicDesignerRoutes, drawerGuestRoutes, drawerRoutes } from '../../utils/constants'
import Stack from '@mui/material/Stack'
import { convertNumber, setKeyMenu, successNotification } from '../../utils/helpers'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Reviews from '@mui/icons-material/Reviews';
import ReactFlagsSelect from 'react-flags-select';
import { setLanguage } from '../../Redux/actions/languageAction'
import { BiMenuAltLeft } from "react-icons/bi"
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import { Tooltip } from 'antd'
import testallyLogo from "../../assets/testallyLogo.png"
import { Layout, Menu } from 'antd'


const HeaderUpdateAppBar = (props) => {
    // console.log('HEADER IS WORKING', updateWallet)
    const { SubMenu } = Menu
    const { authActions, history, user, userActions, language, selectedLanguage, MenuNone } = props
    const [auth, setAuth] = useState(true)
    const [walletAmount, setWalletAmount] = useState([])
    const [businessRoutes, setbusinessRoutes] = useState(drawerRoutes || [])
    const [isAmount, setIsAmount] = useState(false)
    const [selected, setSelected] = useState('GB');
    const [key, setKey] = useState(1)
    const dispatch = useDispatch()
    const isGuest = user?.userType === 'guest'
    const isDesigner = user?.ownerType === 'designer'
    const isNlSelect = language?.language === 'netherlands'
    useEffect(() => {
        language?.language === 'english' ?
            setSelected('GB') :
            setSelected('NL')
    })


    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }
    const handleClick = (e) => {
        setKey(parseInt(e?.key))
    }
    return (
        <>
            <AppBar
                className='laptopDiv'
                style={{ background: 'white', color: 'black', height: '100%', }}
                position='static'>
                <Toolbar>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        {MenuNone ?
                            <IconButton
                                sx={{
                                    color: 'white',
                                    bgcolor: '#ec671b',
                                    borderRadius: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    '&.MuiButtonBase-root:hover': {
                                        color: 'white',
                                        bgcolor: '#ec671b',
                                        borderRadius: 2
                                    }
                                }}
                                onClick={() => { dispatch(SetMenuNone(false)) }}
                            >
                                <BiMenuAltLeft style={{ fontSize: 26 }} />
                            </IconButton> :
                            window.innerWidth > 700 ?
                                <img
                                    className='deck-header-logo'
                                    src={testallyLogo} alt='SidebarLogo' /> : null
                        }


                        {auth && (
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '-15px' }}>
                                <Stack direction="row" spacing={4}>

                                    <span style={{ display: 'flex' }}>
                                        <Menu
                                            onClick={handleClick}
                                            defaultOpenKeys={[`${setKeyMenu(window?.location?.pathname)}`]}
                                            defaultSelectedKeys={[`${window?.location?.pathname}`]}
                                            mode='horizontal'
                                            style={{ marginBottom: '3px' }}
                                        >
                                            <>
                                                {(isDesigner ? drawerGraphicDesignerRoutes : isGuest ? drawerGuestRoutes : businessRoutes)?.map((v, i) => {
                                                    if (v?.isSubMenu && v?.children?.length) {
                                                        return (
                                                            <SubMenu key={v?.route}
                                                                icon={v?.icon}
                                                                title={window.innerWidth > 1000 ? isNlSelect ? v.titleNl : v.title : null}
                                                                style={{ fontSize: 18 }}>
                                                                {v?.children?.map((y, j) => {
                                                                    return (
                                                                        <Menu.Item key={y?.route} icon={y.icon}>
                                                                            <Link
                                                                                to={y?.route}
                                                                            >
                                                                                {isNlSelect ? y?.titleNl : y?.title}
                                                                            </Link>
                                                                        </Menu.Item>
                                                                    )
                                                                })}

                                                            </SubMenu>

                                                        )
                                                    }
                                                    return (
                                                        <Menu.Item key={v.route} icon={v?.icon}>
                                                            <Link
                                                                to={v?.route}
                                                            >
                                                                <Tooltip title={isNlSelect ? v.titleNl : v.title}>
                                                                    {window.innerWidth > 1000 ? isNlSelect ? v.titleNl : v.title : null}
                                                                </Tooltip>
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                })}
                                            </>
                                        </Menu>


                                        <span className='countries-select-update-span' style={{
                                            cursor: 'pointer',
                                            fontSize: '1.2em',
                                            marginLeft: 15,
                                        }}>
                                            <ReactFlagsSelect
                                                className='countries-select-update'
                                                fullWidth={false}
                                                showOptionLabel={false}
                                                showSelectedLabel={false}
                                                countries={["GB", "NL"]}
                                                selected={selected}
                                                onSelect={(v) => onchangeLanguage(v)}
                                            />
                                        </span>
                                    </span>
                                </Stack>
                            </div>
                        )}
                    </div>
                </Toolbar>
            </AppBar >
        </>
    )
}

export default HeaderUpdateAppBar