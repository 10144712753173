import { Button, Form, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../utils/apis'
import { allPaths, contentType } from '../../../utils/constants'
import { checkExpiry, checkLuxurySub, checkSelectOption, checkSubscription, errorMessage, toBase64 } from '../../../utils/helpers'

const NewStudentTraning = (props) => {

    const { selectedLanguage, user, history } = props
    const { Option } = Select
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [topics, setTopics] = useState([])
    const [form] = Form.useForm()

    let subscribeTeacher = user?.subscribeTeacher.filter((v) => !checkExpiry(v?.expiry))

    useEffect(() => {
        if (!checkLuxurySub(user?.subscription)) {
            history.push(allPaths.GUEST_HOME)
            return errorMessage(selectedLanguage?.txt_PleaseBuysubscriptionforthisFeature)
        } else {
            getTopics()
        }
    }, [])

    const getTopics = () => {

        let obj = {
            teachers: user?.subscribeTeacher?.map((v) => v?.teacher?._id)
        }

        axios.post(`${POST.GET_TRAINING_TOPICS}`, obj)
            .then((res) => {
                const { data } = res
                console.log('data-->', data)
                if (data?.success) {
                    setTopics(data?.data)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const onCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    const onFinish = (values) => {
        values.student = user?._id
        setLoading(true)
        axios.post(`${POST.GET_TRAINING_QUESTIONS}`, values)
            .then(async (res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    // successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    console.log('data?.data', data?.data)
                    if (data?.data?.length) {
                        let newQuestions = JSON.parse(JSON.stringify(data?.data))

                        for (let v of newQuestions) {
                            let dragFile = v?.dragFile
                            let answerList = v?.answerList
                            if (dragFile?.url) {
                                dragFile.baseUrl = await toBase64(dragFile?.url)
                            }
                            if (v?.file?.url) {
                                v.file.baseUrl = await toBase64(v?.file?.url)
                            }
                            for (let x of answerList) {
                                if (x?.file?.url) {
                                    x.file.baseUrl = await toBase64(x?.file?.url)
                                }
                            }
                        }
                        history.push({
                            pathname: allPaths?.TOPIC_TRAINING,
                            state: {
                                trainQuestions: newQuestions,
                                topics: form.getFieldValue('topics')
                            }
                        })
                        form.resetFields()
                        onCancel()
                    } else {
                        errorMessage(selectedLanguage?.txt_NoQuestionsavailable)
                    }
                }
            })
            .catch((e) => {
                console.log('error', e);
                setLoading(false)
                // errorMessage()
            })
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote8}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Training}</h3>

                <span className='button-end-div-with-media' style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button
                        size='large'
                        type='primary'
                        onClick={() => setVisible(true)} >
                        {selectedLanguage?.btn_Train}
                    </Button>
                </span>

                <div className='three-boxes-card-div'>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Dashboard}</p>
                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Dashboard}
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Exams}</p>

                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Exams}
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Result}</p>

                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Result}
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Dashboard}</p>
                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Dashboard}
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <Modal
                    className='pop-info-modal'
                    style={{ top: '10px' }}
                    open={visible}
                    footer={null}
                    onCancel={onCancel}>
                    <p className='new-p-question'>{selectedLanguage?.txt_TraningForm}</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        name='Form'
                        layout='vertical'
                    >
                        <Form.Item
                            name='topics'
                            label={selectedLanguage?.txt_SelectTopic}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_PleaseSelectTopic
                                }
                            ]}
                        >
                            <Select
                                className='form-input-new-style-filter-select'
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                                mode='multiple'
                                placeholder={selectedLanguage?.txt_SelectTopicHere}>
                                {
                                    topics.map((v, i) => {
                                        return (
                                            <Option key={i} value={v}>{v}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='teachers'
                            label={selectedLanguage?.txt_SelectTeacher}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_PleaseSelectTeacher
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                                mode='multiple'
                                placeholder={selectedLanguage?.txt_SelectTeacherhere}
                                className='form-input-new-style-filter-select'
                            >
                                {
                                    subscribeTeacher.map((v, i) => {
                                        return (
                                            <Option key={i} value={v?.teacher?._id}>{v?.teacher?.fullName}</Option>
                                        )
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Button
                                type='primary'
                                size='large'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.txt_StartExam}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}
export default NewStudentTraning