import { MinusCircleOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Form, Input, InputNumber, Radio, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PreviewStudentQuestion } from '../../../Components'
import { ADMIN } from '../../../utils/apis'
import { checkSelectOption, errorMessage, generalData, successMessage } from '../../../utils/helpers'
const { Option } = Select

const AddJsonquestion = (props) => {
    const { onCancel, user, copyQuestion, setCopyQuestion, openQuestions, selectedLanguage, lanKorCondition, generalActions } = props
    const tutions = useSelector(state => state?.generalReducer?.tutions || [])
    const chapters = useSelector(state => state?.generalReducer?.chapters || [])
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const languages = useSelector(state => state?.generalReducer?.adminLanguages || [])

    const [form] = Form.useForm()
    const [ansType, setAnsType] = useState(copyQuestion?.answerType || 'input')
    const [loading, setLoading] = useState(false)
    const [allChapters, setAllChapters] = useState(chapters || [])
    const [subjectName, setSubjectsName] = useState(copyQuestion?.subject?.subjectName || '')
    const [chapterValue, setchapterValue] = useState(copyQuestion?.chapter || {})
    const [paragraphValue, setParagraphValue] = useState(copyQuestion?.paragraph || {})
    const [fileList, setFileList] = useState(copyQuestion?.file?.url ? [copyQuestion?.file] : [])
    const [titleFileList, setTitleFileList] = useState(copyQuestion?.titleFile?.url ? [copyQuestion?.titleFile] : [])
    const [questionType, setQuestionType] = useState(copyQuestion?.questionType || 'questionnaire')
    const [another, setAnother] = useState(false)
    const [formValues, setFormValues] = useState(copyQuestion || {})
    const [previewImage, setPreviewImage] = useState(copyQuestion?.dragFile?.url || '');
    const [spots, setSpots] = useState(((copyQuestion?.answerType === 'dragDrop' || copyQuestion?.answerType === 'dragDropSequence') && copyQuestion?.answerList) || [])
    const [previewQuestion, setPreviewQuestion] = useState(false)
    useEffect(() => {
        generalData?.getAllSubjects(generalActions, user?.verify)
        generalData.getAllChapters(generalActions)
        generalData.getAllLanguages(generalActions)
    }, [])

    const getTopicId = (value) => {
        return allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)[0]?.topics?.filter((x) => x?.topicName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterId = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getParagraphId = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterName = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?.chapterName
    }
    const getParagraphName = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?.paragraphName
    }
    const onFinish = async (values) => {

        let tempArr = values?.topics?.map(element => element?.toLowerCase())
        let cond = _.uniq(tempArr)?.length !== tempArr?.length

        if (cond && !user?.userType) {
            return errorMessage(selectedLanguage?.errorMessage_Topicsmustbeunique)
        }
        setLoading(true)

        values.userId = user?._id
        values.questionType = questionType
        values.point = Number(values?.point || 0)
        values.chapter = chapterValue
        values.paragraph = paragraphValue

        if (values?.topics?.length > 0) {
            values.topic1 = { topicName: values?.topics[0], topicId: getTopicId(values?.topics[0]) || null }
        }
        if (values?.topics?.length > 1) {
            values.topic2 = { topicName: values?.topics[1], topicId: getTopicId(values?.topics[1]) || null }
        }
        if (values?.topics?.length > 2) {
            values.topic3 = { topicName: values?.topics[2], topicId: getTopicId(values?.topics[2]) || null }
        }

        // delete values?.answerList1
        // delete values?.answerList2
        // delete values?.answerList3
        // delete values?.answerList4

        if (!user?.ownerType) {
            values.isAdmin = true
        }
        console.log('values', values)

        await axios.post(ADMIN.ADD_JSON_QUESTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    setCopyQuestion({})
                    if (another) {
                        setFileList([])
                        generalData.getAllChapters(generalActions)
                    } else {
                        form.resetFields()
                        onCancel()
                    }
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)

                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

            })
    }
    const optionsTopics = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)?.map((x) => x?.topics)?.flat()?.map((z) => {
        return {
            value: z?.topicName,
            id: z?._id
        }
    })

    const optionsParagraph = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph).flat().map((x) => {
        return {
            value: x?.paragraphName,
            id: x?._id,
        }
    })
    const optionsChapter = allChapters?.map((v, i) => {
        return {
            value: v?.chapterName
        }
    })
    const onSearchChapter = (searchText) => {
        if (getChapterId(searchText)) {
            setchapterValue({ chapterName: getChapterName(searchText), chapterId: getChapterId(searchText) })
        } else {
            setchapterValue({ chapterName: searchText, chapterId: getChapterId(searchText) || null })
        }

        form.setFieldsValue({
            paragraph: null,
            topics: [null]
        })
        setParagraphValue({})
    }
    const onSelectChapter = (data) => {
        setchapterValue({
            chapterName: allChapters?.filter((v, i) => v?.chapterName === data)[0]?.chapterName,
            chapterId: allChapters?.filter((v, i) => v?.chapterName === data)[0]?._id,
        })
        form.setFieldsValue({
            paragraph: null,
            topics: [null]
        })
        setParagraphValue({})

    }
    const onSearchParagraph = (searchText) => {
        setParagraphValue({ paragraphName: searchText, paragraphId: null })
        if (getParagraphId(searchText)) {
            setParagraphValue({ paragraphName: getParagraphName(searchText), paragraphId: getParagraphId(searchText) || null })
        } else {
            setParagraphValue({ paragraphName: searchText, paragraphId: null })
        }
        form.setFieldsValue({
            topics: [null]
        })
    }

    const onSelectParagraph = (data) => {
        setParagraphValue({
            paragraphName: data,
            paragraphId: optionsParagraph?.filter((v) => v?.value === data)[0]?.id,
        })
        form.setFieldsValue({
            topics: [null]
        })

    }
    const onChangeQuestionType = (e) => {
        setQuestionType(e?.target?.value)
    }
    const onChangeSubject = (id) => {
        let subjectName = subjects?.find((v) => v?._id === id)
        setSubjectsName(subjectName?.subjectName)
        let filteredChapters = chapters?.filter((v) => v?.subject === id?.toString())
        setAllChapters(filteredChapters)
    }
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handlePreview = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        setPreviewImage(file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }
    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }

    const handleSpots = () => {
        let newArray = form.getFieldValue('spots')
        newArray = newArray.map((v, i) => {
            return {
                id: i,
                x: `${v?.x}%`,
                y: `${v?.y}%`,
                correct: v?.correct
            }
        })
        setSpots(newArray)
    }
    console.log('copyQuestion', copyQuestion)

    const quesData = () => {
        let allData = form.getFieldsValue()

        allData.spots = allData?.spots?.map((v, i) => {
            return {
                id: i,
                x: v?.x + '%',
                y: v?.y + '%',
                correct: v?.correct
            }
        })

        allData.titleFile = titleFileList?.length ? (titleFileList?.[0]?.preview || titleFileList?.[0]?.url) : null
        allData.file = fileList?.length ? (fileList?.[0]?.preview || fileList?.[0]?.url) : null
        allData.answerType = ansType

        allData.answerList = (allData?.answerList1?.length && allData?.answerList1) || (allData?.answerList2?.length && allData?.answerList2) || (allData?.answerList3?.length && allData?.answerList3) || (allData?.answerList4?.length && allData?.answerList4) || (allData?.answerList5?.length && allData?.answerList5)

        allData.dragFile = previewImage

        return allData
    }
    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Button
                    style={{
                        padding: '0px 20px 0px 20px',
                        background: '#1eb2a6',
                        color: 'white',
                        border: 'none',
                        marginTop: '10px',
                        borderRadius: 6
                    }}
                    type='primary'
                    onClick={() => { onCancel(); setCopyQuestion({}) }}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </span>

            <div className='crt-question-div'>
                <p className='crt-qutin-heading'>{selectedLanguage?.Button_CreateQuestion}</p>
                {
                    previewQuestion && <PreviewStudentQuestion subjectName={subjectName} onCancelPreview={() => setPreviewQuestion(false)} previewQuestion={previewQuestion} quesData={quesData} {...props} />
                }
                <Form
                    form={form}
                    onFinish={onFinish}
                    name='QuestionsForm'
                    layout='vertical'
                    requiredMark={true}
                    onValuesChange={(e) => {
                        setFormValues(form.getFieldsValue())
                    }}
                >
                    <Form.Item
                        name='point'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_Pointsarerequired,
                            }
                        ]}
                        label={selectedLanguage?.label_Points}
                        required
                        initialValue={copyQuestion?.point || 0}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder='2'
                            type='number'
                            max={1000}
                            min={0}
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        name='time'
                        label={selectedLanguage?.label_Time}
                        initialValue={copyQuestion?.time || 8}
                    >
                        <InputNumber
                            controls={false}
                            style={{ width: '100%', maxWidth: 200 }}
                            addonAfter='s'
                            placeholder={selectedLanguage?.txt_Time_inSeconds}
                            type='number'
                            max={300}
                            min={8}
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        name='questionType'
                        label={selectedLanguage?.label_QuestionType}
                        required
                        initialValue={questionType}
                    >
                        <Radio.Group disabled={copyQuestion?.questionType} value={questionType} onChange={onChangeQuestionType} defaultValue={questionType}>
                            <Radio.Button value='questionnaire'>{selectedLanguage?.Button_Questionnaire}</Radio.Button>
                            <Radio.Button value='test'>{selectedLanguage?.Button_Test}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    
                    <Form.Item
                        name='subject'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectSubject,
                            },
                        ]}
                        initialValue={copyQuestion?.subject?._id}
                    >
                        <Select
                            onChange={onChangeSubject}
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.label_SelectSubject}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {subjects.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='language'
                        label={selectedLanguage?.label_SelectLanguage}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectLanguage,
                            },
                        ]}
                        initialValue={copyQuestion?.language?._id}
                    >
                        <Select
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.label_SelectLanguage}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {languages.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.languageName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='tutions'
                        label={selectedLanguage?.label_SelectSelectTutions}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.requiredMessage_pleaseSelectTutions,
                            },
                        ]}
                        initialValue={copyQuestion?.tutions?.map((v) => v?._id?.toString()) || []}
                    >
                        <Select
                            showArrow
                            allowClear
                            mode='multiple'
                            placeholder={selectedLanguage?.label_SelectSelectTutions}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {tutions?.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.tutionName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='book'
                        label={selectedLanguage?.label_LearningBook}
                        initialValue={copyQuestion?.book}
                    >
                        <Input placeholder={'e.g. Harry potter'} />
                    </Form.Item>

                    <Form.Item
                        name='chapter'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_Pleasechapter,
                            }
                        ]}
                        required
                        label={selectedLanguage?.label_Chapters}
                        initialValue={copyQuestion?.chapter?.chapterName}
                    >
                        <AutoComplete
                            options={_.uniqBy(optionsChapter, 'value')}
                            placeholder={selectedLanguage?.placeholder_Chapters}
                            onSearch={onSearchChapter}
                            onSelect={onSelectChapter}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name='paragraph'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseParagraph,
                            }
                        ]}
                        label={selectedLanguage?.label_Paragraph}
                        required
                        initialValue={copyQuestion?.paragraph?.paragraphName}
                    >
                        <AutoComplete
                            disabled={!chapterValue?.chapterName?.length}
                            options={_.uniqBy(optionsParagraph, 'value')}
                            // options={optionsParagraph}
                            placeholder={selectedLanguage?.placeholder_Paragraph}
                            onSearch={onSearchParagraph}
                            onSelect={onSelectParagraph}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.List
                        name='topics'
                        initialValue={copyQuestion?.topics?.map((v) => v?.topicName) || ['']}

                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? selectedLanguage?.label_Topics : ''}
                                        required
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: selectedLanguage?.errorMessage_PleaseTopic,
                                                },
                                            ]}
                                            noStyle
                                        // initialValue={copyQuestion?.topics[0]?.topicName}
                                        >
                                            <AutoComplete

                                                options={_.uniqBy(optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value)), 'value')}
                                                // options={optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value))}
                                                placeholder={selectedLanguage?.placeholder_Topic}
                                                disabled={!paragraphValue?.paragraphName?.length}
                                                style={{
                                                    width: fields.length > 1 ? '93%' : '100%',
                                                    marginRight: fields.length > 1 ? '1%' : 0
                                                }}
                                                filterOption={(inputValue, option) =>
                                                    option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className='dynamic-delete-button'
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type='dashed'
                                        onClick={() => add()}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={!paragraphValue?.paragraphName?.length && fields?.length > 2}

                                    >
                                        {selectedLanguage?.button_AddTopic} ({fields?.length}/3)
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <br />
                    <Form.Item
                        name='jsonQuestion'
                        label={selectedLanguage?.txt_Enter_ofquestion}
                    // initialValue={copyQuestion?.book}
                    >
                        <Input placeholder={selectedLanguage?.txtr_EnterJSONofquestionhere} />
                    </Form.Item>
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={!another && loading}
                            onClick={() => setAnother(false)}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </>
    )
}

export default AddJsonquestion