import { Button } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../utils/apis'
import { allPaths, contentType } from '../../../utils/constants'
import { checkExpiry, checkLuxurySub, checkSubscription, errorMessage, toBase64 } from '../../../utils/helpers'

const Test10 = (props) => {

    const { selectedLanguage, user, history } = props
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!checkLuxurySub(user?.subscription)) {
            history.push(allPaths.GUEST_HOME)
            return errorMessage(selectedLanguage?.txt_PleaseBuysubscriptionforthisFeature)
        }
    }, [])


    let subscribeTeacher = user?.subscribeTeacher.filter((v) => !checkExpiry(v?.expiry))

    const onFinish = () => {
        let obj = {
            student: user?._id,
            teachers: subscribeTeacher?.map((v) => v?._id)
        }
        setLoading(true)
        axios.post(`${POST.GET_TEST10_QUESTIONS}`, obj)
            .then(async (res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    // successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    if (data?.data?.length) {
                        let newQuestions = JSON.parse(JSON.stringify(data?.data))

                        for (let v of newQuestions) {
                            let dragFile = v?.dragFile
                            let answerList = v?.answerList
                            if (dragFile?.url) {
                                dragFile.baseUrl = await toBase64(dragFile?.url)
                            }
                            if (v?.file?.url) {
                                v.file.baseUrl = await toBase64(v?.file?.url)
                            }
                            for (let x of answerList) {
                                if (x?.file?.url) {
                                    x.file.baseUrl = await toBase64(x?.file?.url)
                                }
                            }
                        }
                        history.push({
                            pathname: allPaths?.TOPIC_TRAINING,
                            state: {
                                trainQuestions: newQuestions,
                                test10: true
                            }
                        })
                    } else {
                        errorMessage(selectedLanguage?.txt_NoQuestionsavailable)
                    }
                }
            })
            .catch((e) => {
                console.log('error', e);
                setLoading(false)
                // errorMessage()
            })
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote8}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Test10t}</h3>

                <span className='button-end-div-with-media' style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button
                        size='large'
                        type='primary'
                        loading={loading}
                        onClick={onFinish} >
                        {selectedLanguage?.btn_Train}
                    </Button>
                </span>


                <div className='three-boxes-card-div'>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Dashboard}</p>
                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Dashboard}
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Exams}</p>

                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Exams}
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Result}</p>

                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Result}
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.txt_Dashboard}</p>
                        <p>{selectedLanguage?.txt_loremispum_full} </p>
                        <button className='ex-tion-card-button'>
                            {selectedLanguage?.txt_Dashboard}
                        </button>
                    </div>
                </div>
                <br />
            </div>
        </div>
    )
}
export default Test10