import { Tabs } from "antd";
import React from "react";
import AdminCardReview from "./AdminCardReview";
import AdminVideoReview from "./AdminVideoReview";
import AdminExamReview from "./AdminExamReview";



const { TabPane } = Tabs
const AdminMainReviewScreen = (props) => {
    const { selectedLanguage } = props
    return (

        <div >
            <Tabs className='new-new-my-tabs' defaultActiveKey='1' centered>
                <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_CardsReview}</p>} key='1'>
                    <AdminCardReview  {...props} />
                </TabPane>
                <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_VideosReview}</p>} key='2'>
                    <AdminVideoReview  {...props} />
                </TabPane>
                <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_ExamsReview}</p>} key='3'>
                    <AdminExamReview  {...props} />
                </TabPane>
            </Tabs>
        </div>
    )
}


export default AdminMainReviewScreen