import React from "react";
import ReactFlagsSelect from 'react-flags-select';
import { setLanguage } from '../../Redux/actions/languageAction'
import { useDispatch, useSelector } from 'react-redux'

const LoginLanguageSelect = ({ color, bg }) => {
    const [selected, setSelected] = React.useState('GB');
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)

    React.useEffect(() => {
        language?.language === 'english' ?
            setSelected('GB') :
            setSelected('NL')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }

    return (
        <div
            style={{
                height: 50,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: ' center',
                paddingRight: '1%',
                backgroundColor: bg ? 'transparent' : color ? '#A4C8CE' : '#FBFBFB'
            }}>
            <ReactFlagsSelect
                className={color ? 'new--countries-select' : 'countries-select'}
                fullWidth={false}
                showOptionLabel={false}
                showSelectedLabel={false}
                countries={["GB", "NL"]}
                selected={selected}
                onSelect={(v) => onchangeLanguage(v)}
            />
        </div >
    )
}
export default LoginLanguageSelect