import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { POST } from '../../../utils/apis'
import { convertNumber, errorMessage, studentData, successNotification } from '../../../utils/helpers'

const StudentReviewForm = (props) => {
    const { user, userActions, data, onCancel, selectedLanguage, lanKorCondition, type } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const userId = user?._id
    const _id = data?._id
    const { TextArea } = Input


    const onFinish = (values) => {
        let overall = +values?.overall
        let images = +values?.images
        let languageUse = +values?.languageUse
        let detailed = +values?.detailed
        let clarity = +values?.clarity
        let relevance = +values?.relevance
        let avrageReview = overall + images + languageUse + detailed + clarity + relevance
        values.userId = userId
        type === 'card' && (values.cardId = _id)
        type === 'video' && (values.videoId = _id)
        type === 'exam' && (values.examId = _id)
        values.type = type
        values.average = convertNumber(avrageReview / 6)
        !data?.admin && data?.user?._id && (values.teacherId = data?.user?._id)


        setLoading(true)

        axios.post(POST?.ADD_REVIEW, values)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successNotification(selectedLanguage?.ReviewSuccessfullySubmitted)
                    form.resetFields()
                    await studentData.getStudentVideos(userActions, user?._id)
                    await studentData.getStudentCards(userActions, user?._id)
                    await studentData.getStudentExams(userActions, user?._id)
                    onCancel()
                }
                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    return (
        <div>
            <h3 className='new-exam-title'>{selectedLanguage?.WriteAReview}</h3>
            <p style={{ fontWeight: 600, color: 'rgb(30, 178, 166)', marginLeft: '16px' }}>{selectedLanguage?.WeAimFor100RealReview}</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '99.5%' }}>
                <Button type="primary" onClick={onCancel}>{selectedLanguage?.txt_Cancel}</Button>
            </div>
            <div style={{ padding: '10px' }}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    form={form}
                    layout={'vertical'}
                    requiredMark={false}
                >

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='relevance'
                            label={selectedLanguage?.ttx_Relevance}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Please_input_your_relevance_review
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input-new-style'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.txt_Input_your_relevance_review_here}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-input-single-input'>
                        <Form.Item
                            name='clarity'
                            label={selectedLanguage?.ttx_Clarity}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Please_input_your_Clarity_review
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input-new-style'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.cxt_Input_your_Clarity_review_here}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='detailed'
                            label={selectedLanguage?.ttx_Detailed}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Please_input_your_Detailed_review
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input-new-style'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.txt_Input_your_Detailed_review_here}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='languageUse'
                            label={selectedLanguage?.ttx_Languageuse}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Please_input_your_Languagese_review
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input-new-style'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.txt_Input_your_Languageuse_review_here}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='images'
                            label={selectedLanguage?.ttx_Images}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Please_input_your_Images_review
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input-new-style'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.txt_Input_your_Images_review_here}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='overall'
                            label={selectedLanguage?.ttx_Overall}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Please_input_your_Overall_review
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input-new-style'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.txt_Input_your_Overall_review_here}
                            />
                        </Form.Item>
                    </div>

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='comment'
                            label={selectedLanguage?.ReviewMessage}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourReviewMessage
                                }
                            ]}
                        >
                            <TextArea
                                className='form-input-new-style'
                                showCount
                                maxLength={250}
                                style={{ height: 80 }}
                                placeholder={selectedLanguage?.InputYourReviewMessageHere}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '100%', marginTop: '10px' }}
                            htmlType='submit'
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.SubmitYourReview}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default StudentReviewForm