import { Modal } from 'antd';
import React from 'react';
import Nodataimage from '../../../../src/assets/NewImages/Nodata.gif'

const PreviewCardModal = (props) => {
    const { data, selectedLanguage, user, onCancel } = props
    return (
        <Modal
            className='pop-info-modal'
            style={{ top: '10px' }}
            footer={null}
            open={data?._id}
            onCancel={onCancel}
        >
            <div className='preview-box-height' style={{ background: 'rgb(30, 178, 166)', padding: '25px 4px 4px', borderRadius: '12px' }}>
                <>
                    <p className='div-tt' style={{ textAlign: 'center' }}>{data?.title}</p>
                    <div
                        className='new-layout-scroll-class new-height-of-preview'
                        style={{
                            height: '280px',
                            overflowY: 'auto',
                            marginTop: '5px'
                        }}>
                        {data?.content?.length && (data?.content?.[0]?.files?.length || data?.content?.[0]?.description) ?
                            data?.content?.map((y, ii) => {
                                return (
                                    <>
                                        {y?.description?.length ? <p key={ii} className='disc'>{y?.description}</p> : null}
                                        {y?.files?.length ?
                                            y?.files?.map((data, index) => {
                                                return (
                                                    <>
                                                        {data?.perUrl?.length ?
                                                            <img key={index} className='tr-content-img' src={data?.url} /> :
                                                            null
                                                        }
                                                    </>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </>
                                )
                            }) :
                            <center>
                                <img
                                    src={Nodataimage}
                                    style={{
                                        height: '200px',
                                        borderRadius: '10px',
                                        margin: '10px auto'
                                    }}
                                    className='nodata-lottie' />
                                <p className='margin0' style={{ textAlign: 'center' }}>{selectedLanguage?.txt_ContentNotAdded}</p>
                            </center>
                        }
                    </div>
                </>
            </div>
        </Modal>
    )
}
export default PreviewCardModal