import React, { useState } from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import axios from 'axios'
import { ADMIN } from './../../../../utils/apis'
import { errorMessage, getAdminFaq, successMessage, successNotification } from '../../../../utils/helpers'

const { TextArea } = Input;
const { Option } = Select

const AddAdminFaq = (props) => {
    const { selectedLanguage, user, language, edit, onCancel, adminActions } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState(edit?._id ? edit?.type : 'How it works?')

    const onFinish = (values) => {
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id
        setLoading(true)
        axios.post(edit?._id ? ADMIN.EDIT_FAQ_QUESTION : ADMIN.ADD_NEW_FAQ, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getAdminFaq(adminActions)

                    edit?._id && (onCancel())
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            }
            )
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }


    return (
        <>
            <div className='crt-question-div'>
                <p className='admin-home-title'>{edit?._id ? selectedLanguage?.txt_UpdateFaq : selectedLanguage?.txt_CreateFaq}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <Form.Item
                        name='type'
                        label={selectedLanguage?.txt_SelectType}
                        initialValue={type}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourTypeName
                            }
                        ]}
                    >
                        <Select
                            defaultValue={type}
                            onChange={(e) => { setType(e) }}
                            style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                            className='form-input-new-style '
                            placeholder={selectedLanguage?.txt_SelectType}
                        >
                            <Option value={'How it works?'}>{selectedLanguage?.txthow_its_work}</Option>
                            <Option value={"FAQ's"}>{selectedLanguage?.txtxFAQ}</Option>
                        </Select>
                    </Form.Item>

                    {type === "FAQ's" &&
                        <>
                            <Form.Item
                                name='titleEng'
                                initialValue={edit?.titleEng}
                                label={selectedLanguage?.txt_TitleinEnglish}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseEnterTitleInEnglish
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_EnterTitleinenglishhere}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={edit?.titleNl}
                                name='titleNl'
                                label={selectedLanguage?.txt_TitleinDutch}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseEnterTitleInDutch
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_EnterTitleindutchhere}
                                />
                            </Form.Item>
                        </>}
                    <Form.Item
                        name='catagrie'
                        initialValue={edit?.catagrie}
                        label={selectedLanguage?.SelectCatagrie}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectCatagrie
                            }
                        ]}
                    >
                        <Select
                            style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                            className='form-input-new-style-select-minhi'
                            mode="multiple"
                            placeholder={selectedLanguage?.SelectCatagriehere}
                        >
                            <Option value={"guest"}>{selectedLanguage?.txt_Student}</Option>
                            <Option value={'teacher'}>{selectedLanguage?.txt_Teacher}</Option>
                            <Option value={"companyowner"}>{selectedLanguage?.txt_Learningcompany}</Option>
                            <Option value={"cbrStudent"}>{selectedLanguage?.txt_CBR_STUDENT}</Option>
                            <Option value={"cbrTeacher"}>{selectedLanguage?.txt_CBR_TEACHER}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='questionEng'
                        initialValue={edit?.questionEng}
                        label={selectedLanguage?.txt_QuestioninEnglish}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_please_enter_Question_in_English
                            }
                        ]}
                    >
                        <Input
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.txt_Enter_Question_in_English_here}
                        />
                    </Form.Item>
                    <Form.Item
                        name='questionNl'
                        initialValue={edit?.questionNl}
                        label={selectedLanguage?.txt_Question_in_Dutch}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_please_enter_Question_in_Dutch
                            }
                        ]}
                    >
                        <Input
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.txt_Enter_Question_in_Dutch_here}
                        />
                    </Form.Item>

                    <Form.Item
                        name='answerEng'
                        initialValue={edit?.answerEng}
                        label={selectedLanguage?.txt_Answer_in_English}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Please_Enter_Answer_in_English
                            }
                        ]}
                    >
                        <TextArea
                            rows={4}
                            placeholder={selectedLanguage?.txt_EnterAnswerinEnglishhere}
                        />
                    </Form.Item>
                    <Form.Item
                        name='answerNl'
                        initialValue={edit?.answerNl}
                        label={selectedLanguage?.txt_Answer_in_Dutch}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Please_Enter_Answer_in_Ducth

                            }
                        ]}
                    >
                        <TextArea
                            rows={4}
                            placeholder={selectedLanguage?.txt_EnterAnswerinDucthhere}
                        />
                    </Form.Item>


                    <Form.Item
                        name='priority'
                        initialValue={edit?.priority}
                        hasFeedback
                        label={<p className='p-admin-title-text'>{selectedLanguage?.txt_priority}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_pleaseenterPriority
                            }
                        ]}
                    >
                        <InputNumber
                            step={1}
                            style={{ width: '100%' }}
                            min={1}
                            max={99999999999}
                            // className='form-input-new-style '
                            placeholder={selectedLanguage?.txt_Enterpriorityhere}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                          style={{ float: 'right', width: '120px' }}
                          loading={loading}
                          htmlType='submit'
                          type='primary'
                          size='large' 
                        >
                            {edit?._id ? selectedLanguage?.txt_Update : selectedLanguage?.Create}
                        </Button>
                        {edit?._id &&
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                {selectedLanguage?.txt_cancel}
                            </Button>}
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default AddAdminFaq                                              