import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { POST } from '../../utils/apis';
import { errorMessage, successMessage } from '../../utils/helpers';

const AddWork = (props) => {
    const { showUpload, onCancel, designerActions, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        setLoading(true)
        let edited = []
        let formData = new FormData()
        for (let v of values?.upload) {
            if (Boolean(v?.originFileObj)) {
                formData.append('edited', v?.originFileObj)
            } else {
                edited.push(v)
            }
        }
        formData.append('unEdited', JSON.stringify(edited))
        formData.append('_id', showUpload?._id)
        axios.post(POST?.ADD_DESIGNER_IMAGES, formData)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage('Successfully Updated')
                    designerActions.addAdminImages(data?.data || [])
                    setLoading(false)
                    // adminData.getAdminImages(designerActions)
                    onCancel()
                } else {
                    setLoading(false)
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)


            })
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e?.fileList
    }
    return (
        <Modal
            open={showUpload?._id}
            footer={false}
            onCancel={onCancel}>
            <div>
                <h3 style={{ textAlign: 'center' }} >Upload Files</h3>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                    requiredMark={true}
                >
                    <Form.Item
                        name={'upload'}
                        label='Upload'
                        valuePropName='fileList'
                        getValueFromEvent={normFile}
                        extra='Add Image and .ai Files only'
                        initialValue={showUpload?.threeDFiles}
                    >
                        <Upload
                            multiple={true}
                            accept='image/*, .ai'
                            name='logo'
                            action='/upload.do'
                            listType='picture'
                            beforeUpload={() => false}
                        >
                            <Button type='primary' icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={loading}
                        >
                            {'Submit'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    )
}
export default AddWork