import React, { useEffect, useState } from 'react';
import { adminData, checkSelectOption, convertTitle, createBuOrderReport, createGiftCard, errorMessage, successNotification } from '../../../utils/helpers';
import { Button, Modal, Select, Spin, Table } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ADMIN } from '../../../utils/apis';
import moment from 'moment';
import HTML2Canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import { CheckCircleFilled } from '@ant-design/icons';
import NewViewCard from '../../../NewScreen/Cards/ViewCard';


const { Option } = Select
const TeachersCoupon = (props) => {
    const { selectedLanguage, adminActions, user, isEnglish, lanKorCondition, onCancel } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []
    const allTeachers = buUsers.filter((v) => v?.ownerType !== 'companyowner')
    const [selectedUser, setSelectedUser] = useState(allTeachers?.[0]?._id || null)
    const [selectedApplication, setSelectedApplication] = useState(true)
    const [loading, setLoading] = useState(false)
    const [teachersCoupon, setTeacherCoupon] = useState({})
    const [spin, setSpin] = useState({})
    const [allcoupons, setAllcoupons] = useState([])
    const [innerCoupons, setInnerCoupons] = useState([])
    const [printSpin, setPrintSpin] = useState({})
    const [couponCode, setCouponCode] = useState('')
    const [visible, setVisible] = useState(false)
    const [download, setDownload] = useState(false)
    const [imgUrl, setImgUrl] = useState('')

    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

    useEffect(() => {
        if (download) {
            androidDownloadQR()
        }
    }, [download])

    const disableModal = () => {
        setVisible(false)
        setCouponCode('')
    }

    const androidDownloadQR = async () => {
        let id = download ? 'qrCodeDownload' : 'qrCode'
        const canvas = document.getElementById(id)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                const a = document.createElement('a')
                a.href = pngUrl
                a.download = 'coupon-code.png'

                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)

                setDownload(false)
            })
            .catch((e) => {
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }

    const gotoNewTable = (e) => {
        setInnerCoupons(e)
    }

    const changePrintStatus = (_id) => {
        setPrintSpin({ [_id]: true })
        let obj = {
            _id,
            adminId: user?._id
        }
        axios.post(ADMIN?.UPDATE_PRINT_STATUS, obj)
            .then(async (res) => {
                const { data } = res
                setPrintSpin({})
                if (data?.success) {
                    await getTeacherCoupon(selectedUser)
                    successNotification(lanKorCondition ? data?.message : data?.messageNl)
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setPrintSpin({})
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }


    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancell = () => {
        setInvoice({})
        setShowInvoice(false)
        setImgUrl('')
    }


    let innerColumns = [
        {
            title: <p className='table-title-p' style={{ width: '200px' }}>{selectedLanguage?.txt_CouponCode}</p>,
            render: (e) => {
                return (
                    <p>{e?.couponCode ? e?.couponCode : '----'}</p>
                )
            }
        }
        ,
        {
            title: <p className='table-title-p' style={{ width: '70px' }}>{selectedLanguage?.Amount}</p>,
            render: (e) => {
                return (
                    <p>{e?.money ? e?.money : '0'}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '150px' }}>{selectedLanguage?.txt_Created}</p>,
            render: (e) => {
                return (
                    <p>{e?.created ? moment(e?.created).format('YYYY-MM-DD HH:mm') : '----'}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '70px' }}>{selectedLanguage?.Claimed}</p>,
            render: (e) => {
                return (
                    <p>{e?.isClaimed ? selectedLanguage?.True : selectedLanguage?.False}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '200px' }}>{''}</p>,
            render: (e) => {
                console.log(e, '======99')
                return (
                    <span>
                        <Button type='primary' onClick={() => (setImgUrl(e?.imageUrl), setCouponCode(e?.couponCode), setVisible(true))}>{selectedLanguage?.txt_View} ➝</Button>
                        &nbsp;
                        <Button type='primary' onClick={() => (setImgUrl(e?.imageUrl), setCouponCode(e?.couponCode), setDownload(true))}>{selectedLanguage?.txt_Download} ➝</Button>
                    </span>
                )
            }
        }
    ]

    let columns = [
        {
            title: <p style={{ width: 100 }}>{selectedLanguage?.txt_Description}</p>,
            render: (e) => <p>{e?.id || '----'}</p>
        },
        {
            title: <p style={{ width: 100 }}>{selectedLanguage?.txt_Quantity}</p>,
            dataIndex: 'value',
            align: 'center',
            render: (e) => <p>{e?.length || 0}</p>
        },
        {
            title: <p style={{ width: 100 }}>{selectedLanguage?.txt_Printstatus}</p>,
            dataIndex: 'value',
            key: 'value',
            align: 'center',
            sorter: (a, b) => a?.value[0]?.orderId?.isPrint - b?.value[0]?.orderId?.isPrint,
            render: (e) => {
                return (
                    <>
                        {
                            e?.[0]?.orderId?.isPrint ?
                                <CheckCircleFilled style={{ color: 'green' }} />
                                :
                                <>
                                    {printSpin[e?.[0]?.orderId?._id] ? <Spin />
                                        :
                                        <Button onClick={() => changePrintStatus(e?.[0]?.orderId?._id)}>{selectedLanguage?.Print}</Button>
                                    }
                                </>
                        }
                    </>
                )
            },
        },
        {
            title: <p style={{ width: 170 }}>{selectedLanguage?.txt_ViewPDFreport}</p>,
            dataIndex: 'value',
            key: 'value',
            render: (e) => <Button type='primary' onClick={() => {
                createBuOrderReport(e?.[0]?.orderId, e?.[0]?.teacherId, 'view', viewInvoice)
            }}>{selectedLanguage?.txt_View} ➝</Button>
        },
        {
            title: <p style={{ width: 170 }}>{selectedLanguage?.txt_DownloadPDFreport}</p>,
            dataIndex: 'value',
            key: 'value',
            render: (e) => <Button type='primary' onClick={() => {
                createBuOrderReport(e?.[0]?.orderId, e?.[0]?.teacherId, 'download', viewInvoice)
            }}>{selectedLanguage?.txt_Download} ➝</Button>
        },
        {
            title: <p style={{ width: 100 }}>{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (
                    <>
                        {
                            spin?.[e?.id] ? <Spin /> :
                                <p
                                    onClick={() =>
                                    (
                                        setSpin({ [e?.id]: true, ...spin }),
                                        createGiftCard(e?.value, spinFunc)
                                    )
                                    }

                                    style={{
                                        cursor: 'pointer',
                                        color: '#23afe5',
                                        width: '110px'
                                    }}>{selectedLanguage?.text_ShowTotalPdfs}</p >
                        }
                    </>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '120px' }}>{ }</p>,
            render: (e) => {
                return (
                    <Button type='primary' onClick={() => gotoNewTable(e?.value)}>{selectedLanguage?.txt_Viewallcoupons} ➝</Button>
                )
            }
        },
    ]
    let cbrColumns = [
        {
            title: <p >{selectedLanguage?.txt_Description}</p>,
            render: (e) => <p>{e?.id || '----'}</p>
        },
        {
            title: <p >{selectedLanguage?.txt_Quantity}</p>,
            dataIndex: 'value',
            align: 'center',
            render: (e) => <p>{e?.length || 0}</p>
        },
        {
            title: <p >{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (
                    <>
                        {
                            spin?.[e?.id] ? <Spin /> :
                                <p
                                    onClick={() =>
                                    (
                                        setSpin({ [e?.id]: true, ...spin }),
                                        createGiftCard(e?.value, spinFunc)
                                    )
                                    }

                                    style={{
                                        cursor: 'pointer',
                                        color: '#23afe5',
                                        width: '110px'
                                    }}>{selectedLanguage?.text_ShowTotalPdfs}</p >
                        }
                    </>
                )
            }
        },
        {
            title: ' ',
            render: (e) => {
                return (
                    <Button type='primary' onClick={() => gotoNewTable(e?.value)}>{selectedLanguage?.txt_Viewallcoupons} ➝</Button>
                )
            }
        },
    ]


    const changeSelectTeacher = (e) => {
        setSelectedUser(e)
        getTeacherCoupon(e)
        setInnerCoupons([])
    }

    const getTeacherCoupon = (e) => {
        setLoading(true)
        axios?.get(`${ADMIN?.GET_ALL_COUPON_FOR_TEACHER}/${e}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setLoading(false)
                    setTeacherCoupon(data?.data || [])
                }
                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)

            })
    }

    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
        adminData.getGuestUser(adminActions, user?._id)
        getTeacherCoupon(allTeachers?.[0]?._id)
    }, [])

    useEffect(() => {
        let tableData = Object.entries(teachersCoupon || {})
        tableData = tableData?.map((v) => {
            return {
                id: v[0],
                value: v[1]
            }
        })
        setAllcoupons([...tableData])
    }, [teachersCoupon])

    return (
        <div>
            <span style={{ marginTop: '20px' }} >
                <p className='admin-home-title'>
                    {selectedLanguage?.txt_TeachersCoupons}
                </p>
            </span>
            <br />
            <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedApplication}
                    onChange={setSelectedApplication}
                    className='form-input invice-select'
                    // placeholder={selectedLanguage?.txt_SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    <Option value={true}>CBR</Option>
                    <Option value={false}>TESTALLY</Option>
                </Select>
                <br />
                <br />
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedUser}
                    onChange={(e) => changeSelectTeacher(e)}
                    // className='form-input invice-select'
                    className='form-input'
                    placeholder={selectedLanguage?.SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {allTeachers?.filter((v) => v?.isCbr === selectedApplication)?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
            </div>
            <Button
                onClick={innerCoupons?.length ? () => setInnerCoupons([]) : onCancel}
                type='primary'
                style={{ float: 'right' }}
            >{selectedLanguage?.Cancel}</Button>
            <br />
            <div style={{ marginTop: '20px' }}>
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        loading={loading}
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        dataSource={innerCoupons?.length ? innerCoupons : allcoupons}
                        columns={innerCoupons?.length ? innerColumns : (selectedApplication ? cbrColumns : columns)}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>

            {/* //////////////////////////////////// */}

            <Modal
                open={visible}
                title={selectedLanguage?.CouponCode}
                onCancel={disableModal}
                footer={[
                    <Button key='back' onClick={disableModal}>
                        {selectedLanguage?.Close}
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
                    <div id='qrCode' style={{ textAlign: 'center' }}>
                        {/* <QRCode
                            value={couponCode}
                            size={250}
                            level={'H'}
                            includeMargin={true}
                        /> */}
                        <img src={imgUrl} style={{ height: 220, width: 220 }} />
                        <hr />
                        <p>{couponCode}</p>
                    </div>
                    <a onClick={androidDownloadQR}> {selectedLanguage?.Download} </a>
                </div>
            </Modal>

            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div
                    style={{ textAlign: 'center' }}
                    id='qrCodeDownload'
                    className='fixed-qr'
                >
                    <img src={imgUrl} style={{ height: 220, width: 220 }} />
                    <hr />
                    {/* <QRCode
                        value={couponCode}
                        size={250}
                        level={'H'}
                        includeMargin={true}
                    /> */}
                    <p>{couponCode}</p>
                </div>
            </div>
            {/* //////////////////////////////////// */}
            {showInvoice ? <NewViewCard card={invoice} onCancel={onCancell} showData={showInvoice} /> : null}
        </div>
    )
}

export default TeachersCoupon