import { DeleteOutlined } from '@ant-design/icons'
import { Button, DatePicker, Popconfirm, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { apiUrl } from '../../../utils/constants'
import { checkSelectOption, convertTitle, errorMessage, errorNotification, successNotification } from '../../../utils/helpers'

const { RangePicker } = DatePicker
const { Option } = Select

const QuestionFormReport = (props) => {
    const { user, adminActions, lanKorCondition, selectedLanguage } = props
    const [spin, setSpin] = useState({})
    const [spinLoad, setSpinLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [dateRange, setDateRange] = useState({
        date1: moment.utc().format('YYYY-MM-DD'),
        date2: moment.utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [reportData, setReportData] = useState([])

    useEffect(() => {
        getQuestionReports()
    }, [selectedUser])

    const createReport = () => {
        setSpinLoad(true)
        let obj = {
            date1: dateRange?.date1,
            date2: dateRange?.date2,
            adminId: user?._id,
            userId: selectedUser
        }
        console.log('obj', obj)

        axios.post(`${apiUrl}${ADMIN?.CREATE_ADMIN_FORM_REPORTS}`, obj)
            .then((res) => {
                const { data } = res
                console.log('data created', data)
                if (data?.success) {
                    successNotification(selectedLanguage?.txt_ReportSuccessfullyCreated)
                    getQuestionReports()
                    // console.log('DATA', data?.data)
                }
                else {
                    errorNotification(lanKorCondition ? data?.message : data?.messageNl)
                }
                setSpinLoad(false)
            })
            .catch((e) => {
                errorNotification(lanKorCondition ? e?.message : e?.messageNl)
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const createReportRange = (d) => {
        let obj = {
            date1: moment.utc(d[0]).format('YYYY-MM-DD'),
            date2: moment.utc(d[1]).format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    console.log('selectedUser', selectedUser)

    const getQuestionReports = () => {

        setLoading(true)

        axios.get(`${ADMIN.GET_ADMIN_FORM_REPORTS}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                console.log('data get', data)
                setLoading(false)

                if (data.success) {
                    setReportData(data?.data)
                }
            })
            .catch((e) => {
                // dispatch(removeUser())
                setLoading(false)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

            })
    }

    const deleteReport = (e) => {
        setSpin({ [e?._id]: true })
        console.log('e?._id', e?._id)
        axios.post(`${ADMIN.DELETE_ADMIN_FORM_REPORTS}`, { _id: e?._id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification(selectedLanguage?.txt_SuccessfullyDeleted)
                    getQuestionReports()
                    // setReportData(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    let columns = [
        {
            title: selectedLanguage?.ttx_Reportrange,
            key: 'sendingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.fileName}</p>
        },
        {
            title: selectedLanguage?.txt_Actions,
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <a onClick={() => window.open(e?.reportUrl, '_blank')}>View</a>
                <Popconfirm
                    title={`${selectedLanguage?.txt_Delete} ${convertTitle(e?.fileName)}?`}
                    okText={selectedLanguage?.txt_Delete}
                    cancelText={selectedLanguage?.txt_Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteReport(e)}
                >
                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> {selectedLanguage?.txt_Delete}</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </div>
        }
    ]
    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    return (
        <div className='flex-column menu-main'>
            <Spin spinning={spinLoad}>
                <div className='flex-mh' style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 400 }}>
                    <h3>{selectedLanguage?.txt_FormReport}</h3>
                    <RangePicker
                        defaultValue={[moment().add(-7, 'days'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                        disabledDate={disabledDate}
                    />
                    {/* <br /> */}
                    <Select
                        showSearch
                        showArrow
                        allowClear
                        defaultValue={buUsers[0]?._id}
                        onChange={(e) => setSelectedUser(e)}
                        className='form-input'
                        placeholder={selectedLanguage?.txt_SelectUser}
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                    </Select>
                </div>
                <Button
                    className='form-input report-button'
                    type='primary'
                    style={{ background: '#1eb2a6' }}
                    onClick={createReport}>{selectedLanguage?.txt_Createformreport}</Button>
                <br />
                <br />   <div className='new-layout-table-upper-div'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        loading={loading}
                        columns={columns}
                        dataSource={reportData}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </Spin>
        </div >
    )
}

export default QuestionFormReport