import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { addAdminCoupons, addAdminFaq, addAdminFormApprovals, addAdminForms, addAdminImages, addAdminVideos, addAllAdminCards, addAllAdminFeedbackForms, addAllAdminQuestions, addAllAdminQuestionsApprovals, addAllAdminSpecialQuestions, addAllBusinessUsers, addAllGuestUsers } from '../../Redux/actions/adminActions'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { addAllSubscription, addAllTutions, setAllChapters, setAllLanguages, setAllSubjects, setAllTeachers, setCities, setCollapsed, setTaxes } from '../../Redux/actions/generalActions'
import { addAllFormReports, addAllForms } from '../../Redux/actions/userActions'
import { allPaths } from '../../utils/constants'
import { adminData, generalData } from '../../utils/helpers'
import SideMenu from '../SideMenu/SideMenu'
import Header from './Header'


const AdminLayout = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const lanKorCondition = language?.language === 'english'

    useEffect(() => {
        console.log('Admin Layout')
        generalData.all(generalActions,false)
        adminData.all(adminActions, user?._id)
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
    }, [])
    const setWindowWidth = () => {
        window.innerWidth < 500 ? dispatch(setCollapsed(true)) : dispatch(setCollapsed(false))
    }

    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }
    const adminActions = {
        // addAllUsers: (e) => dispatch(addAllUsers(e)),
        addAllBusinessUsers: (e) => dispatch(addAllBusinessUsers(e)),
        addAllGuestUsers: (e) => dispatch(addAllGuestUsers(e)),
        addAllAdminQuestions: (e) => dispatch(addAllAdminQuestions(e)),
        addAllAdminQuestionsApprovals: (e) => dispatch(addAllAdminQuestionsApprovals(e)),
        addAdminFaq: (e) => dispatch(addAdminFaq(e)),
        addAllAdminSpecialQuestions: (e) => dispatch(addAllAdminSpecialQuestions(e)),
        addAllAdminCards: (e) => dispatch(addAllAdminCards(e)),
        addAdminForms: (e) => dispatch(addAdminForms(e)),
        addAdminVideos: (e) => dispatch(addAdminVideos(e)),
        addAdminCoupons: (e) => dispatch(addAdminCoupons(e)),
        addAdminImages: (e) => dispatch(addAdminImages(e)),
        addAdminFormApprovals: (e) => dispatch(addAdminFormApprovals(e)),
        addAllAdminFeedbackForms: (e) => dispatch(addAllAdminFeedbackForms(e)),
    }
    
    const userActions = {
        // addAllGuests: (e) => dispatch(addAllGuests(e)),
        addAllForms: (e) => dispatch(addAllForms(e)),
        addAllFormReports: (e) => dispatch(addAllFormReports(e)),
    }
    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e)),
        setCities: (e) => dispatch(setCities(e)),
        setTaxes: (e) => dispatch(setTaxes(e)),
        setAllTeachers: (e) => dispatch(setAllTeachers(e)),
        setAllChapters: (e) => dispatch(setAllChapters(e)),
        addAllTutions: (e) => dispatch(addAllTutions(e)),
        setAllLanguages: (e) => dispatch(setAllLanguages(e)),
        setAllSubjects: (e) => dispatch(setAllSubjects(e)),
        addAllSubscription: (e) => dispatch(addAllSubscription(e))
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.ADMIN_LOGIN} />
        )
    }

    return (
        <Route
            {...rest} render={props => <AddMenu {...props} component={Component} lanKorCondition={lanKorCondition} selectedLanguage={selectedLanguage} language={language} user={user} authActions={authActions} generalActions={generalActions} inlineCollapsed={inlineCollapsed} userActions={userActions} adminActions={adminActions} dispatch={dispatch} />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, dispatch } = props

    useEffect(() => {
        if (user) {

        }
    }, [props?.location?.pathname])

    if (!user) {
        return (
            <Redirect to={allPaths?.ADMIN_LOGIN} />
        )
    }

    return (
        <div className='helper-main'>
            <div className='menu-header' >
                <Header
                    {...props}
                />
            </div>
            <div className='menu-flex' style={{ background: '#c5bebe3d' }} >
                <div className='side-menu1' >
                    <SideMenu {...props} isAdmin={true} />
                </div>
                <div
                    className='helper-comp new-layout-scroll-class'
                    style={{
                        marginLeft: props?.inlineCollapsed ? 0 : 243,
                        background: 'white',
                        width: `calc(100% - ${props?.inlineCollapsed ? '0px' : '246px'})`
                    }}
                >
                    <Component
                        {...props}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminLayout