import { Image, Modal } from "antd";
import React from "react";

const CardsModal = (props) => {
    const { onCancel, selectedLanguage, CardsData } = props
    console.log('Cardsdata===>', CardsData)
    return (
        <div>
            <Modal
                open={true}
                onCancel={onCancel}
                footer={null}>
                <div>
                    <h1 style={{ textAlign: 'center' }}>Cards...</h1>
                    <div style={{
                        background: 'rgba(37, 155, 210, 0.24)',
                        padding: '10px 20px 10px 20px',
                        borderRadius: '12px',
                    }}>
                        {CardsData?.map((value, i) => {
                            console.log(value, '=======185s')
                            return (
                                <div style={{ borderRadius: '10px', background: 'rgba(37, 155, 210, 0.24)', padding: '10px', marginTop: '10px' }}>
                                    <strong>Card title:{value?.title}</strong>
                                    <br />
                                    <strong>Card content</strong>
                                    <ul>
                                        {value?.content?.map((v, i) =>
                                        (<div Key={i}>
                                            {v?.description ? <li > Description:{v?.description}</li> : null}
                                            {v?.files?.length ?
                                                <li >Files:
                                                    <ol>
                                                        {
                                                            v?.files.map((x, ind) => (
                                                                <li key={`0${ind}`}><Image width={150} src={x?.url} />  </li>
                                                            ))
                                                        }
                                                    </ol>
                                                </li>
                                                : null}
                                        </div>)
                                        )}
                                    </ul >
                                </div>
                            )
                        })}
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default CardsModal