import React, { useEffect, useState } from 'react'
import { secondsToMinutesAndSeconds } from '../../utils/helpers'

const TimerComponent = (props) => {
    const { triggerFunc, formData, currentQuestionObj, selectedLanguage } = props
    const [remainingTime, setRemainingTime] = useState(null)

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime ? prevTime - 1 : prevTime)
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    useEffect(() => {
        if (remainingTime === 0 && currentQuestionObj?.fixTime) {
            triggerFunc()
        }
    }, [remainingTime])

    useEffect(() => {
        setRemainingTime(currentQuestionObj?.time)
    }, [currentQuestionObj, formData])

    return (
        <>
            {currentQuestionObj?.fixTime &&
                <p style={{ margin: 0, marginRight: 4, float: 'right', fontWeight: 600 }}>{selectedLanguage?.txt_QuestionTimeRemaining} : {secondsToMinutesAndSeconds(remainingTime)}s</p>
            }
        </>
    )
}

export default TimerComponent
