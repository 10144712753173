import IconButton from '@mui/material/IconButton'
import { Button, DatePicker, Modal, Spin, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { convertRoundNumber, createCashBackReport, errorMessage, successMessage } from '../../../utils/helpers'

import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'

const { RangePicker } = DatePicker
const AdminCashback = (props) => {
    const { user, userActions, selectedLanguage, isEnglish } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [showSubmitModal, setShowSubmitModal] = useState({})
    const [bankDetails, setBankDetails] = useState({})
    const [usrDetail, setUsrDetail] = useState({})
    const [forms, setForms] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [pendingRequest, setPendingRequest] = useState([])


    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    useEffect(() => {
        getAllForms()
    }, [dateRange])
    useEffect(() => {
        setfilteredArray(_.uniqBy(buemailArray, 'text'))
        setPendingRequest(forms.filter(item => item.isPaid === false))
    }, [forms])

    const getAllForms = () => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }
        axios.post(`${ADMIN.GET_ALL_CASH_BACKS}`, obj)
            .then((res) => {
                const { data } = res
                setForms(data?.data || [])
                setLoading(false)
                setShowSubmitModal({})
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    const approveCashBackStatus = (e) => {
        let obj = {
            _id: e?._id,
            isPaid: true,
            adminId: user?._id
        }
        setLoading(true)
        axios.post(ADMIN.CONFIRM_CASH_BACK, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getAllForms()
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.txt__Playername,
            dataIndex: 'playerId',
            key: 'playerId',
            render: (e) => <span>{e?.fullName}</span>
        },
        {
            title: selectedLanguage?.txt_Player_email,
            dataIndex: 'playerId',
            key: 'playerId',
            filters: filteredArray,
            onFilter: (value, record) => record.playerId?.email.indexOf(value) === 0,
            render: (e) => <span>{e?.email}</span>
        },
        {
            title: selectedLanguage?.txt_Amount,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> $ {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.txt_ServiceCharge,
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> $ {e}</p>
        },
        {
            title: selectedLanguage?.txt_Requestdate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p>
        },
        {
            title: selectedLanguage?.txt_Status,
            sorter: (a, b) => a.isPaid - b.isPaid,
            render: (e) => (
                <div style={{ display: 'flex', width: '100px', justifyContent: 'space-between' }}>
                    <p style={{ width: '50px', marginTop: '10px' }}>{e?.isPaid ? selectedLanguage?.text_paid : selectedLanguage?.pending}</p>
                    {
                        e?.isPaid === true ?
                            < IconButton style={{ color: 'green' }} disabled >
                                <DoneAllIcon />
                            </IconButton > :
                            < IconButton style={{ color: 'red' }} onClick={() => { setShowSubmitModal(e) }} >
                                <CloseIcon />
                            </IconButton >}
                </div>
            )
        },
        {
            render: (e) => (
                <Button onClick={() => { setBankDetails(e?.playerId); setUsrDetail(e); setShowDetails(true) }}>{selectedLanguage?.txt_Details}</Button>
            )
        }
    ]

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getAllForms()
    }

    const buemailArray = forms.map((v, i) => {
        return {
            text: v?.playerId?.email,
            value: v?.playerId?.email
        }
    })

    const handleOk = () => {
        setShowDetails(false)
        setUsrDetail({})
        setBankDetails({})
    }

    console.log(forms, '=forms')
    return (
        <div>
            <div style={{ marginTop: '40px' }}>
                <p className='admin-home-title'>{selectedLanguage?.text_CashbackRequests}</p>
                <div className='flex-mobile' style={{ display: 'flex', marginTop: '20px', }}>
                    <RangePicker
                        defaultValue={[moment().add(-1, 'months'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                    />
                    <Button
                        loading={loading}
                        style={{ marginLeft: '10px' }}
                        type='primary'
                        onClick={() => createCashBackReport(user, dateRange, pendingRequest)}
                    >
                        {selectedLanguage?.txt_Report}
                    </Button>
                </div>
            </div>

            <div className='new-layout-table-upper-div margin0'>
                <Table
                    style={{ marginTop: '30px' }}
                    className='new-layout-table'
                    loading={loading}
                    dataSource={forms}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 'auto' }}
                />
            </div>
            <Modal
                open={showDetails}
                onOk={handleOk}
                onCancel={handleOk}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}>
                <div>
                    <p className='new-p-question'>{selectedLanguage?.txt_Bankdetails}</p>
                    <ul style={{ padding: '20px' }}>
                        <li>{selectedLanguage?.txt_FullName}: {bankDetails?.fullName} </li>
                        <li>{selectedLanguage?.txt_Bank_account}: {bankDetails?.bankAccount}</li>
                        <li>{selectedLanguage?.txt_IBAN}: {bankDetails?.IBAN}</li>
                        <p>{selectedLanguage?.txt_RequestedAmount}: ${convertRoundNumber(usrDetail?.amount)}</p>
                    </ul>
                </div>
            </Modal>
            {showSubmitModal?._id &&
                <Modal
                    open={showSubmitModal?._id}
                    onCancel={() => setShowSubmitModal({})}
                    onOk={() => setShowSubmitModal({})}
                    className='pop-info-modal'
                    footer={null}
                >
                    <div>
                        <p className='new-p-question'>{selectedLanguage?.txt_Cashbackconfirmation}</p>
                        <p className='p-opt'>{`${selectedLanguage?.txt_Are_you_sure_you_paid} $${showSubmitModal?.amount} ${selectedLanguage?.txtx_to}${showSubmitModal?.playerId?.fullName} `}</p>
                        <p className='p-opt'>{selectedLanguage?.txt_If_you_paid_press_yes}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <button className='cancel-btn'
                            onClick={() => setShowSubmitModal({})}>{selectedLanguage?.txt_Cancel}
                        </button>
                        <button className='confirm-btn'
                            onClick={() => approveCashBackStatus(showSubmitModal)}>
                            {loading && <Spin />}{selectedLanguage?.txt_Yes_i_paid}
                        </button>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default AdminCashback
