import { DotChartOutlined, WechatOutlined } from '@ant-design/icons'
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import { Button, Progress, Select, Skeleton, Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import noDataImage from '../../../assets/noDataImae.png'
import { ADMIN, GET } from '../../../utils/apis'
import { adminData, calculateAverageForClarity, calculateAverageForDetailed, calculateAverageForImages, calculateAverageForLanguageUse, calculateAverageForOverall, calculateAverageForRelevance, calculateReviewAverage, checkSelectOption, convertTitle, successNotification } from '../../../utils/helpers'

const { Option } = Select

const AdminCardReview = (props) => {
    const { user, adminActions, history, lanKorCondition, selectedLanguage } = props
    let filterTimeData = [
        { val: 'Newest first' },
        { val: 'Oldest first' },
        { val: 'Highest score' },
        { val: 'Lowest score' }
    ]

    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []
    const allTeachers = buUsers.filter((v) => v?.ownerType !== 'companyowner')
    const [loading, setLoading] = useState(false)
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [teacherCards, setTeacherCards] = useState([])
    const [selectedUser, setSelectedUser] = useState('admin' || null)
    const [filterTime, setFilterTime] = useState(filterTimeData?.[0]?.val)
    const [selectedCard, setSelectedCard] = useState(null)
    const [readingIndex, setReadingIndex] = useState(null)
    const [commentReadingIndex, setCommentReadingIndex] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [GuestReview, setGuestReview] = useState(null)

    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
        adminData.getGuestUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        getCard()
        setSelectedCard(null)
        setTeacherCards([])
    }, [selectedUser])

    const getCard = () => {
        setProgressLoading(true)
        setLoading(true)
        axios.get(`${GET?.GET_TEACHER_CARDS_FOR_ADMIN}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setTeacherCards(data?.data || [])
                setSelectedCard(data?.data?.[0] || null)
                setTimeout(() => {
                    setProgressLoading(false)
                }, 2000);
                setLoading(false)
            })
            .catch(() => {
                setProgressLoading(false)
                setLoading(false)
            })
    }

    const hideSingleReview = (_id) => {
        setButtonLoading(true)
        axios.post(`${ADMIN?.HIDE_SINGLE_REVIEW}`, { _id })
            .then((res) => {
                const { data } = res
                setButtonLoading(false)
                getCard()
                if (data?.success) {
                    successNotification(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setButtonLoading(false)
            })
    }

    const ShowSingleReview = (_id) => {
        setButtonLoading(true)
        axios.post(`${ADMIN?.SHOW_SINGLE_REVIEW}`, { _id })
            .then((res) => {
                const { data } = res
                setButtonLoading(false)
                getCard()
                if (data?.success) {
                    successNotification(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setButtonLoading(false)
            })
    }

    const getCardById = (Id) => {
        setProgressLoading(true)
        setLoading(true)
        axios.get(`${GET?.GET_CARDS_BY_ID}/${Id}`)
            .then((res) => {
                const { data } = res
                setSelectedCard(data?.data || {})
                setLoading(false)
                setTimeout(() => {
                    setProgressLoading(false)
                }, 2000);
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        let filterReviewModal = selectedCard?.review
        switch (filterTime) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(filterReviewModal || [])
    }, [selectedCard])

    const setFilterValue = (e) => {
        setFilterTime(e)
        let filterReviewModal = selectedCard?.review
        switch (e) {
            case 'Newest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(b?.created) - new Date(a?.created) })
                break
            case 'Oldest first':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return new Date(a?.created) - new Date(b?.created) })
                break
            case 'Highest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return b?.average - a?.average })
                break
            case 'Lowest score':
                filterReviewModal = filterReviewModal?.sort((a, b) => { return a?.average - b?.average })
                break
            default:
                break
        }
        setGuestReview(filterReviewModal || [])
    }

    return (
        <div className='card-form'>
            <div className='card2'
                style={{ height: 'auto', minHeight: '450px', marginBottom: 20 }}>
                <div >
                    <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                        <Select
                            showSearch
                            showArrow
                            allowClear={false}
                            defaultValue={selectedUser}
                            onChange={setSelectedUser}
                            className='form-input'
                            placeholder={selectedLanguage?.SelectUser}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            style={{ width: '250px' }}
                        >
                            <Option value={'admin'} >{`Admin`}</Option>
                            {allTeachers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                        </Select>
                    </div>
                    <div className='flex-mobile' style={{ marginTop: 10, margin: '10px auto' }}>
                        <Select
                            showSearch
                            showArrow
                            allowClear={false}
                            value={selectedCard?._id}
                            onChange={(e) => {
                                setSelectedCard(null);
                                getCardById(e)
                            }}
                            className='form-input'
                            placeholder={selectedLanguage?.txt_Select_card_here}
                            filterOption={checkSelectOption}
                            optionFilterProp='children'
                            style={{ width: '250px' }}
                        >
                            {teacherCards?.length ?
                                teacherCards?.map((v, i) => {
                                    return (
                                        <Option value={v?._id} key={i}>{v?.title}</Option>
                                    )
                                })
                                : null
                            }
                        </Select>
                    </div>
                </div>
                <br />
                <div >
                    {loading ?
                        <div className='admin-review-div' style={{ marginTop: 50 }}>
                            <Spin tip={selectedLanguage?.Loading} size='large' />
                        </div>
                        :
                        !allTeachers?.length ?
                            <div className='admin-review-div'>
                                <img style={{ height: 200, width: 200 }} src={noDataImage} />
                                <p className='review-text' style={{ fontWeight: 700 }}>{selectedLanguage?.txt_Teachernotfound}</p>
                            </div>
                            :
                            !teacherCards?.length ?
                                <div className='admin-review-div'>
                                    <img style={{ height: 200, width: 200 }} src={noDataImage} />
                                    <p className='review-text' style={{ fontWeight: 700 }}>{selectedLanguage?.txt_Cardscreatedbyselectedeacher}</p>
                                </div>
                                :
                                !selectedCard?._id ?
                                    <div className='admin-review-div'>
                                        <p className='review-text' style={{ fontWeight: 700 }}>{selectedLanguage?.txt_cardsnotfound}</p>
                                    </div>
                                    :
                                    <div style={{ padding: 20, paddingTop: 0 }}>
                                        <span style={{ marginLeft: 10 }}>
                                            <div style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                <button
                                                    style={{
                                                        height: 24,
                                                        width: 24,
                                                        padding: 0,
                                                        background: 'rgb(30, 178, 166)',
                                                        border: 'none',
                                                        borderRadius: 4,
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        color: 'white',
                                                    }}>
                                                    {selectedCard?.review?.length ? calculateReviewAverage(selectedCard?.review) : 0}
                                                </button>
                                            </div>

                                            <div style={{ textAlign: 'left', marginTop: 5 }}>
                                                <span>
                                                    <span className='card_sold_price'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }} >
                                                        <p className='card_title'
                                                        >{selectedCard?.title}</p>
                                                        <span >
                                                            <p className='card_desc1'>
                                                                {selectedCard?.review?.length ?
                                                                    calculateReviewAverage(selectedCard?.review) < 4.5 ?
                                                                        selectedLanguage?.Bad + ',' :
                                                                        calculateReviewAverage(selectedCard?.review) < 5.9 ?
                                                                            selectedLanguage?.Poor + ',' :
                                                                            calculateReviewAverage(selectedCard?.review) < 6.9 ?
                                                                                selectedLanguage?.Adeqate + ',' :
                                                                                calculateReviewAverage(selectedCard?.review) < 8.9 ?
                                                                                    selectedLanguage?.Good + ',' :
                                                                                    selectedLanguage?.Excellent + ',' : null}
                                                                {selectedCard?.review?.length ?
                                                                    selectedCard?.review?.length !== 1 ?
                                                                        selectedCard?.review?.length > 999 ?
                                                                            ' ' +
                                                                            `${selectedCard?.review?.length?.slice}`(0, 1) +
                                                                            '.' +
                                                                            `${selectedCard?.review?.length}`?.slice(1, 2) +
                                                                            'k' + ' ' + selectedLanguage?.Reviews :
                                                                            ' ' + selectedCard?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                        ' ' + selectedCard?.review?.length + ' ' + selectedLanguage?.Review :
                                                                    selectedLanguage?.NoReviewesYet}
                                                            </p>
                                                        </span>
                                                    </span>
                                                    <span className='card_sold_price'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            height: '25px'
                                                        }} >
                                                        <p className='card_desc' >{selectedCard?.description}</p>
                                                    </span>
                                                </span>
                                            </div>
                                        </span>
                                        <div>
                                            <div >
                                                <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.Categories}</h4>
                                                <div className='progress-main-div'>
                                                    <div className='progress-inner-main-div'>
                                                        <div className='progress-text-point-div'>
                                                            <p className='review-text'>{selectedLanguage?.ttx_Relevance}</p>
                                                            <p className='review-text'>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> :
                                                                    selectedCard?.review?.length
                                                                        ? calculateAverageForRelevance(selectedCard?.review) : 0}
                                                                {ProgressLoading ? null : '/10'}</p>
                                                        </div>
                                                        <div>
                                                            {ProgressLoading ?
                                                                <Skeleton.Input
                                                                    style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                    active={true}
                                                                    block={true}
                                                                /> : <Progress percent={selectedCard?.review?.length ?
                                                                    (calculateAverageForRelevance(selectedCard?.review) * 10) : 0}
                                                                    showInfo={false} />}
                                                        </div>
                                                    </div>
                                                    <div className='progress-inner-main-div'>
                                                        <div className='progress-text-point-div'>
                                                            <p className='review-text'>{selectedLanguage?.ttx_Clarity}</p>
                                                            <p className='review-text'>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> :
                                                                    selectedCard?.review?.length
                                                                        ? calculateAverageForClarity(selectedCard?.review) : 0}
                                                                {ProgressLoading ? null : '/10'}</p>
                                                        </div>
                                                        <div>
                                                            {ProgressLoading ?
                                                                <Skeleton.Input
                                                                    style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                    active={true}
                                                                    block={true}
                                                                /> : <Progress percent={selectedCard?.review?.length ?
                                                                    (calculateAverageForClarity(selectedCard?.review) * 10) : 0}
                                                                    showInfo={false} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='progress-main-div'>
                                                    <div className='progress-inner-main-div'>
                                                        <div className='progress-text-point-div'>
                                                            <p className='review-text'>{selectedLanguage?.ttx_Detailed}</p>
                                                            <p className='review-text'>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> :
                                                                    selectedCard?.review?.length
                                                                        ? calculateAverageForDetailed(selectedCard?.review) : 0}
                                                                {ProgressLoading ? null : '/10'}</p>
                                                        </div>
                                                        <div>
                                                            {ProgressLoading ?
                                                                <Skeleton.Input
                                                                    style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                    active={true}
                                                                    block={true}
                                                                /> : <Progress percent={selectedCard?.review?.length ?
                                                                    (calculateAverageForDetailed(selectedCard?.review) * 10) : 0}
                                                                    showInfo={false} />}
                                                        </div>
                                                    </div>
                                                    <div className='progress-inner-main-div'>
                                                        <div className='progress-text-point-div'>
                                                            <p className='review-text'>{selectedLanguage?.ttx_Languageuse}</p>
                                                            <p className='review-text'>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCard?.review?.length
                                                                        ? calculateAverageForLanguageUse(selectedCard?.review) : 0}
                                                                {ProgressLoading ? null : '/10'}</p>
                                                        </div>
                                                        <div>
                                                            {ProgressLoading ?
                                                                <Skeleton.Input
                                                                    style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                    active={true}
                                                                    block={true}
                                                                /> : <Progress percent={selectedCard?.review?.length ?
                                                                    (calculateAverageForLanguageUse(selectedCard?.review) * 10) : 0}
                                                                    showInfo={false} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='progress-main-div'>
                                                    <div className='progress-inner-main-div'>
                                                        <div className='progress-text-point-div'>
                                                            <p className='review-text'>{selectedLanguage?.ttx_Images}</p>
                                                            <p className='review-text'>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCard?.review?.length
                                                                        ? calculateAverageForImages(selectedCard?.review) : 0}
                                                                {ProgressLoading ? null : '/10'}</p>
                                                        </div>
                                                        <div>
                                                            {ProgressLoading ?
                                                                <Skeleton.Input
                                                                    style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                    active={true}
                                                                    block={true}
                                                                /> : <Progress percent={selectedCard?.review?.length ?
                                                                    (calculateAverageForImages(selectedCard?.review) * 10) : 0}
                                                                    showInfo={false} />}
                                                        </div>
                                                    </div>
                                                    <div className='progress-inner-main-div'>
                                                        <div className='progress-text-point-div'>
                                                            <p className='review-text'>{selectedLanguage?.ttx_Overall}</p>
                                                            <p className='review-text'>
                                                                {ProgressLoading ?
                                                                    <Skeleton.Node active={true} style={{ height: 10, width: 25, marginBottom: -8 }}>
                                                                        <DotChartOutlined style={{ fontSize: 0 }} />
                                                                    </Skeleton.Node> : selectedCard?.review?.length
                                                                        ? calculateAverageForOverall(selectedCard?.review) : 0}
                                                                {ProgressLoading ? null : '/10'}</p>
                                                        </div>
                                                        <div>
                                                            {ProgressLoading ?
                                                                <Skeleton.Input
                                                                    style={{ height: 10, borderRadius: 10, marginTop: 8 }}
                                                                    active={true}
                                                                    block={true}
                                                                /> : <Progress percent={selectedCard?.review?.length ?
                                                                    (calculateAverageForOverall(selectedCard?.review) * 10) : 0}
                                                                    showInfo={false} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-end'
                                            }}>
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    allowClear={false}
                                                    value={filterTime}
                                                    onChange={setFilterValue}
                                                    className='form-input'
                                                    optionFilterProp='children'
                                                    style={{ width: '250px', marginTop: '10px' }}
                                                >
                                                    {filterTimeData?.map((v, i) => {
                                                        return (
                                                            <Option value={v?.val} key={i}>{`${v?.val}`}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div style={{ marginTop: 10 }}>
                                                <h4 style={{ fontWeight: 600 }}>{selectedLanguage?.GuestReview}</h4>
                                            </div>
                                            {
                                                GuestReview?.length ?
                                                    GuestReview?.map((v, i) => {
                                                        return (
                                                            <div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '80%', alignItems: 'center' }}>
                                                                        <div>
                                                                            {ProgressLoading ?
                                                                                <div
                                                                                    style={{ height: 40, width: 40, borderRadius: 100 }}
                                                                                >
                                                                                    <Skeleton.Avatar
                                                                                        style={{ margin: 5 }}
                                                                                        active={true} shape={'circle'} />
                                                                                </div> :
                                                                                <div
                                                                                    style={{
                                                                                        height: 40,
                                                                                        width: 40,
                                                                                        border: '2px solid rgb(30, 178, 166)',
                                                                                        borderRadius: 100,
                                                                                        // padding: '10px'
                                                                                    }}
                                                                                >
                                                                                    <FaUserCircle size={36} color='rgb(30, 178, 166)' />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {ProgressLoading ? <div style={{ margin: 3, marginLeft: 7 }}>
                                                                            <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node>
                                                                            <br />
                                                                            <Skeleton.Node active={true} style={{ height: 10, width: 35 }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node>
                                                                        </div> :
                                                                            <div style={{ margin: 5 }}>
                                                                                <p className='review-text' style={{ fontWeight: 700 }}>{v?.userId?.fullName ? v?.userId?.fullName : '----'}</p>
                                                                                <p className='review-text' style={{ fontWeight: 400 }}>{v?.userId?.city ? v?.userId?.city : '----'}</p>
                                                                            </div>}
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'flex-end' }}>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'flex-end'
                                                                            }}
                                                                        >
                                                                            {ProgressLoading ?
                                                                                <Skeleton.Input style={{ height: 10, width: 50 }} active={true} block={true} />
                                                                                : <p className='review-text' style={{ marginBottom: 2 }}>
                                                                                    {v?.average ?
                                                                                        v?.average < 4.5 ?
                                                                                            selectedLanguage?.Bad :
                                                                                            v?.average < 5.9 ?
                                                                                                selectedLanguage?.Poor :
                                                                                                v?.average < 6.9 ?
                                                                                                    selectedLanguage?.Adeqate :
                                                                                                    v?.average < 8.9 ?
                                                                                                        selectedLanguage?.Good :
                                                                                                        v?.average > 8.9 ?
                                                                                                            selectedLanguage?.Excellent :
                                                                                                            null :
                                                                                        null}
                                                                                </p>}
                                                                            {ProgressLoading ?
                                                                                <Skeleton.Node active={true} style={{ height: 34, width: 34 }}>
                                                                                    <DotChartOutlined style={{ fontSize: 0 }} />
                                                                                </Skeleton.Node> :
                                                                                <button
                                                                                    style={{
                                                                                        height: 34,
                                                                                        width: 34,
                                                                                        padding: 0,
                                                                                        background: 'rgb(30, 178, 166)',
                                                                                        border: 'none',
                                                                                        borderRadius: 4,
                                                                                        fontSize: 12,
                                                                                        fontWeight: 500,
                                                                                        color: 'white',
                                                                                    }}>
                                                                                    {v?.average ?
                                                                                        v?.average === 10 ? 10 : v?.average.toFixed(1)
                                                                                        : 0}
                                                                                </button>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ margin: 5, }}>
                                                                    <p className='review-text' style={{ fontWeight: 700 }}>
                                                                        {ProgressLoading ?
                                                                            <Skeleton.Node active={true} style={{ height: 10, width: 35, marginBottom: 5 }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node>
                                                                            :
                                                                            `${selectedLanguage?.Reviewed}:`}
                                                                        {ProgressLoading ?
                                                                            <Skeleton.Node active={true} style={{ marginLeft: 4, height: 10, width: 35, marginBottom: 5 }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node>
                                                                            :
                                                                            new Date(v?.created).toLocaleDateString()}
                                                                    </p>
                                                                    {ProgressLoading ?
                                                                        <Skeleton.Node active={true} style={{ height: 40, marginBottom: 5 }}>
                                                                            <DotChartOutlined style={{ fontSize: 0 }} />
                                                                        </Skeleton.Node>
                                                                        :
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                            <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                                                <p className={'review-text'}   >{v?.comment ? <>
                                                                                    <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                                        v?.comment?.length > 100 && commentReadingIndex !== i ?
                                                                                            v?.comment?.slice(0, 100) + '...'
                                                                                            :
                                                                                            v?.comment} </> : '-----'}
                                                                                    <p className='review-text'
                                                                                        style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                                        onClick={() => { setCommentReadingIndex(commentReadingIndex !== i ? i : null) }}
                                                                                    >{v?.comment?.length > 100 ?
                                                                                        commentReadingIndex !== i ?
                                                                                            selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                                                </p>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', paddingLeft: '10px', paddingBottom: '10px' }}>
                                                                                {!v?.isHide ?
                                                                                    < div style={{ width: '100%' }}>
                                                                                        <Button
                                                                                            onClick={() => { hideSingleReview(v?._id) }}
                                                                                            type='primary'
                                                                                            // block
                                                                                            htmlType='submit'
                                                                                            style={{ width: '80px', }}
                                                                                            loading={buttonLoading}
                                                                                        >
                                                                                            {selectedLanguage?.Button_Hide}
                                                                                        </Button>
                                                                                    </div> :
                                                                                    <div style={{ width: '100%' }}>
                                                                                        <Button
                                                                                            onClick={() => { ShowSingleReview(v?._id) }}
                                                                                            type='primary'
                                                                                            // block
                                                                                            htmlType='submit'
                                                                                            style={{ width: '80px', }}
                                                                                            loading={buttonLoading}
                                                                                        >
                                                                                            {selectedLanguage?.Button_display}
                                                                                        </Button>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {v?.answered === null ? null :
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <div style={{
                                                                            width: '50px',
                                                                            height: '50px',
                                                                            padding: '14px',
                                                                            rotate: '180deg',
                                                                        }}>
                                                                            {ProgressLoading ? <Skeleton.Node active={true} style={{ height: 20, width: 20 }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node> : <ReplyAllIcon fontSize='large' />}
                                                                        </div>
                                                                        {ProgressLoading ?
                                                                            <Skeleton.Node active={true} style={{ height: 40, }}>
                                                                                <DotChartOutlined style={{ fontSize: 0 }} />
                                                                            </Skeleton.Node> :
                                                                            <div style={{ background: '#E9EBEE', width: '80%', padding: 20, borderRadius: 8 }}>
                                                                                <p className={'review-text'}   >{v?.answered ? <>
                                                                                    <WechatOutlined style={{ marginRight: 10 }} /> {
                                                                                        v?.answered?.length > 100 && readingIndex !== i ?
                                                                                            v?.answered?.slice(0, 100) + '...'
                                                                                            :
                                                                                            v?.answered} </> : '-----'}
                                                                                    <p className='review-text'
                                                                                        style={{ color: '#007AFF', cursor: 'pointer' }}
                                                                                        onClick={() => { setReadingIndex(readingIndex !== i ? i : null) }}
                                                                                    >{v?.answered?.length > 100 ?
                                                                                        readingIndex !== i ?
                                                                                            selectedLanguage?.ContinueReading : selectedLanguage?.ShowLess : null}</p>
                                                                                </p>
                                                                            </div>}
                                                                    </div>}
                                                                <hr />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div>
                                                        <p className='review-text'>{selectedLanguage?.NoReviewesYet}</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>}
                </div>
            </div >
        </div >
    )
}

export default AdminCardReview