import axios from 'axios'
import momentTz from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { addAllTutions, setAllChapters, setAllLanguages, setAllSubjects, setAllTeachers, setCities, setCollapsed, setTaxes } from '../../Redux/actions/generalActions'
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import { addAllBuInvoices, addAllFormReports, addAllForms, addAllGiftCardSubscriptionDetail, addAllInvoices, addAllLotterySubscriptionDetail, addAllOtherUserQuestions, addAllPayments, addAllQuestions, addAllSubscriptions, addAllVouchers, addMyStudents, addStudentResults, addUserFaq, addUserJoinedGroups, addUserPendingGroups, addWalletTransaction, setRates } from '../../Redux/actions/userActions'
import { GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import SideMenuUpdate from '../SideMenuUpdate/SideMenuUpdate'
import FooterUpdate from './FooterUpdate'
import HeaderUpdateMobile from './HeaderForMobile'
import HeaderUpdateAppBar from './HeaderUpdate'
import { addAdminImages } from '../../Redux/actions/adminActions'
import { adminData } from '../../utils/helpers'

const MenuLayoutUpdate = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const language = useSelector(state => state?.languageReducer)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)
    const MenuNone = useSelector(state => state.menuNoneReducer.menuNone)
    const [updateWallet, setUpdateWallet] = useState(false)
    const filter = useSelector(state => state.userReducer.filter)
    const rates = useSelector(state => state.userReducer.rates)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const lanKorCondition = language?.language === 'english'
    useEffect(() => {
        setUpdateWallet(true)
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
        dispatch(SetMenuNone(false))
        adminData.getAdminImages(designerActions)
        // getAllUserData(user?._id, userActions)
        // getGeneralData(generalActions)
    }, [])

    const setWindowWidth = () => {
        window.innerWidth < 701 ? dispatch(SetMenuNone(true)) : dispatch(SetMenuNone(false))
    }

    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }

    const userActions = {
        addAllInvoices: (e) => dispatch(addAllInvoices(e)),
        addAllVouchers: (e) => dispatch(addAllVouchers(e)),
        addAllPayments: (e) => dispatch(addAllPayments(e)),
        addAllBuInvoices: (e) => dispatch(addAllBuInvoices(e)),
        addAllSubscriptions: (e) => dispatch(addAllSubscriptions(e)),
        setRates: (e) => dispatch(setRates(e)),
        addUserJoinedGroups: (e) => dispatch(addUserJoinedGroups(e)),
        addUserPendingGroups: (e) => dispatch(addUserPendingGroups(e)),
        addAllLotterySubscriptionDetail: (e) => dispatch(addAllLotterySubscriptionDetail(e)),
        addAllGiftCardSubscriptionDetail: (e) => dispatch(addAllGiftCardSubscriptionDetail(e)),
        addAllForms: (e) => dispatch(addAllForms(e)),
        addAllFormReports: (e) => dispatch(addAllFormReports(e)),
        addAllQuestions: (e) => dispatch(addAllQuestions(e)),
        addAllOtherUserQuestions: (e) => dispatch(addAllOtherUserQuestions(e)),
        addUserFaq: (e) => dispatch(addUserFaq(e)),
        addWalletTransaction: (e) => dispatch(addWalletTransaction(e)),
        addStudentResults: (e) => dispatch(addStudentResults(e)),
        addMyStudents: (e) => dispatch(addMyStudents(e))
    }

    const designerActions = {
        addAdminImages: (e) => dispatch(addAdminImages(e)),
    }

    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e)),
        setCities: (e) => dispatch(setCities(e)),
        addAllTutions: (e) => dispatch(addAllTutions(e)),
        setTaxes: (e) => dispatch(setTaxes(e)),
        setAllTeachers: (e) => dispatch(setAllTeachers(e)),
        setAllChapters: (e) => dispatch(setAllChapters(e)),
        setAllLanguages: (e) => dispatch(setAllLanguages(e)),
        setAllSubjects: (e) => dispatch(setAllSubjects(e))
    }
    if (!user) {
        return (
            <Redirect to={allPaths?.HOME} />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <AddMenu {...props} lanKorCondition={lanKorCondition} selectedLanguage={selectedLanguage} rates={rates} language={language} filter={filter} component={Component} user={user} authActions={authActions} MenuNone={MenuNone} inlineCollapsed={inlineCollapsed} dispatch={dispatch} generalActions={generalActions} designerActions={designerActions} userActions={userActions} updateWallet={updateWallet} setUpdateWallet={setUpdateWallet} />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, dispatch, history, location } = props
    const MenuNone = useSelector(state => state.menuNoneReducer.menuNone)

    useEffect(() => {
        if (user) {
            getUser()
            let timeZone = momentTz.tz.zonesForCountry(user?.country || 'NL')
            momentTz.tz.setDefault(timeZone?.[0] || 'Europe/Amsterdam')
        }
        try {
            // axios.get(`${apiUrl}/api/get/test`)
        }
        catch (e) {
        }
    }, [props?.location?.pathname])

    const getUser = () => {
        axios.get(`${GET?.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
                else {
                    history?.replace(allPaths?.LOGIN)
                    dispatch(removeUser())
                }
            })
            .catch((e) => {
                dispatch(removeUser())
            })
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.LOGIN} />
        )
    }

    return (
        <div className='helper-main'>
            <div className='menu-flex'>
                <div
                >
                    {window.innerWidth < 701 && <SideMenuUpdate {...props} />}
                </div>
                <div
                    style={{
                        minWidth: '20%',
                        position: 'relative',
                        height: `calc(100vh)`,
                        width: '100%',
                        paddingBottom: '50px',
                    }}
                >
                    <div style={{
                        background: 'white',
                        height: '62px',
                    }}>
                        <HeaderUpdateMobile
                            {...props}
                        />
                        <HeaderUpdateAppBar
                            {...props}
                        />

                    </div>
                    <div
                        className='sidebar-scroll_class'
                        style={{
                            height: `calc(100vh - 122px)`,
                            maxHeight: `calc(100vh - 122px)`,
                            overflow: 'scroll',
                            marginTop: '10px',
                            marginBottom: '10px',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            margin: '0px auto',
                            padding: '20px'
                        }}>
                        <Component
                            {...props}
                            getUser={getUser}
                        />
                    </div>
                    <div style={{
                        height: '60px',
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <FooterUpdate />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MenuLayoutUpdate