import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { allPaths, drawerAdminRoutes, drawerGuestRoutes, drawerRoutes } from '../../utils/constants'
import { setActiveAdminMenu, setActiveMenu, setKeyMenu } from '../../utils/helpers'

const { SubMenu } = Menu

const SideMenu = (props) => {
    const { location, inlineCollapsed, isAdmin, user, selectedLanguage, language } = props
    const [key, setKey] = useState(1)
    const [mobile, setMobile] = useState(false)
    useEffect(() => {
        window.addEventListener('resize', setSiderMargin)
        setSiderMargin()
    }, [])

    const setSiderMargin = () => {
        window.innerWidth < 500 ? setMobile(true) : setMobile(false)
    }

    const handleClick = (e) => {
        setKey(parseInt(e?.key))
    }

    const isGuest = user?.userType === 'guest'
    const isNlSelect = language?.language === 'netherlands'
    return (
        <div className='home-main'>
            <div className='flex-row'>
                <div style={{ marginTop: mobile ? 56 : 64 }}>
                    <Menu
                        onClick={handleClick}
                        style={{ height: `calc(90vh)`, width: !inlineCollapsed ? '240px' : '80px' }}
                        defaultOpenKeys={[`${setKeyMenu(location?.pathname)}`]}
                        defaultSelectedKeys={[`${location?.pathname}`]}
                        mode='inline'
                        inlineCollapsed={inlineCollapsed}
                    >
                        {drawerAdminRoutes?.map((v, i) => {
                            if (v?.isSubMenu && v?.children?.length) {
                                return <SubMenu key={v?.route} icon={v?.icon} title={isNlSelect ? v?.titleNl : v?.title} style={{ fontSize: 18 }}>
                                    {v?.children?.map((y, j) => {
                                        return (
                                            <Menu.Item key={y?.route} icon={y.icon}>
                                                <Link
                                                    to={y?.route}
                                                    onClick={() => { return false }}
                                                    className='side-list'
                                                >
                                                    {isNlSelect ? y.titleNl : y.title}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    })}
                                </SubMenu>
                            }
                            return (
                                <Menu.Item key={v.route} icon={v?.icon}>
                                    <Link
                                        to={v?.route}
                                        onClick={() => { return false }}
                                        className='side-list'
                                    >
                                        {isNlSelect ? v.titleNl : v.title}
                                    </Link>
                                </Menu.Item>
                            )
                        })}
                    </Menu>
                </div>
            </div>
        </div >
    )
}

export default SideMenu