import { Button, DatePicker, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, POST } from '../../../utils/apis'
import { checkSelectOption, convertTitle, createExamReport, createQuestionFeeReport, errorNotification, successNotification } from '../../../utils/helpers'

const { RangePicker } = DatePicker
const { Option } = Select

const QuestionFeeReport = (props) => {
    const { user, adminActions, lanKorCondition, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [spin, setSpin] = useState({})
    const [spinLoad, setSpinLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dateRange, setDateRange] = useState({ date1: moment.utc().format('YYYY-MM-DD'), date2: moment.utc().add(7, 'days').format('YYYY-MM-DD') })
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [reportData, setReportData] = useState([])

    useEffect(() => {
        createReport()
    }, [])
    console.log('reportData', reportData)
    const createReport = () => {
        setSpinLoad(true)
        let obj = {
            date1: dateRange?.date1,
            date2: dateRange?.date2,
            adminId: user?._id,
            userId: selectedUser
        }
        axios.post(`${ADMIN?.GET_QUESTION_FEE_REPORT}`, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setReportData(data?.data)
                    successNotification(selectedLanguage?.txt_ReportSuccessfullyCreated)
                } else {
                    setReportData([])
                    errorNotification(lanKorCondition ? data?.message : data?.messageNl)
                }
                setSpinLoad(false)
            })
            .catch((e) => {
                setReportData([])
                errorNotification(lanKorCondition ? e?.message : e?.messageNl)
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const createReportRange = (d) => {
        let obj = {
            date1: moment.utc(d[0]).format('YYYY-MM-DD'),
            date2: moment.utc(d[1]).format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    let columns = [
        {
            title: selectedLanguage?.ttx_Reportrange,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{dateRange?.date1} - {dateRange?.date2}</p>
        },
        {
            title: selectedLanguage?.txt_Actions,
            key: 'actions',
            render: (e) => <Button type='primary' onClick={() =>
                createQuestionFeeReport(reportData, buUsers?.find((v) => v?._id == selectedUser), dateRange)
            }>Download</Button>
        }
    ]
    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    return (
        <div style={{ marginTop: '10px', padding: '5px' }}>
            <Spin spinning={spinLoad}>
                <div className='flex-mh' style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, width: 400 }} >
                    <h1 className='crt-qutin-heading'>{selectedLanguage?.txt_FeeReport}</h1>
                    <RangePicker
                        defaultValue={[moment().add(-7, 'days'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                        disabledDate={disabledDate}
                    />
                    {/* <br /> */}
                    <Select
                        showSearch
                        showArrow
                        allowClear
                        defaultValue={buUsers[0]?._id}
                        onChange={(e) => setSelectedUser(e)}
                        className='form-input'
                        placeholder={selectedLanguage?.txt_SelectUser}
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                    </Select>
                </div>
                <Button
                    type='primary'
                    onClick={createReport}>{selectedLanguage?.txt_Createformreport}</Button>
                <br />
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        loading={loading}
                        columns={columns}
                        dataSource={reportData?.slice(0, 1)}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </Spin>
        </div >
    )
}

export default QuestionFeeReport