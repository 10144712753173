import React, { useEffect } from "react";
import { NewFooter } from "../../NewComponents";
import VisiterHomeContent from "./VisiterHomeContent";
import { useDispatch, useSelector } from "react-redux";
import NewVisiterHeader from "./VisiterHeader";
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useHistory } from "react-router-dom";
import { removeUser } from "../../Redux/actions/authActions";


const VisiterHome = () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const history = useHistory()
    useEffect(() => {
        dispatch(removeUser())
    }, [])
    return (
        <div className="new-main-layout-div">
            <div className="visiter-home-main-div ">
                <div className="visiter-home-main-div-scroll new-layout-scroll-class">
                    <NewVisiterHeader language={language} selectedLanguage={selectedLanguage} />
                    <VisiterHomeContent language={language} selectedLanguage={selectedLanguage} />
                    <NewFooter history={history} language={language} selectedLanguage={selectedLanguage} />
                </div>
            </div>
        </div>
    )
}

export default VisiterHome