import { CheckCircleFilled } from '@ant-design/icons'
import { Button, Popconfirm, Select, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { adminData, checkSelectOption, convertTitle, errorMessage, successNotification } from '../../../utils/helpers'

const { Option } = Select

const AdminPayments = (props) => {
    const { user, adminActions, language, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([])
    const [spin, setSpin] = useState({})
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [userDetail, setUserDetail] = useState({})
    const isEnglish = language?.language === 'english'
    console.log('payments', payments, userDetail)
    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        getPayments()
        setUserDetail(buUsers?.find((v) => v?._id === selectedUser))
    }, [selectedUser])

    const getPayments = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ADMIN_PAYMENTS}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setPayments(data?.data || [])
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const changePaymentStatus = (_id, adminPaid) => {
        setSpin({ [_id]: true })
        let obj = {
            _id,
            adminPaid,
            userId: user?._id,
            tax: 21,
            fee: userDetail?.questionFee
        }
        axios.post(ADMIN?.CHANGE_PAYMENT_STATUS, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                getPayments()
                if (data?.success) {
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                } else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let columns = [
        {
            title: selectedLanguage?.title_Question,
            dataIndex: ['question', 'question'],
        },
        {
            title: selectedLanguage?.title_Title,
            dataIndex: ['question', 'title'],
        },
        {
            title: selectedLanguage?.title_questionFee,
            align: 'center',
            render: (e) => <>{e?.adminPaid ? e?.fee : userDetail?.questionFee}</>
        },
        {
            title: selectedLanguage?.Status,
            align: 'center',
            render: (e) => <> {e?.adminPaid ?
                <Button type='link' icon={<CheckCircleFilled />} />
                :
                <>{spin[e?._id] ? <Spin /> :
                    <Popconfirm
                        title={`You have to pay € ${userDetail?.questionFee + (0.21)}`}
                        okText={selectedLanguage?.Pay}
                        cancelText={selectedLanguage?.Cancel}
                        okButtonProps={{ type: 'primary', className: 'form-button' }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => changePaymentStatus(e?._id, !e?.adminPaid)}
                    >
                        <Button >{selectedLanguage?.Pay}</Button>
                    </Popconfirm>
                }</>
            }</>,
            sorter: (a, b) => a?.adminPaid - b?.adminPaid
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='flex-mobile' style={{ display: 'flex' }}>
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedUser}
                    onChange={(e) => (setSelectedUser(e))}
                    className='form-input'
                    placeholder={selectedLanguage?.SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
            </div>

            <div className='new-layout-table-upper-div margin0'>
                <Table
                    style={{ marginTop: '30px' }}
                    className='new-layout-table'
                    columns={columns}
                    dataSource={payments}
                    loading={loading}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 'auto' }}
                />
            </div>
        </div>
    )
}

export default AdminPayments