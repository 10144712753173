
import { ALL_ADMIN_FEEDBACK_FORMS_FOR_TEACHER, ALL_FEEDBACK_FORMS, ALL_SUBSCRIBE_GIFTCARDS, ALL_SUBSCRIBE_LOTTERY, BU_INVOICES, DASHBOARD_DATA, FORMS, FORM_REPORTS, INVOICES, MY_CASHBACKS, MY_STUDENTS, OTHER_USER_QUESTIONS, PAYMENTS, PRODUCT_FILTER, RATES, STUDENT_CARDS, STUDENT_RESULT, STUDENT_TRAIN_RESULT, STUDENT_VIDEOS, SUBSCRIPTIONS, TEACHER_CARDS, TEACHER_COUPON, TEACHER_FEEDBACK_FORMS, TEACHER_RESPONSE, TEACHER_TO_STUDENT_RESPONSE, TEACHER_VIDEOS, USER_FAQ, USER_JOINED_GROUPS, USER_PENDING_GROUPS, USER_QUESTIONS, VOUCHERS, WALLET_TRANSACTIONS } from '../types'

const addAllInvoices = (invoices) => {
    return {
        type: INVOICES,
        invoices
    }
}

const addAllPayments = (payments) => {
    return {
        type: PAYMENTS,
        payments
    }
}

const addAllVouchers = (vouchers) => {
    return {
        type: VOUCHERS,
        vouchers
    }
}

const addFilter = (filter) => {
    return {
        type: PRODUCT_FILTER,
        filter
    }
}

const addAllBuInvoices = (bu_invoices) => {
    return {
        type: BU_INVOICES,
        bu_invoices
    }
}

const addAllSubscriptions = (subscriptions) => {
    return {
        type: SUBSCRIPTIONS,
        subscriptions
    }
}
const setRates = (rates) => {
    return {
        type: RATES,
        rates
    }
}
const addUserJoinedGroups = (joined) => {
    return {
        type: USER_JOINED_GROUPS,
        joined
    }
}
const addUserPendingGroups = (pending) => {
    return {
        type: USER_PENDING_GROUPS,
        pending
    }
}
const addAllLotterySubscriptionDetail = (lotteryDetail) => {
    return {
        type: ALL_SUBSCRIBE_LOTTERY,
        lotteryDetail
    }
}
const addAllGiftCardSubscriptionDetail = (giftCardDetail) => {
    return {
        type: ALL_SUBSCRIBE_GIFTCARDS,
        giftCardDetail
    }
}

const addAllForms = (forms) => {
    return {
        type: FORMS,
        forms
    }
}

const addAllFormReports = (formReports) => {
    return {
        type: FORM_REPORTS,
        formReports
    }
}

const addAllQuestions = (questions) => {
    return {
        type: USER_QUESTIONS,
        questions
    }
}

const addAllOtherUserQuestions = (otherQuestions) => {
    return {
        type: OTHER_USER_QUESTIONS,
        otherQuestions
    }
}

const addUserFaq = (userFaq) => {
    return {
        type: USER_FAQ,
        userFaq
    }
}


const addWalletTransaction = (walletTransaction) => {
    return {
        type: WALLET_TRANSACTIONS,
        walletTransaction
    }
}
const addStudentResults = (studentResult) => {
    return {
        type: STUDENT_RESULT,
        studentResult
    }
}
const addStudentTrainResults = (studentTrainResult) => {
    return {
        type: STUDENT_TRAIN_RESULT,
        studentTrainResult
    }
}
const addMyStudents = (myStudents) => {
    return {
        type: MY_STUDENTS,
        myStudents
    }
}
const addCards = (studentCards) => {
    return {
        type: STUDENT_CARDS,
        studentCards
    }
}
const addTeacherVideos = (teacherVideos) => {
    return {
        type: TEACHER_VIDEOS,
        teacherVideos
    }
}
const addStudentVideos = (studentVideos) => {
    return {
        type: STUDENT_VIDEOS,
        studentVideos
    }
}
const addTeacherCards = (teacherCards) => {
    return {
        type: TEACHER_CARDS,
        teacherCards
    }
}
const addMyCashbacks = (myCashbacks) => {
    return {
        type: MY_CASHBACKS,
        myCashbacks
    }
}
const addTeacherFeedbackForm = (teacherFeedbackForm) => {
    return {
        type: TEACHER_FEEDBACK_FORMS,
        teacherFeedbackForm
    }
}
const addAllFeedbackForm = (allFeedbackForm) => {
    return {
        type: ALL_FEEDBACK_FORMS,
        allFeedbackForm
    }
}

const addAdminFeedbackFormForTeacer = (allAdminFeedbackForTeacher) => {
    return {
        type: ALL_ADMIN_FEEDBACK_FORMS_FOR_TEACHER,
        allAdminFeedbackForTeacher
    }
}
const addDashboardData = (dashboardData) => {
    return {
        type: DASHBOARD_DATA,
        dashboardData
    }
}

const addTeacherCoupon = (teacherCoupon) => {
    return {
        type: TEACHER_COUPON,
        teacherCoupon
    }
}
const addTeacherResponses = (teacherResponses) => {
    return {
        type: TEACHER_RESPONSE,
        teacherResponses
    }
}
const addTeacherToStudentResponse = (teacherToStudentResponse) => {
    return {
        type: TEACHER_TO_STUDENT_RESPONSE,
        teacherToStudentResponse
    }
}

export {
    addAllBuInvoices,
    addAllFormReports,
    addAllForms,
    addAllGiftCardSubscriptionDetail,
    addAllInvoices,
    addAllLotterySubscriptionDetail,
    addAllOtherUserQuestions,
    addAllPayments,
    addAllQuestions,
    addAllSubscriptions,
    addAllVouchers,
    addCards,
    addFilter,
    addMyStudents,
    addStudentResults,
    addStudentVideos,
    addTeacherCards,
    addTeacherVideos,
    addUserFaq,
    addUserJoinedGroups,
    addUserPendingGroups,
    addWalletTransaction,
    setRates,
    addMyCashbacks,
    addTeacherFeedbackForm,
    addAllFeedbackForm,
    addAdminFeedbackFormForTeacer,
    addStudentTrainResults,
    addDashboardData,
    addTeacherCoupon,
    addTeacherResponses,
    addTeacherToStudentResponse
}
