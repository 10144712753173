import { Button, Divider } from 'antd'
import React, { useState } from 'react'
// import logo from '../../assets/testallyLogo-removebg.png'
import { AppstoreOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { AiFillDislike, AiFillLike } from 'react-icons/ai'
import { RxExit } from 'react-icons/rx'
import { useSelector } from 'react-redux'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import logo from '../../assets/testallyLogo-removebg.png'
import { allPaths } from '../../utils/constants'
import { paraText } from '../../utils/helpers'
import OverviewBoxes from './OverviewBoxes'
import OverviewDetail from './OverviewDetail'
import ParagraphOverview from './ParagraphOverview'

const TrainOverview = (props) => {
    const { location, history } = props
    const { chResults, paragraphResults, chPoints, formData, allQuestions } = location?.state || {}
    const [overviewQuestion, setOverviewQuestion] = useState({})
    const [paraOverview, setParaOverview] = useState({})
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const user = useSelector(state => state.authReducer.user)
    const matteColors = ['DarkOrange', 'DarkOliveGreen', 'LightSlateGray', 'RosyBrown', 'DarkSlateGray', 'GoldenRod', 'MidnightBlue', 'SaddleBrown', 'DarkRed']
    let paraArray = Object.keys(paragraphResults) || []
    let chapArray = Object.keys(chResults) || []
    return (
        <>
            {
                overviewQuestion?._id ?
                    <OverviewDetail goBack={() => setOverviewQuestion({})} selectedLanguage={selectedLanguage} user={user} allQuestions={paraOverview?.paraName ? paraOverview?.questions : allQuestions} selectedQuestion={_.findIndex(paraOverview?.paraName ? paraOverview?.questions : allQuestions, { _id: overviewQuestion?._id })} {...props} />
                    :
                    <div>
                        <div className='header-with-text'>
                            <div>
                                <img src={logo} style={{ height: '30px' }} alt='Logo' />
                            </div>
                            <div className='flex-row'>
                                {paraOverview?.paraName &&
                                    <Button type='primary' style={{ marginRight: 5 }} icon={<AppstoreOutlined />} onClick={() => setParaOverview({})} />}

                                <Button
                                    type='primary'
                                    size='middle'
                                    // type='link'
                                    icon={<RxExit />}
                                    onClick={() => !user?.userType ? history.replace(allPaths?.ADMIN_QUESTION) : user?.userType !== 'guest' ? history.replace(allPaths?.QUESTIONS) : history.replace(allPaths?.RESULTS)} />
                            </div>

                        </div>

                        {paraOverview?.paraName ?
                            <ParagraphOverview isEnglish={language?.language === 'english'} formData={formData} allQuestions={allQuestions} setOverviewQuestion={setOverviewQuestion} paraOverview={paraOverview} user={user} {...props} />
                            :
                            <div className='student-main-div student-part-scroll_class flexDiv'>
                                <div className='overiew1'>
                                    <h2>{location?.state?.passed ? 'Successfully Passed' : 'UNFORTUNATELY FAILED'}</h2>
                                    <p>Below you will find a study advice based on your exam results</p>
                                    {
                                        paraArray?.map((v, i) => {
                                            const randomIndex = Math.floor(Math.random() * matteColors.length)
                                            let newBackColor = matteColors[randomIndex]
                                            let resultCounts = paragraphResults?.[v]
                                            let paraOverviewObj = {
                                                paraName: v,
                                                color: matteColors[i] || newBackColor,
                                                questions: paragraphResults?.[v],
                                                like: (chPoints?.[paragraphResults?.[v]?.[0]?.chapter] >= formData?.limit?.[paragraphResults?.[v]?.[0]?.chapter])
                                            }
                                            return (
                                                <div key={i} style={{ backgroundColor: matteColors[i] || newBackColor }} className='paraOverview' onClick={() => setParaOverview(paraOverviewObj)}>
                                                    <div style={{ marginRight: 10 }} className='centerDiv'>
                                                        <h3 style={{ color: 'white', marginBottom: 0 }}>{resultCounts?.filter((x) => x?.correct)?.length} / {resultCounts?.length}</h3>
                                                    </div>
                                                    <div style={{ margin: 'auto 0' }}>
                                                        <h4 style={{ marginBottom: 0, color: 'white' }}>{v}</h4>
                                                        <p style={{ marginBottom: 0, color: 'white', fontSize: 11 }}>{paraText(paraOverviewObj?.questions || [])}</p>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className='overiew2'>
                                    <h2>REVIEW ANSWERS</h2>
                                    <p>Below you will find the results for each section. By selecting a box you get an extensive motivation</p>
                                    {
                                        chapArray?.map((v, i) => {
                                            console.log('vvvvvv',chPoints, formData)
                                            return (
                                                <div key={i}>
                                                    <Divider />
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ marginRight: 10 }}>
                                                            {
                                                                (chPoints?.[v] >= formData?.limit?.[v]) ?
                                                                    <AiFillLike fontSize={40} color='#388E3C' />
                                                                    :
                                                                    <AiFillDislike fontSize={40} color='#DC4C35' />
                                                            }
                                                        </div>
                                                        <div className='centerDiv' >
                                                            <h3 style={{ marginBottom: 0 }}>{v}</h3>
                                                        </div>
                                                    </div>

                                                    <OverviewBoxes questions={chResults?.[v] || []} setQuestion={setOverviewQuestion} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='test-footer'>
                                </div>
                            </div>}

                    </div>
            }
        </>
    )
}

export default TrainOverview
