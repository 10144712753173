import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { ADMIN } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { errorMessage, successNotification } from '../../utils/helpers'

import LoginLanguageSelect from '../../Components/LoginLanguageSelect/LoginLanguageSelect'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'

const Login = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {
        const { history } = props
        setLoading(true)
        axios.post(ADMIN.LOGIN, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                console.log('data', data)
                if (data.success) {
                    // successNotification(selectedLanguage?.SuccessfullyLoggedIn)
                    dispatch(loginUser(data.admin))
                    return setTimeout(() => {
                        history.replace(allPaths?.ADMIN_USERS)
                    }, 300)
                }
                console.log('error', data)
                errorMessage(isEnglish ? data?.message : data?.messageNl)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <>
            <div className='card-parent background-layout'>
                <LoginLanguageSelect bg={true} />
                
                <div className='card1'style={{marginTop:'100px'}} >
                    <p className='heading head-center'>{selectedLanguage?.AdminPortal}</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='userName'
                            label={selectedLanguage?.UserName}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourUserName
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={selectedLanguage?.InputyourUserNameHere}
                            />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            label={selectedLanguage?.Password}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourPassword
                                },
                                {
                                    min: 6,
                                    message: selectedLanguage?.PasswordMustBeAtLeastCharacters
                                }
                            ]}
                        >
                            <Input.Password
                                className='form-input'
                                placeholder={selectedLanguage?.InputYourPasswordHere}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                block
                                size='large'
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.Login}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Login