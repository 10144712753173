import React from "react";
import BuResult from "../../Screens/Results/BuResults";

const TeacherResult = (props) => {
    const { user } = props
    return (
        <>
            <BuResult {...props} />
        </>
    )
}
export default TeacherResult