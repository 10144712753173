import { Button, Checkbox, Col, Divider, Input, Row } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../../utils/apis'
import { checkDurationType, generalData, successNotification } from '../../../../utils/helpers'

const CbrPriceSettings = (props) => {
    const { selectedLanguage, generalActions, subscriptionList } = props
    const [loading, setLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState(subscriptionList || [])
    useEffect(() => {
        generalData.getAllSubscriptions(generalActions)
    }, [])
    useEffect(() => {
        setSubscriptions(subscriptionList || [])
    }, [subscriptionList])
    const updateSubscription = async () => {
        setLoading(true)
        let allPromise = []
        for (var v of subscriptions) {
            allPromise.push(Promise.resolve(axios.post(ADMIN.UPDATE_SUBSCRIPTION, v)))
        }
        await Promise.all(allPromise)
            .then(() => {
                setLoading(false)
                generalData.getAllSubscriptions(generalActions)
                successNotification(selectedLanguage?.SuccssfullyUpdated)
            })
    }

    const updateMonthlyCouponState = (i, k, v, arr) => {
        if (v < 0) {
            return
        }
        arr[i][k] = v

        let otherSub = subscriptions.filter((x) => !x?.monthlyCoupon)
        setSubscriptions([...(arr), ...otherSub] || [])
    }
    const updateState = (i, k, v, arr, userType) => {
        if (v < 0) {
            return
        }
        arr[i][k] = v
        let otherSub = subscriptions.filter((x) => x?.monthlyCoupon || x?.userType != userType)
        setSubscriptions([...(arr), ...otherSub] || [])
    }
    return (
        <div style={{ paddingBottom: 40 }}>
            <h2>Coupons</h2>

            {
                subscriptions.filter((v) => v?.monthlyCoupon)?.map((v, i, arr) => {
                    return (
                        <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                            <Col span={24}>
                                <Input
                                    style={{ width: 50 }}
                                    type='number'
                                    value={v?.numOfItems}
                                    min={0}
                                    onChange={e => updateMonthlyCouponState(i, 'numOfItems', e?.target?.value, arr)}
                                />
                                <span>
                                    &nbsp;{v?.name}&nbsp;({v?.contentType})&nbsp;
                                </span>
                                <Input
                                    style={{ width: 50 }}
                                    type='number'
                                    value={v?.subscriptionItems}
                                    min={0}
                                    onChange={e => updateMonthlyCouponState(i, 'subscriptionItems', e?.target?.value, arr)}
                                />
                                <span>
                                    &nbsp;{checkDurationType(v)/*  v?.contentType === 'basic' ? 'Exams' : 'Days' */}
                                </span>
                            </Col>
                        </Row>
                    )
                })
            }
            <Divider />

            <h2>Teacher</h2>

            {subscriptions.filter((v) => !v?.monthlyCoupon && v?.userType === 'business')?.map((v, i, arr) => {
                if(!v?.coupon){
                    return (
                        <Row gutter={16} key={i} style={{ marginBottom: 20 }} title='Abc'>
                            <Col span={24}>
                                <span>
                                    <Checkbox checked={v?.activate} onChange={e => updateState(i, 'activate', e?.target?.checked, arr, v?.userType)} />
                                    &nbsp;{v?.name}&nbsp;&nbsp;&nbsp;{v?.duration.toString() + ' ' + v?.durationType}&nbsp;&nbsp;&nbsp;
                                </span>
    
                                <Input
                                    type='number'
                                    className='subs-input'
                                    value={v?.amount}
                                    min={0}
                                    onChange={e => updateState(i, 'amount', e?.target?.value, arr, v?.userType)}
                                    addonAfter='€'
                                />
                            </Col>
                        </Row>
                    )
                }else{
                    return (
                        <Row gutter={16} key={i} style={{ marginBottom: 20 }} title='Abc'>
                            <Col span={24}>
                                <span>
                                    &nbsp;{v?.name} ({v?.contentType})&nbsp;&nbsp;
                                </span>
    
                                <Input
                                    type='number'
                                    className='subs-input'
                                    value={v?.subscriptionItems}
                                    min={0}
                                    onChange={e => updateState(i, 'subscriptionItems', e?.target?.value, arr, v?.userType)}
                                    addonAfter={checkDurationType(v)}
                                />
                                &nbsp;&nbsp;
                                <Input
                                    type='number'
                                    className='subs-input'
                                    value={v?.amount}
                                    min={0}
                                    onChange={e => updateState(i, 'amount', e?.target?.value, arr, v?.userType)}
                                    addonAfter='€'
                                />
                            </Col>
                        </Row>
                    )

                }
            })}
            <Divider />
            <h2>Student</h2>
            {subscriptions.filter((v) => !v?.monthlyCoupon && v?.userType === 'guest')?.map((v, i, arr) => {
                return (
                    <Row gutter={16} key={i} style={{ marginBottom: 20 }} title='Abc'>
                        <Col span={24}>
                            <span>
                                <Checkbox checked={v?.activate} onChange={e => updateState(i, 'activate', e?.target?.checked, arr, v?.userType)} />
                                &nbsp;{v?.name}&nbsp;&nbsp;&nbsp;{v?.duration.toString() + ' ' + checkDurationType(v)}&nbsp;&nbsp;&nbsp;
                            </span>

                            <Input
                                type='number'
                                className='subs-input'
                                value={v?.amount}
                                min={0}
                                onChange={e => updateState(i, 'amount', e?.target?.value, arr, v?.userType)}
                                addonAfter='€'
                            />
                        </Col>
                    </Row>
                )
            })}

            {subscriptions?.length ? <Button
                onClick={updateSubscription}
                className='form-button'
                type='primary'
                loading={loading}
            >
                {selectedLanguage?.Update}
            </Button> : null}
            {/* </div> */}
            {/* </div> */}
        </div >
    )
}

export default CbrPriceSettings