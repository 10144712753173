import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkSelectOption, errorMessage, successMessage, teacherData } from '../../../utils/helpers';
import TheoryCharts from '../../Student/Dashboard/TheoryCharts';
import ResultChart from '../../Student/Dashboard/ResultChart';
import TraningChart from '../../Student/Dashboard/TraningChart';
import ExamChart from '../../Student/Dashboard/ExamChart';
import StudentsVideoChart from '../../Student/Dashboard/VideoChart';
import { Select } from 'antd';
import axios from 'axios';
import { GET } from '../../../utils/apis';

const NewTeacherDashboard = (props) => {
    const { selectedLanguage, userActions, user, lanKorCondition } = props
    const [cardData, setCardData] = useState({ scoreOptions: 'opened', teacher: 'all' })
    const Students = useSelector(state => state?.userReducer?.myStudents || [])
    let myStudents = Students?.filter(item => item?.showDashboard)
    const [selectedStudent, setselectedStudent] = useState(myStudents?.[0]?._id || null)
    const [loading, setLoading] = useState(false)
    let [dashboardData, setDashboardData] = useState(null)
    const { Option } = Select

    console.log(dashboardData)
    useEffect(() => {
        teacherData.getMyStudents(userActions, user?._id)
    }, [])

    useEffect(() => {
        getUserDashboardData(selectedStudent)
    }, [selectedStudent])

    const getUserDashboardData = (_id) => {
        setDashboardData(null)
        setLoading(true)
        axios?.get(`${GET?.GET_STUDENT_DASHBOARD_DATA}/${_id}`)
            .then((res) => {
                let { data } = res
                if (data?.data) {
                    setLoading(false)
                    setDashboardData(data?.data || null)
                } else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    return (
        <div>
            <h3 className='new-exam-title' style={{ paddingTop: '2px' }}>{selectedLanguage?.txt_Dashboard}</h3>
            <br />
            <div className='flex-mobile' style={{ display: 'flex', width: '98%', margin: '0px auto' }}>
                <Select
                    style={{ width: 350 }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedStudent}
                    onChange={(e) => setselectedStudent(e)}
                    className='form-input'
                    placeholder={selectedLanguage?.SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                    loading={loading}
                >
                    {myStudents?.map((v, i) => <Option value={v?._id} key={i}>{v?.fullName}</Option>)}
                </Select>
            </div>
            <div style={{ paddingTop: '20px' }}>
                <div className='dashboard-main-div'>
                    <div className='dashboard-inner-div-same-page'>
                        <h1 className='dashboard-card-title'>{selectedLanguage?.txt_theroy}</h1>
                        <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                            <TheoryCharts loading={loading} data={dashboardData?.cardData || []} text={selectedLanguage?.txt_txtCards} {...props} />
                        </div>
                        <br />
                        <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                            <TheoryCharts loading={loading} data={dashboardData?.videoData || []} text={selectedLanguage?.txtx_Video} {...props} />
                        </div>
                    </div>
                    <div className='dashboard-inner-div-same-page'>
                        <h1 className='dashboard-card-title'>{selectedLanguage?.txt_Traning}</h1>
                        <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                            <TraningChart loading={loading} data={dashboardData?.trainingData || {}} text={selectedLanguage?.txt_Train} {...props} />
                        </div>
                        <br />
                        <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                            <TraningChart loading={loading} data={dashboardData?.test10Data || {}} text={selectedLanguage?.txt_Test10} {...props} />
                        </div>
                    </div>
                    <div className='dashboard-inner-div-same-page'>
                        <h1 className='dashboard-card-title'>{selectedLanguage?.txt_Exam}</h1>
                        <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                            <ExamChart loading={loading} data={dashboardData?.examData || []} text={selectedLanguage?.txt_Exams} {...props} />
                        </div>
                        <br />
                        <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                            <ResultChart loading={loading} data={dashboardData?.resultData || []} {...props} />
                            {/* <StudentsVideoChart loading={loading} data={dashboardData?.resultData || []} {...props} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewTeacherDashboard