import { Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState, } from 'react'
import { teacherData } from '../../../utils/helpers'
import NewAddCashback from './AddCashback'
import { useSelector } from 'react-redux'

const NewTeacherCashback = (props) => {
    const { user, userActions, selectedLanguage, isEnglish } = props
    const [loading, setLoading] = useState(false)
    const myCashbacks = useSelector(state => state?.userReducer?.myCashbacks || [])

    useEffect(() => {
        teacherData.getMyCashbacks(userActions, user?._id)
    }, [])

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_FormId}</p>,
            dataIndex: '_id',
            ellipsis: true
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Amount}</p>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> € {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Status}</p>,
            key: 'isPaid',
            dataIndex: 'isPaid',
            sorter: (a, b) => a.isPaid - b.isPaid,
            ellipsis: true,
            render: (e) => <span>{e ? selectedLanguage?.Approved : selectedLanguage?.Pending}</span>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Created}</p>,
            key: 'created',
            dataIndex: 'created',
            ellipsis: true,
            render: (e) => <span>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</span>
        },

    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>

            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_CASHBACKREQUESTS}</h3>
                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <NewAddCashback {...props} />
                </span>
                <div className='new-layout-table-upper-div'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        loading={loading}
                        dataSource={myCashbacks}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default NewTeacherCashback