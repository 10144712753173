import React, { useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { useSelector } from 'react-redux'
import { getFaq } from '../../utils/helpers'

const Faq = (props) => {
    const { selectedLanguage, user, language, userActions } = props
    const isEnglish = language?.language === 'english'
    const faqsObj = useSelector(state => state?.userReducer?.userFaq)
    const [reading, setReading] = useState('')
    const [readingFaq, setReadingFaq] = useState('')

    let FaqGroup = faqsObj?.[1] || {}
    const keys = Object.keys(FaqGroup);

    useEffect(() => {
        getFaq(userActions)
    }, [])

    return (
        <div style={{ marginBottom: '10px' }}>
            <div>
                <div className='main-div-new-style'>
                    <div className='div-flex-back-name'>
                        <div className='height-35'>
                            <p className='new-screen-heading'>{selectedLanguage?.txt_Howitswork}</p>
                        </div>
                    </div>


                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                        {faqsObj?.[0]?.length ?
                            faqsObj?.[0]?.map((v, i) => {
                                return (
                                    (v?.catagrie?.includes(user?.userType) || v?.catagrie?.includes(user?.ownerType)) &&
                                    <div
                                        key={i}
                                        style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                            borderRadius: '8px',
                                            paddingTop: '30px'
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.questionEng : v?.questionNl}</p>
                                            {reading !== v?._id ?
                                                <IoIosArrowDown color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                    setReading(v?._id)
                                                }} /> :
                                                <IoIosArrowUp color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                    setReading('')
                                                }} />}
                                        </div>
                                        {reading === v?._id ?
                                            <div>
                                                <br />
                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.answerEng : v?.answerNl}</p>
                                            </div> : null}
                                    </div>
                                )
                            })
                            : null
                        }
                    </div>

                    <>
                        <div className='div-flex-back-name'>
                            <div className='height-35'>
                                <p className='new-screen-heading'>{selectedLanguage?.txt_FAQs}</p>
                            </div>
                        </div>
                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {keys?.length ? keys?.map((v, i) => {
                                let faqArr = FaqGroup?.[v]
                                return (
                                    <>
                                        {faqArr?.filter((vv) =>
                                            vv?.catagrie?.includes(user?.userType) || vv?.catagrie?.includes(user?.ownerType)
                                        )?.length ?
                                            <div className='height-35' key={i + 2}>
                                                <p className='new-screen-heading' style={{ textAlign: 'center' }}>{isEnglish ? faqArr[0].titleEng : faqArr[0].titleNl}</p>
                                            </div> : null}
                                        {faqArr?.filter((vv) =>
                                            vv?.catagrie?.includes(user?.userType) || vv?.catagrie?.includes(user?.ownerType)
                                        )?.map((vv, ii) => {
                                            return (
                                                <>
                                                    <div
                                                        key={ii + 1}
                                                        style={{
                                                            padding: '20px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            marginBottom: '20px',
                                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                            borderRadius: '8px',
                                                            paddingTop: '30px'
                                                        }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.questionEng : vv?.questionNl}</p>
                                                            {readingFaq !== vv?._id ?
                                                                <IoIosArrowDown color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                    setReadingFaq(vv?._id)
                                                                }} /> :
                                                                <IoIosArrowUp color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                    setReadingFaq('')
                                                                }} />}
                                                        </div>
                                                        {readingFaq === vv?._id ?
                                                            <div>
                                                                <br />
                                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.answerEng : vv?.answerEng}</p>
                                                            </div> : null}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>

                                )
                            }) :
                                <div className='nodata-center-text'>
                                    <p>No Faqs added yet!</p>
                                </div>}
                        </div>
                    </>
                </div>
            </div >
        </div >
    )
}

export default Faq