import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { GET } from '../../utils/apis'

const VerifyEmail = (props) => {
    const [state, setState] = useState({
        verify: false,
        message: '',
        success: false
    })

    const updateState = (obj) => {
        setState({ ...state, ...obj })
    }
    useEffect(() => {
        const { match } = props
        const { token } = match.params
        axios.get(`${GET.VERIFY_EMAIL}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    updateState({ message: data.message, success: data.success })
                }
                else {
                    updateState({ message: data.message, success: data.success })
                }
            })
            .catch(e => {
                updateState({ message: 'Something went wrong please refresh this page', success: false })
            })
    }, [])

    return (
        <div className='verify-div'>
            {state.message.length ?
                <div className='verify-child'>
                    <p className='verify-head'>Email Verification</p>
                    <p className='verify-text'>{state.message}</p>
                    {state.success && <p className='verify-text'>You may now <Link to='/'>Login</Link>.</p>}
                </div> : <Skeleton paragraph={{ rows: 22 }} />}
        </div>
    )
}

export default VerifyEmail
