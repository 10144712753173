import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { generalData } from '../../../../utils/helpers'
import CbrPriceSettings from './CbrPriceSettings'
import TestallyPriceSettings from './TestallyPriceSettings'

const { TabPane } = Tabs

const AdminPriceSettings = (props) => {
    const { selectedLanguage, generalActions } = props
    const subscriptionList = useSelector(state => state?.generalReducer?.subscriptions || [])
    const cbrSubscriptions = subscriptionList.filter((v) => v?.cbr)
    const testallySubscriptions = subscriptionList.filter((v) => !v?.cbr)
    useEffect(() => {
        generalData.getAllSubscriptions(generalActions)
    }, [])

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{selectedLanguage?.PricesSettings}</p>
                    <div className='main-div-new-style'>
                        <Tabs className='new-new-my-tabs' defaultActiveKey='1' centered>
                            <TabPane tab={<p className='my-menu-new-tab-p'>{'Testally'}</p>} key='1'>
                                <TestallyPriceSettings subscriptionList={testallySubscriptions} {...props} />
                            </TabPane>
                            <TabPane tab={<p className='my-menu-new-tab-p'>{'CBR'}</p>} key='2'>
                                <CbrPriceSettings subscriptionList={cbrSubscriptions} {...props} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AdminPriceSettings