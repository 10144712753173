import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { allPaths } from '../../utils/constants'
import NewFooter from '../Footers/Footer'
import NewHeader from '../Headers/Header'

import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { setRates } from '../../Redux/actions/adminActions'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { addAllSubscription, addAllTutions, setAllChapters, setAllLanguages, setAllSubjects, setAllTeachers, setCities, setCollapsed, setTaxes } from '../../Redux/actions/generalActions'
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import { addAdminFeedbackFormForTeacer, addAllBuInvoices, addAllFeedbackForm, addAllFormReports, addAllForms, addAllGiftCardSubscriptionDetail, addAllInvoices, addAllLotterySubscriptionDetail, addAllOtherUserQuestions, addAllQuestions, addAllSubscriptions, addCards, addDashboardData, addMyCashbacks, addMyStudents, addStudentResults, addStudentTrainResults, addStudentVideos, addTeacherCards, addTeacherCoupon, addTeacherFeedbackForm, addTeacherResponses, addTeacherToStudentResponse, addTeacherVideos, addUserFaq, addUserJoinedGroups, addUserPendingGroups, addWalletTransaction } from '../../Redux/actions/userActions'

import { GET } from '../../utils/apis'
import { generalData, studentData, teacherData } from '../../utils/helpers'

const NewLayout = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const language = useSelector(state => state?.languageReducer)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)
    const MenuNone = useSelector(state => state.menuNoneReducer.menuNone)
    let subscriptions = useSelector(state => state.generalReducer.subscriptions) || []
    subscriptions = subscriptions.filter((v) => !v?.cbr && v?.activate)
    const [updateWallet, setUpdateWallet] = useState(false)
    const filter = useSelector(state => state.userReducer.filter)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const lanKorCondition = language?.language === 'english'
    console.log('user-->', user)
    useEffect(() => {
        setUpdateWallet(true)
        dispatch(SetMenuNone(false))
        if (user?.ownerType) {
            teacherData.all(userActions, user?._id)
        } else {
            studentData.all(userActions, user?._id)
        }

        generalData.all(generalActions, true)
    }, [])

    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }

    const userActions = {
        addTeacherCards: (e) => dispatch(addTeacherCards(e)),
        addMyCashbacks: (e) => dispatch(addMyCashbacks(e)),

        addAllInvoices: (e) => dispatch(addAllInvoices(e)),
        // addAllVouchers: (e) => dispatch(addAllVouchers(e)),
        // addAllPayments: (e) => dispatch(addAllPayments(e)),
        addAllBuInvoices: (e) => dispatch(addAllBuInvoices(e)),
        addAllSubscriptions: (e) => dispatch(addAllSubscriptions(e)),
        setRates: (e) => dispatch(setRates(e)),
        addUserJoinedGroups: (e) => dispatch(addUserJoinedGroups(e)),
        addUserPendingGroups: (e) => dispatch(addUserPendingGroups(e)),
        addAllLotterySubscriptionDetail: (e) => dispatch(addAllLotterySubscriptionDetail(e)),
        addAllGiftCardSubscriptionDetail: (e) => dispatch(addAllGiftCardSubscriptionDetail(e)),
        addAllForms: (e) => dispatch(addAllForms(e)),
        addAllFormReports: (e) => dispatch(addAllFormReports(e)),
        addAllQuestions: (e) => dispatch(addAllQuestions(e)),
        addAllOtherUserQuestions: (e) => dispatch(addAllOtherUserQuestions(e)),
        addUserFaq: (e) => dispatch(addUserFaq(e)),
        addWalletTransaction: (e) => dispatch(addWalletTransaction(e)),
        addStudentResults: (e) => dispatch(addStudentResults(e)),
        addMyStudents: (e) => dispatch(addMyStudents(e)),
        addCards: (e) => dispatch(addCards(e)),
        addTeacherVideos: (e) => dispatch(addTeacherVideos(e)),
        addStudentVideos: (e) => dispatch(addStudentVideos(e)),
        addTeacherFeedbackForm: (e) => dispatch(addTeacherFeedbackForm(e)),
        addAllFeedbackForm: (e) => dispatch(addAllFeedbackForm(e)),
        addAdminFeedbackFormForTeacer: (e) => dispatch(addAdminFeedbackFormForTeacer(e)),
        addStudentTrainResults: (e) => dispatch(addStudentTrainResults(e)),
        addDashboardData: (e) => dispatch(addDashboardData(e)),
        addTeacherCoupon: (e) => dispatch(addTeacherCoupon(e)),
        addTeacherResponses: (e) => dispatch(addTeacherResponses(e)),
        addTeacherToStudentResponse: (e) => dispatch(addTeacherToStudentResponse(e)),

    }

    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e)),
        setCities: (e) => dispatch(setCities(e)),
        setTaxes: (e) => dispatch(setTaxes(e)),
        setAllTeachers: (e) => dispatch(setAllTeachers(e)),
        setAllChapters: (e) => dispatch(setAllChapters(e)),
        addAllTutions: (e) => dispatch(addAllTutions(e)),
        setAllLanguages: (e) => dispatch(setAllLanguages(e)),
        setAllSubjects: (e) => dispatch(setAllSubjects(e)),
        addAllSubscription: (e) => dispatch(addAllSubscription(e))
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.HOME} />
        )
    }

    return (
        <Route {...rest} render={props => <AddMenu {...props}
            lanKorCondition={lanKorCondition}
            selectedLanguage={selectedLanguage}
            language={language}
            filter={filter}
            component={Component}
            user={user}
            authActions={authActions}
            MenuNone={MenuNone}
            inlineCollapsed={inlineCollapsed}
            dispatch={dispatch}
            generalActions={generalActions}
            userActions={userActions}
            subscriptions={subscriptions}
            updateWallet={updateWallet}
            setUpdateWallet={setUpdateWallet} />
        } />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, selectedLanguage } = props
    const history = useHistory()
    const dispatch = useDispatch()

    if (!user) {
        return (
            <Redirect to={allPaths?.LOGIN} />
        )
    }
    const getUser = () => {
        axios.get(`${GET?.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
                else {
                    history?.replace(allPaths?.LOGIN)
                    dispatch(removeUser())
                }
            })
            .catch((e) => {
                dispatch(removeUser())
            })
    }


    return (
        <div className='new-main-layout-div '>
            <div style={{ height: '100vh', overflow: 'auto' }} className='new-layout-scroll-class'>
                <NewHeader user={user} selectedLanguage={selectedLanguage} {...props} />
                <div className='new-main-component-screen-div'>
                    <Component user={user} history={history} getUser={getUser} {...props} />
                </div>
                <NewFooter history={history} selectedLanguage={selectedLanguage} user={user} />
            </div>
        </div>
    )
}

export default NewLayout