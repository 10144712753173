import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Popover, Select, Spin, } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Nodataimage from '../../../../src/assets/NewImages/Nodata.gif'
import { POST } from '../../../utils/apis'
import { allPaths, contentType } from '../../../utils/constants'
import { calculateReviewAverage, checkLuxurySub, checkSelectOption, checkSubscription, errorMessage, studentData, toBase64 } from '../../../utils/helpers'
import CardsReviewScreen from '../Review/StudentCardsReview'
import PreviewCardModal from './CardPreview'
import { MdOutlinePreview } from 'react-icons/md';

const { Option } = Select

const NewStudentCards = (props) => {
    const { selectedLanguage, user, history, userActions } = props
    let cardsData = useSelector(state => state.userReducer?.studentCards)
    const [data, setData] = useState(null)
    const [previewData, setPreviewData] = useState(null)
    const [spin, setSpin] = useState({})
    const [showDetails, setShowDetails] = useState({})
    const [cardDetailForReview, setCardDetailForReview] = useState(null)
    
    useEffect(() => {
        if (!checkLuxurySub(user?.subscription)) {
            history.push(allPaths.GUEST_HOME)
            return errorMessage(selectedLanguage?.txt_PleaseBuysubscriptionforthisFeature)
        } else {
            studentData.getStudentCards(userActions, user?._id)
        }
    }, [])

    useEffect(() => {
        cardsData = cardsData?.filter(item => item?.subject?.toLowerCase() !== 'cbr');
        setData(cardsData)
    }, [cardsData])

    const onChnage = (e) => {
        let filteredArray = cardsData.filter(item => item.title === e);
        setData(filteredArray)
    }

    const getTrainQuestions = (e) => {
        setSpin({ [e?._id]: true })
        let obj = JSON.parse(JSON.stringify(e))
        obj.userId = obj?.user?._id || null
        obj.student = user?._id
        axios.post(`${POST.GET_CARDS_TRAINING_QUESTIONS}`, obj)
            .then(async (res) => {
                const { data } = res
                setSpin({})
                if (data.success) {
                    if (data?.data?.length) {
                        let newQuestions = JSON.parse(JSON.stringify(data?.data))

                        for (let v of newQuestions) {
                            let dragFile = v?.dragFile
                            let answerList = v?.answerList
                            if (dragFile?.url) {
                                dragFile.baseUrl = await toBase64(dragFile?.url)
                            }

                            if (v?.file?.url) {
                                v.file.baseUrl = await toBase64(v?.file?.url)
                            }

                            for (let x of answerList) {
                                if (x?.file?.url) {
                                    x.file.baseUrl = await toBase64(x?.file?.url)
                                }
                            }
                        }
                        history.push({
                            pathname: allPaths?.TOPIC_TRAINING,
                            state: {
                                trainQuestions: newQuestions,
                                topics: e?.topics,
                                card: e?._id
                            }
                        })
                    } else {
                        errorMessage(selectedLanguage?.txt_NoQuestionsavailable)
                    }
                }
            })
            .catch((e) => {
                setSpin({})
                console.log('error', e);
                errorMessage(selectedLanguage?.OopsSomethingWentWrong)
            })
    }

    const handleOk = () => {
        setShowDetails({})
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>
            <div className='new-exam-screen-upper'>
                {!cardDetailForReview?._id ?
                    <div>
                        <h3 className='new-exam-title'>{selectedLanguage?.txt_Cards}</h3>
                        <div className='flex-mobile' style={{ display: 'flex', width: '98%', margin: '10px auto' }}>
                            <Select
                                style={{ maxWidth: '98%', width: 350 }}
                                showSearch
                                showArrow
                                allowClear={false}
                                onChange={onChnage}
                                className='form-input'
                                placeholder={selectedLanguage?.txt_SearchTitle}
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {cardsData?.map((v, i) => {
                                    return (
                                        <>
                                            {(v?.subject?.toLowerCase() !== 'cbr') &&
                                                <Option value={v?.title} key={i} >{v?.title}</Option>
                                            }
                                        </>)
                                })}
                            </Select>
                        </div>

                        <Modal
                            open={showDetails?._id}
                            onOk={handleOk}
                            onCancel={handleOk}
                            className='pop-info-modal'
                            style={{ top: '10px' }}
                            footer={null}>
                            <div>
                                <p className='new-p-question'>{selectedLanguage?.Details}</p>
                                <ul style={{ padding: '20px' }}>
                                    <li>{selectedLanguage?.label_Chapter}: {showDetails?.chapter} </li>
                                    <li>{selectedLanguage?.label_Paragraph}: {showDetails?.paragraph}</li>
                                    <li>{selectedLanguage?.label_Topics}: {showDetails?.topics?.join(', ') || ''}</li>
                                    <li>{selectedLanguage?.title_Creater}: {showDetails?.user?.fullName || selectedLanguage?.title_Admin}</li>
                                </ul>
                            </div>
                        </Modal>

                        <div className='three-boxes-card-div'>
                            {data?.length ?
                                data?.map((v, i) => {
                                    return (
                                        <div key={i} className='ex-tion-card'
                                            style={{
                                                height: '380px',
                                                background: '#1eb2a6'
                                            }}>
                                            <span className='span-of-btn2'>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <InfoCircleOutlined onClick={() => setShowDetails(v)} className='info_font' />
                                                    &nbsp;
                                                    <MdOutlinePreview
                                                        onClick={() => setPreviewData(v)}
                                                        className='info_font2'
                                                    />
                                                </div>

                                                <div>
                                                    <Popover content={
                                                        <>
                                                            {!v?.isReviewHide ?
                                                                v?.review?.length ?
                                                                    calculateReviewAverage(v?.review) < 4.5 ?
                                                                        selectedLanguage?.Bad + ',' :
                                                                        calculateReviewAverage(v?.review) < 5.9 ?
                                                                            selectedLanguage?.Poor + ',' :
                                                                            calculateReviewAverage(v?.review) < 6.9 ?
                                                                                selectedLanguage?.Adeqate + ',' :
                                                                                calculateReviewAverage(v?.review) < 8.9 ?
                                                                                    selectedLanguage?.Good + ',' :
                                                                                    selectedLanguage?.Excellent + ',' : null : null}
                                                            {!v?.isReviewHide ?
                                                                v?.review?.length ?
                                                                    v?.review?.length !== 1 ?
                                                                        v?.review?.length > 999 ?
                                                                            ' ' +
                                                                            `${v?.review?.length?.slice}`(0, 1) +
                                                                            '.' +
                                                                            `${v?.review?.length}`?.slice(1, 2) +
                                                                            'k' + ' ' + selectedLanguage?.Reviews :
                                                                            ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                    selectedLanguage?.txt_AddReview :
                                                                selectedLanguage?.txt_AddReview}
                                                        </>
                                                    } trigger='hover'>
                                                        <button onClick={() => setCardDetailForReview(v)} className='card-card-train-btn height-33-33'>
                                                            {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                        </button>
                                                    </Popover>
                                                    &nbsp;
                                                    {spin[v?._id] ? <Spin /> :
                                                        <button onClick={() => getTrainQuestions(v)}
                                                            className='card-card-train-btn height-33-33' style={{ width: '40px' }}>
                                                            {selectedLanguage?.btn_Train}
                                                        </button>
                                                    }
                                                </div>
                                            </span>
                                            <p className='div-tt' style={{ textAlign: 'center' }}>{v?.title}</p>
                                            <div
                                                className='new-layout-scroll-class'
                                                style={{
                                                    height: '280px',
                                                    overflowY: 'auto',
                                                    marginTop: '5px'
                                                }}>
                                                {v?.content?.length && (v?.content?.[0]?.files?.length || v?.content?.[0]?.description) ?
                                                    v?.content?.map((y, ii) => {
                                                        return (
                                                            <>
                                                                {y?.description?.length ? <p key={ii} className='disc'>{y?.description}</p> : null}
                                                                {y?.files?.length ?
                                                                    y?.files?.map((data, index) => {
                                                                        return (
                                                                            <>
                                                                                {data?.perUrl?.length ?
                                                                                    <img key={index}
                                                                                        className='tr-content-img' src={data?.url} /> :
                                                                                    null
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                    :
                                                                    null}
                                                            </>
                                                        )
                                                    }) :
                                                    <center>
                                                        <img src={Nodataimage} style={{
                                                            height: '200px',
                                                            borderRadius: '10px',
                                                            margin: '10px auto'
                                                        }} className='nodata-lottie' />
                                                        <p className='margin0' style={{ textAlign: 'center' }}>{selectedLanguage?.txt_ContentNotAdded}</p>
                                                    </center>
                                                }
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div style={{ width: '100%' }}>
                                    <center>
                                        <img src={Nodataimage} style={{
                                            height: '200px',
                                            borderRadius: '10px',
                                            margin: '10px auto'
                                        }} className='nodata-lottie' />
                                        <p className='margin0' style={{ textAlign: 'center' }}>{selectedLanguage?.txt_cardnotAdded}</p>
                                    </center>
                                </div>
                            }
                        </div>
                        <br />
                        <br />
                    </div>
                    :
                    <CardsReviewScreen type='card' onCancel={() => setCardDetailForReview(null)} data={cardDetailForReview}{...props} />
                }
            </div>
            {
                previewData?._id &&
                <PreviewCardModal data={previewData} onCancel={() => setPreviewData(null)} {...props} />
            }
        </div>
    )
}
export default NewStudentCards