import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Popconfirm, Select, Space, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { AUTH } from '../../utils/apis'
import { ownerType } from '../../utils/constants'
import { adminData, checkSelectOption, convertRoundNumber, convertTitle, errorMessage, successNotification } from '../../utils/helpers'
import UserDetail from './UserDetail'
const { Option } = Select

const Users = (props) => {
    const { user, adminActions, language, selectedLanguage, lanKorCondition } = props
    const [spin, setSpin] = useState({})
    const [userDetail, setuserDetail] = useState({})
    const [showStudents, setShowStudents] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [selectedType, setSelectedType] = useState(ownerType?.[0]?.val || null)
    const searchInput = useRef(null)
    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []
    const gUsers = useSelector(state => state?.adminReducer?.gUsers) || []

    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
        adminData.getGuestUser(adminActions, user?._id)
    }, [])

    const activateUser = (_id, activate, business) => {
        setSpin({ [_id]: true })
        let obj = {
            _id,
            activate
        }
        axios.post(`${AUTH.ACTIVATE_USER}/${user?._id}`, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification(activate ? selectedLanguage?.Activate : selectedLanguage?.Deactivate)
                    if (business) {
                        adminActions?.addAllBusinessUsers(data?.data || [])
                    } else {
                        adminActions?.addAllGuestUsers(data?.data || [])
                    }
                } else {
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(selectedLanguage?.OopsSomethingWentWrong)
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`${selectedLanguage?.Search} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {selectedLanguage?.Search}
                    </Button>
                    <Button type='primary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        {selectedLanguage?.Reset}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    let columns = [
        {
            title: <p className='margin0' style={{ width: '100px' }}>{selectedLanguage?.FullName}</p>,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a?.fullName?.length - b?.fullName?.length,
            width: 180,
            ...getColumnSearchProps('fullName')
        },
        {
            title: selectedLanguage?.Email,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a?.email?.length - b?.email?.length,
            width: 180,
            ...getColumnSearchProps('email')
        },
        {
            title: selectedLanguage?.Type,
            dataIndex: 'isCbr',
            key: 'isCbr',
            sorter: (a, b) => a?.isCbr - b?.isCbr,
            render: (e) => <>{e ? 'CBR' : 'Testally'}</>
        },
        {
            title: selectedLanguage?.TelNo,
            dataIndex: 'telNo',
            key: 'telNo',
            sorter: (a, b) => a?.telNo?.length - b?.telNo?.length,
            ellipsis: true,
        },
        {
            title: selectedLanguage?.City,
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => a?.city?.length - b?.city?.length,
            ellipsis: true,
            align: 'center',
            ...getColumnSearchProps('city')
        },
        {
            title: <p className='margin0' style={{ width: '120px' }}>{selectedLanguage?.title_questionFee}</p>,
            dataIndex: 'questionFee',
            key: 'questionFee',
            align: 'center',
            sorter: (a, b) => a?.questionFee - b?.questionFee,
            render: (e) => <>€ {convertRoundNumber(e)}</>
        },
        {
            title: <p className='margin0' style={{ width: '120px' }}>{selectedLanguage?.txt_QuestionsCount}</p>,
            dataIndex: 'questionsCount',
            key: 'questionsCount',
            align: 'center',
            sorter: (a, b) => a?.questionsCount - b?.questionsCount,
        },
        {
            title: selectedLanguage?.Actions,
            key: 'actions',
            sorter: (a, b) => a?.activate - b?.activate,
            align: 'center',
            width: 180,
            render: (e) =>
                <div style={{ width: '150px' }}>
                    <Button disabled={user?.isPublic} onClick={() => setuserDetail(e)} icon={<InfoCircleOutlined />} shape='circle' />
                    &nbsp;&nbsp;
                    <Popconfirm
                        title={(!e?.activate ? selectedLanguage?.Activate : selectedLanguage?.Deactivate) + ' ' + `${convertTitle(e?.fullName)}?`}
                        okText={selectedLanguage?.Yes}
                        cancelText={selectedLanguage?.No}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => activateUser(e?._id, !e?.activate, true)}
                    >
                        {spin[e?._id] ? <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> : e?.activate ?
                            <Button type='primary' danger>{selectedLanguage?.Deactivate}</Button> :
                            <Button type='primary'>{selectedLanguage?.Activate}</Button>
                        }
                    </Popconfirm >
                </div>
        }
    ]

    let studentsColumns = [
        {
            title: <p className='margin0' style={{ width: '100px' }}>{selectedLanguage?.FullName}</p>,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a?.fullName?.length - b?.fullName?.length,
            width: 180,
            ...getColumnSearchProps('fullName')
        },
        {
            title: selectedLanguage?.Email,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a?.email?.length - b?.email?.length,
            width: 180,
            ...getColumnSearchProps('email')
        },
        {
            title: selectedLanguage?.Type,
            dataIndex: 'isCbr',
            key: 'isCbr',
            sorter: (a, b) => a?.isCbr - b?.isCbr,
            render: (e) => <>{e ? 'CBR' : 'Testally'}</>
        },
        {
            title: selectedLanguage?.TelNo,
            dataIndex: 'telNo',
            key: 'telNo',
            sorter: (a, b) => a?.telNo?.length - b?.telNo?.length,
            ellipsis: true,
        },
        {
            title: selectedLanguage?.City,
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => a?.city?.length - b?.city?.length,
            ellipsis: true,
            align: 'center',
            ...getColumnSearchProps('city')
        },
        {
            title: selectedLanguage?.Actions,
            key: 'actions',
            sorter: (a, b) => a?.activate - b?.activate,
            align: 'center',
            width: 180,
            render: (e) =>
                <>
                    <Popconfirm
                        title={(!e?.activate ? selectedLanguage?.Activate : selectedLanguage?.Deactivate) + ' ' + `${convertTitle(e?.fullName)}?`}
                        okText={selectedLanguage?.Yes}
                        cancelText={selectedLanguage?.No}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => activateUser(e?._id, !e?.activate, false)}
                    >
                        {spin[e?._id] ? <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> : e?.activate ?
                            <Button type='primary' danger>{selectedLanguage?.Deactivate}</Button> :
                            <Button type='primary'>{selectedLanguage?.Activate}</Button>
                        }
                    </Popconfirm >
                </>
        }
    ]

    return (
        <div className='flex-column'>
            {
                userDetail?._id &&
                <UserDetail onCancel={() => setuserDetail({})} userDetail={userDetail} {...props} />
            }

            {!showStudents &&
                <div className='flex-mobile' style={{ display: 'flex', flexDirection: 'column' }}>
                    <br />
                    <Select
                        style={{ maxWidth: '100%', width: '350px' }}
                        showSearch
                        showArrow
                        allowClear={false}
                        defaultValue={selectedType}
                        onChange={(e) => setSelectedType(e)}
                        className='form-input'
                        placeholder={selectedLanguage?.SelectUser}
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {ownerType?.map((v, i) => <Option value={v?.val} key={i}>{`${language?.language === 'english' ? v?.name : v?.nameNl}`}</Option>)}
                    </Select>
                    <br />
                </div>}
            <div className='user-table'>
                <div style={{ textAlign: 'right', width: '100%' }}>
                    <Button
                        type='primary'
                        size='large'
                        onClick={() => setShowStudents(!showStudents)}
                    >
                        {showStudents ? selectedLanguage?.BusinessUser : selectedLanguage?.Button_Students}
                    </Button>
                </div>
                <br />
                <div className='new-layout-table-upper-div' style={{ margin: '0px' }}>
                    <Table
                        className='new-layout-table'
                        columns={showStudents ? studentsColumns : columns}
                        dataSource={showStudents ? gUsers : buUsers?.filter((v) => v?.ownerType === selectedType)}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Users