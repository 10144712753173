import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import axios from 'axios'
import { GET } from '../../../utils/apis'
import { createFeeReport } from '../../../utils/helpers'


let origin = `https://testally.com`

if (process.env.NODE_ENV === 'development') {
    origin = `http://localhost:3000`
}

const NewBuPayments = (props) => {
    const { user, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [feeLoading, setFeeLoading] = useState(false)
    const [showWeeklyReports, setShowWeeklyReports] = useState(false)
    const [payments, setPayments] = useState([])
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        getPayments()
        getQuestionReports()
    }, [])


    const getQuestionReports = () => {

        setFeeLoading(true)

        axios.get(`${GET.GET_QUESTION_FEE_REPORT}/${user?._id}`)
            .then((res) => {
                const { data } = res

                setFeeLoading(false)

                if (data.success) {
                    setReportData(data?.data)
                }
            })
            .catch((e) => {
                setFeeLoading(false)
                console.log('e', e)
            })
    }

    const getPayments = () => {

        axios.get(`${GET.GET_PAYMENTS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setPayments(data?.data)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Question}</p>,
            dataIndex: ['question', 'question'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Title}</p>,
            dataIndex: ['question', 'title'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            dataIndex: 'adminPaid',
            sorter: (a, b) => a?.adminPaid - b?.adminPaid,
            render: (e) => <>{e ? selectedLanguage?.Paid : selectedLanguage?.Unpaid}</>,
            align: 'center'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_questionFee}</p>,
            dataIndex: ['fee'],
        }
    ]

    let reportsColumn = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ReportNumber}</p>,
            dataIndex: ['reportNumber'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            render: (e) => <div>
                <a onClick={() => createFeeReport(e)}>{selectedLanguage?.Download}</a>
            </div>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.Payments}</h3>
                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >

                    {showWeeklyReports ?
                        <Button
                            type='primary'
                            size='large'
                            onClick={() => setShowWeeklyReports(false)}
                        >
                            {selectedLanguage?.Payments}
                        </Button>
                        :
                        <Button
                            type='primary'
                            size='large'
                            onClick={() => setShowWeeklyReports(true)}
                        >
                            {selectedLanguage?.Button_Weekly_Reports}
                        </Button>
                    }
                </span>

                <div className='new-layout-table-upper-div'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        loading={showWeeklyReports ? feeLoading : loading}
                        columns={showWeeklyReports ? reportsColumn : columns}
                        dataSource={showWeeklyReports ? reportData : payments}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: '800px' }}
                    />
                </div>

            </div>
        </div>
    )
}

export default NewBuPayments