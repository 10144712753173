import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import testallyLogo from "../../assets/testallyLogo.png"
import { drawerAdminRoutes, drawerGuestRoutes, drawerRoutes } from '../../utils/constants'
import { setKeyMenu } from '../../utils/helpers'

const { SubMenu } = Menu
const { Sider } = Layout

const SideMenuUpdate = (props) => {
    const dispatch = useDispatch()
    const { location, MenuNone, isAdmin, user, selectedLanguage, language } = props
    const inlineCollapsed = false
    const [key, setKey] = useState(1)
    const [mobile, setMobile] = useState(false)
    const [businessRoutes, setbusinessRoutes] = useState(drawerRoutes || [])
    useEffect(() => {
        window.addEventListener('resize', setSiderMargin)
        setSiderMargin()
    }, [])

    const setSiderMargin = () => {
        window.innerWidth < 700 ? setMobile(true) : setMobile(false)
    }

    const handleClick = (e) => {
        setKey(parseInt(e?.key))
    }
    const isGuest = user?.userType === 'guest'
    const isNlSelect = language?.language === 'netherlands'
    return (
        <div className='home-main'
            style={{
                background: 'white',
            }}
        >
            <div className='flex-row'>
                <div
                    style={{
                        height: '100vh',
                        background: '#ffffff',
                        paddingRight: '4px',
                        position: 'absolute',
                        zIndex: 10,
                        width: 205,
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        display: MenuNone && 'none'

                    }}
                >
                    <Layout className='sidebar-scroll_class' style={{
                        position: 'fixed',
                        zIndex: 9999,
                        height: `calc(100vh - 1px)`,
                        overflowY: 'scroll',
                        paddingBottom: '20px',
                        backgroundColor: 'white',
                    }}>

                        <Sider
                            collapsible
                            collapsed={inlineCollapsed}
                            onCollapse={(value) => { props?.generalActions?.setCollapsed(!props?.inlineCollapsed) }}
                            inlineCollapsed={inlineCollapsed}
                            className='new-side-menu'
                        >
                            {<div className='sideMenuHeader'>
                                <img
                                    className='sideMenuHeader-SidebarLogo'
                                    src={testallyLogo} alt='SidebarLogo' />
                                <div>
                                    <IoIosArrowBack
                                        onClick={() => { dispatch(SetMenuNone(true)) }}
                                        className='backIconHeader' size={32} color="white" />
                                    <p>Testally.com</p>
                                </div>
                            </div>}
                            <Menu
                                onClick={handleClick}
                                defaultOpenKeys={[`${isAdmin ? setKeyMenu(location?.pathname) : setKeyMenu(location?.pathname)}`]}
                                defaultSelectedKeys={[`${location?.pathname}`]}
                                mode='inline'
                                theme='light'
                                className='my-menu-sh-new'
                            >
                                <>
                                    {(isAdmin ? drawerAdminRoutes : isGuest ? drawerGuestRoutes : businessRoutes)?.map((v, i) => {
                                        if (v?.isSubMenu && v?.children?.length) {
                                            return <SubMenu key={v?.route} icon={v?.icon} title={isNlSelect ? v?.titleNl : v?.title} style={{ fontSize: 18 }}>
                                                {v?.children?.map((y, j) => {
                                                    return (
                                                        <Menu.Item key={y?.route} icon={y.icon}>
                                                            <Link
                                                                to={y?.route}
                                                                onClick={window.innerWidth < 701 ? () => { dispatch(SetMenuNone(true)) } : null}
                                                                className='side-list'
                                                            >
                                                                {isNlSelect ? y.titleNl : y.title}
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                })}

                                            </SubMenu>
                                        }
                                        return (
                                            <Menu.Item key={v.route} icon={v?.icon}>
                                                <Link
                                                    to={v?.route}
                                                    onClick={window.innerWidth < 701 ? () => { dispatch(SetMenuNone(true)) } : null}
                                                    className='side-list'
                                                >
                                                    {isNlSelect ? v.titleNl : v.title}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    })}
                                </>
                            </Menu>
                        </Sider>
                    </Layout>
                </div>
            </div >
        </div >
    )
}

export default SideMenuUpdate