import { Input, Modal, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { POST } from "../../utils/apis";
import { errorMessage } from "../../utils/helpers";

const EasyLoadModal = (props) => {
    const { money, user, onCancel, lanKorCondition, selectedLanguage } = props
    const [CouponCodeValue, setCouponCodeValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [showCouponInput, setShowCouponInput] = useState(false)

    const handleCouponCodeChange = (e) => {
        let newCode = e?.target?.value?.replace(/[^A-Za-z0-9]/g, '')
        newCode = newCode?.replace(/(.{3})/g, '$1-')
        newCode = newCode?.replace(/-$/, '')
        setCouponCodeValue(newCode)
    }

    const easyLoad = () => {
        setLoading(true)
        if (showCouponInput && !CouponCodeValue && CouponCodeValue === '') {
            setLoading(false)
            return errorMessage(selectedLanguage?.error_Entercouponcodeplease)
        }
        let obj = {
            userId: user?._id,
            amount: money,
            subscriptionName: 'Wallet Recharge subscription',
            couponUsed: false
        }
        showCouponInput && (obj.couponUsed = true)
        showCouponInput && (obj.couponCode = CouponCodeValue)
        axios.post(POST.WALLET_RECHARGE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    window.location.href = data?.redirectUrl
                }
                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    return (
        <Modal
            open={true}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <h2 className="new-p-question">{selectedLanguage?.txt_Wallet_Recharge_Subscription}</h2>
            <h4>{selectedLanguage?.txt_Are_yousure_to_subscribe_an_easyload_of} € {money} ? </h4>
            <div >
                <p style={{ marginLeft: '4px', cursor: 'pointer' }} className="color-c-Green"
                    onClick={() => {
                        setShowCouponInput(!showCouponInput)
                        setCouponCodeValue('')
                    }}
                >
                    {!showCouponInput ? selectedLanguage?.text_DoYouCanCouponCode : selectedLanguage?.text_idonthavecouponcode}
                </p>

                {showCouponInput &&
                    <>
                        <p>{selectedLanguage?.txt_Enter_Coupon_Code}</p>
                        <Input
                            value={CouponCodeValue}
                            onChange={handleCouponCodeChange}
                            className='form-input-new-style'
                            placeholder={'###-###-###'}
                            maxLength={11}
                        />
                    </>
                }
            </div>
            <div style={{ display: 'flex', marginTop: 10 }}>
                <button
                    style={{
                        height: '40px',
                        width: '50%',
                        background: 'white',
                        color: '#1eb2a6',
                        border: 'none',
                        borderBottomLeftRadius: 10,
                        border: '1px solid #1eb2a6',
                        cursor: 'pointer'
                    }}
                    onClick={onCancel}
                >{selectedLanguage?.btn_Cancel}</button>
                <button
                    style={{
                        height: '40px',
                        width: '50%',
                        background: '#1eb2a6',
                        color: 'white',
                        border: 'none',
                        borderBottomRightRadius: 10,
                        cursor: 'pointer'
                    }}
                    onClick={easyLoad}
                >{loading ? <Spin /> : null}{selectedLanguage?.btn_Subscribe}</button>
            </div>
        </Modal>
    )
}

export default EasyLoadModal