import { DeleteFilled } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, POST } from '../../../utils/apis'
import { errorMessage, generalData, successMessage, teacherData } from '../../../utils/helpers'
import AddTrainVideo from './AddTrainVideo'


const Videos = (props) => {
    const { user, userActions, generalActions, selectedLanguage, lanKorCondition } = props
    const [data, setData] = useState({})
    const [spin, setSpin] = useState({})
    const [displaySpin, setDisplaySpin] = useState({})
    const [showPopup, setShowPopup] = useState(false)
    const subject = useSelector(state => state?.generalReducer?.subjects || [])
    const teacherVideos = useSelector(state => state?.userReducer?.teacherVideos || [])

    useEffect(() => {
        generalData.getAllSubjects(generalActions, user?.verify)
        generalData.getAllChapters(generalActions)
        teacherData.getTeacherVideos(userActions, user?._id)
    }, [])

    const deleteVideo = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_VIDEO, { id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    teacherData.getTeacherVideos(userActions, user?._id)
                    setSpin({})
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    setSpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                return errorMessage(lanKorCondition ? e?.message : e?.messageMl)

            })
    }

    const displayVideo = (videoId, display) => {
        let obj = {
            videoId,
            display,
            id: user?._id
        }
        setDisplaySpin({ [videoId]: true })
        axios.post(POST?.DISPLAY_UNDISPLAY_VIDEOS, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    teacherData.getTeacherVideos(userActions, user?._id)
                    setDisplaySpin({})
                    successMessage(selectedLanguage?.txt_SuccessfullyUpdated)
                }
                else {
                    setDisplaySpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDisplaySpin({})
                return errorMessage(lanKorCondition ? e?.message : e?.messageMl)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Videos}</p>,
            dataIndex: 'file',
            align: 'center',
            render: (e) => {
                return (
                    <>
                        <video width={150} height={100} controls>
                            <source src={e?.url} />
                        </video>
                    </>
                )
            }
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.newtxt_Name}</p>,
            dataIndex: 'name',
            align: 'center',
            sorter: (a, b) => a.name.length - b.name.length,
            render: e => <p className='normalP'>{e}</p>,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Subject}</p>,
            dataIndex: 'subject',
            align: 'center',
            sorter: (a, b) => a.subject.subjectName.length - b.subject.subjectName.length,
            render: e => <p className='normalP'>{e?.subjectName}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.text_Description}</p>,
            dataIndex: 'discription',
            align: 'center',
            sorter: (a, b) => a.discription.length - b.discription.length,
            render: e => <p className='normalP'>{e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tzt_Admin}</p>,
            dataIndex: 'admin',
            align: 'center',
            sorter: (a, b) => a.admin - b.admin,
            render: e => <p className='normalP'>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p' style={{ width: '140px' }}>{selectedLanguage?.txt_Action}</p>,
            align: 'center',
            render: (e) => <>
                {displaySpin[e?._id] ? <Spin /> : !e?.display.includes(user?._id) ?
                    <Button type='primary' onClick={() => displayVideo(e?._id, true)}>{selectedLanguage?.Button_display}</Button>
                    : <Button onClick={() => displayVideo(e?._id, false)}>{selectedLanguage?.Button_Hide}</Button>}
                &nbsp;
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    disabled={e?.admin}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteVideo(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button type="link" disabled={e?.admin} icon={<DeleteFilled />} /* size={size} */ danger />
                    }
                </Popconfirm >
            </>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Videos}</h3>
                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button type='primary' size='large' onClick={() => setShowPopup(true)} >
                        {selectedLanguage?.txt_AddVideo}
                    </Button>
                </span>
                <AddTrainVideo showPopup={showPopup} onCancel={() => setShowPopup(false)} subjects={subject} {...props} />
                <div className='new-layout-table-upper-div'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        dataSource={teacherVideos || []}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>
        </div>
    )
}
export default Videos