import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Image, Modal, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ADMIN } from '../../../utils/apis';
import { adminData, successNotification } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
// import ReactPlayer from 'react-player'

const FormApprovals = (props) => {
    const { onBack, user, selectedLanguage, adminActions } = props
    const formApprovals = useSelector(state => state?.adminReducer?.formApprovals || [])

    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewSelectedData, setViewSelectedData] = useState('')
    useEffect(() => {
        adminData.getAdminFormApprovals(adminActions, user?._id)
    }, [])

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const declineForm = (_id) => {
        setLoading(true)

        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${ADMIN.DECLINE_FORM_APPROVAL}`, valObj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    adminData.getAdminFormApprovals(adminActions, user?._id)
                    successNotification(data?.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('error', e)
            })
    }

    const aproveQuestion = (_id) => {
        setLoading(true)

        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${ADMIN.APPROVE_FORM_APPROVAL}`, valObj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    adminData.getAdminFormApprovals(adminActions, user?._id)
                    successNotification(data?.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('error', e)
            })
    }

    const columns = [
        {
            title: selectedLanguage?.label_FormName,
            dataIndex: 'formName',
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_FormType,
            dataIndex: 'formType',
            render: text => text?.toUpperCase(),
        },
        {
            title: selectedLanguage?.label_QuestionType,
            dataIndex: 'questionsType',
            render: text => text?.toUpperCase(),
        },
        {
            title: selectedLanguage?.label_Totalpoints,
            dataIndex: ['totalPoints'],
            align: 'center'
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_Creater,
            dataIndex: ['userId', 'email'],
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_Approve_Decline,
            key: 'action',
            align: 'center',
            render: (e) => <span>
                <CheckOutlined onClick={() => aproveQuestion(e?._id)} style={{ color: 'green', cursor: 'pointer' }} />&nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title={selectedLanguage?.popup_message_Decline}
                    okText={selectedLanguage?.button_Decline}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => declineForm(e?._id)}
                >
                    <CloseOutlined style={{ color: 'red' }} />
                </Popconfirm>
            </span>
        }
    ]
    return (
        <div>
            <div>
                <div className='button-end button-end-scroll' style={{ marginTop: '10px' }}>
                    <Button
                        type='primary'
                        onClick={onBack}
                    >
                        {selectedLanguage?.Back}
                    </Button>
                </div>
                <div className='user-table'>
                    <Modal title={selectedLanguage?.title_QuestonDetail} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }}>
                        <strong>{selectedLanguage?.title_Title}:</strong> {`${viewSelectedData?.title}`}<br />
                        <strong>{selectedLanguage?.title_Question}:</strong> {`${viewSelectedData?.question}`}<br />
                        <strong>{selectedLanguage?.label_QuestionType}:</strong> {`${viewSelectedData?.questionType}`}<br />
                        <strong>{selectedLanguage?.label_Points}:</strong> {`${viewSelectedData?.point}`}<br />
                        <strong>{selectedLanguage?.title_AnswerType}:</strong> {`${viewSelectedData?.answerType}`}<br />
                        <strong>{selectedLanguage?.title_Answer}:</strong>
                        <ul>
                            {viewSelectedData?.answerList?.map((v, i) => {
                                return (
                                    <>
                                        <li key={i}>{v ? v?.option : selectedLanguage?.title_NoAnswerFound}</li>
                                        <ul>
                                            {v?.explaination ? <li> <strong> {selectedLanguage?.Explaination}: {v?.explaination}</strong></li> : null}
                                            {
                                                v?.file?.mediaType == 'video' ?
                                                    <>
                                                        <video width={200} height={'auto'} controls>
                                                            <source src={v?.file?.url} />
                                                        </video>
                                                    </>
                                                    : v?.file?.mediaType == 'image' ? <Image src={v?.file?.url} width={200} height={'auto'} /> : null
                                            }
                                        </ul>
                                    </>

                                )
                            })}
                        </ul>
                        {
                            viewSelectedData?.questionType == 'test' ?
                                <>
                                    <strong>{selectedLanguage?.title_RightAnswer}:</strong>
                                    <ul>
                                        {viewSelectedData?.answerList?.filter((v) => v?.isTrue).map((v, i) => {
                                            return (
                                                <li key={i}>{v?.option}</li>
                                            )
                                        })}
                                    </ul>
                                </>
                                : null
                        }

                        <strong>{selectedLanguage?.label_Chapter}:</strong> {`${viewSelectedData?.chapter?.chapterName}`}<br />
                        <strong>{selectedLanguage?.label_Paragraph}:</strong> {`${viewSelectedData?.paragraph?.paragraphName}`}<br />
                        <strong>{selectedLanguage?.label_Topics}:</strong> <br />
                        <ul>
                            {viewSelectedData?.topics?.map((v, i) => {
                                return (
                                    <li key={i}>{v ? v.topicName : selectedLanguage?.title_NotopicFound}</li>
                                )
                            })}
                        </ul>
                    </Modal>
                    <div className='new-layout-table-upper-div margin0'>
                        <Table
                            style={{ marginTop: '10px' }}
                            className='new-layout-table'
                            loading={loading}
                            columns={columns}
                            dataSource={formApprovals}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: '1000px' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormApprovals
