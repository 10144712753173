import { DeleteFilled } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Button, Modal, Popconfirm, Space, Spin, Table } from 'antd'
import axios from 'axios'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { TfiMenu } from 'react-icons/tfi'
import { useSelector } from 'react-redux'
import { CreateLeadForm } from '../../../Components'
import { POST } from '../../../utils/apis'
import { allPaths } from '../../../utils/constants'
import { adminData, successNotification } from '../../../utils/helpers'
import AddJsonQuestion from './AddJsonquestion'
import AddQuestions from './AddQuestions'
import CreateAdminForm from './CreateAdminForm'
import Feedback from './Feedback'
import FormApprovals from './FormApprovals'
import QuestionsApproval from './QuestionsApproval'
import Reports from './Reports'
import SpecialQuestion from './SpecialQuestion'
import UsersQuestions from './UsersQuestions'
import ViewAllQuestions from './ViewAllQuestions'

const AdminQuestions = (props) => {
  const { user, userActions, selectedLanguage, adminActions, history } = props
  const forms = useSelector(state => state?.adminReducer?.adminForms || [])
  const [showUsersData, setShowUsersData] = useState(false)
  const [showQuestionApproval, setShowQuestionApproval] = useState(false)
  const [showFormApproval, setShowFormApproval] = useState(false)
  const [showQuestion, setShowQuestion] = useState(false)
  const [allQuestion, setAllQuestion] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [createJsonQuestion, setCreateJsonQuestion] = useState(false)
  const [specialQuestion, setSpecialQuestion] = useState(false)
  const [visible, setVisible] = useState(false)
  const [link, setLink] = useState(null)
  const [edit, setEdit] = useState({})
  const [copyQuestion, setCopyQuestion] = useState({})
  const [copyForm, setCopyForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState(false)
  const [spin, setSpin] = useState({})
  const [showLeadForm, setShowLeadForm] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    adminData.getAdminForms(adminActions, user?._id)
  }, [])

  const deleteForm = (_id) => {
    setSpin({ [_id]: true })
    setLoading(true)
    let valObj = { _id, userId: `${user?._id}` }
    axios.post(`${POST.DELETE_FORM}`, valObj)
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          adminData.getAdminForms(adminActions, user?._id)
          successNotification(selectedLanguage?.SuccessfullyDeleted)
          setLoading(false)
        }
      })
      .catch((e) => {
        setSpin({})
        setLoading(false)
      })
  }

  const disableModal = () => {
    setLink(null)
    setVisible(false)
  }

  const showQR = (url) => {
    setLink(url)
    setVisible(true)
  }

  const downloadQR = () => {
    let id = 'qrCodeQuestion'
    const canvas = document.getElementById(id)
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `from.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  const displayForm = (_id, display) => {
    setSpin({ [_id]: true })
    let valObj = { _id, display }
    axios.post(`${POST.DISPLAY_FORM}`, valObj)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          adminData.getAdminForms(adminActions, user?._id)
          successNotification(data?.message)
        }
        setSpin({})
      })
      .catch((e) => {
        setSpin({})
        setLoading(false)
        console.log('error', e)
      })
  }

  const columns = [
    {
      title: selectedLanguage?.label_FormName,
      dataIndex: 'formName',
      key: 'formName',
      // render: text => <a>{text}</a>
    },
    {
      title: selectedLanguage?.label_FormUrl,
      render: (e) => {
        return (
          <Space>
            <Button target={'_blank'} onClick={() => {
              history.push({
                pathname: allPaths?.FORM,
                state: { formData: e }
              })
            }} >{'Open'}</Button> &nbsp;&nbsp;
          </Space>
        )
      }
    },
    {
      title: selectedLanguage?.Status,
      key: 'actions',
      align: 'center',
      sorter: (a, b) => a?.isApproved - b?.isApproved,
      render: (e) => <>{
        <>
          {
            e?.public && e?.isApproved ?
              <p style={{ marginBottom: 0, color: 'green' }}>{selectedLanguage?.Approved}</p>
              : e?.public && e?.isDeclined ?
                <p style={{ marginBottom: 0, color: 'red' }}>{selectedLanguage?.Declined}</p>
                : e?.public && !e?.isApproved && !e?.isDeclined ?
                  <p style={{ marginBottom: 0, color: 'blue' }}>{selectedLanguage?.Pending}</p>
                  :
                  <p style={{ marginBottom: 0 }}>{selectedLanguage?.Private}</p>
          }
        </>
      }</>
    },
    {
      title: <p className='table-title-p'>{selectedLanguage?.txt_Display}</p>,
      key: 'action',
      align: 'center',
      render: (e) => (
        spin[e?._id] ? <Spin /> : e?.display ?
          < Button onClick={() => displayForm(e?._id, !e?.display)} > {selectedLanguage?.txt_UnDisplay}</Button >
          : <Button type='primary' onClick={() => displayForm(e?._id, !e?.display)}>{selectedLanguage?.txt_Display}</Button>
      )
    },
    {
      key: 'action',
      render: (e) => (
        <Space>
          <Popconfirm
            title={selectedLanguage?.title_DeletethisForm}
            okText={selectedLanguage?.Delete}
            cancelText={selectedLanguage?.Cancel}
            okButtonProps={{
              type: 'primary',
              className: 'form-button'
            }}
            cancelButtonProps={{ style: { borderRadius: 4 } }}
            onConfirm={() => deleteForm(e?._id)}
          >
            <Button type='link' icon={<DeleteFilled />} danger />
          </Popconfirm>
          <Button onClick={() => { setCopyForm(e); e?.lead ? setShowLeadForm(true) : setShowForm(true) }} >{selectedLanguage?.Button_Copy}</Button>
        </Space>
      )
    }
  ]

  return (
    <div className='flex-column'>
      {showLeadForm ?
        <CreateLeadForm forms={forms} openForm={() => { setShowFeedback(false); setAllQuestion(false); setShowForm(false) }} copyForm={copyForm} setCopyForm={setCopyForm} createQuestion={() => (setShowQuestion(true), setShowForm(false))} onCancel={() => (setShowLeadForm(false))} {...props} />
        : specialQuestion ?
          <SpecialQuestion onCancel={() => setSpecialQuestion(false)} {...props} /> :
          createJsonQuestion ?
            <AddJsonQuestion onCancel={() => setCreateJsonQuestion(false)} {...props} /> :
            showUsersData ?
              <UsersQuestions onBack={() => setShowUsersData(false)} {...props} /> :
              showQuestionApproval ?
                <QuestionsApproval onBack={() => setShowQuestionApproval(false)} {...props} /> :
                showFormApproval ?
                  <FormApprovals onBack={() => setShowFormApproval(false)} {...props} /> :
                  showQuestion && !showForm ?
                    <AddQuestions openQuestions={() => { setShowQuestion(false); setShowForm(false); setAllQuestion(true) }} copyQuestion={copyQuestion} setCopyQuestion={setCopyQuestion} onCancel={() => (setShowQuestion(false), setEdit(null))} {...props} /> :
                    !showQuestion && showForm ? (
                      <CreateAdminForm openForm={() => { setShowFeedback(false); setAllQuestion(false); setShowForm(false) }} copyForm={copyForm} setCopyForm={setCopyForm} createQuestion={() => (setShowQuestion(true), setShowForm(false))} onCancel={() => (setShowForm(false))} {...props} />) :
                      !showQuestion && !showForm && allQuestion ?
                        <ViewAllQuestions copyQuestion={copyQuestion} setCopyQuestion={setCopyQuestion} createForm={() => (setShowForm(true))} createQuestion={() => (setShowQuestion(true), setShowForm(false))} setAllQuestion={() => setAllQuestion(false)} {...props} />
                        : !showQuestion && !showForm && !allQuestion && showFeedback ?
                          <Feedback onCancel={() => setShowFeedback(false)} {...props} />
                          : reports ?
                            <Reports onCancel={() => (setReports(false))} {...props} /> : <div>
                              <div className='teacher-button-menu-end'>
                                <IconButton
                                  aria-label='more'
                                  id='long-button'
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup='true'
                                  onClick={handleClick}
                                >
                                  <TfiMenu size={28} color='#1eb2a6' />
                                </IconButton>
                                <Menu
                                  id='long-menu'
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <MenuItem
                                    key={1}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setShowQuestion(true)}
                                    >
                                      {selectedLanguage?.Button_CreateQuestion}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={2}
                                    onClick={handleClose}
                                  ><Button
                                    type='primary'
                                    style={{ width: '100%' }}
                                    className='newlogin-btn form-input-new-style'
                                    onClick={() => setCreateJsonQuestion(true)}
                                  >
                                      {selectedLanguage?.button_CreateJSONsQuestions}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={3}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setSpecialQuestion(true)}
                                    >
                                      {selectedLanguage?.btn_SpecialQuestion}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={4}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setAllQuestion(true)}
                                      type='primary'>
                                      {selectedLanguage?.button_Adminquestions}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={5}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setShowUsersData(true)}
                                    >
                                      {selectedLanguage?.button_Teacher_Questions_Forms}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={6}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setShowQuestionApproval(true)}
                                    >
                                      {selectedLanguage?.button_QuestionsApproval}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={7}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setShowForm(true)}>
                                      {selectedLanguage?.title_CreateForm}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={8}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setShowLeadForm(true)}>
                                      {selectedLanguage?.txt_CreateLeadForm}
                                    </Button>
                                  </MenuItem>

                                  <MenuItem
                                    key={9}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setShowFormApproval(true)}
                                    >
                                      {selectedLanguage?.buton_Examapproval}
                                    </Button>
                                  </MenuItem>
                                  <MenuItem
                                    key={11}
                                    onClick={handleClose}
                                  >
                                    <Button
                                      type='primary'
                                      style={{ width: '100%' }}
                                      className='newlogin-btn form-input-new-style'
                                      onClick={() => setReports(true)}
                                    >
                                      {selectedLanguage?.Button_Reports}
                                    </Button>
                                  </MenuItem>

                                </Menu>
                              </div>
                              <div className='new-layout-table-upper-div'>
                                <Table
                                  style={{ marginTop: '30px' }}
                                  className='new-layout-table'
                                  loading={loading}
                                  columns={columns}
                                  dataSource={forms}
                                  pagination={{ hideOnSinglePage: true }}
                                  scroll={{ x: '800px' }}
                                />
                              </div>
                            </div>
      }
      <Modal
        open={visible}
        title={selectedLanguage?.title_FormQR}
        onCancel={disableModal}
        footer={[
          <Button key='back' onClick={disableModal}>
            {selectedLanguage?.Close}
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
          <QRCode
            value={link}
            size={250}
            id='qrCodeQuestion'
            level={'H'}
            includeMargin={true}
          />
          <a onClick={downloadQR}>{selectedLanguage?.DownloadQR}</a>
        </div>
      </Modal>
    </div >
  )
}

export default AdminQuestions