import { Button, Modal, Table } from 'antd';
import axios from 'axios';
import HTML2Canvas from 'html2canvas';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GET } from '../../../utils/apis';
import { allPaths } from '../../../utils/constants';
import { errorMessage, successNotification, teacherData, } from '../../../utils/helpers';
import AddNewCoupon from './AddNewCoupon';
const TeacherCoupon = (props) => {
    const { selectedLanguage, userActions, user, getUser, location, lanKorCondition, subscriptions } = props
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const userId = new URLSearchParams(location.search).get('userId') || null
    const myCoupon = useSelector(state => state?.userReducer?.teacherCoupon)
    const history = useHistory()
    let priceForOneCoupon = subscriptions.find((v) => v?.coupon)?.amount || 1
    const [showForm, setShowForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allCoupons, setAllcoupons] = useState([])
    const [innerCoupons, setInnerCoupons] = useState([])
    const [couponCode, setCouponCode] = useState('')
    const [imgUrl, setImgUrl] = useState('')
    const [visible, setVisible] = useState(false)
    const [download, setDownload] = useState(false)
    useEffect(() => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths?.BUCOUPON)
                    if (data.success) {
                        return successNotification(lanKorCondition ? data.message : data.messageNl)
                    }
                    errorMessage(lanKorCondition ? data.message : data.messageNl)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(lanKorCondition ? e?.message : e?.messageNl)
                })
        }
    }, [])

    useEffect(() => {
        if (download) {
            androidDownloadQR()
        }
    }, [download])

    const disableModal = () => {
        setVisible(false)
        setCouponCode('')
        setImgUrl('')
    }

    const androidDownloadQR = async () => {
        let id = download ? 'qrCodeDownload' : 'qrCode'
        const canvas = document.getElementById(id)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                const a = document.createElement('a')
                a.href = pngUrl
                a.download = 'coupon-code.png'

                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)

                setDownload(false)
            })
            .catch((e) => {
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }

    const gotoNewTable = (e) => {
        setInnerCoupons(e)
    }
    let innerColumns = [
        {
            title: <p className='table-title-p' style={{ width: '200px' }}>{selectedLanguage?.txt_CouponCode}</p>,
            render: (e) => {
                return (
                    <p>{e?.couponCode ? e?.couponCode : '----'}</p>
                )
            }
        }
        ,
        {
            title: <p className='table-title-p' style={{ width: '70px' }}>{selectedLanguage?.Amount}</p>,
            render: (e) => {
                return (
                    <p>{e?.money ? e?.money : '0'}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '150px' }}>{selectedLanguage?.txt_Created}</p>,
            render: (e) => {
                return (
                    <p>{e?.created ? moment(e?.created).format('YYYY-MM-DD HH:mm') : '----'}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '70px' }}>{selectedLanguage?.Claimed}</p>,
            render: (e) => {
                return (
                    <p>{e?.isClaimed ? selectedLanguage?.True : selectedLanguage?.False}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '200px' }}>{''}</p>,
            render: (e) => {
                console.log(e, '======99')
                return (
                    <span>
                        <Button type='primary' onClick={() => (setCouponCode(e?.couponCode), setImgUrl(e?.imageUrl), setVisible(true))}>{selectedLanguage?.txt_View} ➝</Button>
                        &nbsp;
                        <Button type='primary' onClick={() => (setCouponCode(e?.couponCode), setImgUrl(e?.imageUrl), setDownload(true))}>{selectedLanguage?.txt_Download} ➝</Button>
                    </span>
                )
            }
        }
    ]
    let columns = [
        {
            title: <p className='table-title-p' style={{ width: '200px' }}>{selectedLanguage?.text_Description}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            render: (e) => {
                return (
                    <p>{e ? e : '----'}</p>
                )
            }

        },
        {
            title: <p className='table-title-p' style={{ width: '70px' }}>{selectedLanguage?.title_Quantity}</p>,
            render: (e) => {
                return (
                    <p>{e?.value?.length ? e?.value?.length : '----'}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '120px' }}>{selectedLanguage?.Created}</p>,
            render: (e) => {
                return (
                    <p>{e?.value?.length ? moment(e?.value?.[0]?.created).format('YYYY-MM-DD HH:mm') : '----'}</p>
                )
            }
        },
        {
            title: <p className='table-title-p' style={{ width: '120px' }}>{ }</p>,
            render: (e) => {
                return (
                    <Button type='primary' onClick={() => gotoNewTable(e?.value)}>{selectedLanguage?.txt_Viewall} ➝</Button>
                )
            }
        },
    ]

    useEffect(() => {
        let tableData = Object.entries(myCoupon || {})
        tableData = tableData?.map((v) => {
            return {
                id: v[0],
                value: v[1]
            }
        })
        setAllcoupons([...tableData])
    }, [myCoupon])
    useEffect(() => {
        teacherData?.getAllTeacherCoupon(userActions, user?._id)
    }, [])

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{!showForm ? selectedLanguage?.txt_Coupon : selectedLanguage?.txt_ADD_NEW_COUPON}</h3>
                {showForm && <span className='add-bew-coupon-decscrip'>{selectedLanguage?.txt_Thiscoupon_isonly_valid_foryoursubscription}</span>}
                {showForm && <span className='add-bew-coupon-decscrip'>€{priceForOneCoupon}/{selectedLanguage?.Piece}</span>}
                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    {innerCoupons?.length ?
                        <Button type='primary' size='large' onClick={() => setInnerCoupons([])} >
                            {selectedLanguage?.Cancel}
                        </Button> :
                        <Button type='primary' size='large' onClick={() => setShowForm(!showForm)} >
                            {!showForm ? selectedLanguage?.txt_new : selectedLanguage?.Cancel}
                        </Button>}
                </span>

                {!showForm ?
                    <div className='new-layout-table-upper-div'>
                        <Table
                            loading={loading}
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            dataSource={innerCoupons?.length ? innerCoupons : allCoupons}
                            columns={innerCoupons?.length ? innerColumns : columns}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 'auto' }}
                        />
                    </div> :
                    <AddNewCoupon priceForOneCoupon={priceForOneCoupon} {...props} />
                }
            </div>
            <Modal
                open={visible}
                title={selectedLanguage?.CouponCode}
                onCancel={disableModal}
                footer={[
                    <Button key='back' onClick={disableModal}>
                        {selectedLanguage?.Close}
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
                    <div id='qrCode' style={{ textAlign: 'center' }}>
                        <img src={imgUrl} style={{ height: 220, width: 220 }} />
                        <hr />
                        {/* <QRCode
                            value={couponCode}
                            size={250}
                            level={'H'}
                            includeMargin={true}
                        /> */}
                        <p>{couponCode}</p>
                    </div>
                    <a onClick={androidDownloadQR}> {selectedLanguage?.Download} </a>
                </div>
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div
                    style={{ textAlign: 'center' }}
                    id='qrCodeDownload'
                    className='fixed-qr'
                >
                    <img src={imgUrl} style={{ height: 220, width: 220 }} />
                    <hr />
                    {/* <QRCode
                        value={couponCode}
                        size={250}
                        level={'H'}
                        includeMargin={true}
                    /> */}
                    <p>{couponCode}</p>
                </div>
            </div>
        </div>
    )
}

export default TeacherCoupon