import React from 'react'

const FooterUpdate = (props) => {
    return (
        <p style={{ color: '#ec671b', fontWeight: '400', lineBreak: 'anywhere', }}>
            www.testally.com, Bureau Integra bv, Wijngaardenlaan 17, 2252 XJ Voorschoten, info@testally.com KvK 28104652
        </p>
    )
}

export default FooterUpdate