import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Radio, Select, Space, Switch, Upload } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ADMIN, POST } from '../../../utils/apis';
import { adminData, checkSelectOption, errorMessage, successMessage, teacherData } from '../../../utils/helpers';

const CopyFeedback = (props) => {
    const { selectedLanguage, userActions, user, lanKorCondition, data } = props
    const [form] = Form.useForm()
    const { Option } = Select
    const [loading, setLoading] = useState(false)
    const [groupmembers, setGroupMembers] = useState(data?.groupmembers)
    const allStudents = useSelector(state => state?.userReducer?.myStudents || [])

    useEffect(() => {
        teacherData.getMyStudents(userActions, user?._id)
    }, [])

    const onFinish = (value) => {
        setLoading(true)
        value.admin = false
        value.user = user?._id


        axios?.post(ADMIN?.ADD_FEEDBACK_FORM, value)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    await teacherData.getMyFeedbackForms(userActions, user?._id)

                    form.resetFields()
                } else {
                    setLoading(false)
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })

    }

    return (
        <div className='crt-question-div' style={{ marginTop: '30px' }}>
            <h1>{selectedLanguage?.txt_copy_Feed_back_from}</h1>

            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
                requiredMark={true}
            >
                <Form.Item
                    name='title'
                    rules={[
                        {
                            required: true,
                            message: selectedLanguage?.txt_Pleaseentertitle,
                        }
                    ]}
                    required
                    label={selectedLanguage?.title_Title}
                    initialValue={data?.title}
                >
                    <Input
                        className='form-input-new-style'
                        placeholder={selectedLanguage?.txt_Entertitlehere} />
                </Form.Item>

                <div className='new-radao-btn-div'>
                    <Form.Item
                        required
                        name='formType'
                        label={selectedLanguage?.title_FormType}
                        initialValue={data?.formType}
                    >
                        <Radio.Group className='form-input-new-style'
                            style={{ background: 'white', padding: '10px', width: '100%' }}
                            defaultValue={data?.formType}>
                            <Radio.Button value='personal'>{selectedLanguage?.Button_Personal}</Radio.Button>
                            <Radio.Button value='anonymous'>{selectedLanguage?.Button_Anonymous}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>


                <Form.Item
                    name='students'
                    label={selectedLanguage?.Button_Students}
                    initialValue={data?.students?.map((v) => v?._id?.toString()) || []}
                >
                    <Select
                        className='form-input-new-style'
                        mode='multiple'
                        showArrow
                        showSearch
                        allowClear
                        placeholder={selectedLanguage?.txt_Selectstudentshere}
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {allStudents.map((v, i) => {
                            return (
                                <Option value={v?._id} key={i}>{v?.fullName}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>


                <Form.List name='content' initialValue={data?.content}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (

                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'description']}
                                        label={!name && selectedLanguage?.txt_Description}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.PleaseInputYourDescription,
                                            }
                                        ]}
                                        required
                                    >
                                        <Input.TextArea
                                            className='form-input-new-style'
                                            autoSize={{ minRows: 2 }}
                                            placeholder={selectedLanguage?.txt_inputDescriptionhere}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value1']}
                                        label={!name && selectedLanguage?.label_Value1}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseValue1,
                                            }
                                        ]}
                                        required
                                    >
                                        <Input
                                            className='form-input-new-style-small-height'
                                            placeholder={selectedLanguage?.txt_EnterValue1}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value2']}
                                        label={!name && selectedLanguage?.label_Value2}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseValue2,
                                            }
                                        ]}
                                        required
                                    >
                                        <Input
                                            className='form-input-new-style-small-height'
                                            placeholder={selectedLanguage?.txt_EnterValue2}
                                        />
                                    </Form.Item>

                                    {fields.length > 1 &&
                                        <span style={fields.length > 1 && name && { color: '#1eb2a6', } || { color: '#1eb2a6', }}>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </span> || null}
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type='dashed'
                                    style={{
                                        background: 'white',
                                        color: '#1eb2a6',
                                        border: '1px dashed #1eb2a6'
                                    }}
                                    onClick={() => add()} block icon={<PlusOutlined />}>
                                    {selectedLanguage?.txt_AddFiled}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item >
                    <Button
                        htmlType='submit'
                        className='newlogin-btn form-input-new-style'
                        style={{ height: '45px', width: '100%', marginTop: '10px' }}
                        type='primary'
                        loading={loading}
                    >
                        {selectedLanguage?.btn_Submit}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CopyFeedback