import { FileOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Switch, Upload } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DragDropQuiz, PreviewMaths, PreviewStudentQuestion } from '../../../Components'
import { ADMIN, POST } from '../../../utils/apis'
import { adminData, checkSelectOption, errorMessage, generalData, successMessage } from '../../../utils/helpers'
const { Option } = Select

const EditSpecialQuestion = (props) => {
    const { onCancel, user, editQuestion, selectedLanguage, lanKorCondition, adminActions, generalActions } = props
    const tutions = useSelector(state => state?.generalReducer?.tutions || [])
    const chapters = useSelector(state => state?.generalReducer?.chapters || [])
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const languages = useSelector(state => state?.generalReducer?.adminLanguages || [])

    const [form] = Form.useForm()
    const [ansType, setAnsType] = useState(editQuestion?.answerType || 'input')
    const [ansList, setansList] = useState(editQuestion?.answerList || '')
    const [loading, setLoading] = useState(false)
    const [allChapters, setAllChapters] = useState(chapters || [])
    const [subjectName, setSubjectsName] = useState(editQuestion?.subject?.subjectName || '')
    const [chapterValue, setchapterValue] = useState(editQuestion.chapter || {})
    const [paragraphValue, setParagraphValue] = useState(editQuestion.paragraph || {})
    const [fileList, setFileList] = useState(editQuestion?.file?.url ? [editQuestion?.file] : [])
    const [titleFileList, setTitleFileList] = useState(editQuestion?.titleFile?.url ? [editQuestion?.titleFile] : [])
    const [questionType, setQuestionType] = useState(editQuestion?.questionType || 'questionnaire')
    const [another, setAnother] = useState(false)
    const [formValues, setFormValues] = useState(editQuestion || {})
    const [dragFileList, setDragFileList] = useState(editQuestion?.dragFile?.url ? [editQuestion?.dragFile] : [])
    const [previewImage, setPreviewImage] = useState(editQuestion?.dragFile?.url || '');
    const [spots, setSpots] = useState(((editQuestion?.answerType === 'dragDrop' || editQuestion?.answerType === 'dragDropSequence') && editQuestion?.answerList) || [])
    const [previewQuestion, setPreviewQuestion] = useState(false)

    useEffect(() => {
        generalData.getAllChapters(generalActions)
        generalData?.getAllSubjects(generalActions, user?.verify)
        generalData.getAllLanguages(generalActions)

        if (editQuestion?._id) {
            if (ansType === 'radio') {
                form.setFieldsValue({
                    answerList2: ansList
                })
            } else if (ansType === 'multiple') {
                form.setFieldsValue({
                    answerList3: ansList
                })
            } else if (ansType === 'single') {
                form.setFieldsValue({
                    answerList4: ansList
                })
            } else if (ansType === 'hotspot') {
                form.setFieldsValue({
                    answerList5: ansList
                })
            }
        }
    }, [])

    var error1
    var error2
    const normFile = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return e?.fileList[0];
    }
    const normFile2 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setFileList(e?.fileList)
    }
    const normFile3 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }

        return setTitleFileList(e?.fileList);
    }

    const normDragFile = (e) => {

        if (e?.fileList?.length && !e?.file?.type?.includes('image')) {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setDragFileList(e?.fileList);
    }
    const onChange = e => {
        setAnsType(e.target.value)
        if (editQuestion?.answerType === 'dragDrop' || editQuestion?.answerType === 'dragDropSequence') {
            form.setFieldsValue({
                spots: editQuestion?.answerList?.length ? editQuestion?.answerList?.map((v) => {
                    return {
                        correct: v?.correct,
                        x: Number(v?.x.slice(0, -1)),
                        y: Number(v?.y.slice(0, -1)),
                    }
                }) : []
            })
            let newArray = editQuestion?.answerList
            newArray = newArray.map((v, i) => {
                return {
                    id: i,
                    x: `${v?.x}%`,
                    y: `${v?.y}%`,
                    correct: v?.correct
                }
            })
            setSpots(newArray)
        } else if (ansType === 'dragDrop' || ansType === 'dragDropSequence') {
            form.setFieldsValue({
                spots: []
            })
            setSpots([])
        }
    }


    const getParagraphId = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterName = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?.chapterName
    }
    const getParagraphName = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?.paragraphName
    }
    const onFinish = async (values) => {
        if (ansType === 'input' || ansType === 'inputNumber') {
            values.answerList = values?.answerList1
        }
        else if (ansType === 'radio') {
            values.answerList = values?.answerList2
        }
        else if (ansType === 'multiple') {
            values.answerList = values?.answerList3
        }
        else if (ansType === 'dragDrop' || ansType === 'dragDropSequence') {
            values.answerList = spots
        }
        else if (ansType === 'hotspot') {
            values.answerList = values?.answerList5
        }
        else {
            values.answerList = values?.answerList4
        }

        if (ansType === 'input' || ansType === 'inputNumber') {
            values.answerList = values?.answerList6
        }
        let checkCorrectAnsCond = _.find(values?.answerList, { 'isTrue': true })
        if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.questionType === 'test' && values?.answerType !== 'input' && values?.answerType !== 'inputNumber' && values?.answerType !== 'upload' && !checkCorrectAnsCond) {
            setLoading(false)
            return errorMessage(selectedLanguage?.errorMessage_PleaseSelectaCorrectAnswer)
        }
        for (var i = 0; i < values?.answerList?.length; i++) {
            if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.answerList[i]?.file?.originFileObj) {
                let fileData = new FormData()
                fileData.append('file', values?.answerList[i]?.file?.originFileObj)
                await axios.post(ADMIN.GET_FILE_URL, fileData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            values.answerList[i].file = data?.data
                        } else {
                            return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

                    })
            } else if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.answerList[i]?.file?.url) {
                let fileObj = {
                    mediaType: values?.answerList[i]?.file?.mediaType,
                    name: values?.answerList[i]?.file?.name,
                    url: values?.answerList[i]?.file?.perUrl,
                }
                values.answerList[i].file = fileObj
                // values.titleFile = titleFileList[0]?.url
            }
        }
        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageNl)


                })
        } else if (fileList[0]?.url) {
            let fileObj = {
                mediaType: fileList[0]?.mediaType,
                name: fileList[0]?.name,
                url: fileList[0]?.perUrl,
            }
            values.file = fileObj
        }

        if (titleFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', titleFileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.titleFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(lanKorCondition ? e?.message : e?.messageNl)


                })
        } else if (titleFileList[0]?.url) {
            let fileObj = {
                mediaType: titleFileList[0]?.mediaType,
                name: titleFileList[0]?.name,
                url: titleFileList[0]?.perUrl,
            }
            values.titleFile = fileObj
        }

        let obj = {
            _id: editQuestion?._id,
            question: values?.question,
            answerList: values?.answerList,
            file: (values?.file && values?.file),
            titleFile: (values?.titleFile && values?.titleFile)
        }

        await axios.post(POST.EDIT_QUESTION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    onCancel()
                    generalData.getAllChapters(generalActions)
                    adminData.getSpecialQuestions(adminActions)
                    successMessage(lanKorCondition ? data?.message : data?.messagel)
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messagel)
                }
            })
            .catch((e) => {
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const optionsTopics = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)?.map((x) => x?.topics)?.flat()?.map((z) => {
        return {
            value: z?.topicName,
            id: z?._id
        }
    })

    const optionsParagraph = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph).flat().map((x) => {
        return {
            value: x?.paragraphName,
            id: x?._id,
        }
    })
    const optionsChapter = allChapters?.map((v, i) => {
        return {
            value: v?.chapterName
        }
    })

    const onSelectChapter = (data) => {
        setchapterValue({
            chapterName: allChapters?.filter((v, i) => v?.chapterName === data)[0]?.chapterName,
            chapterId: allChapters?.filter((v, i) => v?.chapterName === data)[0]?._id,
        })
        form.setFieldsValue({
            paragraph: null,
            topics: [null]
        })
        setParagraphValue({})

    }
    const onSearchParagraph = (searchText) => {
        setParagraphValue({ paragraphName: searchText, paragraphId: null })
        if (getParagraphId(searchText)) {
            setParagraphValue({ paragraphName: getParagraphName(searchText), paragraphId: getParagraphId(searchText) || null })
        } else {
            setParagraphValue({ paragraphName: searchText, paragraphId: null })
        }
        form.setFieldsValue({
            topics: [null]
        })
    }

    const onSelectParagraph = (data) => {
        setParagraphValue({
            paragraphName: data,
            paragraphId: optionsParagraph?.filter((v) => v?.value === data)[0]?.id,
        })
        form.setFieldsValue({
            topics: [null]
        })

    }
    const onChangeQuestionType = (e) => {
        setQuestionType(e?.target?.value)
    }
    const onChangeSubject = (id) => {
        let subjectName = subjects?.find((v) => v?._id === id)
        setSubjectsName(subjectName?.subjectName)
        let filteredChapters = chapters?.filter((v) => v?.subject === id?.toString())
        setAllChapters(filteredChapters)
    }
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handlePreview = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        setPreviewImage(file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }
    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }

    const handleSpots = () => {
        let newArray = form.getFieldValue('spots')
        newArray = newArray.map((v, i) => {
            return {
                id: i,
                x: `${v?.x}%`,
                y: `${v?.y}%`,
                correct: v?.correct
            }
        })
        setSpots(newArray)
    }
    console.log('editQuestion', editQuestion)

    const quesData = () => {
        let allData = form.getFieldsValue()

        allData.spots = allData?.spots?.map((v, i) => {
            return {
                id: i,
                x: v?.x + '%',
                y: v?.y + '%',
                correct: v?.correct
            }
        })

        allData.titleFile = titleFileList?.length ? (titleFileList?.[0]?.preview || titleFileList?.[0]?.url) : null
        allData.file = fileList?.length ? (fileList?.[0]?.preview || fileList?.[0]?.url) : null
        allData.answerType = ansType

        allData.answerList = (allData?.answerList1?.length && allData?.answerList1) || (allData?.answerList2?.length && allData?.answerList2) || (allData?.answerList3?.length && allData?.answerList3) || (allData?.answerList4?.length && allData?.answerList4) || (allData?.answerList5?.length && allData?.answerList5)

        allData.dragFile = previewImage

        return allData
    }

    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }} >
                <Button
                    type='primary'
                    onClick={() => { onCancel() }}
                >
                    {selectedLanguage?.Back}
                </Button>
            </span>
            <div className='crt-question-div'>
                <p className='crt-qutin-heading'>{selectedLanguage?.button_EditQuestion}</p>
                {
                    previewQuestion && <PreviewStudentQuestion subjectName={subjectName} onCancelPreview={() => setPreviewQuestion(false)} previewQuestion={previewQuestion} quesData={quesData} {...props} />
                }
                <Form
                    form={form}
                    onFinish={onFinish}
                    name='QuestionsForm'
                    layout='vertical'
                    requiredMark={true}
                    onValuesChange={(e) => {
                        setFormValues(form.getFieldsValue())
                    }}
                >
                    <Form.Item
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.requiredMessage_pleaseEnteraTitle,
                            }
                        ]}
                        label={selectedLanguage?.title_Title}
                        required
                        initialValue={editQuestion?.title}
                    >
                        <Input.TextArea disabled autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.title_Title} />
                    </Form.Item>

                    <Form.Item
                        // disabled
                        label={selectedLanguage?.title_FileforTitle}
                        name='titleFile'
                        style={{ width: '100%' }}
                        valuePropName='file'
                        getValueFromEvent={normFile3}
                    >
                        <Upload
                            // disabled
                            className='questionFileUpload'
                            multiple={false}
                            maxCount={1}
                            beforeUpload={() => false}
                            fileList={titleFileList}
                            onChange={handlePreview2}
                            accept='image/*, video/*, .pdf'
                            action='/upload.do'
                            style={{ width: '100%', cursor: 'not-allowed' }}
                        >
                            <Button /* disabled */ style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                            <br />
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name='point'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_Pointsarerequired,
                            }
                        ]}
                        label={selectedLanguage?.label_Points}
                        required
                        initialValue={editQuestion?.point || 0}
                    >
                        <InputNumber
                            disabled
                            style={{ width: '100%' }}
                            placeholder='2'
                            type='number'
                            max={1000}
                            min={0}
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        name='time'
                        label={selectedLanguage?.label_Time}
                        initialValue={editQuestion?.time || 8}
                    >
                        <InputNumber
                            disabled
                            controls={false}
                            addonAfter='s'
                            style={{ width: '100%', maxWidth: 200 }}
                            placeholder={selectedLanguage?.txt_Time_inSeconds}
                            type='number'
                            max={300}
                            min={8}
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        name='questionType'
                        label={selectedLanguage?.label_QuestionType}
                        required
                        initialValue={questionType}
                    >
                        <Radio.Group disabled value={questionType} onChange={onChangeQuestionType} defaultValue={questionType}>
                            <Radio.Button value='questionnaire'>{selectedLanguage?.Button_Questionnaire}</Radio.Button>
                            <Radio.Button value='test'>{selectedLanguage?.Button_Test}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='subject'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectSubject,
                            },
                        ]}
                        initialValue={editQuestion?.subject?._id}
                    >
                        <Select
                            disabled
                            onChange={onChangeSubject}
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.label_SelectSubject}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {subjects.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='question'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_questionerequired,
                            }
                        ]}
                        label={selectedLanguage?.title_Question}
                        required
                        initialValue={editQuestion?.question}
                    >
                        <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.ttxt_WhatiscapitalofNeatherland} />
                    </Form.Item>
                    {
                        subjectName?.toLowerCase()?.includes('math') && formValues?.question && <PreviewMaths sentence={formValues?.question || ' '} />
                    }
                    <Form.Item
                        /* disabled */
                        label={selectedLanguage?.File}
                        name='file'
                        style={{ width: '100%' }}
                        valuePropName='file'
                        getValueFromEvent={normFile2}
                    >
                        <Upload
                            /* disabled */
                            className='questionFileUpload'
                            multiple={false}
                            maxCount={1}
                            beforeUpload={() => false}
                            fileList={fileList}
                            onChange={handlePreview2}
                            accept='image/*, video/*, .pdf'

                            action='/upload.do'
                            style={{ width: '100%' }}
                        >
                            <Button /* disabled */ style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                            <br />
                        </Upload>
                    </Form.Item>

                    <Form.Item label={selectedLanguage?.label_SelectTypeofAnswer}>
                        <Radio.Group disabled onChange={onChange} value={ansType}>
                            <Radio value={'input'}>{selectedLanguage?.radioLabel_Input}</Radio>
                            <Radio value={'inputNumber'}>{selectedLanguage?.ttx_NumericInput}</Radio>
                            <Radio value={'radio'}>{selectedLanguage?.radioLabel_RadioOptions}</Radio>
                            <Radio value={'multiple'}>{selectedLanguage?.radioLabel_SelectMultipleoptions}</Radio>
                            <Radio value={'single'}>{selectedLanguage?.radioLabel_Selectsingleoptions}</Radio>
                            <Radio value={'upload'}>{selectedLanguage?.radioLabel_Uploadoption}</Radio>
                            <Radio value={'dragDrop'}>{selectedLanguage?.txt_Drag_and_Drop}</Radio>
                            <Radio value={'dragDropSequence'}>{selectedLanguage?.txtx_Drag_and_Drop_Sequence}</Radio>
                            <Radio value={'hotspot'}>{selectedLanguage?.txt_Hotspot}</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {questionType === 'test' ?
                        (
                            ansType === 'input' || ansType === 'inputNumber' ? null :
                                ansType === 'upload' ? null :
                                    ansType === 'radio' ?
                                        <Form.List
                                            name='answerList2'
                                            rules={[
                                                {
                                                    validator: async (_, names) => {
                                                        if (!names || names.length < 2) {
                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                        }
                                                    }
                                                }
                                            ]}
                                            initialValue={ansList}
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <Form.Item
                                                            required={false}
                                                            key={field.key}
                                                        >
                                                            <div style={{ display: 'flex' }}>
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'isTrue']}
                                                                    valuePropName='checked'
                                                                    noStyle
                                                                    initialValue={false}
                                                                >
                                                                    <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'option']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                </Form.Item>
                                                            </div>
                                                            {
                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option || ' '} />
                                                            }
                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'explaination']}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        whitespace: true,
                                                                        message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                    },
                                                                ]}
                                                                noStyle
                                                            >
                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                            </Form.Item>
                                                            {
                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.explaination || ' '} />
                                                            }
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'file']}
                                                                        style={{ width: '100%' }}
                                                                        valuePropName='file'
                                                                        getValueFromEvent={normFile}

                                                                    >
                                                                        <Upload
                                                                            className='questionFileUpload'
                                                                            multiple={false}
                                                                            maxCount={1}
                                                                            beforeUpload={() => false}
                                                                            accept='image/*, video/*, .pdf'
                                                                            onChange={handlePreview2}
                                                                            action='/upload.do'
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                            <br />
                                                                        </Upload>
                                                                    </Form.Item>
                                                                    {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                </div>
                                                                &nbsp;
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        style={{ marginTop: 8 }}
                                                                        className='dynamic-delete-button'
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type='dashed'
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {selectedLanguage?.button_AddField}
                                                        </Button>
                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List> :
                                        ansType === 'multiple' ?
                                            <Form.List
                                                name='answerList3'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                            }
                                                        }
                                                    }
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'isTrue']}
                                                                        valuePropName='checked'
                                                                        noStyle
                                                                        initialValue={false}
                                                                    >
                                                                        <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                </div>
                                                                {
                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option || ' '} />
                                                                }
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'explaination']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                                </Form.Item>
                                                                {
                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.explaination || ' '} />
                                                                }
                                                                <div style={{ display: 'flex' }}>
                                                                    <div style={{ width: '100%' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'file']}
                                                                            style={{ width: '100%' }}
                                                                            valuePropName='file'
                                                                            getValueFromEvent={normFile}

                                                                        >
                                                                            <Upload
                                                                                className='questionFileUpload'
                                                                                multiple={false}
                                                                                maxCount={1}
                                                                                beforeUpload={() => false}
                                                                                accept='image/*, video/*, .pdf'
                                                                                onChange={handlePreview2}

                                                                                action='/upload.do'
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                <br />
                                                                            </Upload>
                                                                        </Form.Item>
                                                                        {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                    </div>
                                                                    &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            style={{ marginTop: 8 }}
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {selectedLanguage?.button_AddField}
                                                            </Button>
                                                            <Form.ErrorList errors={errors} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> :
                                            (ansType === 'dragDrop' || ansType === 'dragDropSequence') ?
                                                <Form.Item
                                                    // label={selectedLanguage?.File}
                                                    name='file'
                                                    style={{ width: '100%' }}
                                                    valuePropName='file'
                                                    getValueFromEvent={normDragFile}
                                                >
                                                    <Upload
                                                        className='questionFileUpload'
                                                        multiple={false}
                                                        maxCount={1}
                                                        beforeUpload={() => false}
                                                        fileList={dragFileList}
                                                        onChange={handlePreview}
                                                        accept='image/*'
                                                        action='/upload.do'
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Button disabled style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                                                        <br />
                                                    </Upload>
                                                </Form.Item> :
                                                ansType === 'hotspot' ?
                                                    <Form.List
                                                        name='answerList5'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {

                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <Form.Item
                                                                        // required={false}
                                                                        key={field.key}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>
                                                                            <div style={{ width: '100%' }}>
                                                                                <Form.Item
                                                                                    required
                                                                                    {...field}
                                                                                    name={[name, 'file']}
                                                                                    style={{ width: '100%' }}
                                                                                    valuePropName='file'
                                                                                    getValueFromEvent={normFile}
                                                                                    rules={[
                                                                                        {
                                                                                            validator: async (_, names) => {

                                                                                                if (!names) {
                                                                                                    return Promise.reject(new Error('Please Upload file'))
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Upload
                                                                                        className='questionFileUpload'
                                                                                        multiple={false}
                                                                                        maxCount={1}
                                                                                        beforeUpload={() => false}
                                                                                        accept='image/*'
                                                                                        onChange={handlePreview2}
                                                                                        action='/upload.do'
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                        <br />
                                                                                    </Upload>
                                                                                </Form.Item>
                                                                                {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}

                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'explaination']}
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            whitespace: true,
                                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                        },
                                                                                    ]}
                                                                                    noStyle
                                                                                >
                                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                                                </Form.Item>
                                                                                {
                                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination || ' '} />
                                                                                }
                                                                            </div>
                                                                            &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    style={{ marginTop: 8 }}
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}

                                                                        </div>
                                                                    </Form.Item>
                                                                ))}
                                                                {fields.length < 4 &&
                                                                    <Form.Item>
                                                                        <Button
                                                                            type='dashed'
                                                                            onClick={() => add()}
                                                                            style={{ width: '100%' }}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {selectedLanguage?.button_AddField}
                                                                        </Button>
                                                                        <Form.ErrorList errors={errors} />
                                                                    </Form.Item>}
                                                            </>
                                                        )}
                                                    </Form.List> :
                                                    <Form.List
                                                        name='answerList4'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {
                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <Form.Item
                                                                        required={false}
                                                                        key={field.key}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                            </Form.Item>
                                                                        </div>
                                                                        {
                                                                            subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option || ' '} />
                                                                        }
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'explaination']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                                        </Form.Item>
                                                                        {
                                                                            subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.explaination || ' '} />
                                                                        }
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div style={{ width: '100%' }}>
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'file']}
                                                                                    style={{ width: '100%' }}
                                                                                    valuePropName='file'
                                                                                    getValueFromEvent={normFile}

                                                                                >
                                                                                    <Upload
                                                                                        className='questionFileUpload'
                                                                                        multiple={false}
                                                                                        maxCount={1}
                                                                                        beforeUpload={() => false}
                                                                                        accept='image/*, video/*, .pdf'
                                                                                        onChange={handlePreview2}

                                                                                        action='/upload.do'
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                        <br />
                                                                                    </Upload>
                                                                                </Form.Item>
                                                                                {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                            </div>
                                                                            &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    style={{ marginTop: 8 }}
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    </Form.Item>
                                                                ))}
                                                                <Form.Item>
                                                                    <Button
                                                                        type='dashed'
                                                                        onClick={() => add()}
                                                                        style={{ width: '100%' }}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {selectedLanguage?.button_AddField}
                                                                    </Button>
                                                                    <Form.ErrorList errors={errors} />
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                        ) :
                        (
                            ansType === 'input' || ansType === 'inputNumber' ? null :
                                ansType === 'upload' ? ''
                                    : ansType === 'radio' ?
                                        <Form.List
                                            name='answerList2'
                                            rules={[
                                                {
                                                    validator: async (_, names) => {
                                                        if (!names || names.length < 2) {
                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                        }
                                                    },
                                                },
                                            ]}
                                            initialValue={ansList}
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <span>
                                                            <Form.Item
                                                                // label={index === 0 ? 'options' : null}
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'option']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '90%' }} />
                                                                </Form.Item> &nbsp;
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        className='dynamic-delete-button'
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </Form.Item>
                                                            {
                                                                subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList2') ? 'answerList2' : 'answerList']?.[name]?.option || ' '} />
                                                            }
                                                        </span>
                                                    ))}

                                                    <Form.Item>
                                                        <Button
                                                            type='dashed'
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {selectedLanguage?.button_AddField}
                                                        </Button>

                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List> : ansType === 'multiple' ?
                                            <Form.List
                                                name='answerList3'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                            }
                                                        },
                                                    },
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors = error1 }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <span>
                                                                <Form.Item
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '90%' }} />
                                                                    </Form.Item> &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </Form.Item>
                                                                {
                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList3') ? 'answerList3' : 'answerList']?.[name]?.option || ' '} />
                                                                }
                                                            </span>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {selectedLanguage?.button_AddField}
                                                            </Button>

                                                            <Form.ErrorList errors={error1} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> :
                                            (ansType === 'dragDrop' || ansType === 'dragDropSequence') ?
                                                <Form.Item
                                                    // label={selectedLanguage?.File}
                                                    name='file'
                                                    style={{ width: '100%' }}
                                                    valuePropName='file'
                                                    getValueFromEvent={normDragFile}
                                                >
                                                    <Upload
                                                        className='questionFileUpload'
                                                        multiple={false}
                                                        maxCount={1}
                                                        beforeUpload={() => false}
                                                        fileList={dragFileList}
                                                        onChange={handlePreview}
                                                        accept='image/*'
                                                        action='/upload.do'
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Button disabled style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                                                        <br />
                                                    </Upload>
                                                </Form.Item> :
                                                ansType === 'hotspot' ?
                                                    <Form.List
                                                        name='answerList5'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {

                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <Form.Item
                                                                        // required={false}
                                                                        key={field.key}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>
                                                                            <div style={{ width: '100%' }}>
                                                                                <Form.Item
                                                                                    required
                                                                                    {...field}
                                                                                    name={[name, 'file']}
                                                                                    style={{ width: '100%' }}
                                                                                    valuePropName='file'
                                                                                    getValueFromEvent={normFile}
                                                                                    rules={[
                                                                                        {
                                                                                            validator: async (_, names) => {

                                                                                                if (!names) {
                                                                                                    return Promise.reject(new Error('Please Upload file'))
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Upload
                                                                                        className='questionFileUpload'
                                                                                        multiple={false}
                                                                                        maxCount={1}
                                                                                        beforeUpload={() => false}
                                                                                        accept='image/*'
                                                                                        onChange={handlePreview2}
                                                                                        action='/upload.do'
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                        <br />
                                                                                    </Upload>
                                                                                </Form.Item>
                                                                                {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}

                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'explaination']}
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            whitespace: true,
                                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                        },
                                                                                    ]}
                                                                                    noStyle
                                                                                >
                                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                                                </Form.Item>
                                                                                {
                                                                                    subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList5') ? 'answerList5' : 'answerList']?.[name]?.explaination || ' '} />
                                                                                }
                                                                            </div>
                                                                            &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    style={{ marginTop: 8 }}
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}

                                                                        </div>
                                                                    </Form.Item>
                                                                ))}
                                                                {fields.length < 4 &&
                                                                    <Form.Item>
                                                                        <Button
                                                                            type='dashed'
                                                                            onClick={() => add()}
                                                                            style={{ width: '100%' }}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {selectedLanguage?.button_AddField}
                                                                        </Button>
                                                                        <Form.ErrorList errors={errors} />
                                                                    </Form.Item>}
                                                            </>
                                                        )}
                                                    </Form.List> :
                                                    <Form.List
                                                        name='answerList4'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {
                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options));
                                                                    }
                                                                },
                                                            },
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors = error2 }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <span>
                                                                        <Form.Item
                                                                            // label={index === 0 ? 'options' : ''}
                                                                            required={false}
                                                                            key={field.key}
                                                                        >
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '90%' }} />
                                                                            </Form.Item> &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}
                                                                        </Form.Item>
                                                                        {
                                                                            subjectName?.toLowerCase()?.includes('math') && formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option && <PreviewMaths sentence={formValues?.[formValues.hasOwnProperty('answerList4') ? 'answerList4' : 'answerList']?.[name]?.option || ' '} />
                                                                        }
                                                                    </span>
                                                                ))}
                                                                <Form.Item>
                                                                    <Button
                                                                        type='dashed'
                                                                        onClick={() => add()}
                                                                        style={{ width: '100%' }}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {selectedLanguage?.button_AddField}
                                                                    </Button>

                                                                    <Form.ErrorList errors={error2} />
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                        )
                    }

                    {previewImage && (ansType === 'dragDrop' || ansType === 'dragDropSequence') &&
                        <>
                            <Form.List
                                name='spots'
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            handleSpots()
                                        }
                                    }
                                ]}
                                initialValue={(ansList?.length && (editQuestion?.answerType === 'dragDrop' || editQuestion?.answerType === 'dragDropSequence')) ? ansList?.map((v, i) => {
                                    return {
                                        id: i,
                                        correct: v?.correct,
                                        x: Number(v?.x?.slice(0, -1)),
                                        y: Number(v?.y?.slice(0, -1)),
                                    }
                                }) : []}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    ansType === 'dragDrop' ?
                                        <>
                                            {fields.map((field, name, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'correct']}
                                                            valuePropName='checked'
                                                            noStyle
                                                            initialValue={false}
                                                        >
                                                            <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' onChange={handleSpots} />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'x']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore={'X'}
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                step={5}
                                                                precision={0}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'y']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore='Y'
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                precision={0}
                                                                step={5}
                                                            />
                                                        </Form.Item>
                                                        &nbsp;
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                style={{ marginTop: 8 }}
                                                                className='dynamic-delete-button'
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Form.Item>
                                            ))}

                                            {!(fields.length > 4) && <Form.Item>
                                                <Button
                                                    type='dashed'
                                                    onClick={() => (add(), handleSpots())}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {'Add Spots'}
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>}
                                        </>
                                        :
                                        <>
                                            {fields.map((field, name, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'x']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore={'X'}
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                step={5}
                                                                precision={0}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'y']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            initialValue={0}
                                                        >
                                                            <InputNumber
                                                                addonBefore='Y'
                                                                onChange={handleSpots}
                                                                min={0}
                                                                max={90}
                                                                precision={0}
                                                                step={5}
                                                            />
                                                        </Form.Item>
                                                        &nbsp;
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                style={{ marginTop: 8 }}
                                                                className='dynamic-delete-button'
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Form.Item>
                                            ))}

                                            {!(fields.length > 4) && <Form.Item>
                                                <Button
                                                    type='dashed'
                                                    onClick={() => (add(), handleSpots())}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {selectedLanguage?.txt_AddSpots}
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>}
                                        </>
                                )}
                            </Form.List>
                            <div style={{ maxWidth: 400 }}>
                                <DragDropQuiz spots={spots} sequence={ansType === 'dragDropSequence'} imgUrl={previewImage} {...props} />
                            </div>

                            <br />
                            <br />
                        </>}

                    <Form.Item
                        name='language'
                        label={selectedLanguage?.label_SelectLanguage}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectLanguage,
                            },
                        ]}
                        initialValue={editQuestion?.language?._id}
                    >
                        <Select
                            disabled
                            showArrow
                            allowClear
                            placeholder={selectedLanguage?.label_SelectLanguage}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {languages.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.languageName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='tutions'
                        label={selectedLanguage?.label_SelectSelectTutions}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.requiredMessage_pleaseSelectTutions,
                            },
                        ]}
                        initialValue={editQuestion?.tutions?.map((v) => v?._id?.toString()) || []}
                    >
                        <Select
                            disabled
                            showArrow
                            allowClear
                            mode='multiple'
                            placeholder={selectedLanguage?.label_SelectSelectTutions}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}

                        >
                            {tutions?.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v?.tutionName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        disabled
                        name='book'
                        label={selectedLanguage?.label_LearningBook}
                        initialValue={editQuestion?.book}
                    >
                        <Input disabled placeholder={'e.g. Harry potter'} />
                    </Form.Item>

                    <Form.Item
                        name='chapter'
                        disabled
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_Pleasechapter,
                            }
                        ]}
                        required
                        label={selectedLanguage?.label_Chapters}
                        initialValue={editQuestion?.chapter?.chapterName}
                    >
                        <AutoComplete
                            disabled
                            options={_.uniqBy(optionsChapter, 'value')}
                            placeholder={selectedLanguage?.placeholder_Chapters}
                            onSelect={onSelectChapter}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name='paragraph'
                        disabled
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseParagraph,
                            }
                        ]}
                        label={selectedLanguage?.label_Paragraph}
                        required
                        initialValue={editQuestion?.paragraph?.paragraphName}
                    >
                        <AutoComplete
                            disabled
                            options={_.uniqBy(optionsParagraph, 'value')}
                            // options={optionsParagraph}
                            placeholder={selectedLanguage?.placeholder_Paragraph}
                            onSearch={onSearchParagraph}
                            onSelect={onSelectParagraph}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.List
                        name='topics'
                        initialValue={editQuestion?.topics?.map((v) => v?.topicName) || ['']}

                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? selectedLanguage?.label_Topics : ''}
                                        required
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: selectedLanguage?.errorMessage_PleaseTopic,
                                                },
                                            ]}
                                            noStyle
                                        // initialValue={editQuestion?.topics[0]?.topicName}
                                        >
                                            <AutoComplete
                                                options={_.uniqBy(optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value)), 'value')}
                                                // options={optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value))}
                                                placeholder={selectedLanguage?.placeholder_Topic}
                                                disabled
                                                style={{
                                                    width: fields.length > 1 ? '93%' : '100%',
                                                    marginRight: fields.length > 1 ? '1%' : 0
                                                }}
                                                filterOption={(inputValue, option) =>
                                                    option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className='dynamic-delete-button'
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button

                                        type='dashed'
                                        onClick={() => add()}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled
                                    >
                                        {selectedLanguage?.button_AddTopic} ({fields?.length}/3)
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <br />

                    <Form.Item
                        disabled
                        name={'level'} initialValue={editQuestion?.level || 1} label={selectedLanguage?.label_Level}>
                        <Radio.Group disabled>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        disabled
                        name={'grade'} initialValue={editQuestion?.grade || 10} label={selectedLanguage?.label_Grade}>
                        <Radio.Group disabled>
                            <Radio value={10}>10</Radio>
                            <Radio value={11}>11</Radio>
                            <Radio value={12}>12</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {user?.ownerType === 'teacher' &&
                        <Form.Item
                            name='public'
                            valuePropName='checked'
                            initialValue={editQuestion?.public || false}
                        >
                            <Checkbox disabled>
                                {selectedLanguage?.label_Public}
                            </Checkbox>
                        </Form.Item>
                    }
                    <Form.Item
                        disabled
                        name='leave'
                        valuePropName='checked'
                        initialValue={editQuestion?.leave || false}
                    >
                        <Checkbox disabled >
                            {selectedLanguage?.label_Leave}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <Button type='primary' style={{ float: 'right' }} onClick={() => setPreviewQuestion(true)}>{selectedLanguage?.Button_PreviewQuestions}</Button>
                        <br />
                        <br />
                        <Button
                            htmlType='submit'
                            block
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.txt_Editquestion}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default EditSpecialQuestion