import { Popconfirm, Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { FaEdit } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../../utils/apis'
import { errorMessage, getAdminFaq, successMessage } from "../../../../utils/helpers"
import AddAdminFaq from './AddAdminFaq'

const AdminFaq = (props) => {
    const { selectedLanguage, user, language, adminActions } = props
    const isEnglish = language?.language === 'english'
    const faqsObj = useSelector(state => state.adminReducer?.adminFaq)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [readingHow, setReadingHow] = useState('')
    const [readingFaq, setReadingFaq] = useState('')
    const [edit, setEdit] = useState({})
    let FaqGroup = faqsObj?.[1] || {}
    const keys = Object.keys(FaqGroup)
    useEffect(() => {
        getAdminFaq(adminActions)
    }, [])


    const deleteFaq = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            adminId: user?._id,
            id: id
        }
        axios.post(ADMIN?.DELETE_ADMIN_FAQ, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setDeleteLoading({})
                    successMessage(isEnglish ? data?.message : data?.messageNl)
                    getAdminFaq(adminActions)
                } else {

                    setDeleteLoading({})
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <div style={{ backgroundColor: 'white', }}>
            {edit?._id ?
                <AddAdminFaq onCancel={() => setEdit({})} edit={edit} {...props} />
                : <div>
                    <div className='main-div-new-style'>
                        <div className='div-flex-back-name'>
                            <div className='height-35'>
                                <p className='new-screen-heading'>{selectedLanguage?.txt_HowitsWork}</p>
                            </div>
                        </div>

                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {faqsObj[0]?.length && faqsObj[0]?.map((v, i) => {
                                return (
                                    <>
                                        <div style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                            borderRadius: '8px',
                                            paddingTop: '30px'
                                        }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.questionEng : v?.questionNl}</p>
                                                {readingHow !== v?._id ?
                                                    <IoIosArrowDown color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                        setReadingHow(v?._id)
                                                    }} /> :
                                                    <IoIosArrowUp color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                        setReadingHow('')
                                                    }} />}
                                            </div>
                                            {readingHow === v?._id ?
                                                <div>
                                                    <br />
                                                    <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.answerEng : v?.answerEng}</p>
                                                </div> : null}
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>

                                                <Popconfirm
                                                    title={selectedLanguage?.txt_Areyousuredeletethisfaq}
                                                    okText={selectedLanguage?.txt_Delete}
                                                    cancelText={selectedLanguage?.txt_Cancel}
                                                    okButtonProps={{
                                                        type: 'primary',
                                                        className: 'form-button'
                                                    }}
                                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                    onConfirm={() => {
                                                        deleteFaq(v?._id)
                                                    }}
                                                >
                                                    {deleteLoading[v?._id] ?
                                                        <Spin />
                                                        :
                                                        < AiFillDelete
                                                            color='#1eb2a6'
                                                            size={20}
                                                            style={{
                                                                margin: '5px',
                                                            }}
                                                        />}
                                                </Popconfirm>
                                                < FaEdit
                                                    onClick={() => setEdit(v)}
                                                    color='#1eb2a6'
                                                    size={20}
                                                    style={{
                                                        margin: '5px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            }
                        </div>
                        <div className='div-flex-back-name'>
                            <div className='height-35'>
                                <p className='new-screen-heading'>{selectedLanguage?.txt_FAQs}</p>
                            </div>
                        </div>
                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {keys?.length ? keys?.map((v, i) => {
                                let faqArr = FaqGroup?.[v]
                                return (
                                    <>
                                        <div className='height-35'>
                                            <p className='new-screen-heading' style={{ textAlign: 'center' }}>{isEnglish ? faqArr[0].titleEng : faqArr[0].titleNl}</p>
                                        </div>
                                        {faqArr?.map((vv, ii) => {
                                            return (
                                                <>
                                                    <div
                                                        key={vv + 1}
                                                        style={{
                                                            padding: '20px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            marginBottom: '20px',
                                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                            borderRadius: '8px',
                                                            paddingTop: '30px'
                                                        }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.questionEng : vv?.questionNl}</p>
                                                            {readingFaq !== vv?._id ?
                                                                <IoIosArrowDown color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                    setReadingFaq(vv?._id)
                                                                }} /> :
                                                                <IoIosArrowUp color='#1eb2a6' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                    setReadingFaq('')
                                                                }} />}
                                                        </div>
                                                        {readingFaq === vv?._id ?
                                                            <div>
                                                                <br />
                                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.answerEng : vv?.answerEng}</p>
                                                            </div> : null}
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                            <Popconfirm
                                                                title={selectedLanguage?.txt_Areyousuredeletethisfaq}
                                                                okText={selectedLanguage?.txt_Delete}
                                                                cancelText={selectedLanguage?.txt_Cancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    deleteFaq(vv?._id)
                                                                }}
                                                            >
                                                                {deleteLoading[v?._id] ? <Spin /> :
                                                                    < AiFillDelete
                                                                        color='#1eb2a6'
                                                                        size={20}
                                                                        style={{
                                                                            margin: '5px',
                                                                        }}
                                                                    />}
                                                            </Popconfirm>
                                                            < FaEdit
                                                                onClick={() => setEdit(vv)}
                                                                color='#1eb2a6'
                                                                size={20}
                                                                style={{
                                                                    margin: '5px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>

                                )

                            }) :
                                <div className='nodata-center-text'>
                                    <p>{selectedLanguage?.txt_NoFaqsaddedyet}</p>
                                </div>}
                        </div>
                    </div>
                </div >}
        </div >
    )
}

export default AdminFaq