import React, { useEffect, useState } from 'react'
import { Faq } from '../../../Screens'
import { checkSelectOption, studentData } from '../../../utils/helpers'
import { Button, Select } from 'antd'
import NoDataComponent from '../../NoData/NoDataComponent'
import { allPaths } from '../../../utils/constants'
import { useSelector } from 'react-redux'
import FeedbackResponseModal from './FeedbackResponse'
import TeacherResponses from './TeacherResponses'

const NewStudentFeedbackScreen = (props) => {
    const { selectedLanguage, history, user, userActions } = props
    const { Option } = Select
    const allData = useSelector(state => state?.userReducer?.allFeedbackForm || [])
    const [selectOption, setSelectOption] = useState('all')
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState(null)
    const [response, setResponse] = useState(false)

    const onChange = (v) => {
        setSelectOption(v)
        if (v === 'all') {
            setData(allData)
        }
        else if (v === 'admin') {
            const filterArray = allData?.filter((y) => y?.admin === true)
            setData(filterArray)
        } else {
            const filterArray = allData?.filter((y) => y?.user?._id === v)
            setData(filterArray)
        }
    }

    const onCancel = () => {
        setModalData(null)
    }

    const gotoFeedbackModal = (v) => {
        setModalData(v)
    }

    useEffect(() => {
        studentData.getAllFeedbackForm(userActions, user?._id)
    }, [])

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote4}</p>
            </div>
            <div className='new-exam-screen-upper' style={{ padding: 10 }}>
                <h3 className='new-exam-title'>{response ?
                    selectedLanguage?.txt_TeacherResponses
                    : selectedLanguage?.txt_Feedback_Forms}</h3>

                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button
                        size='large'
                        type='primary'
                        onClick={() => setResponse(!response)} >
                        {response ? selectedLanguage?.txt_Feedback_Forms : selectedLanguage?.txt_TeacherResponses}
                    </Button>
                </span>
                <div className='main-div-feedback' style={{ paddingTop: 0 }}>
                    <div className='flex-mobile' style={{ display: 'flex', width: '100%', margin: '6px', }}>
                        <Select
                            style={{ maxWidth: '98%', width: 350 }}
                            onSearch={onChange}
                            showSearch
                            showArrow
                            allowClear={false}
                            defaultValue={selectOption}
                            onSelect={onChange}
                            className='form-input'
                            placeholder={selectedLanguage?.txt_Selecttype}
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            <Option value={'all'} >{selectedLanguage?.txt_AllForms}</Option>
                            {!response && <Option value={'admin'} >{selectedLanguage?.title_Admin}</Option>}
                            {user?.subscribeTeacher?.map((v, i) => {
                                return <Option key={i} value={v?.teacher?._id} >{v?.teacher?.fullName}</Option>
                            })}
                        </Select>
                    </div>
                    {!response ?
                        <div className='new-exam-screen-cards' style={{ padding: '0px' }}>
                            {(selectOption === 'all' ? allData : data)?.length ?
                                (selectOption === 'all' ? allData : data)?.map((v, i) => {
                                    return (
                                        <div className='new-exam-card' style={{ minHeight: 'auto' }} key={i} >
                                            <br />
                                            <br />
                                            <div className='new-exam-card-row-div' >
                                                <p className='new-row-title'>{selectedLanguage?.txt_title}</p>
                                                <p className='new-row-value'>{v?.title || '----'}</p>
                                            </div>
                                            <div className='grey-card' />

                                            <div className='new-exam-card-row-div'>
                                                <p className='new-row-title'>{selectedLanguage?.txt_Creater}</p>
                                                <p className='new-row-value'>{v?.admin ? selectedLanguage?.title_Admin : v?.user?.fullName}</p>
                                            </div>
                                            <Button
                                                type='primary'
                                                block
                                                size='large'
                                                onClick={() => gotoFeedbackModal(v)}
                                            >{selectedLanguage?.btn_Open}</Button>
                                            <br />
                                            <br />
                                        </div>
                                    )
                                }) :
                                <>
                                    <NoDataComponent />
                                </>
                            }
                        </div>
                        : <TeacherResponses selectOption={selectOption} {...props} />}
                </div>
                <br />
            </div>
            {modalData?._id &&
                <FeedbackResponseModal open={modalData?._id} onCancel={onCancel} data={modalData} {...props} />
            }
        </div>
    )
}
export default NewStudentFeedbackScreen