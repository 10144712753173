import { Modal } from "antd";
import React from "react";
import { convertTitle } from "../../../../utils/helpers";


const PreviewSubscriptioinModal = (props) => {
    const { open, onCancel, data, selectedLanguage, lanKorCondition } = props
    console.log('data===>', data)
    return (
        < Modal open={open}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <p className='new-p-question'>{selectedLanguage?.ttx_FeedbackResponsesDetail}</p>
            <div>
                <p className='margin0'>
                    <span className='colored-span'>{selectedLanguage?.txt_Name}:</span>
                    {data?.name}
                </p>
                <p className='margin0'>
                    <span className='colored-span'>{selectedLanguage?.Type}:</span>
                    {data?.subscriptionType}
                </p>
                {data?.subscriptionType === 'content' &&
                    <p className='margin0'>
                        <span className='colored-span'>{selectedLanguage?.txt_Content_Type}:</span>
                        {data?.contentType}
                    </p>
                }
                <p className='margin0'>
                    <span className='colored-span'>{selectedLanguage?.txt_Duration}:</span>
                    {data?.duration}{' '}{convertTitle(data?.durationType)}
                </p>
                <p className='margin0'>
                    <span className='colored-span'>{selectedLanguage?.Amount}:</span>
                    {data?.amount} €
                </p>
                <p className='margin0'>
                    {data?.subject?.length ?
                        <>
                            <span className='colored-span'>{selectedLanguage?.title_Subject}:</span>
                            {data?.subject?.map((v, i) => {
                                return (
                                    <div style={{ marginLeft: '30px' }}>
                                        <p className='margin0' >
                                            <span className='colored-span'>{i + 1}:</span>
                                            {v?.subjectName}
                                        </p>
                                    </div>
                                )
                            })}
                        </>
                        : null}
                </p>
            </div>
        </Modal>
    )
}
export default PreviewSubscriptioinModal
