import { Image, Modal } from 'antd'
import React from 'react'

const ShowCardDetails = (props) => {
    const { content, onCancel, selectedLanguage } = props
    return (
        <Modal
            open={content?.length}
            footer={<button onClick={() => onCancel()}>Close</button>}
            onCancel={onCancel}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <p className='new-p-question'>{selectedLanguage?.txt_Files}</p>
            <ul>
                {content?.map((v, i) =>
                (<div Key={i}>
                    <li > {selectedLanguage?.Description}:{v?.description}</li>
                    <li >{selectedLanguage?.txt_Files}:
                        <ol>
                            {
                                v?.files.map((x, ind) => (
                                    <li key={`0${ind}`}><Image width={150} src={x?.url} />  </li>
                                ))
                            }
                        </ol>
                    </li>
                </div>)
                )}
            </ul >
        </Modal >
    )
}

export default ShowCardDetails
