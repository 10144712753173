import { DeleteFilled } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ShowCardDetails } from '../../../Components'
import AdminAddCards from '../../../Screens/Admin/AdminCards/AdminAddCard'
import { ADMIN, POST } from '../../../utils/apis'
import { errorMessage, generalData, successMessage, teacherData } from '../../../utils/helpers'

const TeacherCards = (props) => {
    const { user, userActions, generalActions, selectedLanguage, lanKorCondition } = props
    const [data, setData] = useState([])
    const [spin, setSpin] = useState({})
    const [displaySpin, setDisplaySpin] = useState({})
    const [showPopup, setShowPopup] = useState(false)
    const teacherCards = useSelector(state => state?.userReducer?.teacherCards || [])

    useEffect(() => {
        generalData.getAllTeachers(generalActions)
        generalData.getAllChapters(generalActions)
        teacherData.getTeacherCards(userActions, user?._id)
    }, [])

    const deleteVideo = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_CARDS, { id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    teacherData.getTeacherCards(userActions, user?._id)
                    setSpin({})
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    setSpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(lanKorCondition ? e?.messege : e?.messegeNl)
            })
    }

    const displayVideo = (videoId, display) => {
        let obj = {
            videoId,
            display,
            id: user?._id
        }
        setDisplaySpin({ [videoId]: true })
        axios.post(POST?.DISPLAY_UNDISPLAY_CARD, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    teacherData.getTeacherCards(userActions, user?._id)
                    setDisplaySpin({})
                    successMessage(selectedLanguage?.txt_SuccessfullyUpdated)
                }
                else {
                    setDisplaySpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDisplaySpin({})
                errorMessage(lanKorCondition ? e?.messege : e?.messegeNl)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Title}</p>,
            key: 'title',
            dataIndex: 'title',
            sorter: (a, b) => a.title.length - b.title.length
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Chapter}</p>,
            key: 'chapter',
            dataIndex: 'chapter',
            sorter: (a, b) => a.chapter.length - b.chapter.length
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Paragraph}</p>,
            key: 'paragraph',
            dataIndex: 'paragraph',
            sorter: (a, b) => a.paragraph.length - b.paragraph.length
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Regular}</p>,
            key: 'regularTested',
            dataIndex: 'regularTested',
            sorter: (a, b) => a.regularTested - b.regularTested,
            render: (e) => <>{e ? selectedLanguage?.True : selectedLanguage?.False}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Files}</p>,
            key: 'content',
            dataIndex: 'content',
            render: (e) => <Button onClick={() => setData(e)}>{selectedLanguage?.txt_Files}</Button>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tzt_Admin}</p>,
            key: 'admin',
            dataIndex: 'admin',
            sorter: (a, b) => a.admin - b.admin,
            render: (e) => <>{e ? selectedLanguage?.True : selectedLanguage?.False}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Action}</p>,
            render: (e) => <>
                {displaySpin[e?._id] ?
                    <Spin /> :
                    !e?.display.includes(user?._id) ?
                        <Button type='primary' onClick={() => displayVideo(e?._id, true)}>{selectedLanguage?.Button_display}</Button> :
                        <Button onClick={() => displayVideo(e?._id, false)}>{selectedLanguage?.Button_Hide}</Button>
                }
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    disabled={e?.admin}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteVideo(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button type="link" disabled={e?.admin} icon={<DeleteFilled />} /* size={size} */ danger />
                    }
                </Popconfirm >
            </>
        },
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
            </div>
            <ShowCardDetails content={data || []} onCancel={() => setData([])} />
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Cards}</h3>
                <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button type='primary' size='large' onClick={() => setShowPopup(true)} >
                        {selectedLanguage?.txt_AddCard}
                    </Button>
                </span>
                <AdminAddCards showAddImage={showPopup} onCancel={() => setShowPopup(false)} {...props} />
                <div className='new-layout-table-upper-div'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        dataSource={teacherCards || []}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default TeacherCards