import React, { useEffect, useState } from 'react';
import image_g_laptop from '../../../assets/NewImages/home-girl-with-laptp.jpg'
import { GiBookshelf } from 'react-icons/gi'
import { PiStudentBold } from 'react-icons/pi'
import { CgWebsite } from 'react-icons/cg'
import CountUp from 'react-countup';
import { allPaths } from '../../../utils/constants';
import VisibilitySensor from 'react-visibility-sensor';

const NewTeacherHome = (props) => {
    const { history, selectedLanguage } = props
    const [countUpVisible, setCountUpVisible] = useState(false);
    const [boxesVisible, setBoxesVisible] = useState(false);

    const gotoExam = () => {
        history?.push(allPaths?.QUESTIONS)
    }
    const gotoResult = () => {
        history?.push(allPaths?.BU_RESULTS)
    }
    const handleVisibilityChange = (isVisible) => {
        if (isVisible && !boxesVisible) {
            setBoxesVisible(true);
        }
    };

    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setCountUpVisible(true);
        }
    };
    useEffect(() => {
        setBoxesVisible(false);
    }, []);
    return (
        <div className='new-home-main-div'>
            <div className='home-div-bg-img'>
                <div className='home-txt-div'>
                    <p className='new-home-first-p'>{selectedLanguage?.txt_WELCOMETOTESTALLY} </p>
                    <p className='new-home-snd-p'>{selectedLanguage?.text_EASYLEARNINGBYSMARTTESTING} </p>
                    <p className='new-home-trd-p'>{selectedLanguage?.txt_Far_far_away_behindthe_word}</p>
                    <br />
                    <button className='home-snup-btn' onClick={gotoExam}>
                        {selectedLanguage?.txt_Exams} ➝
                    </button>
                    <button className='home-login-btn' onClick={gotoResult}>
                        {selectedLanguage?.txt_Result} ➝
                    </button>

                </div>
            </div>
            <div className='three-boxes-card-div'>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.txt_Dashboard}</p>
                    <p>{selectedLanguage?.txt_loremispum_full} </p>
                    <button className='ex-tion-card-button' >
                        {selectedLanguage?.txt_Dashboard}
                    </button>
                </div>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.txt_Exams} </p>

                    <p>{selectedLanguage?.txt_loremispum_full} </p>
                    <button className='ex-tion-card-button' onClick={gotoExam}>
                        {selectedLanguage?.txt_Exams} ➝
                    </button>
                </div>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.txt_Result}</p>

                    <p>{selectedLanguage?.txt_loremispum_full} </p>
                    <button className='ex-tion-card-button' onClick={gotoResult}>
                        {selectedLanguage?.txt_Result} ➝
                    </button>
                </div>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.txt_Dashboard}</p>
                    <p>{selectedLanguage?.txt_loremispum_full} </p>
                    <button className='ex-tion-card-button' >
                        {selectedLanguage?.txt_Dashboard}
                    </button>
                </div>
            </div>
            <div className='home-2nd-div-white'>
                <div className='home-2nd-div-inner-white' >
                    <div className='home-2nd-div-inner-white-inner-img-div div-1st-new'>
                        <img src={image_g_laptop} />
                    </div>
                </div>
                <div className='home-2nd-div-inner-white'>
                    <div style={{ paddingTop: '30px' }}>
                        <p className='home-2nd-div-1st-p' >{selectedLanguage?.txt_LEARN_ANYTHING}</p>
                        <p className='home-2nd-div-2nd-p'>{selectedLanguage?.txt_BenefitsAboutOnlineLearningExpertise}</p>
                    </div>
                    <br />
                    {[1, 2, 3]?.map((v, i) => {
                        return (
                            <div key={i} className='div-services-wrap'>
                                <div>
                                    <GiBookshelf size={60} />
                                </div>
                                <div>
                                    <p className='new-pp-title'>{selectedLanguage?.txt_OnlineCourses}</p>
                                    <p className='new-pp-dics'>{selectedLanguage?.txt_loremispum_small}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* <div className='hoame-3rd-div-count-up'>
                <div className='new-1st'>
                    <VisibilitySensor onChange={onVisibilityChange}>
                        <div className='count-up-div'>
                            <div>
                                <PiStudentBold size={50} />
                            </div>
                            <div className='count-up-diiner-content'>
                                <p className='count-up-t'>{selectedLanguage?.txtStudent}</p>
                                {countUpVisible && <p className='count-up-n'>
                                    <CountUp end={100} duration={5} /></p>
                                }
                            </div>
                        </div>
                    </VisibilitySensor>
                    <VisibilitySensor onChange={onVisibilityChange}>
                        <div className='count-up-div'>
                            <div>
                                <PiStudentBold size={50} />
                            </div>
                            <div className='count-up-diiner-content'>
                                <p className='count-up-t'>{selectedLanguage?.txtStudent}</p>
                                {countUpVisible && <p className='count-up-n'>
                                    <CountUp end={100} duration={5} /></p>
                                }
                            </div>
                        </div>
                    </VisibilitySensor>
                    <VisibilitySensor onChange={onVisibilityChange}>
                        <div className='count-up-div'>
                            <div>
                                <PiStudentBold size={50} />
                            </div>
                            <div className='count-up-diiner-content'>
                                <p className='count-up-t'>{selectedLanguage?.txtStudent}</p>
                                {countUpVisible && <p className='count-up-n'>
                                    <CountUp end={100} duration={5} /></p>
                                }
                            </div>
                        </div>
                    </VisibilitySensor>
                    <VisibilitySensor onChange={onVisibilityChange}>
                        <div className='count-up-div'>
                            <div>
                                <PiStudentBold size={50} />
                            </div>
                            <div className='count-up-diiner-content'>
                                <p className='count-up-t'>{selectedLanguage?.txtStudent}</p>
                                {countUpVisible && <p className='count-up-n'>
                                    <CountUp end={100} duration={5} /></p>
                                }
                            </div>
                        </div>
                    </VisibilitySensor>
                </div>
            </div> */}

            {/* <div className='home-4th-div'>
                <p className='home-4th-div-1p'>{selectedLanguage?.txt_BrowseOurOnlineCourses}</p>
                <div className='courses-main-div'>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((v, index) => {
                        return (
                            <VisibilitySensor key={index} onChange={handleVisibilityChange}>
                                {({ isVisible }) => (
                                    <div className={`cours-div ${isVisible || boxesVisible ? 'visible' : ''}`}>
                                        <div className='cours-divicon-div'>
                                            <CgWebsite size={80} />
                                        </div>
                                        <p className='cours-div-tt'>{selectedLanguage?.txt_WebDevelopment}</p>
                                        <p className='cours-div-nn'>10+ {selectedLanguage?.txt_Courses}</p>
                                    </div>
                                )}
                            </VisibilitySensor>
                        )
                    })}
                </div>
            </div> */}
        </div>
    )
}

export default NewTeacherHome