import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../../../utils/apis'
import { errorMessage, generalData, successNotification } from '../../../../utils/helpers'

const AddTution = (props) => {
    const { onCancel, edit, user, language, selectedLanguage, adminActions, generalActions } = props
    const [loading, setLoading] = useState(false)
    const isEnglish = language?.language === 'english'
    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id

        if (!edit?._id) {
            axios.post(ADMIN.CREATE_TUTIONS, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data?.success) {
                        generalData.getAllTutions(generalActions)
                        successNotification(isEnglish ? data?.message : data?.messageNl)

                        onCancel()
                    }
                    else {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    console.log('e', e)
                    setLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        } else {
            values._id = edit?._id
            axios.post(ADMIN.EDIT_TUTIONS, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data?.success) {
                        generalData.getAllTutions(generalActions)
                        successNotification(isEnglish ? data?.message : data?.messageNl)
                        onCancel()
                    }
                    else {
                        errorMessage(isEnglish ? data?.message : data?.messageNl)
                    }
                })
                .catch((e) => {
                    console.log('e', e)
                    setLoading(false)
                    errorMessage(isEnglish ? e?.message : e?.messageNl)
                })
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type='primary'
                    size='large'
                    style={{ float: 'right', width: '120px' }}
                    onClick={onCancel}

                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div className='crt-question-div'>
                <p className='admin-home-title'> {edit?._id ? selectedLanguage?.txt_Updatetution : selectedLanguage?.txt_Createtution} </p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <Form.Item
                        name='tutionName'
                        label={selectedLanguage?.txt_Tutionname}
                        initialValue={edit?.tutionName}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourTypeName
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={selectedLanguage?.txt_egABCSchool}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            size='large'
                            style={{ float: 'right', width: '120px' }}
                            className='form-button'
                            htmlType='submit'
                            loading={loading}
                        >
                            {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create}
                        </Button>

                    </Form.Item>
                </Form>
            </div >
        </>
    )
}

export default AddTution