import { Button, Divider } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { AiFillDislike, AiFillLike } from 'react-icons/ai'
import { POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { checkLuxurySub, errorMessage, paraText, removeBaseUrls, toBase64 } from '../../utils/helpers'
import OverviewBoxes from './OverviewBoxes'

const ParagraphOverview = (props) => {
    const { paraOverview, location, setOverviewQuestion, user, formData, allQuestions, isEnglish, history } = props
    const [loading, setLoading] = useState(false)
    const retryQuestions = () => {
        setLoading(true)

        if (!user?.exams && !checkLuxurySub(user?.subscription)) {
            return errorMessage('Please Buy Subscription')
        }

        let obj = {
            userId: user?._id,
            admin: formData?.isAdmin || false,
            teacherId: formData?.userId,
            questions: removeBaseUrls(paraOverview?.questions)
        }

        axios.post(POST.GET_RETRY_QUESTIONS, obj)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    let newQuestions = JSON.parse(JSON.stringify(data?.data))

                    for (let v of newQuestions) {
                        let dragFile = v?.dragFile
                        let answerList = v?.answerList
                        if (dragFile?.url) {
                            dragFile.baseUrl = await toBase64(dragFile?.url)
                        }
                        if (v?.file?.url) {
                            v.file.baseUrl = await toBase64(v?.file?.url)
                        }
                        for (let x of answerList) {
                            if (x?.file?.url) {
                                x.file.baseUrl = await toBase64(x?.file?.url)
                            }
                        }
                    }

                    let updatedQuestionData = allQuestions.map((v, i) => {
                        let indexofNewQues = obj?.questions?.findIndex(x => x?._id === v?._id)
                        if (indexofNewQues !== -1) {
                            return newQuestions[indexofNewQues]
                        }
                        return v
                    })
                    setLoading(false)

                    history.push({
                        pathname: allPaths?.PARA_TEST,
                        state: {
                            allQuestions: updatedQuestionData,
                            paraQuestions: newQuestions,
                            formData
                        }
                    })
                } else {
                    setLoading(false)
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    return (
        <div className='student-main-div student-part-scroll_class flexDiv'>
            <div className='overiew1'>
                <h2>{location?.state?.passed ? 'Successfully Passed' : 'UNFORTUNATELY FAILED'}</h2>
                <p>Below you will find a study advice based on your exam results</p>
                <div style={{ backgroundColor: paraOverview?.color }} className='paraOverview'>
                    <div style={{ marginRight: 10 }} className='centerDiv'>
                        {/* <CiFaceSmile fontSize={50} fontWeight={900} color='white' /> */}
                        <h3 style={{ color: 'white', marginBottom: 0 }}>{paraOverview?.questions?.filter((x) => x?.correct)?.length} / {paraOverview?.questions?.length}</h3>
                    </div>
                    <div style={{ margin: 'auto 0' }}>
                        <h4 style={{ marginBottom: 0, color: 'white' }}>{paraOverview?.paraName}</h4>
                        <p style={{ marginBottom: 0, color: 'white', fontSize: 11 }}>{paraText(paraOverview?.questions || [])}</p>
                    </div>
                </div>
            </div>
            <div className='overiew2'>
                <h2>REVIEW ANSWERS</h2>
                <p>Below you will find the results for each section. By selecting a box you get an extensive motivation</p>
                <div >
                    <Divider />
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 10 }}>
                            {
                                paraOverview?.like ?
                                    <AiFillLike fontSize={40} color='#388E3C' />
                                    :
                                    <AiFillDislike fontSize={40} color='#DC4C35' />
                            }
                        </div>
                        <div className='centerDiv' >
                            <h3 style={{ marginBottom: 0 }}>{paraOverview?.questions?.[0]?.chapter}</h3>
                        </div>
                    </div>

                    <OverviewBoxes questions={paraOverview?.questions || []} setQuestion={setOverviewQuestion} />
                </div>
                <br />

                {user?.userType === 'guest' && !paraOverview?.questions[0]?.practice &&
                    <Button type='primary' loading={loading} onClick={retryQuestions} >Retry</Button>}
            </div>

            <div className='test-footer'>
            </div>
        </div>
    )
}

export default ParagraphOverview
