import React from 'react'
import { Faq } from '../../../Screens'

const NewStudentFaq = (props) => {
    const { selectedLanguage } = props
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote4}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title padding-30-for-faq'>{selectedLanguage?.txt_FAQ}</h3>
                <Faq {...props} />
                <br />

            </div>
        </div>
    )
}
export default NewStudentFaq