import { SmileOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Image, Input, Modal, Radio, Result, Select, Skeleton, Upload } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Page404 } from '../../../Config/routes'
import logo from '../../../assets/invoice-icon.jpeg'
import { ADMIN, GET, POST } from '../../../utils/apis'
import { allPaths } from '../../../utils/constants'
import { errorMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import NlLanguage from '../../../Languages/NetherlandsLanguage.json'
import EnglishLanguage from '../../../Languages/EnglishLanguage.json'

const { Option } = Select

const MyForm = (props) => {
    const { history, match } = props
    const { token } = match.params
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const lanKorCondition = language?.language === 'english' ? true : false
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [isValid, setValid] = useState(false)
    const [formData, setFormData] = useState([])
    const formRef = useRef(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    let answerList = []
    const showModal = () => {
        setIsModalVisible(true);
    };
    const Loginuser = () => {
        history.push(allPaths.LOGIN)
    }
    useEffect(() => {
        const { match } = props
        const { token } = match.params
        console.log(token, '===============')
        axios.get(`${GET?.FORM}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    setValid(true)
                    setFormData(data?.data || [])
                }
                setPageLoading(false)
            })
            .catch(e => {
                console.log('not found', e)
                setPageLoading(false)
            })
    }, [])

    const normFile = (e) => {
        return e?.fileList;
    }

    const onFinish = async (values) => {
        setLoading(true)
        let obj = { formId: token, email: values.email, name: values.name }

        delete values?.email
        delete values?.name
 
        const keys = Object.keys(values)
        const allvalues = Object.values(values)

        let points = 0
        for (let i = 0; i < keys.length; i++) {
            if (formData[0]?.questionsType) {
                let answerList2 = formData[0]?.questions?.filter((v) => v?._id === keys[i])[0]
                let answerPoint = answerList2?.point
                let correctAnswer = answerList2?.answerList?.filter((x) => x?.isTrue)?.map((x) => x?.option)
                let givenAnswer = values?.[keys[i]]
                if (answerList2?.answerType === 'multiple' && (givenAnswer?.length === correctAnswer?.length) && !(_.difference(givenAnswer, correctAnswer)?.length)) {
                    points += answerPoint
                }
                else if ((answerList2?.answerType === 'radio' || answerList2?.answerType === 'single') && correctAnswer?.includes(givenAnswer)) {
                    points += answerPoint
                } else if (answerList2?.answerType === 'input' || answerList2?.answerType === 'upload') {
                    points += answerPoint
                }
            }

            answerList.push({
                questionId: keys[i],
                question: formData[0]?.questions[i]?.question,
                answer: [allvalues[i]]?.flat(),
                answerType: formData[0]?.questions[i]?.answerType
            })
        }
        obj.feedBack = answerList

        for (let i = 0; i < obj?.feedBack?.length; i++) {
            const element = obj?.feedBack[i];

            if (element?.answer[0]?.originFileObj) {
                let fileData = new FormData()
                fileData.append('file', element?.answer[0]?.originFileObj)
                await axios.post(ADMIN.GET_FILE_URL, fileData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            element.answer[0] = data?.data
                        } else {
                            return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
                    })
            }

        }

        obj.scoredPoints = points 

        axios.post(`${POST?.FEEDBACK}`, obj)
            .then((result) => {
                const { data } = result
                setLoading(false)
                if (data.success) {
                    showModal()
                    form.resetFields()
                }
            }).catch(e => {
                console.log('e', e)
                setLoading(false)
            })
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'><img className='postal-logo' src={logo} /></p>
                    {pageLoading ? <Skeleton paragraph={{ rows: 12 }} active loading /> : isValid ? <div>
                        {formData.map((v, i) => {
                            return (
                                <>
                                    <p key={i} className='heading head-center'>{v?.formName}</p>
                                    <Form
                                        name='form'
                                        form={form}
                                        onFinish={onFinish}
                                        layout={'vertical'}
                                        ref={formRef}
                                    >
                                        {/* <h5>{`Total Points: ${v?.totalPoints}`}</h5>
                                        <h5>{`N-term : ${v?.nTerm || 0}`}</h5>
                                        <h5>{`Free point : ${v?.freePoints || 0}`}</h5> */}
                                        <br />
                                        {
                                            v?.formType == 'personal' ?
                                                <div style={{}}>
                                                    <Form.Item
                                                        name='name'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: selectedLanguage?.errorMessage_PleaseinputyourName,
                                                            }
                                                        ]}
                                                        label={selectedLanguage?.label_Enteryourname}
                                                        required
                                                    >
                                                        <Input placeholder='e.g Tommy' />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name='email'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: selectedLanguage?.PleaseInputYourEmail,
                                                            }
                                                        ]}
                                                        label={selectedLanguage?.label_EnteryourEmail}
                                                        required
                                                    >
                                                        <Input placeholder='e.g abc@gmail.com' />
                                                    </Form.Item>
                                                </div>
                                                : null
                                        }
                                        <br />
                                        {v?.questions?.map((q, index) => {
                                            return (
                                                <>
                                                    <h5 key={index}>{q?.title}</h5>
                                                    {
                                                        q?.file?.mediaType == 'video' ?
                                                            <>
                                                                <video onEnded={(e) => document.exitFullscreen()} width={300} height={150} controls>
                                                                    <source src={q?.file?.url} />
                                                                </video>
                                                                <br />
                                                            </>
                                                            :
                                                            q?.file?.mediaType == 'image' ? <><Image src={q?.file?.url} width={100} height={'auto'} /> <br /></> : null
                                                    }
                                                    <h5 >{q?.question}</h5>
                                                    {q?.answerType === 'radio' ?
                                                        <>
                                                            <Form.Item
                                                                name={`${q?._id}`}
                                                                label={selectedLanguage?.title_Answer}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: selectedLanguage?.errorMessage_PleasefillyourAnswer,
                                                                    }
                                                                ]}
                                                            >
                                                                <Radio.Group >
                                                                    {q?.answerList.map((radioAnswerlist, radioI) => {
                                                                        return (
                                                                            <>
                                                                                {radioAnswerlist?.file?.mediaType == 'video' ?
                                                                                    <>
                                                                                        <video onEnded={(e) => document.exitFullscreen()} width={300} height={150} controls>
                                                                                            <source src={radioAnswerlist?.file?.url} />
                                                                                        </video>
                                                                                        <br />
                                                                                    </>
                                                                                    :
                                                                                    radioAnswerlist?.file?.mediaType == 'image' ? <><Image src={radioAnswerlist?.file?.url} width={100} height={'auto'} /> <br /></> : null}
                                                                                <Radio value={radioAnswerlist?.option} key={radioI}>{radioAnswerlist?.option}</Radio>
                                                                                <br />
                                                                            </>)
                                                                    })}
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </>
                                                        : q.answerType === 'multiple' ?
                                                            <>
                                                                {
                                                                    q?.explanation ? <h5 >{`${selectedLanguage?.label_Explanation} : ${q?.explanation}`}</h5> : null
                                                                }
                                                                {q?.mediaType == 'video' ?
                                                                    <>
                                                                        <video onEnded={(e) => document.exitFullscreen()} width={400} height={150} controls>
                                                                            <source src={q?.mediaUrl} />
                                                                        </video>
                                                                    </>
                                                                    : q?.mediaType == 'image' ? <><Image src={q?.mediaUrl} width={400} height={150} /><br /></> : null
                                                                }
                                                                <Form.Item
                                                                    name={`${q?._id}`}
                                                                    label={selectedLanguage?.label_SelectAnswer}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: selectedLanguage?.errorMessage_PleaseSelectyourAnswer,
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Checkbox.Group>
                                                                        {q?.answerList.map((radioAnswerlist, radioI) => {
                                                                            return (
                                                                                <>
                                                                                    {/* <Radio value={radioAnswerlist?.option} key={radioI}>{radioAnswerlist?.option}</Radio> */}
                                                                                    {radioAnswerlist?.file?.mediaType == 'video' ?
                                                                                        <>
                                                                                            <video onEnded={(e) => document.exitFullscreen()} width={300} height={150} controls>
                                                                                                <source src={radioAnswerlist?.file?.url} />
                                                                                            </video>
                                                                                            <br />
                                                                                        </>
                                                                                        :
                                                                                        radioAnswerlist?.file?.mediaType == 'image' ? <><Image src={radioAnswerlist?.file?.url} width={100} height={'auto'} /> <br /></> : null}

                                                                                    <Checkbox value={radioAnswerlist?.option} key={radioI}>{radioAnswerlist?.option}</Checkbox>
                                                                                    <br />
                                                                                </>)
                                                                        })}
                                                                    </Checkbox.Group>
                                                                </Form.Item>
                                                            </>
                                                            : q?.answerType === 'single' ? <>
                                                                <Form.Item
                                                                    name={`${q?._id}`}
                                                                    label={selectedLanguage?.label_SelectAnswer}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: selectedLanguage?.errorMessage_PleaseSelectyourAnswer,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showArrow
                                                                        allowClear
                                                                        placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                                                    >
                                                                        {q?.answerList.map((singleAnswerlist, SingleI) => {
                                                                            return (
                                                                                <Option value={singleAnswerlist?.option} key={SingleI}>{singleAnswerlist?.option}</Option>

                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </>
                                                                : q?.answerType === 'upload' ? <>
                                                                    <Form.Item
                                                                        name={`${q?._id}`}
                                                                        label={selectedLanguage?.title_Answer}
                                                                        hasFeedback
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: selectedLanguage?.requiredMessage_pleaseUploadafile,
                                                                            }
                                                                        ]}
                                                                        getValueFromEvent={normFile}
                                                                    >
                                                                        <Upload
                                                                            className='questionFileUpload'
                                                                            multiple={false}
                                                                            maxCount={1}
                                                                            beforeUpload={() => false}
                                                                            accept='image/*, video/*'
                                                                            action='/upload.do'
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                            <br />
                                                                        </Upload>
                                                                    </Form.Item>
                                                                </>
                                                                    : <>
                                                                        {
                                                                            q?.explanation ? <h5 >{`${selectedLanguage?.label_Explanation} : ${q?.explanation}`}</h5> : null
                                                                        }
                                                                        {q?.mediaType == 'video' ?
                                                                            <>
                                                                                <video onEnded={(e) => document.exitFullscreen()} width={400} height={150} controls>
                                                                                    <source src={q?.mediaUrl} />
                                                                                </video>
                                                                            </>
                                                                            : q?.mediaType == 'image' ? <Image src={q?.url} width={400} height={150} /> : null
                                                                        }
                                                                        <Form.Item
                                                                            name={`${q?._id}`}
                                                                            label={selectedLanguage?.title_Answer}
                                                                            hasFeedback
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: selectedLanguage?.errorMessage_PleasefillyourAnswer,
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                className='form-input'
                                                                            />
                                                                        </Form.Item>
                                                                    </>
                                                    }
                                                </>
                                            )
                                        })}
                                        <Form.Item>
                                            <Button
                                                type='primary'
                                                className='form-button'
                                                block
                                                htmlType='submit'
                                                loading={loading}
                                            >
                                                {selectedLanguage?.button_Submit}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>
                            )
                        })}
                        <Modal footer={null} closable={false} title='' open={isModalVisible} >
                            <Result
                                status='success'
                                icon={<SmileOutlined />}
                                title={selectedLanguage?.title_Thankyousomuchforthisfeedback}
                                extra={<>
                                    {/* <Button onClick={AgainFeedBack} type='primary'>Submit another Feedback</Button> */}
                                    {/* <br /> */}
                                    <br />
                                    <br />
                                    <Button onClick={Loginuser} type='primary'>{selectedLanguage?.Login}</Button>
                                </>}
                            />
                        </Modal>
                    </div> : <Page404 />}
                    {!formData.length ? <Page404 /> : console.log("Page found")}
                </div>
            </div>
        </div>
    )
}

export default MyForm