import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DragSequence = (props) => {
    const { imgUrl, spots, currentQuestionObj, quesChng, declareAnswer, marked } = props
    const [draggedSeqSpots, setDraggedSeqSpots] = useState(currentQuestionObj?.answerGiven || [])
    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        setTimeout(() => {
            handleResize()
        }, 1000)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [imgUrl, spots, draggedSeqSpots, marked])

    useEffect(() => {
        if (currentQuestionObj?._id) {
            createSeqResult()
        }
    }, [quesChng])

    useEffect(() => {

        if (currentQuestionObj?._id) {
            setDraggedSeqSpots(currentQuestionObj?.answerGiven || [])
        }

    }, [currentQuestionObj])

    const createSeqResult = () => {
        declareAnswer(currentQuestionObj, draggedSeqSpots)
        setDraggedSeqSpots([])
    }


    const handleResize = () => {
        const image = document.getElementById('image1')
        const imageWidth = image.clientWidth
        const circleHeight = (imageWidth) * 0.1

        const dragCircle = document.getElementById(`dragCircle`)
        const checkIcon = document.getElementById(`checkIcon`)
        if (dragCircle) {
            dragCircle.style.fontSize = circleHeight + 'px'
        }
        if (checkIcon) {
            checkIcon.style.fontSize = circleHeight + 'px'
        }

        spots.forEach((v, i) => {
            const circle = document.getElementById(`spot${v?.id}`)
            if (circle) {
                circle.style.height = circleHeight + 'px'
            }

            const seqdragCircle = document.getElementById(`dragCircle${i + 1}`)

            if (seqdragCircle) {
                seqdragCircle.style.height = circleHeight + 'px'
                seqdragCircle.style.width = circleHeight + 'px'
            }
        })
    }

    const SequenceDraggableCircle = ({ id, noMargin }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'circle',
            item: { id },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })

        const style = {
            position: 'absolute',
            opacity: isDragging ? 0 : 1,
            width: '10%',
            marginLeft: noMargin ? 0 : `${10 * (id - 1)}%`,
            backgroundColor: 'darkOrange',
            cursor: 'move',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            zIndex: 'auto',
            borderWidth: 'thick',
            borderColor: 'white',
            borderStyle: 'solid',
            transform: 'translate(0%, 0%)',
        }

        return (
            <div ref={drag} className='circle' id={`dragCircle${id}`} style={style}>
                <h1 style={{ fontSize: '1em' }} className='spotNum'>{id}</h1>
                {/* <h1 style={{ fontSize: window.innerWidth * 0.03 }} className='spotNum'>{id}</h1> */}
            </div>
        )
    }

    const SequenceDropZone = (data) => {
        const { spot } = data
        const [{ canDrop, isOver }, drop] = useDrop({
            accept: 'circle',
            drop: (item, monitor) => {
                handleSequenceDrop(item, spot)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        })

        let collectedSpot = draggedSeqSpots.find((v) => v?.spot === spot?.id + 1)
        const isActive = canDrop && isOver
        const backgroundColor = isActive ? 'lightgreen' : 'orange'
        const style = {
            position: 'absolute',
            opacity: collectedSpot?.circle ? 1 : canDrop ? 0.5 : 0,
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            borderRadius: '50%',
            zIndex: 2,
        }
        return (
            <div id={`spot${spot?.id}`} ref={drop} style={style}>
                {collectedSpot?.circle && (
                    <SequenceDraggableCircle id={collectedSpot?.circle} noMargin={true} />
                )}
            </div>
        )
    }

    const handleSequenceDrop = (item, spot) => {
        let newArray = draggedSeqSpots
        newArray = newArray.filter((v) => v?.circle !== item.id)
        newArray = newArray.filter((v) => v?.spot !== spot?.id + 1)
        newArray.push({ spot: spot?.id + 1, circle: item.id })
        setDraggedSeqSpots(newArray)
    }
    return (
        <>
            <div style={{ width: window.innerWidth < 700 ? '100%' : '40%', position: 'relative' }}>
                <img src={imgUrl} id='image1' width={'100%'} alt='' />
                {spots.map((spot) => (
                    <SequenceDropZone
                        key={spot.id}
                        spot={spot}
                    />
                ))}
            </div>
            <br />
            <div style={{ height: '6vh', position: 'relative', display: 'flex' }}>
                {spots?.map((v, i) => {
                    let condition = draggedSeqSpots.find((v) => v?.circle === i + 1)?.circle
                    if (!condition) {
                        return (<SequenceDraggableCircle id={i + 1} />)
                    }
                })}
            </div>
            <br/>
            <br/>
        </>
    )
}


export default DragSequence
