import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { ADMIN } from '../../../utils/apis';
import { adminData, errorMessage, successMessage } from '../../../utils/helpers';

const AdminAddImages = (props) => {
    const { selectedLanguage, adminActions, showAddImage, onCancel, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        console.log('values', values)
        let formData = new FormData()
        for (let v of values?.allFiles) {
            if (!v?.upload?.length) {
                return errorMessage(selectedLanguage?.txt_Pleaseuploadfiles)
            }
            for (let x of v?.upload) {
                formData.append(v?.name, x?.originFileObj)
            }
        }
        setLoading(true)

        axios.post(ADMIN?.ADD_ADMIN_IMAGES, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    form.resetFields()
                    adminData.getAdminImages(adminActions)
                    onCancel()
                } else {
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })

    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    return (
        <Modal
            open={showAddImage}
            footer={false}
            onCancel={onCancel}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <div>
                <p className='new-p-question'>{selectedLanguage?.txt_UploadFiles}</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                    requiredMark={true}
                >
                    <Form.List name='allFiles' initialValue={[{ name: null, upload: null }]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8
                                        }}
                                        align='baseline'
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.txt_pleaseentername,
                                                }
                                            ]}
                                        // label={'Enter name'}
                                        // required
                                        >
                                            <Input placeholder={selectedLanguage?.txt_Enter_name} />
                                        </Form.Item>
                                        <>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'upload']}
                                                // name='upload'
                                                // label='Upload'
                                                valuePropName='fileList'
                                                getValueFromEvent={normFile}
                                                // extra='longgggggggggggggggggggggggggggggggggg'
                                                required
                                            >
                                                <Upload
                                                    multiple={true}
                                                    accept='image/*, .ai'
                                                    name='logo'
                                                    action='/upload.do'
                                                    listType='picture'
                                                    beforeUpload={() => false}
                                                    type='primary'
                                                >
                                                    <Button icon={<UploadOutlined />}>{selectedLanguage?.txt_Click_to_upload}</Button>
                                                </Upload>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                        {selectedLanguage?.txt_Addfield}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.txt_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    )
}
export default AdminAddImages