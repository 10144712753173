import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import { Form, Button, Input, message } from 'antd'
import { AUTH, GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { Page404 } from '../../Config/routes'
import { errorMessage } from '../../utils/helpers'
import testallyLogo from '../../assets/testallyLogo-removebg.png'

import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
const UpdatePassword = (props) => {
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const lanKorCondition = language?.language === 'english' ? true : false
    const { history } = props
    const path = history?.location?.pathname
    const [state, setState] = useState({
        loading: true,
        success: false,
        buttonLoading: false,
        user: null
    })

    useEffect(() => {
        const { match } = props
        const { token } = match.params
        axios.get(`${GET.VERIFY_TOKEN}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    updateState({ message: data.message, success: data.success, loading: false, user: data.user })
                }
                else {
                    updateState({ message: data.message, success: data.success, loading: false })
                }
            })
            .catch(e => {
                updateState({ message: 'Please refresh this page', success: false, loading: false })
            })
    }, [])

    const updateState = (obj) => {
        setState({ ...state, ...obj })
    }

    const onFinish = (values) => {
        const { user } = state
        let redirectRoute = path?.indexOf(allPaths?.BU_UPDATE_PASSWORD) !== -1 ? allPaths?.BU_LOGIN : allPaths?.LOGIN

        if (user) {

            values.email = user?.email
            values.userType = path?.indexOf(allPaths?.BU_UPDATE_PASSWORD) !== -1 ? 'business' : 'guest'

            axios.post(AUTH.UPDATE_PASSWORD, values)
                .then((result) => {
                    const { data } = result
                    if (data.success) {
                        message.success('Password Successfully changed')
                        setTimeout(() => {
                            history.push(redirectRoute)
                        }, 500)
                    }
                    else {
                        message.error(data.message)
                    }
                })
                .catch((err) => {
                    errorMessage(lanKorCondition ? err?.message : err?.messageNl)

                })
        }
        else {
            errorMessage('user not found!')
        }
    }

    const onFinishFailed = (err) => {

    }

    return (
        <div className='new-layout-scroll-class'
            style={{
                height: '100vh',
                background: '#A4C8CE',
                overflow: 'auto'
            }}>
            {!state.loading ?
                <>
                    {state?.success ?
                        <div className='auth-screen-main-div'>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                <img className='login-top-img' src={testallyLogo} />
                            </div>
                            <p className='heading head-center'>{'Update password'}</p>
                            <Form
                                name='basic'
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    name='password'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new pssword',
                                        },
                                    ]}
                                >
                                    <Input.Password className='form-input-new-style' placeholder={'Enter your new password'} />
                                </Form.Item>
                                <Form.Item
                                    name='confirm password'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords that you enter dees not match');
                                            },
                                        })
                                    ]}
                                >
                                    <Input.Password className='form-input-new-style' placeholder={'Confirm your password'} />
                                </Form.Item>
                                <Form.Item>
                                    <Button

                                        className='newlogin-btn form-input-new-style'
                                        type='primary'
                                        htmlType='submit'
                                        block
                                        loading={state.buttonLoading}
                                        style={{
                                            height: '45px',
                                            width: '99%',
                                            marginTop: '10px',
                                            marginLeft: '0.5%'
                                        }}
                                    >
                                        {'Update'}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div> :
                        <Page404 {...props} />
                    }
                </>
                :
                <Skeleton paragraph={{ rows: 22 }} />}
        </div >
    )
}

export default UpdatePassword