import { Button, Modal, Select, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { checkSelectOption, teacherData, toBase64 } from '../../utils/helpers'


const BuResult = (props) => {
    const { user, selectedLanguage, history, language, userActions } = props
    const myStudents = useSelector(state => state?.userReducer?.myStudents || [])
    const [selectedType, setSelectedType] = useState(myStudents?.[0]?._id || null)
    const { Option } = Select

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [overviewSpin, setOverviewSpin] = useState({})
    const [modalData, setModalData] = useState({})

    useEffect(() => {
        teacherData.getMyStudents(userActions, user?._id)
    }, [])

    useEffect(() => {
        getQuestionReports()
    }, [selectedType])

    const getQuestionReports = () => {
        setLoading(true)
        axios.get(`${GET.GET_MY_STUDENT_RESULT}/?teacher=${user?._id}&student=${selectedType}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setResults(data?.data || [])
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_ExamName}</p>,
            dataIndex: ['formId', 'formName'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Result}</p>,
            dataIndex: 'passed',
            render: (e) => e ? <>{selectedLanguage?.txt_Pass}</> : <>{selectedLanguage?.txt_Fail}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Overview}</p>,
            key: 'actions',
            align: 'center',
            render: (e) => <>
                <Button type='default' loading={overviewSpin[e?._id]} onClick={async () => {
                    setOverviewSpin({ [e?._id]: true })

                    let newQuestions = JSON.parse(JSON.stringify(e?.allQuestions))

                    for (let v of newQuestions) {
                        let dragFile = v?.dragFile
                        let answerList = v?.answerList
                        if (dragFile?.url) {
                            dragFile.baseUrl = await toBase64(dragFile?.url)
                        }
                        if (v?.file?.url) {
                            v.file.baseUrl = await toBase64(v?.file?.url)
                        }
                        for (let x of answerList) {
                            if (x?.file?.url) {
                                x.file.baseUrl = await toBase64(x?.file?.url)
                            }
                        }
                    }
                    let chapResults = _.groupBy(newQuestions, 'chapter')
                    let paraResults = _.groupBy(newQuestions, 'paragraph')

                    let chapPoints = {}
                    let formLimit = e?.formId?.limit
                    let allKeys = Object.keys(chapResults)
                    let checkAllTrue = []

                    for (let v of allKeys) {
                        chapPoints[v] = chapResults[v]?.reduce((a, { point }) => a + point, 0)
                        checkAllTrue.push(chapPoints[v] >= formLimit[v])
                    }

                    setOverviewSpin({})
                    history.push({
                        pathname: allPaths?.OVERVIEW,
                        state: {
                            chResults: chapResults,
                            chPoints: chapPoints,
                            formData: e?.formId,
                            paragraphResults: paraResults,
                            passed: e?.passed,
                            allQuestions: newQuestions
                        }
                    })
                }} > {selectedLanguage?.txt_Overview} </Button>
                &nbsp;
                <Button type='default' onClick={() => setModalData(e?.retries)} > {selectedLanguage?.Details} </Button>
            </>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>

            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Results}</h3>
                <br />
                <div className='flex-mobile' style={{ display: 'flex', width: '98%', margin: '0px auto' }}>
                    <Select
                        style={{ width: 350 }}
                        showSearch
                        showArrow
                        allowClear={false}
                        defaultValue={selectedType}
                        onChange={(e) => setSelectedType(e)}
                        className='form-input'
                        placeholder={selectedLanguage?.SelectUser}
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {myStudents?.map((v, i) => <Option value={v?._id} key={i}>{v?.fullName}</Option>)}
                    </Select>
                </div>
                <br />
                <div className='new-layout-table-upper-div'>
                    <Table
                        className='new-layout-table'
                        columns={columns}
                        dataSource={results}
                        loading={loading}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>

            <Modal
                open={Object.keys(modalData)?.length}
                footer={null}
                onCancel={() => setModalData({})}
                className='pop-info-modal'
                style={{ top: '10px' }}>
                    <p className='new-p-question'>{selectedLanguage?.txt_NumberofParagraphRetries}</p>
                <ul>
                    {Object.keys(modalData)?.map((v, i) => <li key={i}>
                        {v} : {modalData?.[v]}
                    </li>)}
                </ul>
            </Modal>
        </div>
    )
}

export default BuResult