import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { studentData } from '../../../utils/helpers';
import TheoryCharts from './TheoryCharts';
import ResultChart from './ResultChart';
import TraningChart from './TraningChart';
import ExamChart from './ExamChart';
import StudentsVideoChart from './VideoChart';

const NewStudentDashboard = (props) => {
    const { selectedLanguage, userActions, user, } = props
    const dashboardData = useSelector(state => state.userReducer?.dashboardData)
    const [cardData, setCardData] = useState({ scoreOptions: 'opened', teacher: 'all' })

    console.log('dashboardData=dashboardData', dashboardData)

    useEffect(() => {
        studentData.getDashboardData(userActions, user?._id)
    }, [])

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote2}</p>

            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title'>{selectedLanguage?.txt_Dashboard}</h3>
                <div style={{ paddingTop: '20px' }}>
                    <div className='dashboard-main-div'>
                        <div className='dashboard-inner-div-same-page'>
                            <h1 className='dashboard-card-title'>{selectedLanguage?.txt_theroy}</h1>
                            <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                                <TheoryCharts data={dashboardData?.cardData || []} text={selectedLanguage?.txt_txtCards} {...props} />
                            </div>
                            <br />
                            <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                                <TheoryCharts data={dashboardData?.videoData || []} text={selectedLanguage?.txtx_Video} {...props} />
                            </div>
                        </div>
                        <div className='dashboard-inner-div-same-page'>
                            <h1 className='dashboard-card-title'>{selectedLanguage?.txt_Traning}</h1>
                            <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                                <TraningChart data={dashboardData?.trainingData || {}} text={selectedLanguage?.txt_Train} {...props} />
                            </div>
                            <br />
                            <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                                <TraningChart data={dashboardData?.test10Data || {}} text={selectedLanguage?.txt_Test10} {...props} />
                            </div>
                        </div>
                        <div className='dashboard-inner-div-same-page'>
                            <h1 className='dashboard-card-title'>{selectedLanguage?.txt_Exam}</h1>
                            <div className='dashboard-div-1st-baby-child' style={{ background: '#A4C8CE' }}>
                                <ExamChart data={dashboardData?.examData || []} text={selectedLanguage?.txt_Exams} {...props} />
                            </div>
                            <br />
                            <div className='dashboard-div-1st-baby-child ' style={{ background: '#A4C8CE' }}>
                                <ResultChart data={dashboardData?.resultData || []} selectedLanguage={selectedLanguage} {...props} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewStudentDashboard