import { Button, Form, Input, Modal, Select, Switch, Upload } from 'antd';
import React, { useState } from 'react';
import { adminData, checkSelectOption, errorMessage, successMessage } from '../../../utils/helpers';
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios';
import { ADMIN } from '../../../utils/apis';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const { Option } = Select
const AdminEditVideo = (props) => {
    const { selectedLanguage, adminActions, data, onCancel, subjects, lanKorCondition } = props
    const chapters = useSelector(state => state?.generalReducer?.chapters || [])
    const allTeachers = useSelector(state => state.generalReducer.allTeachers) || []
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [train, setTrain] = useState(data?.train || false)
    const [form] = Form.useForm()

    let optionsChapter = chapters?.map((v) => v?.chapterName)
    optionsChapter = _.uniq(optionsChapter)
    let optionsParagraph = chapters?.map((v) => v?.paragraph).flat().map((x) => x?.paragraphName)
    optionsParagraph = _.uniq(optionsParagraph)
    let optionsTopics = chapters?.map((v) => v?.paragraph)?.flat()?.map((x) => x?.topics)?.flat()?.map((z) => z?.topicName)
    optionsTopics = _.uniq(optionsTopics)

    const normFile3 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }

        return setFileList(e?.fileList);
    }
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }
    const onFinish = async (values) => {
        setLoading(true)
        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        setLoading(false)
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)


                })
        }

        values._id = data?._id
        axios.post(ADMIN?.EDIT_VIDEO, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    onCancel(false)
                    adminData.getAdminVideos(adminActions)
                    return successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    // setTimeout(() => {
                    //     return window.location.reload()
                    // }, 3000);
                } else {
                    setLoading(false)
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(lanKorCondition ? data?.message : data?.messageNl)
            })
    }
    console.log(data)
    return (
        <div>
            <Modal
                open={data?._id}
                footer={false}
                onCancel={() => onCancel()}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <div>
                    <p className='new-p-question'>{selectedLanguage?.txt_Editvideo}</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout='vertical'
                        requiredMark={true}
                    >
                        <Form.Item
                            name='name'
                            initialValue={data?.name}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Pleaseentervideoname,
                                }
                            ]}
                            label={selectedLanguage?.txt_Entervideoname}
                            required
                        >
                            <Input autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.ttx_Entervideoname} />
                        </Form.Item>
                        <Form.Item
                            name='discription'
                            initialValue={data?.discription}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_PleaseEnterVideDescription,
                                }
                            ]}
                            label={selectedLanguage?.ttx_videodiscripion}
                            required
                        >
                            <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.txt_Entervideodiscription} />
                        </Form.Item>
                        <Form.Item
                            name='subject'
                            label={selectedLanguage?.label_SelectSubject}
                            initialValue={data?.subject?._id}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_Pleaseselectsubject
                                }
                            ]}
                        >
                            <Select
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                                placeholder={selectedLanguage?.label_SelectSubject}>
                                <Option value={null}>{null}</Option>
                                {subjects.map((v, i) => {
                                    return (
                                        <Option key={i} value={v?._id}>{v?.subjectName}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={'train'}
                            valuePropName='checked'
                            initialValue={data?.train || false}
                        >
                            <Switch onChange={(e) => setTrain(e)} style={{ marginRight: 5, marginTop: 5 }} checkedChildren={selectedLanguage?.btn_Train} unCheckedChildren={selectedLanguage?.txt_Questions} />
                        </Form.Item>
                        {train && <>
                            <Form.Item
                                name='chapter'
                                label={selectedLanguage?.txt_Chapter}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectChapter,
                                    },
                                ]}
                                initialValue={data?.chapter || null}
                            >
                                <Select
                                    // onChange={onChangeSubject}
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.label_SelectChapters}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}

                                >
                                    {optionsChapter.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{v}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='paragraph'
                                label={selectedLanguage?.txt_Paragraph}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectParagraph,
                                    },
                                ]}
                                initialValue={data?.paragraph || null}
                            >
                                <Select
                                    // onChange={onChangeSubject}
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.placeholder_Paragraph}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}

                                >
                                    {optionsParagraph.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{v}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='topics'
                                label={selectedLanguage?.txt_Topics}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectTopic,
                                    }
                                ]}
                                initialValue={data?.topics || null}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.txt_SelectTopicHere}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}

                                >
                                    {optionsTopics.map((v, i) => {
                                        return (
                                            <Option value={v} key={i}>{v}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='teachers'
                                label={selectedLanguage?.txt_Teachers}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_pleaseSelectTeacher,

                                    }
                                ]}
                                initialValue={data?.teachers || null}
                            >
                                <Select
                                    mode='multiple'
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder={selectedLanguage?.txt_PleaseSelectTeachers}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {allTeachers.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={i}>{v?.fullName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </>}
                        <Form.Item
                            label={selectedLanguage?.txt_Selectvideo}
                            name='file'
                            style={{ width: '100%' }}
                            valuePropName='file'
                            getValueFromEvent={normFile3}
                        >
                            <Upload
                                className='questionFileUpload'
                                multiple={false}
                                maxCount={1}
                                beforeUpload={() => false}
                                fileList={fileList}
                                onChange={handlePreview2}
                                accept='video/*'
                                action='/upload.do'
                                style={{ width: '100%' }}
                            >
                                <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToReplace}</Button>
                                <br />
                            </Upload>
                        </Form.Item>{!fileList?.length &&
                            <div>
                                <video width={'200px'} height={'auto'}
                                    style={{ borderRadius: '12px' }}
                                    controls
                                >
                                    <source src={data?.file?.url} />
                                </video>
                                <p style={{ marginTop: 0 }}>{data?.file?.name}</p>
                            </div>
                        }
                        <Form.Item >
                            <Button
                                htmlType='submit'
                                style={{ borderRadius: 4, width: '100%' }}
                                type='primary'
                                loading={loading}
                            >
                                {selectedLanguage?.txt_Submit}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

            </Modal>
        </div>
    )
}
export default AdminEditVideo