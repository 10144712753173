import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Popover, Select, Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Nodataimage from '../../../../src/assets/NewImages/Nodata.gif'
import { POST } from '../../../utils/apis'
import { allPaths } from '../../../utils/constants'
import { calculateReviewAverage, checkLuxurySub, checkSelectOption, errorMessage, studentData, toBase64 } from '../../../utils/helpers'
import CardsReviewScreen from '../Review/StudentCardsReview'

const { Option } = Select

const StudentVideo = (props) => {
    const { user, userActions, selectedLanguage, history } = props
    const studentVideos = useSelector(state => state?.userReducer?.studentVideos || [])
    const [showDetails, setShowDetails] = useState({})
    const [videoDetailForReview, setVideoDetailForReview] = useState({})
    const [data, setData] = useState(studentVideos)
    const [spin, setSpin] = useState({})
    useEffect(() => {
        if (!checkLuxurySub(user?.subscription)) {
            history.push(allPaths.GUEST_HOME)
            return errorMessage(selectedLanguage?.txt_PleaseBuysubscriptionforthisFeature)
        }else{
            studentData.getStudentVideos(userActions, user?._id)
        }
    }, [])

    const onChange = (e) => {
        if (!e) {
            setData(studentVideos)
        } else {
            let filteredArray = studentVideos.filter(item => item.name.toLowerCase().includes(e.toLowerCase().trim()))
            setData(filteredArray)
        }
    }
    const handleOk = () => {
        setShowDetails({})
    }
    const getTrainQuestions = (e) => {
        setSpin({ [e?._id]: true })
        let obj = JSON.parse(JSON.stringify(e))
        obj.subject = obj?.subject?._id
        obj.userId = obj?.userId?._id || null
        obj.student = user?._id
        axios.post(`${POST.GET_VIDEO_TRAINING_QUESTIONS}`, obj)
            .then(async (res) => {
                const { data } = res
                setSpin({})
                if (data.success) {
                    if (data?.data?.length) {
                        let newQuestions = JSON.parse(JSON.stringify(data?.data))

                        for (let v of newQuestions) {
                            let dragFile = v?.dragFile
                            let answerList = v?.answerList
                            if (dragFile?.url) {
                                dragFile.baseUrl = await toBase64(dragFile?.url)
                            }
                            if (v?.file?.url) {
                                v.file.baseUrl = await toBase64(v?.file?.url)
                            }
                            for (let x of answerList) {
                                if (x?.file?.url) {
                                    x.file.baseUrl = await toBase64(x?.file?.url)
                                }
                            }
                        }
                        history.push({
                            pathname: allPaths?.TOPIC_TRAINING,
                            state: {
                                trainQuestions: newQuestions,
                                topics: e?.topics,
                                video: e?._id
                            }
                        })
                    } else {
                        errorMessage(selectedLanguage?.txt_NoQuestionsavailable)
                    }
                }
            })
            .catch((e) => {
                console.log('error', e);
                setSpin({})
                errorMessage(selectedLanguage?.OopsSomethingWentWrong)
            })
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>


            <div className='new-exam-screen-upper'>
                {!videoDetailForReview?._id ?
                    <div>
                        <h3 className='new-exam-title'>{selectedLanguage?.txt_videos}</h3>
                        <div className='flex-mobile' style={{ display: 'flex', width: '98%', margin: '10px auto' }}>
                            <Select
                                style={{ maxWidth: '98%', width: 350 }}
                                onSearch={onChange}
                                showSearch
                                showArrow
                                allowClear={true}
                                onClear={() => setData(studentVideos)}
                                onSelect={onChange}
                                className='form-input'
                                placeholder={selectedLanguage?.txt_SearchVideos}
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {studentVideos?.map((v, i) => <Option value={v?.name} key={i}>{v?.name}</Option>)}
                            </Select>
                        </div>
                        <div className='three-boxes-card-div'>
                            {data?.length ?
                                data?.map((v, i) => {
                                    return (
                                        <div className='ex-tion-card' style={{ height: '380px', background: '#1eb2a6' }} key={i}>
                                            <span className='span-of-btn2'>
                                                <InfoCircleOutlined onClick={() => setShowDetails(v)} className='info_font' />

                                                <div>
                                                    <Popover content={<>
                                                        {v?.review?.length ?
                                                            calculateReviewAverage(v?.review) < 4.5 ?
                                                                selectedLanguage?.Bad + ',' :
                                                                calculateReviewAverage(v?.review) < 5.9 ?
                                                                    selectedLanguage?.Poor + ',' :
                                                                    calculateReviewAverage(v?.review) < 6.9 ?
                                                                        selectedLanguage?.Adeqate + ',' :
                                                                        calculateReviewAverage(v?.review) < 8.9 ?
                                                                            selectedLanguage?.Good + ',' :
                                                                            selectedLanguage?.Excellent + ',' : null}
                                                        {
                                                            v?.review?.length ?
                                                                v?.review?.length !== 1 ?
                                                                    v?.review?.length > 999 ?
                                                                        ' ' +
                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                        '.' +
                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                selectedLanguage?.txt_AddReview}
                                                    </>} trigger="hover">
                                                        <button
                                                            onClick={() => setVideoDetailForReview(v)}
                                                            className='card-card-train-btn height-33-33'>
                                                            {v?.review?.length ? calculateReviewAverage(v?.review) : 0}

                                                        </button>
                                                    </Popover>
                                                    &nbsp;
                                                    {spin[v?._id] ? <Spin /> :
                                                        <button
                                                            style={{ width: '40px' }}
                                                            onClick={() => getTrainQuestions(v)}
                                                            className='card-card-train-btn height-33-33'>
                                                            {selectedLanguage?.btn_Train}
                                                        </button>}
                                                </div>
                                            </span>
                                            <p className='div-tt' style={{ textAlign: 'center' }}>{v?.name}</p>
                                            <div
                                                className='new-layout-scroll-class'
                                                style={{ height: '280px', overflowY: 'auto', marginTop: 5 }}>
                                                <p className='disc'>{v?.discription}</p>
                                                <video width={'200px'} height={'auto'}
                                                    style={{ borderRadius: '12px' }}
                                                    className='tr-content-img'
                                                    controls
                                                >
                                                    <source src={v?.file?.url} />
                                                </video>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div style={{ width: '100%' }}>
                                    <center>
                                        <img src={Nodataimage} style={{
                                            height: '200px',
                                            borderRadius: '10px',
                                            margin: '10px auto'
                                        }} className='nodata-lottie' />
                                        <p className='margin0' style={{ textAlign: 'center' }}>{selectedLanguage?.txt_videos_Not_added}</p>
                                    </center>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <CardsReviewScreen type='video' onCancel={() => setVideoDetailForReview(null)} data={videoDetailForReview}{...props} />
                }
            </div>
            <Modal
                open={showDetails?._id}
                onOk={handleOk}
                onCancel={handleOk}
                className='pop-info-modal'
                style={{ top: '10px' }}

                footer={null}>
                <div>
                    <p className='new-p-question'>{selectedLanguage?.Details}</p>
                    <ul style={{ padding: '20px' }}>
                        <li>{selectedLanguage?.label_Chapter}: {showDetails?.chapter} </li>
                        <li>{selectedLanguage?.label_Paragraph}: {showDetails?.paragraph}</li>
                        <li>{selectedLanguage?.label_Topics}: {showDetails?.topics?.join(', ') || ''}</li>
                        <li>{selectedLanguage?.title_Creater}: {showDetails?.userId?.fullName || selectedLanguage?.title_Admin}</li>
                    </ul>
                </div>
            </Modal>
        </div>
    )
}
export default StudentVideo