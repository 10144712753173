import { SmileOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Radio, Result, Select, Skeleton } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Page404 } from '../../Config/routes'
import logo from '../../assets/invoice-icon.jpeg'
import { GET, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { requiredMessage } from '../../utils/helpers'
import { useSelector } from 'react-redux'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'

const { Option } = Select

const MyForm = (props) => {

    const { history, match } = props
    const { token } = match.params
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [isValid, setValid] = useState(false)
    const [formData, setFormData] = useState([])
    const formRef = useRef(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    let answerList = []
    const showModal = () => {
        setIsModalVisible(true);
    };
    const Loginuser = () => {
        history.push(allPaths.LOGIN)
    }
    useEffect(() => {
        const { match } = props
        const { token } = match.params

        axios.get(`${GET?.FORM}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    setValid(true)
                    setFormData(data?.data || [])
                }
                setPageLoading(false)
            })
            .catch(e => {
                console.log('not found', e)
                setPageLoading(false)
            })
    }, [])


    const onFinish = (values) => {
        let obj = { formId: token }

        if (formData[0]?.formType === 'personal') {
            obj.email = values.email
            obj.name = values.name
        }

        delete values?.email
        delete values?.name

        const keys = Object.keys(values)
        const allvalues = Object.values(values)

        for (let i = 0; i < keys.length; i++) {
            answerList.push({
                questionId: keys[i],
                question: formData[0]?.questions[i]?.question,
                answer: [allvalues[i]]?.flat(),
                answerType: formData[0]?.questions[i]?.answerType
            })
        }
        obj.feedBack = answerList
        console.log(obj)
        setLoading(true)

        axios.post(`${POST?.FEEDBACK}`, obj)
            .then((result) => {
                const { data } = result
                setLoading(false)
                if (data.success) {
                    showModal()
                    form.resetFields()
                }
            }).catch(e => {
                console.log('e', e)
                setLoading(false)
            })
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'><img className='postal-logo' src={logo} /></p>
                    {pageLoading ? <Skeleton paragraph={{ rows: 12 }} active loading /> : isValid ? <div>
                        {formData.map((v, i) => {
                            return (
                                <>
                                    <p key={i} className='heading head-center'>{v?.formName}</p>
                                    <Form
                                        name='form'
                                        form={form}
                                        onFinish={onFinish}
                                        layout={'vertical'}
                                        ref={formRef}
                                    >
                                        <br />
                                        {
                                            !v?.userId?.userType === 'business' && v?.formType === 'personal' ?
                                                <div style={{}}>
                                                    <Form.Item
                                                        name='name'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: selectedLanguage?.errorMessage_PleaseinputyourName,
                                                            }
                                                        ]}
                                                        label={selectedLanguage?.label_Enteryourname}
                                                        required
                                                    >
                                                        <Input placeholder='e.g Tommy' />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name='email'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: selectedLanguage?.label_EnteryourEmail,
                                                            }
                                                        ]}
                                                        label={selectedLanguage?.label_EnteryourEmail}
                                                        required
                                                    >
                                                        <Input placeholder='e.g abc@gmail.com' />
                                                    </Form.Item>
                                                </div>
                                                : null
                                        }
                                        <br />
                                        {v?.questions?.map((q, index) => {
                                            return (
                                                <>
                                                    <h5 key={index}>{`${selectedLanguage?.title_Question} : ${q?.question}`}</h5>
                                                    {q?.answerType === 'radio' ?
                                                        <>
                                                            <Form.Item
                                                                name={`${q?._id}`}
                                                                label={selectedLanguage?.title_Answer}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: selectedLanguage?.errorMessage_PleasefillyourAnswer,
                                                                    },
                                                                ]}
                                                            >
                                                                <Radio.Group >
                                                                    {q?.answerList.map((radioAnswerlist, radioI) => {
                                                                        return (
                                                                            <Radio value={radioAnswerlist?.option} key={radioI}>{radioAnswerlist?.option}</Radio>
                                                                        )
                                                                    })}
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </> : q.answerType === 'multiple' ? <>
                                                            <Form.Item
                                                                name={`${q?._id}`}
                                                                label={selectedLanguage?.label_SelectAnswer}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: selectedLanguage?.errorMessage_PleaseSelectyourAnswer,
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    showArrow
                                                                    allowClear
                                                                    mode='multiple'
                                                                    placeholder={selectedLanguage?.placeholder_SelectMultipleOption}
                                                                >
                                                                    {q?.answerList.map((multipleAnswerlist, MultipleI) => {
                                                                        return (
                                                                            <Option value={multipleAnswerlist?.option} key={MultipleI}>{multipleAnswerlist?.option}</Option>

                                                                        )
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </>
                                                            : q?.answerType === 'single' ? <>
                                                                <Form.Item
                                                                    name={`${q?._id}`}
                                                                    label={selectedLanguage?.label_SelectAnswer}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: selectedLanguage?.errorMessage_PleaseSelectyourAnswer,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showArrow
                                                                        allowClear
                                                                        placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                                                    >
                                                                        {q?.answerList.map((singleAnswerlist, SingleI) => {
                                                                            return (
                                                                                <Option value={singleAnswerlist?.option} key={SingleI}>{singleAnswerlist?.option}</Option>

                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </>
                                                                : <>
                                                                    <Form.Item
                                                                        name={`${q?._id}`}
                                                                        label={selectedLanguage?.title_Answer}
                                                                        hasFeedback
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: selectedLanguage?.errorMessage_PleasefillyourAnswer,
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            className='form-input'
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                    }
                                                </>
                                            )
                                        })}
                                        <Form.Item>
                                            <Button
                                                // onClick={showModal}
                                                type='primary'
                                                className='form-button'
                                                block
                                                htmlType='submit'
                                                loading={loading}
                                            >
                                                {selectedLanguage?.button_Submit}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>
                            )
                        })}
                        <Modal footer={null} closable={false} title='' open={isModalVisible} >
                            <Result
                                status='success'
                                icon={<SmileOutlined />}
                                title={selectedLanguage?.title_Thankyousomuchforthisfeedback}
                                extra={<>
                                    {/* <Button onClick={AgainFeedBack} type='primary'>Submit another Feedback</Button> */}
                                    {/* <br /> */}
                                    <br />
                                    <br />
                                    <Button onClick={Loginuser} type='primary'>{selectedLanguage?.Login}</Button>
                                </>}
                            />
                        </Modal>
                    </div> : <Page404 />}
                    {!formData.length ? <Page404 /> : console.log("Page found")}
                </div>
            </div>
        </div>
    )
}

export default MyForm