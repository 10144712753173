import { Button, DatePicker, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { createCouponReport, errorNotification } from '../../../utils/helpers'
const { RangePicker } = DatePicker

const CouponReport = (props) => {
    const { user, selectedLanguage } = props
    const [spinLoad, setSpinLoad] = useState(false)
    const [reports, setReports] = useState([])
    const [adminCoupons, setAdminCoupons] = useState(0)
    const [cbrCoupons, setCbrCoupons] = useState({})

    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })

    useEffect(() => {
        createReport()
    }, [dateRange])

    const createReport = () => {
        setSpinLoad(true)
        let obj = {
            date1: dateRange?.date1,
            date2: dateRange?.date2
        }
        axios.post(`${ADMIN.GET_COUPON_REPORT}`, obj)
            .then((res) => {
                const { data } = res
                setSpinLoad(false)
                if (data?.success) {
                    setReports(data?.data || [])
                    setAdminCoupons(data?.adminCoupons || 0)
                    setCbrCoupons({
                        basicCoupons: data?.basicCoupons || 0,
                        luxuryCoupons: data?.luxuryCoupons || 0,
                    })
                }
                else {
                    setReports([])
                    errorNotification(data?.message)
                }
            })
            .catch((e) => {
                errorNotification()
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const createReportRange = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }
        setDateRange(obj)
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    let columns = [
        {
            title: selectedLanguage?.title_ReportRange,
            key: 'sendingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{`${dateRange.date1} - ${dateRange.date2}`}</p>
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'actions',
            render: (e) => <div>
                <a onClick={() => createCouponReport(e, user, dateRange)}>{selectedLanguage?.Download}</a>
            </div>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <Spin spinning={spinLoad}>
                <div className='flex-mh' style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 400 }}>
                    <div className='flex-mobile' style={{ display: 'flex' }}>
                        <RangePicker
                            defaultValue={[moment().add(-7, 'days'), moment()]}
                            onChange={createReportRange}
                            style={{ marginBottom: 10 }}
                            disabledDate={disabledDate}
                        />
                        <Button className='form-input report-button' type='primary' onClick={createReport}>{selectedLanguage?.button_CreateReport}</Button>
                    </div>
                </div>
                <div className='new-layout-table-upper-div margin0'>
                    <Table
                        columns={columns}
                        className='new-layout-table'
                        dataSource={reports}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default CouponReport