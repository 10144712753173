import { Button, Form, Modal, Rate } from "antd";
import axios from 'axios';
import React, { useState } from "react";
import { POST } from "../../../utils/apis";
import { errorMessage, studentData, successMessage } from "../../../utils/helpers";

const FeedbackResponseModal = (props) => {
    const { open, onCancel, data, user, selectedLanguage, userActions, lanKorCondition } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)


    const onFinish = (value) => {
        setLoading(true)
        setDisabled(true)
        let obj = {
            feedbackId: data?._id,
            userId: user?._id,
            response: value?.response
        }
        console.log(obj)
        axios?.post(POST?.ADD_RESPONSE_TO_FEEDBACK, obj)
            .then(async (res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    await studentData.getAllFeedbackForm(userActions, user?._id)
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    onCancel()
                } else {
                    setDisabled(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setDisabled(false)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <p className='new-p-question'>{selectedLanguage?.txt_EnterYourFeedback}</p>
            <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_title}:</span>{data?.title}</p>
            <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Creater}:</span>{data?.admin ? selectedLanguage?.title_Admin : data?.user?.fullName}</p>
            <div className="login-alert-paragraph-border" />
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
                requiredMark={true}
            >
                <Form.List name='response' initialValue={data?.content}>
                    {(fields, { add, remove }) => (
                        <div className="form-feedback-student-main">
                            {fields.map(({ key, name, ...restField }) => {
                                return (
                                    <div className="form-feedback-student">
                                        <div className="feedback-student-inner-1">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description']}
                                            >
                                                <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'>&nbsp;✦</span>{data?.content[key]?.description}</p>
                                            </Form.Item>
                                        </div>
                                        <div className="feedback-student-inner-2">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value1']}
                                            >
                                                <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{data?.content[key]?.value1}</p>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value2']}
                                            >
                                                <p className='margin0' style={{ fontSize: 16 }}><span className='colored-span'></span>{data?.content[key]?.value2}</p>
                                            </Form.Item>
                                        </div>
                                        <div className="feedback-student-inner-3">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'answer']}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (value !== undefined && value !== null && value !== 0) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(selectedLanguage?.PleaseEnterRate);
                                                        },
                                                    }),
                                                ]}
                                                required
                                            >
                                                <Rate className="new-my-rate" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    )}
                </Form.List>

                <Form.Item >
                    <Button
                        htmlType='submit'
                        className='newlogin-btn form-input-new-style'
                        style={{ height: '45px', width: '100%', marginTop: '10px' }}
                        type='primary'
                        disabled={disabled}
                        loading={loading}
                    >
                        {selectedLanguage?.txt_save}
                    </Button>
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default FeedbackResponseModal