import { Button, Popconfirm, Spin } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apis'
import { allPaths } from '../../../utils/constants'
import { convertTitle, errorMessage, studentData, successMessage, toBase64 } from '../../../utils/helpers'
import NoDataComponent from '../../NoData/NoDataComponent'

const NewStudentResult = (props) => {
    const { user, userActions, selectedLanguage, lanKorCondition, history } = props
    const studentResult = useSelector(state => state?.userReducer?.studentResult || [])
    const studentTrainResult = useSelector(state => state?.userReducer?.studentTrainResult || [])

    const [train, setTrain] = useState(false)
    const [spin, setSpin] = useState({})
    console.log('studentResult', studentResult);

    useEffect(() => {
        studentData.getStudentResults(userActions, user?._id)
        studentData.getStudentTrainResults(userActions, user?._id)
    }, [])

    const deleteResult = (_id) => {
        setSpin({ [_id]: true })

        axios.post(POST.DELETE_EXAM_RESULT, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})

                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    studentData.getStudentResults(userActions, user?._id)
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote6}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title title-for-alignment'>{train ? selectedLanguage?.txt_TraiResult : selectedLanguage?.txt_Result}</h3>
                <span className='padding-30-l-r' style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                    <Button
                        size='large'
                        type='primary'
                        onClick={() => setTrain(state => !state)} >
                        {train ? selectedLanguage?.txt_TestResult : selectedLanguage?.txt_TraiResult}
                    </Button>
                </span>
                {train ?
                    <div className='new-exam-screen-cards'>
                        {studentTrainResult?.length ?
                            studentTrainResult?.map((e, i) => {
                                return (
                                    <div key={i} className='new-result-card'>
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.Type}</p>
                                            <p className='new-row-value'>{e?.test10 ? selectedLanguage?.txt_Test10t : e?.video ? selectedLanguage?.txt_Video : e?.card ? selectedLanguage?.Card : selectedLanguage?.tx_Train}</p>
                                        </div>
                                        <div className='grey-card' />
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.txt_Questions}</p>
                                            <p className='new-row-value'>{e?.allQuestions?.length || '0'}</p>
                                        </div>
                                        <div className='grey-card' />
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.Created}</p>
                                            <p className='new-row-value'>{e?.createdAt}</p>
                                        </div>
                                        <br />
                                        <div className='new-exam-card-row-div'>
                                            <Button
                                                type='primary'
                                                size='large'
                                                onClick={async () => {
                                                    let newQuestions = JSON.parse(JSON.stringify(e?.allQuestions))

                                                    for (let v of newQuestions) {
                                                        let dragFile = v?.dragFile
                                                        let answerList = v?.answerList
                                                        if (dragFile?.url) {
                                                            dragFile.baseUrl = await toBase64(dragFile?.url)
                                                        }
                                                        if (v?.file?.url) {
                                                            v.file.baseUrl = await toBase64(v?.file?.url)
                                                        }
                                                        for (let x of answerList) {
                                                            if (x?.file?.url) {
                                                                x.file.baseUrl = await toBase64(x?.file?.url)
                                                            }
                                                        }
                                                    }
                                                    let chapResults = _.groupBy(newQuestions, 'chapter')
                                                    let paraResults = _.groupBy(newQuestions, 'paragraph')
                                                    history.push({
                                                        pathname: allPaths?.TRAIN_OVERVIEW,
                                                        state: {
                                                            chResults: chapResults,
                                                            paragraphResults: paraResults,
                                                            allQuestions: newQuestions,
                                                        }
                                                    })
                                                }}
                                                block
                                            >
                                                {selectedLanguage?.txt_Overview}  ➝
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }) :
                            <NoDataComponent />
                        }
                    </div>
                    :
                    <div className='new-exam-screen-cards'>
                        {studentResult?.length ?
                            studentResult?.map((e, i) => {
                                return (
                                    <div key={i} className='new-result-card'>
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.txt_Title}</p>
                                            <p className='new-row-value'>{e?.formId?.title || '----'}</p>
                                        </div>
                                        <div className='grey-card' />
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.txt_Formname}</p>
                                            <p className='new-row-value'>{e?.formId?.formName || '----'}</p>
                                        </div>
                                        <div className='grey-card' />
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.title_Subject}</p>
                                            <p className='new-row-value'>{e?.allQuestions?.[0]?.subject || '----'}</p>
                                        </div>
                                        <div className='grey-card' />
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{selectedLanguage?.txt_Status} </p>
                                            <p className='new-row-value'>{e?.passed ? selectedLanguage?.txt_Pass : selectedLanguage?.txt_Fail}</p>
                                        </div>
                                        <div className='grey-card' />
                                        <div className='new-exam-card-row-div'>
                                            <p className='new-row-title'>{'Created'} </p>
                                            <p className='new-row-value'>{moment(e?.created).format('DD-MM-YYYY HH:mm')}</p>
                                        </div>
                                        <div className='new-exam-card-row-div'>
                                            <button className='new-exam-buton-overview'
                                                onClick={async () => {
                                                    let newQuestions = JSON.parse(JSON.stringify(e?.allQuestions))

                                                    for (let v of newQuestions) {
                                                        let dragFile = v?.dragFile
                                                        let answerList = v?.answerList
                                                        if (dragFile?.url) {
                                                            dragFile.baseUrl = await toBase64(dragFile?.url)
                                                        }
                                                        if (v?.file?.url) {
                                                            v.file.baseUrl = await toBase64(v?.file?.url)
                                                        }
                                                        for (let x of answerList) {
                                                            if (x?.file?.url) {
                                                                x.file.baseUrl = await toBase64(x?.file?.url)
                                                            }
                                                        }
                                                    }
                                                    let chapResults = _.groupBy(newQuestions, 'chapter')
                                                    let paraResults = _.groupBy(newQuestions, 'paragraph')

                                                    let chapPoints = {}
                                                    let formLimit = e?.formId?.limit
                                                    let allKeys = Object.keys(chapResults)
                                                    let checkAllTrue = []

                                                    for (let v of allKeys) {
                                                        chapPoints[v] = chapResults[v]?.reduce((a, { point }) => a + point, 0)
                                                        checkAllTrue.push(chapPoints[v] >= formLimit[v])
                                                    }
                                                    history.push({
                                                        pathname: allPaths?.OVERVIEW,
                                                        state: {
                                                            chResults: chapResults,
                                                            chPoints: chapPoints,
                                                            formData: e?.formId,
                                                            paragraphResults: paraResults,
                                                            passed: e?.passed,
                                                            allQuestions: newQuestions
                                                        }
                                                    })
                                                }}
                                            >{selectedLanguage?.txt_Overview}➝
                                            </button>
                                            <Popconfirm
                                                title={`${selectedLanguage?.Delete} ${convertTitle(e?.formId?.formName)}?`}
                                                okText={selectedLanguage?.Delete}
                                                cancelText={selectedLanguage?.Cancel}
                                                okButtonProps={{
                                                    type: 'primary',
                                                    className: 'form-button'
                                                }}
                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                onConfirm={() => deleteResult(e?._id)}
                                            >
                                                <button className='new-exam-buton-dleted'>{spin[e?._id] ? <Spin /> : null}{selectedLanguage?.txt_Delete}</button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                )
                            }) :
                            <NoDataComponent />
                        }
                    </div>}
            </div>
        </div>
    )
}
export default NewStudentResult