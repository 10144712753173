import { Button, Popconfirm, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FeedbackDetailModal from '../../../Components/FeebackDetail/FeebackDetail'
import { errorMessage, successMessage, teacherData } from '../../../utils/helpers'
import AddTeacherFeedback from './AddFeedback'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'
import EditFeedback from './EditFeedback'
import { FeedbackResponsesModal } from '../../../Components'
import CopyFeedback from './CopyFeedback'
import AdminFeedbackFormForTeacher from './AdminFormForTeacher'
import TeacherToStudent from './TeacherToStudent'

const NewTeacherFeedback = (props) => {
    const { selectedLanguage, user, lanKorCondition, userActions } = props
    const [showform, setShowFrom] = useState(false)
    const [showTeacherResponses, setShowTeacherResponses] = useState(false)
    const [showDetail, setShowDetail] = useState(null)
    const myFeedbackForms = useSelector(state => state?.userReducer?.teacherFeedbackForm || [])
    const [responsesDetail, setResponsesDetail] = useState(null)
    const [data, setData] = useState({})
    const [copyData, setCopyData] = useState({})
    const [spin, setSpin] = useState({})
    const [adminForms, setAdminForms] = useState(false)

    useEffect(() => {
        teacherData.getMyFeedbackForms(userActions, user?._id)
        teacherData.getMyStudents(userActions, user?._id)
    }, [])

    const handleChangeAdminScreeb = () => {
        setAdminForms(!adminForms)
        setShowTeacherResponses(false)
    }

    const handleForm = () => {
        setShowFrom(!showform)
        setShowTeacherResponses(false)
    }

    const onCancel = () => {
        setData({})
        setCopyData({})
        setShowTeacherResponses(false)
    }

    const deleteFeedback = (id) => {
        setSpin({ [id]: true })
        axios.post(POST?.DELETE_TEACHER_FEEDBACK_FORM, { id })
            .then(async (res) => {
                const { data } = res
                if (data?.success) {
                    setSpin({})
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    await teacherData.getMyFeedbackForms(userActions, user?._id)
                }
                else {
                    setSpin({})
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p' style={{ width: '200px' }}>{selectedLanguage?.title_Title}</p>,
            dataIndex: 'title',
        },
        {
            title: <p className='table-title-p' style={{ width: '360px' }}>{selectedLanguage?.title_Action}</p>,
            render: (e) => <>
                <Button
                    type='primary'
                    style={{ marginRight: '5px', marginTop: '4px' }}
                    onClick={() => setCopyData(e)}
                    icon={<CopyOutlined />}
                >{selectedLanguage?.txt_copyFeedbackForm}</Button>

                <Button
                    type='primary'
                    style={{ marginRight: '5px' }}
                    onClick={() => setData(e)}
                    disabled={e?.feedbackResponses?.length}
                    icon={<EditOutlined />}
                >{selectedLanguage?.Edit}</Button>
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    disabled={e?.feedbackResponses?.length}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteFeedback(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button
                            disabled={e?.feedbackResponses?.length}
                            icon={<DeleteOutlined />}
                            type='primary'
                        >{selectedLanguage?.Delete}</Button>
                    }
                </Popconfirm>
            </>
        },
        {
            title: <p style={{ width: 200 }}></p>,
            render: (e) =>
                <span style={{ width: '220px' }}>
                    <Button disabled={!e?.feedbackResponses?.length} onClick={() => setResponsesDetail(e)} >{selectedLanguage?.txt_Responses}</Button>
                    &nbsp;
                    <Button onClick={() => setShowDetail(e)} >{selectedLanguage?.txt_detail}</Button>
                </span>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>
            <div className='new-exam-screen-upper' style={{ padding: '15px', paddingBottom: '30px' }}>
                <h3 className='new-exam-title' style={{paddingLeft:'0px'}}>{showform ? selectedLanguage?.txt_Add_feedback_form : selectedLanguage?.txt_Feedbacks}</h3>
                <div>
                    <div style={{
                        display: 'flex',
                        marginTop: '20px',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end'
                    }}>

                        {!showTeacherResponses &&
                            <Button
                                type='primary'
                                className='margin-for-faadback-button'
                                onClick={() => setShowTeacherResponses(true)}>
                                {selectedLanguage?.txt_TeachertoStudentResponses}
                            </Button>}

                        <Button
                            type='primary'
                            className='margin-for-faadback-button'
                            onClick={handleChangeAdminScreeb}>
                            {adminForms ? selectedLanguage?.Txt_MyFeedbackForm : selectedLanguage?.txt_admin_feedback_from}
                        </Button>

                        {adminForms ?
                            null :
                            (data?._id || copyData?._id) ?
                                <Button
                                    type='primary'
                                    className='margin-for-faadback-button'
                                    onClick={onCancel}
                                >{selectedLanguage?.txt_Cancel}</Button> :
                                <Button
                                    type='primary'
                                    className='margin-for-faadback-button'
                                    onClick={handleForm}
                                >{showform ? selectedLanguage?.txt_Cancel : selectedLanguage?.txt_Add_feedback_form}</Button>}
                    </div>
                    
                    <div>
                        {showTeacherResponses ?
                            <TeacherToStudent {...props} /> :
                            adminForms ?
                                <AdminFeedbackFormForTeacher {...props} /> :
                                showform ?
                                    <AddTeacherFeedback {...props} />
                                    : data?._id ?
                                        <EditFeedback data={data}  {...props} />
                                        : copyData?._id ?
                                            <CopyFeedback data={copyData}  {...props} />
                                            :
                                            <div className='new-layout-table-upper-div' style={{ margin: '0px' }}>
                                                <Table
                                                    style={{ marginTop: '30px' }}
                                                    className='new-layout-table'
                                                    scroll={{ x: 'auto' }}
                                                    columns={columns}
                                                    dataSource={myFeedbackForms}
                                                    pagination={false}
                                                />
                                            </div>
                        }
                    </div>

                    {showDetail?._id &&
                        <FeedbackDetailModal data={showDetail} open={showDetail?._id} onCancel={() => setShowDetail(null)} {...props} />
                    }

                    {responsesDetail?._id &&
                        <FeedbackResponsesModal data={responsesDetail} open={responsesDetail?._id} onCancel={() => setResponsesDetail(null)} {...props} />
                    }

                </div>
            </div>
        </div>
    )
}


export default NewTeacherFeedback