import { Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ChangePassword, UserSettings } from '../../../Components'
import { GET } from '../../../utils/apis'
import { errorMessage, generalData } from '../../../utils/helpers'


const NewStudentSetting = (props) => {
    const { history, userActions, user, generalActions, lanKorCondition, selectedLanguage } = props
    const { TabPane } = Tabs
    const tutions = useSelector(state => state?.generalReducer?.tutions || [])
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const [companyOwners, setCompanyOwners] = useState([])

    useEffect(() => {
        getCompanyOwners()
        generalData?.getAllSubjects(generalActions, user?.verify)

    }, [])
    const getCompanyOwners = () => {
        axios.get(GET?.GET_COMPANY_OWNERS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setCompanyOwners(data?.data || [])
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title title-for-alignment'>{selectedLanguage?.txt_Profilesetting}</h3>
                <div>
                    <div className='main-div-new-style'>
                        <Tabs className='new-new-my-tabs' defaultActiveKey='1' centered>
                            <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_Profile}</p>} key='1'>
                                <UserSettings companyOwners={companyOwners} subjects={subjects} tutions={tutions} {...props} />
                            </TabPane>
                            <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_Security}</p>} key='2'>
                                <ChangePassword {...props} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewStudentSetting