import { Table } from 'antd'
import moment from 'moment'
import React, { useEffect, } from 'react'
import { useSelector } from 'react-redux'
import { convertRoundNumber, studentData } from '../../../utils/helpers'

const NewStudentTransaction = (props) => {
    const { user, userActions, selectedLanguage, isEnglish } = props
    const MyTransaction = useSelector(state => state.userReducer.walletTransaction)

    useEffect(() => {
        studentData.getWalletTransactions(userActions, user?._id)
    }, [])

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.newtxt_Name}</p>,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (v) => <p>{v?.name || '----'}</p>
        },
        {
            title: <p className='table-title-p'> {selectedLanguage?.txt_DateOfTransaction}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (v) => <p>{moment(v).format('DD-MM-YYYY')}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Amount}</p>,
            sorter: (a, b) => a.amount - b.amount,
            render: (v) => <p>
                € {convertRoundNumber(v?.amount)}
            </p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Coupondiscount}</p>,
            render: (v) => <p> {v?.couponDiscount ? `€ ${v?.couponDiscount}` : 0} </p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_ServiceFee}</p>,
            sorter: (a, b) => a.serviceFee - b.serviceFee,
            render: (v) => <p> {v?.serviceFee ? `€ ${v?.serviceFee}` : 0} </p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_OldAmount}</p>,
            render: (v) => <p>
                € {convertRoundNumber(v?.oldAmount)}
            </p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Amount}</p>,
            render: (v) => <p>€ {
                v?.easyload ?
                    convertRoundNumber(v?.oldAmount + v?.amount + v?.couponDiscount)
                    : v?.type ?
                        convertRoundNumber(v?.oldAmount + v?.amount)
                        : convertRoundNumber((v?.oldAmount + v?.couponDiscount) - v?.amount)}
            </p>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote9}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title' style={{ marginBottom: '20px' }}>{selectedLanguage?.txt_WALLETTRANSACTION}</h3>
                <div className='new-layout-table-upper-div'>
                    <Table
                        dataSource={MyTransaction}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        className='new-layout-table'
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>
        </div>
    )
}
export default NewStudentTransaction