import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, POST } from '../../utils/apis'
import { checkSelectOption, errorMessage, generalData, successMessage, teacherData } from '../../utils/helpers'

const { Option } = Select
const { TextArea } = Input;

const CreateForm = (props) => {
    const { onCancel, user, copyForm, setCopyForm, openForm, selectedLanguage, lanKorCondition, generalActions, userActions } = props
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const [form] = Form.useForm()
    const [questions, setQuestions] = useState([])
    const [previousQuestions, setPreviousQuestions] = useState(copyForm?.questions || [])
    const [selectedQuestions, setSelectedQuestions] = useState(copyForm?.questions || [])
    const [filteredQuestions, setFilteredQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [formType, setFormType] = useState(copyForm?.formType || 'personal')
    const [questionType, setQuestionType] = useState(copyForm?.questionsType || 'test')
    const [totalPoints, setTotalPoints] = useState(copyForm?.totalPoints || 0)
    const [filteredChapters, setFilteredChapters] = useState([])
    const [chapters, setChapter] = useState([])
    const [paragraph, setParagraph] = useState([])
    const [selectedChapters, setSelectedChapters] = useState(copyForm?.chapters || null)
    const [selectedSubject, setSelectedSubject] = useState(copyForm?.subjects || null)
    const [selectedParagraph, setSelectedParagraph] = useState(copyForm?.paragraphs || [])
    const [topics, setTopics] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    useEffect(() => {
        generalData?.getAllSubjects(generalActions, user?.verify)

        axios.get(`${ADMIN.GET_QUESTION_WITH_TYPE}/?userId=${user?._id}&questionsType=${questionType}`)
            .then((res) => {
                const { data } = res
                setQuestions(data?.data || [])
                setFilteredQuestions(data?.data || [])
                setFilteredChapters(_.uniqBy(data?.data?.map((v, i) => v?.chapter?.chapterName)))
                setChapter(_.uniqBy(data?.data?.map((v, i) => v?.chapter)))
                setParagraph(_.uniqBy(data?.data?.map((v, i) => v?.paragraph?.paragraphName)))
                setTopics(_.uniqBy(data?.data?.map((v, i) => v?.topics?.map((x) => x?.topicName)).flat()))
                // setTopics(_.uniqBy(data?.data?.map((v, i) => v?.topics?.map((x)=> x?.topicName))))
            })
    }, [questionType])

    useEffect(() => {
        setFilteredQuestions(questions.filter(checkChapter).filter(checkParagraph).filter(checkTopics).filter(checkSubjects))
    }, [selectedChapters, selectedParagraph, selectedTopics, selectedSubject])

    const onQuestionTypeChange = (e) => {
        setQuestionType(e.target.value)
    }
    const onFinish = (values) => {
        values.userId = `${user?._id}`
        values.formType = formType
        values.questionsType = questionType
        values.totalPoints = totalPoints

        if (!user?.ownerType) {
            values.isAdmin = true
        }

        let points = selectedQuestions?.map((v) => {
            return {
                _id: v?._id,
                point: v?.point
            }
        })
        values.questionPoints = points
        let limitObj = {
            [selectedChapters]: values?.limit
        }
        values.limit = limitObj
        console.log('values', values, 'limitObj', limitObj)
        setLoading(true)
        axios.post(`${POST.CREATE_QUESTIONS_FORM}`, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    teacherData.getTeacherForms(userActions, user?._id)

                    form.resetFields()
                    openForm()
                    setCopyForm({})
                    openForm()
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                onCancel()
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }

    const onChange = (e) => {
        setFormType(e.target.value)
    }
    const onChange1 = (value) => {
        let filteredQuestion = questions?.filter((v) =>
            value?.includes(v?._id)
        )
        setSelectedQuestions([...filteredQuestion])
        setPreviousQuestions([...filteredQuestion])
        setTotalPoints(_.sumBy(filteredQuestion, 'point'))
        form.setFieldsValue({
            limit: _.sumBy(filteredQuestion, 'point')
        })
    }
    const onChangeChapter = (value) => {
        setSelectedChapters(value)

        if (value) {
            setParagraph(_.uniqBy(questions.filter((v) => v?.chapter?.chapterName === value).map((v) => v?.paragraph.paragraphName)))
        } else {
            setParagraph(_.uniqBy(questions?.map((v, i) => v?.paragraph?.paragraphName)))
        }

        setSelectedParagraph([])
        setSelectedQuestions([])
        setSelectedTopics([])
        form.setFieldsValue({
            topics: [],
            questions: [],
            paragraphs: []
        })
    }
    const onChangeParagraph = (value) => {
        setSelectedParagraph(value)
        if (value) {
            setTopics(_.uniqBy(questions.filter((v) => value?.includes(v?.paragraph?.paragraphName)).map((v, i) => v?.topics?.map((x) => x?.topicName)).flat()))
        } else {
            setTopics(_.uniqBy(questions?.map((v) => v?.topics?.map((x) => x?.topicName)).flat()))
        }
        setSelectedQuestions([])
        setSelectedTopics([])

        form.setFieldsValue({
            topics: [],
            questions: []
        })
    }
    const onChangeTopic = (value) => {
        setSelectedTopics(value)

        setSelectedQuestions([])
        form.setFieldsValue({
            questions: []
        })
    }
    const checkChapter = (v) => {
        if (selectedChapters?.length) {
            return v?.chapter?.chapterName === selectedChapters
        } else {
            return v
        }
    }
    const checkParagraph = (v) => {
        if (selectedParagraph?.length) {
            return selectedParagraph?.includes(v?.paragraph?.paragraphName)
        } else {
            return v
        }
    }
    const checkTopics = (v) => {
        if (selectedTopics?.length) {
            return v?.topics?.filter((x) => selectedTopics?.includes(x?.topicName))?.length
        } else {
            return v
        }
    }
    const checkSubjects = (v) => {
        if (selectedSubject) {
            return v?.subject === selectedSubject?.toString()
        } else {
            return v
        }
    }
    const onChangeSubject = (id) => {
        if (!id) {
            let newFilteredChapters = chapters?.map((v) => v?.chapterName)
            setFilteredChapters(_.uniqBy(newFilteredChapters))
        } else {
            let newFilteredChapters = chapters?.filter((v) => v?.subject === id?.toString())?.map((v) => v?.chapterName)
            setFilteredChapters(_.uniqBy(newFilteredChapters))
        }
        setSelectedChapters(null)
        setSelectedParagraph([])
        setSelectedTopics([])
        setSelectedQuestions([])
        form.setFieldsValue({
            chapters: null,
            topics: [],
            questions: [],
            paragraphs: []
        })
    }
    const onChangePoint = (value, i) => {
        let questionsData = JSON.parse(JSON.stringify(selectedQuestions))
        questionsData[i].point = value
        setSelectedQuestions(questionsData)
    }
    const onCancelModal = () => {
        setSelectedQuestions(previousQuestions)
        setVisible(false)
    }
    const onOk = () => {
        setPreviousQuestions(selectedQuestions)
        setTotalPoints(_.sumBy(selectedQuestions, 'point'))
        setVisible(false)
        form.setFieldsValue({
            limit: _.sumBy(selectedQuestions, 'point')
        })
    }
    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }} >
                <Button
                    type='primary'
                    style={{
                        padding: '0px 20px 0px 20px',
                        background: '#1eb2a6',
                        color: 'white',
                        border: 'none',
                        // height: '45px',
                        marginTop: '10px',
                        borderRadius: 6
                    }}
                    onClick={() => { onCancel(); setCopyForm({}) }}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </span>

            <div className='crt-question-div'>
                <Modal title={selectedLanguage?.title_Questions} open={visible} onOk={onOk} onCancel={onCancelModal} okText={selectedLanguage?.Ok} cancelText={selectedLanguage?.Cancel}>
                    {selectedQuestions?.map((v, i) => (
                        <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                            <Col span={24}>
                                <span>
                                    {`${i + 1}.) ${v?.question}`}&nbsp;&nbsp;&nbsp;
                                </span>
                                <span>
                                    <InputNumber
                                        type='number'
                                        min={0}
                                        max={100}
                                        className='subs-input'
                                        value={v?.point}
                                        onChange={e => onChangePoint(e, i)}
                                    />
                                </span>
                            </Col>
                        </Row>
                    ))}
                </Modal>
                <p className='heading head-center'>{selectedLanguage?.title_CreateForm}</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    name='Form'
                    layout='vertical'
                    requiredMark={true}>

                    <Form.Item
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Pleaseentertitle,
                            }
                        ]}
                        label={selectedLanguage?.title_Title}
                        required
                        initialValue={copyForm?.title}
                    >
                        <Input.TextArea placeholder={selectedLanguage?.txt_Entertitlehere} />
                    </Form.Item>

                    <Form.Item
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Pleaseenterdescription
                            }
                        ]}
                        label={selectedLanguage?.txt_Description}
                        required
                        initialValue={copyForm?.description}
                    >
                        <Input.TextArea placeholder={selectedLanguage?.txt_Entedescriptionhere} />
                    </Form.Item>


                    <Form.Item
                        required
                        name='formType'
                        label={selectedLanguage?.title_FormType}
                        initialValue={copyForm?.formType}
                    >
                        <Radio.Group onChange={onChange} defaultValue='personal'>
                            <Radio.Button value='personal'>{selectedLanguage?.Button_Personal}</Radio.Button>
                            <Radio.Button value='anonymous'>{selectedLanguage?.Button_Anonymous}</Radio.Button>
                        </Radio.Group>

                    </Form.Item>
                    <Form.Item
                        name='formName'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseEnterFormName,
                            }
                        ]}
                        label={selectedLanguage?.label_FormName}
                        required
                        initialValue={copyForm?.formName}
                    >
                        <Input placeholder={selectedLanguage?.placeholder_EnterFormName} />
                    </Form.Item>
                    {/* 
                    <Form.Item
                        name='questionsType'
                        label={selectedLanguage?.label_QuestionsType}
                        required
                        initialValue={copyForm?.questionsType}
                    >
                        <Radio.Group onChange={onQuestionTypeChange} defaultValue='questionnaire'>
                            <Radio.Button value='questionnaire'>{selectedLanguage?.Button_Questionnaire}</Radio.Button>
                            <Radio.Button value='test'>{selectedLanguage?.Button_Test}</Radio.Button>
                        </Radio.Group>
                    </Form.Item> */}
                    <Form.Item
                        name='subjects'
                        label={selectedLanguage?.label_SelectSubject}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectSubject
                            }
                        ]}
                        initialValue={copyForm?.subjects}
                    >
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            onChange={(e) => (setSelectedSubject(e), onChangeSubject(e))}
                            placeholder={selectedLanguage?.label_SelectSubject}>
                            <Option value={null}>{null}</Option>
                            {subjects.map((v, i) => {
                                return (
                                    <Option key={i} value={v?._id}>{v?.subjectName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='chapters'
                        label={selectedLanguage?.label_SelectChapters}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectChapter
                            }
                        ]}
                        initialValue={copyForm?.chapters}
                    >
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            disabled={!selectedSubject}
                            allowClear
                            onChange={onChangeChapter}
                            placeholder={selectedLanguage?.label_SelectChapters}>
                            <Option value={null}>{null}</Option>
                            {filteredChapters.map((v, i) => {
                                return (
                                    <Option key={i} value={v}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='paragraphs'
                        label={selectedLanguage?.label_SelectParagraph}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseSelectParagraph
                            }
                        ]}
                        initialValue={copyForm?.paragraphs?.length ? copyForm?.paragraphs : []}

                    >
                        <Select mode='multiple' disabled={!selectedChapters || !selectedSubject} onChange={onChangeParagraph} placeholder={selectedLanguage?.label_SelectParagraph}>
                            {paragraph.map((v, i) => {
                                return (
                                    <Option key={i} value={v}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='topics'
                        label={selectedLanguage?.label_SelectTopics}
                    >
                        <Select
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            disabled={!selectedChapters || !selectedSubject || !selectedParagraph?.length}
                            mode='multiple'
                            onChange={onChangeTopic}
                            placeholder={selectedLanguage?.label_SelectTopics}>
                            {topics.map((v, i) => {
                                return (
                                    <Option key={i} value={v}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='questions'
                        label={selectedLanguage?.label_SelectQuestions}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.errorMessage_PleaseselectyourQuestions,
                                type: 'array',
                            },
                        ]}
                        initialValue={_.map(copyForm?.questions, '_id')}
                    >
                        <Select
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            disabled={!selectedChapters || !selectedSubject || !selectedParagraph?.length}
                            mode='multiple'
                            onChange={onChange1}
                            placeholder={selectedLanguage?.placeholder_SelectQuestion}>
                            {filteredQuestions.map((v, i) => {
                                return (
                                    <Option value={v?._id} key={i}>{v.question}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='totalPoints'
                        label={selectedLanguage?.label_Totalpoints}
                        initialValue={totalPoints || copyForm?.totalPoints}
                    >
                        <div>
                            <Input disabled value={totalPoints} />
                        </div>
                    </Form.Item>
                    <Form.Item
                        name='limit'
                        label={selectedLanguage?.txt_Limitpoints}
                        initialValue={copyForm?.limit?.[selectedChapters] || 0}
                    >
                        <InputNumber
                            max={totalPoints}
                            min={0}
                            precision={0}
                            value={totalPoints}
                        />
                    </Form.Item>
                    <Form.Item
                        name='extraTime'
                        label={selectedLanguage?.txt_Extratime}
                        initialValue={copyForm?.extraTime || 0}
                    >
                        <InputNumber
                            type='number'
                            min={0}
                            precision={0}
                            addonAfter='s'
                            placeholder={selectedLanguage?.txt_enterExrtaTimeHere}

                        />
                    </Form.Item>
                    {user?.ownerType === 'teacher' &&
                        <Form.Item
                            name='public'
                            valuePropName='checked'
                            initialValue={copyForm?.public || false}
                        >
                            <Checkbox>
                                {selectedLanguage?.label_Public}
                            </Checkbox>
                        </Form.Item>
                    }
                    <Form.Item
                        name='display'
                        valuePropName='checked'
                        initialValue={copyForm?.display || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.txt_Display}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <Button style={{
                            padding: '0px 20px 0px 20px',
                            background: '#1eb2a6',
                            color: 'white',
                            border: 'none',
                            marginTop: '10px',
                            borderRadius: 6,
                            width: '150px',
                            float: 'right',
                            height: 45,
                            marginBottom:'-30px'
                        }} type='primary' onClick={() => setVisible(true)}>{selectedLanguage?.Button_PreviewQuestions}</Button>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            type='primary'
                            style={{
                                height: 45,
                                padding: '0px 20px 0px 20px',
                                background: '#1eb2a6',
                                color: 'white',
                                border: 'none',
                                marginTop: '10px',
                                borderRadius: 6,
                                width: '100%'
                            }}
                            htmlType='submit'
                            block
                            loading={loading}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default CreateForm