import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../../utils/apis'
import { convertTitle, errorMessage, generalData, successNotification } from '../../../../utils/helpers'
import AddSubject from './AddSubjects'
import { useSelector } from 'react-redux'

const Subjects = (props) => {
    const { user, language, selectedLanguage, generalActions } = props
    const subjects = useSelector(state => state?.generalReducer?.subjects || [])
    const [loading, setLoading] = useState(false)
    const [showAddType, setShowAddType] = useState(false)
    const [edit, setEdit] = useState({})
    const [spin, setSpin] = useState({})
    const isEnglish = language?.language === 'english'

    useEffect(() => {
        generalData?.getAllSubjects(generalActions, user?.verify)
    }, [])

    const deleteType = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id,
            userId: user?._id
        }
        axios.post(`${ADMIN?.DELETE_SUBJECTS}`, deleteProduct)
            .then((res) => {
                const { data } = res
                setSpin({})
                generalData?.getAllSubjects(generalActions, user?.verify)
                if (data?.success) {
                    successNotification(isEnglish ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(isEnglish ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
                console.log('e', e)
                errorMessage(isEnglish ? e?.message : e?.messageNl)
            })
    }

    let columns = [
        {
            title: selectedLanguage?.txt_SubjectName,
            dataIndex: 'subjectName',
            key: 'subjectName',
            sorter: (a, b) => a?.subjectName?.length - b?.subjectName?.length
        },
        {
            title: selectedLanguage?.Actions,
            key: 'actions',
            align: 'center',
            render: (e) => <span>
                <EditTwoTone onClick={() => setEdit(e)} />&nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title={`${selectedLanguage?.Delete} ${convertTitle(e?.subjectName)}?`}
                    okText={selectedLanguage?.Delete}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteType(e?._id)}
                >
                    <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
    ]

    return (
        <div className='new-flex-cloum'>
            {showAddType || edit?._id ?
                <AddSubject
                    edit={edit}
                    onCancel={() => (setShowAddType(false), setEdit({}))}
                    {...props}
                />
                :
                <div>
                    <Button
                        type='primary'
                        size='large'
                        style={{ float: 'right', }}

                        onClick={() => setShowAddType(true)}
                    >
                        {selectedLanguage?.txt_AddSubject}
                    </Button>
                    <br />
                    <div className='new-layout-table-upper-div margin0'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            scroll={{ x: 'auto' }}
                            columns={columns}
                            dataSource={subjects}
                            loading={loading}
                            pagination={{ hideOnSinglePage: true }}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default Subjects

