import { Form, Input, Radio } from 'antd'
import React, { useState } from 'react'
import { AiFillDislike, AiFillLike } from 'react-icons/ai'
import { RxVideo } from 'react-icons/rx'
import { PreviewMaths } from '../../Components'
import OverviewDragDrop from '../Overview/OverviewDragDrop'
import VideoModal from '../Overview/VideoModal'
import { thesisOptions } from '../../utils/constants'
import { checkArrayForThesis } from '../../utils/helpers'
import { BiCard } from "react-icons/bi";
import CardsModal from '../Overview/CardsModal'

const TrainTestDetail = (props) => {
    const { currentQuestionIsWithImg, currentQuestion, currentQuestionObj } = props
    const [VideoData, setVideoData] = useState([])
    const [CardsData, setCardsData] = useState([])


    console.log('currentQuestionObj', currentQuestionObj)
    return (
        <>
            <div>
                <h2 className={'head-center'}>
                    {currentQuestion + 1} : {currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={currentQuestionObj?.question || ' '} /> : currentQuestionObj?.question}
                </h2>
                <div className='form-div1Image exp_box'>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 10 }}>
                                {
                                    currentQuestionObj?.correct ?
                                        <AiFillLike fontSize={30} color='#388E3C' />
                                        :
                                        <AiFillDislike fontSize={30} color='#DC4C35' />
                                }
                            </div>
                            <div className='centerDiv' >
                                <h3 style={{ color: currentQuestionObj?.correct ? '#388E3C' : '#DC4C35' }}>{currentQuestionObj?.correct ? 'Correct' : 'Wrong'}</h3>
                            </div>
                        </div>
                        <div>
                            {currentQuestionObj?.cards?.length ?
                                <BiCard
                                    onClick={() => setCardsData(currentQuestionObj?.cards)}
                                    fontSize={30}
                                    color='rgb(30, 178, 166)'
                                    style={{ cursor: 'pointer' }}
                                />
                                : null
                            }
                            &nbsp;
                            &nbsp;
                            {currentQuestionObj?.videos?.length ?
                                <RxVideo
                                    onClick={() => setVideoData(currentQuestionObj?.videos)}
                                    fontSize={30}
                                    color='#DC4C35'
                                    style={{ cursor: 'pointer' }}
                                />
                                : null
                            }
                        </div>
                    </div>

                    <div>
                        {currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={currentQuestionObj?.answerList?.[0]?.explaination || ' '} /> : currentQuestionObj?.answerList?.[0]?.explaination}
                    </div>
                </div>
                <br />
                <Form
                    name='form'
                    layout='vertical'
                >
                    {
                        currentQuestionObj?.answerType === 'radio' || currentQuestionObj?.answerType === 'single' ? (
                            <div className='question_div'>
                                {currentQuestionIsWithImg &&
                                    <div className='form-div-img1'>
                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                    </div>
                                }
                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                    <br />
                                    {currentQuestionObj?.answerList?.map((v, i) =>
                                        !currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <Input
                                            key={i}
                                            style={{ marginTop: 10 }}
                                            className={'form-input-new-style ' + (v?.isTrue ? 'correct_answer' : !v?.isTrue && v?.option === currentQuestionObj?.answerGiven ? 'wrong_answer' : 'neutral_answer')}
                                            placeholder='Numeric Answer'
                                            disabled
                                            value={v?.option || null}
                                        /> :
                                            <div className={'overview_div ' + (v?.isTrue ? 'correct_answer' : !v?.isTrue && v?.option === currentQuestionObj?.answerGiven ? 'wrong_answer' : 'neutral_answer')}>
                                                <PreviewMaths show={true} sentence={v?.option || ' '} />
                                            </div>
                                    )}
                                </div>
                            </div>
                        ) : currentQuestionObj?.answerType === 'hotspot' ? (
                            <div className='question_div'>
                                {currentQuestionIsWithImg &&
                                    <div className='form-div-img1'>
                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                    </div>
                                }
                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                    <Radio.Group disabled>
                                        {(currentQuestionObj?.answerList || [])?.map((v, radioI) => (
                                            <Radio
                                                className='hotspot_radio'
                                                value={v?.file?.perUrl}
                                                key={radioI}
                                            >
                                                <div
                                                    className={(v?.isTrue ? 'correct_answer' : !v?.isTrue && v?.file?.perUrl === currentQuestionObj?.answerGiven ? 'wrong_answer' : 'neutral_answer')}
                                                    style={{ padding: 5 }}>
                                                    {(v?.file?.url) && <img className='hotspot_image' src={v?.file?.url} alt='' />}
                                                </div>
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </div>
                            </div>
                        ) : currentQuestionObj?.answerType === 'multiple' ? (
                            <div className='question_div'>
                                {currentQuestionIsWithImg &&
                                    <div className='form-div-img1'>
                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                    </div>
                                }
                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                    <br />
                                    {currentQuestionObj?.answerList?.map((v, i) =>
                                        !currentQuestionObj?.subject?.toLowerCase()?.includes('math') ?
                                            <Input
                                                key={i}
                                                style={{ marginTop: 10 }}
                                                className={'form-input-new-style ' + (v?.isTrue ? 'correct_answer' : !v?.isTrue && currentQuestionObj?.answerGiven?.includes(v?.option) ? 'wrong_answer' : 'neutral_answer')}
                                                placeholder='Numeric Answer'
                                                disabled
                                                value={v?.option || null}
                                            /> :
                                            <div className={'overview_div ' + (v?.isTrue ? 'correct_answer' : !v?.isTrue && currentQuestionObj?.answerGiven?.includes(v?.option) ? 'wrong_answer' : 'neutral_answer')}>
                                                <PreviewMaths show={true} sentence={v?.option || ' '} />
                                            </div>
                                    )}
                                </div>
                            </div>
                        ) : (currentQuestionObj?.answerType === 'dragDrop') ? (
                            <OverviewDragDrop VideoData={VideoData} sequence={currentQuestionObj?.answerType === 'dragDropSequence'} currentQuestionObj={currentQuestionObj} spots={currentQuestionObj?.answerList} imgUrl={currentQuestionObj?.dragFile?.url} {...props} />
                        ) : (currentQuestionObj?.answerType === 'dragDropSequence') ? (
                            <OverviewDragDrop VideoData={VideoData} sequence={currentQuestionObj?.answerType === 'dragDropSequence'} currentQuestionObj={currentQuestionObj} spots={currentQuestionObj?.answerList} imgUrl={currentQuestionObj?.dragFile?.url} {...props} />
                        ) : (currentQuestionObj?.answerType === 'thesis') ?
                            <div className='question_div'>
                                {currentQuestionIsWithImg &&
                                    <div className='form-div-img1'>
                                        <img className='question-img1' src={currentQuestionIsWithImg} />
                                    </div>
                                }
                                <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                    {currentQuestionObj?.answerList?.map((v, i) => <p style={{ width: '250px', textAlign: 'start', margin: '0px' }}>Thesis {i + 1}: {v?.option}</p>)}
                                    {thesisOptions?.map((v, i) => {
                                        let answerValidation = currentQuestionObj?.answerGiven === 'first' ? 1 : currentQuestionObj?.answerGiven === 'second' ? 2 : currentQuestionObj?.answerGiven === 'both' ? 3 : currentQuestionObj?.answerGiven === 'none' ? 4 : null
                                        let CorrectIndex = checkArrayForThesis(currentQuestionObj?.answerList)
                                        return (
                                            <div style={{ width: '250px' }}>
                                                {currentQuestionObj?.correct ?
                                                    <Input
                                                        key={i}
                                                        style={{ marginTop: 10 }}
                                                        className={'form-input-new-style ' + (answerValidation === (i + 1) ? 'correct_answer' : 'neutral_answer')}
                                                        disabled
                                                        value={v?.OptionEnglish || null}
                                                    /> :
                                                    <Input
                                                        key={i}
                                                        style={{ marginTop: 10 }}
                                                        className={'form-input-new-style ' + (answerValidation === (i + 1) ? 'wrong_answer' : CorrectIndex === (i + 1) ? 'correct_answer' : 'neutral_answer')}
                                                        disabled
                                                        value={v?.OptionEnglish || null}
                                                    />}
                                            </div>
                                        )
                                    }
                                    )}
                                    <br />
                                    <br />
                                </div>
                            </div>
                            : (
                                <div className='question_div'>
                                    {currentQuestionIsWithImg &&
                                        <div className='form-div-img1'>
                                            <img className='question-img1' src={currentQuestionIsWithImg} />
                                        </div>
                                    }
                                    <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                        <br />
                                        {currentQuestionObj?.correct ?
                                            <Input
                                                className='form-input-new-style correct_answer'
                                                placeholder='Numeric Answer'
                                                type='number'
                                                disabled
                                                value={currentQuestionObj?.answerGiven || null}
                                            />
                                            :
                                            <>
                                                <Input
                                                    className='form-input-new-style wrong_answer'
                                                    placeholder='Numeric Answer'
                                                    type='number'
                                                    disabled
                                                    value={currentQuestionObj?.answerGiven || null}
                                                />
                                                <br />
                                                <br />
                                                <Input
                                                    className='form-input-new-style correct_answer'
                                                    placeholder='Numeric Answer'
                                                    type='number'
                                                    disabled
                                                    value={currentQuestionObj?.answerList?.find((v) => v?.isTrue)?.option}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            )
                    }
                </Form>
            </div>
            {
                VideoData?.[0]?._id &&
                <VideoModal VideoData={VideoData} onCancel={() => setVideoData([])} {...props} />
            }
             {CardsData?.[0]?._id &&
                <CardsModal CardsData={CardsData} onCancel={() => setCardsData([])} {...props} />}
     
        </>
    )
}

export default TrainTestDetail