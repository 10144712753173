import { Form, Input, Modal, Radio, Rate, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import DragDropStudent from '../DragDrop/DragDropStudent'
import PreviewMaths from '../PreviewMaths'
import { thesisOptions } from '../../utils/constants'

const { Option } = Select

const PreviewStudentQuestion = (props) => {
    const { quesData, previewQuestion, onCancelPreview, selectedLanguage, subjectName } = props
    const [selectedAns, setSelectedAns] = useState(null)
    const [form] = Form.useForm()
    let questionData = quesData()
    console.log('quesData', quesData())

    useEffect(() => {
        form.validateFields()

        if (questionData?.answerType === 'hotspot') {
            setSelectedAns(((questionData?.answerList || [])?.find((v) => v?.isTrue)?.file?.url || (questionData?.answerList || [])?.find((v) => v?.isTrue)?.file?.preview))
        }
    }, [form])
    return (
        <Modal width={'90%'} footer={null} title='' open={previewQuestion} onCancel={onCancelPreview}>
            <center>
                <div>
                    <h2 className={'head-center'}>
                        {selectedLanguage?.title_Question} : {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={questionData?.question || ' '} /> : questionData?.question}
                    </h2>
                    <Form
                        form={form}
                        name='form'
                        layout='vertical'
                    >
                        {
                            questionData?.answerType === 'thesis' ?
                                <div>
                                    {questionData?.answerList?.map((v, i) => {
                                        return (
                                            <>
                                                <p style={{ margin: '0px', width: '250px', textAlign: 'start' }}>◉ {v?.option}</p>
                                            </>
                                        )
                                    })}
                                    <br />
                                    <h3 style={{ width: '250px' }}>{selectedLanguage?.txt_SelectanyOneOption}</h3>
                                    <Select
                                        style={{ minWidth: 250 }}
                                        className='form-input-new-style'
                                        showArrow
                                        allowClear
                                        placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                    >
                                        {(thesisOptions || []).map((v, i) => (
                                            <Option value={v?.value} key={i}>
                                                <p className='normalP'>{v?.OptionEnglish}</p>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                : questionData?.answerType === 'rating' ?
                                    <>
                                        <h3>{selectedLanguage?.title_Answer}</h3>
                                        <div className="feedback-student-inner-2">
                                            <p className='margin0' style={{ fontSize: 16 }}>
                                                {questionData?.answerList?.[0]?.option}
                                            </p>
                                            <p className='margin0' style={{ fontSize: 16 }}>
                                                {questionData?.answerList?.[1]?.option}
                                            </p>
                                        </div>
                                        <div className="feedback-student-inner-3" style={{
                                            background: '#A4C8CE',
                                            borderRadius: '10px',
                                            width: '150px',
                                            paddingBottom: '8px'
                                        }}>
                                            <Rate className="new-my-rate" />
                                        </div>
                                    </>
                                    :
                                    questionData?.answerType === 'radio' ? (
                                        <div className='question_div'>
                                            {questionData?.file &&
                                                <div className='form-div-img1'>
                                                    <img className='question-img1' src={questionData?.file} />
                                                </div>
                                            }
                                            <div className={!questionData?.file ? '' : 'form-div1Image'}>

                                                <Form.Item
                                                    name={`answer`}
                                                    label={selectedLanguage?.title_Answer}
                                                    rules={[
                                                        {
                                                            validator(_, value) {
                                                                if (value) {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.option === value)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                } else {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.isTrue)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                }

                                                                return Promise.resolve()
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Radio.Group defaultValue={(questionData?.answerList || [])?.find((v) => v?.isTrue)?.option}>
                                                        {(questionData?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                            <Radio
                                                                className='student_radio'
                                                                value={radioAnswerlist?.option}
                                                                key={radioI}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    <p className='normalP'>{subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={radioAnswerlist?.option || ' '} /> : radioAnswerlist?.option}</p>
                                                                    {(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview) && <img style={{ marginLeft: 10 }} width={100} src={(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview)} alt='' />}
                                                                </div>
                                                            </Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ) : questionData.answerType === 'multiple' ? (
                                        <div className='question_div'>
                                            {questionData?.file &&
                                                <div className='form-div-img1'>
                                                    <img className='question-img1' src={questionData?.file} />
                                                </div>
                                            }
                                            <div className={!questionData?.file ? '' : 'form-div1Image'}>
                                                <Form.Item
                                                    name={`answer`}
                                                    label={selectedLanguage?.label_SelectAnswer}
                                                    rules={[
                                                        {
                                                            validator(_, value) {
                                                                if (value) {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => value?.includes(v?.option))?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                } else {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.isTrue)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                }
                                                                return Promise.resolve()
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ minWidth: 200 }}
                                                        defaultValue={(questionData?.answerList || [])?.filter((v) => v?.isTrue)?.map((v) => v?.option)}
                                                        className='form-input-new-style'
                                                        showArrow
                                                        allowClear
                                                        mode='multiple'
                                                        placeholder={selectedLanguage?.placeholder_SelectMultipleOption}
                                                    >
                                                        {questionData?.answerList?.map((multipleAnswerlist, MultipleI) => (
                                                            <Option value={multipleAnswerlist?.option} key={MultipleI}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p className='normalP'>{subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={multipleAnswerlist?.option || ' '} /> : multipleAnswerlist?.option}</p>
                                                                    {(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.url)} alt='' />}
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ) : questionData?.answerType === 'single' ? (
                                        <div className='question_div'>
                                            {questionData?.file &&
                                                <div className='form-div-img1'>
                                                    <img className='question-img1' src={questionData?.file} />
                                                </div>
                                            }
                                            <div className={!questionData?.file ? '' : 'form-div1Image'}>
                                                <Form.Item
                                                    name={`answer`}
                                                    label={selectedLanguage?.label_SelectAnswer}
                                                    rules={[
                                                        {
                                                            validator(_, value) {
                                                                if (value) {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.option === value)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                } else {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.isTrue)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                }
                                                                return Promise.resolve()
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ minWidth: 200 }}
                                                        defaultValue={(questionData?.answerList || [])?.find((v) => v?.isTrue)?.option}
                                                        className='form-input-new-style'
                                                        showArrow
                                                        allowClear
                                                        placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                                    >
                                                        {(questionData?.answerList || []).map((singleAnswerlist, SingleI) => (
                                                            <Option value={singleAnswerlist?.option} key={SingleI}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p className='normalP'>{subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={singleAnswerlist?.option || ' '} /> : singleAnswerlist?.option}</p>
                                                                    {(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.url)} alt='' />}
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ) : (questionData?.answerType === 'dragDrop' || questionData?.answerType === 'dragDropSequence') ? (
                                        <DragDropStudent sequence={questionData?.answerType === 'dragDropSequence'} spots={questionData?.spots} imgUrl={questionData?.dragFile} {...props} />
                                    ) : questionData?.answerType === 'hotspot' ? (
                                        <div className='question_div'>
                                            {questionData?.file &&
                                                <div className='form-div-img1'>
                                                    <img className='question-img1' src={questionData?.file} />
                                                </div>
                                            }
                                            <div className={!questionData?.file ? '' : 'form-div1Image'}>

                                                <Form.Item
                                                    name={`answer`}
                                                    label={selectedLanguage?.title_Answer}
                                                    rules={[
                                                        {
                                                            validator(_, value) {
                                                                if (value) {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.file?.url === value || v?.file?.preview === value)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                } else {
                                                                    const additionalTextValue = (questionData?.answerList || [])?.find((v) => v?.isTrue)?.explaination
                                                                    if (additionalTextValue) {
                                                                        return Promise.reject(
                                                                            <p>{selectedLanguage?.title_Explaination}: {subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={additionalTextValue || ' '} /> : additionalTextValue}</p>
                                                                        )
                                                                    } else {
                                                                        return
                                                                    }
                                                                }

                                                                return Promise.resolve()
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Radio.Group defaultValue={((questionData?.answerList || [])?.find((v) => v?.isTrue)?.file?.url || (questionData?.answerList || [])?.find((v) => v?.isTrue)?.file?.preview)} onChange={e => setSelectedAns(e?.target?.value)}>
                                                        {(questionData?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                            <Radio
                                                                className='hotspot_radio'
                                                                value={radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview}
                                                                key={radioI}
                                                            >
                                                                <div style={((selectedAns === radioAnswerlist?.file?.url) || (selectedAns === radioAnswerlist?.file?.preview)) ? { backgroundColor: 'lightsalmon', padding: 5 } : { padding: 5 }}>
                                                                    {(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview) && <img className='hotspot_image' src={(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview)} alt='' />}
                                                                </div>
                                                            </Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='question_div'>
                                            {questionData?.file &&
                                                <div className='form-div-img1'>
                                                    <img className='question-img1' src={questionData?.file} />
                                                </div>
                                            }
                                            <div className='form-div'>
                                                <Form.Item
                                                    name={`${questionData?._id}`}
                                                    label={selectedLanguage?.title_Answer}
                                                    hasFeedback
                                                >
                                                    {questionData?.answerType === 'inputNumber' ?
                                                        <Input
                                                            className='form-input-new-style'
                                                            placeholder='Numeric Answer'
                                                            type='number'
                                                        />
                                                        :
                                                        <Input
                                                            className='form-input-new-style'
                                                        />
                                                    }
                                                </Form.Item>
                                            </div>
                                        </div>
                                    )}
                    </Form>
                </div>
            </center>
        </Modal>
    )
}

export default PreviewStudentQuestion
