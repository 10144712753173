import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoginLanguageSelect } from '../../Components'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import testallyLogo from "../../assets/testallyLogo-removebg.png"
import { AUTH } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { errorMessage } from '../../utils/helpers'

const Login = (props) => {
  const { history, } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const path = history?.location?.pathname
  const language = useSelector(state => state?.languageReducer)
  const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
  const isEnglish = language?.language === 'english'

  const onFinish = (values) => {
    values.userType = path === allPaths?.BU_LOGIN ? 'business' : 'guest'
    // values.isCbr = false
    const { history } = props
    setLoading(true)
    axios.post(AUTH.LOGIN, values)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          // successNotification(selectedLanguage?.SuccessfullyLoggedIn)
          dispatch(loginUser(data.user))
          if (data.user?.ownerType === 'designer') {
            return history?.replace(allPaths?.GRAPHIC)
          } else if (data.user?.userType === 'guest') {
            return history?.replace(allPaths?.GUEST_HOME)
          } else {
            return history?.replace(allPaths?.TEACHER_HOME)
          }
        }
        errorMessage(isEnglish ? data.message : data.messageNl)
      })
      .catch((e) => {
        setLoading(false)
        console.log('e', e)
        errorMessage(isEnglish ? e.message : e.messageNl)
      })
  }

  useEffect(() => {
    dispatch(removeUser())
  }, [])

  return (
    <div className='new-layout-scroll-class'
      style={{
        height: '100vh',
        background: '#A4C8CE',
        overflow: 'auto'
      }}>
      <LoginLanguageSelect color={true} />
      <div className='auth-screen-main-div '>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <img className='login-top-img' src={testallyLogo} />
        </div>
        <p className='heading head-center' style={{ color: 'black' }}>
          {path === allPaths?.BU_LOGIN ? selectedLanguage?.txt_Teacherlogin : selectedLanguage?.txtStudentlogin}
        </p>
        <Form
          name='form'
          onFinish={onFinish}
          layout={'vertical'}
          requiredMark={false}
        >

          <div className='form-two-input-flex'>
            <div className='form-two-input-flex-inner-input'>
              <Form.Item
                name='email'
                label={<p className='new-pp-colr-wht'>{selectedLanguage?.Email}</p>}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: selectedLanguage?.PleaseInputYourEmail,
                  },
                  {
                    type: 'email',
                    message: selectedLanguage?.EmailIsNotValid,
                  },
                ]}
              >
                <Input className='form-input-new-style' placeholder={selectedLanguage?.InputYourEmailHere} />
              </Form.Item>
            </div>
            <div className='form-two-input-flex-inner-input'>
              <Form.Item
                name='password'
                label={<p className='new-pp-colr-wht'>{selectedLanguage?.Password}</p>}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: selectedLanguage?.PleaseInputYourPassword,
                  },
                  {
                    min: 6,
                    message: selectedLanguage?.PasswordMustBeAtLeastCharacters,
                  },
                ]}
              >
                <Input.Password
                  className='form-input-new-style'
                  placeholder={selectedLanguage?.InputYourPasswordHere}
                />
              </Form.Item>
            </div>
          </div>
          {path === allPaths?.BU_LOGIN ? (
            <Form.Item>
              <Button
                type='primary'
                className='newlogin-btn form-input-new-style'
                style={{ height: '45px', width: '99%', marginTop: '10px', /* marginLeft: '1%'  */}}
                htmlType='submit'
                loading={loading}
              >
                {selectedLanguage?.txt_Teacherlogin}
              </Button>
            </Form.Item>
          ) : (
            <Form.Item>
              <Button
                type='primary'
                className='newlogin-btn form-input-new-style'
                style={{ height: '45px', width: '99%', marginTop: '10px', /* marginLeft: '1%'  */}}
                htmlType='submit'
                loading={loading}
              >
                {selectedLanguage?.Login}
              </Button>
            </Form.Item>
          )}


        </Form>
        <div style={{ textAlign: 'center' }}>
          <p>
            {selectedLanguage?.DonTaveAnAccount}&nbsp;
            {path === allPaths?.BU_LOGIN ?
              <span>
                <Link to={allPaths?.BU_SIGNUP}>{<span className='new-pp-colr-wht'>{selectedLanguage?.txt_Teacherregister}</span>}</Link>
                <br />
                <Link to={allPaths?.BU_FORGOT}>{<span className='new-pp-colr-wht'>{selectedLanguage?.ForgotPassword}</span>}</Link>
              </span>
              :
              <span>
                <Link to={allPaths.SIGNUP}>{<span className='new-pp-colr-wht'>{selectedLanguage?.txt_Studentregister}</span>}</Link>
                <br />
                <Link to={allPaths?.FORGOT}>{<span className='new-pp-colr-wht'>{selectedLanguage?.ForgotPassword}</span>}</Link>
              </span>
            }
          </p>
        </div >
      </div >
    </div >
  )
}

export default Login
