import { Modal } from "antd";
import React from "react";

const VideoModal = (props) => {
    const { onCancel, selectedLanguage, VideoData } = props
    return (
        <div>
            <Modal
                open={true}
                onCancel={onCancel}
                footer={null}>
                <div>
                    <h1 style={{ textAlign: 'center' }}>Videos...</h1>
                    <div style={{
                        background: 'rgba(37, 155, 210, 0.24)',
                        padding: '10px 20px 10px 20px',
                        borderRadius: '12px',
                    }}>
                        {
                            VideoData?.map((v, i) => {
                                return (
                                    <div style={{
                                        width: '100%',
                                        background: 'rgba(37, 155, 210, 0.24)',
                                        padding: '10px',
                                        borderRadius: '12px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                        <h3>{v?.name}</h3>
                                        <video width={'100%'} height={'auto'}
                                            style={{ borderRadius: '12px' }}
                                            controls
                                        >
                                            <source src={v?.file?.url} />
                                        </video> 
                                        <p>{v?.discription}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default VideoModal