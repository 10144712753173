import React from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { Questions } from '../../../Screens'
import { allPaths } from '../../../utils/constants'


const NewQuestionScreen = (props) => {
    const { user, history, selectedLanguage } = props
    if (user?.userType === 'guest') {
        history.push(allPaths.GUEST_HOME)
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote4}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <h3 className='new-exam-title' style={{paddingLeft:'8px'}}>{selectedLanguage?.txt_Questions}</h3>
                <div className='main-div-for-sub-cards'>
                    <Questions {...props} />
                </div>
            </div>
        </div>
    )
}
export default NewQuestionScreen