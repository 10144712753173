import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Button, Form, Input, Modal, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ShowAllFiles } from '../../../Components'
import { ADMIN } from '../../../utils/apis'
import { adminData, errorMessage, successMessage } from '../../../utils/helpers'
import AdminAddImages from './AdminAddImages'
import { useSelector } from 'react-redux'

const AdminImages = (props) => {
    const { adminActions, lanKorCondition, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [remarksloading, setRemarksLoading] = useState(false)
    const [showRemarks, setShowRemarks] = useState({})
    const [showAddImage, setShowAddImage] = useState(false)
    const [spin, setSpin] = useState({})
    const [deleteSpin, setDeleteSpin] = useState({})
    const [data, setData] = useState({})
    const adminImages = useSelector(state => state?.adminReducer?.adminImages || [])

    useEffect(() => {
        adminData.getAdminImages(adminActions)
    }, [])

    const deleteImages = (id) => {
        setSpin({ [id]: true })
        axios.post(ADMIN?.DELETE_IMAGES, { id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    adminData.getAdminImages(adminActions)
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }

            })
            .catch((e) => {
                setSpin({})
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }
    const approveImages = (id) => {
        setDeleteSpin({ [id]: true })
        axios.post(ADMIN?.APPROVE_IMAGES, { id })
            .then((res) => {
                const { data } = res
                setDeleteSpin({})
                if (data?.success) {
                    adminData.getAdminImages(adminActions)
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }

            })
            .catch((e) => {
                setDeleteSpin({})
                errorMessage(lanKorCondition ? e?.message : e?.messageNl)
            })
    }
    const columns = [
        {
            title: selectedLanguage?.newtxt_Name,
            dataIndex: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: selectedLanguage?.txt_Files,
            render: (e) => <Button onClick={() => setData(e)}>Files</Button>,
        },
        {
            title: selectedLanguage?.txt_Completed,
            dataIndex: 'isCompleted',
            align: 'center',
            sorter: (a, b) => a.isCompleted - b.isCompleted,
            render: (e) => <>{e ? <CheckCircleFilled style={{ color: 'green' }} /> : <CloseCircleFilled style={{ color: 'red' }} />}</>,
        },
        {
            title: selectedLanguage?.txt_Approved,
            align: 'center',
            sorter: (a, b) => a.isApproved - b.isApproved,
            render: (e) => <>{e?.isApproved ? <CheckCircleFilled style={{ color: 'green' }} /> :
                <Button loading={deleteSpin[e?._id]} onClick={() => approveImages(e?._id)}>{selectedLanguage?.txt_Approve}</Button>}</>,
        },
        {
            title: selectedLanguage?.txt_Remarks,
            align: 'center',
            render: (e) => <Button type={e?.remarks ? 'primary' : 'default'} onClick={() => (setData(e), setShowRemarks({ _id: e?._id, remarks: e?.remarks }))}>{selectedLanguage?.txt_Remarks}</Button>
        },
        {
            title: selectedLanguage?.txt_Designer_Updated,
            dataIndex: 'designerUpdatedAt',
            align: 'center',
            sorter: (a, b) => new moment(b.designerUpdatedAt, 'DD-MM-YYYY HH:mm') - new moment(a.designerUpdatedAt, 'DD-MM-YYYY HH:mm'),
        },
        {
            title: selectedLanguage?.txt_Admin_Updated,
            dataIndex: 'adminUpdatedAt',
            align: 'center',
            sorter: (a, b) => new moment(b.adminUpdatedAt, 'DD-MM-YYYY HH:mm') - new moment(a.adminUpdatedAt, 'DD-MM-YYYY HH:mm'),
        },
        {
            title: selectedLanguage?.txt_ActionAction,
            render: (e) => <>
                <Popconfirm
                    title={selectedLanguage?.txt_Areyousureyouwanttodeleteit}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteImages(e?._id)}
                >
                    {spin[e?._id] ?
                        <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> :
                        <Button type='primary' danger>{selectedLanguage?.txt_Delete}</Button>
                    }
                </Popconfirm >
            </>

        },
    ]

    const saveRemarks = async (values) => {
        setRemarksLoading(true)
        values._id = showRemarks?._id
        axios.post(ADMIN?.ADD_ADMIN_IMAGES_REMARKS, values)
            .then((res) => {
                const { data } = res
                setRemarksLoading(false)
                if (data?.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageNl)
                    adminData.getAdminImages(adminActions)
                    setShowRemarks({})
                } else {
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
                return errorMessage(lanKorCondition ? e?.message : e?.messageNl)

            })
    }
    const onCancel = () => {
        setData({})
        setShowRemarks({})
        setShowAddImage(false)
    }
    return (
        <>
            <div style={{ marginTop: 20, marginRight: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <h1>
                        {selectedLanguage?.txt_Images}
                    </h1>
                    <Button
                        type='primary'
                        size='large'
                        onClick={() => setShowAddImage(true)} >{selectedLanguage?.txt_Add_Image_Files}</Button>
                    <AdminAddImages showAddImage={showAddImage} onCancel={onCancel} {...props} />
                </div>
                <div>
                    <div className='new-layout-table-upper-div'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            loading={loading}
                            columns={columns}
                            dataSource={adminImages || []}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 'auto' }}

                        />
                    </div>
                </div>
            </div>
            <Modal
                className='pop-info-modal'
                style={{ top: '10px' }}
                open={data?._id && !showRemarks?._id}
                footer={false}
                onCancel={onCancel}
            >
                <ShowAllFiles data={data} {...props} />
            </Modal>
            {showRemarks?._id && < Modal
                className='pop-info-modal'
                style={{ top: '10px' }}
                open={showRemarks?._id}
                footer={false}
                onCancel={onCancel}
            >
                <div style={{ maxHeight: '63vh', overflowY: 'auto' }}>
                    <ShowAllFiles data={data} {...props} remarks={true} />
                </div>

                <Form
                    onFinish={saveRemarks}
                    layout='vertical'
                >
                    <Form.Item
                        name='remarks'
                        label={selectedLanguage?.txt_Remarks}
                        initialValue={showRemarks?.remarks}
                    >
                        <Input.TextArea rows={2} placeholder={selectedLanguage?.txt_RemarksAdd} />
                    </Form.Item>
                    <Form.Item >
                        <Button
                            htmlType='submit'
                            style={{ borderRadius: 4, width: '100%' }}
                            type='primary'
                            loading={remarksloading}
                        >
                            {selectedLanguage?.txt_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >}
        </>
    )
}

export default AdminImages
