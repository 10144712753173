import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, POST } from '../../../utils/apis'
import { errorMessage, generalData, successMessage, teacherData } from '../../../utils/helpers'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { TfiMenu } from 'react-icons/tfi'
import NewTeacherDashboard from '../Dashboard/TeacherDashboard'


const NewTeacherReportScreen = (props) => {
    const { user, userActions, generalActions, selectedLanguage } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = anchorEl;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <br />
                <div className='teacher-button-menu-end'>
                    <IconButton
                        aria-label='more'
                        id='long-button'
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup='true'
                        onClick={handleClick}
                    >
                        <TfiMenu size={28} color='#1eb2a6' />
                    </IconButton>
                    <Menu
                        id='long-menu'
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem
                            key={1}
                            onClick={handleClose}
                        >
                            <Button
                                type='primary'
                                className='newlogin-btn form-input-new-style'
                                style={{ width: '100%' }}
                            // onClick={() => setShowQuestion(true)}
                            >
                                {'Comming Soon...'}
                            </Button>
                        </MenuItem>
                    </Menu>
                </div>
                <br />
                <NewTeacherDashboard  {...props} />
            </div>
        </div>
    )
}
export default NewTeacherReportScreen