import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './Redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
// import TouchBackend from 'react-dnd-touch-backend';

ReactDOM.render(<Provider store={store}>
    <PersistGate persistor={persistor}>
        {/* <DndProvider backend={!navigator.userAgent.includes('Android') ? TouchBackend : HTML5Backend}> */}
        <DndProvider backend={(!navigator.maxTouchPoints) || (/iPhone|iPad|iPod/i.test(navigator.userAgent) && navigator.maxTouchPoints) ? HTML5Backend : TouchBackend}>
            <App />
        </DndProvider>
    </PersistGate>
</Provider>, document.getElementById('root'))