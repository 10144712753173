import React from 'react';
import PreviewMaths from './PreviewMaths';
import { Image, Modal } from 'antd';
import DragDropQuiz from './DragDrop/DragDropQuiz';

const QuestionDetail = (props) => {
    const { viewSelectedData, selectedLanguage, isModalVisible, handleOk, handleCancel } = props
    console.log(viewSelectedData, "=viewSelectedData")
    return (
        <Modal
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <p className='new-p-question'>{selectedLanguage?.title_QuestonDetail}</p>

            <strong>{selectedLanguage?.title_Title}:</strong> {`${viewSelectedData?.title}`}<br />
            {viewSelectedData?.titleFile?.mediaType && <><strong>{selectedLanguage?.title_FileforTitle}:</strong> {
                viewSelectedData?.titleFile?.mediaType == 'video' ?
                    <video width={'200px'} height={'auto'}
                        style={{ borderRadius: '12px' }}
                        controls
                    >
                        <source src={viewSelectedData?.titleFile?.url} />
                    </video>
                    : viewSelectedData?.titleFile?.mediaType === 'image' ? <Image src={viewSelectedData?.titleFile?.url} width={150} height={'auto'} /> : viewSelectedData?.file?.mediaType === 'application' ?
                        < object data={viewSelectedData?.file?.url} type='application/pdf' width='100%' height='300px'>
                            <p>Unable to display PDF file. <a href={viewSelectedData?.file?.url}>Download</a> instead.</p>
                        </object>
                        : null
            } <br /></>}
            <strong>{selectedLanguage?.title_Question}:</strong>
            {/* <br /> */}
            {
                viewSelectedData?.subject?.subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={viewSelectedData?.question || ' '} /> : <>{viewSelectedData?.question}
                    <br /></>
            }

            {/* {`${viewSelectedData?.question}`}<br /> */}
            {viewSelectedData?.file?.mediaType && <><br /><strong>{selectedLanguage?.File}:</strong> {
                viewSelectedData?.file?.mediaType == 'video' ?
                    <>
                        <video width={'200px'} height={'auto'}
                            style={{ borderRadius: '12px' }}
                            controls
                        >
                            <source src={viewSelectedData?.file?.url} />
                        </video>
                    </>
                    : viewSelectedData?.file?.mediaType == 'image' ? <Image src={viewSelectedData?.file?.url} width={150} height={'auto'} />
                        : viewSelectedData?.file?.mediaType === 'application' ?
                            < object data={viewSelectedData?.file?.url} type='application/pdf' width='100%' height='300px'>
                                <p>Unable to display PDF file. <a href={viewSelectedData?.file?.url}>Download</a> instead.</p>
                            </object>
                            : null
            } <br /></>}
            <strong>{selectedLanguage?.label_QuestionType}:</strong> {`${viewSelectedData?.questionType}`}<br />
            <strong>{selectedLanguage?.label_Points}:</strong> {`${viewSelectedData?.point}`}<br />
            <strong>{selectedLanguage?.label_Time}:</strong> {`${viewSelectedData?.time || 0}`}<br />
            <strong>{selectedLanguage?.title_AnswerType}:</strong> {`${viewSelectedData?.answerType}`}<br />
            <strong>{selectedLanguage?.title_Answer}:</strong>
            <ul>
                {(viewSelectedData?.answerType === 'dragDrop' || viewSelectedData?.answerType === 'dragDropSequence') ?
                    <DragDropQuiz sequence={viewSelectedData?.answerType === 'dragDropSequence'} imgUrl={viewSelectedData?.dragFile?.url} spots={viewSelectedData?.answerList} />
                    :
                    viewSelectedData?.answerList?.filter((v) => !v?.isTrue)?.map((v, i) => {
                        return (
                            <>
                                {/* <li key={i}>{v ? v?.option : selectedLanguage?.title_NoAnswerFound}</li> */}
                                <li key={i}>{viewSelectedData?.subject?.subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={v?.option || ' '} /> : v ? v?.option : selectedLanguage?.title_NoAnswerFound}</li>
                                <ul>
                                    {v?.explaination ? <li> <strong> {selectedLanguage?.Explaination}:
                                        {
                                            viewSelectedData?.subject?.subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={v?.explaination || ' '} /> : v?.explaination
                                        }
                                    </strong></li> : null}
                                    {
                                        v?.file?.mediaType == 'video' ?
                                            <>
                                                <video wwidth={'200px'} height={'auto'}
                                                    style={{ borderRadius: '12px' }}
                                                    controls
                                                >
                                                    <source src={v?.file?.url} />
                                                </video>
                                            </>
                                            : v?.file?.mediaType == 'image' ? <Image src={v?.file?.url} width={150} height={'auto'} /> :
                                                viewSelectedData?.file?.mediaType === 'application' ?
                                                    < object data={viewSelectedData?.file?.url} type='application/pdf' width='100%' height='300px'>
                                                        <p>Unable to display PDF file. <a href={viewSelectedData?.file?.url}>Download</a> instead.</p>
                                                    </object>
                                                    : null
                                    }
                                </ul>
                            </>

                        )
                    })}
            </ul>
            {
                viewSelectedData?.questionType == 'test' && (viewSelectedData?.answerType !== 'dragDrop' && viewSelectedData?.answerType !== 'dragDropSequence') ?
                    <>
                        <strong>{selectedLanguage?.title_RightAnswer}:</strong>
                        <ul>
                            {viewSelectedData?.answerList?.filter((v) => v?.isTrue).map((v, i) => {
                                return (
                                    <>
                                        <li key={i}>{viewSelectedData?.subject?.subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={v?.option || ' '} /> : v ? v?.option : selectedLanguage?.title_NoAnswerFound}</li>
                                        <ul>
                                            {v?.explaination ? <li> <strong> {selectedLanguage?.Explaination}:
                                                {
                                                    viewSelectedData?.subject?.subjectName?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={v?.explaination || ' '} /> : v?.explaination
                                                }
                                            </strong></li> : null}
                                            {
                                                v?.file?.mediaType == 'video' ?
                                                    <>
                                                        <video width={'200px'} height={'auto'}
                                                            style={{ borderRadius: '12px' }}
                                                            controls
                                                        >
                                                            <source src={v?.file?.url} />
                                                        </video>
                                                    </>
                                                    : v?.file?.mediaType == 'image' ? <Image src={v?.file?.url} width={150} height={'auto'} /> : null
                                            }
                                        </ul>
                                    </>
                                )
                            })}
                        </ul>
                    </>
                    : null
            }

            <strong>{selectedLanguage?.label_Language}:</strong> {`${viewSelectedData?.language?.languageName}`}<br />
            <strong>{selectedLanguage?.label_Tution}:</strong> <br />
            <ul>
                {viewSelectedData?.tutions?.map((v, i) => {
                    return (
                        <li key={i}>{v ? v.tutionName : null}</li>
                    )
                })}
            </ul>
            <strong>{selectedLanguage?.label_Grade}:</strong> {`${viewSelectedData?.grade}`}<br />
            <strong>{selectedLanguage?.label_Level}:</strong> {`${viewSelectedData?.level}`}<br />
            <strong>{selectedLanguage?.label_Public}:</strong> {`${viewSelectedData?.public}`}<br />
            <strong>{selectedLanguage?.title_Subject}:</strong> {`${viewSelectedData?.subject?.subjectName}`}<br />
            <strong>{selectedLanguage?.label_Chapter}:</strong> {`${viewSelectedData?.chapter?.chapterName}`}<br />
            <strong>{selectedLanguage?.label_Paragraph}:</strong> {`${viewSelectedData?.paragraph?.paragraphName}`}<br />
            <strong>{selectedLanguage?.label_Topics}:</strong> <br />
            <ul>
                {viewSelectedData?.topics?.map((v, i) => {
                    return (
                        <li key={i}>{v ? v.topicName : selectedLanguage?.title_NotopicFound}</li>
                    )
                })}
            </ul>
            <strong>{'Index'}: {viewSelectedData?.index}</strong> <br />
            <strong>{'Videos'}:</strong><br />
            {viewSelectedData?.videos?.length ?
                viewSelectedData?.videos?.map((v, i) => {
                    console.log(v, '=======s')
                    return (
                        <div>
                            <video width={'200px'} height={'auto'}
                                style={{ borderRadius: '12px' }}
                                controls
                            >
                                <source src={v?.file?.url} />
                            </video>
                            <br />
                        </div>
                    )

                }) : <p>No vidoes added</p>
            }

            <strong>{'Cards'}:</strong><br />
            {viewSelectedData?.cards?.length ?
                viewSelectedData?.cards?.map((value, i) => {
                    console.log(value, '=======185s')
                    return (
                        <div style={{ borderRadius: '10px', background: '#A4C8CE', padding: '10px', marginTop: '10px' }}>
                            <strong>Card title:{value?.title}</strong>
                            <br />
                            <strong>Card content</strong>
                            <ul>
                                {value?.content?.map((v, i) =>
                                (<div Key={i}>
                                    {v?.description ? <li > Description:{v?.description}</li> : null}
                                    {v?.files?.length ?
                                        <li >Files:
                                            <ol>
                                                {
                                                    v?.files.map((x, ind) => (
                                                        <li key={`0${ind}`}><Image width={150} src={x?.url} />  </li>
                                                    ))
                                                }
                                            </ol>
                                        </li>
                                        : null}
                                </div>)
                                )}
                            </ul >
                        </div>
                    )

                }) : <p>No cards added</p>
            }
        </Modal>
    )
}

export default QuestionDetail
