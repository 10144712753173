import { ALL_SUBJECTS, ALL_SUBSCRIPTION, ALL_TEACHERS, ALL_TUTIONS, CHAPTER, CITIES, COLLAPSED, LANGUAGES, PARAGRAPHS, RATES, TAXES, TOPICS } from '../types'

const setCollapsed = (inlineCollapsed) => {
    return {
        type: COLLAPSED,
        inlineCollapsed
    }
}

const setCities = (cities) => {
    return {
        type: CITIES,
        cities
    }
}
const setTaxes = (taxes) => {
    return {
        type: TAXES,
        taxes
    }
}
const setAllTeachers = (allTeachers) => {
    return {
        type: ALL_TEACHERS,
        allTeachers
    }
}
const setAllChapters = (chapters) => {
    return {
        type: CHAPTER,
        chapters
    }
}
const setAllSubjects = (subjects) => {
    return {
        type: ALL_SUBJECTS,
        subjects
    }
}
const setAllParagraphs = (paragraphs) => {
    return {
        type: PARAGRAPHS,
        paragraphs
    }
}
const setAllTopics = (topics) => {
    return {
        type: TOPICS,
        topics
    }
}
const addAllTutions = (tutions) => {
    return {
        type: ALL_TUTIONS,
        tutions
    }
}
const setAllLanguages = (adminLanguages) => {
    return {
        type: LANGUAGES,
        adminLanguages
    }
}
const addAllSubscription = (subscriptions) => {
    return {
        type: ALL_SUBSCRIPTION,
        subscriptions
    }
}
export {
    setCollapsed,
    setCities,
    setTaxes,
    setAllTeachers,
    setAllChapters,
    setAllSubjects,
    setAllParagraphs,
    setAllTopics,
    addAllTutions,
    setAllLanguages,
    addAllSubscription
}