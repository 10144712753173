import { Button, Popconfirm, Spin, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegEye } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { TbEdit } from 'react-icons/tb';
import { useSelector } from "react-redux";
import { QuestionDetail } from "../../../Components";
import { ADMIN } from "../../../utils/apis";
import { adminData, errorMessage, successNotification } from "../../../utils/helpers";
import EditSpecialQuestion from "./EditSpecialQuestion";

const SpecialQuestion = (props) => {
    const { user, selectedLanguage, onCancel, adminActions, lanKorCondition } = props
    const questions = useSelector(state => state.adminReducer?.adminSpecialQuestions)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [viewSelectedData, setViewSelectedData] = useState({})
    const [spin, setSpin] = useState({})
    const [editQuestion, setEditQuestion] = useState({})

    useEffect(() => {
        adminData.getSpecialQuestions(adminActions)
    }, [])

    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }
    const handleOk = () => {
        setIsModalVisible(false)
    }
    const handleCancel = () => {
        setIsModalVisible(false)
        setViewSelectedData({})

    }
    const deleteQuestion = (_id) => {
        setSpin({ [_id]: true })

        let valObj = { _id }
        axios.post(`${ADMIN.DELETE_SPECIAL_QUESTION}`, valObj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    adminData.getSpecialQuestions(adminActions)
                    setSpin({})
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
                else {
                    setSpin({})
                    return errorMessage(lanKorCondition ? data?.message : data?.messageNl)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }
    const columns = [
        {
            title: selectedLanguage?.title_Questions,
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: selectedLanguage?.title_Title,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: selectedLanguage?.label_Public,
            dataIndex: 'public',
            key: 'public',
            sorter: (a, b) => a.public - b.public,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: selectedLanguage?.title_Subject,
            dataIndex: ['subject', 'subjectName'],
            sorter: (a, b) => a?.subject?.subjectName?.length - b?.subject?.subjectName?.length,
        },
        {
            title: selectedLanguage?.label_Chapter,
            dataIndex: ['chapter', 'chapterName'],
            sorter: (a, b) => a?.chapter?.chapterName?.length - b?.chapter?.chapterName?.length,
        },
        {
            title: selectedLanguage?.label_Paragraph,
            dataIndex: ['paragraph', 'paragraphName'],
            sorter: (a, b) => a?.paragraph?.paragraphName?.length - b?.paragraph?.paragraphName?.length,
        },
        {
            title: selectedLanguage?.Status,
            key: 'actions',
            align: 'center',
            sorter: (a, b) => a?.isApproved - b?.isApproved,
            render: (e) => <>{
                <>
                    {
                        e?.public && e?.isApproved ?
                            <p style={{ marginBottom: 0, color: 'green' }}>{selectedLanguage?.Approved}</p>
                            : e?.public && e?.isDeclined ?
                                <p style={{ marginBottom: 0, color: 'red' }}>{selectedLanguage?.Declined}</p>
                                : e?.public && !e?.isApproved && !e?.isDeclined ?
                                    <p style={{ marginBottom: 0, color: 'blue' }}>{selectedLanguage?.Pending}</p>
                                    :
                                    <p style={{ marginBottom: 0 }}>{selectedLanguage?.Private}</p>
                    }
                </>
            }</>
        },
        {
            title: selectedLanguage?.title_Creater,
            dataIndex: ['userId', 'email'],
            render: text => <p style={{ marginBottom: 0 }}>{text || selectedLanguage?.title_Admin}</p>,
        },
        {
            key: 'action',
            render: (e) =>
                <>
                    <span style={{ width: '115px', display: 'flex' }}>
                        <span onClick={() => showModal(e)}><FaRegEye size={19} style={{ color: 'black', cursor: 'pointer' }} /></span> &nbsp;&nbsp;&nbsp;
                        <span onClick={() => setEditQuestion(e)}><TbEdit size={19} style={{ color: 'black', cursor: 'pointer' }} /></span> &nbsp;&nbsp;&nbsp;
                        {spin?.[e?._id] ?
                            <Spin /> :
                            <Popconfirm
                                title={selectedLanguage?.title_DeletethisQuestion}
                                okText={selectedLanguage?.Delete}
                                cancelText={selectedLanguage?.Cancel}
                                okButtonProps={{
                                    type: 'primary',
                                    className: 'form-button'
                                }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => deleteQuestion(e?._id)}
                            >
                                <span><MdOutlineDeleteOutline size={19} style={{ color: 'red', cursor: 'pointer' }} /></span>
                            </Popconfirm>
                        }
                    </span>
                </>
        },
    ]

    return (
        <div>{
            editQuestion?._id ?
                <EditSpecialQuestion editQuestion={editQuestion} onCancel={() => setEditQuestion({})} {...props} />
                :
                <div>
                    <p className='crt-qutin-heading' style={{ color: 'black', margin: '0px', marginTop: '10px' }}>{selectedLanguage?.SpecialQuestion}</p>
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Button
                            style={{
                                padding: '0px 20px 0px 20px',
                                background: '#1eb2a6',
                                color: 'white',
                                border: 'none',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            type='primary'
                            onClick={() => { onCancel(); }}
                        >
                            {selectedLanguage?.Cancel}
                        </Button>
                    </span>

                    <div style={{ marginTop: '20px' }}>
                        {viewSelectedData?._id &&
                            <QuestionDetail viewSelectedData={viewSelectedData} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} {...props} />
                        }
                        <div className='new-layout-table-upper-div margin0' >
                            <Table
                                style={{ marginTop: '30px' }}
                                className='new-layout-table'
                                scroll={{ x: 'auto' }}
                                columns={columns}
                                pagination={{ hideOnSinglePage: true }}
                                dataSource={questions}
                            // pagination={false}
                            />
                        </div>
                    </div>
                </div>}
        </div >
    )
}

export default SpecialQuestion