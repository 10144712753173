import { Button, Checkbox, Col, Input, Row } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../../utils/apis'
import { checkDurationType, generalData, successNotification } from '../../../../utils/helpers'

const TestallyPriceSettings = (props) => {
    const { selectedLanguage, generalActions, subscriptionList } = props
    const [loading, setLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState(subscriptionList || [])
    useEffect(() => {
        generalData.getAllSubscriptions(generalActions)
    }, [])
    useEffect(() => {
        setSubscriptions(subscriptionList || [])
    }, [subscriptionList])

    const updateSubscription = async () => {
        setLoading(true)
        let allPromise = []
        for (var v of subscriptions) {
            allPromise.push(Promise.resolve(axios.post(ADMIN.UPDATE_SUBSCRIPTION, v)))
        }
        await Promise.all(allPromise)
            .then(() => {
                setLoading(false)
                generalData.getAllSubscriptions(generalActions)
                successNotification(selectedLanguage?.SuccssfullyUpdated)
            })
    }

    const updateState = (i, k, v, arr) => {
        if (v < 0) {
            return
        }
        arr[i][k] = v
        let otherSub = subscriptions.filter((x) => x?.coupon !== arr[i]?.coupon)
        console.log('otherSub',otherSub)
        setSubscriptions([...(arr), ...otherSub] || [])
    }
    return (
        <div style={{ paddingBottom: 40 }}>
            <h2>Teacher</h2>
            {subscriptions.filter((v) => v?.coupon)?.map((v, i, arr) => {
                return (
                    <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                        <Col span={24}>
                            <span>

                                &nbsp;{v?.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <Input
                                type='number'
                                className='subs-input'
                                value={v?.amount}
                                addonAfter='€'
                                // addonBefore='€'
                                min={0}
                                onChange={e => updateState(i, 'amount', e?.target?.value, arr)}
                            />
                        </Col>
                    </Row>
                )
            })}
            <h2>Student</h2>
            {subscriptions.filter((v) => !v?.coupon)?.map((v, i, arr) => {
                return (
                    <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                        <Col span={24}>
                            <span>
                                <Checkbox checked={v?.activate} onChange={e => updateState(i, 'activate', e?.target?.checked, arr)} />
                                &nbsp;{v?.name}&nbsp;&nbsp;&nbsp;{v?.duration.toString() + ' ' + checkDurationType(v)}&nbsp;&nbsp;&nbsp;
                            </span>

                            <Input
                                type='number'
                                className='subs-input'
                                value={v?.amount}
                                addonAfter='€'
                                // addonBefore='€'
                                min={0}
                                onChange={e => updateState(i, 'amount', e?.target?.value, arr)}
                            />
                        </Col>
                    </Row>
                )
            })}
            {subscriptions?.length ? <Button
                onClick={updateSubscription}
                className='form-button'
                type='primary'
                loading={loading}
            >
                {selectedLanguage?.Update}
            </Button> : null}
        </div >
    )
}

export default TestallyPriceSettings