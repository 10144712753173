import { FileTextOutlined } from '@ant-design/icons'; // Import Ant Design icons
import { Avatar, Button, Image, List } from 'antd';
import React from 'react';


const renderFileItem = (file) => (
    <List.Item key={file.name}>
        <List.Item.Meta
            avatar={
                file.type === 'image' ? (
                    <Avatar src={file.url} />
                ) : (
                    <Avatar icon={<FileTextOutlined />} />
                )
            }
            title={file.name}
            description={`Type: ${file.type.toUpperCase()}`}
        />
        {file.type === 'image' ? (
            <Image style={{ width: 150 }} alt={file.name} src={file.url} />
        ) : (
            <Button type='primary' href={file.url}>Download</Button>
        )}
    </List.Item>
);

const ShowAllFiles = (props) => {
    const { data, remarks } = props
    console.log('data=-=-=-=->', data)
    return (
        <>
            <h2>Examples</h2>
            <List
                itemLayout='horizontal'
                dataSource={data?.exampleFiles}
                renderItem={renderFileItem}
            />
            <h2>New design</h2>
            <List
                itemLayout='horizontal'
                dataSource={data?.threeDFiles}
                renderItem={renderFileItem}
            />
            {!remarks &&
                <>
                    <h2>Remarks</h2>
                    <p>
                        {data?.remarks}
                    </p>
                </>
            }
        </>
    );
};

export default ShowAllFiles;
