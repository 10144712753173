import React, { useEffect, useState } from "react";
import NewTeacherCardsReview from "./TeacherCardsReview";
import NewTeacherVideosReview from "./TeacherVideoReview";
import { Tabs } from "antd";
import NewTeacherExamsReview from "./TeacherExamReview";

const TeacherCardsReview = (props) => {
    const { selectedLanguage } = props
    const { TabPane } = Tabs
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote4}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <br />
                <br />
                <Tabs className='new-new-my-tabs' defaultActiveKey='1' centered>
                    <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_CardsReview}</p>} key='1'>
                        <NewTeacherCardsReview  {...props} />
                    </TabPane>
                    <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_VideosReview}</p>} key='2'>
                        <NewTeacherVideosReview  {...props} />
                    </TabPane>
                    <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.txt_ExamsReview}</p>} key='3'>
                        <NewTeacherExamsReview  {...props} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default TeacherCardsReview