import React, { useEffect, useState } from "react";
import { NewFooter } from "../../NewComponents";
import VisiterFaqContent from "../VisiterFaq/VisiterFaqContent";
import { useSelector } from "react-redux";
import NewVisiterHeader from "../VisiterHome/VisiterHeader";

import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import axios from "axios";
import { ADMIN } from "../../utils/apis";
import { useHistory } from "react-router-dom";

const VisiterFaq = () => {
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = language?.language === 'english' ? EnglishLanguage : NlLanguage
    const history = useHistory()
    return (
        <div className="new-main-layout-div">
            <div className="visiter-home-main-div ">
                <div className="visiter-home-main-div-scroll new-layout-scroll-class">
                    <NewVisiterHeader language={language} selectedLanguage={selectedLanguage} />
                    <VisiterFaqContent language={language} selectedLanguage={selectedLanguage} />
                    <NewFooter history={history} language={language} selectedLanguage={selectedLanguage} />
                </div>
            </div>
        </div>
    )
}

export default VisiterFaq