import { Button, Select, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { QuestionDetail } from '../../../Components'
import { ADMIN } from '../../../utils/apis'
import { adminData, checkSelectOption, convertTitle } from '../../../utils/helpers'
// import ReactPlayer from 'react-player'
const { Option } = Select

const UsersQuestions = (props) => {
    const { onBack, user, selectedLanguage, adminActions } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const tutions = useSelector(state => state?.generalReducer?.tutions || [])
    const [businessUsers, setBusinessUsers] = useState(buUsers || [])
    const [selectedUser, setSelectedUser] = useState(businessUsers?.[0]?._id || null)
    const [selectedTution, setSelectedTution] = useState(tutions?.[0]?._id || null)
    const [loading, setLoading] = useState(false)
    const [showQuestions, setShowQuestions] = useState(false)
    const [questions, setQuestions] = useState([])
    const [forms, setForms] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewSelectedData, setViewSelectedData] = useState('')

    useEffect(() => {
        adminData.getBusinessUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        getBuQuestions()
        getBuForms()
    }, [selectedUser])

    useEffect(() => {
        let filteredData = buUsers?.filter((v) => v?.tutions === selectedTution)
        setBusinessUsers(filteredData)
        setSelectedUser(filteredData?.[0]?._id)
    }, [selectedTution])

    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const getBuQuestions = () => {
        setLoading(true)
        let obj = {
            userId: selectedUser,
            _id: user?._id,
        }
        axios.post(ADMIN.GET_BU_QUESTIONS, obj)
            .then((res) => {
                const { data } = res
                setQuestions(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    const getBuForms = () => {
        setLoading(true)
        let obj = {
            userId: selectedUser,
            _id: user?._id,
        }
        axios.post(ADMIN.GET_BU_FORMS, obj)
            .then((res) => {
                const { data } = res
                setForms(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const questionColumns = [
        {
            title: selectedLanguage?.title_Questions,
            dataIndex: 'question',
            key: 'question',
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_Title,
            dataIndex: 'title',
            key: 'title',
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.label_QuestionType,
            dataIndex: 'questionType',
            render: text => text?.toUpperCase(),
        },
        {
            title: selectedLanguage?.title_Subject,
            dataIndex: ['subject', 'subjectName'],
            sorter: (a, b) => a?.subject?.subjectName?.length - b?.subject?.subjectName?.length,
        },
        {
            title: selectedLanguage?.label_Chapter,
            dataIndex: ['chapter', 'chapterName'],
            sorter: (a, b) => a?.chapter?.chapterName?.length - b?.chapter?.chapterName?.length,
        },
        {
            title: selectedLanguage?.label_Paragraph,
            dataIndex: ['paragraph', 'paragraphName'],
            sorter: (a, b) => a?.paragraph?.paragraphName?.length - b?.paragraph?.paragraphName?.length,
        },
        {
            title: selectedLanguage?.title_Creater,
            dataIndex: ['userId', 'email'],
            // render: text => <a>{text}</a>,
        },
        {
            key: 'action',
            render: (e) => <span>
                <a onClick={() => showModal(e)}>{selectedLanguage?.View}</a> &nbsp;&nbsp;&nbsp;
            </span>
        },
    ]

    const formColumns = [
        {
            title: selectedLanguage?.label_FormName,
            dataIndex: 'formName',
            // render: text => <a>{text}</a>,
        },
        {
            title: selectedLanguage?.title_FormType,
            dataIndex: 'formType',
            render: text => text?.toUpperCase(),
        },
        {
            title: selectedLanguage?.label_QuestionType,
            dataIndex: 'questionsType',
            render: text => text?.toUpperCase(),
        },
        {
            title: selectedLanguage?.label_Totalpoints,
            dataIndex: ['totalPoints'],
            align: 'center'
            // render: text => <a>{text}</a>,
        },
        // {
        //     title: selectedLanguage?.label_Public,
        //     dataIndex: 'public',
        //     key: 'public',
        //     sorter: (a, b) => a.public - b.public,
        //     render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        // }
    ]

    return (
        <div>
            <div className='flex-mobile' style={{ display: 'flex', marginTop: '10px' }}>
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    defaultValue={selectedTution}
                    onChange={(e) => setSelectedTution(e)}
                    className='form-input'
                    placeholder={selectedLanguage?.label_SelectTutions}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {tutions?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.tutionName)}`}</Option>)}
                </Select>
            </div>

            <div className='flex-mobile' style={{ display: 'flex', marginTop: '10px' }}>
                <Select
                    style={{ maxWidth: '100%', width: '350px' }}
                    showSearch
                    showArrow
                    allowClear={false}
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e)}
                    className='form-input'
                    placeholder={selectedLanguage?.SelectUser}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {businessUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
            </div>
            <div>
                <div className='button-end button-end-scroll' style={{ marginTop: '10px' }}>
                    {
                        showQuestions ?
                            <Button
                                type='primary'
                                onClick={() => setShowQuestions(false)}
                            >
                                {selectedLanguage?.button_User__Forms}
                            </Button>
                            :
                            <Button
                                type='primary'
                                onClick={() => setShowQuestions(true)}
                            >
                                {selectedLanguage?.button_User_Questions}
                            </Button>
                    }

                    <Button
                        style={{ marginLeft: 5 }}
                        type='primary'
                        onClick={onBack}
                    >
                        {selectedLanguage?.Back}
                    </Button>
                </div>
                <div >
                    {viewSelectedData?._id &&
                        <QuestionDetail viewSelectedData={viewSelectedData} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} {...props} />
                    }
                    <div className='new-layout-table-upper-div margin0'>
                        <Table
                            style={{ marginTop: '10px' }}
                            className='new-layout-table'
                            loading={loading}
                            columns={showQuestions ? questionColumns : formColumns}
                            dataSource={showQuestions ? questions : forms}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: '800px' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersQuestions