import { Button, Select, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN } from '../../../utils/apis';
import { checkSelectOption, convertTitle } from '../../../utils/helpers';

const { Option } = Select

export const Feedback = (props) => {
  const { user, onCancel,selectedLanguage } = props
  const forms = useSelector(state => state?.userReducer?.forms || [])
  const [loading, setLoading] = useState(false)
  const [feedbackData, setFeedbackData] = useState([])
  const [selectedForm, setSelectedForm] = useState(forms[0]?._id || null)

  const getData = () => {
    setLoading(true)
    axios.get(`${ADMIN.GET_FEEDBACKS}/${selectedForm}`)
      .then((res) => {
        const { data } = res
        setFeedbackData(data?.data || [])
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }
  const columns = [
    {
      title: selectedLanguage?.title_FormType,
      dataIndex: ['formId', 'formType'],
    },
    {
      title: selectedLanguage?.label_QuestionType,
      dataIndex: ['formId', 'questionsType'],
    },
    {
      title: selectedLanguage?.label_age,
      dataIndex: 'age'
    }
  ]
  useEffect(() => {
    getData()
  }, [selectedForm])

  return (
    <div className='flex-column menu-main'>
      <div className='user-table' style={{ marginLeft: 20 }}>
        <div className='flex-row' style={{ marginBottom: -30 }}>
          <Select
            className='btn-end'
            showSearch
            showArrow
            allowClear
            defaultValue={forms[0]?._id}
            onChange={(e) => setSelectedForm(e)}
            // className='form-input'
            placeholder={selectedLanguage?.SelectUser}
            optionFilterProp='children'
            filterOption={checkSelectOption}
          >
            {forms?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.formName)}`}</Option>)}
          </Select>
          <Button
            type='primary'
            className='btn-end'
            onClick={() => onCancel()}
          >
            {selectedLanguage?.Back}
          </Button>
        </div>
        <br />
        <br />
        <Table
          loading={loading}
          columns={columns}
          dataSource={feedbackData}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 'auto' }}
        />
      </div>
    </div>
  );
};


export default Feedback