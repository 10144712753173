import React from 'react';
import { Modal } from 'antd'


const FeedbackDetailModal = (props) => {
    const { open, onCancel, data, selectedLanguage } = props
    return (
        <>
            <Modal
                open={open}
                onCancel={onCancel}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.ttx_FeedbackDetail}</p>
                <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_title}:</span>{data?.title}</p>
                <p className='margin0'><span className='colored-span'>{selectedLanguage?.title_FormType}:</span>{data?.formType}</p>
                 {data?.groupmembers ? <p className='margin0'><span className='colored-span'>{selectedLanguage?.txt_Groupmembers}:</span>{data?.groupmembers}</p> : null} <br />

                <>
                    {data?.students?.length ?
                        <>
                            <span className='colored-span'>{selectedLanguage?.Button_Students}</span>
                            <ul>
                                {data?.students?.map((v, i) => {
                                    return (
                                        <li key={i}>
                                            <p className='margin0'>{v?.fullName}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                        : null}
                </>
                
                <>
                    {data?.teachers?.length ?
                        <>
                            <span className='colored-span'>{selectedLanguage?.txt_Teachers}</span>
                            <ul>
                                {data?.teachers?.map((v, i) => {
                                    return (
                                        <li key={i}>
                                            <p className='margin0'>{v?.fullName}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                        : null}
                </>

                <>
                    {data?.companyOwners?.length ?
                        <>
                            <span className='colored-span'>{selectedLanguage?.txt_CompanyOwner}</span>
                            <ul>
                                {data?.companyOwners?.map((v, i) => {
                                    return (
                                        <li key={i}>
                                            <p className='margin0'>{v?.fullName}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                        : null}
                </>

                <>
                    {data?.content?.length ?
                        <div className='admin-modal-responses'>
                            <span className='colored-span'>{selectedLanguage?.txt_Content}</span>
                            {data?.content?.map((v, i) => {
                                return (
                                    <div className='form-feedback-student' key={i} >
                                        <p className='margin0'><span className='colored-span'>{selectedLanguage?.Description}:</span>{v?.description}</p>
                                        <p className='margin0'><span className='colored-span'>{selectedLanguage?.label_Value1}:</span>{v?.value1}</p>
                                        <p className='margin0'><span className='colored-span'>{selectedLanguage?.label_Value2}:</span>{v?.value2}</p>
                                    </div>
                                )
                            })}
                        </div>
                        : null}
                </>
            </Modal>
        </>
    )
}
export default FeedbackDetailModal