import { Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { checkSelectOption } from '../../../utils/helpers';
import loafingImg from '../../../assets/load.gif'
import nodataFound from '../../../assets/NewImages/grapgh-not.png'

const { Option } = Select

const ExamChart = (props) => {
    const { selectedLanguage, text, data, loading } = props
    const [teacher, setTeacher] = useState('all')  
    let teachers = data.filter((v) => v.user && !v?.admin).map((v) => v?.user)
    teachers = _.uniqBy(teachers, '_id')
    useEffect(() => {

        setChartState(state => {
            return {
                ...state,
                series: checkData(),
            }
        })
    }, [teacher, data])

    const checkData = () => {
        if (teacher === 'all') {
            let passed = data?.reduce((a, { passed }) => a + passed, 0)
            let failed = data?.reduce((a, { wrong }) => a + wrong, 0)
            let exams = data?.reduce((a, { exams }) => a + exams, 0)

            return [exams - failed - passed, failed, passed/* , exams */]
        } else if (teacher === 'admin') {
            let adminData = data.filter((v) => v?.admin)
            let passed = adminData?.reduce((a, { passed }) => a + passed, 0)
            let failed = adminData?.reduce((a, { wrong }) => a + wrong, 0)
            let exams = adminData?.reduce((a, { exams }) => a + exams, 0)

            return [exams - failed - passed, failed, passed/* , exams */]
        } else {
            let teacherData = data.filter((v) => v?.user?._id === teacher)
            let passed = teacherData?.reduce((a, { passed }) => a + passed, 0)
            let failed = teacherData?.reduce((a, { wrong }) => a + wrong, 0)
            let exams = teacherData?.reduce((a, { exams }) => a + exams, 0)

            return [exams - failed - passed, failed, passed/* , exams */]
        }
    }

    const [chartState, setChartState] = useState({
        series: checkData(),
        options: {
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -10,
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    return `${w.globals.series[seriesIndex]}`;
                },
            },
            labels: ['N/A', 'Failed', 'Passed'/* , 'Total' */],
            colors: ['#f39c12', '#e74c3c', '#2ecc71'/* , '#3498db' */], // Customize colors here
            legend: {
                position: 'bottom'
            }
        }
    })

    return (
        <div className='dasboard-card'>
            <h2 className='inner-dasboard-card-title' style={{ color: 'black', textTransform: 'none' }}>{text}</h2>
            <div className='inner-dasboard-card-title-select-div'>
                <Select
                    showSearch
                    showArrow
                    allowClear={false}
                    style={{ width: '100%' }}
                    placeholder={selectedLanguage?.txtx_selectTeacher}
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                    defaultValue={'all'}
                    onChange={(e) => setTeacher(e)}
                >
                    <Option value={'all'} >{selectedLanguage?.txt_All}</Option>
                    <Option value={'admin'} >{selectedLanguage?.title_Admin}</Option>
                    {teachers.map((v, i) => <Option key={i} value={v?._id} >{v?.fullName}</Option>)}
                </Select>
            </div>

            <div className='dasboard-main-chart-div'>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={loafingImg} />
                    </div> :
                    data?.length ?
                        <div id='chart'>
                            <ReactApexChart
                                options={chartState.options}
                                series={chartState.series}
                                type='pie'
                                width={'100%'}
                                height={205} />
                        </div> :
                        <div style={{ textAlign: 'center' }}>
                            <p>{selectedLanguage?.txt_graphnotFound}</p>
                            <img src={nodataFound} style={{ height: '140px' }} />
                        </div>}
            </div>
        </div>
    )
}

export default ExamChart